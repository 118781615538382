import styles from "./buildingOneWorkshift.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import BuildingOneWorkshiftInfoCard from "./BuildingOneWorkshiftInfoCard";
import BuildingOneWorkshiftInfoComment from "./BuildingOneWorkshiftInfoComment";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import { dateFormats } from "stores/utils/consts";

const BuildingOneWorkshiftInfo = () => {
  const { id } = useParams();
  const { menuStore, buildingOneWorkshiftStore } = useStores();

  useEffect(() => {
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("workshiftOne");
    menuStore.setOpenedSubmodule("workshiftOne");
    buildingOneWorkshiftStore.setSelectedWorkshiftForInfo(id);
    menuStore.setSelectedWindow(`/workshift/id=${id}`);
    buildingOneWorkshiftStore.openedAllWorkshift[id] &&
      buildingOneWorkshiftStore.updateWindowWorkshift(id);
  }, [id, dateFormats.date.format?.length]);

  return (
    <>
      <LoadedComponent
        isLoading={buildingOneWorkshiftStore.isLoading}
        error={buildingOneWorkshiftStore.error}
      >
        <div className={styles.main}>
          <div className={styles.colWorkshift}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BuildingOneWorkshiftInfoCard />
            </ErrorBoundary>
          </div>
          <div className={styles.colComments}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BuildingOneWorkshiftInfoComment />
            </ErrorBoundary>
          </div>
        </div>
      </LoadedComponent>
    </>
  );
};

export default observer(BuildingOneWorkshiftInfo);
