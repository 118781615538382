import styles from "./buildingOneWorkshiftStafftableInputEdit.module.scss";
import { useRef, useEffect } from "react";
import { useStores } from "stores";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import Scrollbars from "react-custom-scrollbars-2";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";

import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { StaffType } from "stores/BuildingModule/types/StaffType";
import { classNames } from "shared/utils/helpers/classNames";

interface IBuildingOneWorkshiftStafftableInputEditProps
  extends WorkshiftStaffFieldProps {
  isMenuOpened: boolean;
  isTouched: string;
  setIsMenuOpened: (value: boolean) => void;
  setIsTouched: (value: string) => void;
}

const BuildingOneWorkshiftStafftableInputEdit = ({
  name,
  staffIndex,
  isMenuOpened,
  isTouched,
  setIsMenuOpened,
  setIsTouched
}: IBuildingOneWorkshiftStafftableInputEditProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { values, handleChange } = useFormikContext<StaffType>();
  const { id } = useParams();

  const ref = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();

  const handleClickToOpen = () => setIsMenuOpened(!isMenuOpened);
  const handleClickToSendData = (value: string) => () => {
    buildingOneWorkshiftStore.sendData(
      value,
      name,
      values[staffIndex].ws_staff_id,
      values[staffIndex].uid,
      values[staffIndex].isMassEdit
    );
    isMenuOpened && setIsMenuOpened(false);
    setIsTouched("");
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => () => {
    if (e.code === "Enter") {
      handleClickToSendData(values[staffIndex][name]);
    }
  };

  useOnClickOutside({
    ref,
    handler: () => {
      handleClickToSendData(values[staffIndex][name]);
      isMenuOpened && setIsMenuOpened(false);
      setIsTouched("");
    }
  });

  useEffect(() => {
    if (isTouched === values[staffIndex].uid) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [isTouched]);

  return (
    <div className="position-relative" ref={ref}>
      <div
        className={classNames(styles.inputBlock, {
          [styles.inputBlock_focused]: isMenuOpened
        })}
      >
        <input
          value={values[staffIndex][name]}
          name={`${[staffIndex]}.${[name]}`}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onChange={handleChange}
        />
        <IconExpand
          className={classNames("", {
            [styles.transformed]: isMenuOpened
          })}
          id="BuildingOneWorkshiftStafftableInputEdit_menuOpen"
          onClick={handleClickToOpen}
          alt="menu_open"
        />
      </div>
      {isMenuOpened && buildingOneWorkshiftStore.filters[id] && (
        <div className={styles.list}>
          <Scrollbars
            style={{ width: "100%" }}
            autoHeight
            autoHeightMax="250px"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            {Object.keys(buildingOneWorkshiftStore.filters[id])
              .filter((item) => item !== values[staffIndex][name])
              .map((item) => (
                <li key={item} id={item} onClick={handleClickToSendData(item)}>
                  {item}
                </li>
              ))}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default BuildingOneWorkshiftStafftableInputEdit;
