import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

const SalaryPremiumsOneHistory = () => {
  const { id } = useParams();
  const { menuStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary/premium`) {
        menuStore.deleteWindow(key);
      }
    });

    menuStore.setOpenedModule("premiumOne");
    menuStore.setOpenedSubmodule("premiumOneHistory");
    menuStore.sendTabId(id);

    menuStore.updateWindow({
      mainPath: `/salary/premium/id=${id}`,
      path: `/salary/premium/id=${id}/history`
    });
  }, [id]);

  return <>История премии {id}</>;
};

export default observer(SalaryPremiumsOneHistory);
