import styles from "./staffOneEditFormCompanies.module.scss";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { isEmpty, isEqual } from "lodash";
import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import { getKeys } from "shared/utils/helpers/getKeys";
import TruddogTabs from "./TruddogTabs";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { getEntries } from "shared/utils/helpers/getEntries";
import SelectMulti from "shared/ui/Inputs/SelectMulti";

type StaffOneEditFormCompaniesProps = {
  values: { companies: Record<string, ICompany>; company: string[] };
  openedListName: string | null;
  changeOpenedWindows: (arg: string | null) => void;
  required: boolean;
};

const StaffOneEditFormCompanies = ({
  values,
  openedListName,
  changeOpenedWindows,
  required
}: StaffOneEditFormCompaniesProps) => {
  const { staffOneEditStore } = useStores();
  const { setFieldValue } = useFormikContext();
  const { id } = useParams();
  const [isError, setIsError] = useState(false);

  const companiesTabs = values.company?.map((company_id) => {
    return {
      id: company_id,
      title: staffOneEditStore.allCompanies[company_id]?.title
    };
  });

  const selectedCompany = staffOneEditStore.selectedCompanyId[id];

  useEffect(() => {
    if (
      values.company?.length &&
      !isEqual(values.company, getKeys(values.companies))
    ) {
      const currentCompanies: Record<string, ICompany> = {};
      let selectedId = "";

      values.company.forEach((key) => {
        let isNewCompany = false;

        const provSelectedCompanyId = () => {
          if (getKeys(currentCompanies)[0]) {
            selectedId = selectedCompany ?? getKeys(currentCompanies)[0];
          } else selectedId = key;
        };

        if (!isEmpty(values.companies)) {
          for (const item of getEntries(values.companies)) {
            if (key === item[0]) {
              currentCompanies[key] = item[1];
              staffOneEditStore.setCurrentCompanyList(id, values.company);

              isNewCompany = false;
              break;
            } else {
              isNewCompany = true;
            }
          }
        } else {
          isNewCompany = true;
        }
        provSelectedCompanyId();

        if (isNewCompany) {
          selectedId = key;
          staffOneEditStore.setNewCompanyData(
            selectedId,
            getKeys(staffOneEditStore.initialCompanies).includes(selectedId)
              ? staffOneEditStore.initialCompanies[selectedId]
              : staffOneEditStore.allCompanies[selectedId]
          );
        }
      });

      setFieldValue("companies", currentCompanies);

      if (selectedId.length) {
        if (
          values.company?.length <
          getKeys(staffOneEditStore.selectedOneForEdit.company).length
        ) {
          staffOneEditStore.setCurrentCompanyList(id, values.company);
        }
        staffOneEditStore.setSelectedCompanyId(id, selectedId);

        if (currentCompanies[selectedId]?.employments) {
          staffOneEditStore.setSelectedTypeOfJob(
            id,
            getKeys(currentCompanies[selectedId].employments)[0]
          );
        }
      }
    }
    if (values.company !== undefined && !values.company?.length) {
      staffOneEditStore.clearCompanyData(id);
      setIsError(true);
    } else setIsError(false);
  }, [values.company]);

  const clearAllFields = () => {
    setIsError(true);
    staffOneEditStore.clearCompanyData(id);
  };

  return (
    <>
      <SelectMulti
        name="company"
        label={staffOneEditStore.columns?.company?.title}
        required={required}
        options={staffOneEditStore.allCompaniesList}
        isMenuOpened={openedListName === "companies"}
        setIsMenuOpened={() => {
          changeOpenedWindows("companies");
        }}
        valueName="id"
        onAdditionalFunc={clearAllFields}
        isError={isError}
      />

      <ButtonsTabs
        tabs={companiesTabs}
        selectedTab={selectedCompany}
        changeActiveTab={(company_id) =>
          staffOneEditStore.setSelectedCompanyId(id, company_id, true)
        }
        className={{ container: styles.tabsContainer }}
      />
      <TruddogTabs values={values} />
    </>
  );
};

export default observer(StaffOneEditFormCompanies);
