import { stringNoPunc } from "shared/utils/validation/validation";
import { object, array } from "yup";

const defaultSchemaWithLimit = stringNoPunc.max(100, "Не более 100 знаков");

export const validationCitiesFields = object({
  cities: array().of(
    object({
      type_city: stringNoPunc.max(5, "Не более 5 знаков"),
      title: defaultSchemaWithLimit,
      type_region: defaultSchemaWithLimit,
      type_district: defaultSchemaWithLimit,
      district: defaultSchemaWithLimit,
      region: defaultSchemaWithLimit,
      federal_district: defaultSchemaWithLimit
    })
  )
});
