import styles from "./editButtonsPanel.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import { Button, ButtonTheme } from "shared/ui/Button";

import iconMultiEdit from "shared/assets/images/mainIcons/iconsMultiEdit/iconMultiEdit.svg";
import iconMultiEditWhite from "shared/assets/images/mainIcons/iconsMultiEdit/iconMultiEditWhite.svg";
import { ReactComponent as IconAttention } from "shared/assets/images/iconStatus/Attention.svg";

import { classNames } from "shared/utils/helpers/classNames";
import { statusParams } from "stores/AregisterModule/directories/statusesParams";
import { getAregisterStatus } from "shared/utils/helpers/getAregisterStatus";

import { AregisterEditTypes } from "stores/AregisterModule/aregisterList";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

const EditButtonsPanel = () => {
  const { aregisterListStore, menuStore } = useStores();

  const { values, dirty, isValid, handleReset } =
    useFormikContext<AregisterListFormik>();

  const isMassEdit = aregisterListStore.openedEditType === "mass_edit";
  const isMassStatus = aregisterListStore.openedEditType === "mass_status";

  const actionBtnTitle = () => {
    const status = getAregisterStatus(
      aregisterListStore.selects.status?.[
        aregisterListStore.openedMassStatusEdit
      ]?.custom
    );

    if (isMassStatus) {
      return statusParams[status]?.savedPanelBtnTitle;
    } else return "Сохранить";
  };
  const actionBtnDisabled =
    !dirty || !isValid || (isMassEdit && aregisterListStore.isMassEditDisabled);

  const handleActionBtnClick = () => {
    if (isMassStatus) {
      aregisterListStore.changeStatus(values["aregister"]);
      handleReset();
    } else {
      // здесь нужно сохранять values
    }
  };
  const changeOpenedType =
    (type: AregisterEditTypes[number], sub_type?: string) => () => {
      if (dirty) {
        aregisterListStore.changeOpenedModal(aregisterListStore.openedEditType);
        aregisterListStore.setPreOpenEditType({
          type: type,
          sub_type: sub_type || ""
        });
      } else {
        aregisterListStore.setOpenedEditType(type, sub_type);
        aregisterListStore.setPreOpenEditType({
          type: "",
          sub_type: ""
        });
      }
    };

  const getChangesText = () => {
    const count = values["aregister"]?.length;
    if (isMassEdit && dirty && !aregisterListStore.isMassEditDisabled)
      return "Внесены изменения";
    else if (isMassStatus && dirty && count)
      return (
        <>
          Отмечено заявок: <b>{count}</b>
        </>
      );
    else if (dirty) return "Внесены изменения";
  };

  return aregisterListStore.openedPanel ? (
    <div
      className="w-100"
      style={{
        marginLeft: menuStore.scroll.scrollLeft
          ? menuStore.scroll.scrollLeft
          : 0,
        transition:
          !aregisterListStore.isLoading && !aregisterListStore.isScaleChanged
            ? "margin 0.8s ease"
            : ""
      }}
    >
      <div className={styles.panel}>
        {/* Кнопки фильтрации заявок для смены статуса.
        (при выборе одного из статусов будут отображены только те заявки, 
        которым можно изменить статус на выбранный) */}
        <div className={styles.leftSide}>
          <p className={styles.subtitle}>Отметить заявки</p>
          <div className="d-flex">
            {Object.entries(aregisterListStore.selects.status).map(
              ([id, status]) => {
                const statusName = getAregisterStatus(status.custom);
                return (
                  <Button
                    key={id}
                    theme={ButtonTheme.SECONDARY}
                    className={classNames(styles.panelBtn, {
                      [styles.panelBtnActive]:
                        aregisterListStore.openedMassStatusEdit === id
                    })}
                    onClick={changeOpenedType("mass_status", id)}
                    id={id}
                  >
                    {statusParams[statusName]?.icon}
                    <p>{statusParams[statusName]?.text}</p>
                  </Button>
                );
              }
            )}
          </div>
          <p className={styles.caption}>
            Выберите для чего требуется отметить заявки
          </p>
        </div>

        {/* Кнопка массового редактирования заявок */}
        <div className={styles.middleSide}>
          <Button
            theme={ButtonTheme.SECONDARY}
            className={classNames("", {
              [styles.panelBtn]: !isMassEdit,
              [styles.panelBtnBlack]: isMassEdit
            })}
            onClick={changeOpenedType("mass_edit")}
            id="EditButtonsPanel_massEdit"
          >
            <img
              src={isMassEdit ? iconMultiEditWhite : iconMultiEdit}
              alt="Редактировать массово"
            />
            <p>Редактировать массово</p>
          </Button>
        </div>

        {/* Кнопки сохранения с текстом о наличии изменений данных */}
        <div className={styles.rightSide}>
          <p>{getChangesText()}</p>

          <ButtonsGroupForFormEdit
            blueBtnTitle={actionBtnTitle()}
            blueBtnDisabled={actionBtnDisabled}
            blueBtnOnClick={handleActionBtnClick}
            grayBtnTitle={isMassStatus ? "Отменить выбор" : "Отменить"}
            grayBtnDisabled={!dirty}
            grayBtnOnClick={handleReset}
          />
        </div>

        {/* Ошибка при смене статуса заявки на "Закрыта" */}
        {aregisterListStore.unableCloseApp.length ? (
          <div className={styles.error}>
            <IconAttention />
            <p>Не все заявки могут быть закрыты</p>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default observer(EditButtonsPanel);
