import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { Col } from "stores/utils/types/Col";
import { Param } from "stores/utils/types/Param";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import { SalaryOperation } from "./types/SalaryOperation";
import { OperationType } from "./types/SalaryOperationType";

import TableParams from "./directories/salaryOperationsTableParams.json";
interface ICol extends Col {
  id?: string;
  isvariable?: number;
  variable?: {
    [key: string]: Col;
  };
}

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

type InitialValues = {
  time_spending: string;
  uid: string;
  type: string;
  money: string;
  comment: string;
  object: string;
  initiator: string;
};
export default class SalaryOperationsFormStore {
  error = false;
  isLoading = false;
  searchValue = "";

  cols: { [key: string]: Col } = {};
  params: { [key: string]: Param } = {};
  operationsTypes: { [key: string]: ICol } = {};
  staff: { [key: string]: string } = {};

  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  initialValues: InitialValues = {
    time_spending: null,
    uid: null,
    type: null,
    money: null,
    comment: "",
    object: null,
    initiator: null
  };
  rootStore: RootStore;

  getDataForForm = async () => {
    this.isLoading = true;
    this.getTableCols();
    this.getStaffList();
    this.getBuildingsList();
    this.getSelects();
    try {
      Promise.all([
        !Object.values(this.params).length && this.getTableParams()
      ]).then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  getTableCols = async () => {
    runInAction(() => (this.isLoading = true));

    try {
      const data: ApiResponse<{ [key: string]: Col }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "salary",
          method: "getTableCols"
        });
      runInAction(() => {
        if (data["code"] === 200) {
          this.cols = data["result"];
        } else {
          this.cols = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getTableParams = async () => {
    this.params = TableParams;
  };

  dataPreparationBuilding = (data: { id: string; title: string }[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfStaffObj["newname"] = value;
            break;

          default:
            tempOneOfStaffObj[key] = value;
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getBuildingsList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.cols) {
            this.cols["object"] = {
              ...this.cols["object"],
              directory: this.dataPreparationBuilding(
                Object.values(data["result"])
              )
            };
          }
        } else {
          this.cols["object"]["directory"] = [];
          this.isLoadingForBuildingList = true;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.cols) {
            const directory = (
              this.cols["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparationBuilding(Object.values(data["result"]))
            );

            this.cols["object"] = {
              ...this.cols["object"],
              directory: directory
            };
          }
        } else {
          this.cols["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  getNewInitialValues = (id?: string) => {
    Object.entries(this.cols).forEach(([key, value]) => {
      if (this.params[key]?.add_show || this.params[key]?.show_default) {
        if (key === "uid" && this.staff[id]) {
          return (this.initialValues[key] = this.staff[id]);
        }
        switch (value.type) {
          case "bool":
            return (this.initialValues[key] = 0);
          default:
            if (key.includes("comment")) {
              return (this.initialValues[key] = "");
            } else return (this.initialValues[key] = null);
        }
      }
    });
    return this.initialValues;
  };

  getInitialValuesForEdit = (operation: Partial<SalaryOperation>) => {
    Promise.all([
      !Object.values(this.params).length && this.getTableParams()
    ]).then(() => {
      runInAction(() => {
        Object.keys(this.params).forEach((title) => {
          switch (title) {
            case "uid":
            case "initiator":
              return (this.initialValues[title] = operation[title]?.id || null);
            case "object":
              return (this.initialValues[title] = operation[title]?.id || null);
            case "author":
              return (this.initialValues[title] =
                operation[title]?.name || null);
            case "paymentlist":
              return (this.initialValues[title] =
                operation[title]?.status_title || null);
            default:
              if (title.includes("comment")) {
                return (this.initialValues[title] = operation[title] || "");
              } else
                return (this.initialValues[title] = operation[title] || null);
          }
        });
      });
    });
    return this.initialValues;
  };

  getRequiredFields = (values: {
    [key: string]: string | number | { newname: string; title: string };
  }): string[] => {
    const requiredFields: string[] = [];

    Object.keys(values).forEach((title) => {
      if (this.params[title].required) {
        requiredFields.push(title);
      }
    });

    return requiredFields;
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<{
        operation_type: { [id: string]: OperationType };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSelects"
      });

      runInAction(() => {
        if (data.code === 200) {
          this.operationsTypes = data["result"]["operation_type"];
          Object.values(this.operationsTypes).forEach((type) => {
            this.operationsTypes[type.id] = type;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  addOperation = async (
    uid: string,
    date: string,
    type: string,
    money: string,
    building: string,
    comment?: string,
    initiator?: string
  ) => {
    this.isLoading = true;
    const formData = {
      uid,
      date,
      type,
      money: money.replaceAll(" ", "").replaceAll(",", ".").slice(0, -1),
      building,
      comment,
      initiator
    };

    try {
      const data: {
        code: number;
        hash: string;
        ok: boolean;
        message: Omit<Errors["message"], "body">;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "addOperation",
        body: formData
      });

      runInAction(() => {
        if (!data["ok"]) {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  setSelectedStaffFromTable = async (id: string, staff: string) => {
    this.staff[id] = staff;
  };

  dataPreparationStaff = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj["newname"] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<Record<string, StaffListItem>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          if ("uid" in this.params) {
            this.cols["uid"] = {
              ...this.cols["uid"],
              directory: this.dataPreparationStaff(
                Object.values(data["result"])
              )
            };
          }
          if ("initiator" in this.params) {
            this.cols["initiator"] = {
              ...this.cols["initiator"],
              directory: this.dataPreparationStaff(
                Object.values(data["result"])
              )
            };
          }
        } else {
          this.error = true;
          this.cols["uid"]["directory"] = [];
          this.cols["initiator"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          if ("uid" in this.params) {
            const directory = (
              this.cols["uid"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparationStaff(Object.values(data["result"])));
            this.cols["uid"] = {
              ...this.cols["uid"],
              directory: directory
            };
          }
          if ("initiator" in this.params) {
            const directory = (
              this.cols["initiator"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparationStaff(Object.values(data["result"])));
            this.cols["initiator"] = {
              ...this.cols["initiator"],
              directory: directory
            };
          }
        } else {
          this.cols["uid"]["directory"] = [];
          this.cols["initiator"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
