export const ColorDict = {
  default: "#ffffff",
  success: "#17a854",
  info: "#2096f4",
  primary: "#337ab7",
  warning: "#ec6500",
  danger: "#ff584e",
  pink: "#e91e63",
  purple: "#9c27b0",
  "deep-purple": "#673ab7",
  indigo: "#3f51b5",
  blue: "#2196f3",
  "light-blue": "#03a9f4",
  cyan: "#00bcd4",
  teal: "#009688",
  green: "#4caf50",
  "light-green": "#8bc34a",
  lime: "#cddc39",
  yellow: "#ffeb3b",
  amber: "#ffc107",
  orange: "#ff9800",
  "deep-orange": "#ff5722",
  red: "#f44336",
  brown: "#4e342e",
  grey: "#9fa6b2",
  "blue-grey": "#78909c"
};
