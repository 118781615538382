import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import styles from "./staffStatusSettingsMenu.module.scss";
import Submenu from "shared/ui/Submenu/index";

import { cloneDeep } from "lodash";

const StaffStatusSettingsMenu = () => {
  const { staffStatusStore } = useStores();
  return (
    <div className={styles.menuContainer}>
      <Submenu
        title="Статусы"
        array={Object.values(staffStatusStore.statusTypes)}
        arrayItemId="id"
        arrayItemTitle="title"
        arrayItemOrdered="ordered"
        selectedItem={
          staffStatusStore.selectedStatusId === "new"
            ? staffStatusStore.newStatus
            : staffStatusStore.statusTypes[staffStatusStore.selectedStatusId]
        }
        newItem={staffStatusStore.newStatus?.id}
        setSelectedItem={staffStatusStore.setSelectedStatusId}
        funcForNewItem={() => {
          const copy = cloneDeep(
            staffStatusStore.statusTypes[staffStatusStore.selectedStatusId]
          );
          staffStatusStore.addNewStatus(copy);
          staffStatusStore.setSelectedStatusId("new");
        }}
        iconSortImg
      />
    </div>
  );
};

export default observer(StaffStatusSettingsMenu);
