import styles from "./staffTableHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import { getEntries } from "shared/utils/helpers/getEntries";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

const StaffTable = () => {
  const { aregisterOneStore, menuStore } = useStores();
  const { id } = useParams();

  const tHClassNames = (name: string) => {
    return classNames(styles.firstRowTh, {
      [styles.presaveDate]: !["num", "main", "total"].includes(name),
      // 28 - ширина столбца num, таблица прилипает к левой границе окна
      [styles.shadowRight]:
        menuStore.scroll.scrollLeft > 28 && ["num", "main"].includes(name),
      // при наличии горизонтального скролла (таблица широкая) и небольшом скролле вправо нужно отобразить тень стобца Итого
      [styles.shadowLeft]:
        aregisterOneStore.hasHorizontalScroll() &&
        menuStore.scroll.left < 0.9 &&
        name === "total",
      [styles.highlightedPresave]:
        aregisterOneStore.highlightedPresave[id] === name ||
        aregisterOneStore.focusedPresave[id] === name,
      [styles.firstRowTotal]: name === "total",
      [styles.num]: name === "num",
      [styles.main]: name === "main"
    });
  };

  return (
    <thead
      className={classNames(styles.thead, {
        [styles.theadShadow]:
          menuStore.scrollbarRef.current?.getScrollTop() > 200
      })}
    >
      <tr className={styles.firstRow}>
        <th className={tHClassNames("num")} rowSpan={2}>
          №
        </th>
        {getEntries(aregisterOneStore.presavesCols).map(([group, col]) => {
          if (group === "main")
            return (
              <th key={group} rowSpan={2} className={tHClassNames(group)}>
                {col.staff_info}
              </th>
            );
          else if (group === "total")
            return (
              <th key={group} colSpan={3} className={tHClassNames(group)}>
                <div>Итого</div>
              </th>
            );
          else if (group === "group_by_presave")
            return getEntries(aregisterOneStore.presaves[id])
              .sort(([, a], [, b]) =>
                parse(a.date, dateFormats.date.format, new Date()) >
                parse(b.date, dateFormats.date.format, new Date())
                  ? 1
                  : -1
              )
              .map(([, presave], i) => (
                <th
                  key={`${group}_${presave.date}`}
                  colSpan={4}
                  className={tHClassNames(group)}
                >
                  <div>
                    {
                      <span className={styles.presavesCounter}>{`${i + 1}/${
                        getKeys(aregisterOneStore.presaves[id]).length
                      }`}</span>
                    }
                    {presave.date}
                  </div>
                </th>
              ));
        })}
      </tr>
      <tr className={styles.secondRow}>
        {getEntries(aregisterOneStore.presavesCols).map(([group, cols]) => {
          if (group === "main") return;
          else if (group === "group_by_presave")
            return getKeys(aregisterOneStore.presaves[id]).map((presave) =>
              getEntries(cols).map(([name, title]) => (
                <th
                  key={name}
                  className={classNames(styles.secondRowTh, {
                    [styles.highlightedPresave]:
                      aregisterOneStore.highlightedPresave[id] === presave ||
                      aregisterOneStore.focusedPresave[id] === presave
                  })}
                >
                  <div>{title}</div>
                </th>
              ))
            );
          else if (group === "total")
            return getEntries(cols).map(([name, title]) => (
              <th
                key={`th_${group}_${name}`}
                className={classNames(styles.secondRowThTotal, {
                  [styles.shadowLeftTop]:
                    aregisterOneStore.hasHorizontalScroll() &&
                    menuStore.scroll.left < 0.9
                })}
              >
                <div>{title}</div>
              </th>
            ));
        })}
      </tr>
    </thead>
  );
};

export default observer(StaffTable);
