export const getFieldsOfGroup = (
  name: string,
  titles: string[],
  group: string[]
) => {
  if (group) {
    // Возвращаемый массив полей для отрисовки в строке
    const result: string[] = [];
    // Поля, отсутствие которых нужно проверить в titles, чтобы не отрисовывать поле повторно
    const verifications = group.slice(0, group.indexOf(name));
    // Поля для отрисовки (непроверенные)
    const another_fields = group.slice(group.indexOf(name), group.length);

    if (!verifications.some((v_name) => titles.includes(v_name)))
      another_fields.forEach((n) => titles.includes(n) && result.push(n));

    return result;
  }
};
