import { observer } from "mobx-react-lite";
import styles from "./awardsGalleryWindow.module.scss";
import { useRef, useState } from "react";

import { Formik } from "formik";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import validationDate from "./validation";
import WindowHeader from "./WindowHeader";
import WindowTabs from "./WindowTabs";
import WindowBody from "./WindowBody";
import History from "./History";
import Modal from "shared/ui/Modal";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { useStores } from "stores";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { classNames } from "shared/utils/helpers/classNames";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { isEmpty } from "lodash";
import LoadedComponent from "widgets/LoadedComponent";

type AwardsGalleryWindowProps = {
  openWindow: boolean;
  setOpenWindow: (openedWindow: boolean) => void;
};

const AwardsGalleryWindow = ({
  openWindow,
  setOpenWindow
}: AwardsGalleryWindowProps) => {
  const { staffOneAwardsStore } = useStores();
  const [openModal, setOpenModal] = useState("");
  const [changeAward, setChangeAward] = useState(false);
  const [withoutAwards, setWithoutAwards] = useState(false);

  const currentAward =
    staffOneAwardsStore.awards[staffOneAwardsStore.openedAward];
  const ref = useRef();

  let titleForModal = "";

  const getTitleForModal = () => {
    if (openModal === "delete") {
      titleForModal = `Вы уверены, что хотите удалить награду "${currentAward?.type_title}"?`;
    }

    if (openModal === "close") {
      if (changeAward) {
        titleForModal = `Вы уверены, что хотите закрыть окно без сохранения данных?`;
      }
    }
    if (openModal === "success") {
      if (changeAward) {
        titleForModal = `Изменения сохранены`;
      } else {
        titleForModal = `Награда ${
          staffOneAwardsStore.deletedAward
            ? '"' + staffOneAwardsStore.deletedAward + '"'
            : ""
        } удалена`;
      }
    }
    return titleForModal;
  };

  return (
    <>
      <LoadedComponent
        isLoading={
          staffOneAwardsStore.isLoadingForModal || staffOneAwardsStore.isLoading
        }
      />
      <Formik
        initialValues={{
          staff_id: staffOneAwardsStore.staffInfo.id,
          award_id: currentAward?.id,
          award_type: currentAward?.type,
          award_start: currentAward?.award_start
        }}
        onSubmit={() => {
          return;
        }}
        enableReinitialize
        validateOnBlur
        validateOnChange
        validationSchema={validationDate}
      >
        {({ dirty, handleReset, values, isValid }) => {
          useOnClickOutside({
            ref,
            handler: () => {
              if (openModal !== "delete") {
                !dirty ? setOpenWindow(false) : setOpenModal("close");
              }
            }
          });

          getTitleForModal();
          const closeWindow = () => {
            if (changeAward && dirty) {
              setOpenModal("close");
            } else {
              setOpenModal("");
              setOpenWindow(false);
              handleReset();
            }
          };

          const blueButtonHandler = async () => {
            if (openModal === "delete") {
              setOpenModal("");
            }
            if (changeAward || (openModal === "close" && changeAward)) {
              await staffOneAwardsStore.updateAward(
                values.staff_id,
                values.award_id,
                values.award_start
              );
              if (isEmpty(staffOneAwardsStore.errorsMessage)) {
                setOpenModal("success");
                setTimeout(() => {
                  setOpenModal("");
                  setChangeAward(false);
                }, 1000);
              } else {
                if (openModal === "close" && changeAward) {
                  setOpenModal("");
                }
              }
            }
            if (!openModal) setChangeAward(true);
          };

          const btnWithCrossHandler = async () => {
            if (openModal === "close") {
              if (changeAward) {
                setOpenWindow(false);
                staffOneAwardsStore.clearErrorsMessage();
              }
            }
            if (openModal === "delete") {
              await staffOneAwardsStore.deleteAward(
                values.staff_id,
                currentAward.id,
                currentAward?.type_title
              );

              if (isEmpty(staffOneAwardsStore.errorsMessage)) {
                setOpenModal("success");
                setOpenWindow(false);

                setTimeout(() => {
                  setOpenModal("");
                  staffOneAwardsStore.clearDeletedAward();
                }, 1000);
              }
            }
          };

          return (
            <>
              {openWindow && (
                <div className={styles.background}>
                  <div className={styles.windowContainer}>
                    <div className={styles.window} ref={ref}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <WindowHeader
                          title="Награда"
                          subtitle={`${staffOneAwardsStore.staffInfo.surname} ${
                            staffOneAwardsStore.staffInfo.name
                          } ${staffOneAwardsStore.staffInfo.patronymic || ""}`}
                          closeWindowFunc={closeWindow}
                        />
                      </ErrorBoundary>
                      <div className={styles.container}>
                        {changeAward ? (
                          <div className={styles.awardDetails}>
                            <p className={styles.title}>Группа</p>
                            <div className={styles.groupName}>
                              {
                                staffOneAwardsStore.awardGroups[
                                  currentAward?.custom?.group
                                ]?.title
                              }
                            </div>
                          </div>
                        ) : (
                          <div className={styles.group}>
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                              <WindowTabs
                                setOpenedAward={
                                  staffOneAwardsStore.setOpenedAward
                                }
                                setWithoutAwards={setWithoutAwards}
                              />
                            </ErrorBoundary>
                          </div>
                        )}
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                          <WindowBody
                            currentAward={currentAward}
                            withoutAwards={withoutAwards}
                            changeAward={changeAward}
                          />
                        </ErrorBoundary>
                        {!withoutAwards && staffOneAwardsStore.access && (
                          <>
                            <div
                              className={classNames(styles.buttons, {
                                [styles.invisible]:
                                  withoutAwards || !staffOneAwardsStore.access
                              })}
                            >
                              <ButtonsGroupForSettings
                                cancelBtnTitle={
                                  changeAward ? "Отмена" : "Удалить награду"
                                }
                                cancelBtnOnClick={() => {
                                  dirty
                                    ? setOpenModal("close")
                                    : setChangeAward(false);
                                  if (!changeAward) {
                                    setOpenModal("delete");
                                  }
                                }}
                                cancelBtnImg={
                                  <IconClose
                                    className={
                                      changeAward
                                        ? styles.cancelBtnIconBlue
                                        : ""
                                    }
                                  />
                                }
                                saveBtnTitle={
                                  changeAward
                                    ? "Сохранить изменения"
                                    : "Редактировать"
                                }
                                saveBtnOnClick={blueButtonHandler}
                                saveBtnDisabled={
                                  (changeAward && !dirty) ||
                                  !isValid ||
                                  !isEmpty(staffOneAwardsStore.errorsMessage)
                                }
                                className={{
                                  cancelBtn: changeAward
                                    ? styles.cancelBtnBlue
                                    : styles.cancelBtn,
                                  saveBtn: !changeAward && styles.saveBtn
                                }}
                              />
                            </div>
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                              <History history={currentAward?.history} />
                            </ErrorBoundary>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Modal
                type={
                  openModal === "delete"
                    ? "clarification"
                    : openModal === "close"
                    ? "clarificationForFormWindows"
                    : "success"
                }
                show={Boolean(openModal)}
                onHide={() => setOpenModal("")}
                title={titleForModal}
                btnWithCrossTitle={
                  openModal === "delete" && changeAward
                    ? "Закрыть"
                    : openModal === "close" && changeAward
                    ? "Закрыть без сохранения"
                    : "Удалить"
                }
                btnWithCrossOnClick={btnWithCrossHandler}
                blueBtnOnClick={blueButtonHandler}
                blueBtnTitle={
                  openModal === "close" && changeAward
                    ? "Сохранить и закрыть"
                    : "Отмена"
                }
                blueBtnDisabled={
                  (changeAward && !dirty) ||
                  !isValid ||
                  !isEmpty(staffOneAwardsStore.errorsMessage)
                }
                greyBtnOnClick={() =>
                  openModal === "close" && changeAward && setOpenModal("")
                }
                greyBtnTitle={openModal === "close" && changeAward && "Отмена"}
                btnWithCrossImg
                successIcon={openModal === "success"}
                direction="row"
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default observer(AwardsGalleryWindow);
