import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import LoadedComponent from "widgets/LoadedComponent";
import { Formik } from "formik";

import AregisterOneMasscopyForm from "./AregisterOneMasscopyForm";

import { getValidationSchema } from "./validation";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const AregisterOneMasscopy = () => {
  const { id } = useParams();
  const { menuStore, aregisterOneMasscopyStore, aregisterAppcreateStore } =
    useStores();

  const [tableType, setTableType] = useState("");

  useEffect(() => {
    setTableType(aregisterOneMasscopyStore.tableType[id]);
  }, [aregisterOneMasscopyStore.tableType[id]]);

  useEffect(() => {
    aregisterOneMasscopyStore.setSelectedApplication(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("aregisterOne");
    menuStore.setOpenedSubmodule("aregisterOneMasscopy");
    menuStore.updateWindow({
      mainPath: `/aregister/id=${id}`,
      path: `/aregister/id=${id}/masscopy`
    });
  }, [id]);

  return (
    <LoadedComponent isLoading={aregisterOneMasscopyStore.isLoading}>
      {Object.values(aregisterAppcreateStore.params).length &&
      Object.values(aregisterOneMasscopyStore.selectedOneForMasscopy).length &&
      Object.values(aregisterAppcreateStore.projects).length &&
      aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id] ? (
        <Formik
          initialValues={{
            reload: 0,
            original:
              aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id],
            copies: [],
            massfill: {}
          }}
          onSubmit={() => {
            return;
          }}
          validationSchema={getValidationSchema(
            ["reload", "original", "copies", "massfill"],
            aregisterAppcreateStore.getRequiredFields(
              Object.keys(
                aregisterOneMasscopyStore.openedAllAppllicationsForMasscopy[id]
              )
            )
          )}
          enableReinitialize
        >
          {({ values }) => {
            return (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AregisterOneMasscopyForm
                  values={values}
                  tableType={tableType}
                />
              </ErrorBoundary>
            );
          }}
        </Formik>
      ) : null}
    </LoadedComponent>
  );
};

export default observer(AregisterOneMasscopy);
