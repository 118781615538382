import styles from "./aregisterFormSize.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";

import { Input } from "shared/ui/Inputs/Input";
import NumberFormat from "react-number-format";

import { ReactComponent as IconFormula } from "./images/iconFormula.svg";
import { Formula } from "stores/AregisterModule/types/Formula";

type AregisterFormSizeProps = {
  values: { [key: string]: string | number };
  requiredFields: string[];
  checkSizeErrors: (formula: Partial<Formula>) => boolean;
};

const AregisterFormSize = ({
  values,
  requiredFields,
  checkSizeErrors
}: AregisterFormSizeProps) => {
  const { aregisterAppcreateStore } = useStores();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [formula, setFormula] = useState<Partial<Formula>>({});

  const cols = aregisterAppcreateStore.cols;

  // функция получения размеров (объёма, поле size)
  const getSize = (value?: string, field?: string) => {
    // значения всех полей для вычисления размеров
    const sizeValues = {
      length: field === "length" ? Number(value) : Number(values.length),
      width: field === "width" ? Number(value) : Number(values.width),
      height: field === "height" ? Number(value) : Number(values.height)
    };

    // словарь для расшифровки формулы, т.к. она приходит на кириллице
    const translateFields = [
      { title: "Длина", name: "length" },
      { title: "Ширина", name: "width" },
      { title: "Высота", name: "height" }
    ];

    // массив из строки-формуды для расшифровки
    const formulaFromDict: string[] = formula.value?.formula
      ? Array.from(
          aregisterAppcreateStore.formulasDict.items[formula.value.formula].name
            // Max и Min заменяем на числовые методы
            .replaceAll("Max", "Math.max")
            .replaceAll("Min", "Math.min")
        )
      : [];
    // расшифровка значений формулы с бэка на кириллице
    const symbolDescription = aregisterAppcreateStore.formulasDict.translate;

    // перебираем массив из строки-формулы, чтобы подставить значения из полей
    formulaFromDict?.length &&
      formulaFromDict.forEach((symbol, i) => {
        if (
          // если элемент массива-строки есть в расшифровке
          symbolDescription[symbol]?.description &&
          // и следующий элемент не "a" (т.к. в формуле есть символ M (длина), а также в Math есть такая же буква)
          formulaFromDict[i + 1] !== "a"
        ) {
          // переводим расшифровку с кириллицы на english
          const fieldName = translateFields.find((translate) => {
            return translate.title === symbolDescription[symbol].description;
          });
          // и перезаписываем элемент массива строки-формулы на значение из поля ввода по переведенному имени поля
          formulaFromDict[i] = sizeValues[fieldName.name] || 0;
        }
      });

    // Так как здесь нет пользовательского ввода, ожидается, что здесь не будет лишних символов и eval будет работать корректно
    const result: number = formulaFromDict?.length
      ? eval(formulaFromDict.join(""))
      : Object.values(sizeValues).reduce((acc, rec) => acc * rec);

    setFieldValue(
      "size",
      isNaN(+result) || result === 0 ? null : String(result)
    );
    setFieldTouched("size", isNaN(+result) || result === 0 ? true : false);
    return result;
  };

  // функция проверки значения поля на вхождение в диапазон, возвращает текст ошибки
  const checkValue = (field: string, value: string): string => {
    let error: string = null;

    if (formula?.[`max_${field}`] && +value > formula?.[`max_${field}`]) {
      error = `Не более ${formula?.[`max_${field}`]}`;
    } else if (
      formula?.[`min_${field}`] &&
      +value < formula?.[`min_${field}`]
    ) {
      error = `Не менее ${formula?.[`max_${field}`]}`;
    }

    return error;
  };

  // функция валидации поля
  const validateField = (field: string, value: string) => {
    if (field !== "size") {
      // массив с именами полей, у которых есть ограничения
      const fields: string[] = [];

      // проверяем, есть ли какие-то ограничения для полей
      ["height", "length", "width"].forEach((fieldName) => {
        if (
          // если есть хоть одно
          formula[`max_${fieldName}`] !== null ||
          formula[`min_${fieldName}`] !== null
        ) {
          // добавляем в массив полей с ограничениями
          fields.push(fieldName);
        }
      });

      // если check_all === true
      if (formula?.check_all) {
        // просто возвращаем функцию ошибки (вернет текст или пустую строку)
        return checkValue(field, value);
      }
      // а если check_all === false
      else if (fields.includes(field)) {
        // массив имен полей, в которых нарушено ограничение (с ошибками)
        const errorFields: string[] = [];

        // проходимся по массиву имен полей с ограничениями
        fields.forEach((fieldName) => {
          // и если в значении поля это ограничение нарушено
          if (checkValue(fieldName, values[fieldName] as string)?.length) {
            // добавляем имя поля в массив с ошибками
            errorFields.push(fieldName);
          }
        });

        // если массив с ошибками равен массиву с ограничениями (т.е. в каждом поле с ограничением есть ошибка)
        return errorFields.toString() === fields.toString()
          ? // возравращаем текст ошибки
            checkValue(field, value)
          : // если ошибка не во всех полях с ограничениями -- пустую строку
            "";
      }
    }
  };

  useEffect(() => {
    if (
      values.type &&
      values.type_work &&
      values.sub_work &&
      values.species &&
      values.project &&
      values.date_start
    ) {
      setTimeout(() => {
        const id = `${values.type}_${values.type_work}_${values.sub_work}_${values.species}`;

        aregisterAppcreateStore.formula[id] &&
          setFormula(aregisterAppcreateStore.formula[id]);
      }, 500);
    }
  }, [
    values.type,
    values.type_work,
    values.syb_work,
    values.species,
    values.project,
    values.date_start
  ]);

  useEffect(() => {
    if (values.size) {
      getSize();
    }
  }, [formula]);

  return (
    <div className={styles.field}>
      <div className={styles.sizes}>
        <div className={styles.sizes__heading}>{cols.measurements.title}</div>
        <div className={styles.sizes__group}>
          {["length", "width", "height", "size"].map((field) => {
            return (
              <NumberFormat
                key={field}
                name={field}
                value={values[field] || String(values[field])}
                label={cols[field].title}
                customInput={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (field !== "size") {
                    getSize(e.target.value, field);
                  }

                  setTimeout(() => setFieldTouched("size"), 200);
                }}
                onClick={() => {
                  setFieldTouched("size");
                }}
                required={requiredFields.includes(field)}
                validate={(value: string) => {
                  return validateField(field, value);
                }}
                disabled={field === "size"}
              />
            );
          })}
        </div>
      </div>
      <div
        className={`${styles.formula} ${
          values.project &&
          (!formula?.value?.formula ||
            !aregisterAppcreateStore.formulasDict.items?.[
              formula.value?.formula
            ]?.full_title)
            ? styles.formula_yellow
            : ""
        } ${
          values.project &&
          formula?.value?.formula &&
          aregisterAppcreateStore.formulasDict.items?.[formula?.value?.formula]
            ?.full_title
            ? styles.formula_green
            : ""
        } 
        ${checkSizeErrors(formula) ? styles.formula_red : ""}`}
      >
        <div className={styles.formula__heading}>
          <IconFormula />
          {values.project
            ? checkSizeErrors(formula)
              ? "Значения нарушают ограничения"
              : formula?.value?.formula &&
                aregisterAppcreateStore.formulasDict.items?.[
                  formula?.value?.formula
                ]?.full_title
              ? `Формула расчёта: ${
                  aregisterAppcreateStore.formulasDict.items[
                    formula?.value?.formula
                  ].full_title
                }`
              : "Не найдена формула расчёта. Значения будут перемножены."
            : "Формула расчёта: "}
        </div>
      </div>
    </div>
  );
};

export default observer(AregisterFormSize);
