// РАССКОММЕНТИРОВАТЬ И УДАЛИТЬ ПРАВИЛО ЛИНТА ПРИ РАБОТЕ С ТАБЛИЦЕЙ ФИНАНСОВ
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./staffOneInfoFinanceMain.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Tooltip from "shared/ui/Tooltip";
import { ReactComponent as IconOpen } from "shared/assets/images/mainIcons/iconOpen.svg";
import StaffOneInfoFinanceTable from "../StaffOneInfoFinanceTable";
import { useParams } from "react-router-dom";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { Button, ButtonTheme } from "shared/ui/Button";
import Alert from "shared/ui/Alert";
type StaffOneInfoFinanceMainProps = {
  setToggleTable: (arg: boolean) => void;
};

const StaffOneInfoFinanceMain = ({
  setToggleTable
}: StaffOneInfoFinanceMainProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  const limitations: string[] = [];

  staffOneStore.salary?.[id]?.table?.records &&
    Object.values(staffOneStore.salary[id].table.records)
      .slice(-6)
      .forEach((value) => {
        Object.keys(value["types"]).forEach((type) => {
          if (!limitations.includes(type)) {
            limitations.push(type);
          }
        });
      });

  const dictionaryTypeList = staffOneStore.salary?.[id]?.table?.type_list
    ? Object.keys(staffOneStore.salary[id].table.type_list)
        .map((el) => {
          return {
            id: el,
            title: staffOneStore.salary[id].table.type_list[el].title
          };
        })
        .filter((el) => {
          if (limitations.includes(el.id)) return el;
        })
    : [];

  return (
    <>
      {staffOneStore.salary?.[id]?.types &&
      Object.entries(staffOneStore.salary[id].types).length ? (
        <div className={styles.infoWrapper}>
          {Object.entries(staffOneStore.salary[id].types).map(
            ([key, value]) => {
              switch (key) {
                case "Удержания СИЗ":
                  return (
                    <div key={key} className={styles.sectionsWrapper}>
                      <div className={styles.leftSection}>{key}</div>
                      <div
                        className={`${styles.rightSection} ${styles.rightSection_withBtn}`}
                      >
                        <p>{numberWithSpaces(value)}</p>
                        <Tooltip
                          color="blue-lazure"
                          text="Раздел находится в разработке"
                        >
                          <Button theme={ButtonTheme.SECONDARY}>
                            Добавить компенсацию
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  );
                default:
                  return (
                    <div key={key} className={styles.sectionsWrapper}>
                      <div className={styles.leftSection}>{key}</div>
                      <div className={styles.rightSection}>
                        {numberWithSpaces(value)}
                      </div>
                    </div>
                  );
              }
            }
          )}
        </div>
      ) : (
        <Alert errors={{ head: "Нет сведений о финансах", color: "empty" }} />
      )}

      {
        // РАССКОММЕНТИРОВАТЬ ППРИ РАБОТЕ С ТАБЛИЦЕЙ ФИНАНСОВ
        /* {staffOneStore.salary[id]?.table?.records &&
      Object.values(staffOneStore.salary[id].table.records).length ? (
        <div className="position-relative">
          <IconOpen
            id="StaffOneInfoFinanceMain_openDetailedTable"
            className={styles.icon}
            onClick={() => {
              setToggleTable(true);
            }}
          />

          <StaffOneInfoFinanceTable
            monthList={Object.keys(staffOneStore.salary[id].table.records).slice(
              -6
            )}
            dictionaryTypeList={dictionaryTypeList}
          />
        </div>
      ) : (
        ""
      )} */
      }
    </>
  );
};

export default observer(StaffOneInfoFinanceMain);
