import styles from "./buildingOneWorkshiftFilter.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import BuildingOneWorkshiftFilterCount from "./BuildingOneWorkshiftFilterCount";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

const BuildingOneWorkshiftFilter = () => {
  const { buildingOneWorkshiftStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    buildingOneWorkshiftStore.setSelectedStaffGroupFilter(
      buildingOneWorkshiftStore.filters[id] &&
        buildingOneWorkshiftStore.selectedFilters[id],
      id
    );
  }, [id, buildingOneWorkshiftStore.filters[id]]);

  const hasSearchValue = buildingOneWorkshiftStore.searchValue[id]?.length > 0;
  const isNothingWasFound = (filter: string) =>
    // если в найденных всего 1 "сотрудник" - это строка масс.редактирования, в поиске не участвует
    getValues(buildingOneWorkshiftStore.foundedStaffList[id]?.[filter] || {})
      .length === 1 ||
    !buildingOneWorkshiftStore.foundedStaffList[id]?.[filter];

  const handleClickFilterBtn = (filter: string) => () => {
    if (buildingOneWorkshiftStore.selectedFilters[id] !== filter) {
      buildingOneWorkshiftStore.setSelectedStaffGroupFilter(filter, id);
    }
  };

  return Object.values(buildingOneWorkshiftStore.filters[id] || {}).length ? (
    <>
      <div className={styles.filterHeader}>
        {getEntries(buildingOneWorkshiftStore.filters[id])
          // сортируем фильтры по алфавиту
          .sort((a, b) => (a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1))
          .map((btn) =>
            !(hasSearchValue && isNothingWasFound(btn[0])) ? (
              <Button
                theme={ButtonTheme.TAB}
                key={btn[0]}
                id={btn[0]}
                selected={
                  buildingOneWorkshiftStore.selectedFilters[id] === btn[0]
                }
                onClick={handleClickFilterBtn(btn[0])}
              >{`${btn[0]} (${
                !isEmpty(
                  buildingOneWorkshiftStore.foundedStaffList[id]?.[btn[0]] || {}
                )
                  ? getValues(
                      buildingOneWorkshiftStore.foundedStaffList[id]?.[btn[0]]
                    ).length - 1
                  : btn[1]
              })`}</Button>
            ) : null
          )}
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BuildingOneWorkshiftFilterCount />
      </ErrorBoundary>
    </>
  ) : null;
};

export default observer(BuildingOneWorkshiftFilter);
