import styles from "./staffOneEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import StaffOneEditForm from "features/StaffOneEditForm/index";

import StaffOneEditSetting from "./StaffOneEditSetting/index";

import PageTitle from "shared/ui/PageTitle";
import Submenu from "shared/ui/Submenu/index";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { isEmpty } from "lodash";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";

const StaffOneEdit = () => {
  const { menuStore, staffOneEditStore } = useStores();

  const { id, edit } = useParams();
  const [activeClass, setActiveClass] = useState<{
    data?: Partial<IRecordsForEdit>;
    sort?: number;
    icon?: string;
    title: string;
    id?: string;
  }>({ id: "", title: "" });
  //здесь хранится значение полей связанных с компанией до того, как были какие-либо манипуляции с компаниями
  const [previousValue, setPreviousValue] = useState<Partial<IRecordsForEdit>>(
    {}
  );

  const navigate = useNavigate();
  const navigateEdit = (objID: string) => {
    navigate(`../staff/id=${id}/edit=${objID}`);
  };

  useEffect(() => {
    staffOneEditStore.setSelectedOneForEdit(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffEdit");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/edit=${edit}`
    });
  }, [id]);

  useEffect(() => {
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/edit=${edit}`
    });
  }, [edit]);

  useEffect(() => {
    !isEmpty(staffOneEditStore.groups) &&
      setSelectedItem(
        edit === "appsettings"
          ? "appsettings"
          : staffOneEditStore.groups[edit]
          ? edit
          : getKeys(staffOneEditStore.groups)[0]
      );
  }, [
    getValues(staffOneEditStore.groups).length,
    staffOneEditStore.selectedOneForEdit,
    staffOneEditStore.selectedCompanyId[id],
    staffOneEditStore.selectedTypeOfJob[id]
  ]);

  const setSelectedItem = (objID: string) => {
    navigateEdit(objID);
    const object: Partial<IRecordsForEdit> = {};
    if (objID !== "appsettings") {
      getValues(staffOneEditStore.groups[objID]["columns"]).forEach((col) => {
        //хранение списка компаний после редактирования их списка
        const tempCompanies: IRecordsForEdit["company"] = {};

        // для булевых полей может прийти null, в object записываем number для Switch-поля
        if (staffOneEditStore.columns?.[col]?.type === "bool") {
          return (object[col] = staffOneEditStore.selectedOneForEdit[col]
            ? 1
            : 0);
        }
        switch (col) {
          case "company":
            //записываем текущий список компаний в виде объекта в tempCompanies на основе массива staffOneEditStore.currentCompanyList[id]
            staffOneEditStore.currentCompanyList[id]?.length &&
              staffOneEditStore.currentCompanyList[id].forEach((company) => {
                tempCompanies[company] =
                  staffOneEditStore.selectedOneForEdit[col][company];
              });
            //в зависисмости от того заполнен массив staffOneEditStore.currentCompanyList[id] или нет, мы записываем список компаний в object[col]
            // object дальше используется как initialValues в компоненте StaffOneEditForm
            object[col] = staffOneEditStore.currentCompanyList[id]?.length
              ? staffOneEditStore.currentCompanyList[id]
              : staffOneEditStore.selectedOneForEdit[col] &&
                getKeys(staffOneEditStore.selectedOneForEdit[col]);

            object["companies"] = !isEmpty(tempCompanies)
              ? tempCompanies
              : staffOneEditStore.selectedOneForEdit[col];
            break;
          default:
            object[col] = staffOneEditStore.selectedOneForEdit[col]
              ? staffOneEditStore.selectedOneForEdit[col]
              : null;
        }
      });
      // записываем previousValue, до тех пор пока не было манипуляций со списком компаний
      !staffOneEditStore.currentCompanyList[id]?.length &&
        setPreviousValue(object);
      setActiveClass({ ...staffOneEditStore.groups[objID], data: object });
    } else setActiveClass(staffOneEditStore.groups[objID]);
  };

  return (
    <LoadedComponent
      isLoading={staffOneEditStore.isLoading}
      error={staffOneEditStore.error}
      errorMessage={staffOneEditStore.errorText[id]}
    >
      <>
        <PageTitle title="Редактирование сотрудника" leftCol />
        <div className={styles.main}>
          <div className={styles.menu}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Submenu
                title="Что хотите редактировать?"
                array={getValues(staffOneEditStore.groups)}
                arrayItemId="id"
                arrayItemTitle="title"
                selectedItem={activeClass}
                setSelectedItem={setSelectedItem}
                funcForItem={() => staffOneEditStore.setErrorsMessage(id, {})}
                withIcons
              />
            </ErrorBoundary>
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[edit]}>
            {edit === "appsettings" ? (
              <StaffOneEditSetting />
            ) : (
              <StaffOneEditForm
                cols={getValues(activeClass["columns"])}
                data={activeClass["data"] ? activeClass["data"] : {}}
                previousValue={previousValue}
                setSelectedItem={setSelectedItem}
              />
            )}
          </ErrorBoundary>
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneEdit);
