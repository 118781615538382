import { ReactComponent as IconLock } from "shared/assets/images/mainIcons/iconLock.svg";
import { ReactComponent as IconLockOpen } from "shared/assets/images/mainIcons/iconLockOpen.svg";
import { ReactComponent as IconCancel } from "shared/assets/images/mainIcons/iconCancel.svg";

export const statusParams = {
  close: {
    icon: <IconLock />,
    text: "Закрыть",
    savedPanelTitle: "Выбор заявок для закрытия",
    savedPanelBtnTitle: "Закрыть заявки",
    changeTitle: "Открыть повторно",
    changeIcon: <IconLockOpen />,
    canCancelled: true
  },
  cancel: {
    icon: <IconCancel />,
    text: "Аннулировать",
    savedPanelTitle: "Выбор заявок для аннулирования",
    savedPanelBtnTitle: "Аннулировать заявки",
    changeTitle: "Открыть повторно",
    changeIcon: <IconLockOpen />
  },
  open: {
    icon: <IconLockOpen />,
    text: "Открыть",
    savedPanelTitle: "Выбор заявок для открытия",
    savedPanelBtnTitle: "Открыть заявки",
    changeTitle: "Закрыть",
    changeIcon: <IconLock />,
    canCancelled: true
  }
};
