import styles from "./staffOneInfoHistory.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import StaffOneInfoHistoryOne from "./StaffOneInfoHistoryOne";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import Alert from "shared/ui/Alert";

const StaffOneInfoHistory = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.history) && staffOneStore.getHistoryWidget(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_history[id]}
      withoutText
    >
      <>
        {staffOneStore.history[id] &&
        Object.keys(staffOneStore.history[id]).length ? (
          Object.entries(staffOneStore.history[id])
            .slice(0, 3)
            .map(([time, historyGroup], idx) => {
              const historyItems = Object.values(
                Object.values(historyGroup)[0]
              );

              return (
                <div
                  key={`${historyItems[0].user_name}_${time}`}
                  className={`${styles.historyOne} ${
                    idx === 0 ? styles.historyOne__first : ""
                  }`}
                >
                  <StaffOneInfoHistoryOne
                    time={time}
                    user_name={historyItems[0].user_name}
                    historyItems={Object.values(
                      Object.values(historyGroup)[0]
                    ).slice(0, 3)}
                  />
                </div>
              );
            })
        ) : (
          <Alert
            errors={{ head: "У сотрудника ещё нет истории", color: "empty" }}
          />
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoHistory);
