import styles from "./aregisterCard.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import MasterAndBrigadierField from "./MasterAndBrigadierField";
import StatusField from "./StatusField";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";
import { TextField, TextFieldTheme } from "shared/ui/TextField";
import ActHoursField from "./ActHoursField";
import MeasurmentsField from "./MeasurmentsField";
import PairAregisterField from "./PairAregisterField";

import {
  groups,
  FieldsGroups
} from "stores/AregisterModule/aregisterOne/aregisterOne";
import { getKeys } from "shared/utils/helpers/getKeys";
import { fileUrl } from "stores/utils/consts";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import CustomFields from "./CustomFields";
import { Button, ButtonTheme } from "shared/ui/Button";

const AregisterCard = () => {
  const { id } = useParams();
  const { aregisterOneStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");

  const navigate = useNavigate();

  const changeOpenedListName = (name: string) => {
    openedListName !== name ? setOpenedListName(name) : setOpenedListName("");
  };

  const getValue = (name: string) => {
    const value = aregisterOneStore.selectedOne[name]?.title
      ? aregisterOneStore.selectedOne[name]?.title
      : getFormattedDate(aregisterOneStore.selectedOne[name]);
    if (["timestamp", "date"].includes(aregisterOneStore.cols[name].type)) {
      value.replace(/ /g, ", в ");
    }

    return value;
  };

  const getElement = (name: string) => {
    switch (name) {
      case "master":
      case "brigadier":
        return (
          <MasterAndBrigadierField
            name={name}
            openedListName={openedListName}
            changeOpenedListName={changeOpenedListName}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      {aregisterOneStore.aregisterTitles.map((title) => {
        const col_title = aregisterOneStore.cols[title].title;
        let groups_name: FieldsGroups[number];
        getKeys(groups).map(
          (name) => groups[name].includes(title) && (groups_name = name)
        );

        if (["measurements", "size", ...groups.sizes].includes(title))
          return (
            <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
              <MeasurmentsField key={title} title={title} getValue={getValue} />
            </ErrorBoundary>
          );
        else if (groups_name && groups_name !== "sizes")
          return (
            <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
              <TextFieldGroup
                name={title}
                fields={groups[groups_name]}
                titles={aregisterOneStore.aregisterTitles}
                cols={aregisterOneStore.cols}
                getValue={getValue}
              />
            </ErrorBoundary>
          );
        else {
          switch (title) {
            case "internal_num":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={getValue}
                    className={{ row: styles.rowWithBtns }}
                  >
                    {() => (
                      <Button
                        theme={ButtonTheme.SECONDARY}
                        className={styles.btn}
                        onClick={() => navigate(`../report`)}
                        id="AregisterCard_goToReport"
                      >
                        <div>Перейти в отчёт мастера</div>
                      </Button>
                    )}
                  </TextFieldGroup>
                </ErrorBoundary>
              );

            case "project":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <div className={styles.row}>
                    <TextField
                      title={col_title}
                      theme={TextFieldTheme.LINK}
                      value={
                        // Раскомментировать и удалить ссылку ниже при доработке раздела Проекты
                        // <Link
                        //   to={`/projects/id=${aregisterOneStore.selectedOne.project.id}`}
                        //   id="AregisterOneInfoMain_goToProject"
                        //   target="_blank"
                        // >
                        //   {aregisterOneStore.selectedOne.project?.title}
                        // </Link>
                        <a
                          href={`${fileUrl}/crm/projects/?id=${aregisterOneStore.selectedOne.project.id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {aregisterOneStore.selectedOne.project?.title}
                        </a>
                      }
                    />
                  </div>
                </ErrorBoundary>
              );

            case "master":
            case "brigadier":
              return (title == "master" && aregisterOneStore.masters[id]) ||
                (title === "brigadier" && aregisterOneStore.brigadiers[id]) ? (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={(field: string) => getElement(field)}
                  />
                </ErrorBoundary>
              ) : null;

            case "status":
              return (
                <ErrorBoundary FallbackComponent={ErrorFallback} key={title}>
                  <StatusField
                    name={title}
                    title={col_title}
                    openedListName={openedListName}
                    changeOpenedListName={changeOpenedListName}
                  />
                </ErrorBoundary>
              );

            case "act_hours":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <ActHoursField />
                </ErrorBoundary>
              );

            case "conditional_cubes":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={getValue}
                  />
                </ErrorBoundary>
              );

            case "kc":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={() =>
                      aregisterOneStore.selectedOne.no_kc
                        ? aregisterOneStore.cols.no_kc.title
                        : aregisterOneStore.selectedOne[title]
                    }
                  />
                </ErrorBoundary>
              );
            case "cancellation_type":
              if (!aregisterOneStore.selectedOne.status.cancelled) return;
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={getValue}
                  />
                </ErrorBoundary>
              );

            case "pair":
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <PairAregisterField
                    title={title}
                    openedListName={openedListName}
                    changeOpenedListName={changeOpenedListName}
                    getValue={getValue}
                  />
                </ErrorBoundary>
              );
            case "no_kc":
            case "masterless":
            case "custom_fields":
              return;
            default:
              return (
                <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
                  <TextFieldGroup
                    name={title}
                    fields={[title]}
                    titles={aregisterOneStore.aregisterTitles}
                    cols={aregisterOneStore.cols}
                    getValue={getValue}
                  />
                </ErrorBoundary>
              );
          }
        }
      })}

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CustomFields />
      </ErrorBoundary>
    </div>
  );
};

export default observer(AregisterCard);
