import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import styles from "./staffOneSafetyWorkCommentsTable.module.scss";
import { Table } from "react-bootstrap";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import Alert from "shared/ui/Alert";

const titlesTable = ["event_date", "comment"];

const StaffOneSafetyWorkCommentsTable = () => {
  const { staffOneSafetyWorkStore } = useStores();

  const cols: string[] = [];
  for (const prop in staffOneSafetyWorkStore.certsColsEvents) {
    cols[prop] = staffOneSafetyWorkStore.certsColsEvents[prop]["title"];
  }

  return (
    <div className={styles.tableMain}>
      {Object.keys(staffOneSafetyWorkStore.comments).length ? (
        <Table>
          <thead>
            <tr className={styles.tableHeader}>
              {titlesTable.map((title) => {
                return (
                  cols && (
                    <th key={title} className={styles.title}>
                      {cols[title]}
                    </th>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {Object.values(staffOneSafetyWorkStore.comments).map(
              (commentItem) => {
                return (
                  <tr
                    key={commentItem.time_create}
                    className={styles.trComment}
                  >
                    <td>{getFormattedDate(commentItem.event_date)}</td>
                    <td>{commentItem.comment}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      ) : (
        <Alert
          errors={{
            head: "У сотрудника отсутствуют комментарии",
            color: "empty"
          }}
        />
      )}
    </div>
  );
};

export default observer(StaffOneSafetyWorkCommentsTable);
