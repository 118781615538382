import { classNames } from "shared/utils/helpers/classNames";
import styles from "./collapsibleButton.module.scss";

type CollapsibleButtonProps = {
  onClick: () => void;
  id?: string;
  openedAllWidgets: number | boolean;
  className?: { container?: string };
};

const CollapsibleButton = ({
  onClick,
  id,
  openedAllWidgets,
  className
}: CollapsibleButtonProps) => {
  return (
    <div
      id={`CollapsibleButton_openedAllWidgets_${id}`}
      className={classNames(
        styles.button,
        {
          [styles.buttonActive]: openedAllWidgets
        },
        [className?.container]
      )}
      onClick={onClick}
    >
      {openedAllWidgets ? "Свернуть всё" : "Развернуть всё"}
    </div>
  );
};

export default CollapsibleButton;
