import styles from "./staffOneConviction.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import StaffOneConvictionTable from "./StaffOneConvictionTable";
import StaffOneConvictionWindow from "./StaffOneConvictionWindow";
import StatusIcon from "shared/ui/StatusIcon";

import { Conviction } from "stores/StaffModule/types/Conviction";
import { fileUrl } from "stores/utils/consts";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

const StaffOneConviction = () => {
  const { staffOneConvictionStore, menuStore } = useStores();
  const { id } = useParams();

  const [openWindow, setOpenWindow] = useState({ type: "", open: false });
  const [selectedConviction, setSelectedConviction] = useState<
    Partial<Conviction>
  >({});

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneConvictionStore.setSelectedOneForConviction(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffConviction");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/conviction`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneConvictionStore.isLoading}
      error={staffOneConvictionStore.error}
    >
      <>
        <PageTitle title="Судимости" leftCol />
        <div className={styles.container}>
          <div className="d-flex justify-content-between">
            <Button
              className={styles.openFormButton}
              theme={ButtonTheme.CLEAR}
              onClick={() => setOpenWindow({ type: "add", open: true })}
              id="StaffOneConviction_openWindow"
            >
              Добавить
              <IconAdd />
            </Button>
            <a
              className={styles.link}
              rel="noreferrer"
              target="_blank"
              href={`${fileUrl}/crm/conviction/?filter[uid][]=${id}`}
            >
              <div className={styles.historyBtn}>
                <p>История</p>
                <StatusIcon icon="iconhistory" color="blue-lazure" />
              </div>
            </a>
          </div>
          {openWindow.open ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOneConvictionWindow
                id={id}
                type={openWindow.type}
                setOpenWindow={setOpenWindow}
                activeRow={selectedConviction}
                setActiveRow={setSelectedConviction}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOneConvictionTable
              setSelectedConviction={(conviction) => {
                if (!openWindow.open) {
                  setSelectedConviction(conviction);
                  setOpenWindow({ type: "detail", open: true });
                }
              }}
            />
          </ErrorBoundary>
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneConviction);
