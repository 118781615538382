import styles from "./staffOneSafetyWorkComments.module.scss";
import StaffOneSafetyWorkCommentsInput from "./StaffOneSafetyWorkCommentsInput";
import StaffOneSafetyWorkCommentsTable from "./StaffOneSafetyWorkCommentsTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffOneSafetyWorkComments = () => {
  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentHeader}>Комментарии</div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneSafetyWorkCommentsInput />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneSafetyWorkCommentsTable />
      </ErrorBoundary>
    </div>
  );
};

export default StaffOneSafetyWorkComments;
