import styles from "./staffOneEditFormPhone.module.scss";
import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import { Input } from "shared/ui/Inputs/Input";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import Checkbox from "shared/ui/Inputs/Checkbox";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import Modal from "shared/ui/Modal";
import { ViewField } from "shared/ui/ViewField";
import { useParams } from "react-router-dom";
import { diff } from "deep-object-diff";
import { getValues } from "shared/utils/helpers/getValues";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { getEntries } from "shared/utils/helpers/getEntries";

type PhoneType = {
  number?: number | string | null;
  comment?: string | null;
  confirm?: number | null;
};

type ModalAction = "delete" | "cancelAdd" | "editCancel" | null;

type StaffOneEditFormPhoneProps = {
  disabled: boolean;
};

const StaffOneEditFormPhone = ({ disabled }: StaffOneEditFormPhoneProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams<{ id: string }>();

  const [selectedPhone, setSelectedPhone] = useState<number | null>(null);
  const [newPhoneIndex, setNewPhoneIndex] = useState<number | null>(null);
  const [isAddPhone, setIsAddPhone] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<ModalAction>(null);
  const [modalInfo, setModalInfo] = useState<{
    action: ModalAction;
    idx: number | null;
  }>({
    action: null,
    idx: null
  });

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    dirty,
    values,
    initialValues,
    isValid
  } = useFormikContext<IRecordsForEdit>();

  useEffect(() => {
    if (!dirty && isAddPhone) {
      setIsAddPhone(false);
    }
  }, [dirty, isAddPhone]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedPhone !== null) {
        setFieldTouched(`phone.${selectedPhone}.number`, isAddPhone);
      }
    }, 200);
  }, [isAddPhone, setFieldTouched, selectedPhone]);

  const newContact = (action: string) => {
    const phones: PhoneType[] = getValues(values["phone"]) || [];

    switch (action) {
      case "addPhone":
        phones.push({
          number: "",
          comment: "",
          confirm: 0
        });
        setNewPhoneIndex(phones.length - 1);
        setSelectedPhone(phones.length - 1);
        setEmpty(false);
        break;
      case "cancelAddPhone":
        phones.pop();
        break;
      default:
        break;
    }

    setFieldValue("phone", phones);
  };

  const editPhone = async ({
    action,
    idx
  }: {
    action: string;
    idx: number;
  }) => {
    const phones: PhoneType[] = getValues(values["phone"]) || [];
    const initialPhone: PhoneType = initialValues["phone"]?.[idx] || {};
    const currentPhone: PhoneType = phones[idx];
    const diffResult = diff(initialPhone, currentPhone) as Partial<PhoneType>;
    const changes = getEntries(diffResult);

    switch (action) {
      case "submit":
        if (changes.length > 0) {
          await Promise.all(
            changes.map(([fieldName, value]) => {
              if (fieldName === "number" || fieldName === "comment") {
                return staffOneEditStore.editPhone(
                  "phone",
                  id,
                  idx,
                  fieldName,
                  value
                );
              }
              return Promise.resolve();
            })
          );
        }
        setSelectedPhone(null);
        break;
      case "edit":
        setFieldValue("phone", initialValues["phone"]);
        setSelectedPhone(idx);
        break;
      case "delete":
        setShowModal("delete");
        setModalInfo({ action: "delete", idx });
        break;
      case "cancel":
        if (changes.length > 0) {
          setShowModal("editCancel");
          setModalInfo({ action: "editCancel", idx });
        } else {
          setSelectedPhone(null);
        }
        break;
      default:
        break;
    }
  };

  const addPhone = async (action: string) => {
    const phones: PhoneType[] = getValues(values["phone"]) || [];
    const newPhone = phones[phones.length - 1];

    if (action === "submit") {
      if (newPhone.number) {
        await staffOneEditStore.addPhone(id, newPhone.number, newPhone.comment);
        setIsAddPhone(false);
        setSelectedPhone(null);
      }
    } else if (action === "cancel") {
      if (newPhone.number || newPhone.comment) {
        setShowModal("cancelAdd");
        setModalInfo({ action: "cancelAdd", idx: newPhoneIndex });
      } else {
        newContact("cancelAddPhone");
        setIsAddPhone(false);
        setSelectedPhone(null);
      }
    }
  };

  const handleModalConfirm = async () => {
    if (showModal === "delete" && modalInfo.idx !== null) {
      await staffOneEditStore.deletePhone(id, modalInfo.idx);
    } else if (showModal === "editCancel" && modalInfo.idx !== null) {
      setFieldValue(
        `phone.${modalInfo.idx}`,
        initialValues.phone[modalInfo.idx]
      );
      setSelectedPhone(null);
    } else if (showModal === "cancelAdd" && newPhoneIndex !== null) {
      newContact("cancelAddPhone");
      setIsAddPhone(false);
      setSelectedPhone(null);
    }
    setShowModal(null);
    setModalInfo({ action: null, idx: null });
  };

  const handleModalClose = () => {
    setShowModal(null);
    setModalInfo({ action: null, idx: null });
  };

  const handleModalSave = async () => {
    if (showModal === "editCancel" && modalInfo.idx !== null) {
      await editPhone({ action: "submit", idx: modalInfo.idx });
    } else if (showModal === "cancelAdd" && newPhoneIndex !== null) {
      await addPhone("submit");
    }
    setShowModal(null);
    setModalInfo({ action: null, idx: null });
  };

  return !staffOneEditStore.isLoadingPhone ? (
    <div className={`${styles.form} ${disabled ? styles.disabled : ""}`}>
      <>
        <div className={styles.subtitle}>Телефон</div>
        <div className={styles.containerForm}>
          {getValues(values["phone"] || {}).map((contact, i) => {
            const order = i + 1;

            return (
              <ul
                key={`contact_${i}`}
                className={
                  isAddPhone && i === newPhoneIndex
                    ? `${styles.confirmRowAddPhone} ${styles.confirmRowAddPhone_selected}`
                    : selectedPhone === i
                    ? `${styles.confirmRowPhone} ${styles.confirmRowPhone_selected}`
                    : styles.confirmRowPhone
                }
              >
                {(isAddPhone && i === newPhoneIndex) || isEmpty ? null : (
                  <li>
                    <label
                      htmlFor={`phone[${i}].confirm`}
                      className={styles.confirmLabel}
                    >
                      Подтвержд.
                    </label>
                    <Checkbox name={`phone.${i}.confirm`} disabled />
                  </li>
                )}
                <li>
                  <ViewField
                    title="№"
                    value={contact["order"] || order}
                    className={{ container: styles.numBorder }}
                  />
                </li>
                <li>
                  <PhoneInput
                    name={`phone.${i}.number`}
                    label={staffOneEditStore.columns.phone?.title}
                    notEditable={selectedPhone !== i}
                    required={selectedPhone === i}
                  />
                </li>
                <li>
                  <Input
                    name={`phone.${i}.comment`}
                    label={staffOneEditStore.columns.comment?.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={selectedPhone !== i}
                  />
                </li>
                <li>
                  {selectedPhone === i ||
                  (isAddPhone && i === newPhoneIndex) ? (
                    <ButtonsGroupForEdit
                      id={`contact_${i}`}
                      idx={i}
                      btns={[
                        {
                          action: "submit",
                          type: "button",
                          icon: "bigcheck",
                          color:
                            errors.phone?.[i] || !dirty
                              ? "bw-gray3"
                              : "blue-lazure",
                          disabled: Boolean(errors.phone?.[i]) || !dirty
                        },
                        {
                          action: "cancel",
                          type: "button",
                          icon: "iconclose",
                          color: "blue-lazure",
                          disabled: false
                        },
                        {
                          action: "delete",
                          type: "button",
                          icon: "iconbasket",
                          color: "bw-gray5",
                          disabled: false
                        }
                      ]}
                      onClick={(action) => {
                        if (isAddPhone && i === newPhoneIndex) {
                          addPhone(action);
                        } else {
                          editPhone({ action, idx: i });
                        }
                      }}
                    />
                  ) : (
                    <ButtonsGroupForEdit
                      id={`contact_${i}`}
                      idx={i}
                      btns={[
                        {
                          action: "edit",
                          type: "button",
                          icon: "iconedit",
                          color: "bw-gray5",
                          disabled: false
                        },
                        {
                          action: "delete",
                          type: "button",
                          icon: "iconbasket",
                          color: "bw-gray5",
                          disabled: false
                        }
                      ]}
                      onClick={(action) => {
                        if (
                          isAddPhone &&
                          (action === "edit" || action === "delete")
                        ) {
                          setShowModal("cancelAdd");
                          setModalInfo({
                            action: "cancelAdd",
                            idx: newPhoneIndex
                          });
                        } else {
                          editPhone({ action, idx: i });
                        }
                      }}
                    />
                  )}
                </li>
              </ul>
            );
          })}
          {!isAddPhone && !isEmpty ? (
            <button
              id="StaffOneEditContacts_addPhoneFormButton"
              type="button"
              className={styles.buttonAddNumber}
              onClick={() => {
                setIsAddPhone(true);
                newContact("addPhone");
              }}
            >
              <p className={styles.buttonAddNumber__text}>Добавить</p>
              <img
                src={addIcon}
                className={styles.buttonAddNumber__icon}
                alt="Добавить"
              />
            </button>
          ) : null}
        </div>
      </>

      {showModal && (
        <Modal
          type={
            showModal === "delete"
              ? "clarification"
              : "clarificationForFormWindows"
          }
          show
          onHide={handleModalClose}
          title={
            showModal === "delete"
              ? "Вы уверены, что хотите удалить номер телефона?"
              : "Внесены изменения."
          }
          subtitle={
            showModal === "editCancel" || showModal === "cancelAdd"
              ? "Вы уверены, что хотите закрыть без сохранения изменений?"
              : undefined
          }
          btnWithCrossTitle={
            showModal === "delete" ? "Удалить" : "Закрыть без сохранения"
          }
          btnWithCrossOnClick={handleModalConfirm}
          blueBtnTitle={
            showModal === "delete" ? "Отмена" : "Сохранить и закрыть"
          }
          blueBtnOnClick={
            showModal === "delete"
              ? handleModalClose
              : isValid
              ? handleModalSave
              : undefined
          }
          blueBtnDisabled={showModal === "delete" ? undefined : !isValid}
          greyBtnTitle={showModal === "delete" ? undefined : "Отмена"}
          greyBtnOnClick={showModal === "delete" ? undefined : handleModalClose}
          btnWithCrossImg
        />
      )}
    </div>
  ) : (
    <p className={styles.loading}>Пожалуйста, подождите...</p>
  );
};

export default observer(StaffOneEditFormPhone);
