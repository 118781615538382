import styles from "./refreshPanel.module.scss";
import { useState, useEffect } from "react";

import iconUpdateSmall from "shared/assets/images/mainIcons/iconUpdate/iconUpdate.svg";
import iconUpdateBig from "shared/assets/images/mainIcons/iconUpdate/iconUpdateWithBackground.svg";
import { Button, ButtonTheme } from "shared/ui/Button";

const RefreshPanel = () => {
  const [showUpdateWindow, setShowUpdateWindow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowUpdateWindow(true);
    }, 5000);
  }, []);

  return showUpdateWindow ? (
    <div className={styles.updateWindow}>
      <img src={iconUpdateBig} alt="update_icon" />
      <div className={styles.updateWindowText}>
        В заявках из списка произошли изменения, необходимо обновить страницу.
      </div>
      <Button
        id="RefreshPanel_btnUpdate"
        onClick={() => setShowUpdateWindow(false)}
        theme={ButtonTheme.CLEAR}
      >
        Обновить
        <img src={iconUpdateSmall} alt="Update" />
      </Button>
    </div>
  ) : null;
};

export default RefreshPanel;
