import styles from "./menu.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";

import AllEmployees from "shared/assets/images/menuIcons/iconDefault/AllEmployees.svg";
import AllEmployeesSelected from "shared/assets/images/menuIcons/iconSelected/AllEmployees.svg";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import BuildingsSelected from "shared/assets/images/menuIcons/iconSelected/Buildings.svg";

import StaffSection from "./StaffSection";
import StaffSectionOne from "./StaffSectionOne";

import icon from "shared/assets/images/mainIcons/iconMenu.svg";
import BuildingSection from "./BuildingSection";
import BuildingSectionOne from "./BuildingSectionOne";

import ProjectsSection from "./ProjectsSection";
import ProjectsSectionOne from "./ProjectsSectionOne";

import FriendInvite from "./FriendInviteSection";
import FriendInviteSectionOne from "./FriendInviteSectionOne";

import PolatiAppSection from "./PolatiAppSection";

import WorkshiftSectionOne from "./WorkshiftSectionOne";

import AregisterSection from "./AregisterSection";
import AregisterSectionOne from "./AregisterSectionOne";

import SalarySection from "./SalarySection";
import SalarySectionOneOperation from "./SalarySectionOneOperation";
import SalarySectionOnePremium from "./SalarySectionOnePremium";
import SalarySectionOnePaymentlist from "./SalarySectionOnePaymentlist";

import SafetyWorkSection from "./SafetyWorkSection";

import Tooltip from "shared/ui/Tooltip";
import { maxHeight } from "shared/utils/constants/maxHeight";

const Menu = () => {
  const { menuStore, userStore } = useStores();
  const { openedModule, allModules, toggleIsMenuShown, isMenuShown } =
    menuStore;

  useEffect(() => {
    if (window.outerWidth <= 1300) {
      toggleIsMenuShown(false);
    } else if (
      localStorage.getItem("menuShown") &&
      JSON.parse(localStorage.getItem("menuShown"))[userStore.user_id] &&
      openedModule in
        JSON.parse(localStorage.getItem("menuShown"))[userStore.user_id]
    ) {
      toggleIsMenuShown(
        Boolean(
          JSON.parse(localStorage.getItem("menuShown"))[userStore.user_id][
            openedModule
          ]
        )
      );
    } else {
      toggleIsMenuShown(true);
    }
  }, [openedModule]);

  useEffect(() => {
    if (openedModule) {
      const allUsersWidgetsFromStorage = localStorage.getItem("menuShown")
        ? JSON.parse(localStorage.getItem("menuShown"))
        : null;

      if (allUsersWidgetsFromStorage) {
        if (allUsersWidgetsFromStorage[userStore.user_id]) {
          allUsersWidgetsFromStorage[userStore.user_id][openedModule] =
            isMenuShown;
        } else {
          allUsersWidgetsFromStorage[userStore.user_id] = {};
          allUsersWidgetsFromStorage[userStore.user_id][openedModule] =
            isMenuShown;
        }

        localStorage.setItem(
          "menuShown",
          JSON.stringify(allUsersWidgetsFromStorage)
        );
      } else {
        localStorage.setItem(
          "menuShown",
          JSON.stringify({
            [userStore.user_id]: { [openedModule]: isMenuShown }
          })
        );
      }
    }
  }, [isMenuShown, openedModule]);

  const handleIsMenuShown = () => {
    isMenuShown ? toggleIsMenuShown(false) : toggleIsMenuShown(true);
  };

  const inviteFriend = [
    {
      text: "Приведи друга",
      to: "/friendinvite/",
      moduleName: "friendinvite",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const staff = [
    {
      text: "Сотрудники",
      to: "/staff/",
      moduleName: "staff",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const buildings = [
    {
      text: "Объекты",
      to: "/building/",
      moduleName: "building",
      submoduleName: "main",
      defaultIcon: Buildings,
      selectedIcon: BuildingsSelected
    }
  ];

  const projects = [
    {
      text: "Проекты",
      to: "/projects/",
      moduleName: "projects",
      submoduleName: "main",
      defaultIcon: Buildings,
      selectedIcon: BuildingsSelected
    }
  ];

  const polatiapp = [
    {
      text: "Приложение ПОЛАТИ",
      to: "/polatiapp/",
      moduleName: "polatiapp",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const aregister = [
    {
      text: "Реестр заявок строительных лесов",
      to: "/aregister/",
      moduleName: "aregister",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const salary = [
    {
      text: "Финансы",
      to: "/salary/",
      moduleName: "salary",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const safetywork = [
    {
      text: "Охрана труда",
      to: "/safetywork/",
      moduleName: "safetywork",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    }
  ];

  const openedAllModules = (
    <>
      <MenuSection sectionTitle="По сотрудникам">
        {staff.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Приведи друга">
        {inviteFriend.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <Tooltip
        placement="right-start"
        color="blue-lazure"
        text="Раздел находится в разработке"
      >
        <div>
          <MenuSection sectionTitle="Объекты">
            {buildings.map((item) => (
              <MenuButton
                key={item["text"]}
                to={item["to"]}
                text={item["text"]}
                defaultIcon={item["defaultIcon"]}
                selectedIcon={item["selectedIcon"]}
                moduleName={item["moduleName"]}
                submoduleName={item["submoduleName"]}
              />
            ))}
          </MenuSection>
        </div>
      </Tooltip>

      <Tooltip
        placement="right-start"
        color="blue-lazure"
        text="Раздел находится в разработке"
      >
        <div>
          <MenuSection sectionTitle="Проекты">
            {projects.map((item) => (
              <MenuButton
                key={item["text"]}
                to={item["to"]}
                text={item["text"]}
                defaultIcon={item["defaultIcon"]}
                selectedIcon={item["selectedIcon"]}
                moduleName={item["moduleName"]}
                submoduleName={item["submoduleName"]}
              />
            ))}
          </MenuSection>
        </div>
      </Tooltip>
      <Tooltip
        placement="right-start"
        color="blue-lazure"
        text="Раздел находится в разработке"
      >
        <div>
          <MenuSection sectionTitle="Приложение ПОЛАТИ">
            {polatiapp.map((item) => (
              <MenuButton
                key={item["text"]}
                to={item["to"]}
                text={item["text"]}
                defaultIcon={item["defaultIcon"]}
                selectedIcon={item["selectedIcon"]}
                moduleName={item["moduleName"]}
                submoduleName={item["submoduleName"]}
                disabled
              />
            ))}
          </MenuSection>{" "}
        </div>
      </Tooltip>
      <Tooltip
        placement="right-start"
        color="blue-lazure"
        text="Раздел находится в разработке"
      >
        <div>
          <MenuSection sectionTitle="Реестр заявок строительных лесов">
            {aregister.map((item) => (
              <MenuButton
                key={item["text"]}
                to={item["to"]}
                text={item["text"]}
                defaultIcon={item["defaultIcon"]}
                selectedIcon={item["selectedIcon"]}
                moduleName={item["moduleName"]}
                submoduleName={item["submoduleName"]}
              />
            ))}
          </MenuSection>
        </div>
      </Tooltip>
      <Tooltip
        placement="right-start"
        color="blue-lazure"
        text="Раздел находится в разработке"
      >
        <MenuSection sectionTitle="Финансы">
          {salary.map((item) => (
            <MenuButton
              key={item["text"]}
              to={item["to"]}
              text={item["text"]}
              defaultIcon={item["defaultIcon"]}
              selectedIcon={item["selectedIcon"]}
              moduleName={item["moduleName"]}
              submoduleName={item["submoduleName"]}
              disabled
            />
          ))}
        </MenuSection>
      </Tooltip>
      <MenuSection sectionTitle="Охрана труда">
        {safetywork.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );

  return (
    <div
      className={`${styles.menu} ${!isMenuShown ? styles.menu__collapsed : ""}`}
    >
      <div className={styles.buttonBlock}>
        <img
          src={icon}
          alt="Show"
          className={styles.button}
          onClick={handleIsMenuShown}
          id="Menu_show"
        />
        {isMenuShown ? <p>Свернуть меню</p> : ""}
      </div>
      <Scrollbars
        style={{
          width: "100%",
          height: `calc(${maxHeight} - 140px)`
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <div className={styles.menuSections}>
          {allModules
            ? openedAllModules
            : (() => {
                switch (openedModule) {
                  case "building":
                    return <BuildingSection />;
                  case "buildingOne":
                    return <BuildingSectionOne />;
                  case "projects":
                    return <ProjectsSection />;
                  case "projectsOne":
                    return <ProjectsSectionOne />;
                  case "staff":
                    return <StaffSection />;
                  case "oneOfStaff":
                    return <StaffSectionOne />;
                  case "friendInvite":
                    return <FriendInvite />;
                  case "friendInviteOne":
                    return <FriendInviteSectionOne />;
                  case "polatiapp":
                    return <PolatiAppSection />;
                  case "aregister":
                    return <AregisterSection />;
                  case "aregisterOne":
                    return <AregisterSectionOne />;
                  case "workshiftOne":
                    return <WorkshiftSectionOne />;
                  case "salary":
                    return <SalarySection />;
                  case "salaryOne":
                    return <SalarySectionOneOperation />;
                  case "premiumOne":
                    return <SalarySectionOnePremium />;
                  case "paymentlistOne":
                    return <SalarySectionOnePaymentlist />;
                  case "safetywork":
                    return <SafetyWorkSection />;
                }
              })()}
        </div>
      </Scrollbars>
    </div>
  );
};

export default observer(Menu);
