import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores/index";

import LoadedComponent from "widgets/LoadedComponent";
import AregisterForm from "features/AregisterForm";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const AregisterAllAppcreate = () => {
  const { menuStore, aregisterAppcreateStore } = useStores();

  useEffect(() => {
    menuStore.setOpenedModule("aregister");
    menuStore.setOpenedSubmodule("appcreate");

    if (!Object.values(menuStore.allWindows).length) {
      menuStore.addWindow("/aregister", "Реестр заявок строительных лесов");
    }

    menuStore.updateWindow({
      mainPath: `/aregister`,
      path: `/aregister/appcreate`
    });
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent
        error={aregisterAppcreateStore.error}
        isLoading={aregisterAppcreateStore.isLoading}
      >
        <AregisterForm type="add" />
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(AregisterAllAppcreate);
