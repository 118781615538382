import Checkbox from "shared/ui/Inputs/Checkbox";
import { StatusType } from "stores/StaffModule/staffStatus";

type CheckListProps = {
  handleChange: (event: React.ChangeEvent<Element>) => void;
  title: string;
  name: string;
  list: StatusType[];
};

const CheckList = ({
  handleChange,
  title,
  name,
  list
}: CheckListProps) => {
  return (
    <section>
      <p>{title}</p>

      <ul>
        {list.map((status) => {
          return (
            <li key={status["id"]}>
              <label className="d-flex align-items-center gap-2">
                <Checkbox
                  name={`custom.${name}`}
                  value={status["id"]}
                  onChange={handleChange}
                />
                {status["title"]}
              </label>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default CheckList;
