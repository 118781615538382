import styles from "./projectsOneInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";

import iconCheck from "shared/assets/images/iconStatus/BigCheck.svg";
import iconDash from "shared/assets/images/iconStatus/Dash.svg";

import ProjectsOneInfoStaffTable from "./ProjectsOneInfoStaffTable";
import ProjectsOneInfoPhoto from "./ProjectsOneInfoPhoto";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { isEmpty } from "lodash";

const ProjectOneInfo = () => {
  const { id } = useParams();
  const { menuStore, projectsOneStore } = useStores();

  useEffect(() => {
    projectsOneStore.setSelectedOneOfProject(id);

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("projectsOne");
    menuStore.setOpenedSubmodule("projectsOne");
    menuStore.updateWindow({
      mainPath: `/projects/id=${id}`,
      path: `/projects/id=${id}`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={projectsOneStore.isLoading}
      error={projectsOneStore.error}
    >
      {!isEmpty(projectsOneStore.selectedOneProject) &&
      !isEmpty(projectsOneStore.projectsTableCols) ? (
        <div className={styles.page}>
          <p className={styles.page__title}>
            {projectsOneStore.selectedOneProject.title}
          </p>

          <div className={styles.body}>
            <div>
              {getEntries(projectsOneStore.projectsTableCols).map(
                ([name, field]) => {
                  if (
                    projectsOneStore.projectsTableParams[name]?.access_show &&
                    !field.hidden
                  ) {
                    switch (name) {
                      case "type_work":
                      case "scaffold_type":
                      case "scaffold_species":
                      case "sub_work":
                      case "cancellation_types":
                      case "company":
                      case "users":
                      case "coordinates":
                        return (
                          <div className={styles.row} key={name}>
                            <div className={styles.field}>
                              <p className={styles.title}>{field.title}</p>
                              <p className={styles.value}>
                                {projectsOneStore.selectedOneProject[name] &&
                                projectsOneStore.selectedOneProject[name] !==
                                  "NULL" ? (
                                  <span className={styles.chips}>
                                    {getValues(
                                      projectsOneStore.selectedOneProject[
                                        name
                                      ] as Record<string, string>
                                    ).map((value) => {
                                      return (
                                        <>
                                          <Badge
                                            key={value}
                                            text={
                                              projectsOneStore.selects[name]?.[
                                                value
                                              ]
                                                ? projectsOneStore.selects[
                                                    name
                                                  ][value]?.title
                                                : value
                                            }
                                            colorName={
                                              name === "company"
                                                ? "green"
                                                : name === "users"
                                                ? "blue"
                                                : name === "coordinates"
                                                ? "purple"
                                                : "blue-dark-blue"
                                            }
                                          />
                                        </>
                                      );
                                    })}
                                  </span>
                                ) : (
                                  <br />
                                )}
                              </p>
                              {name === "scaffold_type" ? (
                                <div className={styles.scaffoldRange}>
                                  <p className={styles.scaffoldRange__label}>
                                    Считается в стартовом диапазоне высоты:
                                  </p>
                                  <p className={styles.chips}>
                                    {projectsOneStore.selectedOneProject
                                      .scaffold_type_start_range &&
                                    projectsOneStore.selectedOneProject
                                      .scaffold_type_start_range !== "NULL" &&
                                    Object.values(
                                      projectsOneStore.selectedOneProject
                                        .scaffold_type_start_range
                                    ).length ? (
                                      Object.values(
                                        projectsOneStore.selectedOneProject
                                          .scaffold_type_start_range
                                      ).map((type) => {
                                        return (
                                          <span
                                            className={styles.chips__one_green}
                                            key={`scaffold_type_start_range__${type}`}
                                          >
                                            {projectsOneStore.selects[name]?.[
                                              type
                                            ]
                                              ? projectsOneStore.selects[name][
                                                  type
                                                ].title
                                              : type}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <span
                                        className={styles.chips__one_disabled}
                                      >
                                        Не выбрано
                                      </span>
                                    )}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );

                      case "construction":
                        return (
                          <div className={styles.row} key={name}>
                            <div className={styles.field}>
                              <p className={styles.title}>{field.title}</p>
                              <p className={styles.value}>
                                {(() => {
                                  if (
                                    !projectsOneStore.selectedOneProject[
                                      name
                                    ] ||
                                    projectsOneStore.selectedOneProject[
                                      name
                                    ] === "NULL"
                                  ) {
                                    return <br />;
                                  } else {
                                    return Object.values(
                                      projectsOneStore.selectedOneProject[name]
                                    ).map((construction) => {
                                      return (
                                        <span key={construction}>
                                          {construction}
                                        </span>
                                      );
                                    });
                                  }
                                })()}
                              </p>{" "}
                            </div>
                          </div>
                        );

                      case "staff_rp":
                      case "staff_manager":
                        return (
                          <div className={styles.row} key={name}>
                            <div className={styles.field}>
                              <p className={styles.title}>{field.title}</p>
                              <div className={styles.value}>
                                <ErrorBoundary
                                  FallbackComponent={ErrorFallback}
                                >
                                  <ProjectsOneInfoStaffTable
                                    name={name}
                                    staff={
                                      projectsOneStore.projectsStaffData[
                                        projectsOneStore.selectedOneProject[
                                          name
                                        ]
                                      ]
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            </div>
                          </div>
                        );
                      case "time_create":
                      case "images":
                      case "staff_rp_phone":
                      case "staff_manager_phone":
                        return;

                      default:
                        return (
                          <div className={styles.row} key={name}>
                            <div className={styles.field}>
                              <p className={styles.title}>{field.title}</p>
                              <p className={styles.value}>
                                {field.type === "bool" ||
                                ["to_mobile_app", "set_open"].includes(name) ? (
                                  !projectsOneStore.selectedOneProject[name] ||
                                  (projectsOneStore.selectedOneProject[name] &&
                                    typeof projectsOneStore.selectedOneProject[
                                      name
                                    ] === "object" &&
                                    !Object.values(
                                      projectsOneStore.selectedOneProject[name]
                                    ).length) ? (
                                    <img
                                      src={iconDash}
                                      className="status_danger"
                                    />
                                  ) : (
                                    <img
                                      src={iconCheck}
                                      className="status_success"
                                    />
                                  )
                                ) : projectsOneStore.selects[name]?.[
                                    projectsOneStore.selectedOneProject[name]
                                  ] ? (
                                  projectsOneStore.selects[name]?.[
                                    projectsOneStore.selectedOneProject[name]
                                  ].title
                                ) : typeof projectsOneStore.selectedOneProject[
                                    name
                                  ] !== "object" ? (
                                  field.type === "date" ||
                                  field.type === "timestamp" ? (
                                    getFormattedDate(
                                      projectsOneStore.selectedOneProject[name]
                                    )
                                  ) : (
                                    projectsOneStore.selectedOneProject[name]
                                  )
                                ) : (
                                  <br />
                                )}
                              </p>
                            </div>
                          </div>
                        );
                    }
                  }
                }
              )}
              {projectsOneStore.selectedOneProject.custom?.custom_fields &&
              Object.values(
                projectsOneStore.selectedOneProject.custom.custom_fields
              ).length ? (
                <div className={styles.row}>
                  <div className={styles.field}>
                    <p className={styles.title}>Дополнительные поля</p>
                    <p className={styles.value}>
                      <span className={styles.chips}>
                        {Object.keys(
                          projectsOneStore.selectedOneProject.custom
                            .custom_fields
                        ).map((name) => {
                          return (
                            <span className={styles.chips__one} key={name}>
                              {name}
                            </span>
                          );
                        })}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <div className={styles.body__secondColumn}>
                <ProjectsOneInfoPhoto />
              </div>
            </ErrorBoundary>
          </div>
        </div>
      ) : null}
    </LoadedComponent>
  );
};

export default observer(ProjectOneInfo);
