import styles from "./customFields.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";

import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getEntries } from "shared/utils/helpers/getEntries";
import { isEmpty } from "lodash";
import { getKeys } from "shared/utils/helpers/getKeys";

const CustomFields = () => {
  const { aregisterOneStore } = useStores();

  const getValue = (title: string, type: string) => {
    if (aregisterOneStore.selectedOne.custom_fields?.[title]) {
      return type === "date"
        ? getFormattedDate(aregisterOneStore.selectedOne.custom_fields[title])
        : aregisterOneStore.selectedOne.custom_fields[title];
    } else return <br />;
  };

  const getCols = () => {
    const cols = {};
    getKeys(
      aregisterOneStore.custom_fields[aregisterOneStore.selectedOne.project.id]
    ).forEach((field) => (cols[field] = { title: field }));
    return cols;
  };

  return !isEmpty(
    aregisterOneStore.custom_fields[aregisterOneStore.selectedOne.project.id]
  ) ? (
    <div className={styles.block}>
      <p className={styles.title}>
        {aregisterOneStore.cols.custom_fields.title}
      </p>
      {getEntries(
        aregisterOneStore.custom_fields[
          aregisterOneStore.selectedOne.project.id
        ]
      ).map(([title, field]) => (
        <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
          <TextFieldGroup
            name={title}
            fields={[title]}
            titles={Object.keys(
              aregisterOneStore.custom_fields[
                aregisterOneStore.selectedOne.project.id
              ]
            )}
            cols={getCols()}
            getValue={() => getValue(title, field.type)}
          />
        </ErrorBoundary>
      ))}
    </div>
  ) : null;
};

export default observer(CustomFields);
