import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import SalaryPremiumsAllTable from "./SalaryPremiumsAllTable";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { browserName } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const SalaryPremiums = () => {
  const { menuStore, salaryPremiumsListStore, filterStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | string[] } = {};

  // в данном useEffect заполняем словарь для расширенного поиска, если в url присутствуют параметры фильтрации, переходя по ссылке или обновляя страницу
  // применяем для полей, пагинация которых происходит с бэка (объект)
  useEffect(() => {
    if (
      Array.isArray(
        salaryPremiumsListStore.salaryPremiumsColsParam["object"]
      ) &&
      salaryPremiumsListStore.salaryPremiumsColsParam["object"].length
    ) {
      // согласно значению поля Объект записываем в словарь эти объекты
      salaryPremiumsListStore.getBuildingDictForSavedFilter(
        salaryPremiumsListStore.salaryPremiumsColsParam["object"]
      );
      salaryPremiumsListStore.setDictForArray(
        salaryPremiumsListStore.dictForFilter
      );
    }
  }, [salaryPremiumsListStore.salaryPremiumsColsParam["object"]]);

  // в данном useEffect заполняем словарь для сохраненного фильтра для тех полей, пагинация которых происходит с бэка (объект)
  useEffect(() => {
    if (
      Array.isArray(salaryPremiumsListStore.filterParams["filter[object]"]) &&
      salaryPremiumsListStore.filterParams["filter[object]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Объект в сохраненном фильтре записываем в словарь эти объекты
      salaryPremiumsListStore.getBuildingDictForSavedFilter(
        salaryPremiumsListStore.filterParams["filter[object]"]
      );
      salaryPremiumsListStore.setDictForArray(
        salaryPremiumsListStore.dictForFilter
      );
    }
  }, [
    filterStore.isOpenedSavedFilterOptions,
    salaryPremiumsListStore.downloadedFilter
  ]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      salaryPremiumsListStore.pageBuilding !== 1 &&
      salaryPremiumsListStore.pageBuilding <=
        salaryPremiumsListStore.maxPageBuilding &&
      salaryPremiumsListStore.pageBuilding !==
        salaryPremiumsListStore.prevPageBuilding
    ) {
      salaryPremiumsListStore.getMoreBuildingList();
    }
  }, [
    salaryPremiumsListStore.pageBuilding,
    salaryPremiumsListStore.maxPageBuilding
  ]);

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      salaryPremiumsListStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            salaryPremiumsListStore.setSearchValue(value);
            break;

          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      salaryPremiumsListStore.setFilterParamsFromQuery(
        queryParams as { [key: string]: string }
      );
    }

    !salaryPremiumsListStore.premiumList.length &&
      salaryPremiumsListStore.getSalaryPremiumsList();
    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("premium");
    menuStore.updateWindow({
      mainPath: "/salary",
      path: "/salary/premium",
      title: "Финансы"
    });
  }, []);

  // обновляет параметры query запроса в поисковой строке при изменении переменных из массива зависимостей
  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    if (salaryPremiumsListStore.searchValue) {
      filterParams["fast_search"] = salaryPremiumsListStore.searchValue;
    }

    Object.entries(salaryPremiumsListStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );

    salaryPremiumsListStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [
    salaryPremiumsListStore.filterParams,
    salaryPremiumsListStore.searchValue
  ]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (Object.keys(salaryPremiumsListStore.queryParams).length) {
      setSearchParams(salaryPremiumsListStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      salaryPremiumsListStore.page !== 1 &&
      salaryPremiumsListStore.page <= salaryPremiumsListStore.maxPage &&
      salaryPremiumsListStore.page !== salaryPremiumsListStore.prevPage
    ) {
      salaryPremiumsListStore.getMorePremiumList();
    }
  }, [salaryPremiumsListStore.page, salaryPremiumsListStore.maxPage]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={salaryPremiumsListStore.isLoading}
        isLoadingForFilters={salaryPremiumsListStore.isLoadingForFilters}
        searchValue={salaryPremiumsListStore.searchValue}
        setSearchValue={salaryPremiumsListStore.setSearchValue}
        getData={salaryPremiumsListStore.getPremiumList}
        selectedFilter={salaryPremiumsListStore.selectedFilter}
        setSelectedFilter={salaryPremiumsListStore.setSelectedFilter}
        cols={salaryPremiumsListStore.salaryPremiumsTableCols}
        params={salaryPremiumsListStore.salaryPremiumsColsParam}
        allCols={salaryPremiumsListStore.salaryPremiumsTableCols}
        currentCols={salaryPremiumsListStore.currentTitles}
        getDataWithFilter={salaryPremiumsListStore.getPremiumListWithFilter}
        filterParams={
          Object.keys(salaryPremiumsListStore.filterParams).length > 1
            ? salaryPremiumsListStore.filterParams
            : undefined
        }
        setQueryParams={salaryPremiumsListStore.setQueryParams}
        queryParams={salaryPremiumsListStore.queryParams}
        isSearchable
        downloadedFilter={salaryPremiumsListStore.downloadedFilter}
        setDownloadedFilter={salaryPremiumsListStore.setDownloadedFilter}
        filters={salaryPremiumsListStore.filters}
        setFilters={salaryPremiumsListStore.setFilters}
        foundCounter={salaryPremiumsListStore.foundCounter}
        itemName="премий"
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(salaryPremiumsListStore.searchValue),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        withoutColsSetting
        selectMulti={["object"]}
        defaultFields={{
          last_update: "",
          calculation_period: "",
          status: "",
          object: []
        }}
        selectsWithLoading={["object"]}
        dateRangeFields={salaryPremiumsListStore.dateRangeFields}
        setFiltersChanged={salaryPremiumsListStore.setFiltersChanged}
        pageSelectsInfo={{
          object: {
            page: salaryPremiumsListStore.pageBuilding,
            prevPage: salaryPremiumsListStore.prevPageBuilding,
            maxPage: salaryPremiumsListStore.maxPageBuilding,
            searchValue: salaryPremiumsListStore.searchValueBuilding,
            isLoading: salaryPremiumsListStore.isLoadingForBuildingList,
            setSearchValue: salaryPremiumsListStore.setSearchValueBuilding,
            setPage: salaryPremiumsListStore.setPageBuilding,
            getList: salaryPremiumsListStore.getBuildingList
          }
        }}
      >
        <SalaryPremiumsAllTable />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(SalaryPremiums);
