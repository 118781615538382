import styles from "./collapsible.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";

type CollapsibleProps = {
  /**
   * Заголовок виджета
   */
  title: string;
  /**
   * Содержимое развернутого виджета
   */
  children: JSX.Element;
  /**
   * Кнопка перехода в виджет
   */
  linkButton?: JSX.Element;
  /**
   * Закреплен ли заголовок
   */
  isStickyHeader?: boolean;
  /**
   * Развернут ли виджет
   */
  isOpenedField: boolean;
  /**
   * Тень при скролле
   */
  withHeaderShadow?: boolean;
  className?: {
    dashedLineWrapper?: string;
    headerContainer?: string;
    headerTitle?: string;
  };
  onClick: () => void;
};

const Collapsible = ({
  title,
  children,
  linkButton,
  isStickyHeader,
  isOpenedField,
  withHeaderShadow,
  className,
  onClick
}: CollapsibleProps) => {
  return (
    <div
      className={classNames(styles.container, {}, [
        className?.dashedLineWrapper
      ])}
    >
      <div
        className={classNames(
          styles.header,
          {
            [styles.stickyHeader]: isStickyHeader
          },
          [className?.headerContainer]
        )}
      >
        <div
          id={`Collapsible_toggleOneFieldShowing_${title}`}
          onClick={onClick}
          className={classNames(styles.fieldButton, {
            [styles.fieldButtonClosed]: !isOpenedField,
            [styles.fieldButtonOpened]: isOpenedField
          })}
        >
          <StatusIcon
            icon="iconcollapsestroke"
            color={!isOpenedField ? "bw-gray6" : "blue-lazure"}
          />
          <p
            className={classNames(
              styles.titleText,
              { [styles.titleTextSize]: !className?.headerTitle },
              [className?.headerTitle]
            )}
          >
            {title}
          </p>
          <div className={styles.gradientLine} />
          <div>{linkButton}</div>
        </div>
      </div>
      {withHeaderShadow && <div className={styles.shadow} />}

      {isOpenedField ? (
        <div className={styles.children}>
          {withHeaderShadow && <div className={styles.shadowCover} />}
          {children}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Collapsible;
