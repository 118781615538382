import styles from "./aregisterPanels.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";

import RefreshPanel from "./RefreshPanel";
import SavedButtonsPanel from "./SavedButtonsPanel";
import { classNames } from "shared/utils/helpers/classNames";

const AregisterPanels = () => {
  const { aregisterListStore, menuStore } = useStores();

  const [isScaleChanged, setIsScaleChanged] = useState(false);

  useEffect(() => {
    setIsScaleChanged(true);
    setTimeout(() => {
      setIsScaleChanged(false);
    }, 500);
    menuStore.setMarginLeft(0);
  }, [window.innerWidth]);

  return (
    <div
      className={classNames(styles.modalWrapper, {
        [styles.visible]: menuStore.isScroll,
        [styles.hidden]: !menuStore.isScroll,
        [styles.animation]: !aregisterListStore.isLoading && !isScaleChanged
      })}
      style={{
        marginLeft: menuStore.scroll.scrollLeft
          ? menuStore.scroll.scrollLeft
          : 0
      }}
    >
      <SavedButtonsPanel />
      <RefreshPanel />
    </div>
  );
};

export default observer(AregisterPanels);
