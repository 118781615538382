import { observer } from "mobx-react-lite";
import styles from "./buildingOneStaffMovementsTransfer.module.scss";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useState } from "react";
import validation from "./validation";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { useStores } from "stores";
import { format } from "date-fns";
import { Button, ButtonTheme } from "shared/ui/Button";

type BuildingOneStaffMovementsTransferProps = {
  uid: string;
};

const BuildingOneStaffMovementsTransfer = ({
  uid
}: BuildingOneStaffMovementsTransferProps) => {
  const { buildingOneStaffMovementsStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        uid,
        building: buildingOneStaffMovementsStore.selectedBuilding.id,
        building_parent: buildingOneStaffMovementsStore.selectedBuilding.id,
        type: buildingOneStaffMovementsStore.setInEmployeeTransfersStatusId,
        event_start: format(new Date(), "yyyy-MM-dd"),
        event_end: format(new Date(), "yyyy-MM-dd")
      }}
      validationSchema={validation}
      onSubmit={() => {
        return;
      }}
    >
      {({ values, setFieldValue, isValid }) => {
        return (
          <Form>
            <div className="d-flex align-items-center">
              <div className={styles.datePickerCol}>
                <span className={!isValid ? styles.hide : ""}>Дата начала</span>
                <DatePickerField
                  name="event_start"
                  title=" "
                  placeholderVisible
                  isCalendarOpened={openedListName === "event_start"}
                  setIsCalendarOpened={() => {
                    changeOpenedWindows("event_start");
                  }}
                  onChange={(value) => {
                    setFieldValue("event_start", value);
                  }}
                  className={{ container: styles.datePickerCustom }}
                />
              </div>
              <div className={styles.datePickerCol}>
                <span className={!isValid ? styles.hide : ""}>
                  Планируемая дата приезда
                </span>
                <DatePickerField
                  name="event_end"
                  title=" "
                  placeholderVisible
                  isCalendarOpened={openedListName === "event_end"}
                  setIsCalendarOpened={() => {
                    changeOpenedWindows("event_end");
                  }}
                  onChange={(value) => {
                    setFieldValue("event_end", value);
                  }}
                  className={{ container: styles.datePickerCustom }}
                />
              </div>
              <Button
                theme={ButtonTheme.SECONDARY}
                onClick={() =>
                  buildingOneStaffMovementsStore.addStaffEvent(values)
                }
              >
                Отправить
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(BuildingOneStaffMovementsTransfer);
