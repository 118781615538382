import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";
import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";
import { fileUrl } from "stores/utils/consts";

const SafetyWorkSection = () => {
  const overview = [
    {
      text: "Все удостоверения",
      to: "/safetywork/",
      moduleName: "safetywork",
      submoduleName: "main",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  const actions = [
    {
      text: "Создать удостоверение",
      to: "/safetywork/add",
      moduleName: "safetywork",
      submoduleName: "add",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Массовое создание удостоверений",
      to: "/safetywork/addmass",
      fullstack: `${fileUrl}/crm/safetywork/?action=massCreation`,
      moduleName: "safetywork",
      submoduleName: "addMass",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};
export default SafetyWorkSection;
