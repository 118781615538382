import { classNames } from "shared/utils/helpers/classNames";
import styles from "./switchedTabs.module.scss";
import Tooltip, { TooltipProps } from "shared/ui/Tooltip";

type SwitchedTabsProps = {
  tabs: Array<{
    id: string;
    title: string;
    tooltip?: TooltipProps;
    isBlockedTab?: boolean;
  }>;
  changeActiveTab: (id: string) => void;
  selectedTab: string | null;
  isClippedTabs?: boolean;
  tooltip?: TooltipProps;
  className?: { tabs?: string };
};

const SwitchedTabs = ({
  tabs,
  changeActiveTab,
  selectedTab,
  isClippedTabs,
  className
}: SwitchedTabsProps) => {
  return (
    <div className={styles.tabsContainer}>
      <div className={classNames(styles.tabs, {}, [className?.tabs])}>
        {tabs.map((tab, i) => {
          return tab.id !== undefined ? (
            tab.tooltip ? (
              <div
                key={`tab-${tab.title}-${tab.id}`}
                className={classNames(styles.tabLinkContainer, {
                  [styles.activeContainer]: tab.id === selectedTab,
                  [styles[`activeContainer_${i}`]]:
                    tab.id === selectedTab && i === 0,
                  [styles[`tabLinkContainer_${i}`]]: i === 0
                })}
                id={`tab-${tab.title}-${tab.id}`}
              >
                <Tooltip {...tab.tooltip}>
                  <button
                    className={classNames(styles.tabLinks, {
                      [styles.tabLinksClipped]: isClippedTabs,
                      [styles.active]: tab.id === selectedTab,
                      [styles[`tabLink_${i}`]]: i === 0
                    })}
                    onClick={() => {
                      !tab.isBlockedTab && changeActiveTab(tab.id);
                    }}
                  >
                    {tab.title}
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div
                className={classNames(styles.tabLinkContainer, {
                  [styles.activeContainer]: tab.id === selectedTab,
                  [styles[`activeContainer_${i}`]]:
                    tab.id === selectedTab && i === 0,
                  [styles[`tabLinkContainer_${i}`]]: i === 0
                })}
                key={`tab-${tab.title}-${tab.id}`}
                id={`tab-${tab.title}-${tab.id}`}
              >
                <button
                  className={classNames(styles.tabLinks, {
                    [styles.tabLinksClipped]: isClippedTabs,
                    [styles.active]: tab.id === selectedTab,
                    [styles[`tabLink_${i}`]]: i === 0
                  })}
                  onClick={() => {
                    !tab.isBlockedTab && changeActiveTab(tab.id);
                  }}
                >
                  {tab.title}
                </button>
              </div>
            )
          ) : null;
        })}
      </div>
    </div>
  );
};

export default SwitchedTabs;
