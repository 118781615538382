// фукция получения верхнего отступа для таблицы с закреплённым фильтром
export const getTableMarginTop = (
  savedFiltersHeight: number,
  hasSearchParams: boolean,
  isOpenedMainFilter: boolean,
  isFirefox: boolean
): number => {
  // если открыты хотя бы какие-то настройки фильтров с полями (кроме сохр.фильтров)
  return isOpenedMainFilter
    ? // для Firefox отступ нужен больше
      isFirefox
      ? 30 + (hasSearchParams ? 23 : 0)
      : // отступ таблицы открытых настроек будет 20px
        20
    : // если все настройки закрыты, то отступ будет равен сумме
      // высоты блока сохранённых фильтров (или 0) и высоты блока параметров поиска (или минимальные 29px)
      (savedFiltersHeight || 0) +
        (hasSearchParams ? 35 : 29) + // и +10px для Firefox из-за особенностей браузера
        (isFirefox ? (hasSearchParams ? 14 : 30) : 0);
};
