import { useEffect } from "react";
import { useStores } from "stores/index";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import StaffListTable from "./StaffListTable/index";
import TableWithStickyFilter from "features/TableWithStickyFilter";

import { browserName } from "react-device-detect";
import StaffListCustomFilter from "./StaffListCustomFilter";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const StaffList = observer(() => {
  const navigateUserProfile = () => {
    menuStore.setScrollPosition(menuStore.scroll);
  };
  const { menuStore, filterStore, staffListStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams:
    | {
        [key: string]: string | number;
      }
    | { [key: string]: string[] } = {};

  useEffect(() => {
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      staffListStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            staffListStore.setSearchValue(value);
            break;
          case "order":
            staffListStore.setStaffListOrder(value);
            break;
          case "ordered":
            staffListStore.setStaffListOrdered(value);
            break;
          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      staffListStore.setFilterParamsFromQuery(queryParams);
    }
    if (!staffListStore.staffList.length) {
      staffListStore.getStaffData();
    }
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/staff", "Сотрудники");
  }, []);

  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    Object.entries(staffListStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );
    if (staffListStore.staffListOrder) {
      filterParams["order"] = staffListStore.staffListOrder;
    }
    if (staffListStore.staffListOrdered) {
      filterParams["ordered"] = staffListStore.staffListOrdered;
    }
    if (staffListStore.searchValue) {
      filterParams["fast_search"] = staffListStore.searchValue;
    }

    staffListStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [
    staffListStore.filterParams,
    staffListStore.searchValue,
    staffListStore.staffListOrder,
    staffListStore.staffListOrdered
  ]);

  useEffect(() => {
    if (Object.keys(staffListStore.queryParams).length) {
      setSearchParams(staffListStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      staffListStore.page !== 1 &&
      staffListStore.page <= staffListStore.maxPage &&
      staffListStore.page !== staffListStore.prevPage
    ) {
      staffListStore.getMoreStaff();
    }
  }, [staffListStore.page, staffListStore.maxPage]);

  const filterStaff = (order: string) => {
    staffListStore.selectedFilter !== "new" &&
      staffListStore.getNewFilterParams(
        staffListStore.filters[staffListStore.selectedFilter]
      );

    staffListStore.setStaffListOrder(order);
    if (order === staffListStore.staffListOrder) {
      staffListStore.setStaffListOrdered(
        staffListStore.staffListOrdered === "DESC" ? "ASC" : "DESC"
      );
    } else {
      staffListStore.setStaffListOrdered("DESC");
    }
    staffListStore.setPage(1);
    staffListStore.setQueryParams({
      ...staffListStore.queryParams,
      order,
      ordered: staffListStore.staffListOrdered,
      selectedFilter: staffListStore.downloadedFilter
    });
    setSearchParams({
      ...staffListStore.queryParams,
      order,
      ordered: staffListStore.staffListOrdered,
      selectedFilter: staffListStore.downloadedFilter
    });
    staffListStore.getStaffData();
  };

  const getTitle = (key: string) => {
    if (
      Object.keys(staffListStore.staffColsAll).length &&
      staffListStore.staffColsAll[key]
    ) {
      return staffListStore.staffColsAll[key]["title"];
    } else return key;
  };

  const createDataFile = (format: "csv" | "xls") => {
    return staffListStore.staffList.map((item) => {
      const newItem: { [key: string]: string | number | string[] } = {};
      staffListStore.currentTitles.forEach((key) => {
        switch (key) {
          case "event_type":
          case "building":
            return (newItem[getTitle(key)] =
              item[key] &&
              Object.entries(item[key])
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map((el) => el[1].title)
                .join("\n"));
          case "company":
          case "position":
            return (newItem[getTitle(key)] =
              item[key] &&
              Object.entries(item[key])
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map((el) => el[1])
                .join("\n"));
          default:
            return (newItem[getTitle(key)] = item[key]);
        }
      });
      return format === "csv" ? Object.values(newItem) : newItem;
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={staffListStore.isLoading}
        isLoadingForFilters={staffListStore.isLoadingForFilters}
        filters={staffListStore.filters}
        searchValue={staffListStore.searchValue}
        setSearchValue={staffListStore.setSearchValue}
        getData={staffListStore.getStaffData}
        selectedFilter={staffListStore.selectedFilter}
        setDownloadedFilter={staffListStore.setDownloadedFilter}
        downloadedFilter={staffListStore.downloadedFilter}
        setSelectedFilter={staffListStore.setSelectedFilter}
        setFilters={staffListStore.setFilters}
        cols={staffListStore.staffTableCols}
        params={staffListStore.staffTableParams}
        allCols={staffListStore.staffColsAll}
        currentCols={staffListStore.currentTitles}
        getDataWithFilter={staffListStore.getStaffListWithFilter}
        setFiltersChanged={staffListStore.setFiltersChanged}
        setQueryParams={staffListStore.setQueryParams}
        listOrdered={staffListStore.staffListOrdered}
        queryParams={staffListStore.queryParams}
        selectMulti={["position", "building", "company"]}
        isSearchable
        isExport
        createDataFile={createDataFile}
        dataFileName="Сотрудники"
        foundCounter={staffListStore.staffFoundCounter}
        filterParams={staffListStore.filterParams}
        itemName="сотрудников"
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !staffListStore.filters[staffListStore.downloadedFilter]?.[
              "general"
            ] || staffListStore.searchValue
          ),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="В этом поле можно искать несколько сотрудников, если вбить ТН номера через пробел"
        defaultFields={{
          event_type: "",
          building: [],
          company: [],
          position: []
        }}
        customFilterMain={<StaffListCustomFilter />}
        dateRangeFields={{}}
      >
        <StaffListTable
          filterItems={filterStaff}
          handleClick={navigateUserProfile}
        />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
});

export default StaffList;
