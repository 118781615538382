import StaffOneInfoAwardOne from "./StaffOneInfoAwardOne";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadedComponent from "widgets/LoadedComponent";
import Alert from "shared/ui/Alert";

const StaffOneInfoAwards = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.awards) && staffOneStore.getAwardsWidget(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_awards[id]}
      withoutText
    >
      <>
        {id in staffOneStore.awards &&
        Object.values(staffOneStore.awards[id]).length ? (
          <StaffOneInfoAwardOne />
        ) : (
          <Alert errors={{ head: "У сотрудника нет наград", color: "empty" }} />
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoAwards);
