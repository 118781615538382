import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

const FriendInviteSectionOne = () => {
  const { menuStore } = useStores();
  const { tabId } = menuStore;

  const overview = [
    {
      text: "Приглашение",
      to: `/friendinvite/id=${tabId}`,
      moduleName: "friendInviteOne",
      submoduleName: "friendInviteOne",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Комментарии",
      to: `/friendinvite/id=${tabId}/comments`,
      moduleName: "friendInviteOne",
      submoduleName: "friendInviteOneComments",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "История",
      to: `/friendinvite/id=${tabId}/history`,
      moduleName: "friendInviteOne",
      submoduleName: "history",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(FriendInviteSectionOne);
