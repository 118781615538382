import * as yup from "yup";

export const getValidationSchema = (maxCount: number, disabled: boolean) => {
  const validationSchema = {
    copiesCount: yup
      .number()
      .typeError("Только число")
      .min(1, `Не менее ${1}`)
      .max(maxCount, `Не более ${maxCount}`)
  };

  return !disabled ? yup.object(validationSchema) : {};
};
