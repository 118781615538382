import { Link } from "react-router-dom";
import styles from "./menuButton.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Tooltip from "shared/ui/Tooltip";

type MenuButtonProps = {
  alt?: string;
  defaultIcon: string;
  disabled?: boolean;
  moduleName: string;
  selectedIcon: string;
  label?: string;
  submoduleName: string;
  text: string;
  to: string;
  onClick?: () => void;
  fullstack?: string;

  isActionButton?: boolean;
  selectedActionButton?: boolean;
};

const MenuButton = ({
  alt,
  defaultIcon,
  disabled,
  moduleName,
  selectedIcon,
  label,
  submoduleName,
  text,
  to,
  onClick,
  fullstack,
  isActionButton,
  selectedActionButton
}: MenuButtonProps) => {
  const { menuStore } = useStores();

  const getMenuButton = () => {
    return (
      <>
        <img
          src={
            (!fullstack && menuStore.openedSubmodule === submoduleName) ||
            selectedActionButton
              ? selectedIcon
              : defaultIcon
          }
          alt={alt}
        />
        <p
          className={`${styles.text} ${
            !menuStore.isMenuShown ? styles.noText : ""
          }`}
        >
          {text}
        </p>
        {menuStore.isMenuShown && label ? (
          <p className={styles.label}>{label}</p>
        ) : (
          ""
        )}
      </>
    );
  };

  return fullstack ? (
    <Tooltip
      color="blue-lazure"
      placement="right-start"
      text={`Перейти в раздел "${text}" текущего ПО`}
    >
      <button
        className={styles.button}
        onClick={() => {
          return setTimeout(() => {
            window.open(fullstack, "_blank");
          }, 100);
        }}
        title={menuStore.isMenuShown ? "" : text}
        //Убрать, когда будет готов раздел "Объекты" и "Отпуск"
        style={disabled ? { pointerEvents: "none" } : {}}
      >
        {getMenuButton()}
      </button>
    </Tooltip>
  ) : to ? (
    <Link
      to={to}
      className={`${styles.button} ${
        menuStore.openedSubmodule === submoduleName ? styles.selectedBtn : ""
      }`}
      onClick={() => {
        menuStore.setAllModules(false);
        menuStore.setOpenedModule(moduleName);
        submoduleName && menuStore.setOpenedSubmodule(submoduleName);
        onClick && onClick();
      }}
      title={menuStore.isMenuShown ? "" : text}
      //Убрать, когда будет готов раздел "Объекты" и "Отпуск"
      style={disabled ? { pointerEvents: "none" } : {}}
    >
      {getMenuButton()}
    </Link>
  ) : isActionButton ? (
    <div
      className={`${styles.button} ${
        selectedActionButton ? styles.selectedBtn : ""
      }`}
      onClick={() => {
        // onClick для actionButton обязательный
        onClick && onClick();
      }}
      title={menuStore.isMenuShown ? "" : text}
    >
      {getMenuButton()}
    </div>
  ) : (
    <Tooltip
      color="blue-lazure"
      placement="right-start"
      text={`Раздел "${text}" находится в разработке`}
    >
      <div
        className={`${styles.button} ${
          menuStore.openedSubmodule === submoduleName ? styles.selectedBtn : ""
        }`}
        title={menuStore.isMenuShown ? "" : text}
        //Убрать, когда будет готов раздел "Объекты" и "Отпуск"
        style={disabled ? { pointerEvents: "none" } : {}}
        onClick={() => onClick && onClick()}
      >
        {getMenuButton()}
      </div>
    </Tooltip>
  );
};

export default observer(MenuButton);
