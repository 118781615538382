import styles from "./pairAregisterField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";

import iconPoints from "shared/assets/images/mainIcons/iconPoints.svg";

import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";

const pairFields = {
  0: {
    title: "Перейти к заявке",
    custom: {
      img: "iconlink"
    }
  },
  1: {
    title: "Отвязать",
    custom: {
      img: "scissors"
    }
  },
  2: {
    title: "Установить “Включено в КС” из связанной заявки",
    custom: {
      img: "iconperiod"
    }
  }
};

type PairAregisterFieldProps = {
  title: string;
  getValue: (name: string) => string;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
};
const PairAregisterField = ({
  title,
  openedListName,
  getValue,
  changeOpenedListName
}: PairAregisterFieldProps) => {
  const { aregisterOneStore } = useStores();

  const navigate = useNavigate();

  const value = () => getValue(title)?.["internal_num"];

  const handleNavigateToPair = () => (item_title: string) =>
    item_title === "Перейти к заявке" &&
    navigate(`../id=${aregisterOneStore.selectedOne[title].id}`);

  const handleChangeOpenedListName = () => changeOpenedListName(title);
  const handleBlurList = () =>
    setTimeout(() => changeOpenedListName(title), 200);

  const PairElement = () => (
    <>
      {value() || <br />}
      {aregisterOneStore.selectedOne.pair?.id ? (
        <span className={styles.icon}>
          <img
            src={iconPoints}
            className={styles.iconPoints}
            onClick={handleChangeOpenedListName}
            tabIndex={1}
            onBlur={handleBlurList}
            id="PairAregisterField_showList"
          />
          {openedListName === title ? (
            <div className={styles.list}>
              <StatusIcon
                icon="iconclose"
                color="bw-gray5"
                onClick={{
                  handleClick: handleChangeOpenedListName,
                  id: "AregisterOneInfoMain_closeList"
                }}
                className={styles.icon}
              />

              {Object.values(pairFields).map((item) => {
                return (
                  <div
                    key={item.title}
                    id="PairAregisterField_chooseAction"
                    onClick={handleNavigateToPair}
                    className={styles.pair}
                  >
                    <StatusIcon icon={item.custom.img} color="" />
                    {item.title}
                  </div>
                );
              })}
            </div>
          ) : null}
        </span>
      ) : null}
    </>
  );

  return (
    <ErrorBoundary key={title} FallbackComponent={ErrorFallback}>
      <TextFieldGroup
        name={title}
        fields={[title]}
        titles={aregisterOneStore.aregisterTitles}
        cols={aregisterOneStore.cols}
        getValue={PairElement}
        className={{ value: styles.value }}
      />
    </ErrorBoundary>
  );
};

export default observer(PairAregisterField);
