import styles from "./staffTablePresave.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";
import { getEntries } from "shared/utils/helpers/getEntries";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

import { PresaveStaff } from "stores/AregisterModule/types/PresavesStaff";
import StaffTablePresaveInput from "./StaffTablePresaveInput";

type StaffTablePresaveProps = {
  staff_id: string;
  company: string;
  company_idx: number;
  staff: PresaveStaff;
};

const StaffTablePresave = ({
  staff_id,
  company,
  company_idx,
  staff
}: StaffTablePresaveProps) => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();

  const companiesCount = getKeys(staff.main.staff_info.used_company).length;
  const isWorkHours = (field: string) =>
    field === "work_hours_sum" && company_idx === 0 && companiesCount > 1;
  const handleOnMouseEnter = (presave: string) => () =>
    aregisterOneStore.setHighlightedPresave(presave);
  const handleOnMouseLeave = () => aregisterOneStore.setHighlightedPresave("");
  const isSecondRowForSum = (field: string) =>
    field === "work_hours_sum" && company_idx !== 0;
  const getTdProps = (presave: string, field: string) => {
    return {
      className: classNames("", {
        ["position-relative"]: field === "contribution",
        [styles.rowspan]: isWorkHours(field),
        [styles.highlightedPresave]:
          aregisterOneStore.highlightedPresave[id] === presave ||
          aregisterOneStore.focusedPresave[id] === presave
      }),
      rowSpan: isWorkHours(field) ? companiesCount : undefined,
      onMouseEnter: handleOnMouseEnter(presave),
      onMouseLeave: handleOnMouseLeave
    };
  };

  return (
    <>
      {getEntries(aregisterOneStore.presaves[id])
        .sort(([, a], [, b]) =>
          parse(a.date, dateFormats.date.format, new Date()) >
          parse(b.date, dateFormats.date.format, new Date())
            ? 1
            : -1
        )
        .map(([presave]) => {
          if (staff["group_by_presave"][presave]?.[company]) {
            const presaveOne = staff["group_by_presave"][presave][company];
            return getKeys(aregisterOneStore.presavesCols.group_by_presave).map(
              (field) =>
                !isSecondRowForSum(field) ? (
                  <td
                    key={`${staff_id}_${presave}_${company}_${field}`}
                    {...getTdProps(presave, field)}
                  >
                    {(() => {
                      switch (field) {
                        case "contribution":
                          if (aregisterOneStore.canEditContribution) {
                            const name = `${presave}_${staff_id}_${field}`;
                            const initialValues = {
                              [name]: String(presaveOne[field].value ?? "")
                            };
                            const handleValidateContribution = (
                              values: Record<string, string>
                            ) => {
                              if (+values[name] > presaveOne.work_hours) {
                                return {
                                  [name]: `Не более ${presaveOne.work_hours}`
                                };
                              }
                            };
                            return (
                              <Formik
                                initialValues={initialValues}
                                onSubmit={() => null}
                                validate={handleValidateContribution}
                              >
                                {() => (
                                  <StaffTablePresaveInput
                                    presave={presave}
                                    name={`${presave}_${staff_id}_${field}`}
                                    staff_id={staff_id}
                                    company={company}
                                    staff={staff}
                                  />
                                )}
                              </Formik>
                            );
                          } else return <div>{presaveOne[field].value}</div>;
                        case "workshift":
                          return (
                            <div className={styles.workshiftState}>
                              <Link
                                className={styles.link}
                                to={`/workshift/id=${staff_id}`}
                                target="_blank"
                                id={`AregisterOneStaffTablePresave_navigateToWorkShift_${staff_id}_${presave}`}
                              >
                                {presaveOne[field].value}
                              </Link>
                              <div
                                className={styles.iconQuestion}
                                data-tooltip={`Временный номер акта: ${aregisterOneStore.presaves[id]?.[presave]?.title}`}
                              >
                                <IconQuestionSmall />
                              </div>
                            </div>
                          );
                        default:
                          return <div>{presaveOne[field]}</div>;
                      }
                    })()}
                  </td>
                ) : null
            );
          } else
            return getKeys(aregisterOneStore.presavesCols.group_by_presave).map(
              (field) =>
                !isSecondRowForSum(field) ? (
                  <td
                    key={`${staff_id}_${presave}_${company}_${field}`}
                    {...getTdProps(presave, field)}
                  />
                ) : null
            );
        })}
    </>
  );
};

export default observer(StaffTablePresave);
