import StatusIcon from "shared/ui/StatusIcon";
import styles from "./informationCard.module.scss";
import { Card } from "react-bootstrap";

type InformationCardProps = {
  isAlert: boolean;
  icon: string;
  title: string;
  children: JSX.Element[];
};

const InformationCard = ({
  isAlert,
  icon,
  title,
  children
}: InformationCardProps) => {
  return (
    <Card className={`${styles.card} ${isAlert ? styles.card_alert : ""}`}>
      <Card.Body>
        <div className={styles.headerCard}>
          {icon ? <StatusIcon icon={icon} color="danger" /> : null}
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.bodyCard}>{children}</div>
      </Card.Body>
    </Card>
  );
};
export default InformationCard;
