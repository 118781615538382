//Функция разделяет числа на разряды :

export const numberWithSpaces = (
  value: number,
  withСurrency?: boolean,
  digits?: number
) => {
  const result = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: digits !== undefined ? digits : 2
  }).format(value);
  return withСurrency ? result : result.slice(0, -2);
};
