import styles from "./dateRangePicker.module.scss";
import "./indexRange.scss";

import { useState, useEffect } from "react";
import { useFormikContext, useField } from "formik";

import DatePicker, {
  CalendarContainerProps,
  registerLocale
} from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

import StatusIcon from "shared/ui/StatusIcon";

import { format, parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

import DateRangePickerInput from "./DateRangePickerInput";
import { classNames } from "shared/utils/helpers/classNames"; 
import CustomCalendarContainer from "./CustomCalendarContainer"; 
import CustomHeader from "./CustomHeader"; 

type DateRangePickerProps = {
  name: string;
  isCalendarOpened: boolean;
  setIsCalendarOpened: (isCalendarOpened: boolean) => void;
  onChange?: (value: string | { [key: string]: string }) => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  placeholderVisible?: boolean;
  required?: boolean;
  title: string;
  monthsShown?: number;
  siblingDateName?: string;
  labelForMainField?: string;
  labelForSiblingField?: string;
  isForFilter?: boolean;
  valueStartDate?: string;
  valueEndDate?: string;
  className?: string;
  withoutError?: boolean;
  withInitialValue?: boolean;
};

const DateRangePicker = ({
  name,
  onChange,
  isCalendarOpened,
  setIsCalendarOpened,
  disabled,
  placeholderVisible,
  required,
  title,
  monthsShown,
  siblingDateName,
  labelForMainField,
  labelForSiblingField,
  isForFilter,
  valueStartDate,
  valueEndDate,
  className,
  withoutError,
  withInitialValue
}: DateRangePickerProps) => {
  const { initialValues, values, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [field, meta] = useField({
    name,
    disabled,
    required,
    title
  });
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [inputStartDateValue, setInputStartDateValue] = useState(
    format(new Date(), dateFormats.date.format)
  );
  const [inputEndDateValue, setInputEndDateValue] = useState(
    format(new Date(), dateFormats.date.format)
  );

  const [inputError, setInputError] = useState(false);

  const [dateRange, setDateRange] = useState("");

  // блок условий, при которых siblingDateName не передан
  const withoutSiblingDateNameConditional =
    (!values[name] || values[name] === initialValues[name]) &&
    !siblingDateName &&
    dateFormats.date.format;

  // блок условий, при которых siblingDateName передан
  const withSiblingDateNameConditional =
    values[name] === initialValues[name] &&
    siblingDateName &&
    values[siblingDateName] === initialValues[siblingDateName];

  // получаем дату в зависимости от переданного условия conditional
  const getDateFromValues = (
    conditional: boolean,
    value: string,
    format = "yyyy-MM-dd"
  ) => {
    return conditional ? parse(value, format, new Date()) : new Date();
  };

  // получаем дату в виде строки в зависимости от переданного условия conditional
  const getStringDate = (
    conditional: boolean,
    value: string,
    formatDate = "yyyy-MM-dd"
  ) => {
    const dateValue = getDateFromValues(conditional, value, formatDate);
    return format(dateValue, dateFormats.date.format || "dd.MM.yyyy");
  };

  useEffect(() => {
    if (withoutSiblingDateNameConditional || withSiblingDateNameConditional) {
      setStartDate(
        getDateFromValues(Boolean(initialValues[name]), initialValues[name])
      );

      setEndDate(null);

      initialValues[name] &&
        setDateRange(
          `${getStringDate(
            Boolean(initialValues[name]),
            initialValues[name]
          )} - ${getStringDate(
            siblingDateName && initialValues[siblingDateName],
            initialValues[siblingDateName]
          )}`
        );
      if (isForFilter && valueStartDate && valueEndDate) {
        setDateRange(
          `${getStringDate(true, valueStartDate)} - ${getStringDate(
            true,
            valueEndDate
          )}`
        );
      }
    }
  }, [values[name]]);

  useEffect(() => {
    if (isForFilter) {
      range([
        getDateFromValues(Boolean(valueStartDate), valueStartDate),
        getDateFromValues(Boolean(valueEndDate), valueEndDate)
      ]);
    } else {
      range([
        getDateFromValues(Boolean(values[name]), values[name]),
        getDateFromValues(
          Boolean(values[siblingDateName]),
          values[siblingDateName]
        )
      ]);
    }
  }, [isCalendarOpened]);

  const range = (dates: Date[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setInputStartDateValue(format(start, dateFormats.date.format));
    end && setInputEndDateValue(format(end, dateFormats.date.format));
  };

  // функция, которая срабатывает по нажатию на кнопку готово
  const handleSetValue = () => {
    if (siblingDateName) {
      setFieldValue(field.name, format(startDate, "yyyy-MM-dd"));
      setFieldValue(siblingDateName, format(endDate, "yyyy-MM-dd"));
    } else {
      setFieldValue(
        field.name,
        `${format(startDate, dateFormats.date.format)} - ${format(
          endDate,
          dateFormats.date.format
        )}`
      );
    }

    onChange &&
      onChange(
        siblingDateName
          ? {
              [name]: format(startDate, "yyyy-MM-dd"),
              [siblingDateName]: format(endDate, "yyyy-MM-dd")
            }
          : dateRange
      );
    setIsCalendarOpened(!isCalendarOpened);
    setDateRange(
      `${format(startDate, dateFormats.date.format)} - ${
        endDate ? format(endDate, dateFormats.date.format) : null
      }`
    );
    setFieldTouched(field.name);
  };

  const getDateRange = () => {
    if (!dateRange.length && isForFilter) {
      return `${getStringDate(
        Boolean(valueStartDate),
        valueStartDate
      )} - ${getStringDate(Boolean(valueEndDate), valueEndDate)}`;
    }

    if (!dateRange.length && !isForFilter) {
      return `${getStringDate(
        Boolean(values[name]),
        values[name]
      )} - ${getStringDate(
        Boolean(values[siblingDateName]),
        values[siblingDateName]
      )}`;
    }

    return dateRange;
  };

  const isTitleWithValue =
    Boolean(isForFilter && valueStartDate && valueEndDate) ||
    Boolean(values[name] && values[siblingDateName]) ||
    withInitialValue;

  const isError = meta.error && meta.touched && !withoutError;

  const handleChangeCalendarState = () => {
    if (isCalendarOpened || disabled) {
      return;
    }
    setIsCalendarOpened(!isCalendarOpened);
  };

  const getCalendarContainer = (props: CalendarContainerProps) => (
    <CustomCalendarContainer
      name={name}
      endDate={endDate}
      inputError={inputError}
      handleSetValue={handleSetValue}
      {...props}
    />
  );

  return (
    <div
      className={classNames(
        styles.inputContainer,
        {
          [styles.requiredBlock]: required,
          [styles.invalid]: isError,
          [styles.disabled]: disabled,
          [styles.active]: isCalendarOpened
        },
        [className]
      )}
      onClick={handleChangeCalendarState}
    >
      <div
        className={classNames(styles.valueContainer, {
          [styles.requiredWithoutValue]: required && !isTitleWithValue
        })}
      >
        {placeholderVisible ? (
          <p
            className={classNames(styles.title, {
              [styles.titleWithValue]: isTitleWithValue,
              [styles.required]: required
            })}
          >
            {title}
          </p>
        ) : null}

        {field.value || withInitialValue ? <div>{getDateRange()}</div> : ""}
      </div>

      <div className={styles.calendarWrapper}>
        {isCalendarOpened ? (
          <DateRangePickerInput
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            inputStartDateValue={inputStartDateValue}
            inputEndDateValue={inputEndDateValue}
            labelForMainField={labelForMainField}
            labelForSiblingField={labelForSiblingField}
            setIsCalendarOpened={setIsCalendarOpened}
            setInputError={setInputError}
          />
        ) : null}

        <DatePicker
          name={name}
          dateFormat={dateFormats.date.format ? dateFormats.date.format : " "}
          popperClassName={styles.popper}
          calendarContainer={getCalendarContainer}
          customInput={<></>}
          renderCustomHeader={CustomHeader}
          monthsShown={monthsShown}
          locale={ru}
          selected={startDate}
          onChange={range}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          open={isCalendarOpened}
        />
      </div>

      <div className={styles.iconBtns}>
        <div
          className={classNames("", {
            [styles.iconCalendar_active]: isCalendarOpened
          })}
        >
          <StatusIcon icon="iconcalendar" color="bw-gray5" />
        </div>
      </div>

      {isError ? <div className={styles.error}>{meta.error}</div> : null}
    </div>
  );
};

export default DateRangePicker;
