import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { browserName } from "react-device-detect";

import SalaryOperationsAllTable from "./SalaryOperationsAllTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const SalaryOperationsAll = () => {
  const { menuStore, salaryOperationsListStore, filterStore } = useStores();

  const navigate = useNavigate();
  const navigateSalaryOperationOne = (id: string) => {
    navigate(`./id=${id}`);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | string[] } = {};

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      salaryOperationsListStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            salaryOperationsListStore.setSearchValue(value);
            break;

          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      salaryOperationsListStore.setFilterParamsFromQuery(queryParams);
    }
    !salaryOperationsListStore.salaryOperationsList.length &&
      salaryOperationsListStore.getSalaryOperationsList();
    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/salary", "Финансы");
  }, []);

  // обновляет параметры query запроса в поисковой строке при изменении переменных из массива зависимостей
  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    if (salaryOperationsListStore.searchValue) {
      filterParams["fast_search"] = salaryOperationsListStore.searchValue;
    }

    Object.entries(salaryOperationsListStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );

    salaryOperationsListStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [salaryOperationsListStore.filterParams]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (Object.keys(salaryOperationsListStore.queryParams).length) {
      setSearchParams(salaryOperationsListStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      salaryOperationsListStore.page !== 1 &&
      salaryOperationsListStore.page <= salaryOperationsListStore.maxPage &&
      salaryOperationsListStore.page !== salaryOperationsListStore.prevPage
    ) {
      salaryOperationsListStore.getMoreSalaryOperationsList();
    }
  }, [salaryOperationsListStore.page, salaryOperationsListStore.maxPage]);

  useEffect(() => {
    if (
      salaryOperationsListStore.pageStaff !== 1 &&
      salaryOperationsListStore.pageStaff <=
        salaryOperationsListStore.maxPageStaff &&
      salaryOperationsListStore.pageStaff !==
        salaryOperationsListStore.prevPageStaff
    ) {
      salaryOperationsListStore.getMoreStaff();
    }
  }, [
    salaryOperationsListStore.pageStaff,
    salaryOperationsListStore.maxPageStaff
  ]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      salaryOperationsListStore.pageBuilding !== 1 &&
      salaryOperationsListStore.pageBuilding <=
        salaryOperationsListStore.maxPageBuilding &&
      salaryOperationsListStore.pageBuilding !==
        salaryOperationsListStore.prevPageBuilding
    ) {
      salaryOperationsListStore.getMoreBuildingList();
    }
  }, [
    salaryOperationsListStore.pageBuilding,
    salaryOperationsListStore.maxPageBuilding
  ]);

  // пагинация для выпадающего списка авторов
  useEffect(() => {
    if (
      salaryOperationsListStore.pageAuthor !== 1 &&
      salaryOperationsListStore.pageAuthor <=
        salaryOperationsListStore.maxPageAuthor &&
      salaryOperationsListStore.pageAuthor !==
        salaryOperationsListStore.prevPageAuthor
    ) {
      salaryOperationsListStore.getMoreAuthorList();
    }
  }, [
    salaryOperationsListStore.pageAuthor,
    salaryOperationsListStore.maxPageAuthor
  ]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={salaryOperationsListStore.isLoading}
        isLoadingForFilters={salaryOperationsListStore.isLoadingForFilters}
        searchValue={salaryOperationsListStore.searchValue}
        setSearchValue={salaryOperationsListStore.setSearchValue}
        getData={salaryOperationsListStore.getSalaryOperationsList}
        selectedFilter={salaryOperationsListStore.selectedFilter}
        setSelectedFilter={salaryOperationsListStore.setSelectedFilter}
        downloadedFilter={salaryOperationsListStore.downloadedFilter}
        setDownloadedFilter={salaryOperationsListStore.setDownloadedFilter}
        cols={salaryOperationsListStore.salaryOperationsTableCols}
        params={salaryOperationsListStore.salaryOperationsTableParams}
        allCols={salaryOperationsListStore.salaryOperationsTableCols}
        currentCols={salaryOperationsListStore.currentTitles}
        filters={salaryOperationsListStore.filters}
        getDataWithFilter={
          salaryOperationsListStore.getOperationsListWithFilter
        }
        filterParams={salaryOperationsListStore.filterParams}
        setQueryParams={salaryOperationsListStore.setQueryParams}
        queryParams={salaryOperationsListStore.queryParams}
        foundCounter={salaryOperationsListStore.foundCounter}
        itemName="операций"
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(Object.keys(salaryOperationsListStore.filterParams).length),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        withoutColsSetting
        selectMulti={["uid", "object", "type", "author"]}
        defaultFields={{
          uid: [],
          object: [],
          time_spending: "",
          type: []
        }}
        dateRangeFields={salaryOperationsListStore.dateRangeFields}
        selectsWithLoading={["uid", "object", "author"]}
        pageSelectsInfo={{
          uid: {
            page: salaryOperationsListStore.pageStaff,
            prevPage: salaryOperationsListStore.prevPageStaff,
            maxPage: salaryOperationsListStore.maxPageStaff,
            searchValue: salaryOperationsListStore.searchValueStaff,
            isLoading: salaryOperationsListStore.isLoadingForStaffList,
            setSearchValue: salaryOperationsListStore.setSearchValueStaff,
            setPage: salaryOperationsListStore.setPageStaff,
            getList: salaryOperationsListStore.getStaffList
          },
          object: {
            page: salaryOperationsListStore.pageBuilding,
            prevPage: salaryOperationsListStore.prevPageBuilding,
            maxPage: salaryOperationsListStore.maxPageBuilding,
            searchValue: salaryOperationsListStore.searchValueBuilding,
            isLoading: salaryOperationsListStore.isLoadingForBuildingList,
            setSearchValue: salaryOperationsListStore.setSearchValueBuilding,
            setPage: salaryOperationsListStore.setPageBuilding,
            getList: salaryOperationsListStore.getBuildingList
          },
          author: {
            page: salaryOperationsListStore.pageAuthor,
            prevPage: salaryOperationsListStore.prevPageAuthor,
            maxPage: salaryOperationsListStore.maxPageAuthor,
            searchValue: salaryOperationsListStore.searchValueAuthor,
            isLoading: salaryOperationsListStore.isLoadingForAuthorList,
            setSearchValue: salaryOperationsListStore.setSearchValueAuthor,
            setPage: salaryOperationsListStore.setPageAuthor,
            getList: salaryOperationsListStore.getAuthorList
          }
        }}
        setFiltersChanged={salaryOperationsListStore.setFiltersChanged}
        isSearchable
      >
        <SalaryOperationsAllTable handleClick={navigateSalaryOperationOne} />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(SalaryOperationsAll);
