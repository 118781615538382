import styles from "./staffImportTableBodyStatus.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { StaffStatus } from "stores/StaffModule/types/StaffImportTable";
import { ReactComponent as IconUpdate } from "shared/assets/images/mainIcons/iconUpdate/iconUpdate.svg";
import iconExpect from "shared/assets/images/mainIcons/iconExpect.svg";
import circleCheck from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as Attention } from "shared/assets/images/iconStatus/Attention.svg";
import StaffImportTableBodyDuplicateError from "../StaffImportTableBodyDuplicateError";
import { getValues } from "shared/utils/helpers/getValues";

type StaffImportTableBodyStatusProps = {
  index: number;
  className: string;
};

const StaffImportTableBodyStatus = ({
  index,
  className
}: StaffImportTableBodyStatusProps) => {
  const { staffImportStore } = useStores();

  const statusRenderDict: Record<StaffStatus, JSX.Element> = {
    verificationRequired: (
      <td className={styles.statusCellVerificationRequired} data-no-hover>
        <div className={styles.statusCellContainer}>
          <IconUpdate className={styles.statusCellIcon} />
          <p className={styles.statusCellParagraph}>Требуется проверка</p>
        </div>
        {staffImportStore.duplicateColsResponse[index]?.length > 0 ? (
          <StaffImportTableBodyDuplicateError
            index={index}
            title={staffImportStore.duplicateColsResponse[index][0]}
          />
        ) : null}
      </td>
    ),
    isLoadingDuplicateCheck: (
      <td className={styles.statusCellIsLoadingDuplicateCheck} data-no-hover>
        <div className={styles.statusCellContainer}>
          <img
            src={iconExpect}
            alt="iconExpect"
            className={styles.statusCellIcon}
          />
          <p className={styles.statusCellParagraph}>Идёт проверка</p>
        </div>
      </td>
    ),
    incorrectField: (
      <td className={styles.statusCellIncorrectField} data-no-hover>
        <div className={styles.statusCellContainer}>
          <Attention className={styles.statusCellIcon} />
          <p className={styles.statusCellParagraph}>Некорректные данные</p>
        </div>
      </td>
    ),
    incorrectDouble: (
      <td className={styles.statusCellIncorrectDouble} data-no-hover>
        <div>
          {getValues(
            staffImportStore.verificationResponse[index]?.["doubles"] || {}
          ).length ? (
            <StaffImportTableBodyDuplicateError
              index={index}
              title={
                staffImportStore.duplicateColsResponse[index]?.length
                  ? staffImportStore.duplicateColsResponse[index][0]
                  : undefined
              }
            />
          ) : (
            <div className={styles.statusCellContainer}>
              <Attention className={styles.statusCellIcon} />
              <p className={styles.statusCellParagraph}>Некорректные данные</p>
            </div>
          )}
        </div>
      </td>
    ),
    correct: (
      <td className={`${styles.statusCellCorrect} ${className}`} data-no-hover>
        <div className={styles.statusCellContainer}>
          <img
            src={circleCheck}
            alt="circleCheck"
            className={styles.statusCellIcon}
          />
          <p className={styles.statusCellParagraph}>Проверено</p>
        </div>
      </td>
    )
  };

  return statusRenderDict[staffImportStore.staffStatus[index]] || <td />;
};

export default observer(StaffImportTableBodyStatus);
