import styles from "./buildingOneStaffTableEventBlock.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { RefObject, useRef } from "react";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { fileUrl } from "stores/utils/consts";

type BuildingOneStaffTableEventBlockProps = {
  event: Staff["event"];
  staff_id: string;
  in_list: boolean;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
  onClick: (event_type: string) => void;
};
const BuildingOneStaffTableEventBlock = ({
  event,
  staff_id,
  in_list,
  openedListName,
  changeOpenedListName,
  onClick
}: BuildingOneStaffTableEventBlockProps) => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();
  const ref: RefObject<HTMLDivElement> = useRef();
  useOnClickOutside({
    ref,
    handler: () => changeOpenedListName("")
  });

  const event_type = buildingOneStaffStore.eventTypes[event.type];

  return (
    <div className={styles.event}>
      <div
        className={`${styles.status__field} ${
          buildingOneStaffStore.openedType !== "edit"
            ? styles.status__field_withoutIcons
            : ""
        }`}
      >
        <div className="d-flex align-items-center gap-1">
          <StatusIcon
            icon={event_type?.custom?.icon}
            color={event_type?.custom?.color_api}
          />
          <p>
            {`${event_type.title}${
              buildingOneStaffStore.activeTab[id] === "transfer_in"
                ? " (исходящий)"
                : buildingOneStaffStore.activeTab[id] === "transfer_out"
                ? " (входящий)"
                : ""
            }`}
          </p>
        </div>
        {buildingOneStaffStore.openedType !== "edit" ? (
          <div className="d-flex">
            {/* Скрываем кнопку смены статуса для исходчщего трансфера и статуса уволен (согласованный хардкод) */}
            {!["transfer_in", "dismiss"].includes(
              buildingOneStaffStore.activeTab[id]
            ) ? (
              <Tooltip text="Изменить статус сотрудника" color="blue-lazure">
                <div
                  className={`${styles.status__button} ${
                    openedListName === staff_id
                      ? styles.status__button_active
                      : styles.status__button_nonactive
                  }`}
                  onClick={() => changeOpenedListName(staff_id)}
                  id={`BuildingOneStaffTableEventBlock_eventTypesList_${staff_id}`}
                >
                  <StatusIcon
                    icon="iconexpand"
                    color={in_list ? "bw-gray2" : "bw-gray4"}
                  />
                </div>
              </Tooltip>
            ) : (
              ""
            )}
            <Tooltip
              text="Перейти в историю статусов сотрудника"
              color="blue-lazure"
            >
              <div
                className={styles.status__button}
                id={`BuildingOneStaffTableEventBlock_staffEventsHistory_${staff_id}`}
                onClick={() => {
                  return setTimeout(() => {
                    window.open(
                      `${fileUrl}/crm/building/?action=staffEvents&uid=${staff_id}`,
                      "_blank"
                    );
                  }, 100);
                }}
              >
                <StatusIcon
                  icon="iconsearch"
                  color={in_list ? "bw-gray2" : "bw-gray4"}
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          ""
        )}
      </div>
      {event.current_tour?.vahta ? (
        <div className={styles.event__currentTour}>
          <p
            className={`${styles.event__currentTourTitle} ${
              in_list ? styles.event__currentTourTitle_light : ""
            }`}
          >
            Смен в вахте:
          </p>
          <div>
            <span
              className={`${styles.event__currentTourNumber} ${
                event.current_tour.vahta < event.current_tour.worked
                  ? styles.event__currentTourNumber_red
                  : event.current_tour.vahta - event.current_tour.worked <= 5
                  ? styles.event__currentTourNumber_yellow
                  : ""
              }`}
            >
              {event.current_tour.worked === -1 ? 0 : event.current_tour.worked}
            </span>
            <span className={styles.event__currentTourNumber}>/</span>
            <span className={styles.event__currentTourNumber}>
              {event?.current_tour?.vahta}
            </span>
          </div>
        </div>
      ) : null}
      {openedListName === staff_id ? (
        <div className={styles.formatForm} ref={ref}>
          {buildingOneStaffStore.getEventTypeOptions(id).map((status) => {
            const item_event_type = buildingOneStaffStore.eventTypes[status.id];
            return (
              <div
                className={styles.formatForm__btn}
                key={`add_${status.id}`}
                onClick={() => onClick(status.id)}
                id={`BuildingOneStaffTableEventBlock_openEventFormBtn_${status.id}`}
              >
                <StatusIcon
                  icon={item_event_type.custom.icon}
                  color={item_event_type.custom.color_api}
                />
                {item_event_type.title}
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(BuildingOneStaffTableEventBlock);
