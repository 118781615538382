import { useStores } from "stores/index";

import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Premium from "shared/assets/images/menuIcons/iconDefault/Premium.svg";
import PremiumSelected from "shared/assets/images/menuIcons/iconSelected/Premium.svg";

import History from "shared/assets/images/menuIcons/iconDefault/History.svg";
import HistorySelected from "shared/assets/images/menuIcons/iconSelected/History.svg";

const SalarySectionOnePremium = () => {
  const { menuStore } = useStores();

  const tabId = menuStore.tabId;

  const overview = [
    {
      text: "Описание",
      to: `/salary/premium/id=${tabId}`,
      moduleName: "premiumOne",
      submoduleName: "premiumOne",
      defaultIcon: Premium,
      selectedIcon: PremiumSelected
    },
    {
      text: "История",
      to: `/salary/premium/id=${tabId}/history`,
      moduleName: "premiumOne",
      submoduleName: "premiumOneHistory",
      defaultIcon: History,
      selectedIcon: HistorySelected
    }
  ];

  //  Раскомментировать, когда будут выполняться задачи по редактировании премии
  // const actions = [
  //   {
  //     text: "Редактировать",
  //     to: `/salary/premium/id=${tabId}/edit`,
  //     moduleName: "premiumOne",
  //     submoduleName: "premiumOneEdit",
  //     defaultIcon: Errors,
  //     selectedIcon: ErrorsSelected
  //   }
  // ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
      {/* <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection> */}
    </>
  );
};

export default SalarySectionOnePremium;
