import styles from "./staffVacationTable.module.scss";
import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { ReactComponent as IconHistory } from "shared/assets/images/mainIcons/iconsHistory/iconHistoryWithoutFill.svg";

import LoadedComponent from "widgets/LoadedComponent";

import StaffVacTableComment from "./StaffVacTableComment";
import StaffVacTablePlannedObj from "./StaffVacTablePlannedObj";
import StaffVacTableFutureStatus from "./StaffVacTableFutureStatus";
import StaffVacTableCurrentStatus from "./StaffVacTableCurrentStatus";
import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";

import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";

type StaffVacationTableProps = {
  filterItems: (arg: string) => void;
};

const StaffVacationTable = ({ filterItems }: StaffVacationTableProps) => {
  const { staffVacationStore, menuStore, filterStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      staffVacationStore.maxPage >= staffVacationStore.page &&
      staffVacationStore.page === staffVacationStore.prevPage
    ) {
      staffVacationStore.setPage(staffVacationStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {Object.keys(staffVacationStore.vacationsTableParams).length &&
      staffVacationStore.currentTitles.length ? (
        <Table className={styles.table}>
          <thead
            style={{
              top: getTHeadTop(
                filterStore.savedFiltersHeight,
                Boolean(
                  !staffVacationStore.filters[
                    staffVacationStore.downloadedFilter
                  ]?.["general"] || staffVacationStore.searchValue
                ),
                filterStore.isOpenedSavedFilters && browserName === "Firefox"
              )
            }}
          >
            <tr>
              {staffVacationStore.currentTitles.map((title) => {
                const isNotSortedIcon = [
                  "company",
                  "position",
                  "event_type",
                  "building",
                  "count_work_all",
                  "phone",
                  // те, что ниже, - хардкод, убрать, когда будут приходить поля
                  "current_status",
                  "future_status",
                  "planned_object",
                  "coordinator"
                ].includes(title);
                switch (title) {
                  case "surname":
                    return (
                      <th key={title} className={styles.title}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => filterItems(title)}
                          id="list_filter"
                        >
                          <p className="m-0">ФИО</p>
                          <img src={iconArrowDouble} />
                        </div>
                      </th>
                    );
                  // хардкод
                  case "name":
                  case "patronymic":
                  case "snils":
                  case "count_work_all":
                  case "locked":
                  case "company":
                    return;
                  default:
                    return (
                      <th key={title} className={styles.title}>
                        {isNotSortedIcon ? (
                          <div className="d-flex align-items-center">
                            <p className="m-0">
                              {staffVacationStore.getTitle(title)}
                            </p>
                          </div>
                        ) : (
                          <div
                            className="d-flex align-items-center"
                            onClick={() => filterItems(title)}
                            id={`StaffVacationTable_sort_${title}`}
                          >
                            <p className="m-0">
                              {staffVacationStore.getTitle(title)}
                            </p>
                            <img src={iconArrowDouble} />
                          </div>
                        )}
                      </th>
                    );
                }
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {staffVacationStore.vacationsList.length ? (
              staffVacationStore.vacationsList.map((oneOfStaff, index) => {
                return (
                  <tr
                    key={oneOfStaff.id}
                    className={`${!(index % 2) ? styles.greyTr : ""} ${
                      oneOfStaff["black_list"] === 1 ? styles.redTr : ""
                    } ${oneOfStaff["grey_list"] === 1 ? styles.beigeTr : ""}`}
                  >
                    {staffVacationStore.currentTitles.map((title) => {
                      const isIconOk =
                        staffVacationStore.vacationsTableParams[title][
                          "type"
                        ] === "bool" &&
                        typeof oneOfStaff[title] !== "object" &&
                        oneOfStaff[title] === 1;

                      const isIconDash =
                        staffVacationStore.vacationsTableParams[title][
                          "type"
                        ] === "bool";
                      typeof oneOfStaff[title] !== "object" &&
                        oneOfStaff[title] === 0;

                      switch (title) {
                        case "surname":
                          return (
                            <td key={title}>
                              <p
                                className={`${styles.text} ${styles.text__link}`}
                                onClick={() =>
                                  navigate(`../id=${oneOfStaff["id"]}`)
                                }
                              >{`${oneOfStaff[title]} ${oneOfStaff["name"]} ${
                                oneOfStaff["surname"] || ""
                              }`}</p>
                            </td>
                          );
                        // хардкод
                        case "name":
                        case "patronymic":
                        case "snils":
                        case "count_work_all":
                        case "locked":
                        case "company":
                          return;

                        case "event_type":
                          return (
                            <td key={title}>
                              {oneOfStaff[title]
                                ? Object.entries(oneOfStaff[title]).map(
                                    ([company, item]) => (
                                      <div
                                        key={company}
                                        className={styles.status}
                                      >
                                        <StatusIcon
                                          icon={item.icon}
                                          color={item.color}
                                        />
                                        <p className={styles.text}>
                                          {item.title}
                                        </p>
                                      </div>
                                    )
                                  )
                                : ""}
                            </td>
                          );
                        case "comment":
                          return (
                            <td key={title} className={styles.comment}>
                              <StaffVacTableComment value={oneOfStaff[title]} />
                            </td>
                          );

                        case "coordinator":
                          return (
                            <td key={title}>
                              {oneOfStaff[title] ? (
                                <div className={styles.link}>
                                  <Tooltip text="Переход в разработке">
                                    <IconHistory />
                                  </Tooltip>
                                  <p>{oneOfStaff[title]}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          );

                        case "planned_object":
                          return (
                            <td key={title}>
                              <div>
                                <StaffVacTablePlannedObj
                                  object={oneOfStaff[title]}
                                  cols={staffVacationStore.vacationsTableParams}
                                />
                              </div>
                            </td>
                          );

                        case "current_status": {
                          return (
                            <td key={title}>
                              <StaffVacTableCurrentStatus
                                data={oneOfStaff[title]}
                                cols={
                                  staffVacationStore.vacationsTableParams[
                                    "event_type"
                                  ]["directory"]
                                }
                              />
                            </td>
                          );
                        }

                        case "future_status":
                          return (
                            <td key={title}>
                              <StaffVacTableFutureStatus
                                data={oneOfStaff[title]}
                                cols={
                                  staffVacationStore.vacationsTableParams[
                                    "event_type"
                                  ]["directory"]
                                }
                              />
                            </td>
                          );

                        case "position":
                          return (
                            <td key={title} className={styles.objectCell}>
                              {oneOfStaff[title] &&
                              typeof oneOfStaff[title] === "object"
                                ? Object.values(oneOfStaff[title]).map(
                                    (item) => (
                                      <div
                                        key={`${oneOfStaff.id}_${title}_${item}`}
                                      >
                                        <p className={styles.text}>{item}</p>
                                      </div>
                                    )
                                  )
                                : ""}
                            </td>
                          );

                        case "building":
                          return (
                            <td key={title} className={styles.objectCell}>
                              {oneOfStaff[title] &&
                              typeof oneOfStaff[title] === "object"
                                ? Object.values(oneOfStaff[title]).map(
                                    (item) => (
                                      <div
                                        key={`${oneOfStaff.id}_${title}_${item}`}
                                      >
                                        <Tooltip text="Переход находится в разработке">
                                          <p
                                            className={`${styles.text} ${styles.text__link}`}
                                          >
                                            {item.title}
                                          </p>
                                        </Tooltip>
                                      </div>
                                    )
                                  )
                                : ""}
                            </td>
                          );
                        case "phone":
                          return (
                            <td key={title}>
                              {Object.values(oneOfStaff[title]).length
                                ? Object.values(oneOfStaff[title]).map(
                                    (item) => {
                                      return (
                                        <p
                                          key={`${item.number}_${item.comment}`}
                                        >
                                          {formatPhoneNumber(item["number"])}
                                        </p>
                                      );
                                    }
                                  )
                                : ""}
                            </td>
                          );
                        default:
                          return (
                            <td key={title}>
                              {typeof oneOfStaff[title] !== "object" ? (
                                <>
                                  {isIconOk ? (
                                    <StatusIcon
                                      icon="bigcheck"
                                      color="accent-green"
                                    />
                                  ) : isIconDash ? (
                                    <StatusIcon
                                      icon="dash"
                                      color="accent-orange"
                                    />
                                  ) : (
                                    <p className={styles.text}>
                                      {oneOfStaff[title]}
                                    </p>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          );
                      }
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className={styles.error}>
                <td colSpan={4}>
                  {staffVacationStore.errorMessage
                    ? staffVacationStore.errorMessage
                    : ""}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        ""
      )}
      {staffVacationStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {staffVacationStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent
          isLoading={staffVacationStore.isLoading}
          errorMessage={staffVacationStore.errorMessage}
          actionButton={staffVacationStore.actionButton}
        />
      )}
    </>
  );
};

export default observer(StaffVacationTable);
