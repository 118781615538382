import styles from "./staffCardSettings.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import PageTitle from "shared/ui/PageTitle";
import Submenu from "shared/ui/Submenu/index";

const StaffCardSettings = () => {
  const { menuStore, staffCardSettingsStore } = useStores();

  useEffect(() => {
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("cardSettings");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/cardSettings",
      title: "Сотрудники"
    });
  }, []);

  return (
    <>
      <PageTitle title="Настройка карточки сотрудника" leftCol />
      <div className={styles.main}>
        <div className={styles.menu}>
          <Submenu
            title="Блоки"
            array={Object.values(staffCardSettingsStore.mainMenu)}
            arrayItemId="id"
            arrayItemTitle="title"
            selectedItem={staffCardSettingsStore.selectedItemOfMainMenu}
            setSelectedItem={staffCardSettingsStore.setSelectedItemOfMainMenu}
          />
        </div>
        <div className={styles.menu}>
          <Submenu
            title="Основная информация"
            array={staffCardSettingsStore.submenu}
            arrayItemId="title"
            arrayItemTitle="title"
            arrayItemObject="filterdata"
            arrayItemOrdered="ordered"
            updateArray={staffCardSettingsStore.updateSubmenu}
            selectedItemColor="gray"
            selectedItem={staffCardSettingsStore.selectedItemOfSubmenu}
            setSelectedItem={staffCardSettingsStore.setSelectedItemOfSubmenu}
            iconSortImg
          />
        </div>
        <div>
          <p>Здесь будет компонент, который отображает данные по подразделу</p>
          <p>{staffCardSettingsStore.selectedItemOfSubmenu?.["title"]}</p>
          <div>
            <h5>Colors</h5>
            <div className="d-flex gap-2">
              <div className={styles.bright} />
              <div className={styles.normal} />
              <div className={styles.light} />
              <div className={styles.background} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(StaffCardSettings);
