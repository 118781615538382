import { makeAutoObservable, runInAction } from "mobx";

import { Select } from "stores/utils/types/Select";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import RootStore from "stores";

type Building = {
  address: string | null;
  city: string | null;
  company: string | null;
  lantitude: number | null;
  longitude: number | null;
  title: string | null;
};
export default class MapStore {
  error = false;
  isLoading = false;

  companiesDict: Record<string, Select> = {};
  typesDict: Record<string, Select> = {};
  statusesDict: Record<string, Select> = {};

  buildingsData: Building[] = [];

  getData = () => {
    Promise.all([
      !Object.keys(this.companiesDict).length && this.getCompaniesDict(),
      !Object.keys(this.typesDict).length && this.getTypesDict(),
      !Object.keys(this.statusesDict).length && this.getStatusesDict(),
      this.getBuildingsData()
    ]).then(() => {
      this.isLoading = false;
    });
  };

  getBuildingsData = async (filter?: {
    type: string;
    status: string;
    company: string;
  }) => {
    const params: { [key: string]: string | object } = {
      "filter[select]": [
        "title",
        "company",
        "city",
        "address",
        "lantitude",
        "longitude"
      ]
    };
    filter &&
      Object.entries(filter).forEach(([key, value]) => {
        if (key && value) {
          params[`filter[where][${key}][value]`] = value;
          params[`filter[where][${key}][comparison]`] = "LIKE";
          params[`filter[where][${key}][logic]`] = "AND";
        }
      });

    try {
      const data: ApiResponse<number | { [key: string]: Building }> =
        await this.rootStore.apiStore.getData({
          baseClass: "building",
          currentClass: "building",
          method: "getList",
          params: {
            page: 1,
            on_page: 204,
            ...params
          }
        });

      runInAction(() => {
        if (data.result !== -1) this.buildingsData = Object.values(data.result);
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesDict = async () => {
    try {
      const data: ApiResponse<
        number | { [key: string]: { id: string; title: string } }
      > = await this.rootStore.apiStore.getData({
        baseClass: "company",
        currentClass: "company",
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result).forEach(
            (company: { id: string; title: string }) =>
              (this.companiesDict[company.id] = {
                title: company.title,
                newname: company.id
              })
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTypesDict = async () => {
    try {
      const data: ApiResponse<
        number | { [key: string]: { id: string; title: string } }
      > = await this.rootStore.apiStore.getData({
        baseClass: "building",
        currentClass: "building_type",
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result).forEach(
            (type: { id: string; title: string }) =>
              (this.typesDict[type.id] = {
                title: type.title,
                newname: type.id
              })
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getStatusesDict = async () => {
    try {
      const data: ApiResponse<
        number | { [key: string]: { id: string; title: string } }
      > = await this.rootStore.apiStore.getData({
        baseClass: "building",
        currentClass: "building_status",
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result).forEach(
            (status: { id: string; title: string }) =>
              (this.statusesDict[status.id] = {
                title: status.title,
                newname: status.id
              })
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
