import styles from "./filterInfoBlock.module.scss";
import { observer } from "mobx-react-lite";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import { useStores } from "stores";
import { cloneDeep } from "lodash";

export type FilterParams = {
  [key: string]: string | number | string[];
};

type FilterProps = {
  filters: {
    [key: string]: FilterType;
  };
  downloadedFilter: string;
  item: string;

  searchValue: string;
  foundCounter?: number;
  setSearchValue: (value: string) => void;
  params: {
    [key: string]: Col;
  };
  getDataWithFilter?: (filter: FilterType) => void;
  filterParams?: FilterParams;
  isScaleChanged?: boolean;
  withoutFilterInfoBar?: boolean;
  getData?: (filters?: string) => Promise<void>;
  dateRangeFields?: Record<string, string[]>;
};

const FilterInfoBlock = ({
  filters,
  downloadedFilter,
  item,

  searchValue,
  setSearchValue,

  params,
  getDataWithFilter,
  foundCounter,
  filterParams,
  isScaleChanged,
  withoutFilterInfoBar,
  dateRangeFields,
  getData
}: FilterProps) => {
  const { filterStore, menuStore } = useStores();

  // функция возврата списка в начальное положение при загрузке данных
  const setListToInitialPosition = () => {
    menuStore.scroll?.top < 0.01
      ? menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          top: 1,
          behavior: "smooth"
        })
      : menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          behavior: "smooth"
        });
  };

  // функция для получения названия параметров для их вывода при применении сохраненных фильтров
  const getParamsName = (values: FilterParams) => {
    if (values && Object.keys(values).length) {
      return Object.keys(values).filter((key) => key.includes("filter[")).length
        ? Object.keys(values)
            .filter((key) => key.includes("filter["))
            .map((key) => {
              const paramsName = key.split("[")[1].slice(0, -1);
              if (
                dateRangeFields &&
                Object.values(dateRangeFields).flat().includes(paramsName)
              ) {
                const paramsForInfoBlock = cloneDeep(params);
                Object.keys(dateRangeFields).forEach((key) => {
                  if (key in paramsForInfoBlock) {
                    paramsForInfoBlock[dateRangeFields[key][0]] =
                      paramsForInfoBlock[key];
                    delete paramsForInfoBlock[key];
                  }
                });
                return paramsForInfoBlock[paramsName]?.["title"];
              } else return params[paramsName]?.["title"];
            })
            .filter(Boolean)
            .join(", ")
        : Object.keys(values)
            .filter((key) => key in params)
            .map((key) => params[key]?.["title"]);
    }
  };

  return ((filters && !filters[downloadedFilter]?.["default"]) ||
    searchValue ||
    (!filters && filterParams && Object.keys(filterParams).length)) &&
    !withoutFilterInfoBar ? (
    <div
      className={styles.filterInfoBlock}
      style={{
        marginLeft:
          isScaleChanged !== undefined && menuStore.scroll.scrollLeft
            ? menuStore.scroll.scrollLeft - 30
            : "-30px",
        transition: !isScaleChanged ? "margin 0.4s ease" : ""
      }}
    >
      <p className={styles.filterInfoElement}>
        Найдено {item}: <span>{foundCounter}</span>
      </p>
      {(filters && !filters[downloadedFilter]?.["default"]) ||
      (!filters && filterParams && Object.keys(filterParams).length) ? (
        <p className={styles.filterInfoElement}>
          Параметры:{" "}
          <span>
            {getParamsName(filterParams)?.length
              ? getParamsName(filterParams)
              : "-"}
          </span>
        </p>
      ) : (
        ""
      )}

      {searchValue ? (
        <p className={styles.filterInfoElement}>
          Быстрый поиск: <span>{searchValue}</span>
        </p>
      ) : (
        ""
      )}

      {!filters?.[downloadedFilter]?.["default"] || searchValue ? (
        <p
          className={styles.resetFilter}
          onClick={() => {
            searchValue && setSearchValue("");
            filterStore.setSearchInputValue("");
            setListToInitialPosition();
            if (filters) {
              Object.values(filters).forEach((filter) => {
                if (filter["default"]) getDataWithFilter(filter);
              });
            } else {
              getDataWithFilter
                ? getDataWithFilter(filterStore.currentFilter)
                : getData();
            }
          }}
          id="FilterInfoBlock_resetFilter"
        >
          {!filters?.[downloadedFilter]?.["default"]
            ? "Сбросить фильтр"
            : "Сбросить поиск"}
        </p>
      ) : (
        ""
      )}
    </div>
  ) : null;
};

export default observer(FilterInfoBlock);
