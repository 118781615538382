import styles from "./staffOnePremiumLevelHeader.module.scss";
import { useStores } from "stores";
import { fileUrl } from "stores/utils/consts";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/Avatar.svg";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";

const cols = {
  level: "Текущий уровень:",
  current_ws: "Количество смен:",
  fraction: "Вознаграждение (% от ЗП):",
  money: "Вознаграждение (сумма):"
};

const currentTitles = ["level", "current_ws", "fraction", "money"];

const StaffOnePremiumLevelHeader = () => {
  const { staffOneStore, staffOnePremiumLevelStore } = useStores();
  const { id } = useParams();

  const typeOfPremiumLevel =
    staffOnePremiumLevelStore.premium_level_selects[
      staffOnePremiumLevelStore.premium_level_current[id]?.["level"]
    ];

  return (
    <div className={styles.headerBlock}>
      <div className={styles.staffInfo}>
        <img
          src={
            staffOneStore.info["photo"]?.["src"]
              ? `${fileUrl}${staffOneStore.info["photo"]["src"]}?${Math.floor(
                  Math.random() * 100000
                )}`
              : Avatar
          }
        />
        <div className={styles.staffInfo_text}>
          <p>
            {`${staffOneStore.info["surname"]} ${staffOneStore.info["name"]}
            ${staffOneStore.info["patronymic"]}`}
          </p>
          <div className={styles.TNBlock}>
            <p className={styles.TNBlockTitle}>ТН</p>
            <p className={styles.TNBlockValue}>{staffOneStore.info["uid"]}</p>
          </div>
        </div>
      </div>
      {id in staffOnePremiumLevelStore.premium_level_current &&
      staffOnePremiumLevelStore.premium_level_current[id] &&
      Object.values(staffOnePremiumLevelStore.premium_level_current[id])
        .length ? (
        <div className={styles.premiumLevelInfo}>
          {currentTitles.map((title) => {
            switch (title) {
              case "level":
                return (
                  <div key={title} className={styles.oneBlock}>
                    <p>{cols[title]}</p>
                    <div className={styles.rowWithIcon}>
                      {typeOfPremiumLevel?.["img"] ? (
                        <img src={`${fileUrl}${typeOfPremiumLevel["img"]}`} />
                      ) : (
                        ""
                      )}
                      <p>{typeOfPremiumLevel?.["title"]}</p>
                    </div>
                  </div>
                );
              case "current_ws":
                return (
                  <div key={title} className={styles.oneBlock}>
                    <p>{cols[title]}</p>
                    <p>
                      {
                        staffOnePremiumLevelStore.premium_level_current[id][
                          title
                        ]
                      }
                    </p>
                  </div>
                );
              case "fraction":
                return (
                  <div key={title} className={styles.oneBlock}>
                    <p>{cols[title]}</p>
                    <p>
                      {`${numberWithSpaces(
                        typeOfPremiumLevel?.["custom"]?.["fraction"] || null,
                        false,
                        1
                      )} %`}
                    </p>
                  </div>
                );
              case "money":
                return (
                  <div key={title} className={styles.oneBlock}>
                    <p>{cols[title]}</p>
                    <p>
                      {numberWithSpaces(
                        staffOnePremiumLevelStore.premium_level_current[id][
                          title
                        ],
                        true
                      )}
                    </p>
                  </div>
                );
            }
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOnePremiumLevelHeader);
