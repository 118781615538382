import styles from "./actHoursField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState } from "react";
import { useParams } from "react-router-dom";

import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";

import { classNames } from "shared/utils/helpers/classNames";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";
import { getValues } from "shared/utils/helpers/getValues";

const HOURS_FIELDS_GROUP = ["total", "act_hours"];

const ActHoursField = () => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();

  const [isHovering, setIsHovering] = useState(false);

  let workHours = 0;
  getValues(aregisterOneStore.presavesStaffList[id] || {}).forEach(
    (staff) => (workHours += staff.total.work_hours_total)
  );
  const hours_fields = {
    total: {
      title: "Итого часы",
      value: workHours || "0.00"
    },
    act_hours: {
      title: aregisterOneStore.cols.act_hours?.title,
      value: aregisterOneStore.selectedOne.act_hours
    }
  };
  const has_hours = isHovering
    ? aregisterOneStore.selectedOne.act_hours
      ? true
      : false
    : false;

  const hours_matches = workHours === aregisterOneStore.selectedOne.act_hours;
  const setIsHoveringField = (val: boolean) => () => setIsHovering(val);
  const getValue = (name: string) => hours_fields[name]?.value || "";

  return (
    <div
      onMouseEnter={setIsHoveringField(true)}
      onMouseLeave={setIsHoveringField(false)}
    >
      <Tooltip
        text={hours_matches ? "Часы совпадают" : "Часы не совпадают"}
        borderColor="bw-gray5"
        placement="right"
      >
        <TextFieldGroup
          name="total"
          fields={HOURS_FIELDS_GROUP}
          titles={HOURS_FIELDS_GROUP}
          cols={hours_fields}
          getValue={getValue}
          className={{
            row: classNames("", {
              [styles.ok]: isHovering && has_hours,
              [styles.error]: isHovering && !has_hours
            })
          }}
        >
          {() => (
            <StatusIcon
              icon={hours_matches ? "circlecheck" : "attention"}
              color={hours_matches ? "accent-green" : "accent-coral"}
              className={styles.icon}
            />
          )}
        </TextFieldGroup>
      </Tooltip>
    </div>
  );
};

export default observer(ActHoursField);
