import styles from "./filterHeader.module.scss";
import { useEffect, useRef } from "react";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

import { Formik } from "formik";
import SearchInput from "shared/ui/Inputs/SearchInput/index";
import StatusIcon from "shared/ui/StatusIcon";
import { CSVLink } from "react-csv";

import { ReactComponent as IconFilterEdit } from "shared/assets/images/mainIcons/iconFilterEdit.svg";
import { ReactComponent as IconExportBW } from "shared/assets/images/mainIcons/iconExport/iconExportWithoutFill.svg";
import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";

import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import { FilterType } from "stores/utils/types/FilterType";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";

type FilterHeaderProps = {
  isLoading: boolean;
  searchValue: string;
  downloadedFilter: string;
  selectedFilter: string;
  filters: { [key: string]: FilterType };

  isSearchable?: boolean;
  isExport?: boolean;
  listOrdered?: string;
  dataFileName?: string;
  queryParams?: { [key: string]: string };

  setSearchValue: (value: string) => void;
  getDataWithFilter: (filter: FilterType) => void;
  setDownloadedFilter: (value: string) => void;
  setSelectedFilter: (value: string) => void;

  setQueryParams?: (value: { [key: string]: string }) => void;
  getData: (filters?: string) => Promise<void>;
  createDataFile?: (extension: "csv" | "xls") => (
    | {
        [key: string]: string | number | string[];
      }
    | (string | number | string[])[]
  )[];
  handleSetQueryParams?: (values: FilterType) => void;
  setListToInitialPosition: () => void;
  searchInputTooltip?: string;
  withoutAdvancedSearch?: boolean;
  withoutSavedFilter?: boolean;
  withoutColsSetting?: boolean;
  withoutQueryParams?: boolean;
  fixedWidthSearchInput?: boolean;
};

const FilterHeader = ({
  isLoading,
  filters,
  searchValue,
  setSearchValue,
  getData,
  downloadedFilter,
  selectedFilter,
  setDownloadedFilter,
  setSelectedFilter,
  getDataWithFilter,
  setQueryParams,
  setListToInitialPosition,
  listOrdered,
  queryParams,
  isSearchable,
  isExport,
  createDataFile,
  dataFileName,
  searchInputTooltip,
  withoutAdvancedSearch,
  withoutSavedFilter,
  withoutColsSetting,
  withoutQueryParams,
  fixedWidthSearchInput
}: FilterHeaderProps) => {
  const { modalWindowsStore, menuStore, filterStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef(null);
  const savedFiltersRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    ref,
    handler: () => filterStore.setIsOpenedExport(false)
  });

  useEffect(() => {
    try {
      filterStore.setIsOpenAdvancedSearch(
        Boolean(JSON.parse(localStorage.getItem("isOpenAdvancedSearch") || ""))
      );
    } catch (error) {
      filterStore.setIsOpenAdvancedSearch(false);
    }
  }, []);

  useEffect(() => {
    try {
      filterStore.setIsOpenedSavedFilter(
        Boolean(JSON.parse(localStorage.getItem("isOpenSavedFilters") || ""))
      );
    } catch (error) {
      filterStore.setIsOpenedSavedFilter(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "isOpenAdvancedSearch",
      filterStore.isOpenAdvancedSearch ? "true" : ""
    );

    localStorage.setItem(
      "isOpenSavedFilters",
      filterStore.isOpenedSavedFilters ? "true" : ""
    );
  }, [filterStore.isOpenAdvancedSearch, filterStore.isOpenedSavedFilters]);

  useEffect(() => {
    const height = savedFiltersRef?.current?.getBoundingClientRect()?.height;
    filterStore.setSavedFiltersHeight(
      filterStore.isOpenedSavedFilters
        ? // мин.высота кнопки сохр.фильтра 40px
          // при первом открытии станицы с открытыми сохр.фильтрами
          // высота блока 39.98, когда реальная высота 51.98
          // поэтому, если высота меньше 40
          height < 41
          ? // прибавляем ещё 12
            height + 12
          : height
        : 0
    );
  }, [filterStore.isOpenedSavedFilters, savedFiltersRef.current?.offsetHeight]);

  const handleFindData = (values: { searchInputValue: string }) => {
    setListToInitialPosition();

    setSearchValue(values.searchInputValue);
    if (filters) {
      getValues(filters).forEach((filter) => {
        if (filter["default"]) getDataWithFilter(filter);
      });
    } else {
      getData();
    }
    if (!withoutQueryParams) {
      const updatedQueryParams = {
        ...queryParams,
        ordered: listOrdered,
        fast_search: values.searchInputValue,
        selectedFilter: downloadedFilter
      };
      setQueryParams && setQueryParams(updatedQueryParams);
      setSearchParams(updatedQueryParams);
    }

    inputRef.current?.blur();
  };

  const clearSearch = (setFieldValue) => {
    setFieldValue("searchInputValue", "");
    filterStore.setSearchInputValue("");
    if (searchValue) {
      if (!withoutQueryParams) {
        if ([...searchParams.values()].length === 3) {
          setQueryParams && setQueryParams({});
          setSearchParams({});
        } else {
          const paramsWithoutSearchValue: { [key: string]: string } = {};
          getEntries(queryParams || {}).forEach(([key, value]) => {
            if (key !== "fast_search") paramsWithoutSearchValue[key] = value;
          });
          setQueryParams && setQueryParams(paramsWithoutSearchValue);
          setSearchParams(paramsWithoutSearchValue);
        }
      }

      setSearchValue("");
      getData();
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue
  ) => {
    if (!isLoading && !modalWindowsStore.isErrorWindow) {
      setFieldValue("searchInputValue", e.target.value);
      filterStore.setSearchInputValue(e.target.value);
    }
  };

  return (
    <div
      className={`${styles.filterHeaderContainer} ${
        menuStore.isMenuShown
          ? styles.filterHeaderContainer_withOpenedMenu
          : styles.filterHeaderContainer_withClosedMenu
      }`}
    >
      <>
        <div className={styles.filterHeader}>
          <div className={styles.leftSideRow}>
            {isSearchable ? (
              <Formik
                initialValues={{
                  searchInputValue: filterStore.searchInputValue || ""
                }}
                onSubmit={handleFindData}
                enableReinitialize
              >
                {({ values, setFieldValue }) => (
                  <div
                    className={`${styles.searchInputBlock} ${
                      fixedWidthSearchInput
                        ? styles.searchInputBlock_fixedWidth
                        : ""
                    }`}
                  >
                    <SearchInput
                      name="searchInputValue"
                      inputRef={inputRef}
                      handleFindData={() => handleFindData(values)}
                      blurCondition={
                        isLoading && modalWindowsStore.isErrorWindow
                      }
                      clearSearch={() => clearSearch(setFieldValue)}
                      onChange={(e) => handleInputChange(e, setFieldValue)}
                      pattern={(value) =>
                        value.replace(
                          /[.,±§*+<>@#%№:^&;_"'`~=?!^${}()|[\]\\/]/g,
                          ""
                        )
                      }
                      fixWidth
                    />
                    {searchInputTooltip && (
                      <div
                        className={styles.tooltipSearchInput}
                        data-tooltip={searchInputTooltip}
                      >
                        <IconQuestionSmall className={styles.iconQuestion} />
                      </div>
                    )}
                  </div>
                )}
              </Formik>
            ) : null}

            {!withoutAdvancedSearch ? (
              <div
                className={`${styles.dropOption} ${
                  filterStore.isOpenAdvancedSearch
                    ? styles.selectedDropOption
                    : ""
                }`}
                id="FilterHeader_setIsOpenAdvancedSearch"
                onClick={() => {
                  !isLoading &&
                    filterStore.setIsOpenAdvancedSearch(
                      !filterStore.isOpenAdvancedSearch
                    );

                  if (filterStore.isOpenAdvancedSearch) {
                    setTimeout(() => {
                      menuStore.scrollbarRef.current?.view?.scroll({
                        top: 0,
                        behavior: "smooth"
                      });
                    }, 100);
                  }

                  filterStore.setIsOpenedColumnsOptions(false);
                  filterStore.setIsOpenedSavedFilterOptions(false);
                }}
              >
                {filterStore.isDiff &&
                !(
                  filterStore.isOpenedColumnsOptions ||
                  filterStore.isOpenedSavedFilterOptions
                ) ? (
                  <div className={styles.diffCircle}></div>
                ) : (
                  ""
                )}
                Расширенный поиск
                <div
                  className={
                    filterStore.isOpenAdvancedSearch
                      ? styles.transformedPosition
                      : styles.defaultPosition
                  }
                >
                  <StatusIcon
                    icon="iconexpand"
                    color={
                      filterStore.isOpenAdvancedSearch
                        ? "blue-lazure"
                        : "bw-gray5"
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {!withoutSavedFilter ? (
              <div
                className={`${styles.dropOption} ${
                  filterStore.isOpenedSavedFilters
                    ? styles.selectedDropOption
                    : ""
                }`}
                id="FilterHeader_setIsOpenSavedFilters"
                onClick={() =>
                  filterStore.setIsOpenedSavedFilter(
                    !filterStore.isOpenedSavedFilters
                  )
                }
              >
                {filterStore.isDiff &&
                selectedFilter !== "new" &&
                (filterStore.isOpenedColumnsOptions ||
                  filterStore.isOpenedSavedFilterOptions) ? (
                  <div className={styles.diffCircle}></div>
                ) : (
                  ""
                )}
                Сохранённые фильтры
                <div
                  className={
                    filterStore.isOpenedSavedFilters
                      ? styles.transformedPosition
                      : styles.defaultPosition
                  }
                >
                  <StatusIcon
                    icon="iconexpand"
                    color={
                      filterStore.isOpenedSavedFilters
                        ? "blue-lazure"
                        : "bw-gray5"
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={styles.rightSideRow}>
            {!withoutColsSetting &&
            !filters?.[downloadedFilter]?.["general"] ? (
              <>
                <Button
                  className={classNames(
                    styles.btnColSettings,
                    {
                      [styles.btnActive]: filterStore.isOpenedColumnsOptions
                    },
                    [styles.tooltip]
                  )}
                  theme={ButtonTheme.SECONDARY}
                  size={ButtonSize.S}
                  onClick={() => {
                    !isLoading &&
                      filterStore.setIsOpenedColumnsOptions(
                        !filterStore.isOpenedColumnsOptions
                      );
                    if (filterStore.isOpenedColumnsOptions) {
                      setTimeout(() => {
                        filterStore.isOpenedColumnsOptions &&
                          menuStore.scrollbarRef.current?.view?.scroll({
                            top: 0,
                            behavior: "smooth"
                          });
                      }, 100);
                    }
                    setSelectedFilter && setSelectedFilter(downloadedFilter);
                    filterStore.setIsOpenAdvancedSearch(false);
                    filterStore.setIsOpenedSavedFilterOptions(false);
                  }}
                  id="FilterHeader_setIsOpenedColumnsOptions_btn"
                  data-tooltip="Настройка таблицы"
                >
                  <StatusIcon
                    icon="iconfilter"
                    color={
                      filterStore.isOpenedColumnsOptions
                        ? "bw-white"
                        : "blue-lazure"
                    }
                  />
                </Button>
              </>
            ) : (
              ""
            )}

            {filterStore.isOpenedExport ? (
              <div className={styles.formatForm} ref={ref}>
                <CSVLink
                  data={createDataFile("xls")}
                  separator={";"}
                  filename={`${dataFileName}.xls`}
                >
                  <button className={styles.formatFormBtn}>
                    Excel (кодировка Windows-1251)
                  </button>
                </CSVLink>
                <CSVLink
                  data={createDataFile("csv")}
                  separator={";"}
                  filename={`${dataFileName}.csv`}
                >
                  <button className={styles.formatFormBtn}>
                    CSV (кодировка UTF-8)
                  </button>
                </CSVLink>
              </div>
            ) : (
              ""
            )}
            {isExport ? (
              <Button
                className={classNames(
                  "",
                  {
                    [styles.btnActive]: filterStore.isOpenedExport
                  },
                  [styles.tooltip]
                )}
                theme={ButtonTheme.SECONDARY}
                size={ButtonSize.S}
                onClick={() => {
                  filterStore.setIsOpenedExport(!filterStore.isOpenedExport);
                }}
                data-tooltip="Экспорт"
                id="FilterHeader_export"
              >
                <IconExportBW
                  className={
                    filterStore.isOpenedExport
                      ? styles.iconExport_active
                      : styles.iconExport
                  }
                />
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        {!withoutSavedFilter ? (
          <div
            className={`${styles.filtersBlock} ${
              filterStore.isOpenedSavedFilters
                ? styles.filtersBlock_opened
                : styles.filtersBlock_closed
            }`}
            ref={savedFiltersRef}
          >
            <div className={styles.filtersBlock__savedFilters}>
              <IconFilterEdit className={styles.iconFilterEdit} />
              {!isEmpty(filters)
                ? getEntries(filters).map(([id, filter]) => {
                    const handleSelectDownloadedFilter = () => {
                      filterStore.setIsDownloadedFilterSelected(true);
                      setSearchValue && setSearchValue("");
                      downloadedFilter !== id && setDownloadedFilter(id);
                      getDataWithFilter(filter);
                      selectedFilter !== "new" &&
                        setSelectedFilter &&
                        setSelectedFilter(id);
                      setListToInitialPosition();
                      setTimeout(() => {
                        filterStore.setIsOpenAdvancedSearch(false);
                      }, 1000);
                    };
                    return (
                      <Button
                        theme={ButtonTheme.TAB}
                        key={id}
                        id={id}
                        selected={downloadedFilter === id}
                        onClick={handleSelectDownloadedFilter}
                      >
                        {filter.title}
                      </Button>
                    );
                  })
                : null}
            </div>
            {!filters?.[downloadedFilter]?.["general"] &&
            downloadedFilter !== "new" &&
            downloadedFilter ? (
              <Button
                className={classNames(styles.tooltip, {
                  [styles.btnActive]: filterStore.isOpenedSavedFilterOptions
                })}
                theme={ButtonTheme.SECONDARY}
                size={ButtonSize.S}
                onClick={() => {
                  !isLoading &&
                    !filters[downloadedFilter]?.["general"] &&
                    filterStore.setIsOpenedSavedFilterOptions(
                      !filterStore.isOpenedSavedFilterOptions
                    );
                  if (filterStore.isOpenedSavedFilterOptions) {
                    setTimeout(() => {
                      filterStore.isOpenedSavedFilterOptions &&
                        menuStore.scrollbarRef.current?.view?.scroll({
                          top: 0,
                          behavior: "smooth"
                        });
                    }, 100);
                  }

                  setSelectedFilter(downloadedFilter);
                  filterStore.setIsOpenAdvancedSearch(false);
                  filterStore.setIsOpenedColumnsOptions(false);
                }}
                id="FilterHeader_settings"
                data-tooltip="Настройка сохранённых фильтров"
              >
                <StatusIcon
                  icon="iconsettings"
                  color={
                    filterStore.isOpenedSavedFilterOptions
                      ? "bw-white"
                      : "blue-lazure"
                  }
                />
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
};

export default observer(FilterHeader);
