import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import styles from "./buttonsGroupForSettings.module.scss";
import { classNames } from "shared/utils/helpers/classNames";

type ButtonsGroupForSettingsProps = {
  saveBtnTitle?: string;
  saveBtnDisabled?: boolean;
  saveBtnOnClick?: () => void;
  addBtnTitle?: string;
  addBtnDisabled?: boolean;
  addBtnOnClick?: () => void;
  addBtnImg?: JSX.Element;
  cancelBtnTitle?: string;
  cancelBtnDisabled?: boolean;
  cancelBtnOnClick?: () => void;
  cancelBtnImg?: JSX.Element;
  className?: { cancelBtn?: string; addBtn?: string; saveBtn?: string };
};

const ButtonsGroupForSettings = ({
  saveBtnTitle,
  saveBtnDisabled,
  saveBtnOnClick,
  addBtnTitle,
  addBtnDisabled,
  addBtnOnClick,

  addBtnImg,
  cancelBtnTitle,
  cancelBtnDisabled,
  cancelBtnOnClick,
  cancelBtnImg,
  className
}: ButtonsGroupForSettingsProps) => {
  return (
    <div className={styles.btnGroup}>
      {saveBtnTitle && (
        <Button
          type="button"
          className={classNames("", {}, [className?.saveBtn])}
          size={ButtonSize.L}
          disabled={saveBtnDisabled}
          onClick={saveBtnOnClick}
          id="ButtonsGroupForSettings_saveBtn"
        >
          {saveBtnTitle}
        </Button>
      )}
      {addBtnTitle && (
        <Button
          type="button"
          className={classNames(styles.addBtn, {}, [className?.addBtn])}
          size={ButtonSize.L}
          theme={ButtonTheme.SECONDARY}
          disabled={addBtnDisabled}
          onClick={addBtnOnClick}
          id="ButtonsGroupForSettings_addBtnTitle"
        >
          {addBtnImg ? addBtnImg : null}
          <p>{addBtnTitle}</p>
        </Button>
      )}
      {cancelBtnTitle && (
        <Button
          type="button"
          className={classNames(styles.cancelBtn, {}, [className?.cancelBtn])}
          size={ButtonSize.L}
          theme={ButtonTheme.CANCELLED}
          disabled={cancelBtnDisabled}
          onClick={cancelBtnOnClick}
          id="ButtonsGroupForSettings_cancelBtnTitle"
        >
          {cancelBtnImg ? cancelBtnImg : null}
          <p>{cancelBtnTitle}</p>
        </Button>
      )}
    </div>
  );
};

export default ButtonsGroupForSettings;
