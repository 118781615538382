import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";

type FormikReloadProps = {
  data: Partial<IRecordsForEdit>;
  cols: string[];
  setRequiredFields: (arg: string[]) => void;
};

const FormikReload = ({ data, cols, setRequiredFields }: FormikReloadProps) => {
  const { staffOneEditStore } = useStores();
  const { handleReset } = useFormikContext();

  const { id, edit } = useParams();

  //   в useEffect ниже при смене id или группы редактировани, мы передаём обязательные поля в стейт
  //  и перезагружаем форму, иначе Formik не обновит валидацию
  useEffect(() => {
    if (cols.length) {
      setRequiredFields(staffOneEditStore.getRequiredCols(cols, data));

      handleReset();
    }
  }, [id, edit]);

  return <></>;
};

export default observer(FormikReload);
