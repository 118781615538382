import { object, string } from "yup";
import { dateSchema } from "shared/utils/validation/validation";

const validation = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = string().nullable();
    if (col.includes("date")) {
      validationSchema[col] = dateSchema;
    }
    if (requiredCols.includes(col))
      validationSchema[col] = validationSchema[col].required(
        "Поле обязательно для заполнения"
      );
  });

  return object(validationSchema);
};

export default validation;
