import { Col } from "stores/StaffModule/types/Col";
import { makeAutoObservable, runInAction } from "mobx";

import { Project } from "../utils/types/Project";
import { TableParams } from "stores/utils/types/TableParams";
import { Application } from "stores/AregisterModule/types/Application";
import { Aregister } from "stores/AregisterModule/types/Aregister";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { CustomFields } from "stores/utils/types/CustomFields";

import { Formula } from "./types/Formula";
import { FormulasDictItem } from "./types/FormulasDictItem";
import { MastersGangers } from "./types/MastersGangers";

import RootStore from "stores";

import { format, parse } from "date-fns";
import { datePattern } from "shared/utils/validation/validation";

type Status = {
  [key: string]: string | number;
} & {
  title: string;
  custom: {
    btn_text: string;
    color: string;
    done: string;
  };
};

type MastersAndGangers = {
  [key: string]: {
    title: string;
    id: string;
  };
};

export default class AregisterFormStore {
  isLoading = false;
  isLoadingField = "";
  isLoadingCustomFields = false;
  error = false;
  errorsMessage: {
    [aregister_id: string]: { [error_name: string]: Errors };
  } = {};

  newAregisterValues: Application = {};

  openedAllAregisterValues: {
    [key: string]: Application;
  } = {};

  cols: { [key: string]: Col } = {};
  params: {
    [key: string]: TableParams;
  } = {};

  statusesDict: { [key: string]: Status } = {};
  projects: { [key: string]: Project } = {};

  newAregisterObj: Application = {};

  openedAllInitialValuesForEdit: { [key: string]: Partial<Application> } = {};
  openedAllInitialValuesForMassCopy: { [key: string]: Partial<Application> } =
    {};

  masterList: {
    [key: string]: MastersAndGangers;
  } = {};
  brigadierList: {
    [key: string]: MastersAndGangers;
  } = {};
  pairsList: {
    [key: string]: {
      [key: string]: {
        id: string;
        title: string;
      };
    };
  } = {};

  aregisters: {
    [key: string]: Aregister;
  } = {};

  formula: { [key: string]: Formula } = {};
  formulasDict: Partial<{
    items: FormulasDictItem;
    translate: {
      [key: string]: { description: string };
    };
  }> = {};

  setFormFields = (type: string) => {
    this.isLoading = true;

    Promise.all([
      !Object.values(this.params).length && this.getTableParams(),
      !Object.values(this.cols).length && this.getTableCols(),
      !Object.values(this.statusesDict).length && this.getStatuses(),
      !Object.values(this.projects).length && this.getProjects(),
      !Object.values(this.formulasDict).length && this.getFormulasDict()
    ]).then(() => {
      runInAction(() => {
        if (type === "add") {
          Object.entries(this.cols).forEach(([key, field]) => {
            if (
              this.params[key]["add_show"] ||
              this.params[key]["show_default"]
            ) {
              if (key === "date_start" || key === "date_end")
                return (this.newAregisterObj[key] = format(
                  new Date(),
                  "yyyy-MM-dd"
                ));
              switch (field.type) {
                case "date":
                  return (this.newAregisterObj[key] = null);
                case "bool":
                  return (this.newAregisterObj[key] = 0);
                default:
                  if (key.includes("comment")) {
                    return (this.newAregisterObj[key] = "");
                  } else {
                    return (this.newAregisterObj[key] = null);
                  }
              }
            }
          });

          // hardcode (создание отчета мастера)
          this.newAregisterObj.create_report = 0;

          setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
        }
      });
    });
  };

  getInitialValuesForEdit = (aregister: Partial<Aregister>) => {
    Promise.all([
      !Object.values(this.projects).length && this.getProjects(),
      !Object.values(this.params).length && this.getTableParams(),
      !Object.values(this.cols).length && this.getTableCols(),
      !Object.values(this.statusesDict).length && this.getStatuses(),
      !Object.values(this.formulasDict).length && this.getFormulasDict()
    ]).then(() => {
      Promise.all([this.getCustomFields(aregister.project.id)]).then(() => {
        const initialValues: Partial<Application> = {};

        Object.keys(this.cols).length &&
          Object.keys(this.cols).forEach((key) => {
            if (
              (this.rootStore.aregisterOneEditStore.editableFields[
                aregister.id
              ]?.includes(key) &&
                this.params[key]?.editable === "on") ||
              key === "project"
            ) {
              if (key.includes("comment") && aregister[key] === null) {
                return (initialValues[key] = "");
              }

              if (key === "project") {
                new Promise(() =>
                  this.getMastersAndGangers(
                    aregister.project.id as string,
                    aregister.date_start as string,
                    aregister.date_end as string,
                    aregister.id as string
                  )
                );
              }

              switch (key) {
                case "master":
                  if (aregister.masterless) {
                    initialValues[key] = "masterless";
                  } else {
                    initialValues[key] = aregister[key].id || null;
                  }
                  break;
                case "manual_hours":
                  if (
                    this.projects[aregister.project.id].allow_manual_hours === 1
                  ) {
                    initialValues.allow_manual_hours =
                      aregister[key] || aregister[key] !== "NULL" ? 1 : 0;
                    initialValues[key] =
                      aregister[key] === "NULL" || !aregister[key]
                        ? null
                        : aregister[key];
                  }
                  break;
                case "manual_size":
                  if (
                    this.projects[aregister.project.id].allow_manual_size === 1
                  ) {
                    initialValues[key] =
                      !aregister[key] || aregister[key] === "NULL" ? 0 : 1;
                  }

                  break;
                case "kc":
                  if (!("no_kc" in initialValues && !("no_kc" in aregister))) {
                    initialValues["no_kc"] =
                      aregister[key] === "NULL" || !aregister[key] ? 1 : 0;
                  }
                  initialValues[key] =
                    aregister[key] === "NULL" || !aregister[key]
                      ? null
                      : aregister[key];
                  break;
                case "no_kc":
                  if (!(key in initialValues)) {
                    initialValues[key] =
                      aregister[key] === "NULL" ||
                      !aregister[key] ||
                      !aregister[key]
                        ? 0
                        : 1;
                  }
                  break;
                case "measurements":
                  ["length", "width", "height", "size"].forEach((title) => {
                    initialValues[title] =
                      aregister[title] === "NULL" || !aregister[title]
                        ? null
                        : aregister[title];
                  });
                  initialValues[key] =
                    aregister[key] === "NULL" || !aregister[key]
                      ? null
                      : aregister[key];
                  break;
                case "custom_fields":
                  if (aregister[key] && aregister[key] !== "NULL") {
                    initialValues[key] = {};
                    Object.entries(aregister[key]).forEach(([name, value]) => {
                      if (
                        this.projects[aregister.project.id].custom
                          ?.custom_fields?.[name]?.type === "date" &&
                        datePattern.test(value)
                      ) {
                        initialValues[key][name] = format(
                          parse(value, "dd.MM.yyyy", new Date()),
                          "yyyy-MM-dd"
                        );
                      } else {
                        // Приведение к строчному типу, т.к. кастомные поля могут приходить числом
                        initialValues[key][name] = value ? String(value) : null;
                      }
                    });
                  } else
                    initialValues[key] =
                      aregister[key] === "NULL" || !aregister[key]
                        ? null
                        : (aregister[key] as null);
                  break;
                case "length":
                case "height":
                case "width":
                  return;
                default:
                  return (initialValues[key] = aregister[key]?.id
                    ? aregister[key].id
                    : aregister[key] === "NULL" || !aregister[key]
                    ? null
                    : // Приведение к строчному типу, т.к. многие поля приходят числом
                    // а для корректной валидации и работы Formik + Input нужен строчный тип данных
                    this.cols[key]?.type !== "bool" &&
                      typeof aregister[key] === "number"
                    ? String(aregister[key])
                    : aregister[key]);
              }
            }
          });

        runInAction(() => {
          this.openedAllInitialValuesForEdit[aregister.id] = initialValues;
          this.isLoading = false;
        });
      });
    });
  };

  // Изменить функцию при правках в масс.копировании как в getInitialValuesForEdit
  getInitialValuesForMasscopy = (aregister: Partial<Application>) => {
    Promise.all([
      !Object.values(this.params).length && this.getTableParams(),
      !Object.values(this.cols).length && this.getTableCols(),
      !Object.values(this.statusesDict).length && this.getStatuses(),
      !Object.values(this.projects).length && this.getProjects(),
      !Object.values(this.formulasDict).length && this.getFormulasDict()
    ]).then(() => {
      Promise.all([this.getCustomFields(aregister.project as string)]).then(
        () => {
          const initialValues: Partial<Application> = {};

          Object.keys(this.cols).length &&
            Object.keys(this.cols).forEach((key) => {
              if (
                this.params[key]?.add_show === "on" ||
                this.params[key]?.show_default === "on" ||
                key === "custom_fields"
              ) {
                if (key.includes("comment") && aregister[key] === null) {
                  return (initialValues[key] = "");
                }

                switch (key) {
                  case "master":
                    if (aregister.masterless) {
                      initialValues[key] = "masterless";
                    } else {
                      initialValues[key] =
                        aregister[key] === "NULL" ? null : aregister[key];
                    }
                    break;
                  case "manual_hours":
                    // Изменить логику отображения manual_hours и manual_size  при следующих правках масс.копирования
                    initialValues.allow_manual_hours = aregister[key] ? 1 : 0;
                    initialValues[key] =
                      aregister[key] === "NULL" ? null : aregister[key];
                    break;
                  case "manual_size":
                    initialValues.allow_manual_size = aregister[key] ? 1 : 0;
                    initialValues[key] =
                      aregister[key] === "NULL" ? null : aregister[key];
                    break;
                  case "kc":
                    if (
                      !("no_kc" in initialValues && !("no_kc" in aregister))
                    ) {
                      initialValues["no_kc"] =
                        aregister[key] === "NULL" || !aregister[key] ? 1 : 0;
                    }
                    initialValues[key] =
                      aregister[key] === "NULL" ? null : aregister[key];
                    break;
                  case "no_kc":
                    if (!(key in initialValues)) {
                      initialValues[key] =
                        aregister[key] === "NULL" || !aregister[key] ? 0 : 1;
                    }
                    break;
                  case "measurements":
                    ["length", "width", "height", "size"].forEach((title) => {
                      initialValues[title] =
                        aregister[title] === "NULL" ? 0 : aregister[title];
                    });
                    initialValues[key] =
                      aregister[key] === "NULL" ? 0 : aregister[key];
                    break;
                  case "custom_fields":
                    initialValues[key] = aregister[key];
                    return new Promise(() =>
                      this.getCustomFields(aregister.project as string)
                    );
                  default:
                    return (initialValues[key] =
                      aregister[key] === "NULL" ? null : aregister[key]);
                }
              }
            });

          runInAction(() => {
            this.openedAllInitialValuesForMassCopy[aregister.id] =
              initialValues;
            this.isLoading = false;
          });
        }
      );
    });
  };

  getRequiredFields = (cols: string[]) => {
    const requiredFields: string[] = [];
    cols.forEach((col) => {
      if (this.params[col]?.required) {
        requiredFields.push(col);
      }
    });

    return requiredFields;
  };

  getStatuses = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Status } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          currentClass: "application_register_status",
          method: "getList"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          const types = {
            "3894467eff96de70b066bc0b824c871ece1be040": "opened",
            a2fd9081c65bf3951e7457b570ebd8873d44be27: "closed",
            "643ab623e6253440368813fafcaa013d5cbc72a9": "cancelled"
          };

          this.statusesDict = data["result"];

          Object.keys(data["result"]).forEach((id) => {
            this.statusesDict[id].type = types[id];
          });
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setDirectory = (
    id?: string,
    name?: string,
    aregister?: string,
    copy?: number
  ) => {
    // хардкод, удалить при подключении API с договорами
    if (name === "contracts" && aregister !== "add") {
      if (this.params[name].variable) {
        return this.rootStore.aregisterOneEditStore.selectedOneForEdit.contracts
          ? {
              [this.rootStore.aregisterOneEditStore.selectedOneForEdit.contracts
                .id]:
                this.rootStore.aregisterOneEditStore.selectedOneForEdit
                  .contracts,
              ...this.params[name].variable
            }
          : this.params[name].variable;
      }
    }
    if (["master", "brigadier"].includes(name)) {
      const list: Partial<{
        [key: string]: MastersAndGangers;
      }> =
        name === "master"
          ? this.masterList
          : name === "brigadier"
          ? this.brigadierList
          : {};

      if (copy !== undefined) {
        return list[`copies_${aregister}_${copy}`]
          ? list[`copies_${aregister}_${copy}`]
          : {};
      } else {
        return list[aregister] ? list[aregister] : {};
      }
    }

    if (id && name && this.projects[id]) {
      const directory = {};
      const project = this.projects[id];

      if (project) {
        switch (name) {
          case "species":
            Object.values(project.scaffold_species).length &&
              project.scaffold_species !== "NULL" &&
              Object.values(project.scaffold_species).forEach((key) => {
                // hardcode (убрать проверку)
                this.params[name].variable[key]
                  ? (directory[key] = this.params[name].variable[key])
                  : "";
              });
            break;
          case "type":
            Object.values(project.scaffold_type).length &&
              project.scaffold_type !== "NULL" &&
              Object.values(project.scaffold_type).forEach((key) => {
                // hardcode (убрать проверку)
                this.params[name].variable[key]
                  ? (directory[key] = this.params[name].variable[key])
                  : "";
              });
            break;
          case "coordinates":
            if (
              Object.values(project[name]).length &&
              project[name] !== "NULL"
            ) {
              // Здесь title и id приводятся к строке, т.к. значения могут быть числовыми и ломают ItemsScrollBoard
              Object.values(project[name]).forEach((key) => {
                directory[key] = {
                  title: String(key),
                  id: String(key)
                };
              });
            }
            break;
          case "type_work":
          case "sub_work":
            Object.values(project[name]).length &&
              project[name] !== "NULL" &&
              Object.values(
                project[name] as {
                  [key: string]: string;
                }
              ).forEach((key) => {
                // hardcode
                this.params[name].variable[key]
                  ? (directory[key] = this.params[name].variable[key])
                  : "";
              });
            break;
          default:
            if (this.params[name].variable) {
              return this.params[name].variable;
            }
            return;
        }
      }
      return directory;
    } else if (this.params[name].variable) {
      return this.params[name].variable;
    }
    return {};
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: TableParams;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register",
        method: "getTableParams"
      });

      runInAction(() => {
        if (!data.errors) {
          this.params = data.result;

          Promise.all([
            this.getSpecies(),
            this.getTypeWork(),
            this.getTypeSubWork(),
            this.getTypes()
          ]).then(() => {
            if (this.params.coordinates) {
              runInAction(() => {
                this.params.coordinates.isvariable = "on";
              });
            }

            if (this.params.contracts) {
              runInAction(() => {
                this.params.contracts.isvariable = "on";
                // TODO: исправить в задаче 529
                 this.params.contracts.variable = {};
              });
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: Col;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register",
        method: "getTableCols"
      });

      runInAction(() => {
        if (!data.errors) {
          this.cols = data.result;

          // хардкод, согласован с Андреем
          // замена title для поля master
          if (this.cols.master) {
            this.cols.master.title = "Мастер";
          }
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSizeFormula = (value: string) => {
    if (
      value &&
      this.params.species.variable[value]?.custom &&
      "formula" in this.params.species.variable[value].custom
    ) {
      switch (this.params.species.variable[value].custom.formula) {
        case 0:
          return `Формула расчёта: Д * Ш * В`;
        case 1:
          return `Формула расчёта: Д * Ш`;
        case 2:
          return `Формула расчёта: Д или Ш * В (в зависимости от того, что больше)`;
      }
    } else return null;
  };

  getSizeFormulaForMasscopy = (value: string) => {
    if (
      value &&
      this.params.species.variable[value]?.custom &&
      "formula" in this.params.species.variable[value].custom
    ) {
      switch (this.params.species.variable[value].custom.formula) {
        case 0:
          return `Д*Ш*В`;
        case 1:
          return `Д*Ш`;
        case 2:
          return `(Д или Ш)*В`;
      }
    } else return null;
  };

  getTypeWork = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: { title: string; [key: string]: string };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_type_work",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (!data.errors) {
          this.params.type_work.isvariable = "on";
          this.params.type_work.variable = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTypeSubWork = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: { title: string; [key: string]: string };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_type_sub_work",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (!data.errors) {
          this.params.sub_work.isvariable = "on";
          this.params.sub_work.variable = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTypes = async () => {
    try {
      const data: ApiResponse<Col["directory"]> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          currentClass: "application_register_types",
          on_page: 200,
          method: "getList"
        });

      runInAction(() => {
        if (!data.errors) {
          this.params.type.isvariable = "on";
          this.params.type.variable = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSpecies = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: { title: string; [key: string]: string };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_species",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (!data.errors) {
          this.params.species.isvariable = "on";
          this.params.species.variable = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjects = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: Project;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (!data.errors) {
          Object.values(data.result).forEach((project) => {
            this.projects[project.id] = project;
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getMastersAndGangers = async (
    project_id: string,
    date_start: string,
    date_end: string,
    aregister_id: string
  ) => {
    this.isLoadingField = "master";

    try {
      const data: ApiResponse<MastersGangers> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getMastersAndGangers",
          params: {
            project_id: project_id,
            date_start: format(
              parse(date_start, "yyyy-MM-dd", new Date()),
              "dd.MM.yyyy"
            ),
            date_end: format(
              parse(date_end, "yyyy-MM-dd", new Date()),
              "dd.MM.yyyy"
            )
          }
        });

      runInAction(() => {
        this.masterList[aregister_id] = {};
        this.brigadierList[aregister_id] = {};
        if (data.result) {
          const getList = (
            options: {
              [key: string]: {
                fio: string;
                tn: number;
              };
            },
            isMaster?: boolean
          ) => {
            const list: MastersAndGangers = {};
            if (isMaster) {
              list.masterless = {
                title: "Без мастера",
                id: "masterless"
              };
            }

            Object.entries(options).length &&
              Object.entries(options).forEach(([staff_id, staffOne]) => {
                list[staff_id] = {
                  title: `ТН${staffOne.tn} ${staffOne.fio}`,
                  id: staff_id
                };
              });

            // В некоторых заявках есть такие ошибки (будут приходить нам чуть позже):
            // "Мастер заявки не числится в закрытых сменах либо не имеет часов"
            // Данные такого мастера и бригадира (если он выбран) не приходят в списке на выбранное число заявки
            // И их нужно добавить в список хардкодом для отображения в поле Select
            if (aregister_id !== "add") {
              if (
                isMaster
                  ? this.rootStore.aregisterOneEditStore.selectedOneForEdit
                      ?.master?.id
                  : this.rootStore.aregisterOneEditStore.selectedOneForEdit
                      ?.brigadier?.id &&
                    !list[
                      isMaster
                        ? this.rootStore.aregisterOneEditStore
                            .openedAllAppllicationsForEdit[aregister_id]?.master
                            .id
                        : this.rootStore.aregisterOneEditStore
                            .openedAllAppllicationsForEdit[aregister_id]
                            ?.brigadier.id
                    ]
              ) {
                const staff = isMaster
                  ? this.rootStore.aregisterOneEditStore
                      .openedAllAppllicationsForEdit[aregister_id].master
                  : this.rootStore.aregisterOneEditStore
                      .openedAllAppllicationsForEdit[aregister_id].brigadier;

                list[staff.id] = {
                  title: `ТН${staff.uid} ${staff.surname} ${staff.name}${
                    " " + staff.patronymic || ""
                  }`,
                  id: staff.id
                };
              }
            }

            return list;
          };
          this.masterList[aregister_id] = getList(data.result.masters, true);
          this.brigadierList[aregister_id] = getList(data.result.gangers);
        } else if (data.message) {
          this.errorsMessage[aregister_id].master = {
            result: false,
            message: {
              head: data.message.head,
              color: data.message.color,
              body: {}
            }
          };
        } else {
          this.deleteErrors("master");
        }

        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  getRecordToPair = async (project_id: string) => {
    this.isLoadingField = "dismantling";

    try {
      const data: ApiResponse<{
        [key: string]: string;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getRecordToPair",
        params: {
          project_id: project_id
        }
      });

      runInAction(() => {
        this.pairsList[project_id] = {};
        if (data.result) {
          const pairs: { [key: string]: { id: string; title: string } } = {};
          Object.entries(data.result).forEach(([id, title]) => {
            pairs[id] = {
              id: id,
              title: String(title)
            };
          });
          this.pairsList[project_id] = pairs;
        } else {
          this.error = true;
        }

        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  deleteMastersAndGangers = (id: string) => {
    this.masterList[id] && delete this.masterList[id];
    this.brigadierList[id] && delete this.brigadierList[id];
  };

  checkInternalNum = async (
    aregister_id: string,
    project_id: string,
    internal_num: string,
    index?: number
  ) => {
    this.isLoadingField = "internal_num";

    try {
      const data: ApiResponse<{
        module: string;
        isUnique: boolean;
        duplicate_id: string;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "checkInternalNum",
        params: {
          project_id: project_id,
          internal_num: internal_num
        }
      });

      runInAction(() => {
        !this.errorsMessage[aregister_id]
          ? (this.errorsMessage[aregister_id] = {})
          : "";

        !this.errorsMessage[aregister_id]?.["internal_num"]
          ? (this.errorsMessage[aregister_id]["internal_num"] = {})
          : "";

        if (data.result && "isUnique" in data.result && !data.result.isUnique) {
          if (index !== undefined) {
            this.errorsMessage[aregister_id].internal_num[index] = {
              result: data.result.isUnique,
              message: {
                head: "Заявка с таким номером уже создана",
                color: "danger",
                body: {
                  "0": {
                    head: "",
                    list: {
                      type: "link",
                      dir: "aregister",
                      body: {
                        [data.result.duplicate_id]: `Заявка ${internal_num}`
                      }
                    }
                  }
                }
              }
            };
          } else {
            this.errorsMessage[aregister_id].internal_num = {
              result: data.result.isUnique,
              message: {
                head: "Заявка с таким номером уже создана",
                color: "danger",
                body: {
                  "0": {
                    head: "",
                    list: {
                      type: "link",
                      dir: "aregister",
                      body: {
                        [data.result.duplicate_id]: `Заявка ${internal_num}`
                      }
                    }
                  }
                }
              }
            };
          }
        } else if (data.message) {
          this.errorsMessage[aregister_id].internal_num = {
            result: false,
            message: {
              head: data.message.head,
              color: data.message.color,
              body: {}
            }
          };
        } else {
          this.deleteErrors(aregister_id, "internal_num", index);
        }
        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  deleteErrors = (
    aregister_id: string,
    error_name?: string,
    index?: number
  ) => {
    if (error_name) {
      if (index !== undefined) {
        if (
          this.errorsMessage[aregister_id][error_name] &&
          Object.values(this.errorsMessage[aregister_id][error_name])
        ) {
          delete this.errorsMessage[aregister_id][error_name][index];
          if (
            Object.entries(this.errorsMessage[aregister_id][error_name]).length
          ) {
            const newErrors = {};
            Object.entries(
              this.errorsMessage[aregister_id][error_name]
            ).forEach(([key, value]) => {
              const errorIndex =
                key.slice(-2) && isNaN(+key.slice(-2))
                  ? +key.slice(-1)
                  : +key.slice(-2);
              if (errorIndex > index) {
                newErrors[errorIndex - 1] = value;
              }
            });
            this.errorsMessage[aregister_id][error_name] = newErrors;
          } else {
            delete this.errorsMessage[aregister_id][error_name];
          }
        }
      } else {
        this.errorsMessage[aregister_id]?.[error_name] &&
          delete this.errorsMessage[aregister_id][error_name];
      }
    } else {
      this.errorsMessage[aregister_id] &&
        delete this.errorsMessage[aregister_id];
    }
  };

  setNewAregisterValues = (values: Application) => {
    this.newAregisterValues = values;
  };

  setOpenedAllAregisterValues = (
    values: Partial<Application>,
    id: string,
    type?: string
  ) => {
    if (type === "delete") {
      delete this.openedAllAregisterValues[id];
    } else {
      this.openedAllAregisterValues[id] = values;
    }
  };

  getCustomFields = async (project_id: string) => {
    if (!("custom_fields" in this.projects[project_id].custom)) {
      this.isLoadingCustomFields = true;
      try {
        const data: ApiResponse<CustomFields | false | Record<string, never>> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "projects",
            method: "getCustomFields",
            params: {
              project_id: project_id
            }
          });

        runInAction(() => {
          if (!data.errors) {
            this.projects[project_id].custom = {
              custom_fields: data.result
            } as Project["custom"];

            Object.entries(data.result).forEach(([title, param]) => {
              if (param.type === "select") {
                const directory: {
                  [key: string]: { title: string; id: string };
                } = {};
                param.data &&
                  Object.values(param.data).forEach((option) => {
                    directory[option as string] = {
                      title: option as string,
                      id: option as string
                    };
                  });

                this.projects[project_id].custom.custom_fields[title].data =
                  directory;
              }
            });
          } else {
            runInAction(() => {
              !this.projects[project_id].custom?.custom_fields
                ? (this.projects[project_id].custom = {
                    ...this.projects[project_id].custom,
                    custom_fields: {}
                  })
                : "";
              this.error = true;
            });
          }

          this.isLoadingCustomFields = false;
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
          this.isLoadingCustomFields = false;
        });
      }
    }
  };

  getOneOfAregister = async (id: string) => {
    if (!this.aregisters[id]) {
      this.isLoadingField = "mount_reg";
      try {
        const data: ApiResponse<Aregister> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "aregister",
            method: "getOne",
            params: {
              module: "aregister",
              id: id
            }
          });

        runInAction(() => {
          if (data.result) {
            this.aregisters[id] = data.result;
          }
          this.isLoadingField = "";
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
          this.isLoadingField = "";
        });
      }
    }
  };

  getFormulasDict = async () => {
    try {
      const data: ApiResponse<{
        items: FormulasDictItem;
        translate: {
          [key: string]: { description: string };
        };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        method: "getFormulas"
      });

      runInAction(() => {
        if (!data.errors) {
          this.formulasDict = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getAddRawCalcData = async (project_id: string, date_start: string) => {
    this.isLoadingField = "size";
    this.error = false;
    this.formula = {};

    try {
      const data: ApiResponse<{ [key: string]: Formula }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getAddRawCalcData",
          params: {
            project_id: project_id,
            date: date_start
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          this.formula = data.result;
        }
        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
