import styles from "./staffRatingTableDetailsWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import FormWindow from "shared/ui/FormWindow";
import StaffRatingTableCoefficient from "./StaffRatingTableCoefficient";
import { useRef } from "react";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import StaffRatingDetailsTable from "./StaffRatingDetailsTable";

const StaffRatingTableDetailsWindow = () => {
  const { staffRatingStore } = useStores();

  const ref = useRef<HTMLDivElement>();
  useOnClickOutside({
    ref,
    handler: () =>
      staffRatingStore.isOpenCoefficientTableWindow
        ? staffRatingStore.setIsOpenCoefficientTableWindow(false)
        : staffRatingStore.setSelectedStaff(null)
  });

  return (
    <div className={styles.windowContainer} ref={ref}>
      <FormWindow
        title={
          !staffRatingStore.isOpenCoefficientTableWindow
            ? "Детали"
            : "Таблица коэффициентов"
        }
        subtitle={
          !staffRatingStore.isOpenCoefficientTableWindow
            ? `${staffRatingStore.selectedStaff.uid} ${staffRatingStore.selectedStaff.title}`
            : "Коэффициент зависит от средней оценки"
        }
        setOpenWindow={() =>
          staffRatingStore.isOpenCoefficientTableWindow
            ? staffRatingStore.setIsOpenCoefficientTableWindow(false)
            : staffRatingStore.setSelectedStaff(null)
        }
        isLoadingForModal={staffRatingStore.isLoadingForDetails}
        withoutSendData
        isNotHaveButtons={
          !staffRatingStore.isOpenCoefficientTableWindow ? true : false
        }
        cancelBtnTitle="Закрыть"
        cancelBtnImg={<IconClose />}
        cancelBtnOnClick={() =>
          staffRatingStore.setIsOpenCoefficientTableWindow(false)
        }
        additionalChildren={
          !staffRatingStore.isOpenCoefficientTableWindow ? (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              resetKeys={[staffRatingStore.selectedStaff]}
            >
              <StaffRatingDetailsTable />
            </ErrorBoundary>
          ) : null
        }
      >
        <>
          {!staffRatingStore.isOpenCoefficientTableWindow ? (
            <div className={styles.tableTitle}>
              {`Засчитано оценок: ${staffRatingStore.selectedStaff.marks_count}`}
            </div>
          ) : (
            ""
          )}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffRatingTableCoefficient
              selectedStaff={staffRatingStore.selectedStaff}
            />
          </ErrorBoundary>
        </>
      </FormWindow>
    </div>
  );
};

export default observer(StaffRatingTableDetailsWindow);
