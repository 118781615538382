import styles from "./staffOnePremiumLevelAbout.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { Table } from "react-bootstrap";
import { fileUrl } from "stores/utils/consts";
import StatusIcon from "shared/ui/StatusIcon";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { Button, ButtonTheme } from "shared/ui/Button";

const StaffOnePremiumLevelAbout = () => {
  const { staffOnePremiumLevelStore } = useStores();
  return (
    <>
      <div className={styles.description}>
        <p>О программе “ПОЛАТИ-БОНУС”</p>
        <p>
          Вознаграждение зависит от уровня, который зависит от количества смен,
          отработанных за прошедшие 365 дней.
        </p>
      </div>
      {staffOnePremiumLevelStore.premium_level_selects &&
      Object.values(staffOnePremiumLevelStore.premium_level_selects).length ? (
        <Table className={styles.table}>
          <tbody className={styles.tableBody}>
            <tr>
              <td>Количество смен</td>
              {Object.values(
                staffOnePremiumLevelStore.premium_level_selects
              ).map((type, ind) => {
                return (
                  <td key={type.id}>{`${type?.custom?.ws_num} - ${
                    Object.values(
                      staffOnePremiumLevelStore.premium_level_selects
                    ).length -
                      1 !==
                    ind
                      ? Object.values(
                          staffOnePremiumLevelStore.premium_level_selects
                        )[ind + 1]?.custom?.ws_num - 1
                      : 365
                  }`}</td>
                );
              })}
            </tr>
            <tr>
              <td>Уровень</td>
              {Object.values(
                staffOnePremiumLevelStore.premium_level_selects
              ).map((type) => {
                return (
                  <td key={type.id}>
                    <div className={styles.cellWithIcon}>
                      {type?.["img"] ? (
                        <img src={`${fileUrl}${type["img"]}`} />
                      ) : (
                        ""
                      )}
                      <p>{type?.["title"]}</p>
                    </div>
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Вознаграждение</td>
              {Object.values(
                staffOnePremiumLevelStore.premium_level_selects
              ).map((type) => {
                return (
                  <td key={type.id}>
                    {`${numberWithSpaces(
                      type?.["custom"]?.["fraction"],
                      false,
                      1
                    )} %`}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      ) : (
        ""
      )}
      <div className={styles.linksBlock}>
        <Button
          onClick={() =>
            window.open(
              "https://polaticdn.itscrm.ru/crm/images/6/a/96e5d1f8736e9ff5b67df83c0fd94cea8e5ccd.mp4",
              "_blank"
            )
          }
          id="StaffOnePremiumLevelAbout_watch_video"
          theme={ButtonTheme.CLEAR}
        >
          <StatusIcon icon="iconvideo" />
          Смотреть видео
        </Button>
        <Button
          onClick={() =>
            window.open(
              "https://polaticdn.itscrm.ru/crm/images/1/4/73ca56106388d1d3b68e0eaf77d3390e9c5811.pdf",
              "_blank"
            )
          }
          id="StaffOnePremiumLevelAbout_read_faq"
          theme={ButtonTheme.CLEAR}
        >
          <StatusIcon icon="iconread" />
          Читать инструкцию
        </Button>
      </div>
    </>
  );
};

export default observer(StaffOnePremiumLevelAbout);
