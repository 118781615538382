import styles from "./staffOneStatusesWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";

import FormWindow from "shared/ui/FormWindow";
import Select from "shared/ui/Inputs/Select";
import StaffOneStatusesTabs from "./StaffOneStatusesTabs";
import StaffOneStatusesForm from "features/StaffOneStatusesForm";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "shared/utils/validation/validationStaffForm";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty, isEqual } from "lodash";
import { Status } from "stores/StaffModule/types/Status";
import { ViewField } from "shared/ui/ViewField";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";
import Modal from "shared/ui/Modal";
import { addDays, format, isFuture } from "date-fns";
import { StatusesList } from "stores/BuildingModule/types/StatusesList";

type StaffOneStatusesWindowType = {
  selectedStatus?: { [key: string]: string | number };
};

const StaffOneStatusesWindow = ({
  selectedStatus
}: StaffOneStatusesWindowType) => {
  const { staffOneStatusesStore } = useStores();

  const [openedListName, setOpenedListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [requiredFields, setRequiredFields] = useState<Record<string, number>>(
    {}
  );

  const { id } = useParams();
  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };
  // последний статус в выбранной компании
  const lastStatusForStaffModule = getValues(
    getValues(
      staffOneStatusesStore.statusesList[staffOneStatusesStore.selectedCompany]
    )[0]
  )?.[0];
  // находится ли последний статус в будущем
  const lastStatusInFuture =
    staffOneStatusesStore.openedWindow === "add" &&
    isFuture(new Date(lastStatusForStaffModule?.event_start || ""));
  // обычный ли это пользователь
  const isDefaultUser =
    !staffOneStatusesStore.statusesParams[lastStatusForStaffModule?.["type"]]?.[
      "custom"
    ]?.["allow_event_start_bool"];
  // функция получения минимальной даты начала статуса
  const getMinDate = () => {
    let date: Date = null;
    if (staffOneStatusesStore.selectedCompany && lastStatusForStaffModule) {
      const isActive =
        staffOneStatusesStore.selectedStatus === StatusesList.ACTIVE;
      const isLeftForBuilding =
        staffOneStatusesStore.selectedStatus === StatusesList.LEFT_FOR_BUILDING;

      const isTransferInToActive =
        lastStatusForStaffModule?.type === StatusesList.TRANSFER &&
        lastStatusForStaffModule?.parent?.length &&
        isActive;
      const isLeftForBuildingToTransferOut =
        lastStatusForStaffModule?.type === StatusesList.TRANSFER &&
        !lastStatusForStaffModule?.parent?.length &&
        isLeftForBuilding;

      if (isTransferInToActive || isLeftForBuildingToTransferOut) {
        date = new Date(lastStatusForStaffModule?.["event_start"]);
      } else {
        date = addDays(new Date(lastStatusForStaffModule?.["event_start"]), 1);
      }
    }

    return date;
  };
  // минимальная дата начала при добавлении статуса для тех пользователей
  // чей доступ не позволяет ставить любые даты (условно, т.к. на бэке тоже есть проверки)
  const minDate = isDefaultUser ? getMinDate() : undefined;
  // функция получения массива имен полей формы добавления статусов
  const getFieldsForValidation = () => {
    const fields = getKeys(
      staffOneStatusesStore.initialValuesForAdd[
        staffOneStatusesStore.selectedStatus
      ]
    );

    if (
      staffOneStatusesStore.statusesParams[staffOneStatusesStore.selectedStatus]
        ?.custom?.close_withholding &&
      staffOneStatusesStore.openedAllEventAllowEdu[id]?.result
    ) {
      fields.push("money", "date", "without_edu");
    }
    return fields as string[];
  };
  const validationSchema = getValidationSchema(
    getFieldsForValidation(),
    getKeys(requiredFields)
  );
  // функция получения изначальных значений
  const getInitialValues = () => {
    const values = {
      ...(staffOneStatusesStore.openedWindow === "edit"
        ? staffOneStatusesStore.getInitialValuesForEdit(selectedStatus)
        : staffOneStatusesStore.initialValuesForAdd[
            staffOneStatusesStore.selectedStatus
          ]),
      company:
        staffOneStatusesStore.openedWindow === "edit"
          ? staffOneStatusesStore.companiesList[selectedStatus["company"]]?.[
              "id"
            ]
          : staffOneStatusesStore.companiesList[
              staffOneStatusesStore.selectedCompany
            ]
          ? staffOneStatusesStore.companiesList[
              staffOneStatusesStore.selectedCompany
            ]["id"]
          : null
    };

    if (!lastStatusInFuture && minDate && new Date() <= minDate) {
      values.event_start = format(minDate, "yyyy-MM-dd");
    }

    if (staffOneStatusesStore.openedWindow === "add") {
      values;
    }
    if ("dismiss_position" in values) {
      values["dismiss_position"] =
        staffOneStatusesStore.actualPositions[id]?.[
          staffOneStatusesStore.selectedCompany
        ];
    }

    if ("dismiss_object" in values) {
      values["dismiss_object"] =
        staffOneStatusesStore.statusesList[
          staffOneStatusesStore.selectedCompany
        ]?.[0]?.[0]?.["building_title"];
    }

    if (
      staffOneStatusesStore.statusesParams[staffOneStatusesStore.selectedStatus]
        ?.custom?.close_withholding &&
      staffOneStatusesStore.openedAllEventAllowEdu[id]?.result
    ) {
      values.money = staffOneStatusesStore.openedAllEventAllowEdu[id].sum;
      values.date = staffOneStatusesStore.openedAllEventAllowEdu[id].date;
      values.without_edu = 1;
    }
    return values;
  };
  const initialValues = getInitialValues();
  const handleSubmit = (values: Status) => {
    Promise.all([
      staffOneStatusesStore.openedWindow === "add"
        ? staffOneStatusesStore.addNewStaffEvent(
            {
              uid: id,
              type: staffOneStatusesStore.selectedStatus,
              ...values
            },
            id
          )
        : staffOneStatusesStore.openedWindow === "edit"
        ? staffOneStatusesStore.updateStaffEvent(
            {
              event_id: staffOneStatusesStore.selectedRow,
              ...values
            },
            id,
            staffOneStatusesStore.getInitialValuesForEdit(selectedStatus)
          )
        : null
    ]).then(() => {
      if (isEmpty(staffOneStatusesStore.errorsMessage)) {
        staffOneStatusesStore.setOpenedWindow("");
        staffOneStatusesStore.setSelectedRow("");
      }
    });
  };

  useEffect(() => {
    setRequiredFields(
      staffOneStatusesStore.openedWindow === "add" &&
        staffOneStatusesStore.statusesGroupsForAdd[
          staffOneStatusesStore.selectedStatus
        ]?.required_column
        ? staffOneStatusesStore.statusesGroupsForAdd[
            staffOneStatusesStore.selectedStatus
          ].required_column
        : staffOneStatusesStore.requiredColumnForEdit[
            staffOneStatusesStore.selectedStatus
          ]?.required_column
        ? staffOneStatusesStore.requiredColumnForEdit[
            staffOneStatusesStore.selectedStatus
          ].required_column
        : {}
    );
  }, [
    staffOneStatusesStore.statusesGroupsForAdd[
      staffOneStatusesStore.selectedStatus
    ]?.required_column,
    staffOneStatusesStore.requiredColumnForEdit[
      staffOneStatusesStore.selectedStatus
    ]?.required_column
  ]);

  useEffect(() => {
    isLoading !== staffOneStatusesStore.isLoadingForForm &&
      setIsLoading(staffOneStatusesStore.isLoadingForForm);
  }, [staffOneStatusesStore.isLoadingForForm]);

  return (
    <>
      {!isEmpty(staffOneStatusesStore.statusesCols) &&
      !isEmpty(staffOneStatusesStore.initialValuesForAdd) ? (
        <div
          className={classNames(styles.window, {}, [styles.loader])}
          data-window={true}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            enableReinitialize
          >
            {({ values, handleReset, isValid, dirty }) => {
              return (
                <FormWindow
                  title={
                    staffOneStatusesStore.openedWindow === "add"
                      ? "Добавление статуса"
                      : "Редактирование статуса"
                  }
                  subtitle={
                    staffOneStatusesStore.openedWindow === "edit"
                      ? staffOneStatusesStore.statusesParams[
                          staffOneStatusesStore.selectedStatus
                        ].title
                      : ""
                  }
                  setOpenWindow={() =>
                    staffOneStatusesStore.setOpenedWindow("")
                  }
                  saveBtnTitle="Сохранить"
                  saveBtnDisabledValue={
                    (lastStatusInFuture && isDefaultUser) ||
                    (isEqual(requiredFields, {
                      event_start: 1
                    }) && staffOneStatusesStore.openedWindow === "add"
                      ? !isValid
                      : !isValid || !dirty)
                  }
                  cancelBtnTitle={
                    staffOneStatusesStore.openedWindow === "edit"
                      ? "Удалить запись"
                      : ""
                  }
                  cancelBtnDisabled={
                    staffOneStatusesStore.openedWindow === "edit" &&
                    "delete" in staffOneStatusesStore.errorsMessage
                  }
                  cancelBtnOnClick={() => setShowDeleteModal(true)}
                  cancelBtnImg={<IconClose />}
                  addBtnTitle="Отмена"
                  addBtnImg={<IconClose />}
                  optionalCloseFunc={() => {
                    staffOneStatusesStore.setSelectedRow("");
                  }}
                  errors={getValues(staffOneStatusesStore.errorsMessage)}
                  isLoadingForModal={
                    isLoading || staffOneStatusesStore.isLoading
                  }
                  isScroll
                >
                  <>
                    {staffOneStatusesStore.openedWindow === "add" &&
                    staffOneStatusesStore.selectedCompany !== "all" ? (
                      <StaffOneStatusesTabs
                        changeSelectedStatus={(key: string) => {
                          handleReset();
                          if (key === staffOneStatusesStore.dismissStatus) {
                            staffOneStatusesStore.setSelectedStatus(
                              key,
                              values.company,
                              values.event_start
                            );
                          } else staffOneStatusesStore.setSelectedStatus(key);
                        }}
                      />
                    ) : null}

                    <div
                      className={classNames(styles.objectAndCompanyBlock, {
                        [styles.selectCompany]:
                          staffOneStatusesStore.openedWindow === "add" &&
                          staffOneStatusesStore.selectedCompany !== "all"
                      })}
                    >
                      <Select
                        name="company"
                        title="Компания"
                        options={staffOneStatusesStore.companiesList}
                        disabled={staffOneStatusesStore.openedWindow !== "add"}
                        isFloating
                        valueName="id"
                        onClick={(option) => {
                          staffOneStatusesStore.setSelectedCompany(
                            option["id"] as string
                          );
                        }}
                        required
                      />

                      {staffOneStatusesStore.openedWindow == "edit" ? (
                        <ViewField
                          title="Объект"
                          value={selectedStatus.building_title}
                          disabled
                        />
                      ) : null}
                    </div>

                    {(staffOneStatusesStore.selectedCompany === "all" &&
                      staffOneStatusesStore.openedWindow == "edit") ||
                    staffOneStatusesStore.selectedCompany !== "all" ? (
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <StaffOneStatusesForm
                          staff_id={id}
                          selectedStatus={staffOneStatusesStore.selectedStatus}
                          company={staffOneStatusesStore.selectedCompany}
                          cols={staffOneStatusesStore.statusesCols}
                          boolCols={
                            staffOneStatusesStore.statusesGroupsForAdd[
                              staffOneStatusesStore.selectedStatus
                            ].bool_column
                          }
                          requiredCols={requiredFields}
                          setRequiredCols={setRequiredFields}
                          openedListName={openedListName}
                          changeOpenedWindows={changeOpenedWindows}
                          showRetentionBlock={
                            staffOneStatusesStore.openedWindow !== "edit"
                          }
                          params={
                            staffOneStatusesStore.statusesParams[
                              staffOneStatusesStore.selectedStatus
                            ]
                          }
                          isLoadingAllowEdu={
                            staffOneStatusesStore.isLoadingAllowEdu
                          }
                          retention={
                            staffOneStatusesStore.openedAllEventAllowEdu[id]
                          }
                          updateRetention={
                            staffOneStatusesStore.allUpdatedEventAllowEdu[id]
                          }
                          checkEventAllowEdu={
                            staffOneStatusesStore.updateEventAllowEdu
                          }
                          minDateForDefaultUser={minDate}
                          maxDate={
                            lastStatusInFuture
                              ? new Date(
                                  lastStatusForStaffModule?.["event_start"] ||
                                    ""
                                )
                              : null
                          }
                          staffCode={
                            staffOneStatusesStore.openedWindow === "add"
                              ? staffOneStatusesStore.staffGuid[id]
                              : undefined
                          }
                          selectStaffGuid={
                            staffOneStatusesStore.openedWindow === "add"
                              ? staffOneStatusesStore.selectStaffGuid
                              : undefined
                          }
                          staffGuidMessage={
                            staffOneStatusesStore.openedWindow === "add"
                              ? staffOneStatusesStore.staffGuidMessage[id]
                              : undefined
                          }
                          disabledForm={lastStatusInFuture && isDefaultUser}
                        />
                      </ErrorBoundary>
                    ) : null}
                  </>
                </FormWindow>
              );
            }}
          </Formik>
          {showDeleteModal && (
            <Modal
              type="clarification"
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              title="Вы уверены, что хотите удалить статус?"
              btnWithCrossTitle="Удалить"
              btnWithCrossOnClick={() => {
                staffOneStatusesStore.deleteStaffEvent(
                  { event_id: staffOneStatusesStore.selectedRow },
                  id
                );
                setShowDeleteModal(false);
              }}
              blueBtnOnClick={() => setShowDeleteModal(false)}
              blueBtnTitle="Отмена"
              btnWithCrossImg
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default observer(StaffOneStatusesWindow);
