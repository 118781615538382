import styles from "./buildingOneStaffTableSafetywork.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import FormWindow from "shared/ui/FormWindow";
import BuildingOneStaffTableSafetyworkTable from "./BuildingOneStaffTableSafetyworkTable";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

type BuildingOneStaffTableSafetyworkProps = {
  staff_id: string;
  col_title: string;
  staff_data: Staff;
  openedListName: string;
  changeOpenedListName: (arg: string) => void;
};

const BuildingOneStaffTableSafetywork = ({
  staff_id,
  col_title,
  staff_data,
  openedListName,
  changeOpenedListName
}: BuildingOneStaffTableSafetyworkProps) => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  return (
    <div className={styles.wrapper}>
      <Button
        theme={ButtonTheme.SECONDARY}
        className={classNames("", {
          [styles.activeBtn]: openedListName === `safetywork_${staff_id}`
        })}
        id={`BuildingOneStafffTableSafetywork_${staff_id}`}
        onClick={() => {
          changeOpenedListName(
            openedListName !== `safetywork_${staff_id}`
              ? `safetywork_${staff_id}`
              : ""
          );
          !buildingOneStaffStore.safetyworkWidgets[id]?.[staff_id] &&
            buildingOneStaffStore.getActualWidgetByCompany(staff_id, id);
        }}
      >
        Получить данные
      </Button>
      {openedListName === `safetywork_${staff_id}` && (
        <div className={styles.window}>
          <FormWindow
            title={col_title}
            subtitle={staff_data.name}
            setOpenWindow={() => {
              changeOpenedListName("");
            }}
            isLoadingForModal={
              buildingOneStaffStore.isLoadingForSafetyworkWidget
            }
            isScroll
            isNotHaveButtons
            ignorOnClickOutside
            optionalCloseFunc={() => {
              changeOpenedListName("");
            }}
          >
            <BuildingOneStaffTableSafetyworkTable staff_id={staff_id} />
          </FormWindow>
        </div>
      )}
    </div>
  );
};

export default observer(BuildingOneStaffTableSafetywork);
