import styles from "./projectsOneInfoStaffTable.module.scss";
import { observer } from "mobx-react-lite";

import { Link } from "react-router-dom";

import { StaffData } from "stores/ProjectsModule/projectsOne/projectsOne";
import { useStores } from "stores";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";

type ProjectsOneInfoStaffTableProps = {
  name: string;
  staff: StaffData;
};

const ProjectsOneInfoStaffTable = ({
  name,
  staff
}: ProjectsOneInfoStaffTableProps) => {
  const { projectsOneStore } = useStores();

  return (
    <div className={styles.staffTable}>
      {[name, "phone", `${name}_phone`].map((staffField) => {
        return (
          <div className={styles.staffTable__row} key={staffField}>
            <div>
              {(() => {
                switch (staffField) {
                  case name:
                    return "Сотрудник";
                  case "phone":
                    return "Телефон";
                  case `${name}_phone`:
                    return "Предпочтительный номер телефона";
                }
              })()}
            </div>

            {(() => {
              switch (staffField) {
                case name:
                  return staff ? (
                    <div className={styles.staffTable__row__link}>
                      <span>{`TH${staff.uid} `}</span>
                      <Link
                        to={`/staff/id=${staff.id}`}
                        id={`ProjectOneInfoStaffTable_${staff.id}`}
                        target="_blank"
                      >{`${staff.surname} ${staff.name}${
                        staff.patronymic ? " " + staff.patronymic : ""
                      }`}</Link>
                    </div>
                  ) : (
                    projectsOneStore.selectedOneProject[staffField]
                  );
                case "phone":
                  return (
                    <div>
                      {staff?.phone
                        ? formatPhoneNumber(
                            Object.values(staff.phone)[0].number
                          )
                        : ""}
                    </div>
                  );

                case `${name}_phone`:
                  return (
                    <div>
                      {projectsOneStore.selectedOneProject[staffField]
                        ? formatPhoneNumber(
                            projectsOneStore.selectedOneProject[staffField]
                          )
                        : ""}
                    </div>
                  );
              }
            })()}
          </div>
        );
      })}
    </div>
  );
};

export default observer(ProjectsOneInfoStaffTable);
