import styles from "./buildingOneWorkshiftFilterCount.module.scss";
import { observer } from "mobx-react-lite";

import { useEffect, useRef, useState } from "react";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { classNames } from "shared/utils/helpers/classNames";

const BuildingOneWorkshiftFilterCount = () => {
  const { buildingOneWorkshiftStore, menuStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);

  const { id } = useParams();
  const countRef = useRef<HTMLDivElement>();

  // отслеживание положение скролла для закрепления count-бара
  useEffect(() => {
    const scrollHeight =
      countRef["current"]?.getBoundingClientRect()?.top -
        buildingOneWorkshiftStore.blocksHeight[id].header <=
      79;
    if (scrollHeight && !isScroll) {
      setIsScroll(true);
    } else if (!scrollHeight && isScroll) {
      setIsScroll(false);
    }
  }, [menuStore.scroll.scrollTop, window.innerWidth]);

  const getTopValue = () => {
    return buildingOneWorkshiftStore.blocksHeight[id]?.header
      ? buildingOneWorkshiftStore.blocksHeight[id]?.header - 5
      : 60;
  };

  return (
    <div
      className={classNames(styles.countBar, {
        [styles.countBar_sticky]: isScroll,
        [styles.countBar_normal]: !isScroll
      })}
      style={{ top: getTopValue() }}
      ref={countRef}
    >
      <span>{buildingOneWorkshiftStore.selectedFilters[id]}</span>
      <div>
        Cотрудников:
        <span>
          {
            buildingOneWorkshiftStore.filters[id][
              buildingOneWorkshiftStore.selectedFilters[id]
            ]
          }
        </span>
      </div>
      <div>
        Сумма часов группы:
        <span>
          {Object.values(
            buildingOneWorkshiftStore.staffList[id][
              buildingOneWorkshiftStore.selectedFilters[id]
            ] || {}
          )
            .filter((item) =>
              buildingOneWorkshiftStore.selectedFilters[id] === "Без группы"
                ? !item.group
                : item.group === buildingOneWorkshiftStore.selectedFilters[id]
            )
            .reduce((sum, group) => sum + Number(group.work_hours), 0)}
        </span>
      </div>
    </div>
  );
};

export default observer(BuildingOneWorkshiftFilterCount);
