import styles from "./awardsFields.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { fileUrl } from "stores/utils/consts";
import { Award } from "stores/StaffModule/types/Award";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import AwardsGalleryWindow from "../AwardsGalleryWindow";
import { useState } from "react";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getEntries } from "shared/utils/helpers/getEntries";
import Collapsible from "shared/ui/Collapsible";
import { classNames } from "shared/utils/helpers/classNames";
import Alert from "shared/ui/Alert";

type AwardsFieldsProps = {
  awards: Record<string, Award>;
  field: string;
};

const AwardsFields = ({ awards, field }: AwardsFieldsProps) => {
  const { staffOneAwardsStore, menuStore } = useStores();
  const [openWindow, setOpenWindow] = useState(false);

  const isOpenedField =
    (!staffOneAwardsStore.awardsArray?.includes(field) &&
      !staffOneAwardsStore.isFocusSearch) ||
    (staffOneAwardsStore.isFocusSearch &&
      staffOneAwardsStore.awardsFieldsFoundDuringSearch.includes(field));

  const toggleOneFieldShowing = () => {
    if (
      !staffOneAwardsStore.isFocusSearch &&
      !staffOneAwardsStore.searchValue
    ) {
      staffOneAwardsStore.changeOpenedAwards(
        !isOpenedField ? "delete" : "add",
        [field]
      );
    }
    return;
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsGalleryWindow
          openWindow={openWindow}
          setOpenWindow={() => setOpenWindow(false)}
        />
      </ErrorBoundary>

      <Collapsible
        onClick={toggleOneFieldShowing}
        title={field}
        isOpenedField={isOpenedField}
        className={{
          headerContainer: classNames(styles.collapsibleContainer, {
            [styles.widgetHeader]: menuStore.scroll.scrollTop
          }),
          dashedLineWrapper: styles.marginBottom
        }}
        withHeaderShadow
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {isOpenedField ? (
            <div className={styles.field}>
              {getKeys(awards).length ? (
                getEntries(awards).map(([id, award]) => {
                  const handleAwardWindowOpen = () => {
                    staffOneAwardsStore.setOpenedAward(id);
                    setOpenWindow(true);
                    staffOneAwardsStore.setIsActiveButton(
                      staffOneAwardsStore.awardGroups[
                        staffOneAwardsStore.awards[
                          staffOneAwardsStore.openedAward
                        ].custom?.group
                      ]
                    );
                  };
                  return (
                    <div
                      key={award.id}
                      className={styles.award}
                      onClick={handleAwardWindowOpen}
                    >
                      <div className={styles.dataText}>
                        {getFormattedDate(award.award_start)}
                      </div>
                      {award.img_src !== -1 ? (
                        <img
                          src={fileUrl + award.img_src}
                          alt={award.comment}
                          className={styles.img}
                        />
                      ) : (
                        ""
                      )}
                      <h3 className={styles.title}>{award.type_title}</h3>
                    </div>
                  );
                })
              ) : (
                <Alert
                  errors={{
                    head: `У сотрудника нет наград за ${field}`,
                    color: "empty"
                  }}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </ErrorBoundary>
      </Collapsible>
    </>
  );
};

export default observer(AwardsFields);
