import styles from "./staffOneStatusesGraphHeader.module.scss";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";

import { useStores } from "stores/index";

import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";

const StaffOneStatusesGraphHeader = () => {
  const { staffOneStatusesStore } = useStores();

  return (
    <thead>
      <tr
        className={`${styles.row} ${
          staffOneStatusesStore.isReversedTable ? styles.row_withPadding : ""
        }`}
      >
        <th
          className={`${styles.row__iconBlock}`}
          onClick={() => {
            staffOneStatusesStore.getReverseTable();
          }}
          id="StaffOneStatusesGraphHeader_reverseTable"
        >
          <Tooltip text="Реверс данных">
            <div className={styles.row__icon}>
              <StatusIcon
                icon="iconarrowdouble"
                color={
                  staffOneStatusesStore.isReversedTable
                    ? "blue-lazure"
                    : "bw-gray6"
                }
              />
            </div>
          </Tooltip>
        </th>
        {staffOneStatusesStore.monthList.map((date) => {
          return (
            <th
              key={date}
              colSpan={staffOneStatusesStore.monthStatusesCount[date]}
            >
              <p>
                {format(getParsedDate(date), "LLLL yyyy", {
                  locale: ru
                })[0].toUpperCase() +
                  format(getParsedDate(date), "LLLL yyyy", {
                    locale: ru
                  }).substring(1)}
              </p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default observer(StaffOneStatusesGraphHeader);
