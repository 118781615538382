import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import { fileUrl } from "stores/utils/consts";
import styles from "./staffOneInfoPremiumLevel.module.scss";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import Alert from "shared/ui/Alert";

const cols = {
  level: "Текущий уровень",
  current_ws: "Количество смен",
  fraction: "Вознаграждение (% от ЗП):",
  money: "Вознаграждение (сумма):"
};

const currentTitles = ["level", "current_ws", "fraction", "money"];

const StaffOneInfoPremiumLevel = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.premium_level) &&
      staffOneStore.getPremiumLevelWidget(id);
  }, [id]);

  const typeOfPremiumLevel =
    staffOneStore.premium_level_selects[
      staffOneStore.premium_level[id]?.["level"]
    ];

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_premium_level[id]}
      withoutText
    >
      <>
        {id in staffOneStore.premium_level &&
        staffOneStore.premium_level[id] &&
        Object.values(staffOneStore.premium_level[id]).length ? (
          <div
            className={`${styles.container} ${
              styles[`container_${typeOfPremiumLevel?.["custom"]?.["color"]}`]
            }`}
          >
            {typeOfPremiumLevel?.["img"] ? (
              <img
                src={`${fileUrl}${typeOfPremiumLevel["img"]}`}
                className={styles.icon}
              />
            ) : (
              ""
            )}
            {currentTitles.map((title) => {
              switch (title) {
                case "level":
                  return (
                    <div key={title} className={styles.oneBlock}>
                      <p>{cols[title]}</p>
                      <p>{typeOfPremiumLevel?.["title"]}</p>
                    </div>
                  );
                case "current_ws":
                  return (
                    <div key={title} className={styles.oneBlock}>
                      <p>{cols[title]}</p>
                      <p>{staffOneStore.premium_level[id][title]}</p>
                    </div>
                  );
                case "fraction":
                  return (
                    <div key={title} className={styles.oneBlock}>
                      <p>{cols[title]}</p>
                      <p>
                        {`${numberWithSpaces(
                          typeOfPremiumLevel?.["custom"]?.["fraction"],
                          false,
                          1
                        )} %`}
                      </p>
                    </div>
                  );
                case "money":
                  return (
                    <div key={title} className={styles.oneBlock}>
                      <p>{cols[title]}</p>
                      <p>
                        {numberWithSpaces(
                          staffOneStore.premium_level[id][title],
                          true
                        )}
                      </p>
                    </div>
                  );
              }
            })}
          </div>
        ) : (
          <Alert
            errors={{
              head: `${
                staffOneStore.premium_level_error_text[id] ||
                "Не найден в рейтинге"
              }`,
              color: "empty"
            }}
          />
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoPremiumLevel);
