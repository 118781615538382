import { fileUrl } from "stores/utils/consts";

export const modulesList = {
  staff: {
    id: "6995795d11b84ddba91f75aaa0d2b52e",
    title: "Сотрудники",
    dir: "staff",
    orderliness: 1,
    sections: {
      "5996795d11b84ddba91f75aaa0d2b52e": {
        id: "5996795d11b84ddba91f75aaa0d2b52e",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          "9756655d11b84ddba91f75aaa0d2b52e": {
            id: "9756655d11b84ddba91f75aaa0d2b52e",
            title: "Все сотрудники",
            dir: "",
            orderliness: 1
          },
          "9756691f75b84ddba91f75aaa0d2b52e": {
            id: "9756691f75b84ddba91f75aaa0d2b52e",
            title: "Отпуск",
            dir: "staffVacation",
            orderliness: 2
          },
          "9756691f75b84ddba91f75aaa0d2b52v": {
            id: "9756691f75b84ddba91f75aaa0d2b52v",
            title: "Вычеты УЦ",
            dir: "staffRetention",
            orderliness: 3
          },
          "9756691f75b84ddba91f75aaa0d2b52t": {
            id: "9756691f75b84ddba91f75aaa0d2b52t",
            title: "Рейтинги",
            dir: "staffRating",
            orderliness: 4
          }
        }
      },
      "625c93063047458798e783185793b526": {
        id: "625c93063047458798e783185793b526",
        title: "Действия",
        orderliness: 2,
        submodules: {
          "58798e625c930630474783185793b526": {
            id: "58798e625c930630474783185793b526",
            title: "Добавить",
            dir: "add",
            orderliness: 1
          },
          fhrf43hr8f98943h8fh34hf43fj83443f: {
            id: "fhrf43hr8f98943h8fh34hf43fj83443f",
            title: "Импортировать",
            dir: "import",
            orderliness: 2
          }
        }
      }
    }
  },
  friendinvite: {
    id: "09d3733890aab9bf54018577fc21cd27",
    title: "Приведи друга",
    dir: "friendinvite",
    orderliness: 2,
    sections: {
      "33890aab9bf540109d378577fc21cd27": {
        id: "33890aab9bf540109d378577fc21cd27",
        title: "",
        orderliness: 1,
        submodules: {
          b9b33890aaf540109d378577fc21cd27: {
            id: "b9b33890aaf540109d378577fc21cd27",
            title: "Все приглашения",
            dir: "",
            orderliness: 1
          }
        }
      }
    }
  },
  building: {
    id: "479cb1326b0dca517a98bef90aa0a24b",
    title: "Объекты",
    dir: "building",
    orderliness: 3,
    sections: {
      "326b0dca517a479cb198bef90aa0a24b": {
        id: "326b0dca517a479cb198bef90aa0a24b",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          a24326b0dca517a479cb198bef90aa0b: {
            id: "a24326b0dca517a479cb198bef90aa0b",
            title: "Объекты",
            dir: "",
            orderliness: 1
          },
          "479cb198bef90a24326b0dca517aaa0b": {
            id: "479cb198bef90a24326b0dca517aaa0b",
            title: "История статусов",
            fullstack: `${fileUrl}/crm/building/?action=staffEvents`,
            dir: "staffEvents",
            orderliness: 2
          },
          a517a479cb24326b0dca198bef90aa0b: {
            id: "a517a479cb24326b0dca198bef90aa0b",
            fullstack: `${fileUrl}/crm/building/?action=staffVacation`,
            title: "Межвахта",
            dir: "watch",
            orderliness: 3
          },
          a7a479cb198bef90aa0b24326b0dca51: {
            id: "a7a479cb198bef90aa0b24326b0dca51",
            fullstack: `${fileUrl}/crm/building/?action=plannedObjectList`,
            title: "Планируемые объекты",
            dir: "buildingReport",
            orderliness: 4
          }
        }
      }
    }
  },
  projects: {
    id: "47b0dca517a98be9cb1326f90aa0a24b",
    title: "Проекты",
    dir: "projects",
    orderliness: 3,
    sections: {
      "3479cb198bef9026b0dca517aaa0a24b": {
        id: "3479cb198bef9026b0dca517aaa0a24b",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          a24326a479cb1b0dca51798bef90aa0b: {
            id: "a24326a479cb1b0dca51798bef90aa0b",
            title: "Проекты",
            dir: "",
            orderliness: 1
          }
        }
      }
    }
  },
  polatiapp: {
    id: "517a9479cb1326b0dca8bef90aa0a24b",
    title: "Приложение ПОЛАТИ",
    dir: "polatiapp",
    orderliness: 4,
    sections: {
      bef90aa0a24326b0dca517a479cb198b: {
        id: "bef90aa0a24326b0dca517a479cb198b",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          a24326b0dca517a479cb198bef90aa0b: {
            id: "a24326b0dca517a479cb198bef90aa0b",
            title: "Заявки на перевод",
            dir: "",
            orderliness: 1
          }
        }
      }
    }
  },
  aregister: {
    id: "6995795d341b84ddba91f75aaa0d2b52e",
    title: "Реестр заявок строительных лесов",
    dir: "aregister",
    orderliness: 5,
    sections: {
      "5996795d11b8423dba91f75aaa0d2b52e": {
        id: "5996795d11b84ddba91f75aaa0d2b52e",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          "9756655d11b84ddba945f75aaa0d2b52e": {
            id: "9756655d11b84ddba91f75aaa0d2b52e",
            title: "Реестр заявок строительных лесов",
            dir: "",
            orderliness: 1
          },
          "9756691f75b8434dba91f75aaa0d2b52e": {
            id: "9756691f75b84ddba781f75aaa0d2b52e",
            title: "Отчёт мастера за смену",
            dir: "report",
            orderliness: 2
          },
          "97566932f75b8434dba91f75aaa0d2b52e": {
            id: "9756691f75b8324ddba91f75aaa0d2b52e",
            title: "Справка",
            dir: "reference",
            orderliness: 3
          }
        }
      },
      "625c93063047458798e783185793b526": {
        id: "625c93063047458798e783185793b526",
        title: "Действия",
        orderliness: 2,
        submodules: {
          "58798e625c930630474783185793b526": {
            id: "58798e625c930630474783185793b526",
            title: "Создать заявку",
            dir: "appcreate",
            orderliness: 1
          }
        }
      }
    }
  },
  salary: {
    id: "0d2b526995795d11b84ddba91f75aaae",
    title: "Финансы",
    dir: "salary",
    orderliness: 6,
    sections: {
      "1f75aaa05996795d11b84ddba9d2b52e": {
        id: "1f75aaa05996795d11b84ddba9d2b52e",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          "91f75aaa0d2b52e9756655d11b84ddba": {
            id: "91f75aaa0d2b52e9756655d11b84ddba",
            title: "Операции",
            dir: "",
            orderliness: 1
          },

          "75b84ddb7566991fa91f75aaa0d2b52e": {
            id: "75b84ddb7566991fa91f75aaa0d2b52e",
            title: "Премии",
            dir: "premium",
            orderliness: 3
          },
          "7566991f75b84ddba91f75aaa0d2b52e": {
            id: "7566991f75b84ddba91f75aaa0d2b52e",
            title: "Ведомости",
            dir: "paymentlist",
            orderliness: 2
          }
        }
      },
      "047458798625c93063e783185793b526": {
        id: "047458798625c93063e783185793b526",
        title: "Действия",
        orderliness: 2,
        submodules: {
          "56304747831858798e625c930793b526": {
            id: "56304747831858798e625c930793b526",
            title: "Создать операцию",
            dir: "add_operation",
            orderliness: 1
          },
          c930793b5256304747831858798e6256: {
            id: "c930793b5256304747831858798e6256",
            title: "Создать премию",
            dir: "add_premium",
            orderliness: 2
          },
          "747831858798e625c93079356304b526": {
            id: "747831858798e625c93079356304b526",
            title: "Создать ведомость",
            dir: "add_payment",
            orderliness: 3
          }
        }
      }
    }
  },
  safetywork: {
    id: "7xkseewwuatsm0xonrr7orpysratiyjb",
    title: "Охрана труда",
    dir: "safetywork",
    orderliness: 1,
    sections: {
      "7qbth8lm58me3stme2lx77yufiq2q4dh": {
        id: "7qbth8lm58me3stme2lx77yufiq2q4dh",
        title: "Обзор",
        orderliness: 1,
        submodules: {
          "4hj6wxhsnt14uso8x357k86fcqa05wpx": {
            id: "4hj6wxhsnt14uso8x357k86fcqa05wpx",
            title: "Все удостоверения",
            dir: "",
            orderliness: 1
          }
        }
      },
      "1mmbaaccldz16kimjjvr4u0et9zof0u8": {
        id: "1mmbaaccldz16kimjjvr4u0et9zof0u8",
        title: "Действия",
        orderliness: 2,
        submodules: {
          "3vlcjor90534c7833tcantf3czo2vbqk": {
            id: "3vlcjor90534c7833tcantf3czo2vbqk",
            title: "Создать удостоверение",
            dir: "add",
            orderliness: 1
          },
          y7r453fh348ty3487th3f7458f457845: {
            id: "y7r453fh348ty3487th3f7458f457845",
            title: "Массовое создание удостоверений",
            dir: "addMass",
            orderliness: 2,
            fullstack: `${fileUrl}/crm/safetywork/?action=massCreation`
          }
        }
      }
    }
  }
};
