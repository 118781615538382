import { saveAs } from "file-saver";
import { Button, ButtonTheme } from "shared/ui/Button";
import StatusIcon from "shared/ui/StatusIcon";
import { utils, write } from "xlsx";

const StaffImportUploadFileDownload = () => {
  // функция генерирования и скачивания таблицы эксель
  const handleDownLoad = () => {
    // прописываем заголовки
    const header = [
      [
        "Фамилия",
        "Имя",
        "Отчество",
        "Дата рождения",
        "Серия и № паспорта",
        "Кем выдан",
        "Дата выдачи",
        "Код подразделения",
        "Адрес регистрации",
        "Адрес фактического проживания",
        "Место рождения",
        "СНИЛС",
        "ИНН",
        "Дата начала обучения",
        "Дата окончания обучения",
        "Учебный центр",
        "Номер телефона 1",
        "Номер телефона 2",
        "Номер телефона 3"
      ]
    ];

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(header);

    // задаем минимальную ширину каждой колонке
    const fitToColumn = (arrayOfArray: string[][]) => {
      return arrayOfArray[0].map((_row, index) => ({
        wch: Math.max(
          ...arrayOfArray.map((header) =>
            header[index] ? header[index].toString().length + 7 : 0
          )
        )
      }));
    };

    ws["!cols"] = fitToColumn(header);

    utils.book_append_sheet(wb, ws, "Лист 1");

    const blob: string = write(wb, { bookType: "xlsx", type: "binary" });

    // кодировка для таблицы
    const s2ab = (s: string) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    saveAs(
      new Blob([s2ab(blob)], {
        type: "application/octet-stream"
      }),
      "Импорт людей в ПО.xlsx"
    );
  };
  return (
    <Button
      id="StaffImportUpload_download_xlsx"
      onClick={handleDownLoad}
      theme={ButtonTheme.SECONDARY}
    >
      <StatusIcon icon="iconexcel" /> Cкачать шаблон Excel
    </Button>
  );
};

export default StaffImportUploadFileDownload;
