import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import styles from "./friendInviteOneHistoryOne.module.scss";

type FriendInviteOneHistoryOneProps = {
  user_name: string;
  time: string;
  historyItems: Array<{ [key: string]: string | number | null }>;
};

const FriendInviteOneHistoryOne = ({
  user_name,
  time,
  historyItems
}: FriendInviteOneHistoryOneProps) => {
  return (
    <div className={styles.storyContainer}>
      <div className={styles.storyWrapper}>
        <div className={styles.storyData}>
          <div className={styles.date}>
            {getFormattedDate(time).replace(/ /g, ", в ")}
          </div>
          <div className={styles.name}>{user_name}</div>
        </div>
        {historyItems.map((item) => {
          const { id, text } = item;
          return (
            <div className={styles.storyContent} key={id}>
              {text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FriendInviteOneHistoryOne;
