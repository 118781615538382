import styles from "./staffOneStatusesGraphTooltip.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { Status } from "stores/StaffModule/types/Status";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import StatusIcon from "shared/ui/StatusIcon";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneStatusesGraphTooltipProps = {
  status: Status;
  isSubDate: boolean;
  ind: number;
  buildingIndex: number;
  isSubVacation: boolean;
};

const StaffOneStatusesGraphTooltip = ({
  status,
  isSubDate,
  ind,
  buildingIndex,
  isSubVacation
}: StaffOneStatusesGraphTooltipProps) => {
  const { staffOneStatusesStore } = useStores();

  if (!isSubVacation) {
    return (
      <div className={styles.tooltip}>
        <div className={styles.building}>
          <img src={Buildings} className={styles.citiesImg} />
          <div>{status.building_title}</div>
        </div>
        <div className="d-flex">
          {getFormattedDate(status["event_start"])}{" "}
          {status["event_end"]
            ? ` - ${getFormattedDate(status["event_end"])}`
            : ""}
        </div>
        <div className={styles.status}>
          <StatusIcon
            icon={staffOneStatusesStore.statusesParams[status.type].custom.icon}
            color={
              staffOneStatusesStore.statusesParams[status.type].custom.color_api
            }
          />
          <div>
            {status["type_title"]}
            {status["bage"] ? (
              status["bage"]
            ) : isSubDate ? (
              <span
                className={`${styles.bage} ${
                  styles[
                    `bage_${
                      staffOneStatusesStore.statusesParams[status.type].custom
                        .color_api
                    }`
                  ]
                }`}
              >
                Ом
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className={styles.caption}>Дней в статусе:</div>
          <span>
            {calculateDays(
              status["event_start"],
              !status["event_end"] || (ind === 0 && buildingIndex === 0)
                ? new Date()
                : status["event_end"]
            )}
          </span>
        </div>
        <div className="d-flex">
          <div className={styles.caption}>Дата создания:</div>
          <span>{getFormattedDate(status.time_create).substring(0, 10)}</span>
        </div>

        <div className="d-flex">
          <div className={styles.caption}>Автор:</div>
          <span>{status.author}</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.tooltip}>
          <div className={styles.building}>
            <img src={Buildings} className={styles.citiesImg} />
            <div>{status.building_title}</div>
          </div>
          <div className="d-flex">
            {getFormattedDate(status["sub_start_date"])}
            {`- ${getFormattedDate(status["sub_end_date"])}`}
          </div>
          <div className={styles.status}>
            <StatusIcon
              icon={
                staffOneStatusesStore.statusesParams[status.type].custom.icon
              }
              color={
                staffOneStatusesStore.statusesParams[status.type].custom
                  .color_api
              }
            />
            <div>
              {status["type_title"]}
              <span
                className={`${styles.bage} ${
                  styles[
                    `bage_${
                      staffOneStatusesStore.statusesParams[status.type].custom
                        .color_api
                    }`
                  ]
                }`}
              >
                Ое
              </span>
            </div>
          </div>
          <div className="d-flex">
            <div className={styles.caption}>Дней в статусе:</div>
            <span>
              {calculateDays(
                status["sub_start_date"],
                ind === 0 && buildingIndex === 0
                  ? new Date()
                  : status["sub_end_date"]
              )}
            </span>
          </div>
          <div className="d-flex">
            <div className={styles.caption}>Дата создания:</div>
            <span>{getFormattedDate(status.time_create).substring(0, 10)}</span>
          </div>

          <div className="d-flex">
            <div className={styles.caption}>Автор:</div>
            <span>{status.author}</span>
          </div>
        </div>
        <div className={styles.tooltip}>
          <div className={styles.building}>
            <img src={Buildings} className={styles.citiesImg} />
            <div>{status.building_title}</div>
          </div>
          <div className="d-flex">
            {getFormattedDate(status["event_start"])}
            {`- ${getFormattedDate(status["event_end"])}`}
          </div>
          <div className={styles.status}>
            <StatusIcon
              icon={
                staffOneStatusesStore.statusesParams[status.type].custom.icon
              }
              color={
                staffOneStatusesStore.statusesParams[status.type].custom
                  .color_api
              }
            />
            <div>
              {status["type_title"]}
              <span
                className={`${styles.bage} ${
                  styles[
                    `bage_${
                      staffOneStatusesStore.statusesParams[status.type].custom
                        .color_api
                    }`
                  ]
                }`}
              >
                Ом
              </span>
            </div>
          </div>
          <div className="d-flex">
            <div className={styles.caption}>Дней в статусе:</div>
            <span>
              {calculateDays(status["event_start"], status["event_end"])}
            </span>
          </div>
          <div className="d-flex">
            <div className={styles.caption}>Дата создания:</div>
            <span>{getFormattedDate(status.time_create).substring(0, 10)}</span>
          </div>

          <div className="d-flex">
            <div className={styles.caption}>Автор:</div>
            <span>{status.author}</span>
          </div>
        </div>
      </>
    );
  }
};

export default observer(StaffOneStatusesGraphTooltip);
