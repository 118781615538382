import styles from "./salaryPremiumsOneInfoStaffTable.module.scss";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Table } from "react-bootstrap";
import { Input } from "shared/ui/Inputs/Input";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import Checkbox from "shared/ui/Inputs/Checkbox";
import NumberFormat from "react-number-format";
import Tooltip from "shared/ui/Tooltip";
import SalaryPremiumsOneInfoStaffTableFilter from "./SalaryPremiumsOneInfoStaffTableFilter";
import StatusIcon from "shared/ui/StatusIcon";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";

const tabs = [
  { id: "premium_fix", title: "Премия FIX" },
  { id: "premium", title: "Премия" }
];

type SalaryPremiumsOneInfoStaffTableCellProps = {
  column: number;
  highlightIndex: number;
  setHighlightIndex: (arg: number) => void;
  children: JSX.Element | JSX.Element[];
  className?: string;
  isColorHighLight?: boolean;
};

const SalaryPremiumsOneInfoStaffTableCell = ({
  column,
  highlightIndex,
  setHighlightIndex,
  className,
  children,
  isColorHighLight
}: SalaryPremiumsOneInfoStaffTableCellProps) => {
  return (
    <td
      className={`${className} ${
        highlightIndex === column ? styles.highlightedColumn : ""
      }`}
      onMouseEnter={() => {
        setHighlightIndex(column);
      }}
      onMouseLeave={() => {
        setHighlightIndex(-1);
      }}
    >
      {isColorHighLight && highlightIndex === column ? (
        <div className={styles.backroundBlack}></div>
      ) : (
        ""
      )}
      {children}
    </td>
  );
};

type SalaryPremiumsOneInfoStaffTableProps = {
  values: {
    [key: string]: {
      [key: string]: string | number;
    }[];
  };
};

const SalaryPremiumsOneInfoStaffTable = ({
  values
}: SalaryPremiumsOneInfoStaffTableProps) => {
  const { salaryPremiumOneStore } = useStores();
  const [selectedTab, setSelectedTab] = useState("premium_fix");
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const { handleChange, handleBlur } = useFormikContext();

  return (
    <>
      <SwitchedTabs
        tabs={tabs}
        selectedTab={selectedTab}
        changeActiveTab={setSelectedTab}
      />
      <SalaryPremiumsOneInfoStaffTableFilter />
      <Table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.titleQuantity} colSpan={2}>
              <div className="d-flex justify-content-between">
                <p className="m-0">Итого:</p>
                <p className="m-0">
                  {Object.values(values[selectedTab]).length}
                </p>
              </div>
            </th>
            {Object.values(
              salaryPremiumOneStore.currentColsForTable[selectedTab]
            )
              .slice(1)
              .map((title, i) => {
                switch (title) {
                  case "fact_rubles":
                    return (
                      <th
                        key={title}
                        className={`${styles.title} ${styles.beigeTitle} ${
                          styles.factsColumn
                        } ${
                          highlightIndex === i + 1
                            ? styles.highlightedTitle
                            : ""
                        }`}
                      >
                        {numberWithSpaces(
                          values[selectedTab].reduce(
                            (sum, current) =>
                              sum + (current["fact_rubles"] as number),
                            0
                          )
                        )}
                      </th>
                    );
                  default:
                    return (
                      <th
                        key={title}
                        className={`${styles.title} ${styles.beigeTitle} ${
                          highlightIndex === i + 1
                            ? styles.highlightedTitle
                            : ""
                        }`}
                      ></th>
                    );
                }
              })}
          </tr>
          <tr>
            <th className={styles.titleQuantity} colSpan={2}>
              <div className="d-flex justify-content-between">
                <p className="m-0">Итого с учетом фильтрации:</p>
                <p className="m-0">0</p>
              </div>
            </th>
            {Object.values(
              salaryPremiumOneStore.currentColsForTable[selectedTab]
            )
              .slice(1)
              .map((title, i) => {
                switch (title) {
                  case "fact_rubles":
                    return (
                      <th
                        key={title}
                        className={`${styles.title} ${styles.blueTitle} ${
                          styles.factsColumn
                        } ${
                          highlightIndex === i + 1
                            ? styles.highlightedTitle
                            : ""
                        }`}
                      >
                        {numberWithSpaces(
                          values[selectedTab].reduce(
                            (sum, current) =>
                              sum + (current["fact_rubles"] as number),
                            0
                          )
                        )}
                      </th>
                    );
                  default:
                    return (
                      <th
                        key={title}
                        className={`${styles.title} ${styles.blueTitle} ${
                          highlightIndex === i + 1
                            ? styles.highlightedTitle
                            : ""
                        }`}
                      ></th>
                    );
                }
              })}
          </tr>
          <tr>
            <th
              className={`${styles.title} ${styles.titleColumn} ${styles.greenTitle}`}
            >
              <p className="m-0">№</p>
            </th>
            {Object.values(
              salaryPremiumOneStore.currentColsForTable[selectedTab]
            ).map((title, i) => {
              switch (title) {
                case "max_premium_count":
                  return (
                    <th
                      key={title}
                      className={`${styles.title} ${
                        styles.maxPremiumTitleCell
                      } ${styles.greenTitle} ${
                        highlightIndex === i ? styles.highlightedTitle : ""
                      }`}
                    >
                      <div>
                        <p className="m-0">
                          {salaryPremiumOneStore.colsTable[title]["title"]}
                        </p>
                        <Checkbox name={title} />
                      </div>
                    </th>
                  );
                default:
                  return (
                    <th
                      key={title}
                      className={`${styles.title} ${styles.greenTitle} ${
                        highlightIndex === i ? styles.highlightedTitle : ""
                      }`}
                    >
                      <p className="m-0">
                        {salaryPremiumOneStore.colsTable[title]["title"]}
                      </p>
                    </th>
                  );
              }
            })}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {values[selectedTab].map((item, i) => {
            return (
              <tr key={item.rid}>
                <td className={styles.numberColumn}>{i + 1}</td>
                <>
                  {Object.values(
                    salaryPremiumOneStore.currentColsForTable[selectedTab]
                  ).map((title, index) => {
                    switch (title) {
                      case "fio":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            className={styles.fioColumn}
                          >
                            <Tooltip
                              color="blue-lazure"
                              text="Переход на страницу сотрудника находится в разработке"
                            >
                              <p>{item[title]}</p>
                            </Tooltip>
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      case "approved_premium":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                          >
                            <p>{item[title]}%</p>
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      case "all_workshift":
                      case "num_workshift":
                      case "num_weekends":
                      case "num_missed":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            className={`${
                              title !== "num_workshift"
                                ? title === "num_missed" &&
                                  (item[title] as number) > 0
                                  ? styles.workshiftColumnRed
                                  : styles.workshiftColumnGreen
                                : ""
                            } ${
                              title === "num_weekends" && !item[title]
                                ? styles.workshiftColumnYellow
                                : ""
                            }`}
                            isColorHighLight={title !== "num_workshift"}
                          >
                            <p>{item[title]}</p>
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      case "max_premium_count":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            className={
                              (item[title] as number) > 0
                                ? styles.workshiftColumnGreen
                                : styles.workshiftColumnRed
                            }
                            isColorHighLight
                          >
                            <div className="d-flex justify-content-between">
                              <StatusIcon
                                icon={
                                  (item[title] as number) > 0
                                    ? "bigcheck"
                                    : "dash"
                                }
                                color={
                                  (item[title] as number) > 0
                                    ? "accent-green"
                                    : "accent-coral"
                                }
                              />
                              <p>{item[title]}%</p>
                            </div>
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      case "master_premium":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            className={`${styles.commentColumn} ${styles.masterColumn}`}
                          >
                            <NumberFormat
                              suffix={"%"}
                              name={`table.${selectedTab}.${i}.${title}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={item[title]}
                              customInput={Input}
                              allowNegative={false}
                            />
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      case "comment":
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            className={styles.commentColumn}
                          >
                            <Input
                              name={`table.${selectedTab}.${i}.${title}`}
                              onChange={handleChange}
                            />
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                      default:
                        return (
                          <SalaryPremiumsOneInfoStaffTableCell
                            key={title}
                            column={index}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                          >
                            <p>
                              {typeof item[title] === "string"
                                ? (item[title] as string)
                                : numberWithSpaces(item[title] as number)}
                            </p>
                          </SalaryPremiumsOneInfoStaffTableCell>
                        );
                    }
                  })}
                </>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default observer(SalaryPremiumsOneInfoStaffTable);
