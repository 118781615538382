import StatusIcon from "shared/ui/StatusIcon";
import styles from "./staffVacationInfoTabs.module.scss";
import Tooltip from "shared/ui/Tooltip";

type StaffVacationInfoTabProps = {
  data: number;
  title: string;
};
const StaffVacationInfoTab = ({ data, title }: StaffVacationInfoTabProps) => {
  return (
    <div key={data} className={styles.tab}>
      <div className={styles.tabHeader}>{title}</div>
      <div className={styles.tabFooter}>
        <div className={styles.tabData}>{data}</div>
        <div>
          <Tooltip text="Test message Test message Test message Test message">
            <StatusIcon icon="iconquestionsmall" color="accent-green" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default StaffVacationInfoTab;
