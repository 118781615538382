import styles from "./buildingOneTimesheetInfoCellSelect.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import { without } from "lodash";

type BuildingOneTimesheetInfoCellSelectProps = {
  staff_id: string;
  day: number;
};

const BuildingOneTimesheetInfoCellSelect = ({
  staff_id,
  day
}: BuildingOneTimesheetInfoCellSelectProps) => {
  const { id } = useParams();
  const { buildingOneTimesheetStore } = useStores();

  // дата смены
  const workshiftDate = `${buildingOneTimesheetStore.year[id]}-${
    buildingOneTimesheetStore.month[id] < 10 ? 0 : ""
  }${buildingOneTimesheetStore.month[id]}-${day < 10 ? 0 : ""}${day}`;

  if (
    buildingOneTimesheetStore.openedWindow === `${staff_id}${workshiftDate}`
  ) {
    // Статусы
    const events_type = buildingOneTimesheetStore.selects.events_type;
    // Внешние состония
    const conditions = buildingOneTimesheetStore.selects.condition_type;
    // Состояния в смене
    const workshift_staff_status =
      buildingOneTimesheetStore.selects.workshift_staff_status;

    // Данные смены сотрудника на дату смены для ячейки
    const workshift =
      buildingOneTimesheetStore.allOpenedTabels[id]?.tabel?.[staff_id]?.[
        workshiftDate
      ];

    const conditionsArray =
      // если состояние внешнее или статус, то могут быть недоступные к смене состояния
      // которые нужно убрать из списка
      workshift.event_type &&
      (conditions[workshift.title] || events_type[workshift.event_type])
        ? without(
            // список доступных к смене внешек берется из справочника типов статусов
            events_type[workshift.event_type].custom?.buidling_condition
              ?.list &&
              Object.values(
                events_type[workshift.event_type].custom.buidling_condition.list
              ),
            ...buildingOneTimesheetStore.disabledConditions
          )
        : // если же состояние в смене - отображаем в списке те состояния
        // которые есть в custom.children текущего состояния
        workshift_staff_status[workshift.title]?.custom?.children
        ? Object.values(workshift_staff_status[workshift.title].custom.children)
        : [];

    return (
      <div className={styles.list}>
        {conditionsArray.map((condition) => {
          return (
            <p
              key={condition}
              onClick={() => {
                // При выборе состояния из списка, сначала нужно отправить на бэк запрос на сохранение
                // в зависимости от того, какое состояние текущее, вызываются разные методы
                Promise.all([
                  // для внешних состояний и статусов
                  workshift.event_type &&
                    (conditions[workshift.title] ||
                      events_type[workshift.event_type]) &&
                    buildingOneTimesheetStore.setCondition(
                      id,
                      workshiftDate,
                      staff_id,
                      condition
                    ),
                  // для состояний в смене
                  !workshift.event_type &&
                    workshift_staff_status[workshift.title] &&
                    buildingOneTimesheetStore.setStaffObjectStatus(
                      buildingOneTimesheetStore.allOpenedTabels[id]?.ws_list?.[
                        workshiftDate
                      ]?.id,
                      workshift.ws_staff_id,
                      condition,
                      id,
                      workshiftDate,
                      staff_id
                    )
                ]).then(() => {
                  // и только потом закрываем окно
                  buildingOneTimesheetStore.setOpenedWindow("");
                  focus();
                });
              }}
              id={`BuildingOneTimesheetInfoCellSelect_changeCondition_${condition}`}
            >
              {condition}
            </p>
          );
        })}
        {/* Для внешних состояний из списка conditions доступно удаление, поэтому отображаем полосу и кнопку Очистить */}
        {workshift.event_type &&
        conditions[workshift.title] &&
        conditionsArray.length ? (
          <>
            <p className={styles.list__divider} />
            <p
              onClick={() => {
                Promise.all([
                  buildingOneTimesheetStore.setCondition(
                    id,
                    workshiftDate,
                    staff_id,
                    "NULL"
                  )
                ]).then(() => {
                  buildingOneTimesheetStore.setOpenedWindow("");
                  focus();
                });
              }}
              id={`BuildingOneTimesheetInfoCellSelect_changeCondition_clean`}
            >
              Очистить
            </p>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default observer(BuildingOneTimesheetInfoCellSelect);
