import styles from "./selectColor.module.scss";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import iconClose from "shared/assets/images/mainIcons/iconClose.svg";
import iconExpand from "shared/assets/images/mainIcons/iconExpand.svg";
import { Button, ButtonTheme } from "../Button";

type SelectColorProps = {
  name: string;
  value: string;
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

const colors_list = [
  "success",
  "info",
  "primary",
  "warning",
  "danger",
  "pink",
  "purple",
  "deep-purple",
  "indigo",
  "blue",
  "light-blue",
  "cyan",
  "teal",
  "green",
  "light-green",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deep-orange",
  "red",
  "brown",
  "grey",
  "blue-grey"
];

/*
  Нужна доработка для сохранения насыщенности цветов
*/

const SelectColor = ({
  isOpened,
  setIsOpened,
  value,
  name
}: SelectColorProps) => {
  const [selectedColor, setSelectedColor] = useState<{
    [color: string]: string;
  }>({});
  const [valueColor, setValueColor] = useState<{
    [color: string]: string;
  }>({});
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (value) {
      setSelectedColor({ [value]: "bright" });
      setValueColor({ [value]: "bright" });
    }
  }, []);

  return (
    <div className="position-relative">
      <div
        className={`
          ${styles.field} ${
          value ? styles.field_selectedColor : styles.field_noColor
        } ${isOpened ? styles.field_selected : ""}
        `}
        onClick={() => {
          setIsOpened(false);
        }}
        id="selectColor_toggleWindow"
      >
        <div
          className={`d-flex flex-column gap-1 ${
            value ? "justify-content-between" : "justify-content-center"
          }`}
        >
          {value ? <p className={styles.label}>Цвет</p> : null}
          <div
            className={`${styles.colorParams} ${
              !value ? styles.colorParams_noColorText : ""
            } ${
              Object.values(valueColor).length
                ? styles[
                    `colorParams_${Object.values(valueColor)[0]}_${
                      Object.keys(valueColor)[0]
                    }`
                  ]
                : ""
            }`}
          >
            {!value ? "Цвет" : ""}
          </div>
        </div>
        <div className={`align-self-center ${!value ? "pb-1" : ""}`}>
          <img
            className={isOpened ? styles.iconExpandRotated : ""}
            src={iconExpand}
          />
        </div>
      </div>
      {isOpened ? (
        <div className={styles.selectContainer}>
          <p className={styles.title}>Выберите цвет</p>
          <img
            src={iconClose}
            className={styles.iconClose}
            onClick={() => setIsOpened(false)}
            id="selectColor_closeWindow"
          />
          <div className={styles.picker}>
            {colors_list.map((color) => {
              return (
                <div key={color} className={styles.colors}>
                  {["bright", "normal", "light", "extra-light"].map((type) => {
                    return (
                      <div
                        key={`${color}_${type}`}
                        id={`SelectColor_${color}_${type}`}
                        className={`${styles[`circle_${type}_${color}`]} ${
                          selectedColor[color] === type
                            ? styles[`circle_${type}_${color}_selected`]
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedColor[color] !== type) {
                            setSelectedColor({ [color]: type });
                          }
                        }}
                      >
                        <div />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <Button
            type="button"
            theme={ButtonTheme.SECONDARY}
            id="selectColor_saveSelectedColor"
            className={styles.btnSave}
            disabled={
              Object.values(selectedColor)[0] === "bright" &&
              value === Object.keys(selectedColor)[0]
            }
            onClick={() => {
              setValueColor({
                [Object.keys(selectedColor)[0]]: Object.values(selectedColor)[0]
              });
              setFieldValue(name, Object.keys(selectedColor)[0]);
              setIsOpened(false);
            }}
          >
            Сохранить
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SelectColor;
