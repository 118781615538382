import styles from "./staffImportTableError.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";

type StaffImportTableErrorProps = {
  error: string;
};

const StaffImportTableError = ({ error }: StaffImportTableErrorProps) => {
  return (
    <>
      {error && (
        <div className={styles.error}>
          {error === "Обязательное поле" ? (
            <>
              <StatusIcon icon="attention" color="accent-red" />
              <p>Обязательное поле</p>
            </>
          ) : (
            <>
              <p>Некорректный формат</p>
              <Tooltip text={error} borderColor="bw-gray6" placement="top">
                <StatusIcon icon="iconquestionsmall" color="accent-red" />
              </Tooltip>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StaffImportTableError;
