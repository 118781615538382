import styles from "./staffOneStatusesGraphOneStatus.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import { Status } from "stores/StaffModule/types/Status";

import { classNames } from "shared/utils/helpers/classNames";

import StatusIcon from "shared/ui/StatusIcon";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneStatusesGraphOneStatusProps = {
  status: Status;
  isSubDate: boolean;
};

const StaffOneStatusesGraphOneStatus = ({
  status,
  isSubDate
}: StaffOneStatusesGraphOneStatusProps) => {
  const { staffOneStatusesStore } = useStores();

  return (
    <div
      className={classNames(styles.statusOneBlock, {}, [
        styles[
          `statusOneBlock_${
            staffOneStatusesStore.statusesParams[status.type].custom.color
          }`
        ]
      ])}
      id={`StaffOneStatusesGraphOneStatus_${status["id"]}`}
      onClick={staffOneStatusesStore.handleSetSelectedStatus(status)}
    >
      <div className={styles.mainInfo}>
        <div className={styles.statusTitleAndDate}>
          <StatusIcon
            icon={staffOneStatusesStore.statusesParams[status.type].custom.icon}
            color={
              staffOneStatusesStore.statusesParams[status.type].custom.color_api
            }
          />
          <p>
            {status["type_title"]}
            {status["bage"] ? (
              status["bage"]
            ) : isSubDate ? (
              <span
                className={classNames(styles.vacationMark, {}, [
                  styles[
                    `vacationMark_${
                      staffOneStatusesStore.statusesParams[status.type].custom
                        .color_api
                    }`
                  ]
                ])}
              >
                Ом
              </span>
            ) : (
              ""
            )}
          </p>
          <p className={styles.dateValue}>
            {getFormattedDate(status["event_start"])}{" "}
            {status["event_end"]
              ? ` - ${getFormattedDate(status["event_end"])}`
              : ""}
          </p>
        </div>
        <div>
          {calculateDays(status["event_start"], new Date()) > 0 ? (
            <p className={styles.inStatus}>
              Дней в статусе: <span>{status.days_in_status}</span>
            </p>
          ) : (
            <p className={styles.inStatus}>
              Статус начнется через <span>{status.days_in_status}</span>{" "}
              {getUnits(status.days_in_status, "день", "дня", "дней")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default observer(StaffOneStatusesGraphOneStatus);
