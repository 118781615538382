import styles from "./aregisterFormFieldsGroup.module.scss";
import { observer } from "mobx-react-lite";

import AregisterFormFieldsGroupOneField from "./AregisterFormFieldsGroupOneField";

type AregisterFormFieldsGroupProps = {
  keys: string[];
  values: { [key: string]: string | number };
  openedListName: string;
  changeOpenedWindows: (arg: string) => void;
  blockedFields: string[];
  type: string;
};

const AregisterFormFieldsGroup = ({
  keys,
  values,
  openedListName,
  changeOpenedWindows,
  blockedFields,
  type
}: AregisterFormFieldsGroupProps) => {
  switch (keys.length) {
    case 1:
      return (
        <AregisterFormFieldsGroupOneField
          name={keys[0]}
          values={values}
          openedListName={openedListName}
          changeOpenedWindows={changeOpenedWindows}
          blockedFields={blockedFields}
          type={type}
        />
      );
    case 2:
      return (
        <div
          className={`${styles.twoFields} ${
            keys.includes("date_start") && keys.includes("internal_num")
              ? styles.twoFields_withDateRangePicker
              : ""
          }`}
        >
          {keys.map((key) => {
            return (
              <AregisterFormFieldsGroupOneField
                key={key}
                name={key}
                values={values}
                openedListName={openedListName}
                changeOpenedWindows={changeOpenedWindows}
                blockedFields={blockedFields}
                type={type}
              />
            );
          })}
        </div>
      );
    case 3:
      return (
        <div className={styles.threeFields}>
          {keys.map((key) => {
            return (
              <AregisterFormFieldsGroupOneField
                key={key}
                name={key}
                values={values}
                openedListName={openedListName}
                changeOpenedWindows={changeOpenedWindows}
                blockedFields={blockedFields}
                type={type}
              />
            );
          })}
        </div>
      );
  }
};

export default observer(AregisterFormFieldsGroup);
