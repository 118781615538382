import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Input } from "shared/ui/Inputs/Input";
import styles from "./main.module.scss";
import SelectColor from "shared/ui/SelectColor";
import { useEffect, useState } from "react";
import SelectIcon from "shared/ui/SelectIcon";
import { StatusType } from "stores/StaffModule/staffStatus";

type MainProps = {
  values: StatusType;
  handleChange: (event: React.ChangeEvent<Element>) => void;
};

const Main = ({ values, handleChange }: MainProps) => {
  const { staffStatusStore } = useStores();

  const checkIfTitleExists = (value: string) => {
    let error = "";
    if (!staffStatusStore.selectedStatusId && value) {
      if (
        staffStatusStore.statusTitles
          .map((item) => item.toLowerCase())
          .includes(
            value.toLowerCase().replaceAll(" ", "").slice(0, value.length)
          )
      ) {
        error = "Такое название существует";
      }
    }
    return error;
  };

  const checkStatusIndex = (value: string) => {
    let error = "";
    if (!staffStatusStore.selectedStatusId) {
      if (Number(value) <= staffStatusStore.statusTitles.length) {
        error = `Не меньше ${staffStatusStore.statusTitles.length}`;
      }
    }
    return error;
  };

  const [isOpened, setIsOpened] = useState({
    color: false,
    icon: false
  });

  useEffect(() => {
    return () => {
      setIsOpened({
        color: false,
        icon: false
      });
    };
  }, [values]);

  return (
    <section>
      <p>Основное</p>

      <div className={styles.inputsContainerTop}>
        <Input
          name="index"
          validate={checkStatusIndex}
          onChange={(e) => {
            handleChange(e);
          }}
          label="№"
          disabled
        />
        <Input
          name="title"
          validate={checkIfTitleExists}
          onChange={(e) => {
            handleChange(e);
          }}
          label="Название статуса"
        />
        <Input
          name="custom.short_name"
          onChange={(e) => {
            handleChange(e);
            `custom.short_name`;
          }}
          label="Сокращенное название"
        />
      </div>
      <div className={styles.inputsContainerBottom}>
        <Input
          name="comment"
          onChange={(e) => {
            handleChange(e);
          }}
          label="Комментарий"
        />
        <SelectColor
          key={values["id"] + values["custom"]["color"]}
          name="custom.color"
          value={values["custom"]["color"]}
          isOpened={isOpened.color}
          setIsOpened={() =>
            setIsOpened({
              color: !isOpened.color,
              icon: false
            })
          }
        />
        <SelectIcon
          value={values["custom"]["icon"]}
          isOpened={isOpened.icon}
          setIsOpened={() =>
            setIsOpened({
              color: false,
              icon: !isOpened.icon
            })
          }
        />
      </div>
    </section>
  );
};

export default observer(Main);
