import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores/index";

const AregisterAllReference = () => {
  const { menuStore } = useStores();

  useEffect(() => {
    menuStore.setOpenedModule("aregister");
    menuStore.setOpenedSubmodule("reference");
    menuStore.addWindow("/aregister", "Реестр заявок строительных лесов");
  }, []);

  return <div>Справка</div>;
};

export default observer(AregisterAllReference);
