import styles from "./card.module.scss";
import Tooltip from "shared/ui/Tooltip";
import { ReactComponent as IconQuestion } from "shared/assets/images/mainIcons/iconQuestionSmall.svg";
import { Fragment } from "react";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";

type CardProps = {
  data: {
    title: string;
    value: string | number;
    tooltip_text?: string;
    money?: boolean;
  }[];
  color?: string;
  colspan?: number;
  center?: boolean;
  custom_data?: JSX.Element;
  onClick?: () => void;
};

// Требуется доработка компонента для переиспользования с учетом colspan, center, is_link и т.д.

const Card = ({
  colspan,
  color,
  center,
  onClick,
  custom_data,
  data
}: CardProps) => {
  return (
    <div
      className={
        colspan === 1
          ? "d-flex align-items-center justify-content-between gap-2"
          : ""
      }
    >
      <div
        className={`${styles.block} ${
          colspan === 1 ? styles.block_mini : ""
        }   ${styles[`block_${color || "gray5"}`]} ${
          onClick ? styles.pointer : ""
        }`}
        onClick={onClick}
      >
        {custom_data ? (
          <div className={`${styles.item} ${styles.borderRight}`}>
            {custom_data}
          </div>
        ) : (
          ""
        )}
        {data.map((element, index) => {
          return (
            <Fragment key={`${element.title}_${element.title}_${index}`}>
              <div
                className={`${styles.item} ${
                  center ? styles.item_center : ""
                } ${index !== data.length - 1 ? styles.borderRight : ""}`}
              >
                <div className={styles.title}>{`${element.title}:`}</div>
                <div className={styles.value}>
                  {element.money && typeof element.value === "number"
                    ? numberWithSpaces(element.value, true)
                    : element.value}
                  {element.tooltip_text?.length ? (
                    <Tooltip text={element.tooltip_text} placement="bottom">
                      <IconQuestion className={styles.iconQuestion} />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default Card;
