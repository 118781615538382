import styles from "./staffOneStatusesGraphTodayBtn.module.scss";
import { useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import StatusIcon from "shared/ui/StatusIcon";

const StaffOneStatusesGraphTodaybtn = () => {
  const { staffOneStatusesStore, menuStore } = useStores();
  const [todayBtnPosition, setTodayBtnPosition] = useState<"left" | "right">(
    "left"
  );

  const scrollTableBy = (value: number) => {
    menuStore.scrollbarRef.current.view.scroll({
      left: value,
      behavior: "smooth"
    });
  };

  return (
    <div className={styles.todayBtn}>
      <button
        className={`${styles.todayBtn__container} ${
          menuStore.scroll.left ? styles.todayBtn__container_hover : ""
        } ${
          todayBtnPosition === "right" ? styles.todayBtn__container_right : ""
        }`}
        onClick={() => {
          if (!staffOneStatusesStore.isReversedTable) {
            scrollTableBy(
              menuStore.scroll.scrollWidth - menuStore.scroll.clientWidth > 200
                ? menuStore.scroll.scrollLeft -
                    (menuStore.scroll.scrollWidth -
                      menuStore.scroll.clientWidth)
                : 0
            );
            setTodayBtnPosition("left");
          }
          if (staffOneStatusesStore.isReversedTable) {
            scrollTableBy(
              menuStore.scroll.scrollWidth - menuStore.scroll.clientWidth > 200
                ? menuStore.scroll.scrollLeft +
                    (menuStore.scroll.scrollWidth +
                      menuStore.scroll.clientWidth)
                : menuStore.scroll.scrollLeft + menuStore.scroll.clientWidth
            );
            setTodayBtnPosition("right");
          }
        }}
        id="StaffOneStatusesGraphTodaybtn_goToPrevious"
      >
        <div
          className={`${styles.todayBtn__button} ${
            staffOneStatusesStore.isReversedTable &&
            todayBtnPosition !== "right"
              ? styles.todayBtn__button_right
              : ""
          }`}
        >
          {!staffOneStatusesStore.isReversedTable && menuStore.scroll.left ? (
            <StatusIcon icon="iconarrowleft" color="blue-lazure" />
          ) : (
            ""
          )}
          <p
            className={`${styles.todayBtn__text} ${
              (staffOneStatusesStore.isReversedTable &&
                todayBtnPosition !== "right") ||
              (!staffOneStatusesStore.isReversedTable &&
                todayBtnPosition !== "left")
                ? styles.todayBtn__text_active
                : ""
            }`}
          >
            Сегодня
          </p>
          {staffOneStatusesStore.isReversedTable &&
          todayBtnPosition !== "right" ? (
            <StatusIcon icon="iconarrowleft" color="blue-lazure" />
          ) : (
            ""
          )}
        </div>
      </button>
    </div>
  );
};

export default observer(StaffOneStatusesGraphTodaybtn);
