import styles from "./staffVacTablePlannedObj.module.scss";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useState, useRef } from "react";
import { validationPlannedObject } from "./validation";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import Tooltip from "shared/ui/Tooltip";
import { Col } from "stores/StaffModule/types/Col";
import Select from "shared/ui/Inputs/Select";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type StaffVacTablePlannedObjProps = {
  object: {
    date_start: string;
    planned_object: string;
  };
  cols: {
    [key: string]: Col;
  };
};

const StaffVacTablePlannedObj = ({
  object,
  cols
}: StaffVacTablePlannedObjProps) => {
  const [openedField, setOpenedField] = useState("");
  const ref = useRef<HTMLFormElement>();
  useOnClickOutside({ ref, handler: () => setOpenedField("") });

  return (
    <div className={styles.container}>
      {Object.keys(object).length ? (
        <>
          <div className={styles.buildingTitle}>
            <p>
              {
                cols["planned_object"]["directory"][object["planned_object"]]?.[
                  "title"
                ]
              }
            </p>
            <Tooltip
              color="blue-lazure"
              text="Удаление планируемого объекта находится в разработке"
            >
              <IconClose className={styles.cross} />
            </Tooltip>
          </div>
          <div>{getFormattedDate(object["date_start"])}</div>
        </>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            planned_object: object["planned_object"]
              ? object["planned_object"]
              : "",
            date_start: object["date_start"] ? object["date_start"] : ""
          }}
          validateOnBlur
          onSubmit={(values) => {
            // eslint-disable-next-line no-console
            console.log("id", values);
          }}
          validationSchema={validationPlannedObject}
        >
          {({ values, isValid, handleSubmit, dirty }) => {
            return (
              <Form className={styles.form} onSubmit={handleSubmit} ref={ref}>
                <div
                  className={
                    values["planned_object"]
                      ? styles.select
                      : styles.select_withoutValue
                  }
                >
                  <Select
                    title="Планируемый объект"
                    name="planned_object"
                    options={cols["planned_object"]["directory"]}
                  />
                </div>
                <div className={styles.datepicker}>
                  <DatePickerField
                    name="date_start"
                    title={
                      cols["date_start"]
                        ? cols["date_start"]["title"]
                        : "Планируемая дата"
                    }
                    isCalendarOpened={openedField === "date_start"}
                    setIsCalendarOpened={() =>
                      setOpenedField(
                        openedField === "date_start" ? "" : "date_start"
                      )
                    }
                  />
                </div>

                <Tooltip
                  color="blue-lazure"
                  text="Сохранение планируемого объекта находится в разработке"
                >
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty}
                    theme={ButtonTheme.SECONDARY}
                    size={ButtonSize.M}
                    id="staffVacTablePlannedObj_saveForm"
                  >
                    Сохранить
                  </Button>
                </Tooltip>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default StaffVacTablePlannedObj;
