import styles from "./salaryPremiumsAllTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useNavigate } from "react-router-dom";

import { Table } from "react-bootstrap";
import LoadedComponent from "widgets/LoadedComponent";
import StatusIcon from "shared/ui/StatusIcon";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

//hardcode
const icons = {
  "8cde3376531f47b26b07c209eb3ee911b4d14df0": { icon: "coins" }, //статус Начислено (Подтверждено)
  "8c24bcb9faf90639fa0cbb7ce53e2c3e44d32a78": {
    icon: "circlecheck",
    color: "teal"
  } //статус Рассчитано
};

const SalaryPremiumsAllTable = () => {
  const { salaryPremiumsListStore, menuStore, filterStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      salaryPremiumsListStore.maxPage >= salaryPremiumsListStore.page &&
      salaryPremiumsListStore.page === salaryPremiumsListStore.prevPage
    ) {
      salaryPremiumsListStore.setPage(salaryPremiumsListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      <Table
        style={{
          marginTop: getTableMarginTop(
            filterStore.savedFiltersHeight,
            Boolean(salaryPremiumsListStore.searchValue),
            filterStore.isOpenAdvancedSearch ||
              filterStore.isOpenedSavedFilterOptions ||
              filterStore.isOpenedColumnsOptions,
            browserName === "Firefox"
          ),
          transition: "0.2s"
        }}
      >
        <thead
          className={styles.thead}
          style={{
            top: getTHeadTop(
              filterStore.savedFiltersHeight,
              Boolean(salaryPremiumsListStore.searchValue),
              filterStore.isOpenedSavedFilters && browserName === "Firefox"
            )
          }}
        >
          <tr>
            {salaryPremiumsListStore.currentTitles.map((title) => {
              return (
                <th key={title} className={styles.title}>
                  <div className="d-flex align-items-center">
                    <p className="m-0">
                      {
                        salaryPremiumsListStore.salaryPremiumsTableCols[
                          title
                        ]?.["title"]
                      }
                    </p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {salaryPremiumsListStore.premiumList.length ? (
            salaryPremiumsListStore.premiumList.map((premium, index) => {
              return (
                <tr
                  key={premium.id}
                  id={`SalaryPremiumsAllTable_row_${premium.id}`}
                  //хардкод
                  onClick={() => navigate(`./id=dfsfewrt43trferst`)}
                  className={index % 2 ? styles.greyRow : ""}
                >
                  {salaryPremiumsListStore.currentTitles.map((title) => {
                    switch (title) {
                      case "last_update":
                        return (
                          <td key={title}>
                            <p>
                              {getFormattedDate(premium[title].slice(0, 10))}
                            </p>
                          </td>
                        );
                      case "status":
                        return (
                          <td key={title}>
                            <div className={styles.statusCell}>
                              <StatusIcon
                                icon={icons[premium[title]]["icon"]}
                                color={
                                  icons[premium[title]]?.["color"] ||
                                  "accent-green"
                                }
                              />
                              <p>
                                {
                                  salaryPremiumsListStore.premiumStatusSelects[
                                    premium[title]
                                  ]?.["title"]
                                }
                              </p>
                            </div>
                          </td>
                        );
                      case "object":
                        return (
                          <td key={title}>
                            <Link
                              onClick={(event) => event.stopPropagation()}
                              id="SalaryPremiumsAllTable_goToBuilding"
                              to={`../../building/id=${premium[title]["id"]}/timesheet`}
                              target="_blank"
                              className={styles.objectLink}
                            >
                              {premium[title]["title"]}
                            </Link>
                          </td>
                        );
                      default:
                        return (
                          <td key={title}>
                            <p>{premium[title]}</p>
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr className={styles.error}>
              <td colSpan={salaryPremiumsListStore.currentTitles.length}>
                {salaryPremiumsListStore.errorMessage
                  ? salaryPremiumsListStore.errorMessage
                  : ""}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {salaryPremiumsListStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {salaryPremiumsListStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent isLoading={salaryPremiumsListStore.isLoading} />
      )}
    </>
  );
};

export default observer(SalaryPremiumsAllTable);
