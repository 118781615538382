import styles from "./aregisterAllReportNewReportTableWorkHoursCell.module.scss";
import { useState, useRef, useEffect } from "react";
import NumberFormat from "react-number-format";
import { useFormikContext } from "formik";
import { classNames } from "shared/utils/helpers/classNames";
import iconClose from "shared/assets/images/mainIcons/iconClose.svg";
import { AregisterAllReportNewReportTableCellProps } from "../AregisterAllReportNewReportTableCell/index";
import AregisterAllReportNewReportTableCell from "../AregisterAllReportNewReportTableCell/index";

interface AregisterAllReportNewReportTableWorkHoursCellProps
  extends AregisterAllReportNewReportTableCellProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hour: number;
  disabled?: boolean;
  onKeyDown: (e: { keyCode: number }) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  name: string;
  autoFocus?: boolean;
  canceled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const AregisterAllReportNewReportTableWorkHoursCell = ({
  hour,
  onChange,
  disabled,
  onKeyDown,
  value,
  name,
  canceled,
  autoFocus,
  onBlur,
  ...props
}: AregisterAllReportNewReportTableWorkHoursCellProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [oldValue, setOldValue] = useState(0);
  const [oldAutoFocus, setOldAutoFocus] = useState(false);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
      if (autoFocus !== oldAutoFocus) {
        setOldValue(value);
        setOldAutoFocus(autoFocus);
      }
    }
    if (inputRef.current && !autoFocus) {
      inputRef.current.blur();
      if (autoFocus !== oldAutoFocus) {
        setOldAutoFocus(autoFocus);
      }
    }
  }, [autoFocus, inputRef.current]);

  return (
    <AregisterAllReportNewReportTableCell {...props}>
      {disabled && autoFocus ? (
        <span className={styles.closedAreg}>
          {canceled ? "Заявка отменена" : "Заявка закрыта"}
        </span>
      ) : (
        <div className={styles.input}>
          <NumberFormat
            getInputRef={inputRef}
            name={name}
            value={hour ? hour : ""}
            onChange={onChange}
            className={classNames(styles.hours, {
              [styles.hours__orange]: hour !== undefined && hour > 10,
              [styles.hours_disabled]: !hour && !autoFocus
            })}
            disabled={disabled}
            onKeyDown={(e: { keyCode: number }) => {
              if (e.keyCode === 27) {
                setFieldValue(name, oldValue);
              }
              onKeyDown && onKeyDown(e);
            }}
            onBlur={onBlur}
            autoFocus={autoFocus}
            allowNegative={false}
            isAllowed={({ formattedValue, floatValue }) =>
              formattedValue === "" || floatValue <= 24
            }
          />
          {!hour && !autoFocus && !disabled ? (
            <img
              src={iconClose}
              alt="add_work_hours"
              className={styles.iconAddHours}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </AregisterAllReportNewReportTableCell>
  );
};

export default AregisterAllReportNewReportTableWorkHoursCell;
