import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";

import BuildingOneWorkshiftStafftableInputEdit from "./BuildingOneWorkshiftStafftableInputEdit";
import BuildingOneWorkshiftStafftableInputShow from "./BuildingOneWorkshiftStafftableInputShow";

import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { StaffType } from "stores/BuildingModule/types/StaffType";

const BuildingOneWorkshiftStafftableInput = ({
  name,
  staffIndex
}: WorkshiftStaffFieldProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { values } = useFormikContext<StaffType>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isTouched, setIsTouched] = useState("");
  const { id } = useParams();

  const value = values[staffIndex][name];

  useEffect(
    () => setIsTouched(""),
    [
      buildingOneWorkshiftStore.openedMode[id],
      buildingOneWorkshiftStore.selectedFilters[id]
    ]
  );

  const mode = buildingOneWorkshiftStore.getOpenedModeForField(name);

  switch (mode) {
    case "show":
      return <>{value}</>;
    case "edit":
      if (isTouched?.length && isTouched === values[staffIndex].uid) {
        return (
          <BuildingOneWorkshiftStafftableInputEdit
            name={name}
            staffIndex={staffIndex}
            isMenuOpened={isMenuOpened}
            isTouched={isTouched}
            setIsMenuOpened={setIsMenuOpened}
            setIsTouched={setIsTouched}
          />
        );
      } else {
        return (
          <BuildingOneWorkshiftStafftableInputShow
            staffIndex={staffIndex}
            setIsMenuOpened={setIsMenuOpened}
            setIsTouched={setIsTouched}
            name={name}
          />
        );
      }
  }
};

export default observer(BuildingOneWorkshiftStafftableInput);
