import styles from "./friendInviteOneInvite.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import { Link } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";

import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import { calculateYears } from "shared/utils/helpers/calculateYears";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import { getUnits } from "shared/utils/helpers/getUnits";
import { getKeys } from "shared/utils/helpers/getKeys";

const FriendInviteOneInvite = () => {
  const { menuStore, friendInviteStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    menuStore.setOpenedModule("friendInviteOne");
    menuStore.setOpenedSubmodule("friendInviteOne");
  }, []);

  useEffect(() => {
    menuStore.sendTabId(id);
    friendInviteStore.setSelectedOneInvite(id);
    menuStore.updateWindow({
      mainPath: `/friendinvite/id=${id}`,
      path: `/friendinvite/id=${id}`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={friendInviteStore.isLoading}
      error={friendInviteStore.error}
    >
      <div className={styles.container}>
        <div className={styles.title}>Описание</div>
        {getKeys(friendInviteStore.inviteOneFriendItem).length ? (
          <>
            {friendInviteStore.showFields.map((field) => {
              switch (field) {
                case "time_create":
                  return (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.row}>
                        <div className={styles.columns}>
                          <div className={styles.subtitle}>
                            {
                              friendInviteStore.inviteOneFriendTitles[
                                "time_create"
                              ]["title"]
                            }
                          </div>
                          {getFormattedDate(
                            friendInviteStore.inviteOneFriendItem["time_create"]
                          ).replace(/ /g, ", ")}
                        </div>
                        {friendInviteStore.showFields.includes("author") ? (
                          <div className={styles.columns}>
                            <div className={styles.subtitle}>
                              {
                                friendInviteStore.inviteOneFriendTitles[
                                  "author"
                                ]["title"]
                              }
                            </div>
                            <div>
                              {friendInviteStore.inviteOneFriendItem["author"]}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                case "author":
                  return !friendInviteStore.showFields.includes(
                    "time_create"
                  ) ? (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.row}>
                        <div className={styles.columns}>
                          <div className={styles.subtitle}>
                            {
                              friendInviteStore.inviteOneFriendTitles["author"][
                                "title"
                              ]
                            }
                          </div>
                          <div>
                            {friendInviteStore.inviteOneFriendItem["author"]}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                case "uid":
                  return friendInviteStore.inviteOneFriendItem["uid"] ? (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.subtitle}>
                        {
                          friendInviteStore.inviteOneFriendTitles["uid"][
                            "title"
                          ]
                        }
                      </div>
                      <div className={styles.col}>
                        <div className="d-flex">
                          <Link
                            className={styles.tn}
                            id={`FriendInviteOneInvite_showOneStaff_${friendInviteStore.inviteOneFriendItem["by"]["id"]}`}
                            to={`/staff/id=${friendInviteStore.inviteOneFriendItem["by"]["id"]}`}
                            target="_blank"
                          >
                            {friendInviteStore.inviteOneFriendItem["by"][
                              "tn"
                            ] &&
                              friendInviteStore.inviteOneFriendItem["by"]["tn"]}
                          </Link>
                          {friendInviteStore.inviteOneFriendItem["by"]["name"]}
                        </div>

                        {friendInviteStore.inviteOneFriendItem["by"]["company"]}
                        {getValues(
                          friendInviteStore.inviteOneFriendItem.by.events
                        ).map((event) => {
                          return (
                            <div key={event.company} className={styles.status}>
                              <Badge
                                colorName={
                                  !isEmpty(friendInviteStore.companiesList)
                                    ? getValues(
                                        friendInviteStore.companiesList
                                      ).find(
                                        (company) =>
                                          event.company === company.title
                                      )?.color
                                    : "primary"
                                }
                                text={event.company}
                              />
                              <Badge
                                text={event.type.title}
                                textColorName="bw-gray7"
                                icon={event.type.icon}
                                iconColor={event.type.color}
                                colorName="none"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null;
                case "alt_uid":
                  return friendInviteStore.inviteOneFriendItem["alt_uid"] ? (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.subtitle}>
                        {
                          friendInviteStore.inviteOneFriendTitles["alt_uid"][
                            "title"
                          ]
                        }
                      </div>
                      <div className={styles.col}>
                        {friendInviteStore.inviteOneFriendItem["alt_uid"]}
                        <div className={styles["notStaff_bw-gray5"]}>
                          Не сотрудник
                        </div>
                      </div>
                    </div>
                  ) : null;
                case "uc":
                  return (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.row}>
                        <div className={styles.columns}>
                          <div className={styles.subtitle}>
                            {
                              friendInviteStore.inviteOneFriendTitles["uc"][
                                "title"
                              ]
                            }
                          </div>
                          {friendInviteStore.inviteOneFriendItem["uc"] && (
                            <div>
                              {friendInviteStore.inviteOneFriendItem["uc"]}
                            </div>
                          )}
                        </div>
                        {friendInviteStore.showFields.includes("uc_date") ? (
                          <div className={styles.columns}>
                            <div className={styles.subtitle}>
                              {
                                friendInviteStore.inviteOneFriendTitles[
                                  "uc_date"
                                ]["title"]
                              }
                            </div>
                            {friendInviteStore.inviteOneFriendItem[
                              "uc_date"
                            ] && (
                              <div>
                                {getFormattedDate(
                                  friendInviteStore.inviteOneFriendItem[
                                    "uc_date"
                                  ]
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                case "uc_date":
                  return !friendInviteStore.showFields.includes("uc") ? (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.row}>
                        <div className={styles.columns}>
                          <div className={styles.subtitle}>
                            {
                              friendInviteStore.inviteOneFriendTitles[
                                "uc_date"
                              ]["title"]
                            }
                          </div>
                          {friendInviteStore.inviteOneFriendItem["uc_date"] && (
                            <div>
                              {getFormattedDate(
                                friendInviteStore.inviteOneFriendItem["uc_date"]
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null;
                case "candidate_tn":
                  return friendInviteStore.inviteOneFriendItem[
                    "candidate_tn"
                  ] ? (
                    <div key={field}>
                      <div className={styles.borderBottom}>
                        <div className={styles.subtitle}>
                          {
                            friendInviteStore.inviteOneFriendTitles[
                              "candidate_tn"
                            ]["title"]
                          }
                        </div>
                        <div className={styles.row}>
                          <Link
                            className={styles.tn}
                            id={`FriendInviteOneInvite_showCandidate_${friendInviteStore.inviteOneFriendItem["candidate_staff_id"]}`}
                            to={`/staff/id=${friendInviteStore.inviteOneFriendItem["candidate_staff_id"]}`}
                            target="_blank"
                          >
                            {
                              friendInviteStore.inviteOneFriendItem[
                                "candidate_tn"
                              ]
                            }
                          </Link>
                          <div>
                            {
                              friendInviteStore.inviteOneFriendItem[
                                "candidate_surname"
                              ]
                            }{" "}
                            {
                              friendInviteStore.inviteOneFriendItem[
                                "candidate_name"
                              ]
                            }
                          </div>
                        </div>
                      </div>
                      <div className={styles.borderBottom}>
                        <div
                          className={
                            getKeys(
                              friendInviteStore.inviteOneFriendItem[
                                "candidate_events"
                              ]
                            ).length
                              ? styles.column
                              : ""
                          }
                        >
                          <div className={styles.subtitle}>
                            {
                              friendInviteStore.inviteOneFriendTitles[
                                "candidate_days"
                              ]["title"]
                            }
                          </div>
                          {friendInviteStore.inviteOneFriendItem[
                            "candidate_days"
                          ] !== -1
                            ? friendInviteStore.inviteOneFriendItem[
                                "candidate_days"
                              ]
                            : 0}
                        </div>
                      </div>{" "}
                      {getKeys(
                        friendInviteStore.inviteOneFriendItem[
                          "candidate_events"
                        ]
                      ).length ? (
                        <div className={styles.borderBottom}>
                          <ol className={styles.eventsList}>
                            {getValues(
                              friendInviteStore.inviteOneFriendItem[
                                "candidate_events"
                              ]
                            ).map((event) => {
                              return (
                                <li
                                  key={event.building_id}
                                  className={styles.listItem}
                                >
                                  <span className={styles.row}>
                                    <span className={styles.columns}>
                                      <span className={styles.subtitle}>
                                        {
                                          friendInviteStore
                                            .inviteOneFriendTitles[
                                            "candidate_events"
                                          ]["event_start"]["title"]
                                        }
                                      </span>
                                      {getFormattedDate(
                                        event.event_start
                                      ).slice(0, -9)}
                                    </span>
                                    <span className={styles.columns}>
                                      <span className={styles.subtitle}>
                                        {
                                          friendInviteStore
                                            .inviteOneFriendTitles[
                                            "candidate_events"
                                          ]["status"]["title"]
                                        }
                                      </span>
                                      <span>{event.status}</span>
                                    </span>
                                    <span className={styles.columns}>
                                      <span className={styles.subtitle}>
                                        {
                                          friendInviteStore
                                            .inviteOneFriendTitles[
                                            "candidate_events"
                                          ]["building"]["title"]
                                        }
                                      </span>
                                      <span className={styles.tn}>
                                        {event.building}
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      ) : null}
                    </div>
                  ) : null;
                case "candidate_phone":
                  return (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.subtitle}>
                        {
                          friendInviteStore.inviteOneFriendTitles[
                            "candidate_phone"
                          ]["title"]
                        }
                      </div>
                      {formatPhoneNumber(
                        friendInviteStore.inviteOneFriendItem["candidate_phone"]
                      )}
                    </div>
                  );
                case "id":
                  return null;
                case "candidate_birthday":
                  return (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.subtitle}>
                        {friendInviteStore.inviteOneFriendTitles[field].title}
                      </div>
                      <div>
                        <div className={styles.candidateBirthday}>
                          {getFormattedDate(
                            friendInviteStore.inviteOneFriendItem[field]
                          )}
                          <div className={styles.birthday}>
                            (
                            {`${calculateYears(
                              getParsedDate(
                                getFormattedDate(
                                  friendInviteStore.inviteOneFriendItem[field]
                                )
                              ),
                              new Date()
                            )} ${getUnits(
                              calculateYears(
                                getParsedDate(
                                  getFormattedDate(
                                    friendInviteStore.inviteOneFriendItem[field]
                                  )
                                ),
                                new Date()
                              ),
                              "год",
                              "года",
                              "лет"
                            )}`}
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                default:
                  return (
                    <div className={styles.borderBottom} key={field}>
                      <div className={styles.subtitle}>
                        {friendInviteStore.inviteOneFriendTitles[field].title}
                      </div>
                      {getFormattedDate(
                        friendInviteStore.inviteOneFriendItem[field]
                      )}
                    </div>
                  );
              }
            })}
          </>
        ) : (
          ""
        )}
      </div>
    </LoadedComponent>
  );
};

export default observer(FriendInviteOneInvite);
