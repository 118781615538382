import { classNames } from "shared/utils/helpers/classNames";
import styles from "./button.module.scss";
import { ButtonHTMLAttributes, ReactNode } from "react";

export enum ButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CANCELLED = "cancelled",
  FLOATING = "floating",
  CLEAR = "clear",
  ROUND = "round",
  TAB = "tab",
  TAB_WITH_TEXT = "tab_with_text"
}

export enum ButtonSize {
  S = "size_s",
  M = "size_m",
  L = "size_l"
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  /**
   * Стили, заданные в родителе для кнопки
   */
  className?: string;
  /**
   * Вид кнопки, передаем из enum ButtonTheme
   */
  theme?: ButtonTheme;
  /**
   * Размер кнопки, передаем из enum ButtonSize
   */
  size?: ButtonSize;
  selected?: boolean;
  label?: string;
}

export const Button = ({
  className,
  children,
  theme = ButtonTheme.PRIMARY,
  size,
  label,
  selected,
  ...otherProps
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles[theme]]: theme,
          [styles[size]]: size,
          [styles[`${theme}Active`]]: selected,
          [styles.withLabel]: label !== undefined
        },
        [className ? className : ""]
      )}
      type="button"
      {...otherProps}
    >
      {label !== undefined && <p className={styles.label}>{label}</p>}
      {children}
    </button>
  );
};
