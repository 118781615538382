import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useState } from "react";

import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";

type BuildingOneWorkshiftStafftableWarningProps = {
  name: string;
  ws_staff_id: string;
};

const BuildingOneWorkshiftStafftableWarning = ({
  name,
  ws_staff_id
}: BuildingOneWorkshiftStafftableWarningProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const [isHovering, setIsHovering] = useState(false);
  const { id } = useParams();

  return buildingOneWorkshiftStore.openedMode[id] === "edit" &&
    buildingOneWorkshiftStore.editingWarnings[name]?.includes(ws_staff_id) ? (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <Tooltip text="Данные не сохранены">
          <StatusIcon icon="attention" color="warning" />
        </Tooltip>
      ) : (
        <StatusIcon icon="attention" color="warning" />
      )}
    </span>
  ) : null;
};

export default observer(BuildingOneWorkshiftStafftableWarning);
