import styles from "./staffOneInfoHistoryOne.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";

import icon1C from "shared/assets/images/mainIcons/icon1C.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Badge } from "shared/ui/Badge";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

type StaffOneInfoHistoryOneProps = {
  time: string;
  user_name: string | number;
  historyItems: Array<{
    [key: string]: string | null | number;
  }>;
};

const StaffOneInfoHistoryOne = ({
  time,
  user_name,
  historyItems
}: StaffOneInfoHistoryOneProps) => {
  const { staffOneStore } = useStores();

  return (
    <div className={styles.storyWrapper}>
      <div className={styles.storyData}>
        <div className={styles.date}>
          {getFormattedDate(time).replace(/ /g, ", в ")}
        </div>
        <div className={styles.name}>
          {user_name === "crm_bot" ? (
            <img src={icon1C} alt="icon" />
          ) : (
            user_name
          )}
        </div>
      </div>
      {historyItems.map((item) => {
        const { id, text, company } = item;
        return (
          <div className={styles.history} key={id}>
            {company && (
              <Badge
                text={company as string}
                colorName={
                  !isEmpty(staffOneStore.companiesList)
                    ? getValues(staffOneStore.companiesList).find(
                        (companyData) => companyData.title === company
                      )?.color
                    : "primary"
                }
              />
            )}
            <div className={styles.actions}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(StaffOneInfoHistoryOne);
