import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "stores/StaffModule/types/Col";
import { Building } from "stores/StaffModule/types/Building";
import { SelectModule } from "stores/StaffModule/types/Selects";
import { Errors } from "stores/utils/types/ErrorsType";
import { Company } from "stores/StaffModule/types/Company";
import { Project } from "stores/utils/types/Project";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import work_direction from "../../ProjectsModule/work_direction.json";
import { fileUrl } from "stores/utils/consts";

interface ICol extends Col {
  id?: string;
}

type BuildingVariables = {
  hide_objectstatus: SelectModule;
  payment_types: SelectModule;
  project: SelectModule;
  status: SelectModule;
  type: SelectModule;
  company: { [key: string]: Company };
  users: { [key: string]: { id: number; title: string } };
};

type BuildingData = {
  // tableCols + tableParams
  columns: { [key: string]: Col };
  // данные объекта
  record: Partial<Building>;
} & BuildingVariables;

interface IBuildingOneModify extends Omit<Building, "users"> {
  users: { [key: string]: string | number };
  work_direction: string;
}

export default class BuildingOneStore {
  error: { [building_id: string]: boolean | string } = {};
  errorText: { [building_id: string]: string } = {};
  isLoading = false;

  selectedOne: Partial<IBuildingOneModify> = {};
  openedAllBuildings: { [key: string]: Partial<IBuildingOneModify> } = {};
  openedAllProjects: { [key: string]: Partial<Project> } = {};

  variables: Partial<BuildingVariables> = {};
  buildingCols: { [key: string]: Col } = {};
  buildingsList: { [key: string]: ICol } = {};

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  setSelectedBuildingForInfo = (id: string) => {
    this.isLoading = true;
    this.error[id] = false;
    this.errorText[id] = "";

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/building", "Объекты");
    }
    if (!this.rootStore.menuStore.allWindows[`/building/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/building/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllBuildings[id];
    }

    if (
      Object.keys(this.openedAllBuildings).length &&
      this.openedAllBuildings[id]
    ) {
      if (Object.values(this.openedAllBuildings[id]).length) {
        this.selectedOne = this.openedAllBuildings[id];
      } else {
        this.error[id] = true;
      }
      this.isLoading = false;
    } else {
      this.getBuildingOne(id);
    }
  };

  getBuildingOne = async (id: string) => {
    try {
      const data: BuildingData & { code: number; message: Errors["message"] } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getOne",
          params: {
            building_id: id
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/building/id=${id}`,
            title: data.record?.["title"]
          });

          this.openedAllBuildings[id] = data.record;

          Promise.all([this.getProject(data.record.project, id)]).then(() => {
            Object.entries(data).forEach(([key, value]) => {
              if (
                [
                  "code",
                  "hash",
                  "message",
                  "record",
                  "tableparams",
                  "show_fields"
                ].includes(key)
              ) {
                return;
              }
              if (key === "columns") {
                this.buildingCols = data.columns;
              } else if (key === "users" && data.columns[key]?.show) {
                this.variables[key] = {};
                Object.values(value).forEach(
                  (staff) =>
                    (this.variables[key][staff.id] = {
                      title: staff.name,
                      id: staff.id
                    })
                );
              } else {
                this.variables[key] = value;
              }
            });

            runInAction(() => (this.selectedOne = this.openedAllBuildings[id]));
          });
        } else {
          this.openedAllBuildings[id] = {};

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/building/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error[id] = true;
          // hardcode
          this.errorText[id] = data.message.head;
        }
      });
    } catch (error) {
      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/building/id=${id}`,
        title: "Ничего не найдено"
      });
      runInAction(() => {
        this.error[id] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getProject = async (id: string, building_id: string) => {
    try {
      const data: ApiResponse<Project | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "projects",
          currentClass: "projects",
          method: "getByColOne",
          params: {
            col: "id",
            value: id
          }
        });

      runInAction(() => {
        if (data.result !== -1) {
          this.openedAllBuildings[building_id].work_direction =
            data.result.work_direction;
        } else {
          this.error[id] = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    }
  };

  getLinkForApplications = (
    type: "form" | "list" | "paymentlist" | "premium" | "paymentlist_dismiss"
  ) => {
    if (this.selectedOne?.id) {
      const module = this.selectedOne
        ? work_direction[this.selectedOne.work_direction]?.module
        : "";

      const links = {
        form: `${fileUrl}/crm/${module}/?addRecord&building=${this.selectedOne.id}&selectCompany=${this.selectedOne.company}`,
        list: `${fileUrl}/crm/${module}`,
        paymentlist: `${fileUrl}/crm/salary/?action=paymentlist&filter%5Bobject%5D%5B0%5D=${this.selectedOne.id}`,
        paymentlist_dismiss: `${fileUrl}/crm/salary/?action=paymentlist_dismiss&filter%5Bobject%5D%5B0%5D=${this.selectedOne.id}&anyValue[staff]=on&filter[staff]`,
        premium: `${fileUrl}/crm/salary/?action=premium&filter%5Bobject%5D%5B0%5D=${this.selectedOne.id}`
      };

      return links[type];
    }
  };
}
