import styles from "./buildingOneTimesheetHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { Formik } from "formik";
import DatePickerField from "shared/ui/Inputs/DatePickerField";

import { getYear } from "date-fns";
import { months } from "shared/utils/dictionaries/months";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { Button, ButtonTheme } from "shared/ui/Button";

const staffFilter = {
  all: { id: "all", title: "Все" },
  itr: { id: "itr", title: "Итр" },
  non_itr: { id: "non_itr", title: "не ИТР" }
};

const BuildingOneTimesheetHeader = () => {
  const { id } = useParams();
  const { buildingOneTimesheetStore } = useStores();
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filterParams: { [key: string]: string } = {};

    for (const [key, value] of searchParams.entries()) {
      filterParams[key] =
        key === "month"
          ? `${buildingOneTimesheetStore.month[id]}`
          : key === "year"
          ? `${buildingOneTimesheetStore.year[id]}`
          : value;
    }

    setSearchParams(filterParams);
  }, [buildingOneTimesheetStore.month[id]]);

  return (
    <div className={styles.buttons}>
      {buildingOneTimesheetStore.selectedMode[id] === "timesheet" ? (
        <div className={styles.buttons_group}>
          <p>Сотрудники</p>
          <div>
            <ButtonsTabs
              tabs={getValues(staffFilter)}
              selectedTab={buildingOneTimesheetStore.selectedStaffFilter}
              changeActiveTab={buildingOneTimesheetStore.setSelectedStaffFilter}
            />
          </div>
        </div>
      ) : null}

      <div className={styles.buttons_group}>
        <p>Год</p>
        <Formik
          initialValues={{ year: buildingOneTimesheetStore.year[id] }}
          onSubmit={() => null}
        >
          {() => (
            <div>
              <DatePickerField
                name="year"
                isCalendarOpened={isCalendarOpened}
                setIsCalendarOpened={() =>
                  setIsCalendarOpened(!isCalendarOpened)
                }
                dateFormat="yyyy"
                title=""
                isButton
                minDate={
                  new Date(
                    getValues(
                      buildingOneTimesheetStore.allYearsAndMonth[id].years
                    ).sort((a, b) => (a > b ? 1 : -1))[0] - 1,
                    1
                  )
                }
                maxDate={
                  new Date(
                    getValues(
                      buildingOneTimesheetStore.allYearsAndMonth[id].years
                    ).sort((a, b) => (a > b ? -1 : 1))[0],
                    1
                  )
                }
                onChange={(value) => {
                  const filterParams: { [key: string]: string } = {};

                  buildingOneTimesheetStore.setSearchValue(id, "");

                  for (const [key, value] of searchParams.entries()) {
                    filterParams[key] =
                      key === "year" ? `${getYear(new Date(value))}` : value;
                  }

                  setSearchParams(filterParams);
                  buildingOneTimesheetStore.setSelectedYear(
                    getYear(new Date(value)),
                    id
                  );
                }}
                placement="bottom-start"
              />
            </div>
          )}
        </Formik>
      </div>

      <div className={styles.buttons_group}>
        <p>Месяц</p>
        <div>
          {getEntries(months).map(([month, title]) => {
            const handleSelectedMonth = () => {
              buildingOneTimesheetStore.setSearchValue(id, "");
              buildingOneTimesheetStore.setSelectedMonth(+month, id);
            };
            return (
              <Button
                theme={ButtonTheme.TAB}
                id={`BuildingOneTimesheetHeader_setSelectedMonth_${title}`}
                key={`months_${month}`}
                selected={buildingOneTimesheetStore.month[id] === +month}
                onClick={handleSelectedMonth}
                disabled={
                  !getValues(
                    buildingOneTimesheetStore.allYearsAndMonth[id].months[
                      buildingOneTimesheetStore.year[id]
                    ]
                  ).includes(+month)
                }
              >
                {title}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default observer(BuildingOneTimesheetHeader);
