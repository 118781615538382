import { format } from "date-fns";
import styles from "./customHeader.module.scss";
import iconLeft from "shared/assets/images/mainIcons/iconLeft.svg";
import ru from "date-fns/locale/ru";
import { classNames } from "shared/utils/helpers/classNames";
import { MouseEvent } from "react";

type CustomHeaderProps = {
  customHeaderCount: number;
  monthDate: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
};

const CustomHeader = ({
  customHeaderCount,
  monthDate,
  decreaseMonth,
  increaseMonth
}: CustomHeaderProps) => {
  const date = format(monthDate, "LLLL yyyy", {
    locale: ru
  });

  const capitalLettetDate = date[0].toUpperCase() + date.substring(1);

  const handleChangeMonth =
    (callback: () => void) =>
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.preventDefault();
      callback();
    };

  return (
    <div className={styles.wrapper}>
      <button
        onClick={handleChangeMonth(decreaseMonth)}
        className={classNames(styles.btnPrev, {
          [styles.hiddenBtn]: customHeaderCount === 1
        })}
        id="CustomHeader_btnDecreaseMonth"
      >
        <img src={iconLeft} alt="prevMonth" />
      </button>
      <span
        className={classNames("react-datepicker__current-month", {}, [
          styles.header
        ])}
      >
        {capitalLettetDate}
      </span>
      <button
        onClick={handleChangeMonth(increaseMonth)}
        className={classNames(styles.btnNext, {
          [styles.hiddenBtn]: customHeaderCount === 0
        })}
        id="CustomHeader_btnIncreaseMonth"
      >
        <img src={iconLeft} alt="nextMonth" />
      </button>
    </div>
  );
};

export default CustomHeader;
