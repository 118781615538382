import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import AllOperations from "shared/assets/images/menuIcons/iconDefault/AllOperations.svg";
import AllOperationsSelected from "shared/assets/images/menuIcons/iconSelected/AllOperations.svg";

import AllStatements from "shared/assets/images/menuIcons/iconDefault/AllStatements.svg";
import AllStatementsSelected from "shared/assets/images/menuIcons/iconSelected/AllStatements.svg";

import AllPremium from "shared/assets/images/menuIcons/iconDefault/AllPremium.svg";
import AllPremiumSelected from "shared/assets/images/menuIcons/iconSelected/AllPremium.svg";

import AddPremium from "shared/assets/images/menuIcons/iconDefault/AddPremium.svg";
import AddPremiumSelected from "shared/assets/images/menuIcons/iconSelected/AddPremium.svg";

import AddOperations from "shared/assets/images/menuIcons/iconDefault/AddOperations.svg";
import AddOperationsSelected from "shared/assets/images/menuIcons/iconSelected/AddOperations.svg";

import AddStatements from "shared/assets/images/menuIcons/iconDefault/AddStatements.svg";
import AddStatementsSelected from "shared/assets/images/menuIcons/iconSelected/AddStatements.svg";
const SalarySection = () => {
  const overview = [
    {
      text: "Операции",
      to: "/salary/",
      moduleName: "salary",
      submoduleName: "main",
      defaultIcon: AllOperations,
      selectedIcon: AllOperationsSelected
    },
    {
      text: "Премии",
      to: "/salary/premium",
      moduleName: "salary",
      submoduleName: "premium",
      defaultIcon: AllPremium,
      selectedIcon: AllPremiumSelected
    },
    {
      text: "Ведомости",
      to: "/salary/paymentlist",
      moduleName: "salary",
      submoduleName: "paymentlist",
      defaultIcon: AllStatements,
      selectedIcon: AllStatementsSelected
    }
  ];

  const actions = [
    {
      text: "Создать операцию",
      to: "/salary/add_operation",
      moduleName: "salary",
      submoduleName: "add_operation",
      defaultIcon: AddOperations,
      selectedIcon: AddOperationsSelected
    },
    {
      text: "Создать премию",
      to: "/salary/add_premium",
      moduleName: "salary",
      submoduleName: "add_premium",
      defaultIcon: AddPremium,
      selectedIcon: AddPremiumSelected
    },
    {
      text: "Создать ведомость",
      to: "/salary/add_payment",
      moduleName: "salary",
      submoduleName: "add_payment",
      defaultIcon: AddStatements,
      selectedIcon: AddStatementsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default SalarySection;
