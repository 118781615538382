import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "../StaffModule/types/Col";
import { Param } from "stores/utils/types/Param";
import { Project } from "stores/utils/types/Project";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { ProjectsSelectType } from "./types/ProjectsSelectType";
import { FilterType } from "stores/utils/types/FilterType";
import { StaffList } from "stores/StaffModule/types/StaffList";

import work_direction from "./work_direction.json";

export default class ProjectsListStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;

  projectsListOrder = "status";
  projectsListOrdered = "ASC";

  projectsTableCols: { [key: string]: Col } = {};
  projectsTableParams: { [key: string]: Param } = {};

  selects: Partial<{
    [key: string]: { [key: string]: ProjectsSelectType };
  }> = {};

  projectsList: Project[] = [];

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  filters: { [key: string]: FilterType } = {};
  searchValue = "";
  filterParams = {};
  selectedFilter = "";
  downloadedFilter = "";

  // staff-данные для фильтров
  staffTableCols: { [key: string]: Col } = {};
  staffTableParams: { [key: string]: Col } = {};
  staffColsAll: { [key: string]: Col } = {};

  actionButton = {
    "0": {
      id: 1,
      title: "OK",
      action: () => {
        this.setErrorMessage("");
      }
    }
  };

  getProjectsData = () => {
    this.isLoading = true;

    Promise.all([
      !Object.keys(this.filters).length && this.getStaffFilters(),
      !Object.keys(this.projectsTableCols).length && this.getTableCols(),
      !Object.keys(this.projectsTableParams).length && this.getTableParams(),
      !this.selects.priority && this.getProjectsPriority(),
      !this.selects.type && this.getProjectsTypes(),
      !this.selects.status && this.getProjectsStatus(),
      !this.selects.company && this.getCompaniesList(),
      !this.selects.work_direction && this.getWorkDirectionList(),
      this.getProjectsList()
    ]).then(() => {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    });
  };

  getProjectsList = async () => {
    this.errorMessage = "";
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.projectsList = [];

    try {
      const data: ApiResponse<
        | {
            [key: string]: Project;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getList",
        on_page: this.onPage,
        params: {
          "order[]": this.projectsListOrder,
          "ordered[]": this.projectsListOrdered
        }
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsList.push(...Object.values(data["result"]));
        } else this.errorMessage = "Ничего не найдено";

        this.maxPage = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getMoreProjects = async () => {
    this.errorMessage = "";
    this.isLoading = true;

    try {
      const data: ApiResponse<
        | {
            [key: string]: Project;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getList",
        on_page: this.onPage,
        page: this.page,
        params: {
          "order[]": this.projectsListOrder,
          "ordered[]": this.projectsListOrdered
        }
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsList.push(...Object.values(data["result"]));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.projectsList = [];
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: Col;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getTableCols"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsTableCols = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: Param;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getTableParams"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsTableParams = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsPriority = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_priority",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.priority = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsTypes = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_types",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.type = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsStatus = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_status",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.status = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((company) => {
            !this.selects.company ? (this.selects.company = {}) : "";
            this.selects.company[company.id] = company;
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getWorkDirectionList = async () => {
    this.selects.work_direction = work_direction;
  };

  // хардкод для отображения фильтров
  getStaffFilters = async (action?: string, id?: string) => {
    this.errorMessage = "";

    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        page: this.page,
        params: {
          fast_search: this.searchValue,
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (
          Object.values(data["filters"]).length &&
          (!Object.keys(this.filters).length || action)
        ) {
          Object.entries(data["cols"]).forEach(([key, col]) => {
            if (col["showAsColumn"]) this.staffTableCols[key] = col;
            if (col["useAsFilter"]) this.staffTableParams[key] = col;
          });

          if (Object.keys(data["selects"]).length) {
            Object.entries(data["selects"]).forEach(([key, value]) => {
              const directory = {};

              switch (key) {
                case "company":
                case "building":
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    };
                  });
                  break;
                case "position":
                  Object.values(value).forEach((company: object) => {
                    Object.values(company).forEach((position) => {
                      directory[position.id ? position.id : position.title] = {
                        newname: position.id ? position.id : position.title,
                        title: `${position.title} (${
                          Object.values(data["selects"]["company"]).filter(
                            (company) => company.id === position.company
                          )[0].title
                        })`
                      };
                    });
                  });
                  break;
                default:
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    };
                  });
                  break;
              }

              this.staffTableParams[key] = {
                ...this.staffTableParams[key],
                directory: directory
              };
            });
          }

          this.staffColsAll = data["cols"];

          this.filters = {};
          Object.entries(data["filters"]).forEach(([key, filter], index) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["cols"] = filter["filterdata"]["cols"]
              ? Object.values(filter["filterdata"]["cols"])
              : [];
            this.filters[key]["id"] = key;

            if (action) {
              switch (action) {
                case "add":
                  if (Object.entries(data["filters"]).length - 2 === index) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "edit":
                  if (id === key) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "delete":
                  if (this.filters[key]["default"]) {
                    this.setSelectedFilter(key);
                  }
                  break;
              }
            } else {
              if (this.filters[key]["default"]) {
                this.setSelectedFilter(key);
              }
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // хардкод для отображения фильтров
  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  // хардкод для отображения фильтров
  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setProjectsListOrder = (value: string) => {
    this.projectsListOrder = value;
  };

  setProjectsListOrdered = (value: string) => {
    this.projectsListOrdered = value;
  };

  resetAll = () => {
    this.projectsList = [];
    this.searchValue = "";
    this.filters = {};
    this.filterParams = {};
    this.selectedFilter = "";
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.projectsListOrder = "";
    this.projectsListOrdered = "ASC";
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
