import { number, object, string, array } from "yup";

export const validationFilterFields = object({
  title: string().trim().lowercase().max(100, "Не более 100 знаков").nullable(),
  ordered: string(),
  order: string(),
  default: number(),
  general: number(),
  cols: array(),
  params: object({})
});
