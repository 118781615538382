import { observer } from "mobx-react-lite";
import styles from "./windowTabs.module.scss";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";

type WindowTabsProps = {
  setOpenedAward: (openedAward: string) => void;
  setWithoutAwards: (withoutAwards: boolean) => void;
};

const WindowTabs = ({ setOpenedAward, setWithoutAwards }: WindowTabsProps) => {
  const { staffOneAwardsStore } = useStores();

  return (
    <>
      <p className={styles.title}>Группа</p>
      <div className={styles.tabs}>
        {getValues(staffOneAwardsStore.awardGroups).map((group) => {
          const handleChangeAwardsField = () => {
            staffOneAwardsStore.setIsActiveButton(group);
            const filteredAwardsObj = getValues(
              staffOneAwardsStore.awards
            ).filter((award) => award.custom.group === group.id);

            if (filteredAwardsObj.length) {
              setWithoutAwards(false);
              setOpenedAward(filteredAwardsObj?.[0]?.id);
            } else {
              setWithoutAwards(true);
            }
          };
          return (
            <button
              key={group.id}
              className={classNames(styles.button, {
                [styles.selectedButton]:
                  group.id === staffOneAwardsStore.isActiveButton.id
              })}
              id={`WindowTabs_${group.id}`}
              onClick={handleChangeAwardsField}
            >
              {group.title}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default observer(WindowTabs);
