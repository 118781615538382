import styles from "./editButtons.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import EditButtonsPanel from "./EditButtonsPanel";
import Modal from "shared/ui/Modal";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import iconEdit from "shared/assets/images/mainIcons/iconEdit.svg";
import iconAddBlack from "shared/assets/images/mainIcons/iconAdd/iconAddBlack.svg";

import { classNames } from "shared/utils/helpers/classNames";

const EditButtons = () => {
  const { aregisterListStore } = useStores();

  const { dirty, handleReset } = useFormikContext();

  const handleClickEditButton = () => {
    if (dirty) {
      aregisterListStore.changeOpenedModal(aregisterListStore.openedEditType);
      aregisterListStore.setPreOpenEditType({
        type: "exit",
        sub_type: ""
      });
    } else {
      aregisterListStore.setOpenedPanel(!aregisterListStore.openedPanel);
      aregisterListStore.setOpenedEditType(
        aregisterListStore.openedPanel ? "" : "edit"
      );
    }
  };

  const getModalTitle = () => {
    const isMassEdit =
      aregisterListStore.openedModal === "mass_edit"
        ? "массового редактирования"
        : "";
    const isMassStatus =
      aregisterListStore.openedModal === "mass_status"
        ? "массового перевода в статус"
        : "";
    const editType = isMassEdit || isMassStatus || "редактирования";

    return `Выйти из режима ${editType}? Все несохраненные изменения будут потеряны.`;
  };

  const handleCancelBtnClick = () => {
    aregisterListStore.changeOpenedModal(aregisterListStore.openedModal);
  };

  const handleExitBtnClick = () => {
    if (aregisterListStore.preOpenEditType.type === "exit") {
      aregisterListStore.setOpenedEditType("");
      aregisterListStore.setOpenedPanel(false);
    } else {
      aregisterListStore.setOpenedEditType(
        aregisterListStore.preOpenEditType.type,
        aregisterListStore.preOpenEditType.sub_type
      );
    }
    aregisterListStore.changeOpenedModal(aregisterListStore.openedModal);
    handleReset();
  };

  return (
    <>
      <div
        className={styles.editButton}
        onClick={handleClickEditButton}
        id="EditButtons_editButton"
      >
        <img
          className={classNames("", {
            [styles.iconClose]: aregisterListStore.openedPanel
          })}
          src={aregisterListStore.openedPanel ? iconAddBlack : iconEdit}
          alt="Редактирование"
        />
        <p>Редактирование</p>
      </div>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EditButtonsPanel />
      </ErrorBoundary>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Modal
          type="clarification"
          show={Boolean(aregisterListStore.openedModal)}
          onHide={handleCancelBtnClick}
          title={getModalTitle()}
          btnWithCrossTitle="Выйти"
          btnWithCrossOnClick={handleExitBtnClick}
          blueBtnOnClick={handleCancelBtnClick}
          blueBtnTitle="Отмена"
          btnWithCrossImg
        />
      </ErrorBoundary>
    </>
  );
};

export default observer(EditButtons);
