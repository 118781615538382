import styles from "./staffOneInfoCommentOne.module.scss";
import { fileUrl } from "stores/utils/consts";
import iconClip from "shared/assets/images/mainIcons/iconClip/iconClip.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Comment } from "stores/Comments/types/Comment";
import { getValues } from "shared/utils/helpers/getValues";

type StaffOneInfoCommentOneProps = {
  commentItem: Comment;
};

const StaffOneInfoCommentOne = ({
  commentItem
}: StaffOneInfoCommentOneProps) => {
  const { author, comment, time_create, files } = commentItem;
  return (
    <>
      <div className={styles.commentDateAndName}>
        <div className={styles.date}>
          {getFormattedDate(time_create).replace(/ /g, ", в ")}
        </div>
        <div className={styles.name}>{author?.title || ""}</div>
      </div>
      {comment ? (
        <div className={styles.commentText}>{comment}</div>
      ) : (
        <div className={styles.commentNoText}></div>
      )}
      {files && (
        <div className={styles.file}>
          {getValues(files).map(({ path, title, id }) => {
            return (
              path !== "-1" && (
                <a
                  href={fileUrl + path}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.uploadedFile}
                  key={id}
                  id="StaffOneInfoCommentsLink"
                >
                  <img src={iconClip} className={styles.iconClip} />
                  {title}
                </a>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default StaffOneInfoCommentOne;
