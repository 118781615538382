import styles from "./buildingOneWorkshiftStafftableSelectShow.module.scss";
import { observer } from "mobx-react-lite";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { useFormikContext } from "formik";

import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { classNames } from "shared/utils/helpers/classNames";
import { useStores } from "stores";

interface IBuildingOneWorkshiftStafftableSelectShowProps
  extends WorkshiftStaffFieldProps {
  setIsMenuOpened: (value: boolean) => void;
  setIsTouched: (value: string) => void;
}

const BuildingOneWorkshiftStafftableSelectShow = ({
  staffIndex,
  name,
  setIsMenuOpened,
  setIsTouched
}: IBuildingOneWorkshiftStafftableSelectShowProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { errors, values, setFieldTouched } = useFormikContext();
  const handleClickToTouched = () => {
    setIsTouched(values[staffIndex].uid);
    setFieldTouched(`${staffIndex}.${name}`);
  };

  return (
    <div
      className={classNames(styles.showBlock, {
        [styles.showBlock_required]:
          name === "payment_type" && !values[staffIndex].isMassEdit,
        [styles.showBlock_error]: errors[staffIndex]?.[name]
      })}
      onClick={handleClickToTouched}
    >
      <div>
        {
          buildingOneWorkshiftStore.selects[name]?.[values[staffIndex][name]]
            ?.title
        }
      </div>
      <IconExpand
        id="BuildingOneWorkshiftStafftableSelectShow_menuOpen"
        onClick={() => setIsMenuOpened(true)}
      />
    </div>
  );
};

export default observer(BuildingOneWorkshiftStafftableSelectShow);
