import styles from "./staffOneStatusesHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef } from "react";
import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/AvatarWithoutFill.svg";
import SearchInput from "shared/ui/Inputs/SearchInput";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import StaffOneStatusHeaderStaffList from "./StaffOneStatusHeaderStaffList";
import { useParams } from "react-router-dom";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { Formik } from "formik";

type StaffOneStatusHeaderProps = {
  isFocusSearch: boolean;
  setIsFocusSearch: (arg: boolean) => void;
  selectedTab: string;
  setSelectedTab: (arg: string) => void;
};

const tabs = [
  { id: "table", title: "Таблица" },
  { id: "schedule", title: "График" }
];

const StaffOneStatusesHeader = ({
  isFocusSearch,
  setIsFocusSearch,
  selectedTab,
  setSelectedTab
}: StaffOneStatusHeaderProps) => {
  const { staffOneStatusesStore, menuStore } = useStores();

  const inputRef = useRef<HTMLInputElement>();
  const ref = useRef<HTMLDivElement>(null);
  const { id } = useParams();

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        event.target["className"]?.length &&
        !event.target["className"].toLowerCase().includes("search")
      ) {
        setIsFocusSearch(false);
      }
    }
  });

  const handleOpenStatusForm = () => {
    staffOneStatusesStore.setSelectedRow("");
    staffOneStatusesStore.setOpenedWindow(
      staffOneStatusesStore.openedWindow !== "add" ? "add" : ""
    );
  };

  const position =
    staffOneStatusesStore.filteredCompany === "all"
      ? staffOneStatusesStore.actualPositions?.[id]?.[
          staffOneStatusesStore.companiesTabs[1]["id"]
        ]
      : staffOneStatusesStore.actualPositions?.[id]?.[
          staffOneStatusesStore.filteredCompany
        ];

  const handleSwitchCompany = (id: string) => {
    staffOneStatusesStore.setFilteredCompany(id);
    staffOneStatusesStore.setSelectedCompany(id);
    staffOneStatusesStore.setOpenedWindow("");
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_withOpenedMenu]: menuStore.isMenuShown,
        [styles.container_withClosedMenu]: !menuStore.isMenuShown
      })}
    >
      <div className={styles.mainInfo}>
        <div
          className={classNames(styles.photo, {
            [styles.photo_avatar]: !staffOneStatusesStore.staffInfo["photo"]
          })}
        >
          <img
            src={
              staffOneStatusesStore.staffInfo["photo"]
                ? `${fileUrl}${
                    staffOneStatusesStore.staffInfo["photo"]
                  }?${Math.floor(Math.random() * 100000)}`
                : Avatar
            }
          />
        </div>

        <div className={styles.mainInfo__textinfo}>
          <div className={styles.TNBlock}>
            <p className={styles.TNBlock__title}>ТН</p>
            <p className={styles.TNBlock__value}>
              {staffOneStatusesStore.staffInfo["uid"]}
            </p>
          </div>
          <div className={styles.fioBlock}>
            <p className={styles.fioBlock__fio}>
              {staffOneStatusesStore.staffInfo["surname"]}{" "}
              {staffOneStatusesStore.staffInfo["name"]}{" "}
              {staffOneStatusesStore.staffInfo["patronymic"] || ""}
            </p>

            {position ? (
              <p className={styles.fioBlock_position}>{position}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        className={styles.search}
        onClick={() => setIsFocusSearch(true)}
        id="StaffOneStatusesHeader_search_staff"
        ref={ref}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Formik
            initialValues={{
              searchStaffOneStatusValue: staffOneStatusesStore.searchValue || ""
            }}
            onSubmit={() => null}
          >
            {({ values, setFieldValue }) => {
              const handleOnChange = (e) => {
                const newValue = e.target.value.toLowerCase();
                setFieldValue("searchStaffOneStatusValue", newValue);
                staffOneStatusesStore.setSearchValue(newValue);
              };

              const handleClearSearch = () => {
                setFieldValue("searchStaffOneStatusValue", "");
                if (staffOneStatusesStore.searchValue) {
                  staffOneStatusesStore.setSearchValue("");
                  staffOneStatusesStore.getStaffList();
                }
              };

              const handleFindData = () => {
                staffOneStatusesStore.setSearchValue(
                  values.searchStaffOneStatusValue
                );
                staffOneStatusesStore.getStaffList();
              };

              return (
                <SearchInput
                  name="searchStaffOneStatusValue"
                  inputRef={inputRef}
                  placeholder="Поиск сотрудника"
                  onChange={handleOnChange}
                  handleFindData={handleFindData}
                  clearSearch={handleClearSearch}
                  fixWidth
                />
              );
            }}
          </Formik>
        </ErrorBoundary>
        {isFocusSearch ? <StaffOneStatusHeaderStaffList /> : ""}
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SwitchedTabs
          tabs={tabs}
          selectedTab={selectedTab}
          changeActiveTab={setSelectedTab}
        />
      </ErrorBoundary>

      <div className={styles.companies}>
        <p>Компании</p>
        <div className={styles.companies__buttons}>
          <div className={styles.companies__buttonsContainer}>
            <ButtonsTabs
              selectedTab={staffOneStatusesStore.filteredCompany}
              tabs={staffOneStatusesStore.companiesTabs}
              changeActiveTab={handleSwitchCompany}
            />
          </div>
          <Button
            theme={ButtonTheme.CLEAR}
            className={styles.addButton}
            onClick={handleOpenStatusForm}
            id="StaffOneStatusesHeader_openStatusForm"
            data-notclickable={true}
          >
            Добавить статус
            <IconAdd />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(StaffOneStatusesHeader);
