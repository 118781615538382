/* eslint-disable @typescript-eslint/no-unused-vars */
// Раскомментировать и привести в порядок, когда (и если) будет реализовываться поиск по столбцам
import styles from "./buildingOneStaffTableSearchRow.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

import SearchInput from "shared/ui/Inputs/SearchInput";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const notSearchable = [
  "uid",
  "event",
  "event_start",
  "event_end",
  "invest",
  "chch_rate_total"
];

const BuildingOneStaffTableSearchRow = () => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  // return buildingOneStaffStore.openedType === "search" ? (
  //   <tr className={styles.searchRow}>
  //     {buildingOneStaffStore.tabs[buildingOneStaffStore.activeTab[id]]?.cols
  //       ? Object.keys(
  //           buildingOneStaffStore.tabs[buildingOneStaffStore.activeTab[id]].cols
  //         ).map((col_name, index) => {
  //           if (notSearchable.includes(col_name)) return <td key={col_name} />;
  //           return (
  //             <Fragment key={col_name}>
  //               {index === 0 ? <td /> : ""}
  //               <td key={col_name} className={styles.searchRow__container}>
  //                 <div
  //                   className={styles.searchRow__field}
  //                   onClick={() => {
  //                     buildingOneStaffStore.searchValue.title !== col_name
  //                       ? buildingOneStaffStore.setSearchValue(col_name, "")
  //                       : "";
  //                   }}
  //                   id={`BuildingOneStaffTableSearchRow_searchField_${col_name}`}
  //                 >
  //                   <ErrorBoundary FallbackComponent={ErrorFallback}>
  //                     <SearchInput
  //                       placeholder=" "
  //                       value={
  //                         buildingOneStaffStore.searchValue.title === col_name
  //                           ? buildingOneStaffStore.searchValue.value
  //                           : ""
  //                       }
  //                       onChange={(e) => {
  //                         buildingOneStaffStore.setSearchValue(
  //                           col_name,
  //                           e.target.value.toLowerCase()
  //                         );
  //                       }}
  //                       clearSearch={() =>
  //                         buildingOneStaffStore.setSearchValue(col_name, "")
  //                       }
  //                       handleFindData={() => {
  //                         return;
  //                       }}
  //                     />
  //                   </ErrorBoundary>
  //                 </div>
  //               </td>
  //             </Fragment>
  //           );
  //         })
  //       : null}
  //   </tr>
  // ) : null

  return null;
};

export default observer(BuildingOneStaffTableSearchRow);
