import styles from "./buildingOneWorkshiftHeaderWorkshiftMessage.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useState } from "react";

import StatusIcon from "shared/ui/StatusIcon";
import BuildingOneWorkshiftHeaderWorkshiftMessageWindow from "./BuildingOneWorkshiftHeaderWorkshiftMessageWindow";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const BuildingOneWorkshiftHeaderWorkshiftMessage = () => {
  const { buildingOneWorkshiftStore } = useStores();
  const { id } = useParams<{ id: string }>();
  const [isOpenedPanel, setIsOpenedPanel] = useState(false);

  const isAccessToClose =
    buildingOneWorkshiftStore.closeWorkshiftMsg[id]?.result &&
    buildingOneWorkshiftStore.closeWorkshiftStatusMsg[id]?.result === undefined;

  const handleClickOnCloseBtn = () => setIsOpenedPanel(!isOpenedPanel);

  return (
    <>
      {buildingOneWorkshiftStore.openedMode[id] !== "edit" &&
      !buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom.done ? (
        <div className="position-relative">
          <div
            className={styles.errorBtn}
            onClick={handleClickOnCloseBtn}
            id="BuildingOneWorkshiftHeaderWorkshiftMessage_openedPanelBtn"
          >
            <StatusIcon
              icon={isAccessToClose ? "circlecheck" : "attention"}
              color={isAccessToClose ? "accent-green" : "accent-coral"}
            />
          </div>
          {isOpenedPanel ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BuildingOneWorkshiftHeaderWorkshiftMessageWindow
                setIsOpenedPanel={setIsOpenedPanel}
              />
            </ErrorBoundary>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default observer(BuildingOneWorkshiftHeaderWorkshiftMessage);
