import styles from "./staffOneInfoFinanceTableDetailed.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import FormWindow from "shared/ui/FormWindow";
import StaffOneInfoFinanceTable from "../StaffOneInfoFinanceTable";
import { useParams } from "react-router-dom";
import { getKeys } from "shared/utils/helpers/getKeys";
import { Button, ButtonTheme } from "shared/ui/Button";

type StaffOneInfoFinanceTableDetailedProps = {
  setToggleTable: (arg: boolean) => void;
};

const StaffOneInfoFinanceTableDetailed = ({
  setToggleTable
}: StaffOneInfoFinanceTableDetailedProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  const [year, setYear] = useState("");

  useEffect(() => {
    if (staffOneStore.salary?.[id]?.table?.sum?.months) {
      setYear(Object.keys(staffOneStore.salary[id].table.sum.months)[0]);
    }
  }, []);

  const limitations: string[] = [];

  staffOneStore.salary?.[id]?.table?.records &&
    Object.values(staffOneStore.salary[id].table.records)
      .filter((item) => item.year === +year)
      .forEach((value) => {
        Object.keys(value["types"]).forEach((type) => {
          if (!limitations.includes(type)) {
            limitations.push(type);
          }
        });
      });

  const dictionaryTypeList = staffOneStore.salary?.[id]?.table?.type_list
    ? Object.keys(staffOneStore.salary[id].table.type_list)
        .map((el) => {
          return {
            id: el,
            title: staffOneStore.salary[id].table.type_list[el].title
          };
        })
        .filter((el) => {
          if (limitations.includes(el.id)) return el;
        })
    : [];

  const monthList = staffOneStore.salary?.[id]?.table?.records
    ? Object.keys(staffOneStore.salary[id].table.records).filter(
        (key) => key.slice(0, 4) === year
      )
    : [];

  return (
    <div className={styles.windowContainer}>
      <div className={styles.window}>
        <FormWindow
          title="Финансы"
          setOpenWindow={setToggleTable}
          withoutSendData
          isNotHaveButtons
          isScroll
        >
          <>
            <div className={styles.yearButtonsWrapper}>
              {staffOneStore.salary?.[id]?.table?.sum?.months
                ? getKeys(
                    // Временное решение типизации, исправить при разработке таблицы финансов
                    staffOneStore.salary[id].table.sum.months as Record<
                      string,
                      number | Record<string, number>
                    >
                  ).map((item) => {
                    const handleSetYear = () => setYear(item);
                    return (
                      <Button
                        key={item}
                        theme={ButtonTheme.TAB}
                        onClick={handleSetYear}
                        selected={year === item}
                        id={`StaffOneInfoFinanceTableDetailed_year_${item}`}
                      >
                        item
                      </Button>
                    );
                  })
                : null}
            </div>
            <div className={styles.tableWrapper}>
              <StaffOneInfoFinanceTable
                monthList={monthList}
                dictionaryTypeList={dictionaryTypeList}
              />
            </div>
          </>
        </FormWindow>
      </div>
    </div>
  );
};
export default observer(StaffOneInfoFinanceTableDetailed);
