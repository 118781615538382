import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { Building } from "stores/StaffModule/types/Building";
import { Col } from "stores/utils/types/Col";
import { Param } from "stores/utils/types/Param";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import data from "./data.json";
import dataForTable from "./dataForStaffTable.json";

type SalaryPremium = {
  record: {
    id: string;
    time_create: string;
    calculation_period: string;
    last_update: string;
    object: string;
    status: string;
  };
  table: Partial<{
    [key: string]: {
      [key: string]: string | number;
    }[];
  }>;
};

export default class SalaryPremiumOneStore {
  error = false;
  isLoading = false;

  cols: { [key: string]: Col } = {};
  colsTable: { [key: string]: Col } = {};
  currentColsForTable: { [key: string]: { [key: string]: string } } = {};
  params: { [key: string]: Param } = {};
  fieldsForAdd: string[] = [];
  requiredFields: string[] = [];
  openedAllSalaryPremiums: {
    [id: string]: Partial<SalaryPremium>;
  } = {};
  selectedOneSalaryPremium: Partial<SalaryPremium> = {};
  salaryPremiumTypes: { [key: string]: { [key: string]: Col } } = {};
  premiumInitialValues: { [key: string]: string } = {};

  rootStore: RootStore;

  setSelectedOneOfSalaryPremium = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/salary", "Финансы");
      this.rootStore.menuStore.updateWindow({
        mainPath: "/salary",
        path: "/salary/premium",
        title: "Финансы"
      });
    }

    if (!this.rootStore.menuStore.allWindows[`/salary/premium/id=${id}`]) {
      this.rootStore.menuStore.addWindow(`/salary/premium/id=${id}`, `Премия`);
      this.rootStore.menuStore.addParentPath(
        `/salary/premium`,
        `/salary/premium/id=${id}`
      );
    } else {
      this.rootStore.menuStore.updateWindow({
        mainPath: `/salary/premium/id=${id}`,
        path: `/salary/premium/id=${id}`
      });
    }

    if (
      Object.keys(this.openedAllSalaryPremiums).length &&
      this.openedAllSalaryPremiums[id]
    ) {
      if (Object.values(this.openedAllSalaryPremiums[id]).length) {
        this.selectedOneSalaryPremium = this.openedAllSalaryPremiums[id];
      } else this.error = true;
      this.isLoading = false;
    } else {
      this.openedAllSalaryPremiums[id] = {};
      this.openedAllSalaryPremiums[id]["record"] = data["result"][id];

      this.openedAllSalaryPremiums[id]["table"] = {};

      Object.entries(dataForTable["result"]).forEach(([key, value]) => {
        this.openedAllSalaryPremiums[id]["table"][key] = Object.values(value);
      });
      this.selectedOneSalaryPremium = this.openedAllSalaryPremiums[id];

      Promise.all([
        !Object.values(this.cols).length && this.getFieldCols(),
        !Object.values(this.params).length && this.getFieldParams(),
        !Object.values(this.salaryPremiumTypes).length &&
          this.getSalaryOperationsTypes(),
        !Object.values(this.colsTable).length && this.getFieldForTableCols()
      ]).then(() => {
        setTimeout(() => {
          runInAction(() => (this.isLoading = false));
        }, 2000);
      });
    }
  };

  setInitialValues = (values: { [key: string]: string }) => {
    this.premiumInitialValues = values;
  };

  getDataForAdd = async () => {
    this.isLoading = true;

    try {
      Promise.all([
        !Object.values(this.cols).length && this.getFieldCols(),
        !Object.values(this.params).length && this.getFieldParams()
      ]).then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  getFieldCols = async () => {
    this.cols = data["cols"];
  };

  getFieldForTableCols = async () => {
    this.colsTable = dataForTable["cols"];
    this.currentColsForTable = dataForTable["show_fields"];
  };

  getFieldParams = async () => {
    this.params = data["params"];
    runInAction(() => {
      Object.entries(data["params"]).forEach(([key, value]) => {
        if (value["add_show"] === "on") {
          this.fieldsForAdd.push(key);
        }
        if (value["required"] === "on") {
          this.requiredFields.push(key);
        }
      });
    });

    this.getBuildingsList();
  };

  getSalaryOperationsTypes = () => {
    runInAction(() => {
      this.salaryPremiumTypes = data["selects"];
    });
  };

  getBuildingsList = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Building } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building",
          method: "getList",
          params: {
            on_page: 300
          }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.params.object.variable = {};
          Object.values(data["result"]).forEach((option) => {
            this.params.object.variable[option.id] = option as {
              title: string;
            };
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
