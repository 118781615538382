import styles from "./staffOneStatusHeaderStaffList.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStores } from "stores/index";
import LoadedComponent from "widgets/LoadedComponent";
import { useEffect, useRef, useState } from "react";
import { positionValues, Scrollbars } from "react-custom-scrollbars-2";
import { IScrollBars } from "stores/utils/types/ScrollBars";

const StaffOneStatusHeaderStaffList = () => {
  const { staffOneStatusesStore } = useStores();
  const scrollbar = useRef<IScrollBars>();
  const navigate = useNavigate();

  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const handleScroll = (e: positionValues) => {
    if (e.top > 0.87) {
      setIsScrollBottom(!isScrollBottom);
    } else {
      setIsScrollBottom(false);
    }
  };

  useEffect(() => {
    if (
      isScrollBottom &&
      staffOneStatusesStore.maxPage >= staffOneStatusesStore.page &&
      staffOneStatusesStore.page === staffOneStatusesStore.prevPage
    ) {
      staffOneStatusesStore.setPage(staffOneStatusesStore.page + 1);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    if (
      staffOneStatusesStore.page !== 1 &&
      staffOneStatusesStore.page <= staffOneStatusesStore.maxPage &&
      staffOneStatusesStore.page !== staffOneStatusesStore.prevPage
    ) {
      staffOneStatusesStore.getMoreStaff();
    }
  }, [staffOneStatusesStore.page, staffOneStatusesStore.maxPage]);

  return (
    <div className={styles.main}>
      <div className={styles.loading}>
        <>
          {staffOneStatusesStore.staffList.length ? (
            <Scrollbars
              ref={scrollbar}
              style={{ width: "100%" }}
              autoHeight
              autoHeightMax={"400px"}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              onScrollFrame={(e) => handleScroll(e)}
            >
              <div className={styles.list}>
                {staffOneStatusesStore.staffList.map((staff) => {
                  const handleNavigate = () =>
                    navigate(`../staff/id=${staff.id}/statuses`);
                  return (
                    <div
                      key={staff.id}
                      className={styles.item}
                      onClick={handleNavigate}
                      id={`StaffOneStatusHeaderStaffList_navigate_to_id${staff.id}`}
                    >
                      <div>{staff.uid}</div>
                      <div>{staff.name}</div>
                    </div>
                  );
                })}
                {staffOneStatusesStore.page !== 1 ? (
                  <>
                    {staffOneStatusesStore.isLoadingForStaffList ? (
                      <p className={styles.loadingText}>
                        Пожалуйста, подождите
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <LoadedComponent
                    isLoading={staffOneStatusesStore.isLoadingForStaffList}
                    withoutText
                  />
                )}
              </div>
            </Scrollbars>
          ) : (
            <p className={styles.loadingText}>Ничего не найдено</p>
          )}
        </>
      </div>
    </div>
  );
};

export default observer(StaffOneStatusHeaderStaffList);
