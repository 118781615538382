import { stringNoPuncButLimit } from "shared/utils/validation/validation";
import { array, object, string } from "yup";

export const validationTypeAddress = object({
  types: array().of(
    object({
      title: stringNoPuncButLimit.required("Обязательно к заполнению"),
      ordered: string().matches(/^[0-9]+$/gi, "Только число"),
      comment: stringNoPuncButLimit
    })
  )
});
