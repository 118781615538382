import styles from "./switch.module.scss";
import { useFormikContext, Field } from "formik";

type SwitchProps = {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const Switch = ({ name, onChange, disabled }: SwitchProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={styles.switch}>
      <Field
        type="checkbox"
        className={styles.input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const val = e.target.checked ? 1 : 0;
          setFieldValue(name, val);
          onChange && onChange(e);
        }}
        name={name}
        disabled={disabled}
      />
      <span className={styles.tumbler}></span>
    </div>
  );
};

export default Switch;
