import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import BuildingListTable from "./BuildingListTable";
import TableWithStickyFilter from "features/TableWithStickyFilter";

import { browserName } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import BuildingListFilterButtons from "./BuildingListFilterButtons";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const BuildingList = () => {
  const { menuStore, filterStore, buildingListStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams:
    | {
        [key: string]: string | number;
      }
    | { [key: string]: string[] } = {};

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      buildingListStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            buildingListStore.setSearchValue(value);
            break;
          case "order":
            buildingListStore.setBuildingListOrder(value);
            break;
          case "ordered":
            buildingListStore.setBuildingListOrdered(value);
            break;
          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      buildingListStore.setFilterParamsFromQuery(queryParams);
    }

    if (!buildingListStore.buildingList.length) {
      buildingListStore.getBuildingsList();
      buildingListStore.getSelects();
    }
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("building");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/building", "Объекты");
  }, []);

  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    Object.entries(buildingListStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );
    if (buildingListStore.buildingListOrder) {
      filterParams["order"] = buildingListStore.buildingListOrder;
    }
    if (buildingListStore.buildingListOrdered) {
      filterParams["ordered"] = buildingListStore.buildingListOrdered;
    }
    if (buildingListStore.searchValue) {
      filterParams["fast_search"] = buildingListStore.searchValue;
    }

    buildingListStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [
    buildingListStore.filterParams,
    buildingListStore.searchValue,
    buildingListStore.buildingListOrder,
    buildingListStore.buildingListOrdered
  ]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (Object.keys(buildingListStore.queryParams).length) {
      setSearchParams(buildingListStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      buildingListStore.page !== 1 &&
      buildingListStore.page <= buildingListStore.maxPage &&
      buildingListStore.page !== buildingListStore.prevPage
    ) {
      buildingListStore.getMoreBuildingsList();
    }
  }, [buildingListStore.page, buildingListStore.maxPage]);

  const filterBuildings = (order: string) => {
    buildingListStore.getNewFilterParams();
    buildingListStore.setBuildingListOrder(order);

    if (order === buildingListStore.buildingListOrder) {
      buildingListStore.setBuildingListOrdered(
        buildingListStore.buildingListOrdered === "DESC" ? "ASC" : "DESC"
      );
    } else {
      buildingListStore.setBuildingListOrdered("DESC");
    }
    buildingListStore.setPage(1);
    buildingListStore.getBuildingsList();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={buildingListStore.isLoading}
        isLoadingForFilters={buildingListStore.isLoadingForFilters}
        filters={buildingListStore.filters}
        searchValue={buildingListStore.searchValue}
        setSearchValue={buildingListStore.setSearchValue}
        getData={buildingListStore.getBuildingsList}
        selectedFilter={buildingListStore.selectedFilter}
        setSelectedFilter={buildingListStore.setSelectedFilter}
        setFilters={buildingListStore.setFilters}
        cols={buildingListStore.buildingTableCols}
        params={buildingListStore.buildingTableParams}
        allCols={buildingListStore.buildingColsAll}
        currentCols={buildingListStore.currentTitles}
        getDataWithFilter={buildingListStore.getBuildingListWithFilter}
        setFiltersChanged={buildingListStore.setFiltersChanged}
        downloadedFilter={buildingListStore.downloadedFilter}
        setDownloadedFilter={buildingListStore.setDownloadedFilter}
        selectMulti={["company"]}
        isSearchable
        withoutAdvancedSearch
        withoutSavedFilter
        withoutColsSetting
        customConditionDisplayForInfoBlock={
          !buildingListStore.searchValue.length
        }
        moreButton={
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BuildingListFilterButtons />
          </ErrorBoundary>
        }
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(buildingListStore.searchValue),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="В этом поле можно осуществить поиск по объектам"
        fixedWidthSearchInput
      >
        <BuildingListTable filterItems={filterBuildings} />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(BuildingList);
