import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import styles from "./staffVacTableFutureStatus.module.scss";
import StatusIcon from "shared/ui/StatusIcon";

type StaffVacTableFutureStatusProps = {
  data: {
    OE: { [key: string]: string };
    OM: { [key: string]: string };
    author: string;
    color: string;
    icon: string;
    status: string;
  };
  cols: { [key: string]: { newname: string; title: string } | string };
};

const StaffVacTableFutureStatus = ({
  data,
  cols
}: StaffVacTableFutureStatusProps) => {
  return Object.keys(data).length ? (
    <div className={styles.container}>
      <div className={styles.statusTitle}>
        {data["icon"] ? (
          <StatusIcon icon={data["icon"]} color={data["color"]} />
        ) : (
          ""
        )}
        <p>{cols[data["status"]]["title"]}</p>
      </div>

      <p>{getFormattedDate(data["date"])}</p>
      <p>{data["author"]}</p>
    </div>
  ) : (
    <></>
  );
};

export default StaffVacTableFutureStatus;
