import { useParams } from "react-router-dom";
import styles from "./recordsInfo.module.scss";
import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import {
  LastOperationType,
  PremiumType
} from "stores/StaffModule/staffOne/staffOneSalary";

type StaffOneSalaryFieldsRecordsInfoProps = {
  name: "lastOperationList" | "paymentList" | "premium";
  paymentList?: number;
  premiumList?: PremiumType[];
  lastOperationsList?: LastOperationType[];
};

const StaffOneSalaryFieldsRecordsInfo = ({
  name,
  paymentList,
  premiumList,
  lastOperationsList
}: StaffOneSalaryFieldsRecordsInfoProps) => {
  const { id } = useParams();

  const handleOpenStaffPaymentlist = () =>
    setTimeout(() => {
      window.open(
        `${fileUrl}/crm/salary/?action=paymentlist&filter%5Bstaff%5D%5B%5D=${id}`,
        "_blank"
      );
    }, 100);

  const handleOpenStaffSalary = () =>
    setTimeout(() => {
      window.open(
        `${fileUrl}/crm/salary/?action=salary&filter%5Bstaff%5D%5B%5D=${id}`,
        "_blank"
      );
    }, 100);

  const handleOpenStaffPremium = () =>
    setTimeout(() => {
      window.open(
        `${fileUrl}/crm/salary/?action=premium&filter%5Bstaff%5D%5B%5D=${id}`,
        "_blank"
      );
    }, 100);

  const handlers = {
    salary: handleOpenStaffSalary,
    paymentList: handleOpenStaffPaymentlist,
    premium: handleOpenStaffPremium
  };

  return (
    <div className={styles.container}>
      {(name === "paymentList"
        ? paymentList
        : name === "lastOperationList"
        ? lastOperationsList.length
        : premiumList.length) >= 10 ? (
        <>
          <div className={styles.lastNotes}>Последние 10 записей</div>
          {/* Временно отображаем кнопку только для блока Ведомостей,
          пока в старом интерфейсе не будет реализована фильтрация Операций и Премий по
          сотруднику */}
          {name === "paymentList" ? (
            <div
              className={classNames(styles.link)}
              id="RecordsInfo_allRecords"
              onClick={handlers[name]}
            >
              Смотреть все
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default StaffOneSalaryFieldsRecordsInfo;
