import styles from "./cardsList.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useState } from "react";
import { useParams } from "react-router-dom";

import CardOne from "./CardOne";
import ReportsWindow from "./ReportsWindow";

import { ReactComponent as IconInstruction } from "shared/assets/images/iconStatus/Instruction.svg";

import { isEmpty } from "lodash";
import { classNames } from "shared/utils/helpers/classNames";
import { getAregisterStatus } from "shared/utils/helpers/getAregisterStatus";
import { getValues } from "shared/utils/helpers/getValues";
import ContributionErrorPanel from "./ContributionErrorPanel";

const CARDS_LIST = [
  "act_hours",
  "Объём кубов по заявке",
  "Сумма часов",
  "conditional_cubes"
];
const ITR_CARDS_LIST = ["master", "brigadier"];

const CardsList = () => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();
  const [openedWindow, setOpenedWindow] = useState(false);

  const reportsWindowClose = () => {
    setOpenedWindow(false);
  };

  const handleGetNumber = (card_name: string) => {
    let hours = 0;
    switch (card_name) {
      case "Объём кубов по заявке":
        return (
          typeof aregisterOneStore.selectedOne.size === "number" &&
          aregisterOneStore.selectedOne.size
        );
      case "Сумма часов":
        getValues(aregisterOneStore.presavesTotal[id]).forEach(
          (total) => (hours += total.work_hours_sum)
        );
        return hours;
      case "conditional_cubes":
        return aregisterOneStore.cubes[id]?.conditional_cubes || null;
      default:
        return aregisterOneStore.selectedOne[card_name] || 0;
    }
  };

  const handleShowWindowBtnClick = () => {
    new Promise(() => {
      aregisterOneStore
        .getPresaveForAregister(aregisterOneStore.selectedOne.id)
        .then(() => {
          setOpenedWindow(!openedWindow);
        });
    });
  };

  const status = getAregisterStatus(
    aregisterOneStore.selects.status?.[aregisterOneStore.selectedOne.status?.id]
      ?.custom
  );

  return !isEmpty(aregisterOneStore.selectedOne) ? (
    <div className={styles.wrapper}>
      <div className={styles.cardContainer}>
        {/* Данные из почасовок (часы, объем и суммы) */}
        <div className={styles.cards}>
          {CARDS_LIST.map((card) => (
            <CardOne
              key={card}
              title={aregisterOneStore.cols[card]?.title || card}
              number={handleGetNumber(card)}
            />
          ))}
        </div>
        {/* Мастер и бригадир */}
        <div
          className={classNames("", {
            [styles.itr]: status === "open",
            [styles.itrColumn]: status !== "open"
          })}
        >
          {ITR_CARDS_LIST.map((card) => (
            <div key={card} className="position-relative">
              <CardOne
                height={status === "open" ? "big" : "small"}
                title={aregisterOneStore.cols[card]?.title}
                subtitle={
                  aregisterOneStore.selectedOne[card]
                    ? `ТН${aregisterOneStore.selectedOne[card].uid} ${
                        aregisterOneStore.selectedOne[card].surname
                      } ${aregisterOneStore.selectedOne[card].name}${
                        aregisterOneStore.selectedOne[card].patronymic
                          ? " " + aregisterOneStore.selectedOne[card].patronymic
                          : ""
                      }`
                    : "-"
                }
                link_id={aregisterOneStore.selectedOne[card]?.id}
              />
              {card === "master" && status === "open" ? (
                <button
                  className={classNames(styles.button, {
                    [styles.buttonActive]: openedWindow
                  })}
                  onClick={handleShowWindowBtnClick}
                  id="CardList_showWindow"
                >
                  <IconInstruction /> Доступные отчеты
                </button>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      {/*  Окно добавления почасовок в заявку */}
      {openedWindow ? <ReportsWindow onClose={reportsWindowClose} /> : null}

      <ContributionErrorPanel />
    </div>
  ) : null;
};

export default observer(CardsList);
