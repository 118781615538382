import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import FriendInviteAllTable from "./FrienInviteAllTable/index";
import { Formik } from "formik";
import SearchInput from "shared/ui/Inputs/SearchInput/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const FriendInviteAll = () => {
  const { menuStore, friendInviteStore, modalWindowsStore } = useStores();
  const [isScaleChanged, setIsScaleChanged] = useState(false);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!friendInviteStore.invitesList.length) {
      friendInviteStore.getAllInviteFriendData();
    }
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("friendInvite");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/friendinvite", "Приведи друга");
  }, []);

  useEffect(() => {
    setIsScaleChanged(true);
    setTimeout(() => {
      setIsScaleChanged(false);
    }, 500);
    menuStore.setMarginLeft(0);
  }, [window.innerWidth]);

  useEffect(() => {
    if (
      friendInviteStore.page !== 1 &&
      friendInviteStore.page <= friendInviteStore.maxPage &&
      friendInviteStore.page !== friendInviteStore.prevPage
    ) {
      friendInviteStore.getInvitesPage();
    }
  }, [friendInviteStore.page, friendInviteStore.maxPage]);

  return (
    <>
      <div
        style={{
          marginLeft: menuStore.scroll.scrollLeft
            ? menuStore.scroll.scrollLeft
            : 0,
          transition:
            !friendInviteStore.isLoading && !isScaleChanged
              ? "margin 0.8s ease"
              : ""
        }}
        className="w-100"
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Formik
            initialValues={{
              searchFriendInviteValue: friendInviteStore.searchValue || ""
            }}
            onSubmit={() => null}
          >
            {({ setFieldValue, values }) => {
              const handleFindData = () => {
                friendInviteStore.setSearchValue(
                  values.searchFriendInviteValue
                );
                friendInviteStore.getAllInviteFriendData();
                inputRef.current.blur();
              };

              const handleOnChange = (e) => {
                const newValue = e.target.value.toLowerCase();
                setFieldValue("searchFriendInviteValue", newValue);
                friendInviteStore.setSearchValue(newValue);
              };

              const handleClearSearch = () => {
                setFieldValue("searchFriendInviteValue", "");
                if (friendInviteStore.searchValue) {
                  friendInviteStore.setSearchValue("");
                  friendInviteStore.getAllInviteFriendData();
                }
              };

              return (
                <SearchInput
                  name="searchFriendInviteValue"
                  inputRef={inputRef}
                  onChange={handleOnChange}
                  handleFindData={handleFindData}
                  blurCondition={
                    friendInviteStore.isLoading ||
                    modalWindowsStore.isErrorWindow
                  }
                  clearSearch={handleClearSearch}
                  fixWidth
                />
              );
            }}
          </Formik>
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FriendInviteAllTable />
      </ErrorBoundary>
    </>
  );
};

export default observer(FriendInviteAll);
