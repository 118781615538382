import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Widgets } from "../types/Widgets";
import { Col } from "../types/Col";
import { Company } from "../types/Company";
import { History } from "../types/History";
import { Workshift } from "../types/Workshift";
import { WidgetMetrics } from "../types/WidgetMetrics";
import { OneOfWorkshift } from "../types/Workshift";
import { Selects } from "../types/Selects";

export default class StaffOneAllSectionsStore {
  error: { [staff_id: string]: boolean } = {};
  isLoading: { [staff_id: string]: boolean } = {};

  history: History = {};
  workshift: {
    [company_id: string]: {
      [workshiftGroup_id: string]: {
        [workshift_id: string]: Workshift["company_id"]["workshift_id"];
      };
    };
  } = {};
  workshiftTotal: Partial<Workshift["total"]> = {};
  companiesData: Selects["company"] = {};
  tabs: Array<Company> = [];
  convictionCols: { [key: string]: Col } = {};
  convictionShowFields: Widgets["show_fields"]["conviction"] = {};

  allWidgetMetrics: {
    [key: string]: WidgetMetrics["building_metrics"]["key"];
  } = {};
  allWorkshift: {
    [key: string]: {
      [company_id: string]: {
        [workshiftGroup_id: string]: {
          [workshift_id: string]: Workshift["company_id"]["workshift_id"];
        };
      };
    };
  } = {};

  selectedOneForAllSections: Partial<Widgets> = {};
  openedAllStaffForAllSections: { [key: string]: Partial<Widgets> } = {};
  rootStore: RootStore;

  updateSections = (obj?: Partial<Widgets>, id?: string) => {
    if (obj) {
      this.history = obj["widgets"]["history"];
      this.workshift = this.allWorkshift[id];
      this.workshiftTotal = obj.widgets.workshift.total;
      this.companiesData = this.getCompaniesData(obj["selects"]["company"]);
      this.tabs = Object.values(obj["staff"]["company"]);
    } else {
      this.history = {};
      this.workshift = {};
      this.workshiftTotal = {};
      this.companiesData = {};
      this.tabs = [];
    }
  };

  setSelectedOneForAllSections = (id: string) => {
    this.isLoading[id] = true;
    this.error[id] = false;

    this.updateSections();
    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForAllSections[id];
    }

    if (
      Object.keys(this.openedAllStaffForAllSections).length &&
      this.openedAllStaffForAllSections[id]
    ) {
      if (Object.values(this.openedAllStaffForAllSections[id]).length) {
        this.selectedOneForAllSections = this.openedAllStaffForAllSections[id];
        this.updateSections(this.selectedOneForAllSections, id);
      } else {
        this.error[id] = true;
      }
    } else {
      this.getSectionsForOneOfStaff(id);
    }
    this.isLoading[id] = false;
  };

  getSectionsForOneOfStaff = async (id: string) => {
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields: "surname, name, patronymic, id, company",
          widgets: ["comments", "history", "workshift"],
          selects: "company",
          cols: "comments"
        }
      });

      if (!data["errors"]) {
        this.openedAllStaffForAllSections[id] = data;

        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
        });
        if (this.rootStore.menuStore.tabId === id) {
          Promise.all([
            this.getDataForWorkshiftTable(data.widgets.workshift, id),
            this.getWidgetMetrics(data.widgets.workshift.total, id)
          ]).then(() => {
            runInAction(() => {
              this.selectedOneForAllSections =
                this.openedAllStaffForAllSections[id];
            });

            this.updateSections(this.selectedOneForAllSections, id);

            runInAction(() => (this.isLoading[id] = false));
          });
        }
      } else {
        runInAction(() => (this.openedAllStaffForAllSections[id] = {}));
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });

        runInAction(() => {
          this.error[id] = true;
          this.isLoading[id] = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    }
  };

  getDataForWorkshiftTable = (
    workshift: Widgets["widgets"]["workshift"],
    staff_id: string
  ) => {
    const workshiftTableData: {
      [company_id: string]: {
        [workshiftGroup_id: string]: {
          [workshift_id: string]: OneOfWorkshift;
        };
      };
    } = {};

    Object.entries(workshift).forEach(([company_id, companyWorkshifts]) => {
      if (
        company_id === "total" ||
        company_id === "googlesheetsTotal" ||
        company_id === "all_workshift"
      )
        return;

      workshiftTableData[company_id] = {};

      let position = "";
      let workshiftsGroupId = "";

      Object.entries(
        companyWorkshifts as Widgets["widgets"]["workshift"]["company_id"]
      ).forEach(([key, oneOfCompanyWorkshift]) => {
        if (oneOfCompanyWorkshift.position_id !== position) {
          position = oneOfCompanyWorkshift.position_id;
          workshiftsGroupId = oneOfCompanyWorkshift.id;
          workshiftTableData[company_id][oneOfCompanyWorkshift.id] = {
            ...workshiftTableData[company_id][oneOfCompanyWorkshift.id],
            [oneOfCompanyWorkshift.id]: oneOfCompanyWorkshift
          };
          workshiftTableData["all"] = {
            ...workshiftTableData["all"],
            [oneOfCompanyWorkshift.id]: {
              [oneOfCompanyWorkshift.id]: oneOfCompanyWorkshift
            }
          };
        } else {
          workshiftTableData[company_id][workshiftsGroupId] = {
            ...workshiftTableData[company_id][workshiftsGroupId],
            [oneOfCompanyWorkshift.id]: oneOfCompanyWorkshift
          };
          workshiftTableData["all"][workshiftsGroupId] = {
            ...workshiftTableData["all"][workshiftsGroupId],
            [oneOfCompanyWorkshift.id]: oneOfCompanyWorkshift
          };
        }
        this.openedAllStaffForAllSections[staff_id].widgets.workshift[
          company_id
        ][oneOfCompanyWorkshift.id] = oneOfCompanyWorkshift;
        delete this.openedAllStaffForAllSections[staff_id].widgets.workshift[
          company_id
        ][key];
      });
    });
    if (workshiftTableData["all"]) {
      workshiftTableData["all"] = Object.fromEntries(
        Object.entries(workshiftTableData["all"]).sort((a, b) => {
          if (
            new Date(Object.values(b[1])[0]["start_date"]).valueOf() >
            new Date(Object.values(a[1])[0]["start_date"]).valueOf()
          ) {
            return 1;
          }
          if (
            new Date(Object.values(b[1])[0]["start_date"]).valueOf() <
            new Date(Object.values(a[1])[0]["start_date"]).valueOf()
          ) {
            return -1;
          }
          return 0;
        })
      );
    }

    this.allWorkshift[staff_id] = workshiftTableData;
  };

  getCompaniesData = (companies: Selects["company"]) => {
    const newCompanies: Selects["company"] = {};
    Object.values(companies).forEach((company) => {
      newCompanies[company.id] = company;
    });

    return newCompanies;
  };

  getWidgetMetrics = async (
    workshiftTotal: Workshift["total"],
    staff_id: string
  ) => {
    try {
      const data: ApiResponse<WidgetMetrics | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getWidgetMetrics",
          params: {
            uid: staff_id
          }
        });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result.building_metrics).forEach(
            (oneOfMetrics) => {
              if (
                oneOfMetrics.building_title ===
                  workshiftTotal.current_tour.building &&
                oneOfMetrics.company_title ===
                  workshiftTotal.current_tour.company
              ) {
                this.allWidgetMetrics[staff_id] = oneOfMetrics;
              }
            }
          );
        } else {
          this.allWidgetMetrics[staff_id] = null;
          this.error[staff_id] = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[staff_id] = true;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
