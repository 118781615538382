import styles from "./staffOneInfoFinanceTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { months } from "shared/utils/dictionaries/months";

type StaffOneInfoFinanceTableProps = {
  monthList: string[];
  dictionaryTypeList: {
    id: string;
    title: string;
  }[];
};

const StaffOneInfoFinanceTable = ({
  monthList,
  dictionaryTypeList
}: StaffOneInfoFinanceTableProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  return staffOneStore.salary?.[id]?.table?.records ? (
    <Table className={styles.table}>
      <thead>
        <tr>
          <td className={styles.firstCol}>Дата</td>
          {monthList.map((key) => {
            return (
              <td key={key} className={styles.monthsCol}>
                {months[staffOneStore.salary[id].table.records[key].month]}{" "}
                <br /> {staffOneStore.salary[id].table.records[key].year}
              </td>
            );
          })}
          <td className={styles.lastCol}>Итого:</td>
        </tr>
      </thead>

      <tbody className={styles.tableBody}>
        <tr>
          {/* {хардкод, заменить, когда будет апи} */}
          <td className={styles.firstCol}>Часы</td>
          {monthList.map((key) => {
            return (
              <td key={key}>
                {staffOneStore.salary[id].table.records[key].hours}
              </td>
            );
          })}
          <td className={styles.lastCol}>
            {monthList.reduce((sum, item) => {
              return sum + staffOneStore.salary[id].table.records[item].hours;
            }, 0)}
          </td>
        </tr>
        {dictionaryTypeList.map((el) => {
          return (
            <tr key={el.id}>
              <td className={styles.firstCol}>{el.title}</td>
              {monthList.map((key) => {
                return (
                  <td key={key} className={styles.moneyCell}>
                    {staffOneStore.salary[id].table.records[key].types[el.id]
                      ? numberWithSpaces(
                          staffOneStore.salary[id].table.records[key].types[
                            el.id
                          ]
                        )
                      : ""}
                  </td>
                );
              })}
              <td className={`${styles.lastCol} ${styles.moneyCell}`}>
                {monthList.reduce((sum, item) => {
                  return (
                    sum +
                    staffOneStore.salary[id].table.records[item].types[el.id]
                  );
                }, 0)
                  ? numberWithSpaces(
                      monthList.reduce((sum, item) => {
                        return (
                          sum +
                          staffOneStore.salary[id].table.records[item].types[
                            el.id
                          ]
                        );
                      }, 0)
                    )
                  : ""}
              </td>
            </tr>
          );
        })}
        <tr>
          <td className={styles.firstCol}>Остаток</td>
          {monthList.map((key) => {
            return (
              <td
                key={key}
                className={`${styles.boldFontMoney} ${styles.moneyCell}`}
              >
                {numberWithSpaces(
                  staffOneStore.salary[id].table.records[key].balance
                )}
              </td>
            );
          })}
          <td className={styles.lastCol}>
            {numberWithSpaces(
              monthList.reduce((sum, item) => {
                return (
                  sum + staffOneStore.salary[id].table.records[item].balance
                );
              }, 0)
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  ) : null;
};

export default observer(StaffOneInfoFinanceTable);
