import styles from "./staffOneBlocked.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "shared/ui/Button";
import { useStores } from "stores";

const StaffOneBlocked = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleUnlockStaff = () => {
    staffOneStore.unlockStaff(id);
  };

  const handleNavigate = () => {
    navigate(`/staff/id=${staffOneStore.info["locked_id"]}`);
  };

  return (
    <div className={styles.container}>
      <p className={styles.header}>Сотрудник заблокирован</p>
      <div className={styles.info}>
        <p className={styles.blockedStaff}>{`ТН${staffOneStore.info["uid"]} ${
          staffOneStore.info["surname"]
        } ${staffOneStore.info["name"]} ${
          staffOneStore.info["patronymic"] || ""
        }`}</p>
        <p>
          ТН основного физлица:
          <span className={styles.link} onClick={handleNavigate}>
            {staffOneStore.info["locked"]}
          </span>
        </p>
      </div>
      {staffOneStore.accessToUnlock ? (
        <Button onClick={handleUnlockStaff}>Разблокировать</Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOneBlocked);
