import styles from "./staffOneSafetyWorkPositionsList.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import { getPositionsForOneOfStaff } from "features/StaffOneSafetyWork";

type StaffOneInfoSafetyWorkPositionsListProps = {
  id: string;
  activeTab: string;
  setNoCertificateData: (arg: boolean) => void;
  activePosition: string;
  setActivePosition: (arg: string) => void;
  positions: object;
  setPositions: (arg: {
    [key: string]: Partial<{ [key: string]: { [key: string]: string } }>;
  }) => void;
};

const StaffOneInfoSafetyWorkPositionsList = ({
  id,
  activeTab,
  setNoCertificateData,
  activePosition,
  setActivePosition,
  positions,
  setPositions
}: StaffOneInfoSafetyWorkPositionsListProps) => {
  const { staffOneStore } = useStores();

  useEffect(() => {
    setPositions({
      [id]: staffOneStore.safety_work[id]
        ? getPositionsForOneOfStaff(
            staffOneStore.safety_work[id]["certificates"],
            staffOneStore.companiesData,
            setNoCertificateData
          )
        : {}
    });
  }, [staffOneStore.safety_work[id]]);

  useEffect(() => {
    positions[id]?.[activeTab]
      ? setActivePosition(Object.keys(positions[id][activeTab])[0])
      : setActivePosition("");
  }, [positions, activeTab, id]);

  return (
    positions[id]?.[activeTab] && (
      <div className={styles.positionsContainer}>
        {Object.keys(positions[id][activeTab]).map((position) => {
          return (
            <button
              key={`${position}=${activeTab}`}
              id={`staffOneSafetyWorkPositionsList_position_${position}`}
              className={`${styles.position} ${
                position === activePosition ? styles.position_active : ""
              }`}
              onClick={() => {
                setActivePosition(position);
              }}
            >
              {positions[id][activeTab][position]}
            </button>
          );
        })}
      </div>
    )
  );
};

export default observer(StaffOneInfoSafetyWorkPositionsList);
