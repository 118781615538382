import styles from "./staffRatingDetailsTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { Table } from "react-bootstrap";
import { fileUrl } from "stores/utils/consts";
import iconStarBlue from "shared/assets/images/mainIcons/iconStar/iconStarLargeBlue.svg";
import iconStarGray from "shared/assets/images/mainIcons/iconStar/iconStarLargeGray.svg";

//hardcode
const stars = ["1", "2", "3", "4", "5"];

const StaffRatingDetailsTable = () => {
  const { staffRatingStore } = useStores();

  return (
    <Table className={styles.table}>
      <tbody>
        {Object.entries(staffRatingStore.staffDetails)
          .sort((a, b) => (a[0] > b[0] ? -1 : 1))
          .map(([rankId, qualities]) => {
            const countOfrank = staffRatingStore.staffRatingRanks[rankId];
            return countOfrank !== undefined ? (
              <tr className="d-flex" key={rankId}>
                <td>
                  <p className={styles.rank}>{`Оценка ${rankId}`}</p>
                  <div className={styles.stars}>
                    {stars.map((star) => {
                      return (
                        <img
                          src={star <= rankId ? iconStarBlue : iconStarGray}
                          key={star}
                        />
                      );
                    })}
                  </div>
                  <p className={styles.rank}>{`Количество ${countOfrank}`}</p>
                </td>
                {[...qualities]
                  .sort(
                    (a, b) =>
                      (staffRatingStore.staffRatingQualities[b.id] || 0) -
                      (staffRatingStore.staffRatingQualities[a.id] || 0)
                  )
                  .map((quality) => {
                    const countOfQuality =
                      staffRatingStore.staffRatingQualities[quality.id] || 0;
                    return (
                      <td key={quality.id}>
                        <div
                          className={`${styles.quality} ${
                            !countOfQuality ? styles.quality_grey : ""
                          }`}
                        >
                          <div
                            className={`${styles.quality__count} ${
                              !countOfQuality ? styles.quality__count_grey : ""
                            }`}
                          >
                            {countOfQuality}
                          </div>
                          <div className={styles.wrapper}>
                            <div className={styles.quality__image}>
                              {!countOfQuality ? (
                                <img
                                  src={`${fileUrl}${quality?.custom?.images[0].path}`}
                                />
                              ) : (
                                <img
                                  src={`${fileUrl}${quality?.custom?.images[1].path}`}
                                />
                              )}
                            </div>
                            <div className={styles.quality__title}>
                              {quality.title}
                            </div>
                          </div>
                        </div>
                      </td>
                    );
                  })}
              </tr>
            ) : null;
          })}
      </tbody>
    </Table>
  );
};

export default observer(StaffRatingDetailsTable);
