import styles from "./salaryOperationsAllTable.module.scss";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "stores";

import { Table } from "react-bootstrap";

import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddWithoutFill.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpandBackground.svg";
import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";

import Tooltip from "shared/ui/Tooltip";
import { fileUrl } from "stores/utils/consts";
import LoadedComponent from "widgets/LoadedComponent";
import Loading from "widgets/LoadedComponent/Loading";
import { browserName } from "react-device-detect";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

type SalaryOperationsAllTableProps = {
  handleClick: (id: string) => void;
};

const SalaryOperationsAllTable = ({
  handleClick
}: SalaryOperationsAllTableProps) => {
  const {
    salaryOperationsFormStore,
    salaryOperationsListStore,
    menuStore,
    filterStore
  } = useStores();
  const [isHoveredTN, setIsHoveredTN] = useState(false);
  const [openedRow, setOpenedRow] = useState<{ [key: string]: boolean }>({});

  const navigate = useNavigate();
  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      salaryOperationsListStore.maxPage >= salaryOperationsListStore.page &&
      salaryOperationsListStore.page === salaryOperationsListStore.prevPage
    ) {
      salaryOperationsListStore.setPage(salaryOperationsListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {Object.values(salaryOperationsListStore.salaryOperationsTableCols)
        .length &&
      salaryOperationsListStore.salaryOperationsList.length &&
      salaryOperationsListStore.currentTitles.length ? (
        <Table
          className={styles.table}
          style={{
            marginTop: getTableMarginTop(
              filterStore.savedFiltersHeight,
              Boolean(
                !salaryOperationsListStore.filters[
                  salaryOperationsListStore.downloadedFilter
                ]?.["general"] || salaryOperationsListStore.searchValue
              ),
              filterStore.isOpenAdvancedSearch ||
                filterStore.isOpenedSavedFilterOptions ||
                filterStore.isOpenedColumnsOptions,
              browserName === "Firefox"
            ),
            transition: "0.2s"
          }}
          ref={tableRef}
        >
          <thead
            className={`${menuStore.isScroll ? styles.shadow : ""}`}
            style={{
              top: getTHeadTop(
                filterStore.savedFiltersHeight,
                Boolean(
                  !salaryOperationsListStore.filters[
                    salaryOperationsListStore.downloadedFilter
                  ]?.["general"] || salaryOperationsListStore.searchValue
                ),
                filterStore.isOpenedSavedFilters && browserName === "Firefox"
              )
            }}
          >
            <tr>
              {salaryOperationsListStore.currentTitles.map((title) => {
                return (
                  <th key={title}>
                    <div>
                      <p>
                        {
                          salaryOperationsListStore.salaryOperationsTableCols[
                            title
                          ].title
                        }
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {salaryOperationsListStore.salaryOperationsList.map(
              (oneOfStaff, index) => {
                return (
                  <React.Fragment
                    key={`${oneOfStaff.uid}_${
                      Object.values(oneOfStaff.salary_list)[0]["id"]
                    }`}
                  >
                    <tr
                      className={`${!isHoveredTN ? styles.tableRow : ""} ${
                        !(index % 2) ? styles.tableRow_grey : ""
                      }`}
                      id={`SalaryOperationsAllTable_${oneOfStaff.uid}`}
                      onClick={(event) => {
                        if (
                          event.target["dataset"].field === "tn" ||
                          ["circle", "rect", "path", "svg"].includes(
                            event.target["localName"]
                          )
                        )
                          return;
                        handleClick(
                          Object.values(oneOfStaff.salary_list)[0]["id"]
                        );
                      }}
                    >
                      {salaryOperationsListStore.currentTitles.map((title) => {
                        switch (title) {
                          case "uid":
                            return (
                              <td
                                key={`${oneOfStaff.uid}_${title}`}
                                onClick={() => {
                                  return;
                                }}
                                rowSpan={
                                  openedRow[oneOfStaff.uid.id] &&
                                  Object.values(oneOfStaff.salary_list).length >
                                    1
                                    ? Object.values(oneOfStaff.salary_list)
                                        .length
                                    : 1
                                }
                                data-field="uid"
                                onMouseEnter={() => setIsHoveredTN(true)}
                                onMouseLeave={() => setIsHoveredTN(false)}
                                className={styles.uid}
                              >
                                <div data-field="uid">
                                  <IconAdd
                                    className={styles.uid__iconAdd}
                                    onClick={() => {
                                      // hardcode, заменить при разработке на id
                                      salaryOperationsFormStore.setSelectedStaffFromTable(
                                        String(oneOfStaff[title]["id"]),
                                        `TH${oneOfStaff[title]["tn"]} ${
                                          oneOfStaff[title]["surname"] || ""
                                        } ${oneOfStaff[title]["name"] || ""} ${
                                          oneOfStaff[title]["patronymic"] || ""
                                        }`
                                      );
                                      navigate(
                                        `/salary/add_operation/id=${oneOfStaff[title]["id"]}`
                                      );
                                    }}
                                  />
                                  <IconExpand
                                    className={`${styles.uid__iconExpand} ${
                                      Object.values(oneOfStaff.salary_list)
                                        .length > 1 &&
                                      openedRow[oneOfStaff.uid.id]
                                        ? styles.uid__iconExpand_opened
                                        : Object.values(oneOfStaff.salary_list)
                                            .length === 1
                                        ? styles.uid__iconExpand_blocked
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setOpenedRow({
                                        ...openedRow,
                                        [oneOfStaff.uid.id]: Boolean(
                                          !openedRow[oneOfStaff.uid.id]
                                        )
                                      });
                                    }}
                                    id={`SalaryOperationsAllTable_openRowBtn_${oneOfStaff[title]["id"]}`}
                                  />
                                  <Link
                                    className={styles.uid__link}
                                    id={`SalaryOperationsAllTable__thLink_${oneOfStaff[title]["id"]}}`}
                                    to={`/staff/id=${oneOfStaff[title]["id"]}`}
                                    target="_blank"
                                  >
                                    <p data-field="uid">{`TH${
                                      oneOfStaff[title]["tn"]
                                    } ${oneOfStaff[title]["surname"] || ""} ${
                                      oneOfStaff[title]["name"] || ""
                                    } ${
                                      oneOfStaff[title]["patronymic"] || ""
                                    }`}</p>
                                  </Link>
                                </div>
                              </td>
                            );
                          case "object":
                          case "author":
                            return (
                              <td key={`${oneOfStaff.uid.id}_${title}`}>
                                <div>
                                  <p>
                                    {
                                      Object.values(oneOfStaff.salary_list)[0][
                                        title
                                      ]["title"]
                                    }
                                  </p>
                                </div>
                              </td>
                            );
                          case "money":
                            return (
                              <td key={`${oneOfStaff.uid.id}_${title}`}>
                                <div>
                                  <p>
                                    {numberWithSpaces(
                                      Object.values(oneOfStaff.salary_list)[0][
                                        title
                                      ]
                                    )}
                                  </p>
                                </div>
                              </td>
                            );
                          case "type":
                            return (
                              <td key={`${oneOfStaff.uid.id}_${title}`}>
                                <div className={styles.typeBlock}>
                                  <p
                                    className={
                                      styles[
                                        `type_${
                                          salaryOperationsListStore
                                            .salaryOperationsTypes[
                                            Object.values(
                                              oneOfStaff.salary_list
                                            )[0][title]
                                          ]?.["custom"]?.["color"]
                                        }`
                                      ]
                                    }
                                  >
                                    {
                                      salaryOperationsListStore
                                        .salaryOperationsTypes[
                                        Object.values(
                                          oneOfStaff.salary_list
                                        )[0][title]
                                      ]?.["title"]
                                    }
                                  </p>
                                  {Object.values(oneOfStaff.salary_list)[0][
                                    "paymentlist"
                                  ] ? (
                                    <a
                                      rel="noreferrer"
                                      target="_blank"
                                      href={`${fileUrl}/crm/salary/?ps_id=${
                                        Object.values(
                                          oneOfStaff.salary_list
                                        )[0]["paymentlist"]
                                      }`}
                                    >
                                      <Tooltip text="Перейти к Ведомости">
                                        <div className={styles.connect}>
                                          <IconFinance />
                                        </div>
                                      </Tooltip>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td key={`${oneOfStaff.uid.id}_${title}`}>
                                <div>
                                  <p>
                                    {
                                      Object.values(oneOfStaff.salary_list)[0][
                                        title
                                      ]
                                    }
                                  </p>
                                </div>
                              </td>
                            );
                        }
                      })}
                    </tr>
                    {openedRow[oneOfStaff.uid.id] &&
                    Object.values(oneOfStaff.salary_list).length > 1
                      ? Object.values(oneOfStaff.salary_list).map(
                          (operation, idx) => {
                            if (idx === 0) return;
                            return (
                              <tr
                                key={operation.id}
                                className={styles.tableRow}
                                id={`SalaryOperationsAllTable_${operation.id}`}
                                onClick={(event) => {
                                  if (event.target["dataset"].field !== "uid") {
                                    handleClick(operation.id);
                                  }
                                }}
                              >
                                {salaryOperationsListStore.currentTitles.map(
                                  (title) => {
                                    switch (title) {
                                      case "uid":
                                        return;
                                      case "object":
                                      case "author":
                                        return (
                                          <td key={`${operation.id}_${title}`}>
                                            <div>
                                              <p>{operation[title]["title"]}</p>
                                            </div>
                                          </td>
                                        );

                                      case "money":
                                        return (
                                          <td key={`${operation.id}_${title}`}>
                                            <div>
                                              <p>
                                                {numberWithSpaces(
                                                  operation[title]
                                                )}
                                              </p>
                                            </div>
                                          </td>
                                        );
                                      case "type":
                                        return (
                                          <td key={`${operation.id}_${title}`}>
                                            <div className={styles.typeBlock}>
                                              <p
                                                className={
                                                  styles[
                                                    `type_${
                                                      salaryOperationsListStore
                                                        .salaryOperationsTypes[
                                                        operation[title]
                                                      ]?.["custom"]?.["color"]
                                                    }`
                                                  ]
                                                }
                                              >
                                                {
                                                  salaryOperationsListStore
                                                    .salaryOperationsTypes[
                                                    operation[title]
                                                  ]?.["title"]
                                                }
                                              </p>
                                              {operation["paymentlist"] ? (
                                                <a
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={`${fileUrl}/crm/salary/?ps_id=${operation["paymentlist"]}`}
                                                >
                                                  <Tooltip text="Перейти к Ведомости">
                                                    <div
                                                      className={styles.connect}
                                                    >
                                                      <IconFinance />
                                                    </div>
                                                  </Tooltip>
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                        );
                                      default:
                                        return (
                                          <td key={`${operation.id}_${title}`}>
                                            <div>
                                              <p>{operation[title]} </p>
                                            </div>
                                          </td>
                                        );
                                    }
                                  }
                                )}
                              </tr>
                            );
                          }
                        )
                      : ""}
                  </React.Fragment>
                );
              }
            )}
          </tbody>
        </Table>
      ) : null}
      {salaryOperationsListStore.page !== 1 ? (
        <div className={styles.loading}>
          {salaryOperationsListStore.isLoading ? <Loading withoutText /> : ""}
        </div>
      ) : (
        <LoadedComponent
          isLoading={salaryOperationsListStore.isLoading}
          errorMessage={salaryOperationsListStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(SalaryOperationsAllTable);
