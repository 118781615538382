import styles from "./staffVacationInfo.module.scss";
import StaffVacationInfoTab from "./StaffVacationInfoTab";
import { useStores } from "stores/index";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";

const startData = {
  0: { title: "В отпуске более 15 суток", data: 123 },
  1: { title: "Продлили отпуск", data: 423 },
  2: { title: "Уволены из отпуска", data: 1 },
  3: { title: "Прогноз возврата из отпуска", data: 12123 },
  4: { title: "Прогноз возврата", data: 12 }
};
const StaffVacationInfo = () => {
  const { staffVacationStore, filterStore } = useStores();

  return (
    <div
      className={styles.wrapper}
      style={{
        marginTop: getTableMarginTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !staffVacationStore.filters[staffVacationStore.downloadedFilter]?.[
              "general"
            ] || staffVacationStore.searchValue
          ),
          filterStore.isOpenAdvancedSearch ||
            filterStore.isOpenedSavedFilterOptions ||
            filterStore.isOpenedColumnsOptions,
          browserName === "Firefox"
        ),
        transition: "0.2s"
      }}
    >
      <div className={styles.tabsWrapper}>
        {Object.values(startData).map((tab) => {
          return (
            <StaffVacationInfoTab
              key={tab.data}
              data={tab.data}
              title={tab.title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StaffVacationInfo;
