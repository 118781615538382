import { makeAutoObservable, runInAction } from "mobx";
import { StaffListItem } from "./types/StaffListItem";
import { Col } from "./types/Col";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { StaffVacationData } from "./types/StaffVacationData";
import RootStore from "stores";
import { Building } from "./types/Building";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type FilterParams = {
  [key: string]: string | number;
};

export default class StaffVacationStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;

  openedFilter = false;

  currentTitles: string[] = [];

  vacationsTableCols: { [key: string]: Col } = {};
  vacationsTableParams: { [key: string]: Col } = {};
  vacationsColsAll: { [key: string]: Col } = {};

  vacationsList: StaffListItem[] = [];

  vacationsListOrder = "";
  vacationsListOrdered = "";

  searchValue = "";
  filterParams: FilterParams | { [key: string]: string[] } = {};

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  filters: { [key: string]: FilterType } = {};
  selectedFilter = "";
  downloadedFilter = "";

  actionButton = {
    0: {
      id: 1,
      title: "OK"
    }
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};
    if (this.vacationsListOrder) params["order"] = this.vacationsListOrder;
    if (this.vacationsListOrdered)
      params["ordered"] = this.vacationsListOrdered;
    if (this.searchValue) params["fast_search"] = this.searchValue;

    return params;
  };

  getVacationsListWithFilter = (filter: FilterType) => {
    this.filterParams = {};
    this.setPage(1);
    this.setVacationsListOrder(filter.order);
    this.setVacationsListOrdered(filter.ordered);

    if (filter.filter && Object.keys(filter.filter).length) {
      Object.entries(filter.filter).forEach(([key, value]) => {
        if (value && value.toString().length) {
          switch (key) {
            case "phone": {
              if (typeof value === "string") {
                const newPhone = value.replace(/[\D]+/g, "");
                this.filterParams[`filter[${key}]`] = newPhone;
              }
              break;
            }
            default:
              if (value !== -1 && typeof value === "object") {
                this.filterParams[`filter[${key}]`] = Object.values(value);
              } else if (value !== -1) {
                this.filterParams[`filter[${key}]`] = value;
              }
              break;
          }
        }
      });
    }

    filter.cols.forEach((item) => {
      this.filterParams[`show[${item}]`] = "on";
    });
    this.setDownloadedFilter(filter["id"]);
    this.getVacationsData();
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setVacationsListOrder = (value: string) => {
    this.vacationsListOrder = value;
  };

  setVacationsListOrdered = (value: string) => {
    this.vacationsListOrdered = value;
  };

  setOpenedFilter = (value: boolean) => {
    this.openedFilter = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  getTitle = (key: string) => {
    if (
      Object.keys(this.vacationsTableParams).length &&
      this.vacationsTableParams[key]
    ) {
      return this.vacationsTableParams[key]["title"];
    } else return key;
  };

  showMoreVacations = async () => {
    this.errorMessage = "";

    runInAction(() => (this.isLoading = true));
    try {
      const data: StaffVacationData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          only_records: 1,
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.vacationsList.push(
            ...this.dataPreparation(Object.values(data["records"]))
          );
        } else {
          this.vacationsList = [];
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    const array = data.map((oneOfStaff, index) => {
      const data = { ...oneOfStaff };

      // хардкод
      if (index % 2) {
        data["planned_object"] = {
          planned_object: "7eea2bb334feae21344a00729ac8bd46170d9ae6",
          date_start: "23.06.1983"
        };
      } else data["planned_object"] = {};

      if (index % 3) {
        data["coordinator"] = "Евсеева Татьяна Витальевна";
      } else data["coordinator"] = "";

      if (index % 5) {
        data["future_status"] = {
          status: "58f3be3209ee7f6c586d7a7e583b5e9eab8caac2",
          icon: "person",
          color: "danger",
          date: "30-05-2022",
          author: "Инчаков Никита Андреевич"
        };
      } else data["future_status"] = {};

      data["current_status"] = {
        status: "5b700e8e92e40355f0f772c3ab9de6615d585cf2",
        icon: "lotus",
        color: "info",
        OM: {
          id: "OM",
          date_start: "30-04-2022",
          date_end: "30-05-2022"
        },
        author: "Инчаков Никита Андреевич"
      };

      if (index % 2)
        data["current_status"]["OE"] = {
          id: "OE",
          date_start: "30-04-2022",
          date_end: "30-05-2022"
        };

      const addValue = (object: object) => {
        Object.entries(object).forEach(([key, value]) => {
          switch (value) {
            case null:
              data[key] = "";
              break;
            case -1:
              data[key] = 0;
              break;
            default:
              if (typeof value === "object") {
                addValue(value);
              } else data[key] = getFormattedDate(value);
              break;
          }
        });
      };

      addValue(oneOfStaff);
      return data;
    });
    return array;
  };

  getVacationsData = async (newFilters?: string) => {
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.errorMessage = "";
    this.page = 1;
    if (!newFilters) this.vacationsList = [];
    runInAction(() => (this.isLoading = true));
    try {
      const data: StaffVacationData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (!newFilters) {
          if (Object.values(data["records"]).length) {
            this.vacationsList = this.dataPreparation(
              Object.values(data["records"])
            );
          } else {
            this.vacationsList = [];
            this.errorMessage = "Ничего не найдено";
          }

          if (Object.values(data["show_fields"]).length) {
            this.currentTitles = Object.values(data["show_fields"]);
            //хардкод
            this.currentTitles.push(
              "current_status",
              "future_status",
              "planned_object",
              "coordinator"
            );
          } else {
            this.currentTitles = [];
            this.errorMessage = "Ничего не найдено";
          }

          this.maxPage = data["nav"]["max_page"];
        }

        if (
          Object.values(data["filters"]).length &&
          (!Object.keys(this.filters).length || newFilters)
        ) {
          // хардкод
          this.vacationsTableParams = {
            ...data["cols"],
            future_status: {
              newname: "future_status",
              title: "Будущий статус",
              default: "NULL",
              type: "object",
              length: ""
            },
            planned_object: {
              newname: "planned_object",
              title: "Запланированный объект",
              default: "NULL",
              type: "object",
              length: ""
            },
            coordinator: {
              newname: "coordinator",
              title: "Координатор",
              default: "NULL",
              type: "string",
              length: ""
            },
            current_status: {
              newname: "current_status",
              title: "Текущий статус",
              default: "NULL",
              type: "object",
              length: ""
            }
          };

          if (Object.keys(data["selects"]).length) {
            Object.entries(data["selects"]).forEach(([key, value]) => {
              const directory: {
                [key: string]: {
                  newname: string;
                  title: string;
                  cols?: string[];
                };
              } = {};

              switch (key) {
                case "event_type":
                  {
                    const transit_cols: string[] = Object.values(
                      data["selects"][key][
                        "5b700e8e92e40355f0f772c3ab9de6615d585cf2"
                      ]["custom"]["transit_cols"]
                    );

                    directory["5b700e8e92e40355f0f772c3ab9de6615d585cf2"] = {
                      newname: "5b700e8e92e40355f0f772c3ab9de6615d585cf2",
                      title:
                        data["selects"][key][
                          "5b700e8e92e40355f0f772c3ab9de6615d585cf2"
                        ]["title"],
                      cols: transit_cols
                    };

                    transit_cols.forEach((id) => {
                      directory[id] = {
                        newname: id,
                        title: data["selects"][key][id]["title"],
                        cols: Object.values(
                          data["selects"][key][id]["custom"]["transit_cols"]
                        )
                      };
                    });
                  }
                  break;
                case "company":
                case "building":
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = item.title;
                  });
                  break;

                case "position":
                  Object.values(value).forEach((company: object) => {
                    Object.values(company).forEach((position) => {
                      directory[position.id ? position.id : position.title] =
                        position.title;
                    });
                  });
                  break;
                default:
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    };
                  });
                  break;
              }

              this.vacationsTableParams[key] = {
                ...this.vacationsTableParams[key],
                directory: directory
              };
            });

            this.vacationsColsAll = data["cols"];

            // хардкод
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};

            Object.values(
              data["selects"]["building"] as { [key: string]: Building }
            ).forEach((item) => {
              directory[item.id ? item.id : item.title] = {
                newname: item.id ? item.id : item.title,
                title: item.title
              };
            });
            this.vacationsTableParams["planned_object"] = {
              ...this.vacationsTableParams["planned_object"],
              directory: directory
            };
          }

          if (!Object.keys(this.vacationsTableCols).length) {
            Object.entries(data["cols"]).forEach(([key, value]) => {
              if (
                ![
                  "company",
                  "event_type",
                  "building",
                  "count_work_all"
                ].includes(key)
              )
                this.vacationsTableCols[key] = value;
            });
          }

          Object.entries(data["filters"]).forEach((filter) => {
            this.filters[filter[0]] = filter[1]["filterdata"];
            this.filters[filter[0]]["cols"] = filter[1]["filterdata"]["cols"]
              ? Object.values(filter[1]["filterdata"]["cols"])
              : [];
            this.filters[filter[0]]["id"] = filter[0];
            if (filter[0] === this.downloadedFilter) {
              this.vacationsListOrdered = this.filters[filter[0]]["ordered"];
            }

            if (this.filters[filter[0]]["default"]) {
              this.setDownloadedFilter(filter[0]);
              this.vacationsListOrdered = this.filters[filter[0]]["ordered"];
            }
          });
          this.selectedFilter = "filter_default";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const params: { [key: string]: string | number } | { cols?: string[] } = {};

    runInAction(() => {
      this.isLoadingForFilters = true;
    });

    Object.entries(values).forEach(([key, value]) => {
      switch (key) {
        case "cols":
          params[key] = values[key];
          break;
        case "filter":
          value && Object.entries(value).length
            ? Object.entries(value).forEach(([key, value]) => {
                switch (key) {
                  case "company":
                  case "position":
                  case "building":
                    params[`filter[${key}]`] = Object.values(value);
                    break;
                  default:
                    if (value.toString().length)
                      params[`filter[${key}]`] = value;
                    break;
                }
              })
            : "";
          break;
        case "id":
          break;
        default:
          params[key] = values[key];
          break;
      }
    });

    switch (action) {
      case "add":
        params["saveFilter"] = values.title;
        break;
      case "edit":
        params["updateFilter"] = id;
        break;
      case "delete":
        params["removeFilter"] = id;
        this.setSelectedFilter(
          Object.values(this.filters)[0]["id"] !== id
            ? Object.values(this.filters)[0]["id"]
            : "new"
        );
        delete this.filters[id];
        break;
    }

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        body: { module: "staff", ...params }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getVacationsData("newFilters");
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  resetAll = () => {
    this.vacationsListOrder = "";
    this.vacationsListOrdered = "";
    this.onPage = 100;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.searchValue = "";
    this.filters = {};
    this.filterParams = {};
    this.vacationsTableCols = {};
    this.vacationsTableParams = {};
    this.currentTitles = [];
    this.vacationsList = [];
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
