/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";
import MainFilter from "./MainFilter/index";
import Map from "./MainMap/index";
import PageTitle from "shared/ui/PageTitle";

const Main = () => {
  const { menuStore } = useStores();

  const [localMap, setLocalMap] = useState({});
  const [clusterer, setClusterer] = useState({});
  const [geoObjects, setGeoObjects] = useState([]);

  useEffect(() => {
    menuStore.closeAll();
  }, []);

  const newCluster = () => {
    const clust = new window.ymaps.Clusterer({
      preset: "islands#invertedBlueClusterIcons",
      clusterHideIconOnBalloonOpen: false,
      geoObjectHideIconOnBalloonOpen: false
    });

    clust.events.add(["mouseenter", "mouseleave"], function (e: any) {
      const target = e.get("target"),
        type = e.get("type");
      if (typeof target.getGeoObjects !== "undefined") {
        if (type === "mouseenter") {
          target.options.set("preset", "islands#invertedVioletClusterIcons");
        } else {
          target.options.set("preset", "islands#invertedBlueClusterIcons");
        }
      } else {
        if (type === "mouseenter") {
          target.options.set("preset", "islands#invertedBlueClusterIcons");
        } else {
          target.options.set("preset", "islands#invertedVioletClusterIcons");
        }
      }
    });

    setClusterer(clust);
  };

  return (
    <>
      {/* <PageTitle title="Наши объекты" leftCol />
      <MainFilter
        localMap={localMap}
        newCluster={newCluster}
        setGeoObjects={setGeoObjects}
      />
      <Map
        localMap={localMap}
        setLocalMap={setLocalMap}
        clusterer={clusterer}
        newCluster={newCluster}
        geoObjects={geoObjects}
      /> */}
    </>
  );
};

export default observer(Main);
