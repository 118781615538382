import { object, array } from "yup";
import { stringSchema } from "shared/utils/validation/validation";
import { parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

const validationDate = object({
  date_start: stringSchema.test(
    "date_start",
    "Дата получения не может быть позже даты сдачи по акту",
    function (val) {
      if (this.parent.date_end && val) {
        return parse(
          this.parent.date_end,
          dateFormats.date.format,
          new Date()
        ) >= parse(val, dateFormats.date.format, new Date())
          ? true
          : false;
      } else return true;
    }
  ),
  date_end: stringSchema.test(
    "date_end",
    "Дата сдачи по акту не может быть ранее даты получения",
    function (val) {
      if (this.parent.date_start && val) {
        return parse(
          this.parent.date_start,
          dateFormats.date.format,
          new Date()
        ) <= parse(val, dateFormats.date.format, new Date())
          ? true
          : false;
      } else return true;
    }
  ),
  info: array().of(
    object({
      date_start: stringSchema.test(
        "date_start",
        "Дата получения не может быть позже даты сдачи по акту",
        function (val) {
          if (val) {
            return parse(
              this.parent.date_end,
              dateFormats.date.format,
              new Date()
            ) >= parse(val, dateFormats.date.format, new Date())
              ? true
              : false;
          } else return false;
        }
      ),
      date_end: stringSchema.test(
        "date_end",
        "Дата сдачи по акту не может быть ранее даты получения",
        function (val) {
          if (val) {
            return this.parent.date_start &&
              parse(
                this.parent.date_end,
                dateFormats.date.format,
                new Date()
              ) <= parse(val, dateFormats.date.format, new Date())
              ? true
              : false;
          } else return false;
        }
      )
    })
  )
});

export default validationDate;
