import styles from "./headerTopMenuPopup.module.scss";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconClose.svg";

type HeaderTopMenuPopupType = {
  title: string;
  content: { [key: string]: string | { [key: string]: string } };
  isOpenedPopup: boolean;
  setIsOpenedPopup: (arg: string) => void;
  isInfo: boolean;
};

const infoTitles = {
  version: "Версия",
  date: "Дата релиза",
  commit: "Коммит",
  description: ""
};

const HeaderTopMenuPopup = ({
  title,
  content,
  isInfo,
  isOpenedPopup,
  setIsOpenedPopup
}: HeaderTopMenuPopupType) => {
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside({
    ref,
    handler: (event) => {
      if (event["target"]["dataset"]?.type === "topMenuBtn") return;
      setTimeout(() => {
        isOpenedPopup && setIsOpenedPopup("");
      }, 200);
    }
  });

  return (
    <div className={styles.popup} ref={ref}>
      <div className={styles.popup__title}>
        <div>{title}</div>
        <IconClose
          id={`HeaderTopMenuPopup_${title}_closeBtn`}
          onClick={() => setIsOpenedPopup("")}
        />
      </div>
      <div className={styles.popup__body}>
        {isInfo ? (
          Object.keys(infoTitles).map((title) => {
            switch (title) {
              case "description":
                return (
                  <div key={title}>
                    Разработано в{" "}
                    <a
                      className={styles.link}
                      href={content.link as string}
                      target="_blank"
                      rel="noreferrer"
                    >
                      ITS
                    </a>
                  </div>
                );
              default:
                return (
                  <div key={title}>
                    {`${infoTitles[title]} - ${content[title]}`}
                  </div>
                );
            }
          })
        ) : (
          <>
            {content.text ? (
              <div>{content.text as string}</div>
            ) : (
              Object.values(content).map((item) => {
                return item?.link ? (
                  <div key={item.link as string}>
                    <a
                      className={styles.link}
                      href={item.link as string}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.["text"]}
                    </a>
                  </div>
                ) : (
                  "Где ссылка"
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(HeaderTopMenuPopup);
