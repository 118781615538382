import styles from "./selectIcon.module.scss";
import { useState, useEffect } from "react";

import iconClose from "shared/assets/images/mainIcons/iconClose.svg";
import iconExpand from "shared/assets/images/mainIcons/iconExpand.svg";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonTheme } from "../Button";

type SelectIconProps = {
  value: string;
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

const icons_list = [
  "airplane",
  "appsettings",
  "arrows",
  "attention",
  "bigcheck",
  "bonet",
  "companies",
  "dash",
  "delta",
  "dislike",
  "dontсall",
  "flame",
  "health",
  "heart",
  "heartz",
  "instruction",
  "layers",
  "letter",
  "libra",
  "lightning",
  "like",
  "lotus",
  "medal",
  "message",
  "palm tree",
  "person",
  "personcard",
  "personcheck",
  "persondelete",
  "photo",
  "plus",
  "road",
  "scissors",
  "shield",
  "statistics",
  "telephone",
  "ticket",
  "unload",
  "vacation",
  "worker",
  "wrench",
  "coins",
  "сrane"
];

const SelectIcon = ({ value, isOpened, setIsOpened }: SelectIconProps) => {
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    if (value) {
      setSelectedIcon(value);
    }
    return () => setSelectedIcon("");
  }, [value]);

  return (
    <div className="position-relative">
      <div
        className={`${styles.mainStyles} ${isOpened ? styles.selected : ""}`}
        onClick={() => {
          setIsOpened(false);
        }}
        id="selectIcon_toggleWindow"
      >
        <div
          className={`d-flex flex-column gap-1 ${
            selectedIcon ? "justify-content-between" : "justify-content-center"
          }`}
        >
          {selectedIcon ? <p className={styles.label}>Иконка</p> : null}
          <div>
            {selectedIcon ? (
              <StatusIcon icon={selectedIcon} color="" />
            ) : (
              <p className={styles.noIcon}>Иконка</p>
            )}
          </div>
        </div>
        <div>
          <img
            src={iconExpand}
            className={isOpened ? styles.iconExpandRotated : ""}
          />
        </div>
      </div>
      {isOpened ? (
        <div className={styles.container}>
          <p className={styles.title}>Выберите иконку</p>
          <img
            src={iconClose}
            className={styles.iconClose}
            onClick={() => {
              setIsOpened(false);
            }}
            id="selectIcon_closeWindow"
          />
          <div className="d-flex flex-wrap gap-2 pb-4">
            {icons_list.map((key) => {
              return (
                <span
                  key={key}
                  className={`${styles.iconContainer} ${
                    selectedIcon === key ? styles.iconContainer_selected : ""
                  }`}
                  onClick={() => setSelectedIcon(key)}
                  id={`selectIcon_selectIcon_${key}`}
                >
                  <StatusIcon
                    icon={key}
                    color={selectedIcon === key ? "bw-gray7" : "bw-gray5"}
                    negative={selectedIcon === key}
                  />
                </span>
              );
            })}
          </div>
          <Button
            type="button"
            id="selectIcon_saveSelectedIcon"
            className={styles.btnSave}
            theme={ButtonTheme.SECONDARY}
            disabled
          >
            Сохранить
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SelectIcon;
