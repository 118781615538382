import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import styles from "./buildingListTable.module.scss";
import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import LoadedComponent from "widgets/LoadedComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import ScrollButton from "shared/ui/Buttons/ScrollButton";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

type BuildingListTableProps = {
  filterItems: (arg: string) => void;
};

const BuildingListTable = ({ filterItems }: BuildingListTableProps) => {
  const { menuStore, buildingListStore, filterStore } = useStores();
  const navigate = useNavigate();
  const navigateOneBuilding = (id: string) => {
    navigate(`./id=${id}/timesheet`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      buildingListStore.maxPage >= buildingListStore.page &&
      buildingListStore.page === buildingListStore.prevPage
    ) {
      buildingListStore.setPage(buildingListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {Object.values(buildingListStore.buildingTableCols).length &&
      buildingListStore.currentTitles.length ? (
        <>
          <ScrollButton tableRef={tableRef} />
          <Table
            ref={tableRef}
            className={styles.table}
            style={{
              marginTop: getTableMarginTop(
                filterStore.savedFiltersHeight,
                Boolean(
                  !buildingListStore.filters[
                    buildingListStore.downloadedFilter
                  ]?.["general"] || buildingListStore.searchValue
                ),
                filterStore.isOpenAdvancedSearch ||
                  filterStore.isOpenedSavedFilterOptions ||
                  filterStore.isOpenedColumnsOptions,
                browserName === "Firefox"
              ),
              transition: "0.2s"
            }}
          >
            <thead
              className={styles.thead}
              style={{
                top: getTHeadTop(
                  filterStore.savedFiltersHeight,
                  Boolean(buildingListStore.searchValue),
                  filterStore.isOpenedSavedFilters && browserName === "Firefox"
                )
              }}
            >
              <tr>
                {buildingListStore.currentTitles.map((title) => {
                  return (
                    <th
                      key={title}
                      className={styles.title}
                      onClick={() => filterItems(title)}
                      id={title}
                    >
                      <div className="d-flex align-items-center">
                        <p className="m-0">
                          {buildingListStore.getTitle(title)}
                        </p>
                        <img src={iconArrowDouble} alt="sort" />
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {buildingListStore.buildingList.length ? (
                buildingListStore.buildingList.map((oneOfBuilding, index) => {
                  return (
                    <tr
                      key={oneOfBuilding.id}
                      onClick={() =>
                        navigateOneBuilding(oneOfBuilding["id"] as string)
                      }
                      id={`BuildingListTable_navigate_${oneOfBuilding["id"]}`}
                      className={!(index % 2) ? styles.grayRow : ""}
                    >
                      {buildingListStore.currentTitles.map((title) => {
                        switch (title) {
                          case "company":
                          case "type":
                          case "status":
                          case "project":
                            return (
                              <td key={`${oneOfBuilding.id}_${title}`}>
                                <p>
                                  {
                                    buildingListStore.selectsCols[title]?.[
                                      oneOfBuilding[title]
                                    ]?.["title"]
                                  }
                                </p>
                              </td>
                            );
                          default:
                            return (
                              <td key={`${oneOfBuilding.id}_${title}`}>
                                <p>{oneOfBuilding[title]}</p>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr className={styles.error}>
                  <td colSpan={buildingListStore.currentTitles.length}>
                    {buildingListStore.errorMessage
                      ? buildingListStore.errorMessage
                      : ""}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      ) : (
        ""
      )}
      {buildingListStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {buildingListStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent
          isLoading={buildingListStore.isLoading}
          error={buildingListStore.error}
          errorMessage={buildingListStore.errorMessage}
          actionButton={buildingListStore.actionButton}
        />
      )}
    </>
  );
};

export default observer(BuildingListTable);
