import styles from "./input.module.scss";
import { Field, FastField, useFormikContext, useField } from "formik";
import { RefObject, useRef, useState } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import { ClearFieldButton } from "shared/ui/ClearFieldButton";
import { classNames } from "shared/utils/helpers/classNames";

type InputProps = {
  /**
   * Имя поля для связывания с Formik
   */
  name: string;
  /**
   * Функция, отрабатывающая при изменении значения
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Функция, отрабатывающая при фокусе на поле
   */
  onFocus?: (event: React.FocusEvent) => void;
  /**
   * Функция, отрабатывающая при снятии фокуса с поля
   */
  onBlur?: (event: React.FocusEvent) => void;
  /**
   * Функция, отрабатывающая вводе текста
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Функция, отрабатывающая при клике на поле
   */
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Название поля, которое выводится в интерфейсе
   */
  label?: string;
  /**
   * Является поле заблокированным
   */
  disabled?: boolean;
  /**
   * Функция валидации
   */
  validate?: (arg: string) => void;
  /**
   * Является поле обязательным
   */
  required?: boolean;
  /**
   * Нужно ли использовать fastField Formik'а, ипользуется в больших формах (от 30 полей)
   */
  useFastField?: boolean;
  /**
   * Объект, который хранит в себе инпут поля ввода
   */
  inputRef?: RefObject<HTMLElement>;
  /**
   * Передается, если невозможно создать ref извне и работает вместе с функцией
   */
  createRef?: boolean;
  /**
   * Передается "no-autofill-please" (другие значения браузер игнорирует), если не нужно показывать подсказки при вводе
   */
  autocomplete?: string;
  /**
   * Передается, если initialValues поля с типом number, то для корректной работы формика измененное значение тоже должно быть с типом number
   */
  isNumber?: boolean;
  /**
   * Стили, заданные в родителе
   */
  classNamesObj?: {
    container?: string;
    input?: string;
    label?: string;
  };
  /**
   * Отображать ли кнопку очистки поля
   */
  withClearBtn?: boolean;
};

export const Input = ({
  name,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onClick,
  label,
  disabled,
  validate,
  required,
  useFastField,
  inputRef,
  autocomplete,
  createRef,
  isNumber,
  classNamesObj,
  withClearBtn
}: InputProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [, meta] = useField(name);
  const FieldComponent = useFastField ? FastField : Field;

  const newRef = useRef<HTMLElement>();
  const ref = createRef ? inputRef : newRef;
  const isError = meta.error && meta.touched;

  const handleOnClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onClick && onClick(event);
  };
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
      if (createRef && e["keyCode"] === 27) {
        newRef.current.blur();
      }
    }
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    setFieldTouched(name);
    setFieldValue(
      name,
      isNumber
        ? event.target.value?.length
          ? +event.target.value
          : null
        : event.target.value || null
    );
  };
  const handleMouseChange = (type: "enter" | "leave") => () =>
    !disabled && setIsHovering(type === "enter" ? true : false);
  const handleOnClickClearBtn = () => {
    setFieldValue(name, null);

    setTimeout(() => setFieldTouched(name), 1000);
  };

  useOnClickOutside({
    ref,
    handler: () => setIsFocused(false)
  });

  return (
    <div
      className={classNames(styles.relative, {}, [
        classNamesObj?.container || ""
      ])}
      onMouseEnter={handleMouseChange("enter")}
      onMouseLeave={handleMouseChange("leave")}
    >
      <FieldComponent name={name} validate={validate}>
        {({ field }) => {
          const hasValue =
            field.value?.length ||
            (typeof field.value === "number" && String(field)?.length);
          return (
            <div className={styles.container}>
              <input
                {...field}
                type="text"
                className={classNames(
                  styles.input,
                  {
                    [styles.inputError]: isError,
                    [styles.inputDisabled]: disabled,
                    [styles.inputHovered]: !disabled && isHovering,
                    [styles.inputWithClearBtn]: withClearBtn
                  },
                  [classNamesObj?.input || ""]
                )}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onClick={handleOnClick}
                value={field.value ?? ""}
                disabled={disabled}
                onChange={handleOnChange}
                ref={ref}
                autoComplete={autocomplete || "on"}
              />
              <label
                className={classNames(
                  styles.label,
                  {
                    [styles.labelWithData]: hasValue,
                    [styles.labelNoData]: !hasValue,
                    [styles.labelRequired]: required,
                    [styles.labelRequiredError]: required && isError,
                    [styles.labelWithDataRequired]: hasValue && required
                  },
                  [classNamesObj?.label || ""]
                )}
              >
                {label}
              </label>
              {withClearBtn ? (
                <ClearFieldButton
                  name={name}
                  disabled={disabled}
                  isFocused={isFocused}
                  onClick={handleOnClickClearBtn}
                  className={
                    field.value ? styles.clearBtnActive : styles.clearBtn
                  }
                />
              ) : null}
            </div>
          );
        }}
      </FieldComponent>
      {isError && (
        <div className={styles.feedback}>{isError ? meta.error : null}</div>
      )}
    </div>
  );
};
