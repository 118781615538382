import styles from "./buildingOneWorkshiftHistory.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import PageTitle from "shared/ui/PageTitle";
import LoadedComponent from "widgets/LoadedComponent";
import Alert from "shared/ui/Alert";

const BuildingOneWorkshiftHistory = () => {
  const { id } = useParams();
  const { menuStore, buildingOneWorkshiftStore } = useStores();

  useEffect(() => {
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("workshiftOne");
    menuStore.setOpenedSubmodule("workshiftOneHistory");
    buildingOneWorkshiftStore.setSelectedWorkshiftForInfo(id, "/history");
  }, [id]);

  return (
    <>
      <PageTitle title="История" leftCol />
      <LoadedComponent
        isLoading={buildingOneWorkshiftStore.isLoading}
        error={buildingOneWorkshiftStore.error}
      >
        <div className={styles.container}>
          {buildingOneWorkshiftStore.selectedOne?.history &&
          Object.entries(buildingOneWorkshiftStore.selectedOne.history)
            .length ? (
            Object.entries(buildingOneWorkshiftStore.selectedOne.history).map(
              ([date, historyItems]) => {
                const items: [string, { [key: string]: string }] =
                  Object.entries(historyItems)[0];
                return (
                  <div className={styles.storyContainer} key={date}>
                    <div className={styles.storyHeader}>
                      <div className={styles.storyHeader__date}>{date}</div>
                      <div className={styles.storyHeader__name}>{items[0]}</div>
                    </div>
                    <div className={styles.itemsContainer}>
                      {Object.values(items[1]).map((text) => {
                        return (
                          <div className={styles.item} key={text}>
                            {text}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <Alert
              errors={{
                head: "История изменений не найдена",
                color: "empty"
              }}
            />
          )}
        </div>
      </LoadedComponent>
    </>
  );
};

export default observer(BuildingOneWorkshiftHistory);
