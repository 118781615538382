import StatusIcon from "shared/ui/StatusIcon";
import styles from "./buttonsGroupForEdit.module.scss";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { ButtonGroupForEditType } from "stores/StaffModule/types/ButtonGroupForEditType";

type ButtonsGroupForEditProps = {
  btns: ButtonGroupForEditType;
  idx?: number;
  id?: string;
  onClick: (action: string, idx: number, id: string) => void;
};

const ButtonsGroupForEdit = ({
  btns,
  id,
  idx,
  onClick
}: ButtonsGroupForEditProps) => {
  return (
    <div className={styles.wrapper}>
      {btns
        ? btns.map((btn) => {
            return (
              <Button
                key={btn["action"]}
                id={btn["action"]}
                theme={ButtonTheme.SECONDARY}
                size={ButtonSize.S}
                onClick={() => onClick(btn["action"], idx, id)}
                className={styles.button}
                type={btn["type"]}
                disabled={btn["disabled"]}
              >
                <StatusIcon icon={btn["icon"]} color={btn["color"]} />
              </Button>
            );
          })
        : null}
    </div>
  );
};

export default ButtonsGroupForEdit;
