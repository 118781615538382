import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { format, parse } from "date-fns";
import { isEmpty } from "lodash";

import { Col } from "stores/StaffModule/types/Col";
import { Aregister } from "stores/AregisterModule/types/Aregister";
import { Company } from "stores/StaffModule/types/Company";
import { Project } from "stores/utils/types/Project";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { CustomFields } from "stores/utils/types/CustomFields";
import { MastersGangers } from "../types/MastersGangers";
import {
  AllPresavesInAregister,
  PresaveStaff,
  PresaveStaffTranslate,
  Total
} from "../types/PresavesStaff";
import { Select } from "stores/utils/types/Select";
import { Errors } from "stores/utils/types/ErrorsType";
import { Conditional_Cubes } from "../types/Cubes";
import { AregisterSelects } from "../types/AregisterSelects";
import { PresaveForAregister } from "../types/PresaveForAregister";

export type MastersAndGangers = {
  [key: string]: Select;
};

export type FieldsGroups = Readonly<["types", "dates", "sizes"]>;
export const groups: Readonly<Record<FieldsGroups[number], string[]>> = {
  types: ["type_work", "type", "species", "sub_work"],
  dates: ["time_create", "date_start", "date_end"],
  sizes: ["length", "width", "height"]
};

type ClosedErrName = "presaves" | "cubes";
export default class AregisterOneStore {
  error: Record<string, boolean> = {};
  error_message: Record<string, Errors["message"]> = {};
  closed_errors: Record<
    string,
    Partial<Record<ClosedErrName, Errors["message"]>>
  > = {};
  isLoading: Record<string, boolean> = {};

  // данные карточки заявки
  selectedOne: Partial<Aregister> = {};
  openedAll: Record<string, Partial<Aregister>> = {};

  // Приведённый объём
  cubes: Record<string, Conditional_Cubes> = {};

  // Колонки и параметры карточки
  cols: Record<string, Col> = {};
  aregisterTitles: string[] = [];

  // Статичные выпадающие списки
  selects: Partial<AregisterSelects> &
    Partial<{
      projects: Record<string, Project>;
      companies: Record<string, Company>;
    }> = {};

  custom_fields: Record<string, CustomFields> = {};

  // Динамические списки мастеров и бригадиров
  masters: Record<string, MastersAndGangers> = {};
  brigadiers: Record<string, MastersAndGangers> = {};

  // Данные почасовок
  presaves: Record<string, AllPresavesInAregister> = {};
  presavesCols: Partial<PresaveStaffTranslate> = {};
  presavesStaffList: Record<string, Record<string, PresaveStaff>> = {};
  presavesTotal: Record<string, Total> = {};
  canEditContribution = false;
  editContributionError: Record<
    string,
    Partial<{ message: Errors["message"]; presave_staff_id: string }>
  > = {};

  allAllowedPresaves: Record<string, Record<string, PresaveForAregister>> = {};

  highlightedPresave: Record<string, string> = {};
  focusedPresave: Record<string, string> = {};
  focusedCompanyStaff: Record<string, string> = {};

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  // открытие вкладки
  setSelectedAregister = (id: string) => {
    this.error[id] = false; // сомнительно

    if (isEmpty(this.rootStore.menuStore.allWindows)) {
      this.rootStore.menuStore.addWindow(
        "/aregister",
        "Реестр заявок строительных лесов"
      );
    }
    if (!this.rootStore.menuStore.allWindows[`/aregister/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/aregister/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAll[id];
    }

    // Если заявка уже открыта
    if (this.openedAll[id]) {
      // выбираем её как активную
      this.selectedOne = this.openedAll[id];

      // если ещё не получен список мастеров и бригадиров на даты заявки
      (!this.masters[id] || !this.brigadiers[id]) &&
        // делаем запрос на их получение
        this.getMastersAndGangers(
          id,
          this.openedAll[id].project?.id || "",
          this.openedAll[id].date_start,
          this.openedAll[id].date_end,
          this.getMasterOrGangerObj(id, "master"),
          this.getMasterOrGangerObj(id, "brigadier")
        );
    } else {
      // если открытой заявки нет - вызываем функцию получения всех данных по заявке
      this.getDataForAregister(id);
    }
  };

  // aregister/getOne
  getOneOfAregister = async (id: string) => {
    runInAction(() => {
      this.error[id] = false;
      this.isLoading[id] = true;
      this.selectedOne = {};
      this.openedAll[id] && delete this.openedAll[id];
    });

    try {
      const data: ApiResponse<Aregister> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getOne",
          params: { module: "aregister", id }
        });

      runInAction(() => {
        if (data.code === 200 && !isEmpty(data.result || {})) {
          this.selectedOne = data.result;
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/aregister/id=${id}`,
            title: `Заявка ${this.selectedOne.internal_num}`
          });
        } else {
          this.selectedOne = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/aregister/id=${id}`,
            title: "Ничего не найдено"
          });
          data.message && (this.error_message[id] = data.message);
          this.error[id] = true;
        }

        new Promise(() => {
          // если ещё не получен список мастеров и бригадиров на даты заявки
          (!this.masters[id] || !this.brigadiers[id]) &&
            // делаем запрос на их получение
            this.getMastersAndGangers(
              id,
              this.selectedOne.project.id,
              this.selectedOne.date_start,
              this.selectedOne.date_end,
              this.getMasterOrGangerObj(id, "master"),
              this.getMasterOrGangerObj(id, "brigadier")
            );
          // получаем параметры доп.полей для проекта заявки
          !this.custom_fields[this.selectedOne.project.id] &&
            this.getCustomFields(this.selectedOne.project.id);
        }).then(() => {
          this.openedAll[id] = this.selectedOne;
        });
      });
    } catch (error) {
      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/aregister/id=${id}`,
        title: "Ничего не найдено"
      });
      runInAction(() => (this.error[id] = true));
    }
  };

  // все запросы для полу
  getDataForAregister = (id: string) => {
    this.isLoading[id] = true;

    Promise.all([
      isEmpty(this.cols) && this.getCols(),
      isEmpty(this.selects) && this.getSelects(id),
      this.getOneOfAregister(id),
      this.getRecordConditionalCubes(id),
      this.getRecordCloseErrors(id, "presaves"),
      this.getRecordCloseErrors(id, "cubes"),
      this.getRecordPresaves(id)
    ]).then(() => {
      if (!this.aregisterTitles.length) {
        Object.values(this.cols).forEach(
          ({ newname, hidden }) =>
            hidden !== "on" && this.aregisterTitles.push(newname)
        );
      }

      runInAction(() => (this.isLoading[id] = false));
    });
  };

  getCols = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Col }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getTableCols",
          params: {
            module: "aregister",
            table_key: "aregister"
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cols = data.result;

          // хардкод, согласован с PM Андреем
          // замена title для поля master
          if (this.cols.master) {
            this.cols.master.title = "Мастер";
          }
        } else {
          this.error[this.rootStore.menuStore.tabId] = true;
        }
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  getSelects = async (id: string) => {
    try {
      const data: ApiResponse<AregisterSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getSelects",
          params: { module: "aregister" }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
          this.getCompaniesList();
        } else this.error[id] = true;
      });
    } catch (error) {
      runInAction(() => (this.error[id] = true));
    }
  };

  getMastersAndGangers = async (
    id: string,
    project_id: string,
    date_start: string,
    date_end: string,
    master: MastersAndGangers[string],
    brigadier: MastersAndGangers[string]
  ) => {
    try {
      const data: ApiResponse<MastersGangers> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getMastersAndGangers",
          params: {
            project_id: project_id,
            date_start: format(
              parse(date_start, "yyyy-MM-dd", new Date()),
              "dd.MM.yyyy"
            ),
            date_end: format(
              parse(date_end, "yyyy-MM-dd", new Date()),
              "dd.MM.yyyy"
            )
          }
        });

      runInAction(() => {
        this.masters[id] =
          master &&
          (!this.openedAll[id]?.masterless || this.selectedOne?.masterless)
            ? {
                masterless: {
                  id: "masterless",
                  title: "Без мастера"
                },
                [master.id]: master
              }
            : {
                masterless: {
                  id: "masterless",
                  title: "Без мастера"
                }
              };
        this.brigadiers[id] = brigadier ? { [brigadier.id]: brigadier } : {};

        if (data.code === 200) {
          const getList = (
            options: {
              [key: string]: {
                fio: string;
                tn: number;
              };
            },
            aregisterValue: MastersAndGangers,
            isMaster?: string
          ) => {
            const list: MastersAndGangers = aregisterValue;

            Object.entries(options).length &&
              Object.entries(options).forEach(([id, staffOne]) => {
                if (!list[id]) {
                  list[id] = {
                    title: `ТН${staffOne.tn} ${staffOne.fio}`,
                    id: id
                  };
                }
              });

            // В некоторых заявках есть такие ошибки (будут приходить нам чуть позже):
            // "Мастер заявки не числится в закрытых сменах либо не имеет часов"
            // Данные такого мастера и бригадира (если он выбран) не приходят в списке на выбранное число заявки
            // И их нужно добавить в список хардкодом для отображения в поле Select
            if (
              isMaster
                ? this.selectedOne.master?.id
                : this.selectedOne.brigadier?.id &&
                  !list[
                    isMaster
                      ? this.selectedOne.master.id
                      : this.selectedOne.brigadier.id
                  ]
            ) {
              const staff = isMaster
                ? this.selectedOne.master
                : this.selectedOne.brigadier;

              list[staff.id] = {
                title: `ТН${staff.uid} ${staff.surname} ${staff.name}${
                  " " + staff.patronymic || ""
                }`,
                id: id
              };
            }

            return list;
          };

          this.masters[id] = getList(
            data.result.masters,
            this.masters[id],
            "isMaster"
          );
          this.brigadiers[id] = getList(
            data.result.gangers,
            this.brigadiers[id]
          );
        } else this.error[id] === true;
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  getCustomFields = async (project_id: string) => {
    try {
      const data: ApiResponse<CustomFields> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "projects",
          method: "getCustomFields",
          params: {
            project_id: project_id
          }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.custom_fields[project_id] = data.result;
        } else this.error[this.selectedOne.id] === true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getMasterOrGangerObj = (
    aregister_id: string,
    name: string
  ): { id: string; title: string } => {
    return this.openedAll[aregister_id]?.[name]
      ? {
          id: this.openedAll[aregister_id][name]?.id,
          title: `ТН${this.openedAll[aregister_id][name].uid} ${
            this.openedAll[aregister_id][name]["surname"]
          } ${this.openedAll[aregister_id][name]["name"]} ${
            this.openedAll[aregister_id][name]["patronymic"] || ""
          }`
        }
      : null;
  };

  getRecordCloseErrors = async (id: string, entity: ClosedErrName) => {
    this.isLoading[id] = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getRecordCloseErrors",
        params: { module: "aregister", id, entity }
      });

      runInAction(() => {
        if (data.code === 200) {
          if (!data.result) {
            !this.closed_errors[id] && (this.closed_errors[id] = {});
            this.closed_errors[id][entity] = data.message;
          }
        } else this.error[id] === true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
        this.isLoading[id] = false;
      });
    }
  };

  getRecordConditionalCubes = async (id: string) => {
    this.isLoading[id] = true;

    try {
      const data: ApiResponse<Conditional_Cubes> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getRecordConditionalCubes",
          params: { module: "aregister", id }
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cubes[id] = data.result;
        } else this.error[id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
        this.isLoading[id] = false;
      });
    }
  };

  getRecordPresaves = async (id: string) => {
    this.isLoading[id] = true;

    try {
      const data: ApiResponse<{
        presaves: Record<string, PresaveStaff>;
        total: Total;
      }> & {
        translate: PresaveStaffTranslate;
        presaves: AllPresavesInAregister;
        can_edit_contribution: boolean;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getRecordPresaves",
        params: { module: "aregister", id }
      });

      runInAction(() => {
        if (data.code === 200) {
          this.presavesCols = data.translate;
          this.presaves[id] = data.presaves;
          this.presavesStaffList[id] = data.result.presaves;
          this.presavesTotal[id] = data.result.total;
          this.canEditContribution = data.can_edit_contribution;

          this.highlightedPresave[id] = "";
          this.focusedPresave[id] = "";
          this.focusedCompanyStaff[id] = "";
        } else this.error[id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
        this.isLoading[id] = false;
      });
    }
  };

  getPresaveForAregister = async (aregister: string) => {
    try {
      const data: ApiResponse<{
        [key: string]: PresaveForAregister;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPresaveForAregister",
        params: { module: "aregister", aregister }
      });

      runInAction(() => {
        if (data.code === 200) {
          this.allAllowedPresaves[aregister] = {};

          Object.values(data.result).forEach((presave) => {
            this.allAllowedPresaves[aregister][presave.id] = {
              ...presave,
              is_pinned: presave.is_pinned ? 1 : 0
            };
          });
        } else this.error[aregister] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[aregister] = true;
        this.isLoading[aregister] = false;
      });
    }
  };

  pinPresave = async (presave_id: string, aregister_id: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "pinPresave",
        body: {
          module: "aregister",
          presave: presave_id,
          aregister: aregister_id
        }
      });

      runInAction(() => {
        if (data.code !== 200) {
          this.error[aregister_id] = true;
          this.isLoading[aregister_id] = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[aregister_id] = true;
        this.isLoading[aregister_id] = false;
      });
    }
  };

  unpinPresave = async (presave_id: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "unpinPresave",
        body: {
          module: "aregister",
          presave: presave_id
        }
      });

      runInAction(() => {
        if (data.code !== 200) {
          this.error[this.selectedOne.id] = true;
          this.isLoading[this.selectedOne.id] = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.selectedOne.id] = true;
        this.isLoading[this.selectedOne.id] = false;
      });
    }
  };

  changePresavesConditions = (
    presave_diff: { [key: string]: { is_pinned: boolean } },
    aregister_id: string
  ) => {
    this.isLoading[aregister_id] = true;

    Object.entries(presave_diff).forEach(([presave_id, presave]) => {
      if (presave.is_pinned) {
        this.pinPresave(presave_id, aregister_id);
      } else {
        this.unpinPresave(presave_id);
      }
    });

    setTimeout(() => {
      Promise.all([
        this.getRecordPresaves(aregister_id),
        this.getRecordConditionalCubes(aregister_id),
        this.getRecordCloseErrors(aregister_id, "presaves"),
        this.getRecordCloseErrors(aregister_id, "cubes")
      ]).then(() => {
        runInAction(() => {
          this.isLoading[aregister_id] = false;
        });
      });
    }, 300);
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList",
          select: ["id", "title", "color"]
        });

      runInAction(() => {
        if (data.code === 200) {
          Object.values(data.result || {}).forEach((company) => {
            !this.selects.companies && (this.selects.companies = {});
            this.selects.companies[company.id] = company;
          });
        } else {
          this.selects.companies = {};
        }
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  // функция, проверяющая наличие горизонтального скролла для стилизации
  // первого и последнего столбца в таблице сотрудников в почасовках
  hasHorizontalScroll = () => {
    const menuStore = this.rootStore.menuStore;
    return menuStore.scrollbarRef?.current?.getScrollWidth() -
      menuStore.scrollbarRef?.current?.getClientWidth() >
      0
      ? true
      : false;
  };

  // функция, проверяющая наличие вертикального скролла для стилизации
  // первой и последней строки в таблице сотрудников в почасовках
  hasVerticalScroll = () => {
    const menuStore = this.rootStore.menuStore;
    return menuStore.scrollbarRef?.current?.getScrollHeight() -
      menuStore.scrollbarRef?.current?.getClientHeight() >
      0
      ? true
      : false;
  };

  setHighlightedPresave = (val: string) =>
    (this.highlightedPresave[this.selectedOne.id] = val);

  setFocusedPresave = (val: string) =>
    (this.focusedPresave[this.selectedOne.id] = val);

  setFocusedCompanyStaff = (val: string) =>
    (this.focusedCompanyStaff[this.selectedOne.id] = val);

  changePresaveContribution = async (
    presave_staff_id: string,
    contribution: number
  ) => {
    this.editContributionError[this.selectedOne.id] = {};
    try {
      const data: ApiResponse<Aregister> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "aregister",
          method: "changePresaveContribution",
          body: { module: "aregister", presave_staff_id, contribution }
        });

      runInAction(() => {
        if (data.message) {
          this.editContributionError[this.selectedOne.id] = {
            message: data.message,
            presave_staff_id
          };
        }
      });
    } catch (error) {
      this.error[this.selectedOne.id] = true;
    }
  };
}
