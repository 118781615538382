import { string, object } from "yup";

import { stringSchema, dateSchema } from "shared/utils/validation/validation";
import { numberSchema } from "shared/utils/validation/validationStaffForm";

const numberWithDot = stringSchema.test("", "Только число", (val) => {
  return val ? /^[0-9]*[.]?[0-9]+$/i.test(val) : true;
});

const validationAregisterAllAppcreate = {
  internal_num: stringSchema.test(
    "internal_num",
    "Некорректный номер",
    (val) => {
      return val ? +val[0] !== 0 : true;
    }
  ),
  date_start: dateSchema.test(
    "date_start",
    "Дата получения не может быть позже даты сдачи по акту",
    function (val) {
      if (this.parent.date_end && val) {
        return Boolean(this.parent.date_end >= val);
      } else return true;
    }
  ),
  date_end: dateSchema.test(
    "date_end",
    "Дата сдачи по акту не может быть ранее даты получения",
    function (val) {
      if (this.parent.date_start && val) {
        return Boolean(this.parent.date_start <= val);
      } else return true;
    }
  ),
  project: stringSchema,
  contracts: stringSchema,
  master: stringSchema,
  type_work: stringSchema,
  type: stringSchema,
  species: stringSchema,
  sub_work: stringSchema,
  measurements: numberSchema,
  length: numberWithDot,
  width: numberWithDot,
  height: numberWithDot,
  size: numberWithDot,
  act_hours: numberWithDot,
  manual_hours: numberSchema,
  manual_size: numberSchema,
  high_mark: numberSchema,
  curators: stringSchema,
  kc: stringSchema,
  no_kc: numberSchema,
  for_who: stringSchema,
  object_in_object: stringSchema,
  coordinates: stringSchema,
  brigadier: stringSchema,
  comment: stringSchema
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    if (!["custom_fields", "size"].includes(col)) {
      validationSchema[col] = validationAregisterAllAppcreate[col]
        ? validationAregisterAllAppcreate[col]
        : string().nullable();

      if (requiredCols.includes(col)) {
        // measurements - Размеры, поле не имеет значения, отвечает за группу полей для вычисления размеров
        // в tableParams это поле обязательно, что ломает валидацию
        // поэтому вместо measurements обязательным будет поле size - вычисленный объем
        if (col === "measurements") {
          validationSchema["size"] = validationAregisterAllAppcreate[
            "size"
          ].required("Поле обязательно для заполнения");
        } else {
          validationSchema[col] = validationSchema[col].required(
            "Поле обязательно для заполнения"
          );
        }
      }
    }
  });

  return object(validationSchema);
};
