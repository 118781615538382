import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

type AutoArrayFio = {
  value: string | null;
  name: string | null;
  surname: string | null;
  patronymic: string | null;
};

type AutoArrayAddress = {
  value: string | null;
  city: string | null;
  region: string | null;
};

type Result = {
  data: {
    value: string | null;
    name?: string | null;
    patronymic?: string | null;
    surname?: string | null;
    region_with_type?: string | null;
    city_with_type?: string | null;
  };
  unrestricted_value: string | null;
  value: string | null;
};

export default class staffDaDataStore {
  isLoadingAutoData = false;
  errorMessageAutoFio = "";
  errorMessageAutoAddress = "";
  autoArrayFio: AutoArrayFio[] = [];
  autoArrayAddress: AutoArrayAddress[] = [];
  error = false;

  setAutoArray = () => {
    runInAction(() => {
      this.autoArrayFio = [];
      this.autoArrayAddress = [];
      this.errorMessageAutoFio = "";
      this.errorMessageAutoAddress = "";
    });
  };

  getAutoData = async (method: string, param: string, value: string) => {
    runInAction(() => {
      this.isLoadingAutoData = true;
    });

    this.setAutoArray();
    const obj: { fio?: string; address?: string } = {};
    obj[param] = value;
    try {
      const data: {
        code: number;
        hash: string;
        result: Record<string, Result>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "apidadata",
        currentClass: "apidadata",
        method: method,
        params: obj
      });
      runInAction(() => {
        if (data["result"] && Object.values(data["result"]).length) {
          switch (param) {
            case "fio": {
              Object.values(data["result"]).forEach((item) => {
                this.autoArrayFio.push({
                  value: item["value"],
                  surname: item["data"]["surname"],
                  name: item["data"]["name"],
                  patronymic: item["data"]["patronymic"]
                });
              });
              break;
            }
            case "address": {
              Object.values(data["result"]).forEach((item) => {
                this.autoArrayAddress.push({
                  value: item["value"],
                  region: item["data"]["region_with_type"],
                  city: item["data"]["city_with_type"]
                });
              });
              break;
            }
          }
        } else {
          switch (param) {
            case "fio": {
              this.errorMessageAutoFio = "Ничего не найдено";
              break;
            }
            case "address": {
              this.errorMessageAutoAddress = "Ничего не найдено";
              break;
            }
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingAutoData = false;
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
