import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import BuildingOneWorkshiftForm from "../../../modules/BuildingModule/BuildingOne/BuildingOneWorkshiftForm/index";

import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

import Tabel from "shared/assets/images/menuIcons/iconDefault/Tabel.svg";
import TabelSelected from "shared/assets/images/menuIcons/iconSelected/Tabel.svg";

import BuildingOne from "shared/assets/images/menuIcons/iconDefault/BuildingOne.svg";
import BuildingOneSelected from "shared/assets/images/menuIcons/iconSelected/BuildingOne.svg";

import BuildingStaff from "shared/assets/images/menuIcons/iconDefault/BuildingStaff.svg";
import BuildingStaffSelected from "shared/assets/images/menuIcons/iconSelected/BuildingStaff.svg";

import StaffMovement from "shared/assets/images/menuIcons/iconDefault/StaffMovement.svg";
import StaffMovementSelected from "shared/assets/images/menuIcons/iconSelected/StaffMovement.svg";

import BuildingProp from "shared/assets/images/menuIcons/iconDefault/BuildingProp.svg";
import BuildingPropSelected from "shared/assets/images/menuIcons/iconSelected/BuildingProp.svg";

import AutoCreationShifts from "shared/assets/images/menuIcons/iconDefault/AutoCreationShifts.svg";
import AutoCreationShiftsSelected from "shared/assets/images/menuIcons/iconSelected/AutoCreationShifts.svg";

import AutoClosingShifts from "shared/assets/images/menuIcons/iconDefault/AutoClosingShifts.svg";
import AutoClosingShiftsSelected from "shared/assets/images/menuIcons/iconSelected/AutoClosingShifts.svg";

import BuildingImport from "shared/assets/images/menuIcons/iconDefault/BuildingImport.svg";
import BuildingImportSelected from "shared/assets/images/menuIcons/iconSelected/BuildingImport.svg";

import AddShift from "shared/assets/images/menuIcons/iconDefault/AddShift.svg";
import AddShiftSelected from "shared/assets/images/menuIcons/iconSelected/AddShift.svg";

import AddStatements from "shared/assets/images/menuIcons/iconDefault/AddStatements.svg";
import AddStatementsSelected from "shared/assets/images/menuIcons/iconSelected/AddStatements.svg";

import ApplicationsScaffolding from "shared/assets/images/menuIcons/iconDefault/ApplicationsScaffolding.svg";
import ApplicationsScaffoldingSelected from "shared/assets/images/menuIcons/iconSelected/ApplicationsScaffolding.svg";

import AllPremium from "shared/assets/images/menuIcons/iconDefault/AllPremium.svg";
import AllPremiumSelected from "shared/assets/images/menuIcons/iconSelected/AllPremium.svg";

// у иконок Ведомостей пока нет и не нужны иконки вида Selected
import PaymentsToPay from "shared/assets/images/menuIcons/iconDefault/PaymentsToPay.svg";
import PaymentsForDismissed from "shared/assets/images/menuIcons/iconDefault/PaymentsForDismissed.svg";

import { fileUrl } from "stores/utils/consts";

const BuildingSectionOne = () => {
  const {
    apiStore,
    menuStore,
    buildingOneStore,
    buildingOneAddWorkshiftFormStore
  } = useStores();

  const overview = [
    {
      text: "Табель",
      to: `/building/id=${menuStore.tabId}/timesheet`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneTimesheet",
      defaultIcon: Tabel,
      selectedIcon: TabelSelected
    },
    {
      text: "Объект",
      to: `/building/id=${menuStore.tabId}`,
      moduleName: "buildingOne",
      submoduleName: "buildingOne",
      defaultIcon: BuildingOne,
      selectedIcon: BuildingOneSelected
    },
    {
      text: "Сотрудники",
      to: `/building/id=${menuStore.tabId}/staff`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneStaff",
      defaultIcon: BuildingStaff,
      selectedIcon: BuildingStaffSelected
    },
    {
      text: "Кадровые перемещения",
      to: `/building/id=${menuStore.tabId}/staffmovements`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneStaffMovements",
      defaultIcon: StaffMovement,
      selectedIcon: StaffMovementSelected
    },
    {
      text: "Свойства объекта",
      to: `/building/id=${menuStore.tabId}/buildingproperties`,
      fullstack: `${fileUrl}/crm/building/?action=edit&id=${menuStore.tabId}&t=pos_price`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneBuildingProperties",
      defaultIcon: BuildingProp,
      selectedIcon: BuildingPropSelected
    },
    {
      text: "Автосоздание смен",
      to: `/building/workshiftautocreate`,
      fullstack: `${fileUrl}/crm/building/?id=${menuStore.tabId}&t=workshiftAutoCreate`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneWorkshiftAutoCreate",
      defaultIcon: AutoCreationShifts,
      selectedIcon: AutoCreationShiftsSelected
    },
    {
      text: "Автозакрытие смен",
      to: `/building/workshiftautoclose`,
      fullstack: `${fileUrl}/crm/building/?id=${menuStore.tabId}&t=workshiftAutoClose`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneWorkshiftAutoClose",
      defaultIcon: AutoClosingShifts,
      selectedIcon: AutoClosingShiftsSelected
    },
    {
      text: "Импорт/Экспорт данных",
      to: `/building/importstackstaff`,
      fullstack: `${fileUrl}/crm/building/?id=${menuStore.tabId}&t=importstackstaff`,
      moduleName: "buildingOne",
      submoduleName: "buildingOneImportstackstaff",
      defaultIcon: BuildingImport,
      selectedIcon: BuildingImportSelected
    }
  ];

  const actions = [
    {
      text: "Добавить смену",
      defaultIcon: AddShift,
      selectedIcon: AddShiftSelected,
      isActionButton: true,
      selectedActionButton: buildingOneAddWorkshiftFormStore.isOpenedFromMenu
    },

    {
      text: "Добавить заявку",
      to: "",
      moduleName: "",
      submoduleName: "",
      fullstack: buildingOneStore.getLinkForApplications("form"),
      defaultIcon: AddStatements,
      selectedIcon: AddStatementsSelected
    }
  ];

  const search = [
    {
      text: "Заявки",
      to: "",
      moduleName: "",
      submoduleName: "",
      defaultIcon: ApplicationsScaffolding,
      selectedIcon: ApplicationsScaffoldingSelected,
      fullstack: buildingOneStore.getLinkForApplications("list"),
      selectedActionButton: buildingOneAddWorkshiftFormStore.isOpenedFromMenu
    },

    {
      text: "Ведомости к выплате",
      to: "",
      moduleName: "salary",
      submoduleName: "paymentlist",
      defaultIcon: PaymentsToPay,
      selectedIcon: ErrorsSelected,
      fullstack: buildingOneStore.getLinkForApplications("paymentlist")
    },
    {
      text: "Премии",
      to: "",
      moduleName: "salary",
      submoduleName: "premium",
      defaultIcon: AllPremium,
      selectedIcon: AllPremiumSelected,
      fullstack: buildingOneStore.getLinkForApplications("premium")
    },
    {
      text: "Ведомости по уволенным",
      to: "",
      moduleName: "salary",
      submoduleName: "paymentlist_dismiss",
      defaultIcon: PaymentsForDismissed,
      selectedIcon: ErrorsSelected,
      fullstack: buildingOneStore.getLinkForApplications("paymentlist_dismiss")
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      {!apiStore.errorPage.includes(
        `${menuStore.tabId || "main"}_${menuStore.openedModule || "main"}_${
          menuStore.openedSubmodule || null
        }`
      ) ? (
        <MenuSection sectionTitle="Действия">
          {actions.map((item) => (
            <MenuButton
              key={item.text}
              to={item.to}
              text={item.text}
              defaultIcon={item.defaultIcon}
              selectedIcon={item.selectedIcon}
              moduleName={item.moduleName}
              submoduleName={item.submoduleName}
              fullstack={item["fullstack"]}
              isActionButton={item.isActionButton}
              selectedActionButton={item.selectedActionButton}
              onClick={() => {
                if (item.isActionButton && item.text === "Добавить смену") {
                  buildingOneAddWorkshiftFormStore.setIsOpenedFromMenu();
                }
              }}
            />
          ))}
        </MenuSection>
      ) : null}
      {!apiStore.errorPage.includes(
        `${menuStore.tabId || "main"}_${menuStore.openedModule || "main"}_${
          menuStore.openedSubmodule || null
        }`
      ) ? (
        <MenuSection sectionTitle="Поиск по объекту">
          {search.map((item) => (
            <MenuButton
              key={item.text}
              to={item.to}
              text={item.text}
              defaultIcon={item.defaultIcon}
              selectedIcon={item.selectedIcon}
              moduleName={item.moduleName}
              submoduleName={item.submoduleName}
              selectedActionButton={item.selectedActionButton}
              fullstack={item["fullstack"]}
            />
          ))}
        </MenuSection>
      ) : null}
      {buildingOneAddWorkshiftFormStore.isOpenedFromMenu ? (
        <BuildingOneWorkshiftForm />
      ) : null}
    </>
  );
};

export default observer(BuildingSectionOne);
