import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Comment } from "./types/Comment";

export default class CommentsStore {
  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  error: Record<string, boolean> = {};
  isLoading: Record<string, boolean> = {};
  comments: Record<string, Record<string, Comment>> = {};

  getComments = async (rid: string) => {
    try {
      const data: ApiResponse<Record<string, Comment>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          currentClass: "comments",
          method: "getComment",
          params: { rid }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.comments[rid] = data.result;
        } else {
          this.error[rid] = true;
        }
      });
    } catch (error) {
      runInAction(() => (this.error[rid] = true));
    } finally {
      runInAction(() => (this.isLoading[rid] = false));
    }
  };

  addComment = async (rid: string, comment: string, file: File) => {
    const formData = { rid, comment, "file[]": file };
    this.isLoading[rid] = true;

    try {
      const data: ApiResponse<never> & {
        comment: Comment;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "comments",
        method: "add",
        body: formData
      });

      if (!data["errors"]) {
        this.getComments(rid);
      } else {
        runInAction(() => (this.isLoading[rid] = false));
      }
    } catch (error) {
      runInAction(() => (this.isLoading[rid] = false));
    }
  };

  deleteComment = async (rid: string, comment_id: string) => {
    this.isLoading[rid] = true;

    try {
      const data: ApiResponse<never> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "comments",
        method: "delete",
        body: { comment_id }
      });

      if (!data["errors"]) {
        this.getComments(rid);
      } else {
        runInAction(() => (this.isLoading[rid] = false));
      }
    } catch (error) {
      runInAction(() => (this.isLoading[rid] = false));
    }
  };
}
