import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import BuildingsSelected from "shared/assets/images/menuIcons/iconSelected/Buildings.svg";

import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

import { fileUrl } from "stores/utils/consts";

const ProjectsSection = () => {
  const overview = [
    {
      text: "Проекты",
      to: "/projects/",
      moduleName: "projects",
      submoduleName: "main",
      defaultIcon: Buildings,
      selectedIcon: BuildingsSelected
    }
  ];

  const actions = [
    {
      text: "Расчёт объёма",
      to: "/projects/setSizeCalculation",
      fullstack: `${fileUrl}/crm/projects/?action=setSizeCalculation`,
      moduleName: "projects",
      submoduleName: "projectsSetSizeCalculation",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Текучка сотрудников",
      to: "/projects/setTurnoverParam",
      fullstack: `${fileUrl}/crm/projects/?action=setTurnoverParam`,
      moduleName: "projects",
      submoduleName: "projectsSetTurnoverParam",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Расчёт челдней",
      to: "/projects/setManDaysParam",
      fullstack: `${fileUrl}/crm/projects/?action=setManDaysParam`,
      moduleName: "projects",
      submoduleName: "projectsSetManDaysParam",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Объём заявок",
      to: "/projects/aregisterSize",
      fullstack: `${fileUrl}/crm/projects/?action=aregisterSize`,
      moduleName: "projects",
      submoduleName: "projectsAregisterSize",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  const settings = [
    {
      text: "Создать новый проект",
      to: "",
      moduleName: "projects",
      submoduleName: "projectsAdd",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Приоритеты",
      to: "/projects/setPriority",
      fullstack: `${fileUrl}/crm/projects/?action=setPriority`,
      moduleName: "projects",
      submoduleName: "projectsSetPriority",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Статусы",
      to: "/projects/setStatus",
      fullstack: `${fileUrl}/crm/projects/?action=setStatus`,
      moduleName: "projects",
      submoduleName: "projectsSetStatus",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Типы",
      to: "/projects/setTypes",
      fullstack: `${fileUrl}/crm/projects/?action=setTypes`,
      moduleName: "projects",
      submoduleName: "projectsSetTypes",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Направления работ",
      to: "/projects/setWorkDirection",
      fullstack: `${fileUrl}/crm/projects/?action=setWorkDirection`,
      moduleName: "projects",
      submoduleName: "projectsSetWorkDirection",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Настройки раздела">
        {settings.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};
export default ProjectsSection;
