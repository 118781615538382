import styles from "./aregisterOneStaff.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CardsList from "./CardsList";
import StaffTable from "./StaffTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const AregisterOneStaff = () => {
  const { id } = useParams();
  const { aregisterOneStore, menuStore } = useStores();

  useEffect(() => {
    aregisterOneStore.setSelectedAregister(id);

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("aregisterOne");
    menuStore.setOpenedSubmodule("aregisterOneStaff");
    menuStore.updateWindow({
      mainPath: `/aregister/id=${id}`,
      path: `/aregister/id=${id}/staff`
    });
  }, [id]);

  return (
    <div className={styles.container}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CardsList />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffTable />
      </ErrorBoundary>
    </div>
  );
};

export default observer(AregisterOneStaff);
