import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

type StaffCity = {
  id: string;
  idmodule?: number;
  ukey?: string;
  title: string;
  comment?: null | string;
  ordered?: null | string;
  custom: {
    type_region?: string;
    region?: string;
    type_district?: string;
    district?: string;
    type_city?: string;
    federal_district?: string;
  };
};

type ApiResponse = {
  nav: { [key: string]: number };
  result: { [key: string]: StaffCity } | -1;
};

export default class StaffCitiesStore {
  staffCities: Array<{ [key: string]: string }> = [];
  staffCitiesTableKeys: Array<string> = [];
  searchValue = "";

  on_page = 100;
  page = 1;
  max_page = 0;
  prev_page = 1;

  isLoading = false;
  error = false;
  errorMessage = "";

  actionButton = {
    0: {
      id: 1,
      title: "OK"
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  getStaffCities = async () => {
    this.errorMessage = "";
    runInAction(() => (this.isLoading = true));
    try {
      const response: ApiResponse = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        currentClass: "class_cities",
        method: "getList",
        on_page: this.on_page,
        page: this.page,
        where: {
          title: {
            value: this.searchValue,
            comparison: "LIKE"
          }
        }
      });

      if (response["nav"]) {
        runInAction(() => (this.max_page = response["nav"]["max_page"]));
      }

      if (response["result"] !== -1) {
        const cities = Object.values(response["result"]).map((item) => {
          return {
            id: item["id"],
            type_city: item["custom"]["type_city"],
            title: item["title"],
            type_region: item["custom"]["type_region"],
            region: item["custom"]["region"],
            type_district: item["custom"]["type_district"],
            district: item["custom"]["district"],
            federal_district: item["custom"]["federal_district"]
          };
        });

        runInAction(() => {
          if (this.staffCities.length) {
            this.staffCities.push(...cities);
          } else {
            this.staffCities = cities;
            this.staffCitiesTableKeys = Object.keys(cities[0]);
          }
          this.prev_page = this.page;
        });
      } else {
        this.staffCities = [];
        this.errorMessage = "Ничего не найдено";
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getData = async () => {
    this.staffCities = [];
    this.setPage(1);
    await this.getStaffCities();
    runInAction(() => {
      this.isLoading = false;
      this.error = false;
    });
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setPage = (value: number) => {
    this.page = value;
  };

  setError = (value: boolean) => {
    this.error = value;
  };

  addNewCity = (value: { [key: string]: string }) => {
    this.staffCities.unshift(value);
  };

  deleteCity = (idx: number) => {
    this.staffCities.splice(idx, 1);
  };
}
