import styles from "./projectsListTable.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";

import { Table } from "react-bootstrap";
import LoadedComponent from "widgets/LoadedComponent";
import ScrollButton from "shared/ui/Buttons/ScrollButton";
import StatusIcon from "shared/ui/StatusIcon";

import { isEmpty, without } from "lodash";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getValues } from "shared/utils/helpers/getValues";
import { Badge } from "shared/ui/Badge";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";

const ProjectsListTable = () => {
  const { menuStore, projectsListStore, filterStore } = useStores();

  const navigate = useNavigate();

  const navigateOneProject = (id: string) => {
    navigate(`./id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      projectsListStore.maxPage >= projectsListStore.page &&
      projectsListStore.page === projectsListStore.prevPage
    ) {
      projectsListStore.setPage(projectsListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  const changeOrderOrdered = (order: string) => {
    projectsListStore.setProjectsListOrder(order);

    if (order === projectsListStore.projectsListOrder) {
      projectsListStore.setProjectsListOrdered(
        projectsListStore.projectsListOrdered === "DESC" ? "ASC" : "DESC"
      );
    } else {
      projectsListStore.setProjectsListOrdered("DESC");
    }
    projectsListStore.setPage(1);
    projectsListStore.getProjectsData();
  };

  return (
    <div className={styles.table}>
      {projectsListStore.projectsTableCols &&
      !isEmpty(projectsListStore.selects) ? (
        <>
          <ScrollButton tableRef={tableRef} />
          <Table
            ref={tableRef}
            style={{
              marginTop: getTableMarginTop(
                filterStore.savedFiltersHeight,
                Boolean(
                  !projectsListStore.filters[
                    projectsListStore.downloadedFilter
                  ]?.["general"] || projectsListStore.searchValue
                ),
                filterStore.isOpenAdvancedSearch ||
                  filterStore.isOpenedSavedFilterOptions ||
                  filterStore.isOpenedColumnsOptions,
                browserName === "Firefox"
              ),
              transition: "0.2s"
            }}
          >
            <thead
              className={styles.thead}
              style={{
                top: getTHeadTop(
                  filterStore.savedFiltersHeight,
                  Boolean(
                    !projectsListStore.filters[
                      projectsListStore.downloadedFilter
                    ]?.["general"] || projectsListStore.searchValue
                  ),
                  filterStore.isOpenedSavedFilters && browserName === "Firefox"
                )
              }}
            >
              <tr className={styles.row}>
                {getEntries(projectsListStore.projectsTableCols).map(
                  ([name, { title }]) => {
                    if (
                      projectsListStore.projectsTableParams[name]
                        ?.show_default === "on"
                    ) {
                      return (
                        <th
                          key={name}
                          className={`${styles.title} ${
                            ["set_open", "to_mobile_app"].includes(name)
                              ? styles.wrapCell
                              : ""
                          }`}
                          onClick={() => changeOrderOrdered(name)}
                          id={name}
                        >
                          <div className="d-flex align-items-center">
                            <p className="m-0">{title}</p>
                            <StatusIcon
                              icon="iconarrowdouble"
                              color="bw-gray5"
                            />
                          </div>
                        </th>
                      );
                    }
                  }
                )}
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {projectsListStore.projectsList.length ? (
                projectsListStore.projectsList.map((oneOfProject, index) => {
                  return (
                    <tr
                      key={oneOfProject.id}
                      onClick={() => navigateOneProject(oneOfProject["id"])}
                      id={`ProjectsListTable_navigate_${oneOfProject["id"]}`}
                      className={`${styles.row} ${
                        !(index % 2) ? styles.row_gray1 : styles.row_white
                      } ${
                        projectsListStore.selects.status?.[oneOfProject.status]
                          ?.custom
                          ? styles[
                              `row_${
                                projectsListStore.selects.status[
                                  oneOfProject.status
                                ].custom.color
                              }`
                            ]
                          : ""
                      }`}
                    >
                      {getKeys(projectsListStore.projectsTableCols).map(
                        (title) => {
                          if (
                            projectsListStore.projectsTableParams[title]
                              ?.show_default === "on"
                          ) {
                            switch (title) {
                              case "construction":
                                return (
                                  <td key={`${oneOfProject.id}_${title}`}>
                                    {(() => {
                                      if (
                                        !oneOfProject[title] ||
                                        oneOfProject[title] === "NULL"
                                      ) {
                                        return (
                                          <p>
                                            {
                                              projectsListStore.selects
                                                .work_direction[
                                                oneOfProject.work_direction
                                              ]?.title
                                            }
                                          </p>
                                        );
                                      } else {
                                        return getValues(
                                          oneOfProject[title] as Record<
                                            string,
                                            string
                                          >
                                        ).map((construction) => {
                                          return (
                                            <p key={construction}>
                                              {construction}
                                            </p>
                                          );
                                        });
                                      }
                                    })()}
                                  </td>
                                );
                              case "set_open":
                              case "to_mobile_app":
                                return (
                                  <td
                                    key={`${oneOfProject.id}_${title}`}
                                    className={`${styles.wrapCell} ${styles.wrapCell_center}`}
                                  >
                                    <span>
                                      {oneOfProject[title] ? (
                                        <StatusIcon
                                          icon="bigcheck"
                                          color="accent-green"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </td>
                                );

                              case "company":
                                return (
                                  <td key={`${oneOfProject.id}_${title}`}>
                                    <div className={styles.chips}>
                                      {oneOfProject[title]
                                        ? getValues(oneOfProject[title]).map(
                                            (company) => {
                                              return (
                                                <Badge
                                                  key={`${oneOfProject.id}_${company}`}
                                                  text={
                                                    projectsListStore.selects[
                                                      title
                                                    ]?.[company].title
                                                  }
                                                  colorName={
                                                    projectsListStore.selects[
                                                      title
                                                    ]?.[company]?.["color"] ||
                                                    "primary"
                                                  }
                                                />
                                              );
                                            }
                                          )
                                        : ""}
                                    </div>
                                  </td>
                                );

                              case "users":
                                return (
                                  <td key={`${oneOfProject.id}_${title}`}>
                                    <div className={styles.chips}>
                                      {oneOfProject[title]
                                        ? getValues(oneOfProject[title]).map(
                                            (user) => {
                                              return (
                                                <Badge
                                                  key={`${oneOfProject.id}_${user}`}
                                                  text={String(user)}
                                                  colorName="primary"
                                                />
                                              );
                                            }
                                          )
                                        : ""}
                                    </div>
                                  </td>
                                );
                            }
                            return (
                              <td
                                key={`${oneOfProject.id}_${title}`}
                                className={
                                  title === "description"
                                    ? styles.description
                                    : ""
                                }
                              >
                                {projectsListStore.selects[title]?.[
                                  oneOfProject[title]
                                ] ? (
                                  <p>
                                    {
                                      projectsListStore.selects[title][
                                        oneOfProject[title]
                                      ].title
                                    }
                                  </p>
                                ) : typeof oneOfProject[title] !== "object" ? (
                                  <p>{oneOfProject[title] || ""}</p>
                                ) : (
                                  ""
                                )}
                              </td>
                            );
                          }
                        }
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr className={styles.error}>
                  <td
                    colSpan={
                      without(
                        getValues(projectsListStore.projectsTableCols),
                        ...getValues(
                          projectsListStore.projectsTableCols
                        ).filter(
                          (col) =>
                            !projectsListStore.projectsTableParams[col.newname]
                              ?.show_default
                        )
                      )?.length
                    }
                  >
                    {projectsListStore.errorMessage
                      ? projectsListStore.errorMessage
                      : ""}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      ) : (
        ""
      )}
      {projectsListStore.page !== 1 ? (
        <p className={styles.firstCol}>
          {projectsListStore.isLoading ? (
            <span className={styles.loading}>Пожалуйста, подождите</span>
          ) : (
            ""
          )}
        </p>
      ) : (
        <LoadedComponent
          isLoading={projectsListStore.isLoading}
          errorMessage={projectsListStore.errorMessage}
          actionButton={projectsListStore.actionButton}
        />
      )}
    </div>
  );
};

export default observer(ProjectsListTable);
