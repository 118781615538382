import Checkbox from "shared/ui/Inputs/Checkbox";
import RadioButton from "shared/ui/Inputs/RadioButton";
import { additional, category } from "shared/utils/dictionaries/statusDict";
import styles from "./additional.module.scss";

type AdditionalProps = {
  handleChange: (event: React.ChangeEvent<Element>) => void;
};

const Additional = ({ handleChange }: AdditionalProps) => {
  return (
    <section>
      <p>Дополнительные параметры</p>

      <div className="d-flex">
        <ul className={styles.list}>
          <li>Поля работника (На Объекте)</li>
          {Object.keys(additional).map((key) => {
            return (
              <li key={key}>
                <label className="d-flex align-items-center gap-2">
                  <Checkbox
                    name={`custom.cols.${key}`}
                    id={`custom.cols.${key}`}
                    value={key}
                    onChange={handleChange}
                  />
                  {additional[key]}
                </label>
              </li>
            );
          })}
        </ul>
        <ul className={styles.list}>
          <li>Категория статуса</li>
          {Object.keys(category).map((key) => {
            return (
              <li key={key}>
                <label className="d-flex align-items-center gap-2">
                  <RadioButton
                    name={`custom.category`}
                    value={key}
                    onChange={handleChange}
                  />
                  {category[key]}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Additional;
