import styles from "./buildingOneWorkshiftStaffHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import SearchInput from "shared/ui/Inputs/SearchInput";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import BuildingOneWorkshiftHeaderWorkshift from "./BuildingOneWorkshiftHeaderWorkshift";
import { Formik } from "formik";

const BuildingOneWorkshiftStaffHeader = () => {
  const { id } = useParams();
  const { buildingOneWorkshiftStore, menuStore } = useStores();

  const handleClickOnSaveBtn = () => {
    if (
      !buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom.done
    ) {
      buildingOneWorkshiftStore.editStatus(
        id,
        Object.values(buildingOneWorkshiftStore.selects.status).find(
          (type) => type.custom.done === "on"
        ).id,
        buildingOneWorkshiftStore.selectedOne.comment,
        true
      );
    }
    buildingOneWorkshiftStore.setOpenedMode(id, "show");
  };

  const handleClickOnAddBtn = () => {
    if (
      !buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom.done
    ) {
      buildingOneWorkshiftStore.editStatus(
        id,
        Object.values(buildingOneWorkshiftStore.selects.status).find(
          (type) => type.custom.done === "on"
        ).id,
        buildingOneWorkshiftStore.selectedOne.comment,
        true
      );
    }
    buildingOneWorkshiftStore.setOpenedMode(id, "show");
  };

  useEffect(() => {
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("workshiftOne");
    menuStore.setOpenedSubmodule("workshiftOneStaff");
    buildingOneWorkshiftStore.setSelectedWorkshiftForInfo(id, "/staff");
  }, [id]);

  return (
    <>
      {/* Данные смены с кнопками редактирования и экспорта */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BuildingOneWorkshiftHeaderWorkshift />
      </ErrorBoundary>

      {/* Строка поиска с кнопками сохранения/отмены */}
      <div className={styles.searchRow}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Formik
            initialValues={{
              searchWorkshiftStaffInputValue:
                buildingOneWorkshiftStore.searchValue[id] || ""
            }}
            onSubmit={() => null}
          >
            {({ setFieldValue }) => {
              const handleOnChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                const newValue = event.target.value.toLowerCase();
                setFieldValue("searchWorkshiftStaffInputValue", newValue);
                buildingOneWorkshiftStore.setSearchValue(id, newValue);
              };

              const handleClearInputValue = () => {
                setFieldValue("searchWorkshiftStaffInputValue", "");
                if (buildingOneWorkshiftStore.searchValue) {
                  buildingOneWorkshiftStore.setSearchValue(id, "");
                }
              };

              return (
                <SearchInput
                  name="searchWorkshiftStaffInputValue"
                  onChange={handleOnChange}
                  clearSearch={handleClearInputValue}
                  fixWidth
                  withoutBtn
                />
              );
            }}
          </Formik>
        </ErrorBoundary>
        {buildingOneWorkshiftStore.openedMode[id] === "edit" ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ButtonsGroupForSettings
              saveBtnTitle="Сохранить"
              saveBtnOnClick={handleClickOnSaveBtn}
              addBtnTitle="Отменить"
              addBtnOnClick={handleClickOnAddBtn}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    </>
  );
};

export default observer(BuildingOneWorkshiftStaffHeader);
