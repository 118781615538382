import { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { cloneDeep } from "lodash";

import PageTitle from "shared/ui/PageTitle";
import { Input } from "shared/ui/Inputs/Input";
import RadioButton from "shared/ui/Inputs/RadioButton";
import Select from "shared/ui/Inputs/Select";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import LoadedComponent from "widgets/LoadedComponent";

import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import iconAddDisabled from "shared/assets/images/mainIcons/iconAddDisabled.svg";

import { validationTypeAddress } from "./validation";

import styles from "./staffTypeAddress.module.scss";
import tableStyle from "shared/assets/styles/tableStyles/bigTableStyle.module.scss";
import { Address } from "stores/StaffModule/staffTypeAddress";
import { Button, ButtonTheme } from "shared/ui/Button";

const StaffTypeAddress = () => {
  const { menuStore, staffTypeAddressStore } = useStores();

  const [selectedType, setSelectedType] = useState("");
  const [isAddingNew, setIsAddingNew] = useState(false);

  const prevSelectedType = useRef("");

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      staffTypeAddressStore.max_page >= staffTypeAddressStore.page
    ) {
      staffTypeAddressStore.setPage(staffTypeAddressStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);
  // при разработке эту строку необходимо раскомментировать
  // useEffect(() => {
  //   prevSelectedType.current = "";
  // }, []);

  useEffect(() => {
    prevSelectedType.current = selectedType;
  }, [selectedType]);

  useEffect(() => {
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("typeAddress");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/typeAddress",
      title: "Сотрудники"
    });

    staffTypeAddressStore.getAddressTypes();

    return () => staffTypeAddressStore.clearAddressTypes();
  }, []);

  useEffect(() => {
    if (
      staffTypeAddressStore.page !== 1 &&
      staffTypeAddressStore.page <= staffTypeAddressStore.max_page
    ) {
      staffTypeAddressStore.getAddressTypes();
    }
  }, [staffTypeAddressStore.page, staffTypeAddressStore.max_page]);

  return (
    <>
      <PageTitle title="Типы адресов" leftCol />

      <Formik
        enableReinitialize
        initialValues={{
          types: staffTypeAddressStore.addressTypes,
          default: staffTypeAddressStore.defaultTypeAddress
        }}
        validationSchema={validationTypeAddress}
        validateOnBlur
        validateOnChange
        onSubmit={() => {
          return;
        }}
      >
        {({
          values,
          initialValues,

          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          resetForm
        }) => {
          const editRecord = (...data: Array<string>) => {
            const [action, idx] = data;
            switch (action) {
              case "cancel":
                {
                  setSelectedType("");
                  for (const key of staffTypeAddressStore.typeKeys) {
                    setFieldValue(
                      `types.${idx}.${key}`,
                      initialValues.types[idx][key]
                    );
                    setFieldTouched(`types.${idx}.${key}`, false);
                  }
                }
                break;
            }
          };

          const clearPrevValue = (array: Address[], id: string) => {
            const idx = array.findIndex(
              ({ id }) => id === prevSelectedType.current
            );
            if (idx !== -1 && id !== selectedType) {
              for (const key of staffTypeAddressStore.typeKeys) {
                setFieldValue(
                  `types.${idx}.${key}`,
                  initialValues.types[idx][key]
                );
                setFieldTouched(`types.${idx}.${key}`, false);
              }
            }
          };
          return (
            <Form className={styles.formContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th className={tableStyle.title}>По умолчанию</th>
                      <th className={tableStyle.title}>№</th>
                      <th className={tableStyle.title}>Заголовок</th>
                      <th className={tableStyle.title}>Комментарий</th>
                      <th className={tableStyle.title}>Тип</th>
                      <th className={tableStyle.title}></th>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {values.types.map((type, i, array) => (
                      <tr
                        key={type["id"]}
                        className={
                          type["id"] === selectedType || type["id"] === ""
                            ? styles.selected
                            : ""
                        }
                      >
                        <td>
                          <RadioButton
                            name="default"
                            value={type["id"]}
                            onChange={handleChange}
                            onFocus={() => clearPrevValue(array, type["id"])}
                            onBlur={handleBlur}
                          />
                        </td>
                        <td>
                          <Input
                            name={`types.${i}.ordered`}
                            label="№"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched(`types.${i}.ordered`);
                            }}
                            onBlur={handleBlur}
                            onClick={() => setSelectedType(type["id"])}
                            onFocus={() => clearPrevValue(array, type["id"])}
                            disabled
                          />
                        </td>
                        <td>
                          <Input
                            name={`types.${i}.title`}
                            label="Заголовок"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched(`types.${i}.title`);
                            }}
                            onBlur={handleBlur}
                            onClick={() => setSelectedType(type["id"])}
                            onFocus={() => clearPrevValue(array, type["id"])}
                          />
                        </td>
                        <td>
                          <Input
                            label="Комментарий"
                            name={`types.${i}.comment`}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched(`types.${i}.comment`);
                            }}
                            onBlur={handleBlur}
                            onClick={() => setSelectedType(type["id"])}
                            onFocus={() => clearPrevValue(array, type["id"])}
                          />
                        </td>
                        <td>
                          <Select
                            key={type["id"]}
                            title="Тип"
                            label={type["title"]}
                            name={`types.${i}.custom.type`}
                            options={values.types.reduce((acc, type) => {
                              return { ...acc, ["title"]: type["title"] };
                            }, {})}
                            onClick={() => {
                              setSelectedType(type["id"]);
                            }}
                            onBlur={handleBlur}
                            onFocus={() => clearPrevValue(array, type["id"])}
                            isFloating={false}
                          />
                        </td>
                        <td className={styles.btns}>
                          {/* Исправить цвета при разработке */}
                          <ButtonsGroupForEdit
                            id={type["id"]}
                            idx={i}
                            btns={
                              type["id"] && type["id"] === selectedType
                                ? [
                                    {
                                      action: "submit",
                                      type: "button",
                                      icon: "bigcheck",
                                      color: "",
                                      disabled: true
                                    },
                                    {
                                      action: "cancel",
                                      type: "button",
                                      icon: "iconclose",
                                      color: "",
                                      disabled: false
                                    },
                                    {
                                      action: "delete",
                                      type: "button",
                                      icon: "iconbasket",
                                      color: "",
                                      disabled: true
                                    }
                                  ]
                                : !type["id"]
                                ? [
                                    {
                                      action: "submit",
                                      type: "button",
                                      icon: "bigcheck",
                                      color: "",
                                      disabled: true
                                    },
                                    {
                                      action: "cancel",
                                      type: "button",
                                      icon: "iconclose",
                                      color: "",
                                      disabled: false
                                    }
                                  ]
                                : [
                                    {
                                      action: "delete",
                                      type: "button",
                                      icon: "iconbasket",
                                      color: "",
                                      disabled: true
                                    }
                                  ]
                            }
                            onClick={() => editRecord()}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {staffTypeAddressStore.page !== 1 ? (
                  <p className="w-100 text-center">
                    {staffTypeAddressStore.error ? (
                      staffTypeAddressStore.errorMessage
                    ) : staffTypeAddressStore.isLoading ? (
                      <p className={styles.loading}>Пожалуйста, подождите</p>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <LoadedComponent
                    isLoading={staffTypeAddressStore.isLoading}
                    error={staffTypeAddressStore.error}
                  ></LoadedComponent>
                )}
              </div>

              <div>
                <Button
                  theme={ButtonTheme.FLOATING}
                  onClick={() => {
                    setIsAddingNew(true);
                    setSelectedType("new");
                    const copy = cloneDeep(values.types[0]);
                    staffTypeAddressStore.addNewTypeAddress(copy);
                    resetForm({});
                  }}
                  disabled={isAddingNew}
                >
                  {!isAddingNew ? (
                    <IconAdd fill="white" />
                  ) : (
                    <img src={iconAddDisabled} />
                  )}
                  Добавить
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default observer(StaffTypeAddress);
