import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStores } from "stores/index";

import styles from "./routerComponent.module.scss";

import { IScrollBars } from "stores/utils/types/ScrollBars";
import LoadedComponent from "widgets/LoadedComponent";
import { Route, Routes, useLocation } from "react-router-dom";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { routerConfig } from "../config/routeConfig";
import RouterComponentButton from "./RouterComponentButton";
import { maxHeight } from "shared/utils/constants/maxHeight";
import { headerHeight } from "shared/utils/constants/headerHeight";

const renderThumbHorizontal = (props: JSX.IntrinsicElements["div"]) => {
  return <div {...props} className={styles.thumbHorizontal} />;
};

const RouterComponent = () => {
  const { userStore, menuStore } = useStores();
  const { pathname } = useLocation();
  const scrollbars = useRef<IScrollBars>();

  useEffect(() => {
    switch (window.location.pathname.split("/")[1]) {
      case "main":
      case "":
        document.title = "Главная | Полати";
        break;
      default:
        Object.values(menuStore.modulesList).forEach((module) => {
          if (module.dir === window.location.pathname.split("/")[1])
            document.title = `${module.title} | Полати`;
        });
    }
  }, [pathname]);

  useEffect(() => {
    // если данные юзера/формата даны/топ-меню и списка виджетов сотрудника загружены
    if (!userStore.isLoadingAccessKey) {
      // то вместе с рендером приложения уставливаем настройки скролла
      menuStore.setScrollValue(scrollbars);
      menuStore.isScroll || menuStore.isScrollBottom
        ? menuStore.setScroll(scrollbars.current.getValues())
        : "";

      menuStore.setIsFirstScrollRender(true);

      // и проверяем, является полученый токен валиндным
      window.addEventListener("storage", () => {
        userStore.handleInvalidToken;
      });
      return function cleanup() {
        window.removeEventListener("storage", () => {
          userStore.handleInvalidToken;
        });
      };
    }
  }, [userStore.isLoadingAccessKey]);

  useEffect(() => {
    // если isFirstScrollRender === true, то есть когда проиходит первый рендер
    // нужно подтянуть данные скролла. сделать это можно только поскроллив страницу
    // поэтому при первом рендере страницы мы принудительно незаметно скроллим страницу
    // это нужно для того, чтобы отображать кнопки горизонтального скролла в таблицах
    // (и любых других, зависящих от скролла компонентов)
    if (menuStore.isFirstScrollRender && scrollbars.current) {
      setTimeout(() => scrollbars.current.view.scroll({ top: 2 }), 1000);
      scrollbars.current.view.scroll({ top: 0 });
      menuStore.setIsFirstScrollRender(false);
    }
  }, [menuStore.isFirstScrollRender]);

  const handleScroll = (e: positionValues) => {
    if (e.top > 0.7 && !menuStore.isScrollBottom) {
      menuStore.setIsScrollBottom(!menuStore.isScrollBottom);
    } else if (e.top < 0.7 && menuStore.isScrollBottom) {
      menuStore.setIsScrollBottom(false);
    }

    if (e.scrollTop > 200 && !menuStore.isScroll) {
      menuStore.setIsScroll(true);
    } else if (e.scrollTop < 200 && menuStore.isScroll) {
      menuStore.setIsScroll(false);
    }

    menuStore.setScroll(e);
  };

  return (
    <LoadedComponent isLoading={userStore.isLoadingAccessKey}>
      {userStore.accessKey ? (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[location.href]}
          >
            <Scrollbars
              ref={scrollbars}
              className={styles.scrollbars}
              onScrollFrame={handleScroll}
              style={{
                width: "100%",
                height: `calc(${maxHeight} - ${headerHeight})`
              }}
              autoHide
              autoHideTimeout={3000}
              autoHideDuration={200}
              renderThumbHorizontal={renderThumbHorizontal}
            >
              <Routes>
                {routerConfig.map(({ path, element }) => {
                  return (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <div className={styles.windowContainer}>{element}</div>
                      }
                    />
                  );
                })}
              </Routes>
            </Scrollbars>
          </ErrorBoundary>
          <RouterComponentButton pathname={pathname} />
        </>
      ) : null}
    </LoadedComponent>
  );
};

export default observer(RouterComponent);
