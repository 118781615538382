import {
  stringWithPuncAndLimit,
  stringWithNumAndPuncAndLimit,
  stringSchema,
  commentSchema
} from "shared/utils/validation/validation";
import * as yup from "yup";
import { getYear } from "date-fns";
import { isEmpty } from "lodash";
import {
  phoneSchema,
  pastDate,
  validationStaffForm
} from "shared/utils/validation/validationStaffForm";
import { object, array, string } from "yup";

const validationAddNewStaffSchema = {
  ...validationStaffForm,
  company: array().test("isEmpty", "Поле обязательно для заполнения", (val) => {
    return !isEmpty(val);
  }),
  surname: stringWithPuncAndLimit,
  name: stringWithPuncAndLimit,
  patronymic: stringWithPuncAndLimit,
  autoFIO: stringWithPuncAndLimit,
  phone: phoneSchema,
  comment: commentSchema,
  pasp_place: stringWithNumAndPuncAndLimit,
  birth_place: stringWithPuncAndLimit,
  propisk: stringWithNumAndPuncAndLimit,
  living_adress: stringWithNumAndPuncAndLimit,
  pasp_date: pastDate("pasp_date"),
  education: stringWithPuncAndLimit,
  conviction: array().of(
    object({
      article: stringSchema,
      date_start: yup.date(),
      punishment: stringSchema,
      term_type: stringSchema,
      comment: stringSchema
    })
  ),
  edu: object({
    date_start: yup.date(),
    ws_num: stringWithNumAndPuncAndLimit,
    money: stringWithNumAndPuncAndLimit,
    comment: commentSchema
  })
};

export const getValidationSchema = (
  cols: string[],
  requiredCols: string[],
  isConvictionField: boolean,
  isEduField: boolean
) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = validationAddNewStaffSchema[col]
      ? validationAddNewStaffSchema[col]
      : string().nullable();

    if (requiredCols.includes(col) && col !== "uid") {
      validationSchema[col] = validationAddNewStaffSchema[col]
        ? validationAddNewStaffSchema[col]?.required(
            "Поле обязательно для заполнения"
          )
        : string().nullable()?.required("Поле обязательно для заполнения");
    }

    if (isConvictionField) {
      validationSchema["conviction"] = array().of(
        object({
          article: stringSchema.required("Поле обязательно для заполнения"),
          date_start: yup
            .date()
            .typeError("Некорректный формат даты")
            .required("Поле обязательно для заполнения")
            .max(new Date(), "Дата получения не может быть больше текущей даты")
            .min(
              getYear(new Date()) - 70 + 14,
              `Дата получения не может быть раньше ${
                getYear(new Date()) - 70 + 14
              } года`
            )
            .nullable(),
          punishment: stringSchema,
          term_type: stringSchema,
          comment: stringSchema
        })
      );
    }
    if (isEduField) {
      validationSchema["edu"] = object({
        date_start: yup
          .date()
          .typeError("Некорректный формат даты")
          .required("Поле обязательно для заполнения"),
        ws_num: stringWithNumAndPuncAndLimit,
        money: stringWithNumAndPuncAndLimit,
        comment: commentSchema
      });
    }
  });

  return object(validationSchema);
};
