import styles from "./submenu.module.scss";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import iconSortVertical from "shared/assets/images/mainIcons/iconSort/iconSortVertical.svg";
import iconSortBlue from "shared/assets/images/mainIcons/iconSort/iconSortBlue.svg";
import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

import StatusIcon from "shared/ui/StatusIcon";

type PlaceholderProps = {
  clientX?: number;
  clientY?: number;
  clientHeight?: number;
  clientWidth?: number;
};

type Item = {
  filterdata?: {
    check_col?: string;
    cols?: string[];
    custom?: { [key: string]: string };
    ordered: number | string;
  };
  title: string;
  id?: string | number;
  ordered?: string | number;
  icon?: ReactJSXElement | string;
};

type SubmenuProps = {
  array: Item[];
  arrayItemOrdered?: string;
  arrayItemId: string;
  arrayItemTitle: string;
  arrayItemObject?: string;
  updateArray?: (array: Item[]) => void;
  selectedItemColor?: string;
  selectedItem: Partial<Item>;
  setSelectedItem: (value: string) => void;
  newItem?: string;
  funcForNewItem?: () => void;
  funcForItem?: () => void;
  iconSortImg?: boolean;
  title: string;
  withIcons?: boolean;
  notHover?: boolean;
};

const reorder = (list: Item[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Submenu = ({
  array,
  arrayItemOrdered,
  arrayItemId,
  arrayItemTitle,
  arrayItemObject,
  updateArray,
  selectedItem,
  newItem,
  setSelectedItem,
  selectedItemColor,
  funcForNewItem,
  funcForItem,
  iconSortImg,
  title,
  withIcons,
  notHover
}: SubmenuProps) => {
  const [movingItems, setMovingItems] = useState(false);
  const [listItems, updateListItems] = useState<Item[]>([...array]);
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>(
    {}
  );

  useEffect(() => {
    updateListItems([...array]);
  }, [array]);

  const handleDragStart = (event: {
    draggableId: string;
    source: { index: number };
  }) => {
    const draggedDOM: Element = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentElement).paddingTop) +
      Array.from(draggedDOM.parentNode.children)
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);
    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentElement).paddingLeft
      )
    });
  };

  const handleDragEnd = (result) => {
    setPlaceholderProps({});
    if (!result.destination) {
      return;
    }

    const items: Item[] = reorder(
      listItems,
      result.source.index,
      result.destination.index
    );

    updateListItems(items);
    updateArray([...items]);
  };

  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = Array.from(draggedDOM.parentNode.children);
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1)
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentElement).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentElement).paddingLeft
      )
    });
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${"data-rbd-drag-handle-draggable-id"}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  return (
    <div className={styles.menuWrapper}>
      <div className={iconSortImg ? styles.menuHeaderIcon : styles.menuHeader}>
        {iconSortImg ? (
          <img
            src={movingItems ? iconSortBlue : iconSortVertical}
            alt="sort"
            className={styles.iconSort}
            onClick={() => setMovingItems(!movingItems)}
            id="submenu_toggleMoveItems"
          />
        ) : null}
        <p>{title}</p>
      </div>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <Droppable droppableId="droppable" isDropDisabled={!movingItems}>
          {(provided, snapshot) => (
            <div
              className={
                selectedItemColor === "gray" ? styles.grayMenu : styles.menu
              }
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listItems.map((item, index) => {
                return (
                  <Draggable
                    key={item[arrayItemId]}
                    draggableId={item[arrayItemId]}
                    index={index}
                    isDragDisabled={!movingItems}
                  >
                    {(provided) => (
                      <div
                        key={item[arrayItemId]}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => {
                          setSelectedItem(item[arrayItemId]);
                          funcForItem && funcForItem();
                        }}
                        id={`submenu_setSelectedItem_${item[arrayItemId]}`}
                        className={`${
                          movingItems
                            ? styles.movItem
                            : selectedItemColor === "gray"
                            ? styles.grayItem
                            : notHover
                            ? styles.notHoverItem
                            : styles.item
                        } ${
                          item[arrayItemId] === selectedItem[arrayItemId]
                            ? selectedItemColor === "gray"
                              ? styles.graySelected
                              : styles.selected
                            : ""
                        }`}
                      >
                        <p
                          className={
                            movingItems ? styles.movNumber : styles.number
                          }
                        >
                          {arrayItemOrdered && arrayItemObject
                            ? item[arrayItemObject][arrayItemOrdered]
                            : item[arrayItemOrdered]}
                        </p>
                        {withIcons &&
                          (typeof item["icon"] === "string" ? (
                            <StatusIcon
                              icon={item["icon"].toLowerCase()}
                              color={
                                item[arrayItemId] === selectedItem[arrayItemId]
                                  ? "blue-lazure"
                                  : "bw-gray5"
                              }
                            />
                          ) : (
                            item["icon"]
                          ))}
                        <p
                          className={
                            movingItems ? styles.movingTitle : styles.title
                          }
                        >
                          {item[arrayItemTitle]}
                        </p>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <div
                  className={styles.placeholder}
                  style={{
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth
                  }}
                />
              )}
              {funcForNewItem && (
                <div
                  id="submenu_addNewItem"
                  className={`${styles.addBtn} ${
                    newItem ? styles.selected : ""
                  }`}
                  onClick={funcForNewItem}
                  key="newItem"
                >
                  Добавить
                  <IconAdd fill="transparent" />
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Submenu;
