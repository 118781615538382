import styles from "./salaryPaymentlistAllTable.module.scss";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { useStores } from "stores";
import { Link, useNavigate } from "react-router-dom";

import { browserName } from "react-device-detect";
import StatusIcon from "shared/ui/StatusIcon";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

//hardcode
const icons = {
  "68770fbae852d14b8870f25cfc371ea669c978e4": { icon: "coins" }, //статус Выплачено
  "8d1fad4ef2f2641fe5f4af6fec90a049a4445300": {
    icon: "like",
    color: "purple"
  }, //статус Подтверждено РП
  e319f656830eef40081824c33b6cb92f8cf516e7: {
    icon: "circlecheck",
    color: "teal"
  }, //статус Создано
  "2f49fbc76296047cbf62f666d027df00adc00b64": {
    icon: "crane",
    color: "deep-purple"
  }, //Ведомости по объектам
  f75c1991700f6bfd9941281f02366804ec1814c2: {
    icon: "persondelete",
    color: "danger"
  } //Ведомости по уволенным
};

const SalaryPaymentlistAllTable = () => {
  const { menuStore, salaryPaymentlistAllStore, filterStore } = useStores();
  const navigate = useNavigate();
  const navigateSalaryPaymentOne = (id: string) => {
    navigate(`./id=${id}`);
  };

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      salaryPaymentlistAllStore.maxPage >= salaryPaymentlistAllStore.page &&
      salaryPaymentlistAllStore.page === salaryPaymentlistAllStore.prevPage
    ) {
      salaryPaymentlistAllStore.setPage(salaryPaymentlistAllStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  const handleClickNav =
    (id: string) =>
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (
        event.target["dataset"].field === "tn" ||
        ["circle", "rect", "path", "svg"].includes(event.target["localName"])
      )
        return;
      navigateSalaryPaymentOne(id);
    };

  return Object.values(salaryPaymentlistAllStore.salaryPaymentTableCols)
    .length && salaryPaymentlistAllStore.currentTitles.length ? (
    <Table
      style={{
        marginTop: getTableMarginTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !salaryPaymentlistAllStore.filters[
              salaryPaymentlistAllStore.downloadedFilter
            ]?.["general"] || salaryPaymentlistAllStore.searchValue
          ),
          filterStore.isOpenAdvancedSearch ||
            filterStore.isOpenedSavedFilterOptions ||
            filterStore.isOpenedColumnsOptions,
          browserName === "Firefox"
        ),
        transition: "0.2s"
      }}
    >
      <thead
        className={styles.thead}
        style={{
          top: getTHeadTop(
            filterStore.savedFiltersHeight,
            Boolean(
              !salaryPaymentlistAllStore.filters[
                salaryPaymentlistAllStore.downloadedFilter
              ]?.["general"] || salaryPaymentlistAllStore.searchValue
            ),
            filterStore.isOpenedSavedFilters && browserName === "Firefox"
          )
        }}
      >
        <tr>
          {salaryPaymentlistAllStore.currentTitles.map((title) => (
            <th key={title} className={styles.title}>
              <div className="d-flex align-items-center">
                <p className="m-0">
                  {
                    salaryPaymentlistAllStore.salaryPaymentTableCols[title]
                      .title
                  }
                </p>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {salaryPaymentlistAllStore.paymentList.length ? (
          Object.values(salaryPaymentlistAllStore.paymentList).map(
            (payment, index) => (
              <tr
                key={payment.id}
                id={`SalaryPaymentlistAllTable_oneOFPayment_${payment.id}`}
                className={index % 2 === 0 ? styles.greyRow : ""}
                onClick={handleClickNav(payment.id)}
              >
                {salaryPaymentlistAllStore.currentTitles.map((title) => {
                  switch (title) {
                    case "date_start":
                    case "date_end":
                      return (
                        <td key={title}>
                          <p>{getFormattedDate(payment[title])}</p>
                        </td>
                      );
                    case "type":
                    case "status":
                      return (
                        <td key={title}>
                          <div className={styles.statusCell}>
                            <StatusIcon
                              icon={icons[payment[title]]?.["icon"]}
                              color={
                                salaryPaymentlistAllStore.selects[title]?.[
                                  payment[title]
                                ]?.custom?.color
                              }
                            />
                            <span>
                              {
                                salaryPaymentlistAllStore.selects[title]?.[
                                  payment[title]
                                ]?.title
                              }
                            </span>
                          </div>
                        </td>
                      );
                    case "object":
                      return (
                        <td key={title}>
                          <Link
                            to={`/building/id=${payment[title]?.["id"]}`}
                            id={`SalaryPaymentlistAllTable_goToObject_${payment.id}`}
                            target="_blank"
                            className={styles.link}
                          >
                            {payment[title]?.title}
                          </Link>
                        </td>
                      );
                    case "staff":
                      return payment[title]?.["name"]?.length ? (
                        <td key={title}>
                          <Link
                            to={`/staff/id=${payment[title]?.["id"]}`}
                            id={`SalaryPaymentlistAllTable_goToStaff_${payment.id}`}
                            target="_blank"
                            className={styles.link}
                          >
                            {`${payment[title]?.surname} ${
                              payment[title]?.name
                            }${
                              payment[title]?.patronymic
                                ? " " + payment[title].patronymic
                                : ""
                            }`}
                          </Link>
                        </td>
                      ) : (
                        <td key={title}>
                          <p>{payment[title]?.["name"]}</p>
                        </td>
                      );
                    case "id":
                      return;
                    default:
                      return (
                        <td key={title}>
                          <p>{payment[title]}</p>
                        </td>
                      );
                  }
                })}
              </tr>
            )
          )
        ) : (
          <tr>
            <td className={styles.withoutPayment}>Ничего не найдено</td>
          </tr>
        )}
      </tbody>
    </Table>
  ) : null;
};

export default observer(SalaryPaymentlistAllTable);
