import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";
import Tooltip from "shared/ui/Tooltip";

const AregisterSection = () => {
  const { menuStore } = useStores();

  const overview = [
    {
      text: "Реестр заявок строительных лесов",
      to: "/aregister/",
      moduleName: "aregister",
      submoduleName: "main",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Отчёт мастера за смену",
      to: "/aregister/report",
      moduleName: "aregister",
      submoduleName: "report",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Справка",
      to: "/aregister/reference",
      moduleName: "aregister",
      submoduleName: "reference",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  const actions = [
    {
      text: "Создать заявку",
      to: "/aregister/appcreate",
      moduleName: "aregister",
      submoduleName: "appcreate",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) =>
          item["to"] === "/aregister/reference" ? (
            <Tooltip
              placement="right-start"
              color="blue-lazure"
              text={`Раздел "${item["text"]}" находится в разработке`}
              key={item["text"]}
            >
              <div>
                <MenuButton
                  to={item["to"]}
                  text={item["text"]}
                  defaultIcon={item["defaultIcon"]}
                  selectedIcon={item["selectedIcon"]}
                  moduleName={"aregister"}
                  submoduleName={item["submoduleName"]}
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <MenuButton
              key={item["text"]}
              to={item["to"]}
              text={item["text"]}
              defaultIcon={item["defaultIcon"]}
              selectedIcon={item["selectedIcon"]}
              moduleName={item["moduleName"]}
              submoduleName={item["submoduleName"]}
              onClick={() => {
                !Object.keys(menuStore.scrollSavedPosition).length
                  ? menuStore.setScrollPosition(menuStore.scroll)
                  : menuStore.setScrollPosition(menuStore.scrollSavedPosition);

                menuStore.scrollbarRef.current?.view?.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
            />
          )
        )}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((action) => (
          <MenuButton
            key={action["text"]}
            to={action["to"]}
            text={action["text"]}
            defaultIcon={action["defaultIcon"]}
            selectedIcon={action["selectedIcon"]}
            moduleName={action["moduleName"]}
            submoduleName={action["submoduleName"]}
            onClick={() => {
              !Object.keys(menuStore.scrollSavedPosition).length
                ? menuStore.setScrollPosition(menuStore.scroll)
                : menuStore.setScrollPosition(menuStore.scrollSavedPosition);

              menuStore.scrollbarRef.current?.view?.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }}
          />
        ))}
      </MenuSection>
    </>
  );
};
export default observer(AregisterSection);
