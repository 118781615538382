import styles from "./header.module.scss";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { useStores } from "stores/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import HeaderModulesMenu from "./HeaderModulesMenu";
import HeaderTopMenu from "./HeaderTopMenu";

import AllEmployees from "shared/assets/images/menuIcons/iconDefault/AllEmployees.svg";
import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import SafetyWork from "shared/assets/images/menuIcons/iconDefault/Identity.svg";

import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { ReactComponent as IconBurger } from "shared/assets/images/mainIcons/iconBurger.svg";

import StatusIcon from "shared/ui/StatusIcon";

const Header = () => {
  const { menuStore, buildingOneTimesheetStore } = useStores();
  const navigate = useNavigate();

  const [hoveredTab, setHoveredTab] = useState("");

  // объект с ссылками на функции очистки данных подмодулей
  // ключ -- имя модуля
  // очищать нужно данные со всех разделов в боковом меню
  const clearTabs = {
    building: (id: string) => {
      // TODO: добавить ниже очистку карточки объекта, сотрудников и кадровых перемещений
      buildingOneTimesheetStore.clearBuildingData(id);
    }
  };

  const closeSubmodule = (mainPath: string, prePath: string) => {
    navigate(prePath);
    clearTabs[menuStore.openedModule] &&
      clearTabs[menuStore.openedModule](menuStore.tabId);
    menuStore.deleteWindow(mainPath);
    menuStore.setSelectedWindow(prePath);
  };

  const modulesIcons = {
    staff: AllEmployees,
    building: Buildings,
    projects: Buildings,
    safetywork: SafetyWork,
    default: AllEmployees
  };

  return (
    <div className={styles.header}>
      <button
        className={
          menuStore.selectedWindow !== "all"
            ? styles.mainHeaderBtn
            : styles.mainHeaderBtn_selected
        }
        onClick={() => {
          menuStore.selectedWindow !== "all"
            ? menuStore.setSelectedWindow("all")
            : menuStore.setSelectedWindow(location.pathname);
        }}
        id="Header_openAllModulesBtn"
        onKeyUp={(event) => {
          if (event.key === "Escape") {
            if (
              location.pathname &&
              location.pathname !== "/main" &&
              location.pathname !== "/main/" &&
              location.pathname !== "/"
            ) {
              menuStore.setSelectedWindow(location.pathname);
            }
          }
        }}
      >
        <IconBurger />
      </button>
      {Object.values(menuStore.allWindows).length ? (
        <div
          className={`${
            menuStore.selectedWindow ===
            Object.values(menuStore.allWindows)[0]["path"]
              ? styles.headerBtn_selected
              : styles.headerBtn
          } ${styles.headerBtn_module} ${
            menuStore.selectedWindow ===
              Object.values(menuStore.allWindows)[1]?.["path"] ||
            hoveredTab === Object.values(menuStore.allWindows)[1]?.["path"]
              ? styles.headerBtn_withoutAfter
              : ""
          }`}
        >
          <Link
            to={Object.values(menuStore.allWindows)[0]["path"]}
            onClick={() => {
              menuStore.setSelectedWindow(
                Object.values(menuStore.allWindows)[0]["path"]
              );
            }}
          >
            <Fragment>
              <img
                src={
                  modulesIcons[Object.keys(menuStore.allWindows)[0].slice(1)]
                    ? modulesIcons[
                        Object.keys(menuStore.allWindows)[0].slice(1)
                      ]
                    : modulesIcons.default
                }
                alt={Object.keys(menuStore.allWindows)[0]}
              />

              <span>{Object.values(menuStore.allWindows)[0]["title"]}</span>
            </Fragment>
          </Link>
          <div
            className={styles.iconClose}
            id={`Header_closeTab_${
              Object.values(menuStore.allWindows)[0]["path"]
            }`}
            onClick={() => {
              menuStore.closeAll();
              navigate("/main");
            }}
          >
            <StatusIcon icon="iconclosesmall" color="bw-gray5" />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.headerTabs}>
        {Object.entries(menuStore.allWindows).map(([mainPath, value], i) => {
          const length = Object.entries(menuStore.allWindows).length;
          if (i === 0) return;
          return (
            <div
              className={`${
                menuStore.selectedWindow === value.path
                  ? styles.headerBtn_selected
                  : styles.headerBtn
              } ${
                i !== Object.values(menuStore.allWindows).length - 1 &&
                (hoveredTab ===
                  Object.values(menuStore.allWindows)[i + 1]?.["path"] ||
                  menuStore.selectedWindow ===
                    Object.values(menuStore.allWindows)[i + 1]?.["path"])
                  ? styles.headerBtn_withoutAfter
                  : ""
              }`}
              key={mainPath}
              onMouseEnter={() => setHoveredTab(value.path)}
              onMouseLeave={() => setHoveredTab("")}
            >
              <Link
                to={value.path}
                onClick={() => {
                  menuStore.setSelectedWindow(value.path);
                  !Object.keys(menuStore.scrollSavedPosition).length
                    ? menuStore.setScrollPosition(menuStore.scroll)
                    : menuStore.setScrollPosition(
                        menuStore.scrollSavedPosition
                      );

                  menuStore.scrollbarRef.current?.view?.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                  });
                }}
              >
                {value.title}
              </Link>
              <div
                className={styles.iconClose}
                id={`Header_closeTab_${value.path}`}
                onClick={() => {
                  closeSubmodule(
                    mainPath,
                    length - 1 === i
                      ? Object.entries(menuStore.allWindows)[i - 1][1].path
                      : Object.entries(menuStore.allWindows)[i + 1][1].path
                  );

                  Object.entries(menuStore.allWindows).forEach(
                    ([key, value]) => {
                      if (value.parent_path && value.parent_path === mainPath) {
                        menuStore.deleteWindow(key);
                        navigate(
                          Object.entries(menuStore.allWindows)[
                            Object.keys(menuStore.allWindows).length - 1
                          ][1].path
                        );
                      }
                    }
                  );
                }}
              >
                <StatusIcon icon="iconclosesmall" color="bw-gray5" />
              </div>
            </div>
          );
        })}
      </div>

      {Object.values(menuStore.allWindows).length > 3 ? (
        <div className={styles.closeAllTabsBtn}>
          <button
            type="button"
            onClick={() => {
              Object.keys(menuStore.allWindows).forEach((tabsPath, index) => {
                if (index === 0) return;
                menuStore.deleteWindow(tabsPath);
              });
              navigate(Object.keys(menuStore.allWindows)[0]);
            }}
            id="Header_closeAllTabs"
            data-tooltip="Закрыть все вкладки"
          >
            <IconAdd />
          </button>
        </div>
      ) : (
        ""
      )}

      {menuStore.allModules ? (
        <div
          className={`${styles.headerBtn} ${styles.headerBtn_main}`}
          key={"main"}
          data-tooltip="Главная страница находится в разработке"
        >
          <Link to={"/main"}>Главная</Link>
          <div className={styles.iconClose}>
            <StatusIcon icon="iconclosesmall" color="bw-gray5" />
          </div>
        </div>
      ) : null}

      <div className={styles.topMenu}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HeaderTopMenu />
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HeaderModulesMenu
          path={
            location.pathname[location.pathname.length - 1] === "/"
              ? location.pathname.slice(0, -1)
              : location.pathname
          }
        />
      </ErrorBoundary>
    </div>
  );
};

export default observer(Header);
