import { observer } from "mobx-react-lite";
import { useFormikContext } from "formik";
import { useStores } from "stores";

import Select from "shared/ui/Inputs/Select";
import { isEmpty } from "lodash";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

type SelectFieldProps = {
  title: "master" | "brigadier";
  id: string;
};

const SelectField = ({ title, id }: SelectFieldProps) => {
  const { aregisterListStore } = useStores();
  const { setFieldValue, values } = useFormikContext<AregisterListFormik>();

  let index = 0;
  const aregister = values?.info.find((reg, idx) => {
    reg.id === id ? (index = idx) : null;
    return reg.id === id;
  });

  return (
    <div className="position-relative">
      <div
        onClick={() => {
          aregisterListStore.getMastersAndGangers(aregister);
        }}
      >
        <Select
          name={`info.${index}.${title}`}
          options={
            aregisterListStore[`${title}s`]?.[id] ||
            ({} as Record<string, { title: string }>)
          }
          label={
            aregister[title] || !isEmpty(aregister[title])
              ? aregister[title]?.fio
              : "Без мастера"
          }
          onClick={(options) => {
            setFieldValue(`info.${index}.${title}.fio`, options.title);
            setFieldValue(`info.${index}.${title}.id`, options.id);
          }}
          valueName="id"
          required
        />
      </div>
    </div>
  );
};

export default observer(SelectField);
