import { array, object, string } from "yup";

export const validationEditSetting = object({
  contacts: object({
    phones: array().of(
      object({
        number: string()
          .typeError("Должно быть строкой")
          .max(20, "Не более 20 символов")
          .required("Введите номер телефона"),
        comment: string()
          .typeError("Должно быть строкой")
          .max(50, "Не более 50 символов")
          .nullable()
      })
    )
  })
});
