import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import Employee from "shared/assets/images/menuIcons/iconDefault/Employee.svg";
import EmployeeSelected from "shared/assets/images/menuIcons/iconSelected/Employee.svg";

import EditEmployee from "shared/assets/images/menuIcons/iconDefault/EditEmployee.svg";
import EditEmployeeSelected from "shared/assets/images/menuIcons/iconSelected/EditEmployee.svg";
import EmployeeStatus from "shared/assets/images/menuIcons/iconDefault/EmployeeStatus.svg";
import EmployeeStatusSelected from "shared/assets/images/menuIcons/iconSelected/EmployeeStatus.svg";

import EmployeeAwards from "shared/assets/images/menuIcons/iconDefault/Awards.svg";
import EmployeeAwardsSelected from "shared/assets/images/menuIcons/iconSelected/Awards.svg";

import Worked from "shared/assets/images/menuIcons/iconDefault/Worked.svg";
import WorkedSelected from "shared/assets/images/menuIcons/iconSelected/Worked.svg";

import Dismiss from "shared/assets/images/menuIcons/iconDefault/Dismiss.svg";
import DismissSelected from "shared/assets/images/menuIcons/iconSelected/Dismiss.svg";

// import Export from "shared/assets/images/menuIcons/iconDefault/Export.svg";
// import ExportSelected from "shared/assets/images/menuIcons/iconSelected/Export.svg";

// import Import from "shared/assets/images/menuIcons/iconDefault/Import.svg";
// import ImportSelected from "shared/assets/images/menuIcons/iconSelected/Import.svg";

// import Status from "shared/assets/images/menuIcons/iconDefault/Status.svg";
// import StatusSelected from "shared/assets/images/menuIcons/iconSelected/Statuses.svg";

// import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
// import BuildingsSelected from "shared/assets/images/menuIcons/iconSelected/Buildings.svg";

// import EmployeeCard from "shared/assets/images/menuIcons/iconDefault/EmployeeCard.svg";
// import EmployeeCardSelected from "shared/assets/images/menuIcons/iconSelected/EmployeeCard.svg";

// import AddressTypes from "shared/assets/images/menuIcons/iconDefault/AddressTypes.svg";
// import AddressTypesSelected from "shared/assets/images/menuIcons/iconSelected/TypesOfAddresses.svg";

import Comments from "shared/assets/images/menuIcons/iconDefault/Comments.svg";
import CommentsSelected from "shared/assets/images/menuIcons/iconSelected/Comments.svg";

import Conviction from "shared/assets/images/menuIcons/iconDefault/Manacle.svg";
import ConvictionSelected from "shared/assets/images/menuIcons/iconSelected/Manacle.svg";

import History from "shared/assets/images/menuIcons/iconDefault/History.svg";
import HistorySelected from "shared/assets/images/menuIcons/iconSelected/History.svg";

import SafetyWork from "shared/assets/images/menuIcons/iconDefault/Identity.svg";
import SafetyWorkSelected from "shared/assets/images/menuIcons/iconSelected/Identities.svg";

import Salary from "shared/assets/images/menuIcons/iconDefault/Coin.svg";
import SalarySelected from "shared/assets/images/menuIcons/iconSelected/Coin.svg";

import PremiumLevel from "shared/assets/images/menuIcons/iconDefault/PremiumLevel.svg";
import PremiumLevelSelected from "shared/assets/images/menuIcons/iconSelected/PremiumLevel.svg";

const StaffSectionOne = () => {
  const { menuStore, staffOneStore } = useStores();

  const tabId = menuStore.tabId;

  const overview = [
    {
      text: "Сотрудник",
      to: `/staff/id=${tabId}`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaff",
      defaultIcon: Employee,
      selectedIcon: EmployeeSelected,
      widgetTitle: "main"
    },
    {
      text: "Статусы",
      to: `/staff/id=${tabId}/statuses`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffStatuses",
      defaultIcon: EmployeeStatus,
      selectedIcon: EmployeeStatusSelected,
      widgetTitle: "building_staff_events"
    },
    {
      text: "Финансы и удержания",
      to: `/staff/id=${tabId}/salary`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffSalary",
      defaultIcon: Salary,
      selectedIcon: SalarySelected,
      widgetTitle: "salary"
    },
    {
      text: "Отработано смен",
      to: `/staff/id=${tabId}/workshift`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffWorkshift",
      defaultIcon: Worked,
      selectedIcon: WorkedSelected,
      widgetTitle: "workshift"
    },
    {
      text: "Охрана труда",
      to: `/staff/id=${tabId}/safety_work`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffSafetyWork",
      defaultIcon: SafetyWork,
      selectedIcon: SafetyWorkSelected,
      widgetTitle: "safety_work"
    },
    {
      text: "Судимости",
      to: `/staff/id=${tabId}/conviction`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffConviction",
      defaultIcon: Conviction,
      selectedIcon: ConvictionSelected,
      widgetTitle: "conviction"
    },
    {
      text: "Награды",
      to: `/staff/id=${tabId}/awards`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffAwards",
      defaultIcon: EmployeeAwards,
      selectedIcon: EmployeeAwardsSelected,
      widgetTitle: "awards"
    },
    {
      text: "ПОЛАТИ БОНУС",
      to: `/staff/id=${tabId}/premium_level`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffPremiumLevel",
      defaultIcon: PremiumLevel,
      selectedIcon: PremiumLevelSelected,
      widgetTitle: "premium_level"
    },
    {
      text: "История",
      to: `/staff/id=${tabId}/history`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffHistory",
      defaultIcon: History,
      selectedIcon: HistorySelected,
      widgetTitle: "history"
    },
    {
      text: "Комментарии",
      to: `/staff/id=${tabId}/comments`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffComment",
      defaultIcon: Comments,
      selectedIcon: CommentsSelected,
      widgetTitle: "comments"
    }
  ];

  const actions = [
    {
      text: "Редактировать",
      to: `/staff/id=${tabId}/edit=avatar`,
      moduleName: "oneOfStaff",
      submoduleName: "oneOfStaffEdit",
      defaultIcon: EditEmployee,
      selectedIcon: EditEmployeeSelected
    },

    {
      text: "Согласовать увольнение",
      to: "",
      submoduleName: "",
      defaultIcon: Dismiss,
      selectedIcon: DismissSelected
    }

    // {
    //   text: "Добавить",
    //   to: "/staff/add",
    //   submoduleName: "add",
    //   defaultIcon: Employee,
    //   selectedIcon: EmployeeSelected
    // },
    // {
    //   text: "Экспортировать",
    //   to: "/staff/export",
    //   submoduleName: "export",
    //   defaultIcon: Export,
    //   selectedIcon: ExportSelected
    // },
    // {
    //   text: "Импортировать",
    //   to: "/staff/import",
    //   submoduleName: "import",
    //   defaultIcon: Import,
    //   selectedIcon: ImportSelected
    // }
  ];

  // const settings = [
  //   {
  //     text: "Статусы",
  //     to: "/staff/statusSettings",
  //     submoduleName: "statusSettings",
  //     defaultIcon: Status,
  //     selectedIcon: StatusSelected
  //   },
  //   {
  //     text: "Карточка сотрудника",
  //     to: "/staff/cardSettings",
  //     submoduleName: "cardSettings",
  //     defaultIcon: EmployeeCard,
  //     selectedIcon: EmployeeCardSelected
  //   },
  //   {
  //     text: "Города",
  //     to: "/staff/Buildings",
  //     submoduleName: "Buildings",
  //     defaultIcon: Buildings,
  //     selectedIcon: BuildingsSelected
  //   },
  //   {
  //     text: "Типы адресов",
  //     to: "/staff/typeAddress",
  //     submoduleName: "typeAddress",
  //     defaultIcon: AddressTypes,
  //     selectedIcon: AddressTypesSelected
  //   }
  // ];

  return !(tabId in staffOneStore.lockedIDs) ? (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview
          .filter(
            (item) =>
              menuStore.staffWidgetList.includes(item.widgetTitle) ||
              item.widgetTitle === "main"
          )
          .map((item) => (
            <MenuButton
              key={item["text"]}
              to={item["to"]}
              text={item["text"]}
              defaultIcon={item["defaultIcon"]}
              selectedIcon={item["selectedIcon"]}
              moduleName={item["moduleName"]}
              submoduleName={item["submoduleName"]}
            />
          ))}
      </MenuSection>

      <MenuSection sectionTitle="Действия">
        {actions.map((action) => (
          <MenuButton
            key={action["text"]}
            to={action["to"]}
            text={action["text"]}
            defaultIcon={action["defaultIcon"]}
            selectedIcon={action["selectedIcon"]}
            moduleName={action["moduleName"]}
            submoduleName={action["submoduleName"]}
            onClick={() =>
              action["text"] === "Согласовать увольнение" &&
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdrgjIinnSxFiRZXE32xCwDBTJM45yJ_JTKgmqTsyJBl2nryg/viewform",
                "_blank"
              )
            }
          />
        ))}
      </MenuSection>
      {/* <MenuSection sectionTitle="Настроить">
        {settings.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection> */}
    </>
  ) : null;
};

export default observer(StaffSectionOne);
