import { MouseEvent, ReactNode } from "react";
import { CalendarContainer } from "react-datepicker";

type CustomCalendarContainerProps = {
  className: string;
  children: ReactNode;
};

const CustomCalendarContainer = ({
  className,
  children
}: CustomCalendarContainerProps) => {
  const handleOnClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <div
      id="CustomCalendarContainer_stopPropagation_calendar"
      onClick={handleOnClick}
    >
      <CalendarContainer className={className}>
        <div>{children}</div>
      </CalendarContainer>
    </div>
  );
};

export default CustomCalendarContainer;
