import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import StaffVacationTable from "./StaffVacationTable/index";
import StaffVacationInfo from "./StaffVacationInfo";
import TableWithStickyFilter from "features/TableWithStickyFilter";

import { browserName } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const StaffVacation = () => {
  const { menuStore, filterStore, staffVacationStore } = useStores();

  useEffect(() => {
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/staffVacation",
      title: "Сотрудники"
    });
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("staffVacation");

    !staffVacationStore.vacationsList.length &&
      staffVacationStore.page === 1 &&
      staffVacationStore.getVacationsData();
  }, []);

  const filterVacations = (order: string) => {
    staffVacationStore.setVacationsListOrder(order);
    staffVacationStore.setVacationsListOrdered(
      staffVacationStore.vacationsListOrder === "DESC" ? "ASC" : "DESC"
    );
    staffVacationStore.setPage(1);
    staffVacationStore.getVacationsData();
  };

  useEffect(() => {
    if (
      staffVacationStore.page !== 1 &&
      staffVacationStore.page <= staffVacationStore.maxPage &&
      staffVacationStore.page !== staffVacationStore.prevPage
    ) {
      staffVacationStore.showMoreVacations();
    }
  }, [staffVacationStore.page, staffVacationStore.maxPage]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={staffVacationStore.isLoading}
        isLoadingForFilters={staffVacationStore.isLoadingForFilters}
        filters={staffVacationStore.filters}
        searchValue={staffVacationStore.searchValue}
        setSearchValue={staffVacationStore.setSearchValue}
        getData={staffVacationStore.getVacationsData}
        selectedFilter={staffVacationStore.selectedFilter}
        setSelectedFilter={staffVacationStore.setSelectedFilter}
        setFilters={staffVacationStore.setFilters}
        cols={staffVacationStore.vacationsTableCols}
        params={staffVacationStore.vacationsTableParams}
        currentCols={staffVacationStore.currentTitles}
        getDataWithFilter={staffVacationStore.getVacationsListWithFilter}
        selectMulti={["position", "building", "company"]}
        downloadedFilter={staffVacationStore.downloadedFilter}
        setDownloadedFilter={staffVacationStore.setDownloadedFilter}
        allCols={staffVacationStore.vacationsColsAll}
        isSearchable
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !staffVacationStore.filters[staffVacationStore.downloadedFilter]?.[
              "general"
            ] || staffVacationStore.searchValue
          ),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="Быстрый поиск находится в разработке"
      >
        <>
          <StaffVacationInfo />
          <StaffVacationTable filterItems={filterVacations} />
        </>
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(StaffVacation);
