import RouterComponent from "app/providers/router/RouterComponent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { useAuthData } from "shared/utils/hooks/useAuthData";
import Header from "widgets/Header";
import { classNames } from "shared/utils/helpers/classNames";
import { useStores } from "stores";
import Menu from "widgets/Menu";
import { observer } from "mobx-react-lite";

const App = () => {
  const { menuStore } = useStores();

  useAuthData();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="app">
        <Header />
        <div
          className={classNames("content-page", {
            "content-page__collapsedMenu": !menuStore.isMenuShown
          })}
        >
          <Menu />
          <RouterComponent />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default observer(App);
