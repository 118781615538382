import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

const WorkshiftSectionOne = () => {
  const { menuStore } = useStores();

  const overview = [
    {
      text: "Сотрудники",
      to: `/workshift/id=${menuStore.tabId}/staff`,
      moduleName: "workshiftOne",
      submoduleName: "workshiftOneStaff",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Смена",
      to: `/workshift/id=${menuStore.tabId}`,
      moduleName: "workshiftOne",
      submoduleName: "workshiftOne",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "История изменений",
      to: `/workshift/id=${menuStore.tabId}/history`,
      moduleName: "workshiftOne",
      submoduleName: "workshiftOneHistory",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(WorkshiftSectionOne);
