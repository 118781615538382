import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import PolatiAppVacantRequestFilter from "./PolatiAppVacantRequestFilter";
import PolatiAppVacantRequestTable from "./PolatiAppVacantRequestTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const PolatiAppVacantRequest = () => {
  const { menuStore, polatiAppStore } = useStores();

  useEffect(() => {
    if (!Object.values(polatiAppStore.polatiAppData).length) {
      polatiAppStore.setData();
    }
    menuStore.setOpenedModule("polatiapp");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/polatiapp", "Приложение ПОЛАТИ");
  }, []);

  return (
    <LoadedComponent isLoading={polatiAppStore.isLoading}>
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PolatiAppVacantRequestFilter
            filters={polatiAppStore.filters}
            getDataWithFilter={polatiAppStore.getDataWithFilter}
          />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PolatiAppVacantRequestTable />
        </ErrorBoundary>
      </>
    </LoadedComponent>
  );
};

export default observer(PolatiAppVacantRequest);
