import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

const AregisterSectionOne = () => {
  const { menuStore } = useStores();

  const overview = [
    {
      text: "Описание",
      to: `/aregister/id=${menuStore.tabId}`,
      moduleName: "aregisterOne",
      submoduleName: "aregisterOne",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },

    {
      text: "Сотрудники",
      to: `/aregister/id=${menuStore.tabId}/staff`,
      moduleName: "aregisterOne",
      submoduleName: "aregisterOneStaff",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Почасовки в заявке",
      to: `/aregister/id=${menuStore.tabId}/presaves`,
      moduleName: "aregisterOne",
      submoduleName: "aregisterOnePresave",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  const actions = [
    {
      text: "Копировать массово",
      to: `/aregister/id=${menuStore.tabId}/masscopy`,
      moduleName: "aregisterOne",
      submoduleName: "aregisterOneMasscopy",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Редактирование",
      to: `/aregister/id=${menuStore.tabId}/edit`,
      moduleName: "aregisterOne",
      submoduleName: "aregisterOneEdit",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((action) => (
          <MenuButton
            key={action["text"]}
            to={action["to"]}
            text={action["text"]}
            defaultIcon={action["defaultIcon"]}
            selectedIcon={action["selectedIcon"]}
            moduleName={action["moduleName"]}
            submoduleName={action["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(AregisterSectionOne);
