import styles from "./buildingOneWorkshiftStafftableSelect.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";

import BuildingOneWorkshiftStafftableSelectEdit from "./BuildingOneWorkshiftStafftableSelectEdit";
import BuildingOneWorkshiftStafftableSelectShow from "./BuildingOneWorkshiftStafftableSelectShow";

import { fileUrl } from "stores/utils/consts";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";

const BuildingOneWorkshiftStafftableSelect = ({
  name,
  staffIndex
}: WorkshiftStaffFieldProps) => {
  const { buildingOneWorkshiftStore } = useStores();

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  // стейт для ре-рендера поля при косании (клике) на него
  const [isTouched, setIsTouched] = useState("");

  const { id } = useParams();
  const { values } = useFormikContext<StaffType[]>();

  useEffect(
    () => setIsTouched(""),
    [
      buildingOneWorkshiftStore.openedMode[id],
      buildingOneWorkshiftStore.selectedFilters[id]
    ]
  );

  const selects = buildingOneWorkshiftStore.selects;
  const mode = buildingOneWorkshiftStore.getOpenedModeForField(name);

  switch (mode) {
    case "show":
      switch (name) {
        case "customer":
          return (
            <a
              href={`${fileUrl}/crm/customer/?id=${values[staffIndex][name]}`}
              id={`BuildingOneWorkshiftStafftableSelect_${name}_${values[staffIndex][name]}`}
              rel="noreferrer"
              target="_blank"
              className={styles.link}
            >
              {selects[name]?.[values[staffIndex][name]]?.["title"] || ""}
            </a>
          );
        default:
          return selects[name]?.[values[staffIndex][name]]?.["title"] || "";
      }

    case "edit":
      if (isTouched?.length && isTouched === values[staffIndex].uid) {
        return (
          <BuildingOneWorkshiftStafftableSelectEdit
            name={name}
            staffIndex={staffIndex}
            notSortable={name === "objectstatus"}
            required={name === "payment_type" && !values[staffIndex].isMassEdit}
            isMenuOpened={isMenuOpened}
            setIsTouched={setIsTouched}
          />
        );
      } else {
        return (
          <BuildingOneWorkshiftStafftableSelectShow
            setIsMenuOpened={setIsMenuOpened}
            setIsTouched={setIsTouched}
            staffIndex={staffIndex}
            name={name}
          />
        );
      }
  }
};

export default observer(BuildingOneWorkshiftStafftableSelect);
