import styles from "./statusField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import AregisterOneInfoMainModal from "./CancellationModal";
import Alert from "shared/ui/Alert";
import { Errors } from "stores/utils/types/ErrorsType";
import { TextField } from "shared/ui/TextField";

import { isEmpty } from "lodash";
import { statusParams } from "stores/AregisterModule/directories/statusesParams";
import { getAregisterStatus } from "shared/utils/helpers/getAregisterStatus";
import { Button, ButtonTheme } from "shared/ui/Button";
import { getValues } from "shared/utils/helpers/getValues";

type StatusFieldProps = {
  title: string;
  name: string;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
};

const StatusField = ({
  title,
  name,
  openedListName,
  changeOpenedListName
}: StatusFieldProps) => {
  const { id } = useParams();
  const { aregisterOneStore } = useStores();

  const setIsOpenModal = () => changeOpenedListName("cancelled");
  const getErrors = (error: Errors["message"]["body"]["key"]) => {
    const formattedError: Errors["message"] = {
      head: error.head,
      color: "danger",
      body: {}
    };
    error.list && (formattedError.body["0"] = { head: "", list: error.list });
    return formattedError;
  };
  const params = statusParams[getAregisterStatus()];

  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <TextField
          title={title}
          value={
            <>
              {params?.icon}
              {aregisterOneStore.selectedOne[name].title}
            </>
          }
        />
        <div className={styles.buttons}>
          {/* TODO: Подключить редактирование статуса aregister/updateAregister */}
          {/* TODO: Добавить блокировку кнопки, если нельзя закрыть заявку */}
          <Button
            theme={ButtonTheme.SECONDARY}
            id="StatusField_changeStatusBtn"
          >
            {params?.changeIcon}
            {params?.changeTitle}
          </Button>
          {params["canCancelled"] ? (
            <Button
              theme={ButtonTheme.SECONDARY}
              onClick={setIsOpenModal}
              id="StatusField_cancelAregister"
            >
              {statusParams.cancel.icon}
              {statusParams.cancel.text}
            </Button>
          ) : null}
        </div>
      </div>

      {!isEmpty(aregisterOneStore.closed_errors[id]) ? (
        <div className={styles.errors}>
          {getValues(aregisterOneStore.closed_errors[id]).map((errorsList) =>
            getValues(errorsList.body).map((error) => (
              <Alert key={error.head} errors={getErrors(error)} />
            ))
          )}
        </div>
      ) : null}

      {openedListName === "cancelled" && (
        <AregisterOneInfoMainModal id={id} setIsOpenModal={setIsOpenModal} />
      )}
    </div>
  );
};

export default observer(StatusField);
