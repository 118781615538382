export type MainMenuDict = {
  [key: string]: { id: string; title: string; ordered: number };
};

export type DictionaryElement = {
  id?: string | number;
  ordered?: string | number;
  title: string;
  filterdata?: {
    check_col?: string;
    cols?: string[];
    custom?: {
      [key: string]: string;
    };
    numberOfRecords?: string;
    showAccess?: string;
    showWidget?: boolean;
    ordered: string | number;
  };
};

export type Dict = DictionaryElement[];

export const mainMenuDict: MainMenuDict = {
  1: { id: "1", title: "Основная информация", ordered: 1 },
  2: { id: "2", title: "Виджеты", ordered: 2 },
  3: {
    id: "3",
    title: "Справочная информация",
    ordered: 3
  }
};

export const mainInformationDict: Dict = [
  {
    title: "Фотография и ФИО",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "1"
    }
  },
  {
    title: "Данные по компании",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "2"
    }
  },
  {
    title: "Основные сведения",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "3"
    }
  },
  {
    title: "Контакты",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "4"
    }
  },
  {
    title: "Паспортные данные",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "5"
    }
  },
  {
    title: "СИЗ",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "6"
    }
  },
  {
    title: "Образование",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "7"
    }
  },
  {
    title: "Покинул ПОЛАТИ",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "8"
    }
  },
  {
    title: "Настройки для приложения",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "9"
    }
  }
];

export const widgetsDict: Dict = [
  {
    title: "Статус на объекте",
    filterdata: {
      numberOfRecords: "3",
      showAccess: "110",
      showWidget: true,
      ordered: "1"
    }
  },
  {
    title: "Смены в должности",
    filterdata: {
      numberOfRecords: "4",
      showAccess: "10",
      showWidget: false,
      ordered: "2"
    }
  }
];

export const referenceInfoDict: Dict = [
  {
    title: "Последние события",
    filterdata: { ordered: "1" }
  },
  {
    title: "Важные уведомления",
    filterdata: { ordered: "2" }
  },
  {
    title: "Финансы",
    filterdata: { ordered: "3" }
  }
];
