import { string, object } from "yup";

import { dateSchema, stringSchema } from "shared/utils/validation/validation";

const validationForm = {
  billing_period: dateSchema,
  building: stringSchema
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = validationForm[col]
      ? validationForm[col]
      : string().nullable();

    if (requiredCols.includes(col)) {
      validationSchema[col] = validationSchema[col].required(
        "Поле обязательно для заполнения"
      );
    }
  });

  return object(validationSchema);
};
