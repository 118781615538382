import styles from "./headerTopMenu.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState } from "react";

import Modal from "shared/ui/Modal";

import { ReactComponent as IconRight } from "shared/assets/images/mainIcons/iconRight.svg";

import iconNotification from "shared/assets/images/topMenuIcons/iconsDefault/iconNotification.svg";
import iconLogo from "shared/assets/images/topMenuIcons/iconsDefault/iconLogo.svg";
import iconQuestion from "shared/assets/images/topMenuIcons/iconsDefault/iconQuestion.svg";
import iconInformation from "shared/assets/images/topMenuIcons/iconsDefault/iconInformation.svg";
import iconExit from "shared/assets/images/topMenuIcons/iconsDefault/iconExit.svg";
import iconSupport from "shared/assets/images/topMenuIcons/iconsDefault/iconSupport.svg";

import iconNotificationHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconNotificationHovered.svg";
import iconLogoHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconLogoHovered.svg";
import iconQuestionHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconQuestionHovered.svg";
import iconInformationHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconInformationHovered.svg";
import iconExitHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconExitHovered.svg";
import iconSupportHovered from "shared/assets/images/topMenuIcons/iconsHovered/iconSupportHovered.svg";

import iconNotificationActive from "shared/assets/images/topMenuIcons/iconsActive/iconNotificationActive.svg";
import iconLogoActive from "shared/assets/images/topMenuIcons/iconsActive/iconLogoActive.svg";
import iconQuestionActive from "shared/assets/images/topMenuIcons/iconsActive/iconQuestionActive.svg";
import iconInformationActive from "shared/assets/images/topMenuIcons/iconsActive/iconInformationActive.svg";
import iconExitActive from "shared/assets/images/topMenuIcons/iconsActive/iconExitActive.svg";
import HeaderTopMenuPopup from "./HeaderTopMenuPopup";
import { fileUrl } from "stores/utils/consts";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/AvatarWithoutFill.svg";

const HeaderTopMenu = () => {
  const { userStore, menuStore } = useStores();
  const [isOpenedTopMenu, setIsOpenedTopMenu] = useState(true);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [hoveredBtn, setHoveredBtn] = useState("");
  const [activeBtn, setActiveBtn] = useState("");

  const topMenuIconsDefault = {
    notice: iconNotification,
    help: iconQuestion,
    corporate_portal: iconLogo,
    info: iconInformation,
    main_site: iconExit,
    support: iconSupport,
    default: iconInformation
  };

  const topMenuIconsHovered = {
    notice: iconNotificationHovered,
    help: iconQuestionHovered,
    corporate_portal: iconLogoHovered,
    info: iconInformationHovered,
    main_site: iconExitHovered,
    support: iconSupportHovered,
    default: iconInformationHovered
  };

  const topMenuIconsActive = {
    notice: iconNotificationActive,
    help: iconQuestionActive,
    corporate_portal: iconLogoActive,
    info: iconInformationActive,
    main_site: iconExitActive,
    default: iconInformationActive
  };

  const getImageSrc = (name: string) => {
    return hoveredBtn === name && activeBtn !== name
      ? topMenuIconsHovered[name]
        ? topMenuIconsHovered[name]
        : topMenuIconsHovered.default
      : activeBtn === name
      ? topMenuIconsActive[name]
        ? topMenuIconsActive[name]
        : topMenuIconsActive.default
      : topMenuIconsDefault[name]
      ? topMenuIconsDefault[name]
      : topMenuIconsDefault.default;
  };

  return (
    <>
      <div className={styles.topMenu}>
        {"user" in menuStore.topMenuList ? (
          <div
            className={styles.userBlock}
            id="HeaderTopMenu_toLK"
            onClick={() => {
              return setTimeout(() => {
                window.open(menuStore.topMenuList.user.content?.link, "_blank");
              }, 100);
            }}
          >
            <img
              src={
                menuStore.topMenuList.user.content?.photo
                  ? `${fileUrl}${menuStore.topMenuList.user.content.photo}`
                  : Avatar
              }
              className={styles.avatar}
            />
            {menuStore.topMenuList.user.content?.fio}
          </div>
        ) : (
          ""
        )}
        <div
          className={`${styles.iconArrow} ${
            isOpenedTopMenu ? styles.iconArrow_opened : styles.iconArrow_closed
          }`}
          onClick={() => setIsOpenedTopMenu(!isOpenedTopMenu)}
          id="HeaderTopMenu_openedMenuBtn"
        >
          <IconRight />
        </div>
        <div
          className={`${styles.topMenu__buttons} ${
            isOpenedTopMenu
              ? styles.topMenu__buttons_opened
              : styles.topMenu__buttons_closed
          }`}
          style={{
            width: isOpenedTopMenu
              ? `${
                  Object.keys(menuStore.topMenuList).filter(
                    (key) => key !== "user"
                  ).length * 42
                }px`
              : "0px"
          }}
        >
          {Object.values(menuStore.topMenuList).length && isOpenedTopMenu
            ? Object.entries(menuStore.topMenuList).map(([name, param]) => {
                return (
                  name !== "user" && (
                    <div
                      key={name}
                      className="position-relative"
                      data-type="topMenuBtn"
                    >
                      {/* 
                        color="#008CFF"
                        backgroundColor="#F2F9FF"
                        text={name === "main_site" ? "Выход" : param.title}
                        placement="bottom"
                       */}
                      {name !== "help" &&
                      (param.type !== "link" || name === "main_site") ? (
                        <div
                          className={`${styles.topMenu__oneButton} ${
                            activeBtn === name
                              ? styles.topMenu__oneButton_active
                              : ""
                          }`}
                          onMouseEnter={() => setHoveredBtn(name)}
                          onMouseLeave={() => setHoveredBtn("")}
                          onClick={() => {
                            setActiveBtn(activeBtn === name ? "" : name);
                            name === "main_site" && setIsOpenedModal(true);
                          }}
                          id={`HeaderTopMenu_btn_${name}`}
                          data-type="topMenuBtn"
                          data-tooltip={
                            name === "main_site" ? "Выход" : param.title
                          }
                        >
                          <img
                            src={getImageSrc(name)}
                            alt={name}
                            data-type="topMenuBtn"
                          />
                        </div>
                      ) : (
                        Object.values(param.content).map((item) => {
                          return (
                            <div
                              id={`HeaderTopMenu_btn_${name}`}
                              key={`HeaderTopMenu_btn_${name}`}
                              className={styles.topMenu__oneButton}
                              onMouseEnter={() => setHoveredBtn(name)}
                              onMouseLeave={() => setHoveredBtn("")}
                              onClick={() => {
                                return setTimeout(() => {
                                  window.open(
                                    typeof item === "string"
                                      ? item
                                      : item["link"],
                                    "_blank"
                                  );
                                }, 100);
                              }}
                              data-tooltip={
                                name === "main_site" ? "Выход" : param.title
                              }
                            >
                              <img src={getImageSrc(name)} alt={name} />
                            </div>
                          );
                        })
                      )}
                      {["popup", "notice"].includes(param.type) &&
                      activeBtn === name &&
                      name !== "help" ? (
                        <HeaderTopMenuPopup
                          isInfo={name === "info"}
                          title={param.title}
                          content={param.content}
                          isOpenedPopup={activeBtn === name}
                          setIsOpenedPopup={setActiveBtn}
                        />
                      ) : null}
                    </div>
                  )
                );
              })
            : null}
        </div>
      </div>
      <Modal
        type="clarification"
        show={isOpenedModal}
        onHide={() => {
          setIsOpenedModal(!isOpenedModal);
          setActiveBtn("");
        }}
        title="Вы уверены, что хотите выйти?"
        btnWithCrossTitle="Да"
        btnWithCrossOnClick={userStore.deleteAccessKey}
        blueBtnOnClick={() => {
          setIsOpenedModal(!isOpenedModal);
          setActiveBtn("");
        }}
        blueBtnTitle="Нет"
      />
    </>
  );
};

export default observer(HeaderTopMenu);
