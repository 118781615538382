import styles from "./aregisterTableHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { useFormikContext } from "formik";
import Checkbox from "shared/ui/Inputs/Checkbox";

import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";

import { browserName } from "react-device-detect";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { isEmpty } from "lodash";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

const AregisterTableHeader = () => {
  const { aregisterListStore, filterStore } = useStores();

  const { values, handleReset, setFieldValue, setFieldTouched } =
    useFormikContext<AregisterListFormik>();

  const handleCheckboxClick = () => {
    if (!values.selectAll) {
      const updatedValue: string[] = [];
      aregisterListStore.aregisterList.forEach((aregister) => {
        aregister.status !== aregisterListStore.openedMassStatusEdit &&
          updatedValue.push(aregister.id);
      });
      setFieldValue("selectAll", true);
      setFieldTouched("selectAll");
      setFieldValue("aregister", updatedValue);
      setFieldTouched("aregister");
    } else {
      handleReset();
    }
  };

  return (
    <thead
      className={styles.thead}
      style={{
        top: getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(aregisterListStore.searchValue),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )
      }}
    >
      <tr>
        {aregisterListStore.openedEditType === "mass_status" ? (
          <td>
            <Checkbox name="selectAll" onChange={handleCheckboxClick} />
          </td>
        ) : null}
        {aregisterListStore.currentTitles.map((title) => {
          return (
            <th key={title} className={styles.title}>
              {["size", "act_hours"].includes(title) &&
              !isEmpty(values.aregister) ? (
                <span className={styles.sum}>{`Σ = ${aregisterListStore.getSum(
                  values.aregister,
                  title
                )}`}</span>
              ) : null}
              <div className="d-flex align-items-center">
                <p className="m-0">
                  {aregisterListStore.cols[title]?.["title"] || title}
                </p>
                <img src={iconArrowDouble} alt="Сортировать" />
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default observer(AregisterTableHeader);
