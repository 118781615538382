import styles from "./staffOneSafetyWorkContent.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";

import StaffOneSafetyWorkTabs from "./StaffOneSafetyWorkTabs";
import StaffOneSafetyWorkTable from "./StaffOneSafetyWorkTable";

import { getPositionsForOneOfStaff } from "features/StaffOneSafetyWork";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import Alert from "shared/ui/Alert";
import { getValues } from "shared/utils/helpers/getValues";

const StaffOneSafetyWorkContent = () => {
  const { staffOneSafetyWorkStore } = useStores();
  const [positions, setPositions] = useState<
    Partial<{
      [key: string]: { [key: string]: string };
    }>
  >({});
  const [activeRow, setActiveRow] = useState("");

  useEffect(() => {
    setPositions(
      staffOneSafetyWorkStore.certificates
        ? getPositionsForOneOfStaff(
            staffOneSafetyWorkStore.certificates,
            staffOneSafetyWorkStore.companiesTabs
          )
        : {}
    );
  }, [staffOneSafetyWorkStore.certificates]);

  useEffect(() => {
    setActiveRow("");
  }, [staffOneSafetyWorkStore.selectedCompany]);

  return (
    <div className={styles.container}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneSafetyWorkTabs />
      </ErrorBoundary>
      {positions[staffOneSafetyWorkStore.selectedCompany] &&
      staffOneSafetyWorkStore.certificates[
        staffOneSafetyWorkStore.selectedCompany
      ] ? (
        Object.keys(positions[staffOneSafetyWorkStore.selectedCompany]).map(
          (position) => {
            return (
              <div className={styles.tableContainer} key={position}>
                <div className={styles.position}>
                  {positions[staffOneSafetyWorkStore.selectedCompany][position]}
                </div>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <StaffOneSafetyWorkTable
                    activePosition={position}
                    positions={positions}
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                  />
                </ErrorBoundary>
              </div>
            );
          }
        )
      ) : (
        <Alert
          errors={{
            head: `Не найдены должности в компании ${
              getValues(staffOneSafetyWorkStore.companiesTabs).find(
                (company) =>
                  company.id === staffOneSafetyWorkStore.selectedCompany
              )?.title || ""
            }`,
            color: "empty"
          }}
        />
      )}
    </div>
  );
};

export default observer(StaffOneSafetyWorkContent);
