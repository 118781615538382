import { useFormikContext } from "formik";
import { useEffect } from "react";
import Switch from "shared/ui/Inputs/Switch";
import { StatusType } from "stores/StaffModule/staffStatus";
import { type } from "shared/utils/dictionaries/statusDict";
import styles from "./defaultAndDelete.module.scss";

type DefaultAndDeleteProps = {
  values: StatusType;
};

const DefaultAndDelete = ({ values }: DefaultAndDeleteProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    Object.keys(type).forEach((key) => {
      Object.keys(values).includes(key) && setFieldValue(key, 0);
    });
  }, []);

  return (
    <section>
      <ul className="p-0">
        {Object.keys(type).map((key) => {
          return (
            Object.keys(values).includes(key) && (
              <li key={key} className={styles.label}>
                <label className="d-flex align-items-center gap-3">
                  <Switch
                    name={key}
                    onChange={() => {
                      setFieldTouched(key);
                    }}
                  />
                  {type[key]}
                </label>
              </li>
            )
          );
        })}
      </ul>
    </section>
  );
};

export default DefaultAndDelete;
