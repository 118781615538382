import { format } from "date-fns";
import { dateFormats } from "stores/utils/consts";
import { standardDatePattern } from "shared/utils/validation/validation";

// функция для преобразования строки даты в тот формат, который приходит с бэка
// только для отображения, НЕ для полей ввода
export const getFormattedDate = (str: string) => {
  const timestampPattern =
    /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/;
  const timestampPatternFull =
    /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;

  const type = standardDatePattern.test(str)
    ? "date"
    : timestampPatternFull.test(str) || timestampPattern.test(str)
    ? "timestamp"
    : "";

  const date =
    type === "timestamp"
      ? `${str.slice(0, 10)}T${str.slice(11, str.length)}`
      : str;

  if (type) {
    return (
      dateFormats[type].format &&
      format(new Date(date), dateFormats[type].format)
    );
  } else return str;
};
