import { string, array, object, number } from "yup";

import { dateSchema } from "shared/utils/validation/validation";

const stringSchema = string().nullable();
const numberSchema = number().nullable().typeError("Только число");
const numberWithDot = stringSchema.test("", "Только число", (val) => {
  return val ? /^[0-9]*[.]?[0-9]+$/i.test(val) : true;
});

const aregisterSchema = {
  internal_num: stringSchema,
  date_start: dateSchema,
  date_end: dateSchema.test(
    "date_end",
    "Не может быть ранее даты получения",
    function (val) {
      if (this.parent.date_start && val) {
        return this.parent.date_start <= val ? true : false;
      } else return true;
    }
  ),
  project: stringSchema,
  contracts: stringSchema,
  type_work: stringSchema,
  type: stringSchema,
  species: stringSchema,
  sub_work: stringSchema,
  measurements: numberSchema,
  length: numberWithDot,
  width: numberWithDot,
  height: numberWithDot,
  size: numberWithDot,
  act_hours: numberWithDot,
  high_mark: numberSchema,
  curators: stringSchema,
  kc: stringSchema,
  no_kc: numberSchema,
  for_who: stringSchema,
  object_in_object: stringSchema,
  coordinates: stringSchema,
  comment: stringSchema
};

const validationMasscopy = {
  reload: numberSchema,
  original: object(aregisterSchema),
  copies: array().of(object(aregisterSchema)),
  massfill: object(aregisterSchema).nullable()
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    if (
      col === "copies" &&
      validationMasscopy[col]?.["innerType"]?.["fields"]
    ) {
      return Object.keys(
        validationMasscopy[col]["innerType"]["fields"]
      ).forEach((field) => {
        if (["brigadier", "master", "custom_fields"].includes(field)) return;
        if (!validationSchema[col]) {
          validationSchema[col] = validationMasscopy[col];
        }

        validationSchema[col]["innerType"]["fields"][field] =
          validationMasscopy[col]["innerType"]["fields"][field]
            ? validationMasscopy[col]["innerType"]["fields"][field]
            : stringSchema;

        if (requiredCols.includes(field)) {
          if (field === "measurements") {
            validationSchema[col]["innerType"]["fields"]["size"] =
              validationSchema[col]["innerType"]["fields"]["size"].required(
                "Поле обязательно для заполнения"
              );
          } else {
            validationSchema[col]["innerType"]["fields"][field] =
              validationSchema[col]["innerType"]["fields"][field].required(
                "Поле обязательно для заполнения"
              );
          }
        }
      });
    }

    validationSchema[col] = validationMasscopy[col]
      ? validationMasscopy[col]
      : stringSchema;
  });

  return object(validationSchema);
};
