import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Staff } from "stores/BuildingModule/types/BuildingStaffType";

type BuildingOneStaffTablePhoneProps = {
  staff_data: Staff;
  col_name: string;
  staff_id: string;
};

const BuildingOneStaffTablePhone = ({
  staff_data,
  col_name,
  staff_id
}: BuildingOneStaffTablePhoneProps) => {
  return (
    <>
      {staff_data[col_name] && Object.values(staff_data[col_name]).length
        ? Object.values(staff_data[col_name]).map((item, idx) => (
            <div
              key={`${staff_id}_${col_name}_${item}_${idx}`}
              className="d-flex align-items-center gap-1 "
            >
              {`${formatPhoneNumber(item["number"])} ${
                item?.["comment"] ? ` (${item["comment"]})` : ""
              }`}
            </div>
          ))
        : null}
    </>
  );
};

export default BuildingOneStaffTablePhone;
