import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";
import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

import { fileUrl } from "stores/utils/consts";

const ProjectsSectionOne = () => {
  const { menuStore } = useStores();

  const overview = [
    {
      text: "Проект",
      to: `/projects/id=${menuStore.tabId}`,
      moduleName: "projectsOne",
      submoduleName: "projectsOne",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Таблица норм производительности",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Таблица прямой сделки",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Выработка и текучка проектов",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Монитор заявок",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Неучтённые заявки",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Формулы заявок",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Статусы заявок",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Часы НЕ ИТР состава",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Оплачиваемые состояния ИТР",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    },
    {
      text: "Перерасчёт приведённых кубов",
      to: "",
      fullstack: `${fileUrl}/crm/projects/?id=${menuStore.tabId}`,
      moduleName: "projects",
      submoduleName: "",
      defaultIcon: Errors,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(ProjectsSectionOne);
