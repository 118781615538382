import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "stores";

export const useAuthData = () => {
  const { userStore, menuStore } = useStores();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [hasModulesList, setHasModulesList] = useState(false);

  useEffect(() => {
    userStore.getNewAccessKey();
  }, []);

  useEffect(() => {
    if (
      !hasModulesList &&
      isEmpty(menuStore.modulesList) &&
      userStore.accessKey
    ) {
      setHasModulesList(true);
      menuStore.getAllModulesList();
    }
  }, [menuStore.modulesList, userStore.accessKey]);

  useEffect(() => {
    if (userStore.accessKey) {
      const queryParams = searchParams.toString();
      const url = `${location.pathname}${queryParams ? `?${queryParams}` : ""}`;
      navigate(url);
    }
  }, [userStore.accessKey]);
};
