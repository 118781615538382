export const getAregisterStatus = (
  custom?: Partial<{
    done: string;
    cancelled: string;
  }>
) => {
  if (custom?.done) return "close";
  else if (custom?.cancelled) return "cancel";
  else return "open";
};
