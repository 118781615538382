import styles from "./aregisterOneMasscopyFormAddBlock.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import { ReactComponent as IconAddColumn } from "shared/assets/images/mainIcons/iconAddColumn.svg";
import { ReactComponent as IconAddLine } from "shared/assets/images/mainIcons/iconAddLine.svg";
import { ReactComponent as IconMultiEdit } from "shared/assets/images/mainIcons/iconsMultiEdit/iconMultiEditDisabled.svg";

import { Application } from "stores/AregisterModule/types/Application";
import Tooltip from "shared/ui/Tooltip";
import { InputNumber } from "shared/ui/Inputs/InputNumber";
import { Formik } from "formik";
import { getValidationSchema } from "./validation";

type AregisterOneMasscopyFormAddBlockProps = {
  copies: Partial<Application>[];
  isOpenedMassFill: boolean;
  setIsOpenedMassFill: (arg: boolean) => void;
};

const AregisterOneMasscopyFormAddBlock = ({
  copies,
  isOpenedMassFill,
  setIsOpenedMassFill
}: AregisterOneMasscopyFormAddBlockProps) => {
  const { id } = useParams();
  const { aregisterAppcreateStore, aregisterOneMasscopyStore } = useStores();

  const { setFieldValue } = useFormikContext();

  return (
    <div
      className={`${
        aregisterOneMasscopyStore.tableType[id] === "column"
          ? styles.icons
          : styles.icons_row
      }  ${
        isOpenedMassFill
          ? aregisterOneMasscopyStore.tableType[id] === "row"
            ? styles.icons_activeMultiEdit
            : styles.icons_activeMultiEdit_column
          : ""
      }`}
    >
      <Formik
        initialValues={{ copiesCount: 1 }}
        onSubmit={(values) => {
          const newCopiesValue: Partial<Application>[] = [];

          const copy: Partial<Application> = {};

          Object.entries(
            aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
          ).forEach(([key, value]) => {
            if (
              aregisterOneMasscopyStore.editedNullFieldsForMasscopy.includes(
                key
              )
            ) {
              return (copy[key] = null);
            }
            if (
              key === "custom_fields" &&
              aregisterAppcreateStore.projects[
                aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
                  .project
              ]?.custom?.custom_fields
            ) {
              copy.custom_fields = {};
              return Object.keys(
                aregisterAppcreateStore.projects[
                  aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
                    .project
                ].custom.custom_fields
              ).forEach((customTitle) => {
                return (copy.custom_fields[customTitle] = null);
              });
            }

            copy[key] = value as string | number;
          });

          for (let i = 0; i < values.copiesCount; i++) {
            newCopiesValue.push(copy);
          }

          setFieldValue("copies", [...copies, ...newCopiesValue]);
        }}
        validationSchema={getValidationSchema(
          25 - Object.values(copies).length,
          copies.length === 25
        )}
      >
        {({ values, handleSubmit }) => {
          return (
            <>
              <div
                id="AregisterOneMasscopyFormAddBlock_addCopyBtn"
                onClick={() => {
                  copies.length < 25 && handleSubmit();
                }}
              >
                <Tooltip
                  text={`${
                    copies?.length ? "Добавить ещё" : "Создать"
                  } копии заявки: ${values.copiesCount}`}
                >
                  <div
                    className={`${styles.iconAdd} ${
                      copies.length >= 25 ? styles.iconAdd_disabled : ""
                    }`}
                  >
                    {aregisterOneMasscopyStore.tableType[id] === "row" ? (
                      <IconAddLine data-svg={true} />
                    ) : (
                      <IconAddColumn data-svg={true} />
                    )}
                  </div>
                </Tooltip>
              </div>

              <div
                className={`${styles.inputNumber} ${
                  copies.length === 25 ? styles.inputNumber_disabled : ""
                }`}
              >
                <InputNumber
                  name="copiesCount"
                  value={values.copiesCount}
                  minValue={1}
                  maxValue={25 - Object.values(copies).length}
                  smallInputWithoutBorder
                  disabled={copies.length === 25}
                />
              </div>
              {!Object.values(copies).length ? (
                <div data-svg={true} className={styles.iconMultiEdit_disabled}>
                  <IconMultiEdit data-svg-edit={true} />
                </div>
              ) : (
                <div
                  id="AregisterOneMasscopyFormAddBlock_setOpenedMassFillBtn"
                  onClick={() =>
                    copies.length && setIsOpenedMassFill(!isOpenedMassFill)
                  }
                >
                  <Tooltip
                    text={`${
                      !isOpenedMassFill ? "Включить" : "Отключить"
                    } режим массового заполнения`}
                  >
                    <div data-svg={true} className={styles.iconMultiEdit}>
                      <IconMultiEdit data-svg-edit={true} />
                    </div>
                  </Tooltip>
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(AregisterOneMasscopyFormAddBlock);
