import styles from "./searchStaffInput.module.scss";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { useParams } from "react-router-dom";

import validation from "./validation";
import { classNames } from "shared/utils/helpers/classNames";
import SearchInput from "shared/ui/Inputs/SearchInput";
import Switch from "shared/ui/Inputs/Switch";

const SearchStaffInput = () => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  return (
    <Formik
      initialValues={{
        search_mode: buildingOneStaffStore.search_mode[id] ?? 1,
        search_value: buildingOneStaffStore.search_value[id] || ""
      }}
      onSubmit={() => null}
      enableReinitialize
      validateOnMount
      validateOnBlur
      validateOnChange
      validationSchema={validation}
    >
      {({ values, setFieldValue, errors }) => {
        const handleSetSearchMode = (search_mode: number) => () =>
          !errors.search_mode &&
          buildingOneStaffStore.setSearchMode(search_mode ? 0 : 1);
        const handleSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue("search_value", e.target["value"]);
        const handleFindStaff =
          (
            values: { search_value: string; search_mode: number },
            clear?: boolean
          ) =>
          () => {
            if (clear && !buildingOneStaffStore.search_value[id]?.length) {
              setFieldValue("search_value", "");
              return;
            }

            buildingOneStaffStore.setSearchValue(
              !clear ? values.search_value : ""
            );
            buildingOneStaffStore.setPage(1);
            buildingOneStaffStore.getBuildingStaffTable(
              id,
              buildingOneStaffStore.activeTab[id],
              clear || values.search_mode ? false : true
            );
          };

        return (
          <div className="d-flex gap-1">
            <div
              className={classNames("", {
                [styles.errorField]: errors.search_value
              })}
            >
              <SearchInput
                name="search_value"
                onChange={handleSetSearchValue}
                clearSearch={handleFindStaff(values, true)}
                handleFindData={handleFindStaff(values)}
              />
              {errors.search_value && (
                <span className={styles.error}>{errors.search_value}</span>
              )}
            </div>

            <label className={styles.switch}>
              <Switch
                name="search_mode"
                onChange={handleSetSearchMode(values.search_mode)}
              />
              Поиск по всем вкладкам
            </label>
          </div>
        );
      }}
    </Formik>
  );
};

export default observer(SearchStaffInput);
