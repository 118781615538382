import { classNames } from "shared/utils/helpers/classNames";
import styles from "./legend.module.scss";

const legendList: {
  [key: string]: { title: string; color: string; id: string };
} = {
  ws_in_month: {
    title: "Учтённые смены",
    color: "blue-white-blue",
    id: "ws_in_month"
  },
  ws_open_month: {
    title: "Неучтённые открытые смены",
    color: "accent-pastel-red",
    id: "ws_open_month"
  },
  ws_in_month_old: {
    title: "Смены вне программы",
    color: "bw-gray2",
    id: "ws_in_month_old"
  }
};

const Legend = () => {
  return (
    <>
      <div className={styles.text}>Учтено смен по выбранному уровню</div>
      <div className={styles.legendWrapper}>
        {Object.values(legendList).map((legend) => (
          <div key={legend.id} className={styles.legendItem}>
            <div
              className={classNames(styles.legendItemBox, {}, [
                styles[`legendItemBox_${legend.color}`]
              ])}
            />
            {legend.title}
          </div>
        ))}
      </div>
    </>
  );
};

export default Legend;
