import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import ProjectsListTable from "./ProjectsListTable";
import TableWithStickyFilter from "features/TableWithStickyFilter";

import { browserName } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const ProjectsList = () => {
  const { menuStore, filterStore, projectsListStore } = useStores();

  useEffect(() => {
    if (!projectsListStore.projectsList.length) {
      projectsListStore.getProjectsData();
    }

    menuStore.setSavedScroll();
    menuStore.setOpenedModule("projects");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/projects", "Проекты");
  }, []);

  useEffect(() => {
    if (
      projectsListStore.page !== 1 &&
      projectsListStore.page <= projectsListStore.maxPage &&
      projectsListStore.page !== projectsListStore.prevPage
    ) {
      projectsListStore.getMoreProjects();
    }
  }, [projectsListStore.page, projectsListStore.maxPage]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={projectsListStore.isLoading}
        isLoadingForFilters={projectsListStore.isLoadingForFilters}
        filters={projectsListStore.filters}
        searchValue={projectsListStore.searchValue}
        setSearchValue={projectsListStore.setSearchValue}
        getData={async () => {
          return;
        }}
        selectedFilter={projectsListStore.selectedFilter}
        setSelectedFilter={projectsListStore.setSelectedFilter}
        setFilters={async () => {
          return;
        }}
        cols={projectsListStore.staffTableCols}
        params={projectsListStore.staffTableParams}
        allCols={projectsListStore.staffColsAll}
        currentCols={[]}
        getDataWithFilter={() => {
          return;
        }}
        downloadedFilter={projectsListStore.downloadedFilter}
        setDownloadedFilter={projectsListStore.setDownloadedFilter}
        selectMulti={["position", "building", "company"]}
        isSearchable
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !projectsListStore.filters[projectsListStore.downloadedFilter]?.[
              "general"
            ] || projectsListStore.searchValue
          ),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="Быстрый поиск находится в разработке"
      >
        <ProjectsListTable />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(ProjectsList);
