import styles from "./buildingOneInformation.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadedComponent from "widgets/LoadedComponent";
import BuildingOneInfo from "./BuildingOneInfo";
import BuildingOnePhoto from "./BuildingOnePhoto";
import BuildingOneWorkshiftForm from "../BuildingOneWorkshiftForm";
import BuildingOneMap from "./BuildingOneMap";
import iconDefaultMap from "shared/assets/images/mainIcons/iconDefaultMap.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const BuildingOneInformation = () => {
  const { id } = useParams();
  const { menuStore, buildingOneStore, buildingOneAddWorkshiftFormStore } =
    useStores();

  useEffect(() => {
    buildingOneStore.setSelectedBuildingForInfo(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("buildingOne");
    menuStore.setOpenedSubmodule("buildingOne");
    menuStore.updateWindow({
      mainPath: `/building/id=${id}`,
      path: `/building/id=${id}`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={buildingOneStore.isLoading}
      error={buildingOneStore.error[id] as boolean}
      errorMessage={buildingOneStore.errorText[id]}
    >
      <>
        {Object.keys(buildingOneStore.selectedOne).length ? (
          <div className={styles.page}>
            <p className={styles.title}>
              {buildingOneStore.selectedOne?.title}
            </p>
            <div className={styles.main}>
              <div className={styles.colInfo}>
                <p className={styles.selectedOneTitle}>Описание</p>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BuildingOneInfo />
                </ErrorBoundary>
              </div>
              <div className={styles.colWidgets}>
                <div>
                  <p className={styles.widgetTitle}>Карта</p>
                  {buildingOneStore.selectedOne.lantitude &&
                  buildingOneStore.selectedOne.longitude ? (
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <BuildingOneMap />
                    </ErrorBoundary>
                  ) : (
                    <img src={iconDefaultMap} />
                  )}
                </div>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BuildingOnePhoto />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {buildingOneAddWorkshiftFormStore.isOpenedFromSubmodule === "card" ? (
            <BuildingOneWorkshiftForm />
          ) : (
            ""
          )}
        </ErrorBoundary>
      </>
    </LoadedComponent>
  );
};

export default observer(BuildingOneInformation);
