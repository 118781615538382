import { classNames } from "shared/utils/helpers/classNames";
import styles from "./badge.module.scss";
import StatusIcon from "../StatusIcon";

type BadgeProps = {
  /**
   * Цвет для badge
   */
  colorName: string;
  /**
   * Текст для badge
   */
  text: string;
  /**
   * Цвет для текста
   */
  textColorName?: string;
  /**
   * Имя иконки
   */
  icon?: string;
  /**
   * Цвет для иконки
   */
  iconColor?: string;
  /**
   * Нужно ли отображать иконку, контрастно фону (правила для контрастов прописаны scss-файле компонента StatusIcon)
   */
  isNegativeIcon?: boolean;
  /**
   * Размер шрифта - 16px (по умолчаню 14px)
   */
  mediumTextSize?: boolean;
};

export const Badge = ({
  icon,
  iconColor,
  isNegativeIcon,
  colorName,
  text,
  textColorName,
  mediumTextSize
}: BadgeProps) => {
  return (
    <div
      className={classNames(styles.badge, {
        [styles[`badge_${colorName}`]]: colorName,
        [styles.badgeWithIcon]: icon && iconColor,
        [styles.mediumTextSize]: mediumTextSize,
        [styles[`badgeText_${textColorName}`]]: textColorName,
        [styles.noPadding]: colorName === "none"
      })}
    >
      {icon && iconColor && (
        <StatusIcon icon={icon} color={iconColor} negative={isNegativeIcon} />
      )}
      {text}
    </div>
  );
};
