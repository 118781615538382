import styles from "./buildingOneStaffHeader.module.scss";
import { observer } from "mobx-react-lite";

import { useRef } from "react";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";

const BuildingOneStaffHeader = () => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  const ref = useRef();
  useOnClickOutside({
    ref,
    handler: () => buildingOneStaffStore.changeOpenedType("export")
  });

  return (
    <>
      <div className="d-flex align-items-center">
        {buildingOneStaffStore.openedType === "edit" ? (
          <div style={{ marginRight: "20px" }}>
            <ButtonsGroupForFormEdit
              grayBtnTitle="Отмена"
              blueBtnTitle="Сохранить"
              blueBtnDisabled={!buildingOneStaffStore.dirty[id]}
              blueBtnOnClick={() => {
                buildingOneStaffStore.setDirty();
                buildingOneStaffStore.changeOpenedType("edit");
              }}
              grayBtnOnClick={() =>
                buildingOneStaffStore.changeOpenedType("edit")
              }
            />
          </div>
        ) : Object.values(buildingOneStaffStore.eventTypes)[0]?.custom
            ?.allow_edit_bool ? (
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              buildingOneStaffStore.changeOpenedType("edit");
            }}
            id="BuildingOneStaffHeader_editButton"
          >
            Редактировать <StatusIcon icon="iconedit" color="bw-gray5" />
          </button>
        ) : (
          ""
        )}
        {/* Поиск по параметрам временно заблочен */}
        {/* <button
          type="button"
          className={`${styles.button} ${
            buildingOneStaffStore.openedType === "search"
              ? styles.button_active
              : ""
          }`}
          id="BuildingOneStaffHeader_searchButton"
          onClick={() => buildingOneStaffStore.changeOpenedType("search")}
        >
          Искать по параметрам <StatusIcon icon="iconsearch" color="bw-gray5" />
        </button> */}
        {buildingOneStaffStore.openedType === "export" ? (
          <div className={styles.formatForm} ref={ref}>
            <button className={styles.formatFormBtn}>
              Windows-1251 (Excel)
            </button>
            <button className={styles.formatFormBtn}>UTF-8</button>
          </div>
        ) : (
          ""
        )}
        {/* TODO: добавить экспорт таблицы */}
        <Tooltip
          color="blue-lazure"
          text="Раздел находится в разработке"
          placement="top"
          withoutFlip
        >
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              buildingOneStaffStore.changeOpenedType("export");
            }}
            id="BuildingOneStaffHeader_exportButton"
          >
            Экспортировать <StatusIcon icon="iconexportbw" color="bw-gray5" />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default observer(BuildingOneStaffHeader);
