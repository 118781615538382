import React from "react";
import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconAddWithoutFill } from "shared/assets/images/mainIcons/iconAdd/iconAddWithoutFill.svg";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styles from "./photoAltGalleryUploadSection.module.scss";

type UploadSectionProps = {
  getRootProps: (
    props?: DropzoneRootProps
  ) => DropzoneRootProps & React.HTMLAttributes<HTMLDivElement>;
  getInputProps: (
    props?: DropzoneInputProps
  ) => DropzoneInputProps & React.InputHTMLAttributes<HTMLInputElement>;
  open: () => void;
};

const PhotoAltGalleryUploadSection = ({
  getRootProps,
  getInputProps,
  open
}: UploadSectionProps) => (
  <>
    <div className={styles.addPlaceBorder}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <div className={styles.iconAddWrapper}>
          <div className={styles.iconAdd}>
            <IconImage className={styles.iconAddFotoDefault} />
            <IconAddWithoutFill className={styles.iconAddWithoutFill} />
          </div>
        </div>
        <input {...getInputProps()} />
        <p className={styles.addPlaceText}>
          Перетащите изображение или загрузите с компьютера
        </p>
        <div className={styles.flexColumn}>
          <em>Только файлы с расширениями .jpg, .jpeg, .png</em>
          <em>Объём файла не должен превышать 20МБ</em>
        </div>
      </div>
    </div>
    <div className={styles.uploadBtnBox}>
      <button
        type="button"
        onClick={open}
        className={styles.uploadBtn}
        id="PhotoAltGalleryUploadSection_uploadPhoto"
      >
        Загрузить с компьютера
      </button>
    </div>
  </>
);

export default PhotoAltGalleryUploadSection;
