import styles from "./loading.module.scss";

type LoadingProps = {
  withoutText?: boolean;
};

const Loading = ({ withoutText }: LoadingProps) => {
  return (
    <div className={styles.loadingContainer} data-loading={true}>
      <div className={styles.loadingModal}>
        {!withoutText ? (
          <p className={styles.loadingText}>
            Пожалуйста, подождите, идет загрузка данных
          </p>
        ) : (
          ""
        )}
        <div className={styles.loading}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
