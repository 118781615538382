import styles from "./error.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStores } from "stores/index";

import iconArrow from "shared/assets/images/mainIcons/iconArrow.svg";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type ErrorProps = {
  error: boolean;
  errorText?: string;
};

const Error = ({ error, errorText }: ErrorProps) => {
  const navigate = useNavigate();
  const { menuStore, apiStore } = useStores();

  const closeAll = () => {
    menuStore.setOpenedSubmodule("");
    menuStore.setAllModules(true);
    navigate("/main");
    menuStore.deleteWindow("all");
    menuStore.setSelectedWindow("all");
  };

  return (
    <div className={styles.error}>
      <div className={styles.errorCode}>
        {apiStore.errorCodeMessage?.code || ""}
      </div>
      <div className={styles.errorHeader}>Ошибка</div>{" "}
      <div className={styles.errorText}>
        {apiStore.errorCodeMessage?.message?.length
          ? apiStore.errorCodeMessage.message
          : error
          ? errorText
            ? errorText
            : "Что-то пошло не так..."
          : ""}
      </div>
      <div className={styles.btnGroup}>
        <Button
          id="Error_comeBack"
          theme={ButtonTheme.SECONDARY}
          size={ButtonSize.L}
          onClick={() =>
            window.history.state.idx === 1
              ? navigate(-2)
              : window.history.state.idx === undefined
              ? navigate(0)
              : navigate(-1)
          }
        >
          <img src={iconArrow} alt="" className={styles.arrowImg} />
          Вернуться назад
        </Button>
        <Button
          id="Error_comeToMain"
          onClick={() => closeAll()}
          size={ButtonSize.L}
        >
          Смотреть все разделы
        </Button>
      </div>
    </div>
  );
};

export default observer(Error);
