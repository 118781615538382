import styles from "./errorFallBack.module.scss";
import { useErrorBoundary } from "react-error-boundary";
import iconUpdate from "shared/assets/images/mainIcons/iconUpdate/iconUpdate.svg";
import { Button, ButtonTheme } from "shared/ui/Button";

const ErrorFallback = () => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className={styles.error}>
      <div className={styles.errorText}>Ошибка обработки данных</div>
      <Button
        id="ErrorFallback_resetError"
        theme={ButtonTheme.SECONDARY}
        className={styles.btn}
        onClick={resetBoundary}
      >
        <img src={iconUpdate} className={styles.img} alt="" />
        Попробовать еще раз
      </Button>
    </div>
  );
};

export default ErrorFallback;
