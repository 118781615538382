import styles from "./message.module.scss";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { fileUrl } from "stores/utils/consts";
import Modal from "shared/ui/Modal";
import { ReactComponent as IconClip } from "shared/assets/images/mainIcons/iconClip/iconClip.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/Avatar.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { Comment } from "stores/Comments/types/Comment";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

type MessageProps = {
  message: Comment;
  deleteMessage?: (rid: string, id: string) => void;
  withIcon?: boolean;
  icon?: string;
  // пропс нужен для использования в сторибуке, чтобы не ломался путь из-за fileUrl
  // и нормально отображались файлы в примерах
  forStorybook?: boolean;
};

const Message = ({
  message,
  deleteMessage,
  withIcon,
  icon,
  forStorybook
}: MessageProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { id } = useParams();

  let fileName: string;
  let url: string | null = null;
  let isImage = false;

  if (!isEmpty(message.files)) {
    fileName = getValues(message.files)[0]?.title;
    url = getValues(message.files)[0]?.path;
    const ext = fileName?.split(".")?.pop();
    isImage = ["jpg", "jpeg", "png", "gif"].indexOf(ext) !== -1;
  }

  return (
    <>
      <div className={styles.border}>
        <div className={styles.messageAvatarContainer}>
          {withIcon ? (
            <img
              src={icon ? `${fileUrl}${icon}` : Avatar}
              className={styles.avatar}
            />
          ) : (
            ""
          )}
          <div className={styles.message}>
            <div className={styles.messageData}>
              <div className={styles.author}>{message.author?.title || ""}</div>
              <div className={styles.date}>
                {getFormattedDate(message.time_create)
                  .replace(/ /g, ", в ")
                  .slice(0, 19)}
              </div>
            </div>
            <div className={styles.messageContent}>
              {isImage && (
                <a
                  href={forStorybook ? url : fileUrl + url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.imageFile}
                  id={`Message_imageLink_${message.id}`}
                >
                  <img
                    src={forStorybook ? url : fileUrl + url}
                    className={styles.image}
                  />
                </a>
              )}
              {message ? (
                <div className={styles.text}>{message.comment}</div>
              ) : (
                <div className={styles.noText}></div>
              )}
            </div>
            {url && !isImage ? (
              <a
                href={forStorybook ? null : fileUrl + url}
                target="_blank"
                rel="noreferrer"
                className={styles.uploadedFile}
                key={message.id}
                id={`Message_fileLink_${message.id}`}
              >
                <IconClip className={styles.iconClip} />
                {fileName}
              </a>
            ) : null}
          </div>
        </div>
        {deleteMessage && (
          <div className={styles.btnContainer}>
            <Button
              type="button"
              className={styles.basketBtn}
              theme={ButtonTheme.SECONDARY}
              size={ButtonSize.S}
              id={`Message_deleteButton_${message.id}`}
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <IconBasket className={styles.basketBtnImg} />
            </Button>
          </div>
        )}
      </div>

      <Modal
        type="clarification"
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        title="Вы уверены, что хотите удалить комментарий?"
        btnWithCrossTitle="Удалить"
        btnWithCrossOnClick={() => {
          deleteMessage(id, message.id);
        }}
        greyBtnOnClick={() => {
          setShowDeleteModal(false);
        }}
        greyBtnTitle="Отмена"
        btnWithCrossImg
      />
    </>
  );
};

export default Message;
