import * as yup from "yup";
import { stringSchema } from "shared/utils/validation/validation";

export const checkIfFileIsTooBig = (
  file: { name: string; size: number; type: string } | null
) => {
  let valid = true;
  if (file) {
    const size = file.size / 1024 / 1024;
    if (size > 20) {
      valid = false;
    }
  }
  return valid;
};

export const checkIfFileIsCorrectType = (
  file: { name: string; size: number; type: string } | null
) => {
  if (!file) return true;
  const ext = file.name.split(".").pop();
  const isRightType = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "ico",
    "csv",
    "xls",
    "xlsx",
    "txt",
    "doc",
    "docx",
    "pdf",
    "cdr",
    "zip"
  ].includes(ext);
  return isRightType;
};

const commentValidation = yup.object().shape({
  comment: stringSchema
    .required("Комментарий обязателен")
    .min(3, "Минимум 3 знака"),
  file: yup
    .mixed()
    .nullable()
    .test("is-big-file", "Файл должен быть не более 20Мб", checkIfFileIsTooBig)
    .test("is-wrong-file", "Неверный тип файла", checkIfFileIsCorrectType)
});

export default commentValidation;
