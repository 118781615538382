import { Field, useFormikContext } from "formik";
import styles from "./checkbox.module.scss";

type CheckboxProps = {
  id?: string;
  name: string;
  disabled?: boolean;
  withoutSetFieldValue?: boolean;
  value?: string | number | boolean;
  onChange?: (event: React.ChangeEvent) => void;
};

const Checkbox = ({
  id,
  name,
  disabled,
  value,
  onChange,
  withoutSetFieldValue
}: CheckboxProps) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field
      type="checkbox"
      className={styles.checkbox}
      id={id}
      name={name}
      value={value}
      onChange={(event: React.ChangeEvent) => {
        !withoutSetFieldValue &&
          setFieldValue(name, event.target["checked"] ? 1 : 0);
        onChange && onChange(event);
      }}
      disabled={disabled}
    />
  );
};

export default Checkbox;
