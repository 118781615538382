import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Errors } from "stores/utils/types/ErrorsType";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import data from "./staffRetentionData.json";
import { BuildingEventType } from "./types/BuildingEventType";
import { Col } from "./types/Col";
import { HoldingEduItem } from "./types/HoldingEduItem";
import { StaffList } from "./types/StaffList";
import { WithholdingEdu } from "./types/WithholdingEdu";

type Operations = {
  id: string;
  time_create: string;
  uid: string;
  type: string;
  author: number;
  money: number;
  comment: string;
  time_spending_formatted: string;
  building_title: string;
  object: string;
  user_title: string;
  connect: {
    [key: string]: string;
  };
  event: {
    [key: string]: string;
  };
};

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

export default class StaffRetentionListStore {
  error = false;
  isLoading = false;
  isLoadingForItem = false;
  isLoadingForFilters = false;
  isLoadingForStaffList = false;
  errorMessage = "";
  errorsMessage = {} as Errors["message"];

  selectedStaffName = "";
  holdingEduList: HoldingEduItem[] = [];
  statusList: WithholdingEdu["statusList"] = {};

  selectedHoldingEdu: WithholdingEdu["widget"] = {};
  selectedOperations: { [key: string]: Operations } = {};
  buildingEventsType: { [key: string]: BuildingEventType } = {};

  // сумма для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  money = 0;
  // количество смен для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  ws_num = 0;

  staffRetentionCols: { [key: string]: Col } = {};
  currentTitles: string[] = [];

  page = 1;
  onPage = 100;
  maxPage = 0;
  prevPage = 1;

  staffList: { title: string; id: string }[] = [];
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";

  filters: { [key: string]: FilterType } = {};

  //удалить, когда появится апи
  staffTableCols: { [key: string]: Col } = {};
  staffTableParams: { [key: string]: Col } = {};
  staffColsAll: { [key: string]: Col } = {};
  filterParams = {};

  openedFilter = false;
  searchValue = "";
  selectedFilter = "";
  downloadedFilter = "";
  foundCounter = 0;

  getData = () => {
    this.errorMessage = "";
    this.staffRetentionCols = data["cols"];
    this.currentTitles = data["show_fields"];
    Promise.all([
      !Object.keys(this.statusList).length && this.getStatusList(),
      !Object.keys(this.buildingEventsType).length &&
        this.getBuildingEventsType(),
      this.getSettings(),
      this.getWithHoldingEduList()
    ]).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };

  getWithHoldingEduList = async () => {
    this.page = 1;
    this.holdingEduList = [];
    this.isLoading = true;

    try {
      const data: ApiResponse<never> & {
        records: { [key: string]: HoldingEduItem };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          s: this.searchValue
        }
      });

      runInAction(() => {
        if (Object.values(data.records).length) {
          this.holdingEduList = Object.values(data.records);
        } else {
          this.holdingEduList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.maxPage = data["nav"]["max_page"];
        this.foundCounter = data["nav"]["count"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getMoreWithHoldingEduList = async () => {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const data: ApiResponse<never> & {
        records: { [key: string]: HoldingEduItem };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          s: this.searchValue
        }
      });

      runInAction(() => {
        if (Object.values(data.records).length) {
          this.holdingEduList.push(...Object.values(data.records));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.holdingEduList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getStatusList = async () => {
    try {
      const data: {
        records: WithholdingEdu["statusList"];
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduStatusList"
      });
      runInAction(() => {
        if (Object.values(data.records).length) {
          this.statusList = data.records;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSettings = async () => {
    try {
      const data: ApiResponse<boolean> & {
        default: {
          money: number;
          ws_num: number;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduSettings"
      });

      runInAction(() => {
        if (data.result) {
          this.money = data.default.money;
          this.ws_num = data.default.ws_num;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getHoldingEduItemHistory = async (uid: string) => {
    this.isLoadingForItem = true;
    try {
      const data: ApiResponse<WithholdingEdu["widget"]> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getWEduVersions",
          params: {
            uid
          }
        });

      runInAction(() => {
        if (!("message" in data)) {
          this.selectedHoldingEdu = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForItem = false;
      });
    }
  };

  getHoldingEduItemOperations = async (uid: string) => {
    this.isLoadingForItem = true;
    this.selectedOperations = {};
    try {
      const data: ApiResponse<{
        [key: string]: { [key: string]: Operations };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduOperationsAll",
        params: {
          uid
        }
      });

      runInAction(() => {
        Object.keys(data.result).forEach((key) => {
          if (Object.values(data.result[key]).length) {
            this.selectedOperations = { ...data.result[key] };
          }
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForItem = false;
      });
    }
  };

  getBuildingEventsType = async () => {
    try {
      const data: ApiResponse<{ [key: string]: BuildingEventType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          this.buildingEventsType = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setWithoutEdu = async (uid: string, act: "set" | "unset") => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "setWithoutEdu",
        body: { uid, act }
      });

      if (data["result"]) {
        this.getWithHoldingEduList();
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
      } else {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  addHoldingEdu = async (
    values: {
      [key: string]: string | number;
    },
    uid?: string
  ) => {
    const formData: {
      [key: string]: string | number;
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      switch (value) {
        case null:
          formData[key] = "";
          break;
        default:
          formData[key] = value;
      }
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "addWEduRecord",
        body: { ...formData, uid: uid ? uid : (formData["uid"] as string) }
      });

      if (data["result"]) {
        this.getWithHoldingEduList();
        this.rootStore.staffOneStore.setRebootStaff(
          uid ? uid : (formData["uid"] as string),
          true
        );
        this.clearErrorsMessage();
      } else {
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage = data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  editWEduRecord = async (
    values: {
      [key: string]: string | number;
    },
    id: string
  ) => {
    const formData: {
      [key: string]: string | number;
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      switch (value) {
        case null:
          formData[key] = "";
          break;
        default:
          formData[key] = value;
      }
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "editWEduRecord",
        body: { ...formData, id }
      });

      if (data["result"]) {
        this.getWithHoldingEduList();
        this.clearErrorsMessage();
      } else {
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage = data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  clearErrorsMessage = () => {
    this.errorsMessage = {} as Errors["message"];
  };

  getStaffFilters = async (action?: string, id?: string) => {
    this.page = 1;

    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        page: this.page,
        params: {
          fast_search: this.searchValue,
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (
          Object.values(data["filters"]).length &&
          (!Object.keys(this.filters).length || action)
        ) {
          Object.entries(data["cols"]).forEach(([key, col]) => {
            if (col["showAsColumn"]) this.staffTableCols[key] = col;
            if (col["useAsFilter"]) this.staffTableParams[key] = col;
          });

          if (Object.keys(data["selects"]).length) {
            Object.entries(data["selects"]).forEach(([key, value]) => {
              const directory = {};

              switch (key) {
                case "company":
                case "building":
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    };
                  });
                  break;
                case "position":
                  Object.values(value).forEach((company: object) => {
                    Object.values(company).forEach((position) => {
                      directory[position.id ? position.id : position.title] = {
                        newname: position.id ? position.id : position.title,
                        title: `${position.title} (${
                          Object.values(data["selects"]["company"]).filter(
                            (company) => company.id === position.company
                          )[0].title
                        })`
                      };
                    });
                  });
                  break;
                default:
                  Object.values(value).forEach((item) => {
                    directory[item.id ? item.id : item.title] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    };
                  });
                  break;
              }

              this.staffTableParams[key] = {
                ...this.staffTableParams[key],
                directory: directory
              };
            });
          }

          this.staffColsAll = data["cols"];

          this.filters = {};
          Object.entries(data["filters"]).forEach(([key, filter], index) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["cols"] = filter["filterdata"]["cols"]
              ? Object.values(filter["filterdata"]["cols"])
              : [];
            this.filters[key]["id"] = key;

            if (action) {
              switch (action) {
                case "add":
                  if (Object.entries(data["filters"]).length - 2 === index) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "edit":
                  if (id === key) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "delete":
                  if (this.filters[key]["default"]) {
                    this.setSelectedFilter(key);
                  }
                  break;
              }
            } else {
              if (this.filters[key]["default"]) {
                this.setSelectedFilter(key);
              }
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForFilters = false;
      });
    }
  };

  setSelectedStaffName = (value: string) => {
    this.selectedStaffName = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setOpenedFilter = (value: boolean) => {
    this.openedFilter = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; id: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj[key] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.staffList = this.dataPreparation(Object.values(data["result"]));
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff,
            only_records: 1
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.staffList.push(
            ...this.dataPreparation(Object.values(data["result"]))
          );
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
