import { makeAutoObservable, runInAction } from "mobx";
import data from "./data.json";

import RootStore from "stores";
import { Col } from "./types/Col";
import { Filter } from "./types/Filter";
import { Records } from "./types/Records";

export default class PolatiAppStore {
  error = false;

  isLoading = false;

  filters: { [key: string]: Filter } = {};

  polatiAppData: { [key: string]: Records } = {};
  polatiAppCols: { [key: string]: Col } = {};
  currentTitles: string[] = [];

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  setData = () => {
    runInAction(() => {
      this.isLoading = true;
    });

    setTimeout(() => {
      runInAction(() => {
        this.polatiAppData = data["records"];
        this.polatiAppCols = data["cols"];
        this.currentTitles = Object.values(data["show_fields"]);
      });

      const objectOfFilters = {};
      Object.entries(data["filters"]).forEach(([key, value]) => {
        objectOfFilters[key] = value["filterdata"];
        objectOfFilters[key]["cols"] = Object.values(
          value["filterdata"]["cols"]
        );
        objectOfFilters[key]["id"] = key;
      });

      runInAction(() => {
        this.filters = objectOfFilters;
        this.isLoading = false;
      });
    }, 1000);
  };

  getDataWithFilter = (filter: Filter) => {
    if (filter.params && Object.keys(filter.params).length) {
      const arrayOfFilter = Object.entries(data["records"]).filter(
        (item) => item[1].status === Object.entries(filter.params)[0][1]
      );
      this.polatiAppData = Object.fromEntries(arrayOfFilter);
    } else {
      this.polatiAppData = data["records"];
    }
  };

  setStatus = (id: string, status: string) => {
    for (const prop in this.polatiAppData) {
      if (this.polatiAppData[prop]["id"] === id) {
        this.polatiAppData[prop]["status"] = status;
      }
    }
    return this.polatiAppData;
  };

  resetAll = () => {
    this.filters = {};
    this.polatiAppData = {};
    this.polatiAppCols = {};
    this.currentTitles = [];
  };
}
