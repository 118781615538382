import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import LoadedComponent from "widgets/LoadedComponent";
import SalaryPaymentListOneInfoFilters from "./SalaryPaymentListOneInfoFilters";
import SalaryPaymentListOneInfoHeader from "./SalaryPaymentListOneInfoHeader";
import SalaryPaymentListOneInfoCard from "./SalaryPaymentListOneInfoCard";

import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const SalaryPaymentlistOneInfo = () => {
  const { id } = useParams();
  const { menuStore, salaryPaymentlistOneStore } = useStores();
  const [isSelectedPayment, setIsSelectedPayment] = useState(false);

  const getStatusIcon = (status_id: string) => {
    return (
      <StatusIcon
        icon={
          salaryPaymentlistOneStore.paymentStatuses[status_id]?.custom.done ===
          "on"
            ? "coins"
            : salaryPaymentlistOneStore.paymentStatuses[status_id]?.custom
                .block_when_ws_open &&
              salaryPaymentlistOneStore.paymentStatuses[status_id]?.custom
                .transfer_employee
            ? "like"
            : "circlecheck"
        }
        color={
          salaryPaymentlistOneStore.paymentStatuses[status_id]?.custom.color
        }
      />
    );
  };

  useEffect(() => {
    setIsSelectedPayment(true);
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (
        value["parent_path"] &&
        value["parent_path"] !== `/salary/paymentlist`
      ) {
        menuStore.deleteWindow(key);
      }
    });

    salaryPaymentlistOneStore.setSelectedOneOfPaymentlist(id);

    menuStore.setOpenedModule("paymentlistOne");
    menuStore.setOpenedSubmodule("paymentlistOne");
    menuStore.sendTabId(id);

    menuStore.updateWindow({
      mainPath: `/salary/paymentlist/id=${id}`,
      path: `/salary/paymentlist/id=${id}`
    });

    setTimeout(() => {
      setIsSelectedPayment(false);
    }, 300);
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryPaymentlistOneStore.isLoading}>
      {Object.values(salaryPaymentlistOneStore.selectedOnePayment).length &&
      Object.values(salaryPaymentlistOneStore.paymentCols).length &&
      Object.values(salaryPaymentlistOneStore.paymentParams).length &&
      Object.values(salaryPaymentlistOneStore.paymentStatuses).length ? (
        <div>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SalaryPaymentListOneInfoHeader
              isSelectedPayment={isSelectedPayment}
              getStatusIcon={getStatusIcon}
            />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SalaryPaymentListOneInfoCard getStatusIcon={getStatusIcon} />
          </ErrorBoundary>

          {/* Фильтры отображаются только для ведомостей на объекты */}
          {!salaryPaymentlistOneStore.selectedOnePayment.staff?.id ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <SalaryPaymentListOneInfoFilters />
            </ErrorBoundary>
          ) : null}
        </div>
      ) : null}
    </LoadedComponent>
  );
};

export default observer(SalaryPaymentlistOneInfo);
