import { Button, ButtonSize } from "shared/ui/Button";
import styles from "./windowHeader.module.scss";
import StatusIcon from "shared/ui/StatusIcon";

type WindowHeaderProps = {
  title: string;
  subtitle: string;
  closeWindowFunc: () => void;
};

const WindowHeader = ({
  title,
  subtitle,
  closeWindowFunc
}: WindowHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.titlesContainer}>
        <div className={styles.title}>{title}</div>
        {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : ""}
      </div>

      <Button size={ButtonSize.S} onClick={closeWindowFunc}>
        <StatusIcon icon="iconclose" color="bw-white" />
      </Button>
    </div>
  );
};

export default WindowHeader;
