import styles from "./salaryOperationsForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, Fragment } from "react";
// import { Link } from "react-router-dom";

import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import Tooltip from "shared/ui/Tooltip";
import Select from "shared/ui/Inputs/Select";
import NumberFormat from "react-number-format";
import LoadedComponent from "widgets/LoadedComponent";

import { ReactComponent as IconAuto } from "shared/assets/images/mainIcons/iconAuto.svg";
// import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { ReactComponent as IconDash } from "shared/assets/images/iconStatus/Dash.svg";
import { ReactComponent as IconNotice } from "shared/assets/images/iconStatus/Notice.svg";

import { fileUrl } from "stores/utils/consts";
import Textarea from "shared/ui/Inputs/Textarea";
import Switch from "shared/ui/Inputs/Switch";

import { SalaryOperation } from "stores/SalaryModule/salaryOperations/types/SalaryOperation";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonTheme } from "shared/ui/Button";

type SalaryOperationsFormProps = {
  values: {
    [key: string]: string | number;
  };
  openedListName: string;
  changeOpenedListName: (arg: string) => void;
  setOpenedModal?: (arg: string) => void;

  operation?: Partial<SalaryOperation>;
  id?: string;
  isEdit?: boolean;
};

const SalaryOperationsForm = ({
  values,
  operation,
  // id,
  openedListName,
  changeOpenedListName,
  setOpenedModal,
  isEdit
}: SalaryOperationsFormProps) => {
  const { salaryOperationsFormStore } = useStores();

  useEffect(() => {
    salaryOperationsFormStore.getDataForForm();
  }, []);

  useEffect(() => {
    if (
      salaryOperationsFormStore.pageStaff !== 1 &&
      salaryOperationsFormStore.pageStaff <=
        salaryOperationsFormStore.maxPageStaff &&
      salaryOperationsFormStore.pageStaff !==
        salaryOperationsFormStore.prevPageStaff
    ) {
      salaryOperationsFormStore.getMoreStaff();
    }
  }, [
    salaryOperationsFormStore.pageStaff,
    salaryOperationsFormStore.maxPageStaff
  ]);

  useEffect(() => {
    if (
      salaryOperationsFormStore.pageBuilding !== 1 &&
      salaryOperationsFormStore.pageBuilding <=
        salaryOperationsFormStore.maxPageBuilding &&
      salaryOperationsFormStore.pageBuilding !==
        salaryOperationsFormStore.prevPageBuilding
    ) {
      salaryOperationsFormStore.getMoreBuildingList();
    }
  }, [
    salaryOperationsFormStore.pageBuilding,
    salaryOperationsFormStore.maxPageBuilding
  ]);

  return Object.values(salaryOperationsFormStore.cols).length ? (
    <LoadedComponent isLoading={salaryOperationsFormStore.isLoading}>
      <Fragment>
        {Object.values(values).length
          ? Object.keys(values).map((title, index) => {
              const col = salaryOperationsFormStore.cols[title];
              const param = salaryOperationsFormStore.params[title];

              const checkIfFieldDisabled = (name: string) => {
                return (
                  salaryOperationsFormStore.params[name]?.editable !== "on"
                );
              };

              const checkIfFieldRequired = (name: string) => {
                return Boolean(
                  salaryOperationsFormStore.params[name]?.required
                );
              };

              if (col) {
                switch (title) {
                  case "time_create":
                    return (
                      <div
                        key={`field_${title}`}
                        className={`${index > 0 ? styles.field : ""} ${
                          "time_spending" in values ? styles.doublefield : ""
                        }`}
                      >
                        <DatePickerField
                          name={title}
                          title={col.title}
                          isCalendarOpened={openedListName === title}
                          setIsCalendarOpened={() =>
                            changeOpenedListName(title)
                          }
                          disabled={checkIfFieldDisabled(title)}
                          required={checkIfFieldRequired(title)}
                          withClearBtn
                        />
                        {"time_spending" in values ? (
                          <DatePickerField
                            name="time_spending"
                            title={
                              salaryOperationsFormStore.cols.time_spending.title
                            }
                            isCalendarOpened={
                              openedListName === "time_spending"
                            }
                            setIsCalendarOpened={() =>
                              changeOpenedListName("time_spending")
                            }
                            disabled={checkIfFieldDisabled("time_spending")}
                            required={checkIfFieldRequired("time_spending")}
                            withClearBtn
                          />
                        ) : null}
                      </div>
                    );

                  case "time_spending":
                    return !("time_create" in values) ? (
                      <div
                        key={`field_${title}`}
                        className={`${index > 0 ? styles.field : ""} ${
                          styles.marginBottom
                        }`}
                      >
                        <DatePickerField
                          name={title}
                          title={col.title}
                          isCalendarOpened={openedListName === title}
                          setIsCalendarOpened={() =>
                            changeOpenedListName(title)
                          }
                          disabled={checkIfFieldDisabled(title)}
                          required={checkIfFieldRequired(title)}
                          withClearBtn
                        />
                      </div>
                    ) : null;

                  case "type":
                    return Object.values(
                      salaryOperationsFormStore.operationsTypes
                    ).length ? (
                      <div
                        key={`field_${title}`}
                        className={index > 0 ? styles.field : ""}
                      >
                        <Select
                          name={title}
                          title={col.title}
                          label={
                            values[title]
                              ? salaryOperationsFormStore.operationsTypes[
                                  values[title]
                                ]?.title
                              : ""
                          }
                          options={
                            salaryOperationsFormStore.operationsTypes as {
                              [key: string]: {
                                title: string;
                                [key: string]: string;
                              };
                            }
                          }
                          isFloating
                          disabled={checkIfFieldDisabled(title)}
                          required={checkIfFieldRequired(title)}
                          valueName="id"
                          withClearBtn
                          mark={
                            !isEdit &&
                            values[title] &&
                            salaryOperationsFormStore.operationsTypes[
                              values[title]
                            ]?.["custom"]["profit"] ? (
                              <span className={styles.type_success}>Доход</span>
                            ) : values[title] &&
                              !salaryOperationsFormStore.operationsTypes[
                                values[title]
                              ]?.["custom"]["profit"] ? (
                              <span className={styles.type_orange}>Расход</span>
                            ) : null
                          }
                        />

                        {values[title] &&
                        salaryOperationsFormStore.operationsTypes[
                          values[title]
                        ]["comment"]?.length ? (
                          <div className={styles.typecomment}>
                            <IconNotice />
                            {
                              salaryOperationsFormStore.operationsTypes[
                                values[title]
                              ]["comment"]
                            }
                          </div>
                        ) : null}
                      </div>
                    ) : null;

                  case "uid":
                  case "initiator":
                    return (
                      <div
                        key={`field_${title}`}
                        className={index > 0 ? styles.field : ""}
                      >
                        <Select
                          name={title}
                          title={col.title}
                          label={
                            values[title] && operation
                              ? `ТН${operation.uid.tn} ${
                                  operation.uid.surname
                                } ${operation.uid.name} ${
                                  operation.uid.patronymic || ""
                                }`
                              : ""
                          }
                          options={col.directory}
                          isFloating
                          required={checkIfFieldRequired(title)}
                          disabled={checkIfFieldDisabled(title)}
                          withClearBtn
                          isSearchable
                          isSearchWithPagination
                          page={salaryOperationsFormStore.pageStaff}
                          prevPage={salaryOperationsFormStore.prevPageStaff}
                          maxPage={salaryOperationsFormStore.maxPageStaff}
                          setPage={salaryOperationsFormStore.setPageStaff}
                          getList={salaryOperationsFormStore.getStaffList}
                          setSearchValue={
                            salaryOperationsFormStore.setSearchValueStaff
                          }
                          searchValue={
                            salaryOperationsFormStore.searchValueStaff
                          }
                          isLoading={
                            salaryOperationsFormStore.isLoadingForStaffList
                          }
                        />
                      </div>
                    );

                  case "object":
                    return (
                      <div
                        key={`field_${title}`}
                        className={index > 0 ? styles.field : ""}
                      >
                        <Select
                          name={title}
                          title={col.title}
                          label={
                            values[title] && operation
                              ? operation[title].title
                              : ""
                          }
                          options={col.directory}
                          isFloating
                          required={checkIfFieldRequired(title)}
                          disabled={checkIfFieldDisabled(title)}
                          withClearBtn
                          isSearchable
                          isSearchWithPagination
                          page={salaryOperationsFormStore.pageBuilding}
                          prevPage={salaryOperationsFormStore.prevPageBuilding}
                          maxPage={salaryOperationsFormStore.maxPageBuilding}
                          setPage={salaryOperationsFormStore.setPageBuilding}
                          getList={salaryOperationsFormStore.getBuildingsList}
                          setSearchValue={
                            salaryOperationsFormStore.setSearchValueBuilding
                          }
                          searchValue={
                            salaryOperationsFormStore.searchValueBuilding
                          }
                          isLoading={
                            salaryOperationsFormStore.isLoadingForBuildingList
                          }
                        />
                      </div>
                    );

                  case "money":
                    return (
                      <div
                        key={`field_${title}`}
                        className={index > 0 ? styles.field : ""}
                      >
                        <NumberFormat
                          name={title}
                          value={values[title] || 0}
                          label={col.title}
                          customInput={Input}
                          suffix=" ₽"
                          thousandSeparator=" "
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          allowEmptyFormatting
                          allowedDecimalSeparators={["."]}
                          disabled={checkIfFieldDisabled(title)}
                          required={checkIfFieldRequired(title)}
                          onClick={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (
                              event.target.selectionEnd ===
                              event.target.value.length - 2
                            ) {
                              event.target.selectionStart = 0;
                              event.target.selectionEnd = 0;
                            }
                          }}
                        />
                      </div>
                    );

                  case "connect":
                    return;
                  // раскомментировать когда будет готова задача по формированию ссылки из операции
                  /*                     (
                      <div
                        key={`field_${title}`}
                        className={`${index > 0 ? styles.field : ""} ${
                          styles.uneditable
                        }`}
                      >
                        <p>{col.title}</p>
                        <Link
                          to={`/salary/id=${id}`}
                          id={`SalaryOperationsOneEdit_${title}_${values[title]}`}
                        >
                          <IconFinance />
                          <p style={{ marginLeft: "5px" }}>Финансы</p>
                        </Link>
                      </div>
                    ); */

                  case "paymentlist":
                    return (
                      <div
                        key={`field_${title}`}
                        className={`${index > 0 ? styles.field : ""} ${
                          styles.uneditable
                        } ${
                          operation?.[title]?.status_title
                            ? styles.uneditable_btnInInput
                            : styles.uneditable_withoutValue
                        }`}
                      >
                        <p>{col.title}</p>
                        {operation?.[title]?.status_title ? (
                          <div className={styles.field__values}>
                            <Tooltip text="Перейти к ведомости">
                              <a
                                href={`${fileUrl}/crm/salary/?ps_id=${values[title]}`}
                                target="_blank"
                                rel="noreferrer"
                                id={`SalaryOperationsForm${title}_${values[title]}`}
                                className={styles.field__values__link}
                              >
                                <p>{operation[title]?.status_title}</p>
                              </a>
                            </Tooltip>

                            <Tooltip
                              color="accent-red"
                              text="Открепить от ведомости"
                            >
                              <IconBasket
                                id="SalaryOneOperationEdit_paymentlist"
                                onClick={() =>
                                  setOpenedModal &&
                                  setOpenedModal("paymentlist")
                                }
                              />
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                    );

                  case "cancelled":
                    return (
                      <div
                        key={`field_${title}`}
                        className={`${index > 0 ? styles.field : ""} ${
                          styles.cancelled
                        }`}
                      >
                        <div className={styles.uneditable}>
                          <p>{col.title}</p>
                          {operation?.[title] &&
                          Object.values(operation[title]).length ? (
                            <a
                              href={`${fileUrl}/crm/salary/?sd=${values[title]}`}
                              target="_blank"
                              rel="noreferrer"
                              id={`SalaryOperationsOneEdit_${title}_${values[title]}`}
                              className={`${styles.field__values} ${styles.field__values__link}`}
                            >
                              <p>{operation[title]}</p>
                            </a>
                          ) : (
                            <IconDash />
                          )}
                        </div>
                        {!operation?.[title] ? (
                          <Button
                            theme={ButtonTheme.CANCELLED}
                            className={styles.cancelledBtn}
                            onClick={() => setOpenedModal("cancel_operation")}
                          >
                            <StatusIcon
                              icon="iconcancel"
                              color="accent-orange"
                            />
                            Отменить операцию
                          </Button>
                        ) : null}
                      </div>
                    );
                  default:
                    if (col.type === "date" || col.type === "timestamp") {
                      return (
                        <div
                          key={`field_${title}`}
                          className={index > 0 ? styles.field : ""}
                        >
                          <DatePickerField
                            name={title}
                            title={col.title}
                            isCalendarOpened={openedListName === title}
                            setIsCalendarOpened={() =>
                              changeOpenedListName(title)
                            }
                            disabled={checkIfFieldDisabled(title)}
                            withClearBtn
                          />
                        </div>
                      );
                    } else if (col.type === "bool") {
                      return (
                        <div key={`field_${title}`} className={styles.field}>
                          <label className={styles.switch}>
                            <Switch
                              name={title}
                              disabled={checkIfFieldDisabled(title)}
                            />
                            {col.title}
                          </label>
                        </div>
                      );
                    } else if (param.isvariable && param.variable) {
                      return (
                        <div key={`field_${title}`} className={styles.field}>
                          <Select
                            name={title}
                            options={
                              param.variable as {
                                [key: string]: { title: string };
                              }
                            }
                            title={col.title}
                            label={
                              values[title]
                                ? param.variable[values[title]]?.title
                                : col.title
                            }
                            disabled={checkIfFieldDisabled(title)}
                            required={checkIfFieldRequired(title)}
                            valueName="id"
                            isFloating
                            withClearBtn
                          />
                        </div>
                      );
                    } else if (title.includes("comment")) {
                      return (
                        <div
                          key={`field_${title}`}
                          className={index > 0 ? styles.field : ""}
                        >
                          <Textarea
                            name={title}
                            label={col.title}
                            value={values[title] as string}
                            placeholder={col.title}
                            disabled={checkIfFieldDisabled(title)}
                            required={checkIfFieldRequired(title)}
                            withClearBtn
                            commentCol={salaryOperationsFormStore.cols[title]}
                          />
                        </div>
                      );
                    } else
                      return (
                        <div
                          key={`field_${title}`}
                          className={`${styles.field} ${
                            title === "author" && operation?.isautocomplete
                              ? styles.autocomplete
                              : ""
                          }`}
                        >
                          <Input
                            name={title}
                            label={col.title}
                            disabled={checkIfFieldDisabled(title)}
                            required={checkIfFieldRequired(title)}
                            withClearBtn
                          />
                          {title === "author" && operation?.isautocomplete ? (
                            <Tooltip text="Создано в автоматическом режиме">
                              <IconAuto />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                }
              }
            })
          : null}
      </Fragment>
    </LoadedComponent>
  ) : null;
};

export default observer(SalaryOperationsForm);
