import styles from "./salaryOperationsOneComments.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import CommentInputField from "features/Comments/CommentInputField";
import Message from "shared/ui/Message";
import { getEntries } from "shared/utils/helpers/getEntries";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";
import Alert from "shared/ui/Alert";

const SalaryOperationsOneComments = () => {
  const { id } = useParams();
  const { menuStore, salaryOperationOneStore, commentsStore } = useStores();

  useEffect(() => {
    getEntries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    salaryOperationOneStore.setSelectedOneOfSalaryOperation(id);

    menuStore.updateWindow({
      mainPath: `/salary/id=${id}`,
      path: `/salary/id=${id}/comments`
    });

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("salaryOne");
    menuStore.setOpenedSubmodule("salaryOneComments");
    !commentsStore.comments[id] && commentsStore.getComments(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={commentsStore.isLoading[id]}
      error={commentsStore.error[id]}
    >
      <>
        <PageTitle title="Комментарии" leftCol />

        <div className={styles.wrapper}>
          <div className={styles.commentsList}>
            {!isEmpty(commentsStore.comments[id]) ? (
              <ul>
                {getValues(commentsStore.comments[id]).map((comment) => (
                  <Message
                    key={comment.id}
                    message={comment}
                    deleteMessage={commentsStore.deleteComment}
                  />
                ))}
              </ul>
            ) : (
              <Alert
                errors={{
                  head: "У операции ещё нет комментариев",
                  color: "empty"
                }}
              />
            )}
          </div>
          <CommentInputField
            addComment={commentsStore.addComment}
            commentCol={
              salaryOperationOneStore.salaryOperationsOneCols?.comment
            }
          />
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(SalaryOperationsOneComments);
