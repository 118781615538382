import styles from "./phoneInputSelect.module.scss";
import { useFormikContext } from "formik";
import { getCountries, getExampleNumber, CountryCode } from "libphonenumber-js";
import Flag from "react-world-flags";
import Scrollbars from "react-custom-scrollbars-2";
import { MutableRefObject } from "react";
import examples from "libphonenumber-js/mobile/examples";
import countriesList from "./countriesList.json";
import { classNames } from "shared/utils/helpers/classNames";

type PhoneInputSelectProps = {
  name: string;
  selectedCountry: string;
  setSelectedCountry: (value: CountryCode) => void;
  inputRef: MutableRefObject<HTMLInputElement>;
  className: string;
};

const COUNTRIES = getCountries(); // список всех стран
// страны, которые отображаются первыми в списке
const PRIORITY_COUNTRIES: CountryCode[] = [
  "RU", // Россия
  "KZ", // Казахстан
  "BY", // Беларусь
  "AZ", // Азербайджан
  "AM", // Армения
  "KG", // Киргизия
  "MD", // Молдова
  "TJ", // Таджикистан
  "TM", // Туркмения
  "UZ", // Узбекистан
  "UA", // Украина
  "AE" // ОАЭ
];
const getCountryCallingCode = (country: CountryCode) =>
  getExampleNumber(country, examples).countryCallingCode;

const PhoneInputSelect = ({
  name,
  selectedCountry,
  setSelectedCountry,
  inputRef,
  className
}: PhoneInputSelectProps) => {
  const { setFieldTouched } = useFormikContext();
  const handleClickOnFlag = (country: CountryCode) => () => {
    if (selectedCountry !== country) {
      setSelectedCountry(country);
      setTimeout(() => setFieldTouched(name, true, true), 100);
    }
    setTimeout(() => inputRef.current?.focus(), 200);
  };
  const getOneCountry = (country: CountryCode, isOtherCountry?: boolean) => {
    if (isOtherCountry && PRIORITY_COUNTRIES.includes(country)) return;
    return (
      <div
        key={country}
        className={styles.country}
        onClick={handleClickOnFlag(country)}
        id={`PhoneInputSelect_countryBtn_${country}`}
      >
        <Flag
          // hardcode: для ["AC", "SH"] нет флагов, выбран похожий
          code={!["AC", "SH"].includes(country) ? country.toLowerCase() : "KY"}
          width="24px"
        />
        <div className={styles.countryName}>
          {countriesList[country.toLowerCase()]}
        </div>

        {/* отображение кода страны для приоритетных стран */}
        {PRIORITY_COUNTRIES.includes(country) ? (
          <div className={styles.countryCallingCode}>{`+${getCountryCallingCode(
            country as CountryCode
          )}`}</div>
        ) : null}
      </div>
    );
  };
  return (
    <div className={classNames(styles.selectContainer, {}, [className])}>
      <Scrollbars
        style={{ width: "100%" }}
        autoHeight
        autoHeightMax={"100%"}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        {PRIORITY_COUNTRIES.map((country) => getOneCountry(country))}
        {COUNTRIES.map((country) => getOneCountry(country, true))}
      </Scrollbars>
    </div>
  );
};

export default PhoneInputSelect;
