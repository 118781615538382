import styles from "./table.module.scss";
import { Table } from "react-bootstrap";
import { Property } from "csstype";
import { checkIsValidDate } from "shared/utils/helpers/checkIsValidDate";

type Row = {
  [key: string]: string | number;
};

type MidTableProps = {
  striped?: boolean;
  titles: string[];
  tableRowData: Row[];
  onClick?: (row: Row) => void;
  bigDate?: boolean;
  defaultMsg: () => JSX.Element;
  tableFooterData?: Partial<{
    totalText: string;
    totalSum: number | JSX.Element;
  }>;
  fixWidth?: { [key: string]: string };
  textAlign?: { [key: string]: Property.TextAlign };
  customHover?: boolean;
};

const MidTable = ({
  striped,
  titles,
  tableRowData,
  onClick,
  bigDate,
  defaultMsg,
  tableFooterData,
  fixWidth,
  textAlign,
  customHover
}: MidTableProps) => {
  let rowLength = 0;

  return tableRowData && tableRowData.length ? (
    <Table
      borderless
      className={`${styles.table} ${!customHover ? "table-hover" : ""}`}
      striped={striped}
    >
      <thead>
        <tr className={`${styles.tr} ${styles.thead}`}>
          {titles.map((title, i) => {
            return (
              <th key={`${title}-${i}`} className={styles.title}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {tableRowData.map((row, i) => {
          rowLength = Object.keys(row).length;
          return (
            <tr
              key={`${row.id}-${i}`}
              className={`${styles.tr} ${customHover ? styles.trHover : ""}`}
              onClick={() => onClick && onClick(row)}
              style={{
                cursor: onClick && "pointer"
              }}
              id={`midtable_openRow_${row.id}`}
            >
              {Object.keys(row).map((text, i) => {
                if (text === "id") return;

                return (
                  <td
                    key={`${row[text]}-${i}`}
                    className={
                      checkIsValidDate(String(row[text])) && !bigDate
                        ? styles.date
                        : styles.td
                    }
                    style={{
                      width: fixWidth ? fixWidth[text] : "max-content",
                      textAlign: textAlign ? textAlign[text] : null
                    }}
                  >
                    {row[text] || row[text] == "0" ? row[text] : "—"}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>

      {tableFooterData ? (
        <tfoot className={styles.tfoot}>
          <tr>
            {Object.keys(tableFooterData).map((row, i) => {
              return (
                <td
                  key={`${tableFooterData[row]}-${i}`}
                  colSpan={
                    typeof tableFooterData[row] === "string" && i === 0
                      ? Object.keys(tableFooterData).length === rowLength
                        ? 1
                        : +rowLength - +Object.keys(tableFooterData).length
                      : 1
                  }
                  className={
                    typeof tableFooterData[row] === "string"
                      ? styles.totalText
                      : styles.totalNum
                  }
                  style={{ textAlign: textAlign ? textAlign[row] : null }}
                >
                  {tableFooterData[row] || tableFooterData[row] === "0"
                    ? tableFooterData[row]
                    : "—"}
                </td>
              );
            })}
          </tr>
        </tfoot>
      ) : (
        ""
      )}
    </Table>
  ) : (
    defaultMsg()
  );
};

export default MidTable;
