import styles from "./staffOneSafetyWorkTableWindowHistory.module.scss";
import { ReactComponent as Attachment } from "shared/assets/images/mainIcons/iconClip/iconClipWithoutStroke.svg";
import { fileUrl } from "stores/utils/consts";
import { Col } from "stores/StaffModule/types/Col";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneSafetyWorkHistoryProps = {
  history: {
    [key: string]: {
      date_end: string;
      date_start: string;
      file: string | -1;
      id: string;
      kind_title: string;
      type_title: string;
    };
  };
  cols: {
    [key: string]: Col;
  };
};

const StaffOneSafetyWorkTableWindowHistory = ({
  history,
  cols
}: StaffOneSafetyWorkHistoryProps) => {
  const fields = ["kind", "type", "date_start", "date_end", "file"];

  return (
    <>
      {Object.values(history) && (
        <div className={styles.wrapper}>
          <div className={styles.heading}> История </div>
          <div className={styles.content}>
            {Object.values(history).map((cert) => {
              return (
                <div key={cert.id} className={styles.contentItem}>
                  <>
                    {fields.map((key) => {
                      switch (key) {
                        case "kind":
                        case "type":
                          return (
                            <div key={key}>
                              <span>{cols[key].title}:</span>
                              {cert[`${key}_title`]}
                            </div>
                          );
                        case "date_start":
                          return (
                            <div key={key} className={styles.rowContainer}>
                              <div>
                                <span>{cols[key].title}:</span>
                                {getFormattedDate(cert[key])}
                              </div>
                              {cols["date_end"] && (
                                <div>
                                  <span>{cols["date_end"].title}:</span>
                                  {getFormattedDate(cert["date_end"])}
                                </div>
                              )}
                              {cert["file"] && cert["file"] !== -1 && (
                                <div key={key} className={styles.fileContainer}>
                                  <Attachment className={styles.attachment} />
                                  <a
                                    href={fileUrl + cert["file"]}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    {cert["file"].slice(16)}
                                  </a>
                                </div>
                              )}
                            </div>
                          );

                        case "date_end":
                          if (!cols["date_start"]) {
                            return (
                              <div key={key} className={styles.rowContainer}>
                                <div>
                                  <span>{cols[key].title}:</span>
                                  {getFormattedDate(cert[key])}
                                </div>
                                {cert["file"] && cert["file"] !== -1 && (
                                  <div className={styles.fileContainer}>
                                    <Attachment className={styles.attachment} />
                                    <a
                                      href={fileUrl + cert["file"]}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {cert["file"].slice(16)}
                                    </a>
                                  </div>
                                )}
                              </div>
                            );
                          } else return;

                        case "file":
                          if (
                            !cols["date_start"] &&
                            !cols["date_end"] &&
                            cert[key] !== -1
                          ) {
                            return (
                              <div key={key} className={styles.fileContainer}>
                                <Attachment className={styles.attachment} />
                                <a
                                  href={fileUrl + cert[key]}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {cert[key].toString().slice(16)}
                                </a>
                              </div>
                            );
                          } else return;
                      }
                    })}
                  </>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default StaffOneSafetyWorkTableWindowHistory;
