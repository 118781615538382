import { RefObject, useEffect } from "react";

type Event = MouseEvent | TouchEvent;

type useOnClickOutsideProps = {
  ref: RefObject<HTMLElement>;
  handler: (event: Event) => void;
};

export const useOnClickOutside = ({ ref, handler }: useOnClickOutsideProps) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node) || null) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
