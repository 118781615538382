import styles from "./buildingOneWorkshiftInfoComment.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Formik } from "formik";
import { useRef } from "react";
import Textarea from "shared/ui/Inputs/Textarea/index";
import sendButton from "shared/assets/images/mainIcons/iconsSend/IconSendActive.svg";
import iconEdit from "shared/assets/images/mainIcons/iconEdit/iconEditNonactive.svg";
import { useParams } from "react-router-dom";

const BuildingOneWorkshiftComment = () => {
  const { buildingOneWorkshiftStore } = useStores();
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const { id } = useParams();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id,
        comment: buildingOneWorkshiftStore.selectedOne?.comment || ""
      }}
      onSubmit={(values) =>
        buildingOneWorkshiftStore.editWorkshift(id, values.comment)
      }
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleBlur,
        isValid,
        dirty
      }) => {
        const handleSubmitComment = () => {
          dirty && isValid ? handleSubmit() : textAreaRef.current.focus();
        };

        return (
          <>
            <div className={styles.commentTitle}>Комментарий</div>
            <div className={styles.textarea}>
              <Textarea
                textAreaRef={textAreaRef}
                name="comment"
                value={values.comment || ""}
                placeholder="Напишите комментарий..."
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("comment", e.target.value);
                }}
                commentCol={buildingOneWorkshiftStore.workshiftCols.comment}
                enableEnter
              />
              <button
                type="button"
                className={
                  dirty && isValid
                    ? styles.sendButton
                    : values.comment
                    ? styles.editButton
                    : styles.withoutButtons
                }
                id="BuildingOneWorkshiftComment_submitButton"
                onClick={handleSubmitComment}
              >
                <img
                  src={
                    dirty && isValid
                      ? sendButton
                      : values.comment
                      ? iconEdit
                      : ""
                  }
                  alt="add_comment"
                />
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default observer(BuildingOneWorkshiftComment);
