import styles from "./selectMulti.module.scss";
import { useField, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";

import { ClearFieldButton } from "shared/ui/ClearFieldButton";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { Option } from "shared/ui/Option";
import {
  useFloating,
  useDismiss,
  useRole,
  useInteractions,
  offset,
  flip,
  shift,
  autoUpdate,
  FloatingPortal
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

type SelectMultiProps = {
  name: string;
  isMenuOpened: boolean;
  setIsMenuOpened: (isMenuOpened: boolean) => void;
  required?: boolean;
  label?: string;

  placement?: Placement;
  isFocused?: boolean;

  options: Record<string, OptionWithTitle> | OptionWithTitle[];
  valueName?: string;
  notSearchable?: boolean;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  isLoading?: boolean;
  searchPlaceholder?: string;
  notSortable?: boolean;
  onClick?: (option: OptionWithTitle) => void;
  onAdditionalFunc?: () => void;
  searchValue?: string;
  // пропс передается в редактировании сотрудника,
  //так как после очистки initialValues не приходит meta.error
  isError?: boolean;
};

const SelectMulti = ({
  name,
  isMenuOpened,
  setIsMenuOpened,
  required,
  label,

  placement,
  isFocused,

  options,
  valueName,
  notSearchable,
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  isLoading,
  searchPlaceholder,
  notSortable,
  onClick,
  onAdditionalFunc,
  searchValue,
  isError
}: SelectMultiProps) => {
  const [listOptions, setListOptions] = useState<OptionWithTitle[]>([]);
  const { setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField({
    name,
    required
  });
  const ref = useRef<HTMLDivElement>();
  const valueField = valueName || "newname";
  const values: string[] = getValues(field.value);
  // Настройки выпадающего списка в строгом порядке, т.к. выполняются по порядку
  const { refs, floatingStyles, context } = useFloating({
    open: isMenuOpened, // Открыт ли список
    onOpenChange: setIsMenuOpened, // Функция, изменяющая состояние списка
    placement: placement || "bottom-start", // Положение списка
    // whileElementsMounted - функция, которая вызывается при монтировании ссылочных и плавающих элементов
    // возвращает функцию очистки, вызываемую при их размонтировании.
    // autoUpdate - функция, которая гарантирует, что плавающий элемент остается привязанным к ссылочному элементу
    whileElementsMounted: autoUpdate,
    middleware: [
      // Модификатор размещения, который перемещает плавающий элемент вдоль указанных осей.
      // Нужно для добавления расстояния между ссылкой и плавающим элементом
      offset(10),
      // Оптимизатор видимости, который меняет расположение плавающего элемента
      // чтобы он оставался в поле зрения с возможностью перевернуть его в любое место.
      flip({ fallbackAxisSideDirection: "end" }),
      // Оптимизатор видимости, который сдвигает плавающий элемент по указанным осям, чтобы он оставался в поле зрения.
      shift()
    ]
  });

  // Слушатели событий для изменения состояния списка
  const dismiss = useDismiss(context, {
    // Закрытие списка при клике на ESC
    escapeKey: true
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role
  ]);

  const getTitleFromOption = (value: string) => {
    let title = "";
    if (options[value]) {
      title = options[value].title;
    } else {
      getValues(options).forEach((option) => {
        if (option[valueField] === value) {
          title = option["title"];
        }
      });
    }
    return title;
  };

  const deleteItem = (item: string) => {
    const itemObj = { title: getTitleFromOption(item) };
    itemObj[valueField] = item;
    setListOptions([...listOptions, itemObj]);

    const newValues = [];
    values.forEach((value) => {
      if (!listOptions[value] && value !== item) {
        newValues.push(value);
      }
    });
    setValue(newValues);
  };

  const updateListOptions = () => {
    if (isSearchWithPagination) {
      if (getValues(options).length !== getValues(listOptions).length) {
        const object: OptionWithTitle[] = [];
        getEntries(options as Record<string, OptionWithTitle>).forEach(
          ([key, option]) => {
            if (!values.includes(key)) {
              object.push(option);
            }
          }
        );
        setListOptions(object);
      }
    } else {
      if (listOptions.length) {
        const idx = listOptions.findIndex((option) =>
          values.includes(option[valueField] as string)
        );
        if (idx !== -1) {
          const array = [...listOptions];
          array.splice(idx, 1);
          setListOptions(array);
        }
      } else {
        const modifiedOptions: OptionWithTitle[] = [];
        getValues(options as Record<string, OptionWithTitle>).forEach(
          (option) => {
            if (!values.includes(option[valueName] as string)) {
              modifiedOptions.push(option);
            }
          }
        );
        setListOptions(modifiedOptions);
      }
    }
  };

  useEffect(() => {
    updateListOptions();
    ref.current.focus();
  }, [meta.value, options]);

  const handleOpenItemsList = () => {
    if (!field.value) {
      setListOptions(getValues(options as Record<string, OptionWithTitle>));
    } else {
      updateListOptions();
    }

    setIsMenuOpened(!isMenuOpened);

    if (required) {
      setFieldTouched(field.name);
    }
  };

  const handleClearField = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setValue([]);
    setListOptions(getValues(options as Record<string, OptionWithTitle>));
    onAdditionalFunc ? onAdditionalFunc() : null;
  };

  const handleAddItem = (item: OptionWithTitle) => {
    if (!(values || []).includes(item[valueField] as string)) {
      setValue([...(values || []), item[valueField]]);
      onClick && onClick(item);
    }
  };

  return (
    <div
      className={classNames("", {
        [styles.field]: !isMenuOpened,
        [styles.fieldFocused]: isMenuOpened,
        [styles.fieldError]: meta.error || isError
      })}
      ref={refs.setReference}
      aria-labelledby="select-label"
      {...getReferenceProps()}
    >
      <div
        className={styles.selectContainer}
        id={`selectmulti_showMenuWithoutBtn_${name}`}
        tabIndex={-1}
        ref={ref}
        onClick={handleOpenItemsList}
      >
        <p
          className={classNames("", {
            [styles.label]: !values.length,
            [styles.labelRequired]: !values.length && required,
            [styles.labelRequiredError]:
              (!values.length && required && meta.touched && meta.error) ||
              isError,
            [styles.labelSmall]: values.length || isMenuOpened,
            [styles.labelSmallRequired]:
              (values.length && required) || isMenuOpened,
            [styles.blueLabel]: isMenuOpened
          })}
        >
          {label}
        </p>

        <div
          className={classNames(styles.fieldItems, {
            [styles.fieldItemsWithLabel]: label
          })}
        >
          {values.map((item: string) => {
            const handleDeteleItem = (event: {
              stopPropagation: () => void;
            }) => {
              event.stopPropagation();
              deleteItem(item);
            };
            return (
              <Option
                key={item}
                onClick={handleDeteleItem}
                id={`selectmulti_chooseOption_${item}`}
                title={getTitleFromOption(item)}
              />
            );
          })}
        </div>

        <div className={styles.buttons}>
          <IconAdd
            name={name}
            className={classNames(styles.iconAdd, {
              [styles.iconAddActive]: isMenuOpened
            })}
            onClick={handleOpenItemsList}
            id={`selectmulti_showMenu_${name}`}
            tabIndex={-1}
          />
          <ClearFieldButton
            name={name}
            disabled={false}
            isFocused={isFocused}
            onClick={handleClearField}
          />
        </div>
      </div>
      <FloatingPortal>
        {isMenuOpened ? (
          <div
            className={styles.scrollboard}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            data-list="true"
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ItemsScrollBoard
                options={listOptions}
                values={values}
                valueName={valueField}
                notSearchable={notSearchable}
                isSearchWithPagination={isSearchWithPagination}
                page={page}
                prevPage={prevPage}
                maxPage={maxPage}
                setPage={setPage}
                getList={getList}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                isLoading={isLoading}
                addItem={handleAddItem}
                searchPlaceholder={searchPlaceholder}
                notSortable={notSortable}
              />
            </ErrorBoundary>
          </div>
        ) : null}
      </FloatingPortal>

      {meta.error ? (
        <div className={styles.errorText}>{meta.error}</div>
      ) : isError ? (
        <div className={styles.errorText}>
          {"Поле обязательно для заполнения"}
        </div>
      ) : null}
    </div>
  );
};

export default SelectMulti;
