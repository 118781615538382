import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import SearchInput from "shared/ui/Inputs/SearchInput";
import StaffCitiesTable from "./StaffCitiesTable";

const StaffCities = () => {
  const { menuStore, staffCitiesStore, modalWindowsStore } = useStores();
  const [isAddingNew, setIsAddingNew] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (
      !menuStore.updateWindow["/staff"] ||
      !staffCitiesStore.staffCities.length
    ) {
      staffCitiesStore.setSearchValue("");
      staffCitiesStore.getData();
    }
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("cities");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/cities",
      title: "Города"
    });
  }, []);

  useEffect(() => {
    if (
      staffCitiesStore.page !== 1 &&
      staffCitiesStore.page <= staffCitiesStore.max_page &&
      staffCitiesStore.page !== staffCitiesStore.prev_page
    ) {
      staffCitiesStore.getStaffCities();
    }
  }, [staffCitiesStore.page, staffCitiesStore.max_page]);

  const handleFindData = () => {
    staffCitiesStore.getData();
    inputRef.current.blur();
  };

  return (
    <>
      <Formik
        initialValues={{ staffCitiesSearchValue: staffCitiesStore.searchValue }}
        onSubmit={(values) => {
          staffCitiesStore.setSearchValue(values.staffCitiesSearchValue);
          staffCitiesStore.getData();
        }}
      >
        {({ setFieldValue }) => (
          <SearchInput
            name="staffCitiesSearchValue"
            inputRef={inputRef}
            onChange={(e) => {
              if (
                !staffCitiesStore.isLoading &&
                !modalWindowsStore.isErrorWindow
              ) {
                setFieldValue(
                  "staffCitiesSearchValue",
                  e.target.value.toLowerCase()
                );
              }
            }}
            handleFindData={handleFindData}
            blurCondition={
              staffCitiesStore.isLoading || modalWindowsStore.isErrorWindow
            }
            clearSearch={() => {
              setFieldValue("staffCitiesSearchValue", "");
              if (staffCitiesStore.searchValue) {
                staffCitiesStore.setSearchValue("");
                staffCitiesStore.getData();
              }
            }}
          />
        )}
      </Formik>
      <StaffCitiesTable
        isAddingNew={isAddingNew}
        setIsAddingNew={setIsAddingNew}
      />
    </>
  );
};

export default observer(StaffCities);
