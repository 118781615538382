import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores/index";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import TableWithStickyFilter from "features/TableWithStickyFilter";
import AregisterAllListTable from "./AregisterAllListTable";
import EditButtons from "./EditButtons";

import validationDate from "./AregisterAllListTable/validation";
import { isEmpty } from "lodash";

import { browserName } from "react-device-detect";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const AregisterAllList = () => {
  const { filterStore, menuStore, aregisterListStore } = useStores();

  useEffect(() => {
    if (isEmpty(aregisterListStore.cols)) {
      aregisterListStore.getData();
    }

    menuStore.setSavedScroll();
    menuStore.setOpenedModule("aregister");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/aregister", "Реестр заявок строительных лесов");
  }, []);

  useEffect(() => {
    if (
      aregisterListStore.page !== 1 &&
      aregisterListStore.page <= aregisterListStore.maxPage &&
      aregisterListStore.page !== aregisterListStore.prevPage
    ) {
      aregisterListStore.getMorePage();
    }
  }, [aregisterListStore.page, aregisterListStore.maxPage]);

  // Раскомментировать при доработке

  // const createDataFile = (format: "csv" | "xls") => {
  //   return aregisterListStore.aregisterList.map((item) => {
  //     const newItem: { [key: string]: string | number } = {};
  //     aregisterListStore.currentTitles.forEach((key) => {
  //       switch (key) {
  //         case "date_start":
  //         case "date_end":
  //           return (newItem[aregisterListStore.cols[key]?.title || key] =
  //             getFormattedDate(item[key]));
  //         case "type_work":
  //         case "type":
  //         case "species":
  //         case "sub_work":
  //           return (newItem[aregisterListStore.cols[key]?.title || key] =
  //             aregisterListStore.selects[key]?.[item[key]]?.["title"]);
  //         case "size":
  //           return (newItem[aregisterListStore.cols[key]?.title || key] =
  //             +item[key].toFixed(4));
  //         default:
  //           return (newItem[aregisterListStore.cols[key]?.title || key] =
  //             item[key]);
  //       }
  //     });
  //     return format === "csv" ? Object.values(newItem) : newItem;
  //   });
  // };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Formik
        enableReinitialize
        initialValues={{ info: aregisterListStore.aregisterList }}
        validationSchema={validationDate}
        validateOnBlur
        validateOnChange
        onSubmit={() => null}
      >
        {() => (
          <TableWithStickyFilter
            isLoading={aregisterListStore.isLoading}
            isLoadingForFilters={aregisterListStore.isLoadingForFilters}
            searchValue={aregisterListStore.searchValue}
            setSearchValue={aregisterListStore.setSearchValue}
            getData={aregisterListStore.getPage}
            cols={{}}
            params={{}}
            allCols={{}}
            currentCols={[]}
            isSearchable
            // Раскомментировать при доработке
            // isExport
            // createDataFile={createDataFile}
            dataFileName="Реестр заявок строительных лесов"
            moreButton={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <EditButtons />
              </ErrorBoundary>
            }
            theadTop={getTHeadTop(
              filterStore.savedFiltersHeight,
              Boolean(aregisterListStore.searchValue),
              filterStore.isOpenedSavedFilters && browserName === "Firefox"
            )}
            withoutAdvancedSearch
            withoutSavedFilter
            withoutColsSetting
            withoutQueryParams
            fixedWidthSearchInput
            itemName="заявок: "
            foundCounter={aregisterListStore.aregisterCount}
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AregisterAllListTable />
            </ErrorBoundary>
          </TableWithStickyFilter>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default observer(AregisterAllList);
