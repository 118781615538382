import styles from "./autoComplete.module.scss";
import { useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars-2";
import { useFormikContext } from "formik";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";

type AutoCompleteProps = {
  array: { value: string; [key: string]: string }[];
  name: string;
  label: string;
  values: { [key: string]: string | number | null } | Partial<IRecordsForEdit>;
  initialValues:
    | { [key: string]: string | number | null }
    | Partial<IRecordsForEdit>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
  isLoading: boolean;
  cleanArray: () => void;
  keys: string[];
  disabled?: boolean;
};

const AutoComplete = ({
  array,
  name,
  label,
  values,
  initialValues,
  onChange,
  errorMessage,
  isLoading,
  cleanArray,
  keys,
  disabled
}: AutoCompleteProps) => {
  const { setFieldValue, setFieldTouched, setValues } = useFormikContext();
  const [source, setSource] = useState("");
  const [prevSource, setPrevSource] = useState("");
  let isChanged = false;

  useEffect(() => {
    const string = keys
      .map((key) => values[key])
      .filter(
        (values) => values !== undefined && values !== null && values !== ""
      )
      .join(" ");

    setSource(string);
  }, [initialValues, keys, values]);

  const changeData = (item?: string | { value: string }) => () => {
    if (item) {
      if (typeof item === "object") {
        const newValues = { ...values };
        newValues[name] = item["value"];
        getEntries(item).forEach(([key, value]) => {
          if (key !== "value") {
            (newValues as { [key: string]: string | number | null })[key] =
              value;
          }
        });
        setValues(newValues);
      } else {
        if (name === "fio") {
          const array = item.split(" ");
          const newValues = { ...values };
          newValues[name] = item;
          keys.forEach((key, index) => {
            newValues[key] =
              index !== keys.length - 1
                ? array[index]
                : array.slice(index).join(" ");
          });
          setValues(newValues);
        }
      }
      isChanged = true;
    } else {
      if (!isChanged) {
        setFieldValue(name, source);
      }
    }

    keys.forEach((key) => {
      setFieldTouched(key, true, false);
    });
    setPrevSource("");
    cleanArray();
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      changeData();
    }, 200);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isChanged = false;
    if (!event.target?.value?.length) {
      const newValues = { ...values };
      keys.forEach((key) => {
        newValues[key] = "";
        setFieldTouched(key, true, false);
      });
      newValues[name] = "";
      setValues(newValues);
      setSource("");
      setPrevSource("");
      cleanArray();
    } else {
      setPrevSource(event.target.value);
      onChange(event);
    }
  };

  return (
    <div className={styles.relative} tabIndex={0} onBlur={handleOnBlur}>
      <input
        name={name}
        value={values[name] || values[name] === null ? values[name] : source}
        onChange={handleOnChange}
        disabled={disabled}
        className={classNames(styles.input, {
          [styles.inputDisabled]: disabled
        })}
        autoComplete="off"
      />
      <label
        className={classNames(styles.label, {
          [styles.labelWithData]: source.length > 0 || values[name]?.length > 0,
          [styles.labelNoData]:
            source.length === 0 && (!values[name] || values[name].length === 0)
        })}
      >
        {label}
      </label>

      {(array.length > 1 || prevSource || errorMessage) && (
        <div className={styles.list}>
          {!isLoading ? (
            <Scrollbars
              style={{ width: "100%" }}
              autoHeight
              autoHeightMax="250px"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <>
                {prevSource && name === "fio" ? (
                  <li onClick={changeData(prevSource)}>
                    <p className={styles.currentSource}>{prevSource}</p>
                  </li>
                ) : null}
                {array.map((item, index) => {
                  return (
                    <li key={index} onClick={changeData(item)}>
                      <p>{item["value"]}</p>
                    </li>
                  );
                })}
              </>
            </Scrollbars>
          ) : (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
