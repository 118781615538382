import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Application } from "stores/AregisterModule/types/Application";
import { ApiResponse } from "stores/utils/types/ApiResponse";
export default class AregisterOneMasscopyStore {
  error = false;
  isLoading = false;

  dirty: { [key: string]: boolean } = {};

  selectedOneForMasscopy: Partial<Application> = {};
  openedAllAppllicationsForMasscopy: { [key: string]: Partial<Application> } =
    {};

  values: {
    [key: string]: {
      reload: number;
      original: Partial<Application>;
      copies: Partial<Application>[];
      massfill: Partial<Application>;
    };
  } = {};

  tableType: { [key: string]: string } = {};

  rootStore: RootStore;

  editedNullFieldsForMasscopy = [
    "date_start",
    "date_end",
    "length",
    "width",
    "height",
    "size",
    "measurements",
    "manual_hours",
    "manual_size",
    "internal_num",
    "high_mark",
    "act_hours"
  ];

  setSelectedApplication = (id: string) => {
    this.isLoading = true;
    this.error = false;

    this.selectedOneForMasscopy = {};

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow(
        "/aregister",
        "Реестр заявок строительных лесов"
      );
    }
    if (!this.rootStore.menuStore.allWindows[`/aregister/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/aregister/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllAppllicationsForMasscopy[id];
    }

    if (
      Object.keys(this.openedAllAppllicationsForMasscopy).length &&
      this.openedAllAppllicationsForMasscopy[id]
    ) {
      if (Object.values(this.openedAllAppllicationsForMasscopy[id]).length) {
        this.selectedOneForMasscopy =
          this.openedAllAppllicationsForMasscopy[id];
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else {
      this.getData(id);
    }
  };

  getData = (id: string) => {
    this.isLoading = true;
    Promise.all([this.getDataInfo(id)]).then(() => {
      setTimeout(() => {
        runInAction(() => {
          this.isLoading = false;
          this.dirty[id] = false;
          this.tableType[id] = "column";
        });
      }, 500);
    });
  };

  getDataInfo = async (id: string) => {
    try {
      const data: ApiResponse<Partial<Application>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          currentClass: "application_register",
          method: "getByColOne",
          params: {
            col: "id",
            value: id
          }
        });

      runInAction(() => {
        if (data["result"] && Object.values(data["result"]).length) {
          this.selectedOneForMasscopy = data["result"];
          Promise.all([
            this.getFio(data["result"]["brigadier"] as string, "brigadier"),
            data["result"]["author"] &&
              typeof data["result"]["author"] === "number" &&
              this.getFio(`${data["result"]["author"]}`, "author"),
            this.getFio(data["result"]["master"] as string, "master")
          ]).then(() => {
            Promise.all([
              this.rootStore.aregisterAppcreateStore.getInitialValuesForMasscopy(
                this.selectedOneForMasscopy
              )
            ]).then(() => {
              this.rootStore.menuStore.updateTabWindow({
                mainPath: `/aregister/id=${id}`,
                title: `Заявка ${this.selectedOneForMasscopy["internal_num"]}`
              });
            });
          });
        } else {
          this.selectedOneForMasscopy = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/aregister/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        }

        this.openedAllAppllicationsForMasscopy[id] =
          this.selectedOneForMasscopy;
      });
    } catch (error) {
      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/aregister/id=${id}`,
        title: "Ничего не найдено"
      });
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getFio = async (staffId: string, key: "author" | "brigadier" | "master") => {
    if (staffId) {
      try {
        const data: ApiResponse<{ [key: string]: string | number } | -1> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "staff",
            currentClass: "staff",
            method: "getByColOne",
            params: {
              col: key == "author" ? "uid" : "id",
              value: staffId
            }
          });

        runInAction(() => {
          if (data["result"] !== -1) {
            this.selectedOneForMasscopy[key] = {
              fio: `${data["result"]["surname"]} ${data["result"]["name"]} ${data["result"]["patronymic"]}`,
              id: staffId,
              tn: data["result"]["uid"]
            };
          } else this.error = true;
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
        });
      }
    }
  };

  setTableType = (id: string, type: string) => {
    this.tableType[id] = type;
  };

  setDirty = (id: string, value: boolean) => {
    this.dirty[id] = value;
  };

  setValues = (
    id: string,
    values: {
      reload: number;
      original: Partial<Application>;
      copies: Partial<Application>[];
      massfill: Partial<Application>;
    }
  ) => {
    this.values[id] = values;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
