import { object, string } from "yup";
import { isValid, parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

const rangeDateSchema = string()
  .nullable()
  .test("date", "Некорректный формат даты", function (value) {
    if (
      !value ||
      (value && !isValid(parse(value, dateFormats.date.format, new Date())))
    ) {
      return false;
    } else return Boolean(value);
  });

export const validationDateRangePickerInput = object({
  startDate: rangeDateSchema.test(
    "date_start",
    "Дата начала позже даты окончания",
    function (value) {
      if (this.parent.endDate && value) {
        return (
          parse(this.parent.endDate, dateFormats.date.format, new Date()) >=
          parse(value, dateFormats.date.format, new Date())
        );
      } else return true;
    }
  ),
  endDate: rangeDateSchema.test(
    "date_end",
    "Дата окончания раньше даты начала",
    function (value) {
      if (this.parent.startDate && value) {
        return (
          parse(this.parent.startDate, dateFormats.date.format, new Date()) <=
          parse(value, dateFormats.date.format, new Date())
        );
      } else return true;
    }
  )
});
