import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";

export type Address = {
  id: string;
  ordered: number;
  idmodule: number;
  ukey: string;
  title: string;
  comment: string;
  custom: { [key: string]: string };
};

export default class StaffTypeAddressStore {
  addressTypes: Array<Address> = [];
  defaultTypeAddress = "";
  typeKeys: Array<string> = [];

  on_page = 15;
  page = 1;
  max_page = 1;

  isLoading = false;
  error = false;
  errorMessage = "";

  getAddressTypes = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.error = false;
    });

    try {
      const data: ApiResponse<Address | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff_typeaddress",
          method: "getList",
          on_page: this.on_page,
          page: this.page
        });

      if (data["nav"]) {
        runInAction(() => (this.max_page = data["nav"]["max_page"]));
      }

      if (Object.values(data["result"]).length) {
        runInAction(() => {
          if (!this.defaultTypeAddress) {
            this.defaultTypeAddress = data["default"];
          }
          if (this.addressTypes.length) {
            this.addressTypes = [
              ...this.addressTypes,
              ...Object.values(data["result"])
            ];
          } else {
            this.addressTypes = Object.values(data["result"]);
            this.typeKeys = Object.keys(this.addressTypes[0]);
          }
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.errorMessage = "Ничего не найдено";
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  addNewTypeAddress = (type: Address) => {
    for (const prop in type) {
      if (prop === "custom") {
        type[prop] = {
          type: ""
        };
      }
      if (prop === "ordered") {
        type[prop] = this.addressTypes.length + 1;
      } else {
        type[prop] = "";
      }
    }
    runInAction(() => this.addressTypes.unshift(type));
  };

  clearAddressTypes = () => {
    runInAction(() => (this.addressTypes = []));
  };

  setPage = (page: number) => {
    this.page = page;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
