import styles from "./staffSalaryForm.module.scss";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import validation from "./validation";
import FormWindow from "shared/ui/FormWindow";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { Errors } from "stores/utils/types/ErrorsType";
import StaffSalaryFormFields from "./StaffSalaryFormFields";
import { isEmpty } from "lodash";

const requiredCols = [
  "building",
  "type",
  "money",
  "ws_num",
  "date",
  "date_start",
  "uid"
];

type StaffSalaryFormProps = {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  clearErrorsMessage?: () => void;
  saveBtnOnClickFunc?: (
    values: {
      [key: string]: string | number;
    },
    uid?: string
  ) => void;
  fields: string[];
  disabledFields?: string[];
  dataForEdit?: { [key: string]: string | number };
  title: string;
  nameOneOfStaff?: string;
  type: "add" | "edit" | "";
  isHoldingEdu?: boolean;
  staffID?: string;
  errorsMessage?: Errors["message"];
  successMessage?: Errors["message"];
  ws_num?: number;
  money?: number;
};

const StaffSalaryForm = ({
  isOpenModal,
  setIsOpenModal,
  clearErrorsMessage,
  fields,
  disabledFields,
  title,
  nameOneOfStaff,
  type,
  dataForEdit,
  isHoldingEdu,
  saveBtnOnClickFunc,
  staffID,
  errorsMessage,
  successMessage,
  ws_num,
  money
}: StaffSalaryFormProps) => {
  const { staffRetentionListStore } = useStores();

  // стейт показывает была ли нажата кнопка "Сохранить"
  // нужен для отображения ошибки при добавлении/редактировании
  const [isSaveBtnPressed, setIsSaveBtnPressed] = useState(false);

  const { id } = useParams();
  const isAdd = type === "add" && fields.length;
  const isEdit = type === "edit" && !isEmpty(dataForEdit) && fields.length;
  const getInitialValuesForAdd = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      if (key.includes("comment")) return (values[key] = "");
      switch (key) {
        case "date":
        case "date_start":
          values[key] = format(new Date(), "yyyy-MM-dd");
          break;
        case "ws_num":
          values[key] = isHoldingEdu ? ws_num : null;
          break;
        case "money":
          values[key] = isHoldingEdu ? money : null;
          break;
        default:
          values[key] = null;
      }
    });
    return values;
  };
  const getInitialValuesForEdit = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      switch (key) {
        case "date_start":
          values[key] = (dataForEdit[key] as string)
            .split(".")
            .reverse()
            .join("-");
          break;
        default:
          values[key] = dataForEdit[key];
      }
    });
    return values;
  };

  useEffect(() => {
    if (isOpenModal) {
      clearErrorsMessage();
      isEmpty(staffRetentionListStore.statusList) &&
        staffRetentionListStore.getStatusList();
      staffRetentionListStore.setSearchValueStaff("");
      staffRetentionListStore.getStaffList();
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (
      (isSaveBtnPressed && isEmpty(errorsMessage)) ||
      (isSaveBtnPressed && !errorsMessage)
    ) {
      successMessage
        ? setTimeout(() => setIsOpenModal(), 1000)
        : setIsOpenModal();
      setIsSaveBtnPressed(false)
    }
  }, [errorsMessage]);

  if (isOpenModal && (isAdd || isEdit)) {
    return (
      <div className={styles.background}>
        <div className={styles.modalWindow}>
          <Formik
            initialValues={
              type === "add"
                ? getInitialValuesForAdd()
                : getInitialValuesForEdit()
            }
            enableReinitialize
            onSubmit={() => null}
            validateOnMount
            validateOnBlur
            validateOnChange
            validationSchema={validation(fields, requiredCols)}
          >
            {({ values, isValid, dirty }) => {
              return (
                <>
                  <FormWindow
                    title={title}
                    subtitle={nameOneOfStaff}
                    setOpenWindow={() => {
                      setIsOpenModal();
                      clearErrorsMessage();
                    }}
                    saveBtnTitle={type === "add" ? "Добавить" : "Сохранить"}
                    saveBtnOnClickFunc={() => {
                      saveBtnOnClickFunc(
                        values,
                        type === "add"
                          ? id ?? staffID
                          : (dataForEdit["id"] as string)
                      );
                      setIsSaveBtnPressed(true);
                    }}
                    saveBtnDisabledValue={
                      !isValid || (type === "edit" ? !dirty : false)
                    }
                    addBtnTitle="Отмена"
                    addBtnImg={<IconClose />}
                    errors={
                      errorsMessage &&
                      Object.values(errorsMessage).length && [errorsMessage]
                    }
                    isScroll
                  >
                    <StaffSalaryFormFields
                      requiredCols={requiredCols}
                      disabledFields={disabledFields}
                      isHoldingEdu={isHoldingEdu}
                      successMessage={successMessage}
                      ws_num={ws_num}
                      money={money}
                    />
                  </FormWindow>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
};

export default observer(StaffSalaryForm);
