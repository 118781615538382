import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { changeDateFormats } from "stores/utils/consts";

export default class DateFormatsStore {
  formatTypes = {
    timestamp: {
      format: "",
      placeholder: "",
      date: ""
    },
    date: {
      format: "",
      placeholder: ""
    },
    time: {
      format: "",
      placeholder: ""
    }
  };

  error = "";

  getDateFormatsAll = async () => {
    try {
      const response: { code: number; hash: string; result: string }[] =
        await Promise.all(
          Object.keys(this.formatTypes).map(async (type) => {
            const res: { code: number; hash: string; result: string } =
              await this.rootStore.apiStore.getData({
                baseClass: "core",
                currentClass: "times",
                method: "toJSFormat",
                params: {
                  typeFormat: type
                }
              });
            return res;
          })
        );
      if (response.some(({ result }) => result)) {
        for (let i = 0; i < Object.keys(this.formatTypes).length; i++) {
          const key = Object.keys(this.formatTypes)[i];

          runInAction(() => {
            switch (key) {
              case "date":
                {
                  const date = response[i].result
                    .replace(/d/gi, "d")
                    .replace(/y/gi, "yyyy");
                  const placeholder = date
                    .replace(/y/gi, "г")
                    .replace(/m/gi, "м")
                    .replace(/d/gi, "д");

                  this.formatTypes[key].format = date;
                  this.formatTypes[key].placeholder = placeholder;

                  changeDateFormats(key, date, placeholder);
                }
                break;
              case "timestamp":
                {
                  const timestamp = response[i].result.replace(/d/gi, "d");

                  this.formatTypes[key].date = timestamp;
                  this.formatTypes[key].placeholder = "";

                  changeDateFormats(key, timestamp, "");
                }
                break;
              default: {
                this.formatTypes[key].date = response[i].result;
                this.formatTypes[key].placeholder = "";

                changeDateFormats(key, response[i].result, "");
              }
            }
          });
        }
      }
    } catch (error) {
      runInAction(() => (this.error = (error as Error).message));
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
