import { object, string } from "yup";

export const validation = object({
  title: string()
    .trim()
    .lowercase()
    .max(100, "Не более 100 знаков")
    .required("Для сохранения поле обязательно к заполнению")
    .nullable(),
  params: object({})
});
