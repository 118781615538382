import LoadedComponent from "widgets/LoadedComponent";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import Select from "shared/ui/Inputs/Select";
import Textarea from "shared/ui/Inputs/Textarea";
import { useStores } from "stores";
import styles from "./safetyWorkAdd.module.scss";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import Alert from "shared/ui/Alert";
import { getValidationSchema } from "./validation";
import Switch from "shared/ui/Inputs/Switch";
import { isEmpty, pick, without } from "lodash";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getValues } from "shared/utils/helpers/getValues";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

const SafetyWorkAdd = () => {
  const { menuStore, safetyWorkAddNewStore } = useStores();
  const [nameCalendarOpened, setNameCalendarOpened] = useState("");
  const [requiredFields, setRequiredFields] = useState(
    safetyWorkAddNewStore.requiredFields
  );
  const [withoutDateEnd, setWithoutDateEnd] = useState(false);

  const navigate = useNavigate();

  const changeOpenedWindows = (name: string) => {
    nameCalendarOpened === name
      ? setNameCalendarOpened("")
      : setNameCalendarOpened(name);
  };

  useEffect(() => {
    menuStore.setOpenedModule("safetywork");
    menuStore.setOpenedSubmodule("add");
    menuStore.updateWindow({
      mainPath: "/safetywork",
      path: "/safetywork/add",
      title: "Охрана труда"
    });
    safetyWorkAddNewStore.resetUidDirectory();
    safetyWorkAddNewStore.resetSearchValueStaff();
    safetyWorkAddNewStore.setErrorMessage({});
    safetyWorkAddNewStore.getData();
  }, []);

  // оставить эту строку. решить в задаче с багом стора
  safetyWorkAddNewStore.errorsMessage;

  useEffect(() => {
    if (
      safetyWorkAddNewStore.pageStaff !== 1 &&
      safetyWorkAddNewStore.pageStaff <= safetyWorkAddNewStore.maxPageStaff &&
      safetyWorkAddNewStore.pageStaff !== safetyWorkAddNewStore.prevPageStaff
    ) {
      safetyWorkAddNewStore.getMoreStaff();
    }
  }, [safetyWorkAddNewStore.pageStaff, safetyWorkAddNewStore.maxPageStaff]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent
        isLoading={safetyWorkAddNewStore.isLoading}
        error={safetyWorkAddNewStore.error}
      >
        {Object.values(safetyWorkAddNewStore.safetyWorkCols).length ? (
          <Formik
            initialValues={{
              ...safetyWorkAddNewStore.newCertObject,
              withoutDateEnd: 0,
              add_more: 0
            }}
            enableReinitialize
            onSubmit={(values) => {
              Promise.all([safetyWorkAddNewStore.addCertificate(values)]).then(
                () => {
                  if (
                    isEmpty(safetyWorkAddNewStore.errorsMessage) &&
                    values["add_more"] === 0
                  ) {
                    navigate(`../safetywork`);
                  }
                }
              );
            }}
            validationSchema={() =>
              getValidationSchema(
                Object.keys(safetyWorkAddNewStore.newCertObject),
                requiredFields
              )
            }
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isValid,
              dirty
            }) => {
              return (
                <div className={styles.form}>
                  {safetyWorkAddNewStore.currentTitles.map((field) => {
                    const cols = safetyWorkAddNewStore.safetyWorkCols[field];

                    if (
                      safetyWorkAddNewStore.safetyWorkParams[field]
                        ?.isvariable === "on"
                    ) {
                      const getTypeDirectory = () => {
                        const currentTypesArray = getValues(
                          safetyWorkAddNewStore.selects["kind"]?.[
                            values["kind"]
                          ]?.["custom"]?.["type"] as Record<string, string>
                        );
                        const currentTypesDirectory = pick(
                          safetyWorkAddNewStore.selects[field],
                          currentTypesArray
                        );
                        return currentTypesDirectory || {};
                      };
                      const typeDirectory = values["kind"]
                        ? getTypeDirectory()
                        : {};

                      if (field === "uid") {
                        return (
                          <Select
                            key={field}
                            name={field}
                            isFloating
                            title={cols["title"]}
                            options={cols?.["directory"]}
                            onBlur={handleBlur}
                            isSearchable
                            isSearchWithPagination
                            page={safetyWorkAddNewStore.pageStaff}
                            prevPage={safetyWorkAddNewStore.prevPageStaff}
                            maxPage={safetyWorkAddNewStore.maxPageStaff}
                            setPage={safetyWorkAddNewStore.setPageStaff}
                            getList={safetyWorkAddNewStore.getStaffList}
                            setSearchValue={
                              safetyWorkAddNewStore.setSearchValueStaff
                            }
                            searchValue={safetyWorkAddNewStore.searchValueStaff}
                            isLoading={
                              safetyWorkAddNewStore.isLoadingForStaffList
                            }
                            required={requiredFields.includes(field)}
                          />
                        );
                      }

                      return (
                        <Select
                          key={field}
                          name={field}
                          isFloating
                          title={cols["title"]}
                          options={
                            (field === "type"
                              ? typeDirectory
                              : safetyWorkAddNewStore.selects[field] ||
                                {}) as Record<string, OptionWithTitle>
                          }
                          onBlur={handleBlur}
                          required={requiredFields.includes(field)}
                          disabled={field === "type" && !values["kind"]}
                          valueName="id"
                          onChange={
                            field === "kind"
                              ? () => {
                                  setFieldValue("type", "");
                                }
                              : undefined
                          }
                        />
                      );
                    }

                    switch (field) {
                      case "date_start":
                        return (
                          <Fragment key={field}>
                            <div className={styles.dateContainer}>
                              {safetyWorkAddNewStore.currentTitles.includes(
                                "date_end"
                              ) ? (
                                <label className={styles.switch}>
                                  <Switch
                                    name="withoutDateEnd"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        requiredFields.includes("date_end") &&
                                          setRequiredFields(
                                            without(requiredFields, "date_end")
                                          );
                                        setWithoutDateEnd(true);
                                        setTimeout(() => {
                                          setFieldValue("date_end", "");
                                          setFieldTouched("withoutDateEnd");
                                        }, 200);
                                      } else {
                                        !requiredFields.includes("date_end") &&
                                          safetyWorkAddNewStore.requiredFields.includes(
                                            "date_end"
                                          ) &&
                                          setRequiredFields([
                                            "date_end",
                                            ...requiredFields
                                          ]);
                                        setWithoutDateEnd(false);
                                        setTimeout(() => {
                                          setFieldTouched("withoutDateEnd");
                                        }, 200);
                                      }
                                    }}
                                  />
                                  Бессрочный сертификат
                                </label>
                              ) : (
                                ""
                              )}
                              <div
                                className={
                                  safetyWorkAddNewStore.currentTitles.includes(
                                    "date_end"
                                  )
                                    ? styles.doubleDateField
                                    : ""
                                }
                              >
                                <DatePickerField
                                  name={field}
                                  title={cols["title"]}
                                  isCalendarOpened={
                                    nameCalendarOpened === field
                                  }
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(field);
                                  }}
                                  required={requiredFields.includes(field)}
                                  placement="right"
                                />
                                {safetyWorkAddNewStore.currentTitles.includes(
                                  "date_end"
                                ) ? (
                                  <DatePickerField
                                    name={"date_end"}
                                    title={
                                      safetyWorkAddNewStore.safetyWorkCols[
                                        "date_end"
                                      ]["title"]
                                    }
                                    isCalendarOpened={
                                      nameCalendarOpened === "date_end"
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows("date_end");
                                    }}
                                    required={requiredFields.includes(
                                      "date_end"
                                    )}
                                    placement="right"
                                    disabled={withoutDateEnd}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Fragment>
                        );
                      case "date_end":
                        if (
                          !safetyWorkAddNewStore.currentTitles.includes(
                            "date_start"
                          )
                        ) {
                          return (
                            <Fragment key={field}>
                              <DatePickerField
                                name={field}
                                title={cols["title"]}
                                isCalendarOpened={nameCalendarOpened === field}
                                setIsCalendarOpened={() => {
                                  changeOpenedWindows(field);
                                }}
                                required={requiredFields.includes(field)}
                                placement="right"
                                disabled={withoutDateEnd}
                              />
                            </Fragment>
                          );
                        } else return;

                      case "comment":
                        return (
                          <Fragment key={field}>
                            <Textarea
                              name={field}
                              value={values[field] as string}
                              label={cols["title"]}
                              placeholder={cols["title"]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={requiredFields.includes(field)}
                              commentCol={
                                safetyWorkAddNewStore.safetyWorkCols["comment"]
                              }
                            />
                          </Fragment>
                        );
                      default:
                        return (
                          <Fragment key={field}>
                            <Input
                              name={field}
                              onChange={handleChange}
                              label={cols["title"]}
                              required={requiredFields.includes(field)}
                            />
                          </Fragment>
                        );
                    }
                  })}
                  <label className={styles.switchAddMore}>
                    <Switch name="add_more" />
                    После нажатия кнопки “Создать” добавить ещё
                  </label>
                  <ButtonsGroupForSettings
                    saveBtnTitle="Создать"
                    saveBtnDisabled={
                      !dirty ||
                      !isValid ||
                      (safetyWorkAddNewStore.errorsMessage &&
                        Boolean(
                          Object.values(safetyWorkAddNewStore.errorsMessage)
                            .length
                        ))
                    }
                    saveBtnOnClick={() => {
                      handleSubmit();
                      setTimeout(() => {
                        if (
                          safetyWorkAddNewStore.errorsMessage &&
                          !Object.values(safetyWorkAddNewStore.errorsMessage)
                            .length
                        ) {
                          setRequiredFields(
                            safetyWorkAddNewStore.requiredFields
                          );
                          handleReset();
                          setWithoutDateEnd(false);
                        }
                      }, 300);
                    }}
                    addBtnTitle="Очистить форму"
                    addBtnImg={<IconClose />}
                    addBtnDisabled={!dirty}
                    addBtnOnClick={() => {
                      setRequiredFields(safetyWorkAddNewStore.requiredFields);
                      safetyWorkAddNewStore.setErrorMessage({});
                      setWithoutDateEnd(false);
                      safetyWorkAddNewStore.resetUidDirectory();
                      safetyWorkAddNewStore.resetSearchValueStaff();
                      safetyWorkAddNewStore.getStaffList();
                      handleReset();
                    }}
                  />
                  {safetyWorkAddNewStore.errorsMessage &&
                  Object.values(safetyWorkAddNewStore.errorsMessage).length ? (
                    <Alert errors={safetyWorkAddNewStore.errorsMessage} />
                  ) : null}
                </div>
              );
            }}
          </Formik>
        ) : null}
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(SafetyWorkAdd);
