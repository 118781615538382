import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Errors } from "stores/utils/types/ErrorsType";
import { Col } from "stores/utils/types/Col";
import { Param } from "stores/utils/types/Param";
import { Position } from "stores/StaffModule/types/Position";

// hardcode types
import { Payment } from "./types/HardcodePayment";
import { PaymentType } from "./types/HardcodePaymentTypes";
import { PaymentStatus } from "./types/HardcodePaymentStatus";

import data from "./data.json";

export default class SalaryPaymentlistOneStore {
  error = false;
  isLoading = false;

  errorsMessage: { [key: string]: { [key: string]: Errors["message"] } } = {};

  selectedOnePayment: Partial<Payment> = {};
  openedAllPayments: { [id: string]: Partial<Payment> } = {};

  paymentCols: { [key: string]: Col } = {};
  paymentParams: { [key: string]: Param } = {};

  paymentTypes: { [key: string]: PaymentType } = {};
  paymentStatuses: { [key: string]: PaymentStatus } = {};
  positionsList: { [key: string]: Position } = {};

  // hardcode, удалить при разработке
  allHardcodePayments: { [id: string]: Partial<Payment> } = {};

  // фильтры ведомости
  itrFilter: { [key: string]: string } = {};
  searchInput: { [key: string]: string } = {};
  positionFilter: { [key: string]: string } = {};

  rootStore: RootStore;

  setSelectedOneOfPaymentlist = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/salary", "Финансы");
      this.rootStore.menuStore.updateWindow({
        mainPath: "/salary",
        path: "/salary/paymentlist",
        title: "Финансы"
      });
    }

    if (!this.rootStore.menuStore.allWindows[`/salary/paymentlist/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/salary/paymentlist/id=${id}`,
        "Загрузка..."
      );

      this.rootStore.menuStore.addParentPath(
        `/salary/paymentlist`,
        `/salary/paymentlist/id=${id}`
      );
      delete this.openedAllPayments[id];
    }

    if (
      Object.keys(this.openedAllPayments).length &&
      this.openedAllPayments[id]
    ) {
      if (Object.values(this.openedAllPayments[id]).length) {
        this.selectedOnePayment = this.openedAllPayments[id];
      } else this.error = true;

      this.isLoading = false;
    } else {
      Promise.all([
        this.getPaymentOneData(id),
        !Object.values(this.paymentCols).length && this.getPaymentCols(),
        !Object.values(this.paymentParams).length && this.getPaymentParams(),
        !Object.values(this.paymentTypes).length && this.getPaymentTypes(),
        !Object.values(this.paymentStatuses).length &&
          this.getPaymentStatuses(),
        !Object.values(this.positionsList).length && this.getPositionsList()
      ]).then(() => {
        setTimeout(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        }, 3000);
      });
    }
  };

  getPaymentOneData = async (id: string) => {
    if (!Object.values(this.allHardcodePayments).length) {
      Object.values(data.records).forEach((payment) => {
        this.allHardcodePayments[payment.id] = payment;
      });
    }

    if (this.allHardcodePayments[id]) {
      this.openedAllPayments[id] = this.allHardcodePayments[id];
      this.selectedOnePayment = this.allHardcodePayments[id];

      this.getPaymentErrors(id);

      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/salary/paymentlist/id=${id}`,
        title: `Ведомость ${this.selectedOnePayment.uid}`
      });
    }
  };

  getPaymentErrors = (id: string) => {
    // хардкод для ведомости со статусом "Создано" №5796
    if (id === "00081370e8b3ec49257c3a773e363fce5d3bf26b") {
      this.errorsMessage[id] = {
        "3e363fce5d3bf26b00081370e8b3ec49257c3a77": {
          head: "Имеются незакрытые ведомости.",
          color: "danger",
          body: {
            "0": {
              head: "",
              list: {
                type: "link",
                dir: "paymentslist",
                body: {
                  "5cb11b2b13c82221ce022211db34c96872fe3a12":
                    "#11328. 2023-05-01 - 2023-05-15",
                  ab2b142521fb4435cd068ecaf3b437c177e384a4:
                    "#11569. 2023-05-16 - 2023-05-31",
                  fde3e14688e7da1525dd3b32a3ef66f3461a286b:
                    "#11799. 2023-06-01 - 2023-06-15"
                }
              }
            }
          }
        }
      };
    }
  };

  getPaymentCols = async () => {
    this.paymentCols = data.cols;
  };

  getPaymentParams = async () => {
    this.paymentParams = data.params;
  };

  getPaymentTypes = async () => {
    this.paymentTypes = data.selects.types;
  };

  getPaymentStatuses = async () => {
    this.paymentStatuses = data.selects.status;
  };

  getPositionsList = async () => {
    this.positionsList = data.selects.position;
  };

  setItrFilter = (name: string, id: string) => {
    this.itrFilter[id] !== name ? (this.itrFilter[id] = name) : "";
  };

  setSearchInput = (value: string, id: string) => {
    if (!value?.length) {
      delete this.searchInput[id];
    } else {
      this.searchInput[id] = value;
    }
  };

  setPositionFilter = (position: string, id: string) => {
    this.positionFilter[id] = position;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
