import React from "react";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import styles from "./photoAltGalleryEditSection.module.scss";
import StaffOneEditPhotoAltCropper from "../../StaffOneEditPhotoAltCropper";
import { IFile } from "../index";

type CropperRef = {
  getCropImage: () => string;
};

type EditSectionProps = {
  handleCloseEditing: () => void;
  handleSaveData: () => void;
  editingFromViewing: boolean;
  imageIndex: number;
  loadedImages: { hash: string; isMain: number; src: string }[];
  files: IFile[];
  cropperRef: React.RefObject<CropperRef>;
  closeWindow: () => void;
};

const PhotoAltGalleryEditSection = ({
  handleCloseEditing,
  handleSaveData,
  editingFromViewing,
  imageIndex,
  loadedImages,
  files,
  cropperRef,
  closeWindow
}: EditSectionProps) => (
  <>
    <StaffOneEditPhotoAltCropper
      file={
        imageIndex !== null
          ? loadedImages[imageIndex]["src"]
          : files[0]?.preview
      }
      ref={cropperRef}
    />
    <div className={styles.buttonBox}>
      {editingFromViewing ? (
        <>
          <button
            type="button"
            className={styles.cancelBtn}
            onClick={handleCloseEditing}
            id="PhotoAltGalleryEditSection_closeEditing"
          >
            <IconClose className={styles.iconClose} />
            Выйти без сохранения
          </button>
          <button
            type="button"
            className={styles.saveBtn}
            onClick={handleSaveData}
            id="PhotoAltGalleryEditSection_saveImage"
          >
            Сохранить
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className={styles.cancelBtn}
            onClick={closeWindow}
            id="PhotoAltGalleryEditSection_cancel"
          >
            <IconClose className={styles.iconClose} />
            Отмена
          </button>
          <button
            type="button"
            className={styles.saveBtn}
            onClick={handleSaveData}
            id="PhotoAltGalleryEditSection_savePhoto"
          >
            Сохранить и добавить
          </button>
        </>
      )}
    </div>
  </>
);

export default PhotoAltGalleryEditSection;
