import styles from "./aregisterTableLoader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import LoadedComponent from "widgets/LoadedComponent";

const AregisterTableLoader = () => {
  const { aregisterListStore, menuStore } = useStores();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      aregisterListStore.maxPage >= aregisterListStore.page &&
      aregisterListStore.page === aregisterListStore.prevPage
    ) {
      aregisterListStore.setPage(aregisterListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return aregisterListStore.page !== 1 &&
    aregisterListStore.page !== aregisterListStore.prevPage ? (
    <div className={styles.firstCol}>
      {aregisterListStore.isLoading ? (
        <p className={styles.loading}>Пожалуйста, подождите</p>
      ) : null}
    </div>
  ) : (
    <LoadedComponent
      isLoading={aregisterListStore.isLoading}
      error={aregisterListStore.error}
      errorMessage={aregisterListStore.errorMessage}
    />
  );
};

export default observer(AregisterTableLoader);
