import styles from "./staffOneStatusesForm.module.scss";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";

import { useFormikContext } from "formik";

import Select from "shared/ui/Inputs/Select";
import { Input } from "shared/ui/Inputs/Input";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Switch from "shared/ui/Inputs/Switch";
import NumberFormat from "react-number-format";
import Textarea from "shared/ui/Inputs/Textarea";
import Tooltip from "shared/ui/Tooltip";
import StaffOneStatusesFormEdu from "./StaffOneStatusesFormEdu";

import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";

import { IColForStatuses } from "stores/StaffModule/types/IColForStatuses";
import { cloneDeep } from "lodash";

import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { Retention } from "stores/StaffModule/types/Retention";
import { Status } from "stores/StaffModule/types/Status";
import { ViewField } from "shared/ui/ViewField";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getKeys } from "shared/utils/helpers/getKeys";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import StaffOneStatusesFormStaffGuid from "./StaffOneStatusesFormStaffGuid";
import { StaffGuid } from "stores/StaffModule/types/StaffGuid";
import { Errors } from "stores/utils/types/ErrorsType";
import Alert from "shared/ui/Alert";

// hardcode: id группы статуса Уволен
const dismissStatus = "e329ed9b615763361b86d0d31cc3aac6b620c262";

type StaffOneStatusesFormProps = {
  staff_id: string;
  selectedStatus: string;
  company: string;
  cols: Record<string, Partial<IColForStatuses>>;
  boolCols: Record<string, string[]>;
  openedListName: string;
  changeOpenedWindows: (arg: string) => void;
  showRetentionBlock?: boolean;
  setRequiredCols?: (arg: Record<string, number>) => void;
  requiredCols: Record<string, number>;

  params?: BuildingEventType;
  isLoadingAllowEdu?: boolean;
  retention?: Retention;
  updateRetention?: Retention;
  checkEventAllowEdu?: (
    staff_id: string,
    event_id: string,
    date?: string
  ) => void;
  minDateForDefaultUser: Date;
  maxDate?: Date;
  staffCode?: StaffGuid;
  selectStaffGuid?: (uid: string, company: string, date: string) => void;
  staffGuidMessage?: Record<string, Errors["message"]>;
  disabledForm?: boolean;
};

const StaffOneStatusesForm = ({
  boolCols,
  requiredCols,
  company,
  cols,
  openedListName,
  changeOpenedWindows,
  selectedStatus,
  setRequiredCols,
  staff_id,
  showRetentionBlock,
  params,
  isLoadingAllowEdu,
  retention,
  updateRetention,
  checkEventAllowEdu,
  minDateForDefaultUser,
  staffCode,
  selectStaffGuid,
  staffGuidMessage,
  disabledForm,
  maxDate
}: StaffOneStatusesFormProps) => {
  const [requiredColumn, setRequiredColumn] = useState<{
    [key: string]: number;
  }>({});
  const {
    values,
    initialValues,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched
  } = useFormikContext<Status>();

  useEffect(() => {
    setRequiredColumn(requiredCols);
  }, [requiredCols]);

  useEffect(() => {
    if (
      (values.sub_start_date || values.sub_end_date) &&
      !requiredColumn.sub_start_date
    ) {
      setRequiredColumn({
        sub_start_date: 1,
        sub_end_date: 1,
        ...requiredCols
      });
    } else if (values.sub_start_date === null) {
      setRequiredColumn({
        sub_start_date: 0,
        sub_end_date: 0,
        ...requiredCols
      });
      setFieldValue("sub_end_date", null);
      setFieldTouched("sub_end_date");
    }
  }, [values.sub_start_date, values.sub_end_date]);

  useEffect(() => {
    if (values.event_start === null) {
      setFieldValue("event_end", null);
      setFieldTouched("event_end");
    }

    if (
      selectStaffGuid &&
      values.event_start !== null &&
      !values.event_start.includes("_") &&
      selectedStatus === dismissStatus
    ) {
      selectStaffGuid(staff_id, values.company, values.event_start);
    }
  }, [values.event_start]);

  return (
    <div className={styles.form}>
      {disabledForm && (
        <Alert
          errors={{
            head: "Добавление статуса недоступно, у сотрудника есть статус в будущем",
            color: "warning"
          }}
          className={{ container: styles.emptyAlert }}
        />
      )}
      {getKeys(values).map((key) => {
        if (["money", "date", "without_edu"].includes(key)) {
          return;
        }
        if (getValues(boolCols).find((col) => col.includes(key))) return;

        const field = cols[key];

        switch (key) {
          case "event_start":
            return (
              <Fragment key={key}>
                {getKeys(values).includes("sub_start_date") ? (
                  <p className={styles.subdateTitle}>Отпуск межвахтовый</p>
                ) : null}
                <div
                  className={classNames(styles.date, {
                    [styles.subdate]:
                      getKeys(initialValues).includes("sub_start_date"),
                    [styles.dateFullWidth]:
                      !getKeys(initialValues).includes("event_end")
                  })}
                >
                  <div
                    className={classNames("", {
                      [styles.calendar]: openedListName === key
                    })}
                  >
                    <DatePickerField
                      disabled={Boolean(field.disabled) || disabledForm}
                      required={Boolean(requiredColumn[key])}
                      name={key}
                      title={field.title}
                      isCalendarOpened={openedListName === key}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(key);
                      }}
                      minDate={minDateForDefaultUser}
                      maxDate={maxDate}
                    />
                  </div>
                  {getKeys(initialValues).includes("event_end") ? (
                    <div
                      className={classNames("", {
                        [styles.calendar]: openedListName === "event_end"
                      })}
                    >
                      <DatePickerField
                        required={Boolean(requiredColumn.event_end)}
                        disabled={
                          Boolean(cols["event_end"].disabled) || disabledForm
                        }
                        name="event_end"
                        title={cols["event_end"].title}
                        isCalendarOpened={openedListName === "event_end"}
                        setIsCalendarOpened={() => {
                          changeOpenedWindows("event_end");
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <StaffOneStatusesFormStaffGuid
                  staffGuid={staffCode}
                  isDismiss={selectedStatus === dismissStatus}
                  selectStaffGuid={selectStaffGuid}
                  staffGuidMessage={staffGuidMessage}
                />
              </Fragment>
            );
          case "sub_start_date":
            return (
              <div key={key}>
                <p className={styles.subdateTitle}>Отпуск ежегодный</p>
                <p className={styles.subdateSubtitle}>
                  Даты ежегодного отпуска всегда находятся в пределах дат
                  межвахтового отпуска
                </p>
                <div className={styles.subdate}>
                  <div
                    className={classNames("", {
                      [styles.calendar]: openedListName === key
                    })}
                  >
                    <DatePickerField
                      required={Boolean(requiredColumn[key])}
                      disabled={Boolean(field.disabled) || disabledForm}
                      name={key}
                      title={field.title}
                      isCalendarOpened={openedListName === key}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(key);
                      }}
                    />
                  </div>
                  <div
                    className={classNames("", {
                      [styles.calendar]: openedListName === "sub_end_date"
                    })}
                  >
                    <DatePickerField
                      name="sub_end_date"
                      required={Boolean(requiredColumn[key])}
                      disabled={
                        Boolean(cols["sub_end_date"].disabled) || disabledForm
                      }
                      title={cols["sub_end_date"].title}
                      isCalendarOpened={openedListName === "sub_end_date"}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows("sub_end_date");
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          case "phone":
            return (
              <div key={key}>
                <NumberFormat
                  required={Boolean(requiredColumn[key])}
                  disabled={Boolean(field.disabled) || disabledForm}
                  prefix={"+"}
                  name={key}
                  label={field.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setFieldValue(values[key] as string, e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values[key]}
                  customInput={Input}
                />
              </div>
            );
          // hardcode
          case "time_create":
            return (
              <div key={key}>
                <ViewField
                  title={field.title}
                  value={
                    values[key] ? getFormattedDate(String(values[key])) : null
                  }
                  disabled
                />
              </div>
            );

          case "event_end":
          case "sub_end_date":
          case "uid":
          case "company":
          case "type":
          case "staff_code":
            return;
        }

        if (key.includes("comment")) {
          return (
            <div key={key} className={styles.comment}>
              <Textarea
                name={key}
                value={String(values[key]).replace(/\s\s+/g, " ")}
                placeholder={field["title"]}
                label={field["title"]}
                required={Boolean(requiredColumn[key])}
                disabled={Boolean(field.disabled) || disabledForm}
                onChange={({
                  target: { value }
                }: {
                  target: { value: string };
                }) => {
                  setFieldValue(key, value.replace(/\s+/g, " ").trimStart());
                }}
                onBlur={() => {
                  setFieldValue(
                    key,
                    String(values[key]).replace(/\s\s+/g, " ").trim()
                  );
                }}
                commentCol={field as { [key: string]: string }}
              />
              {key === "dismiss_comment" ? (
                <Tooltip
                  color="blue-lazure"
                  text={
                    <div className={styles.commentTooltip}>
                      <p>
                        Опишите подробности, почему увольняется сотрудник. Не
                        используйте сокращения и аббревиатуры.
                      </p>
                      <br />
                      <p>
                        Примеры: “алкоголь на рабочем месте”, “не прошел
                        аттестацию по охране труда”, “нашел работу с более
                        высокой зарплатой”.
                      </p>
                    </div>
                  }
                  placement="right"
                >
                  <IconQuestionSmall className={styles.commentIcon} />
                </Tooltip>
              ) : null}
            </div>
          );
        }

        if (field?.["directory"]) {
          const dict = field["directory"][selectedStatus]
            ? field["directory"][selectedStatus]
            : field["directory"];
          const buildings = ["building", "transferbuilding"];

          return (
            <div key={key}>
              <Select
                name={key}
                title={field.title}
                label={
                  buildings.includes(key) && values[key]
                    ? dict[company][values[key] as string].title
                    : values[key] && values[key] !== -1
                    ? dict[values[key] as string]?.title
                    : null
                }
                options={buildings.includes(key) ? dict[company] : dict}
                required={Boolean(requiredColumn[key])}
                disabled={Boolean(field.disabled) || disabledForm}
                isFloating
              />
            </div>
          );
        }

        if (field?.["type"] === "date" && typeof values[key] !== "number") {
          return (
            <div
              className={openedListName === key ? styles.calendar : ""}
              key={key}
            >
              <DatePickerField
                name={key}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                onBlur={() => {
                  changeOpenedWindows(null);
                }}
                required={Boolean(requiredColumn[key])}
                disabled={Boolean(field.disabled) || disabledForm}
              />
            </div>
          );
        }

        if (field?.["type"] === "bool") {
          return (
            <div className={styles.boolCols} key={`boolContainer_${key}`}>
              <label className={styles.switch}>
                <Switch
                  name={key}
                  disabled={disabledForm}
                  onChange={(e) => {
                    const changeBoolCols = (key: string) => {
                      if (boolCols?.[key]) {
                        boolCols[key].forEach((bool_key) => {
                          values[bool_key] = initialValues[bool_key];
                        });
                      }
                    };

                    if (key === "black_list" || key === "grey_list") {
                      if (key === "black_list" && values["grey_list"]) {
                        changeBoolCols("grey_list");
                        setFieldValue("grey_list", 0);
                        setFieldTouched("grey_list");
                      } else if (key === "grey_list" && values["black_list"]) {
                        setFieldValue("black_list", 0);
                        changeBoolCols("black_list");
                        setFieldTouched("black_list");
                      }
                    }

                    if (!e.target.checked) {
                      changeBoolCols(key);

                      if (boolCols?.[key]) {
                        // условие, где проверяется необходимость удаления полей из обязательных, в зависимости от значения переключателя
                        const tempObject = cloneDeep(requiredCols);
                        [
                          ...boolCols[key],
                          ...boolCols[
                            key === "grey_list" ? "black_list" : "grey_list"
                          ]
                        ].forEach((bool_key) => {
                          if (bool_key in requiredCols) {
                            delete tempObject[bool_key];
                          }

                          setRequiredCols(tempObject);
                          setTimeout(() => {
                            setFieldTouched(key);
                          }, 100);
                        });
                      }
                    } else {
                      if (boolCols?.[key]) {
                        // условие, где проверяется необходимость добавления полей как обязательных, в зависимости от значения переключателя
                        const tempObject: {
                          [key: string]: number;
                        } = cloneDeep(requiredCols);
                        boolCols[
                          key === "black_list" ? "grey_list" : "black_list"
                        ].forEach((bool_key) => {
                          bool_key in requiredCols
                            ? delete tempObject[bool_key]
                            : "";
                        });

                        boolCols[key].forEach((bool_key) => {
                          // hardcode -- ошибка в настройках в ПО
                          // для grey_list в bool_cols приходит black_reason
                          if (
                            key.includes("grey") &&
                            bool_key.includes("black")
                          ) {
                            return;
                          }
                          if (!(bool_key in requiredCols)) {
                            tempObject[bool_key] = 1;
                          }
                        });

                        setRequiredCols(tempObject);

                        setTimeout(() => {
                          setFieldTouched(key);
                        }, 100);
                      }
                    }
                  }}
                />
                {field.title}
              </label>
              {boolCols[key]
                ? boolCols[key].map((boolKey) => {
                    if (key.includes("grey") && boolKey.includes("black")) {
                      return;
                    }
                    const boolField = cols[boolKey];
                    if (values[key]) {
                      if (boolField["directory"]) {
                        const entriesDict = Object.entries(
                          boolField["directory"]
                        ).filter(
                          (item) =>
                            item[0] !== "building" &&
                            item[0] !== "transferbuilding" &&
                            item[0] !== "dismiss_object"
                        );
                        const dict: {
                          [key: string]: {
                            newname: string;
                            title: string;
                          };
                        } = Object.fromEntries(entriesDict);
                        return (
                          <div
                            className={styles.choossingInput}
                            key={`boolCol_${key}_${boolKey}`}
                          >
                            <Select
                              name={boolKey}
                              title={boolField.title}
                              label={
                                values[boolKey] && values[boolKey] !== -1
                                  ? dict[values[boolKey] as string]
                                    ? dict[values[boolKey] as string].title
                                    : values[boolKey]
                                    ? String(values[boolKey])
                                    : null
                                  : null
                              }
                              options={dict}
                              isFloating
                              required={Boolean(requiredColumn[boolKey])}
                              disabled={Boolean(field.disabled) || disabledForm}
                            />
                          </div>
                        );
                      }
                      if (
                        boolField["type"] === "date" &&
                        typeof values[boolKey] !== "number"
                      ) {
                        return (
                          <div
                            className={
                              openedListName === boolKey ? styles.calendar : ""
                            }
                            key={`boolCol_${key}_${boolKey}`}
                          >
                            <DatePickerField
                              name={boolKey}
                              title={boolField.title}
                              isCalendarOpened={openedListName === boolKey}
                              setIsCalendarOpened={() => {
                                changeOpenedWindows(boolKey);
                              }}
                              onBlur={() => {
                                changeOpenedWindows(null);
                              }}
                              required={Boolean(requiredColumn[boolKey])}
                              disabled={Boolean(field.disabled) || disabledForm}
                            />
                          </div>
                        );
                      }
                      if (boolField["type"] === "bool") {
                        return (
                          <label
                            className={styles.switch}
                            key={`boolCol_${key}_${boolKey}`}
                          >
                            <Switch name={key} disabled={disabledForm} />
                            {field.title}
                          </label>
                        );
                      }
                      return (
                        <div key={`boolCol_${key}_${boolKey}`}>
                          <Input
                            name={boolKey}
                            label={cols[boolKey].title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={Boolean(requiredColumn[boolKey])}
                            disabled={Boolean(field.disabled) || disabledForm}
                          />
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
          );
        }

        return (
          <div key={key}>
            <Input
              name={key}
              onChange={handleChange}
              label={field?.title}
              required={Boolean(requiredColumn[key])}
              disabled={
                Boolean(field.disabled) ||
                key === "dismiss_position" ||
                disabledForm
              }
            />
          </div>
        );
      })}
      {showRetentionBlock ? (
        <StaffOneStatusesFormEdu
          openedListName={openedListName}
          changeOpenedWindows={changeOpenedWindows}
          selectedStatus={selectedStatus}
          staff_id={staff_id}
          params={params}
          isLoadingAllowEdu={isLoadingAllowEdu}
          retention={retention}
          updateRetention={updateRetention}
          checkEventAllowEdu={checkEventAllowEdu}
          disabledForm={disabledForm}
        />
      ) : null}
    </div>
  );
};

export default observer(StaffOneStatusesForm);
