import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";
import { Col } from "stores/StaffModule/types/Col";
import { SalaryOperation } from "../types/SalaryOperation";
import { OperationType } from "stores/SalaryModule/salaryOperations/types/SalaryOperationType";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import history from "./directories/salaryOneHistory.json";

interface ICol extends Col {
  id?: string;
  isvariable?: number;
  variable?: {
    [key: string]: Col;
  };
}

export default class SalaryOperationOneStore {
  error = false;
  isLoading = false;

  historyTitles: string[] = ["author", "value", "old", "now"];

  salaryOperationsOneCols: { [key: string]: ICol } = {};
  salaryOperationsTypes: { [id: string]: OperationType } = {};
  paymentlistStatuses: { [key: string]: Col } = {};

  salaryOperationsHistory: {
    [key: string]: { [key: string]: string | number };
  } = {};

  openedAllSalaryOperations: {
    [id: string]: Partial<SalaryOperation>;
  } = {};
  selectedOneSalaryOperation: Partial<SalaryOperation> = {};

  rootStore: RootStore;

  setSelectedOneOfSalaryOperation = (id: string) => {
    this.isLoading = true;
    this.error = false;
    this.getSalaryById(id, "detail");

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/salary", "Финансы");
    }

    if (!this.rootStore.menuStore.allWindows[`/salary/id=${id}`]) {
      this.rootStore.menuStore.addWindow(`/salary/id=${id}`, `Операция`);
      this.rootStore.menuStore.addParentPath(`/salary`, `/salary/id=${id}`);
    } else {
      this.rootStore.menuStore.updateWindow({
        mainPath: `/salary/id=${id}`,
        path: `/salary/id=${id}`
      });
    }

    if (
      Object.keys(this.openedAllSalaryOperations).length &&
      this.openedAllSalaryOperations[id]
    ) {
      if (Object.values(this.openedAllSalaryOperations[id]).length) {
        this.selectedOneSalaryOperation = this.openedAllSalaryOperations[id];
      } else this.error = true;
      this.isLoading = false;
    } else {
      this.openedAllSalaryOperations[id] = this.selectedOneSalaryOperation;

      Promise.all([
        !Object.values(this.salaryOperationsHistory).length
          ? (this.salaryOperationsHistory = history)
          : "",
        !Object.values(this.salaryOperationsTypes).length && this.getSelects()
      ]).then(() => {
        setTimeout(() => {
          runInAction(() => (this.isLoading = false));
        }, 2000);
      });
    }
  };

  getSalaryById = async (id: string, action: string) => {
    this.isLoading = true;

    try {
      const data: ApiResponse<SalaryOperation> & {
        cols: { [key: string]: ICol };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSalaryById",
        params: {
          id,
          action
        }
      });
      runInAction(() => {
        if (data["code"] === 200) {
          this.selectedOneSalaryOperation = data["result"];
          this.salaryOperationsOneCols = data["cols"];
        } else {
          this.error = true;
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getBuildingsList = async () => {
    if (this.salaryOperationsOneCols.object.isvariable) {
      try {
        const data: ApiResponse<{ [key: string]: ICol } | -1> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "building",
            currentClass: "building",
            method: "getList",
            params: {
              on_page: 300
            }
          });

        runInAction(() => {
          if (data["result"] !== -1) {
            !this.salaryOperationsOneCols.object.variable
              ? (this.salaryOperationsOneCols.object.variable = {})
              : "";
            Object.values(data["result"]).forEach((option) => {
              this.salaryOperationsOneCols.object.variable[option.id] = option;
            });
          } else {
            this.error = true;
          }
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
        });
      }
    }
  };

  cancelOperation = async (id: string) => {
    const formData = { sd: id };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "cancelOperation",
        body: formData
      });
      runInAction(() => {
        if (!data["result"]) {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  deleteOperation = async (id: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "deleteOperation",
        body: { id: id }
      });
      runInAction(() => {
        if (!data["result"]) {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<{
        operation_type: { [id: string]: OperationType };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSelects"
      });

      runInAction(() => {
        if (data.code === 200) {
          this.salaryOperationsTypes = data["result"]["operation_type"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
