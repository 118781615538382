import styles from "./buildingOneTimesheetInfoCell.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import { ReactComponent as IconMessage } from "shared/assets/images/mainIcons/iconMessage.svg";
import { ReactComponent as IconMoon } from "shared/assets/images/mainIcons/iconMoon.svg";

import BuildingOneTimesheetInfoCellSelect from "./BuildingOneTimesheetInfoCellSelect";

type BuildingOneTimesheetInfoCellProps = {
  staff_id: string;
};

const BuildingOneTimesheetInfoCell = ({
  staff_id
}: BuildingOneTimesheetInfoCellProps) => {
  const { id } = useParams();
  const { buildingOneTimesheetStore } = useStores();

  return (
    <>
      {buildingOneTimesheetStore.daysInMonth[
        buildingOneTimesheetStore.year[id]
      ]?.[buildingOneTimesheetStore.month[id]]?.length
        ? buildingOneTimesheetStore.daysInMonth[
            buildingOneTimesheetStore.year[id]
          ][buildingOneTimesheetStore.month[id]].map((day) => {
            // Состояния в смене
            const workshift_staff_status =
              buildingOneTimesheetStore.selects.workshift_staff_status;
            // Статусы
            const events_type = buildingOneTimesheetStore.selects.events_type;
            // Дата смены в формате yyyy-MM-dd
            const workshiftDate = `${buildingOneTimesheetStore.year[id]}-${
              buildingOneTimesheetStore.month[id] < 10 ? 0 : ""
            }${buildingOneTimesheetStore.month[id]}-${day < 10 ? 0 : ""}${day}`;
            // Данные смены сотрудника на дату смены для ячейки
            const workshift =
              buildingOneTimesheetStore.allOpenedTabels[id]?.tabel?.[
                staff_id
              ]?.[workshiftDate];

            return workshift ? (
              <td
                key={`${staff_id}_${workshiftDate}`}
                className={`${styles.day} ${
                  workshift?.comment ? styles.day_withTooltip : ""
                } ${
                  // Стили при наведении только для ячеек, которым доступна смена состояния
                  // Первый вариант - доступно изменение внешнего состояния
                  (workshift?.event_type &&
                    // и статус разрешает изменение внешек
                    events_type?.[workshift?.event_type]?.custom
                      ?.buidling_condition?.active === 1) ||
                  // Второй вариант это состояние в смене
                  (!workshift?.event_type &&
                    // У которого есть список других состояний в смене, на которые можно изменить текущий
                    workshift_staff_status[workshift?.title]?.custom
                      ?.children &&
                    Object.values(
                      workshift_staff_status[workshift.title].custom.children
                    ).length)
                    ? styles.day_withHover
                    : ""
                } ${
                  buildingOneTimesheetStore.openedWindow ===
                  `${staff_id}${workshiftDate}`
                    ? styles.day_active
                    : ""
                } ${
                  styles[`day_${workshift?.hours ? "lime" : workshift?.color}`]
                }`}
                onClick={() => {
                  if (
                    // При клике, если значение ячейки - состояние в смене
                    !workshift?.event_type &&
                    // У которого есть список других состояний в смене, на которые можно изменить текущий
                    workshift_staff_status[workshift?.title]?.custom
                      ?.children &&
                    Object.values(
                      workshift_staff_status[workshift.title].custom.children
                    ).length
                  ) {
                    // То мы просто открываем выпадающий список
                    buildingOneTimesheetStore.setOpenedWindow(
                      `${staff_id}${workshiftDate}`
                    );
                  } else if (
                    // Если же значение можно изменить на внешнее состоние
                    workshift?.event_type &&
                    // и статус разрешает смену внешек
                    events_type?.[workshift?.event_type]?.custom
                      ?.buidling_condition?.active === 1
                  ) {
                    Promise.all([
                      // сначала делаем запрос на недоступные к смене состояния
                      buildingOneTimesheetStore.getDisabledConditions(
                        id,
                        workshiftDate,
                        staff_id
                      )
                    ]).then(() => {
                      // и только потом открываем выпадающий список
                      buildingOneTimesheetStore.setOpenedWindow(
                        `${staff_id}${workshiftDate}`
                      );
                    });
                  }
                }}
                onBlur={() => {
                  buildingOneTimesheetStore.setOpenedWindow("");
                }}
                tabIndex={0}
                id={`BuildingOneTimesheetInfoCell_changeOpenedWindow_${staff_id}${workshiftDate}`}
                data-tooltip={workshift?.comment}
              >
                <div className={styles.cell}>
                  <div
                    className={`${styles.cell__value} ${
                      workshift?.hours > 10
                        ? styles["cell__value_accent-coral"]
                        : workshift?.hours < 10
                        ? styles["cell__value_accent-light-orange"]
                        : ""
                    }`}
                  >
                    {workshift?.hours !== undefined
                      ? workshift.hours
                      : workshift?.title
                      ? workshift.title
                      : ""}
                  </div>
                  <div className={styles.cell__icons}>
                    {workshift?.comment?.length ? <IconMessage /> : ""}
                    {workshift?.night ? <IconMoon /> : ""}
                  </div>
                </div>
                <BuildingOneTimesheetInfoCellSelect
                  staff_id={staff_id}
                  day={day}
                />
              </td>
            ) : (
              <td key={`${staff_id}_${workshiftDate}`} className={styles.day}>
                –
              </td>
            );
          })
        : null}
    </>
  );
};

export default observer(BuildingOneTimesheetInfoCell);
