import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { Project } from "stores/utils/types/Project";
import { ProjectsSelectType } from "../types/ProjectsSelectType";
import { Col } from "stores/utils/types/Col";
import { Param } from "stores/utils/types/Param";
import { CustomFields } from "stores/utils/types/CustomFields";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import { transform } from "lodash";

import work_direction from "../work_direction.json";
import cancellation_types from "../cancellation_types.json";

export type StaffData = {
  surname: string;
  name: string;
  patronymic: string;
  uid: string;
  id: string;
  phone: {
    [key: string]: {
      number: number | string;
      comment: string;
      confirm: number;
    };
  };
};

export default class ProjectsOneStore {
  error = false;
  isLoading = false;

  openedAllProjects: { [project_id: string]: Partial<Project> } = {};
  selectedOneProject: Partial<Project> = {};

  projectsTableCols: { [key: string]: Col } = {};
  projectsTableParams: { [key: string]: Param } = {};

  selects: Partial<{
    [key: string]: { [key: string]: ProjectsSelectType };
  }> = {};

  projectsStaffData: {
    [staff_id: string]: StaffData;
  } = {};

  setSelectedOneOfProject = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/projects", "Проекты");
    }

    if (!this.rootStore.menuStore.allWindows[`/projects/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/projects/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllProjects[id];
    }

    if (
      Object.keys(this.openedAllProjects).length &&
      this.openedAllProjects[id]
    ) {
      if (Object.values(this.openedAllProjects[id]).length) {
        this.selectedOneProject = this.openedAllProjects[id];
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else {
      Promise.all([
        !Object.keys(this.projectsTableCols).length && this.getTableCols(),
        !Object.keys(this.projectsTableParams).length && this.getTableParams(),
        !this.selects.priority && this.getProjectsPriority(),
        !this.selects.type && this.getProjectsTypes(),
        !this.selects.status && this.getProjectsStatus(),
        !this.selects.company && this.getCompaniesList(),
        !this.selects.type_work && this.getTypeWork(),
        !this.selects.sub_work && this.getSubWork(),
        !this.selects.scaffold_type && this.getScaffoldTypes(),
        !this.selects.scaffold_species && this.getScaffoldSpecies(),
        !this.selects.work_direction && this.getWorkDirectionList(),
        !this.selects.cancellation_types && this.getCancellationTypes(),
        this.getOneOfProject(id)
      ]).then(() => {
        runInAction(() => (this.isLoading = false));
      });
    }
  };

  getOneOfProject = async (id: string) => {
    try {
      const data: ApiResponse<Project | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "projects",
          currentClass: "projects",
          method: "getByColOne",
          params: {
            col: "id",
            value: id
          }
        });

      if (data.result !== -1) {
        this.openedAllProjects[id] = data.result;

        if (data.result.staff_manager && data.result.staff_manager !== "NULL") {
          this.getOneOfStaff(data["result"]["staff_manager"]);
        }

        if (data.result.staff_rp && data.result.staff_rp !== "NULL") {
          this.getOneOfStaff(data["result"]["staff_rp"]);
        }

        this.getCustomFields(id);

        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/projects/id=${id}`,
          title: data["result"]["title"]
        });
      } else {
        this.openedAllProjects[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/projects/id=${id}`,
          title: "Ничего не найдено"
        });
        this.error = true;
      }

      runInAction(() => (this.selectedOneProject = this.openedAllProjects[id]));
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCustomFields = async (project_id: string) => {
    try {
      const data: ApiResponse<CustomFields | false | Record<string, never>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "projects",
          method: "getCustomFields",
          params: {
            project_id: project_id
          }
        });

      runInAction(() => {
        if (!data.errors) {
          !this.openedAllProjects[project_id]?.custom
            ? (this.openedAllProjects[project_id] = {
                custom: {},
                ...this.openedAllProjects[project_id]
              })
            : "";
          this.openedAllProjects[project_id].custom = {
            custom_fields: data.result
          } as Project["custom"];

          Object.entries(data.result).forEach(([title, param]) => {
            if (param.type === "select") {
              const directory: {
                [key: string]: { title: string; id: string };
              } = {};
              param.data &&
                Object.values(param.data).forEach((option) => {
                  directory[option as string] = {
                    title: option as string,
                    id: option as string
                  };
                });

              this.openedAllProjects[project_id].custom.custom_fields[
                title
              ].data = directory;
            }
          });
        } else {
          runInAction(() => {
            this.error = true;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: Col;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getTableCols"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsTableCols = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: Param;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects",
        method: "getTableParams"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.projectsTableParams = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsPriority = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_priority",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.priority = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsTypes = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_types",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.type = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getProjectsStatus = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "projects",
        currentClass: "projects_status",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.status = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selects.company = transform(
            data.result,
            (result, value, key) => {
              result[value.id] = value;
              key !== value.id && delete result[key];
            },
            {}
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTypeWork = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_type_work",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          this.selects.type_work = transform(
            data.result,
            (result, value, key) => {
              result[value.id] = value;
              key !== value.id && key !== value.id && delete result[key];
            },
            {}
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSubWork = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_type_sub_work",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          this.selects.sub_work = transform(
            data.result,
            (result, value, key) => {
              result[value.id] = value;
              key !== value.id && delete result[key];
            },
            {}
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getScaffoldTypes = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_types",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          this.selects.scaffold_type = transform(
            data.result,
            (result, value, key) => {
              result[value.id] = value;
              key !== value.id && delete result[key];
            },
            {}
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getScaffoldSpecies = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: ProjectsSelectType;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        currentClass: "application_register_species",
        on_page: 200,
        method: "getList"
      });

      runInAction(() => {
        if (data.result !== -1) {
          this.selects.scaffold_species = transform(
            data.result,
            (result, value, key) => {
              result[value.id] = value;
              key !== value.id && delete result[key];
            },
            {}
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getWorkDirectionList = async () => {
    this.selects.work_direction = work_direction;
  };

  getCancellationTypes = async () => {
    this.selects.cancellation_types = cancellation_types;
  };

  getOneOfStaff = async (id: string) => {
    try {
      const data: {
        record: StaffData;
        errors: {
          [key: string]: string;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: {
          staff_id: id
        }
      });

      runInAction(() => {
        if (data.record) {
          this.projectsStaffData[id] = data.record;
        } else {
          this.error = true;
          return {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
