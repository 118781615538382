import styles from "./buildingOneWorkshiftHeaderWorkshiftStatus.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";

import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type BuildingOneWorkshiftHeaderWorkshiftStatusProps = {
  title?: string;
};

const BuildingOneWorkshiftHeaderWorkshiftStatus = ({
  title
}: BuildingOneWorkshiftHeaderWorkshiftStatusProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const navigate = useNavigate();

  const navigateOneWorkshift = (id: string) => {
    navigate(`/workshift/id=${id}/staff`);
  };

  return (
    <div className={styles.status}>
      <img
        src={
          !buildingOneWorkshiftStore.selects.status?.[
            title
              ? buildingOneWorkshiftStore.selectedOne[title]?.status
              : buildingOneWorkshiftStore.selectedOne.status
          ]?.custom.done
            ? iconLockOpen
            : iconLock
        }
        className={styles.status__icon}
      />
      {!title ? (
        <span className={styles.status__title}>
          {
            buildingOneWorkshiftStore.selects.status?.[
              buildingOneWorkshiftStore.selectedOne.status
            ]?.title
          }
        </span>
      ) : (
        <a
          className={styles.status__link}
          id={`BuildingOneWorkshiftHeaderWorkshiftStatus_navigate_${buildingOneWorkshiftStore.selectedOne[title].id}`}
          onClick={() =>
            navigateOneWorkshift(
              buildingOneWorkshiftStore.selectedOne[title]?.id
            )
          }
        >
          {getFormattedDate(
            buildingOneWorkshiftStore.selectedOne[title].date_report
          )}
        </a>
      )}
    </div>
  );
};

export default observer(BuildingOneWorkshiftHeaderWorkshiftStatus);
