import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "stores/StaffModule/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import { getMonth, getYear } from "date-fns";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";

interface ICol extends Col {
  id?: string;
}

export default class BuildingOneAddWorkshiftFormStore {
  isOpenedFromSubmodule = ""; // идентификатор открытия формы из подмодуля (имя подмодуля)
  isOpenedFromMenu = false; // идентификатор открытия формы из меню
  error = false; // ошибка общая
  errorsMessage: Errors["message"][] = []; // ошибка смены

  isLoadingForForm = false; // идентификатор загрузки для формы добавления смены

  cols: { [key: string]: Col } = {};
  buildingsList: { [key: string]: ICol } = {};

  newWorkshiftId = "";

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  setIsOpenedWorkshiftForm = () => {
    this.isLoadingForForm = true;
    Promise.all([
      isEmpty(this.buildingsList) && this.getBuildingsList(),
      isEmpty(this.cols) && this.getWorkshiftCols()
    ]).then(() => runInAction(() => (this.isLoadingForForm = false)));
  };

  getBuildingsList = async (count?: number) => {
    if (isEmpty(this.buildingsList)) {
      this.isLoadingForForm = true;
      try {
        const data: ApiResponse<{ [key: string]: ICol } | -1> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "building",
            currentClass: "building",
            method: "getList",
            params: {
              on_page: count !== undefined ? count : 1
            }
          });

        runInAction(() => {
          if (count === undefined) {
            this.getBuildingsList(data.nav.count);
          } else if (data["result"] !== -1) {
            getValues(data["result"]).forEach((building) => {
              this.buildingsList[building.id] = building;
            });
          } else {
            this.error = true;
          }
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
        });
      }
    }
  };

  getWorkshiftCols = async () => {
    if (!Object.values(this.cols).length) {
      try {
        const data: ApiResponse<{ [key: string]: Col } | -1> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "workshift",
            currentClass: "workshift",
            method: "getTableCols"
          });

        runInAction(() => {
          if (data.result !== -1) {
            this.cols = data.result;
          } else this.error = true;
        });
      } catch (error) {
        runInAction(() => {
          this.error = true;
        });
      }
    }
  };

  addNewWorkshift = async (values: {
    building_id: string;
    date: string;
    comment: string;
  }) => {
    this.isLoadingForForm = true;
    this.errorsMessage = [];
    this.newWorkshiftId = "";

    try {
      const data: ApiResponse<number> & {
        new_workshift: {
          [key: string]: string;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "addWorkshift",
        body: { ...values }
      });

      if (data["result"] === 1) {
        await Promise.all([
          this.rootStore.buildingOneTimesheetStore.getTabel(
            values.building_id,
            getYear(new Date(values.date)),
            getMonth(new Date(values.date)) + 1
          )
        ]).then(() => {
          runInAction(() => {
            this.newWorkshiftId = data.new_workshift.id;

            setTimeout(() => {
              this.isLoadingForForm = false;
              this.rootStore.buildingOneTimesheetStore.isLoading = false;
            }, 200);
          });
        });
      } else {
        runInAction(() => {
          this.errorsMessage = [data.message];
          this.isLoadingForForm = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForForm = false;
      });
    }
  };

  setOpenedWindow = (module?: string) => {
    this.errorsMessage = [];
    this.isOpenedFromMenu = false;

    if (!this.isOpenedFromMenu) {
      this.isOpenedFromSubmodule = module || "";
    }
  };

  setIsOpenedFromMenu = () => {
    this.errorsMessage = [];
    this.isOpenedFromMenu = true;
  };
}
