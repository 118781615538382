import { dateFormats } from "stores/utils/consts";
import { CalendarContainer, CalendarContainerProps } from "react-datepicker";
import { classNames } from "shared/utils/helpers/classNames";
import styles from "./customCalendarContainer.module.scss";
import { useField } from "formik";
import { Button, ButtonTheme } from "shared/ui/Button";

interface CustomCalendarContainerProps extends CalendarContainerProps {
  name: string;
  endDate: Date;
  inputError: boolean;
  handleSetValue: () => void;
}

const CustomCalendarContainer = ({
  name,
  endDate,
  inputError,
  children,
  handleSetValue
}: CustomCalendarContainerProps) => {
  const [field] = useField({
    name
  });

  return dateFormats.date.format ? (
    <CalendarContainer
      className={classNames(styles.calendarContainer, {
        [styles.calendarContainer_withoutData]: !field.value
      })}
    >
      <div className={styles.children}>{children}</div>
      <div className={styles.btnSaveWrapper}>
        <Button
          type="button"
          theme={ButtonTheme.SECONDARY}
          id={`CustomCalendarContainer_saveSelectedDate_${field.name}`}
          className={styles.btnSave}
          disabled={!endDate || inputError}
          onClick={handleSetValue}
        >
          Готово
        </Button>
      </div>
    </CalendarContainer>
  ) : null;
};

export default CustomCalendarContainer;
