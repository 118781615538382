import styles from "./staffTablePresaveInput.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import { PresaveStaff } from "stores/AregisterModule/types/PresavesStaff";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { classNames } from "shared/utils/helpers/classNames";

type StaffTablePresaveInputProps = {
  presave: string;
  name: string;
  staff_id: string;
  company: string;
  staff: PresaveStaff;
};

const StaffTablePresaveInput = ({
  presave,
  name,
  staff_id,
  company,
  staff
}: StaffTablePresaveInputProps) => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();
  const { values, errors, initialValues, setFieldValue } = useFormikContext();
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    if (
      String(value)?.length > 1 &&
      String(value)?.[0] === "0" &&
      (String(value)?.[1] === "0" || String(value)?.[1] !== ".")
    ) {
      return;
    }
    setFieldValue(name, value);
  };
  const handleOnClick = () => {
    aregisterOneStore.setFocusedPresave(presave);
    aregisterOneStore.setFocusedCompanyStaff(`${staff_id}_${company}`);
  };
  const handleOnBlur = (value: number, presave_staff_id: string) => () => {
    if (!errors[name]) {
      aregisterOneStore.changePresaveContribution(presave_staff_id, value || 0);
      if (!value) setFieldValue(name, 0);
    }

    setTimeout(() => {
      if (
        aregisterOneStore.editContributionError[id]?.presave_staff_id ===
        presave_staff_id
      ) {
        setFieldValue(name, initialValues[name]);
      }
    }, 300);
  };

  return (
    <div className={styles.wrapper}>
      <input
        className={classNames(styles.contribution, {
          [styles.inputError]: errors[name]
        })}
        type="number"
        name={name}
        value={values[name]}
        onChange={handleOnChange}
        onClick={handleOnClick}
        onBlur={handleOnBlur(
          values[name],
          staff.group_by_presave?.[presave]?.[company]?.contribution
            ?.presave_staff_id || ""
        )}
        min={0}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "-") {
            e.preventDefault();
          }
        }}
      />
      {errors[name] ? <div className={styles.error}>{errors[name]}</div> : ""}
    </div>
  );
};

export default observer(StaffTablePresaveInput);
