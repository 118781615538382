import styles from "./staffOneStatusesFormEdu.module.scss";
import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";

import { Input } from "shared/ui/Inputs/Input";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Switch from "shared/ui/Inputs/Switch";
import NumberFormat from "react-number-format";
import Alert from "shared/ui/Alert";

import { format, isValid, parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";

import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { Retention } from "stores/StaffModule/types/Retention";
import { Status } from "stores/StaffModule/types/Status";

type StaffOneStatusesFormEduProps = {
  staff_id: string;
  selectedStatus: string;
  openedListName: string;
  changeOpenedWindows: (arg: string) => void;
  params: BuildingEventType;
  isLoadingAllowEdu: boolean;
  retention: Retention;
  updateRetention: Retention;
  checkEventAllowEdu: (
    staff_id: string,
    event_id: string,
    date?: string
  ) => void;
  disabledForm: boolean;
};

interface StatusWithRetention extends Status {
  money: number;
  date: string;
  without_edu: number;
}

const StaffOneStatusesFormEdu = ({
  openedListName,
  changeOpenedWindows,
  selectedStatus,
  staff_id,
  params,
  isLoadingAllowEdu,
  retention,
  updateRetention,
  checkEventAllowEdu,
  disabledForm
}: StaffOneStatusesFormEduProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { setFieldValue, setFieldTouched, values } =
    useFormikContext<StatusWithRetention>();

  useEffect(() => {
    setTimeout(() => {
      if (isUpdating && !isLoadingAllowEdu) {
        setFieldValue("date", updateRetention?.date);
        setFieldValue("money", updateRetention?.sum);

        setIsUpdating(false);

        setFieldTouched("date");
        setFieldTouched("money");
      }
    }, 200);
  }, [isLoadingAllowEdu]);

  return params?.custom?.close_withholding && retention ? (
    retention.result ? (
      <>
        <div className={styles.eduTitle}>Удержание УЦ</div>
        {!retention.message ? (
          <>
            <label className={styles.switch}>
              <Switch
                name="without_edu"
                disabled={disabledForm}
                onChange={(e) => {
                  if (!e.target.checked) {
                    // при отключении "Добавить удержание"
                    // значение полей date и money возвращаем в изначальное
                    setFieldValue("date", retention?.date || null);
                    setFieldValue("money", retention?.sum || 0);

                    setFieldTouched("date");
                    setFieldTouched("money");
                  }
                }}
              />
              Добавить операцию
            </label>
            {values["without_edu"] ? (
              <div className={styles.eduFields}>
                <Field
                  name="date"
                  validate={(value: string) => {
                    if (!value && !values.without_edu) {
                      return "Поле обязательно для заполнения";
                    }

                    if (
                      value &&
                      !isValid(parse(value, "yyyy-MM-dd", new Date()))
                    ) {
                      return "Неверный формат даты";
                    }

                    if (retention?.min_date) {
                      return parse(
                        retention.min_date,
                        "yyyy-MM-dd",
                        new Date()
                      ) > parse(value, "yyyy-MM-dd", new Date())
                        ? `Дата не может быть меньше ${format(
                            parse(retention.min_date, "yyyy-MM-dd", new Date()),
                            dateFormats.date.format
                          )}`
                        : false;
                    }
                  }}
                >
                  {({ field }) => {
                    return (
                      <DatePickerField
                        {...field}
                        value={(values["date"] as string) || null}
                        title="Дата операции"
                        isCalendarOpened={openedListName === "date"}
                        setIsCalendarOpened={() => {
                          changeOpenedWindows("date");
                        }}
                        onBlur={() => {
                          changeOpenedWindows(null);
                        }}
                        required
                        disabled={!values.without_edu || disabledForm}
                        minDate={
                          retention?.min_date
                            ? parse(
                                retention.min_date,
                                "yyyy-MM-dd",
                                new Date()
                              )
                            : null
                        }
                        onChange={(value) => {
                          if (
                            selectedStatus !== retention?.status ||
                            value !== updateRetention?.date ||
                            value !== retention?.date
                          ) {
                            checkEventAllowEdu(staff_id, selectedStatus, value);
                            setIsUpdating(true);
                          }
                        }}
                      />
                    );
                  }}
                </Field>

                <NumberFormat
                  name="money"
                  label={"Сумма операции"}
                  customInput={Input}
                  suffix=" ₽"
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  allowEmptyFormatting
                  withClearBtn
                  allowedDecimalSeparators={["."]}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      event.target.selectionEnd ===
                      event.target.value.length - 2
                    ) {
                      event.target.selectionStart = 0;
                      event.target.selectionEnd = 0;
                    }
                  }}
                  required
                  disabled={!values.without_edu || disabledForm}
                />
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <Alert
            errors={{
              head: retention.message.head,
              color: retention.message.color
            }}
          />
        )}
      </>
    ) : retention.message ? (
      <Alert
        errors={{
          head: retention.message.head,
          color: retention.message.color
        }}
      />
    ) : null
  ) : null;
};

export default StaffOneStatusesFormEdu;
