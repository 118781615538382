import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Aregister } from "stores/AregisterModule/types/Aregister";
import { ApiResponse } from "stores/utils/types/ApiResponse";
export default class AregisterOneEditStore {
  error = false;
  errorMessage = "";
  isLoading = false;

  editableFields: { [aregister_id: string]: string[] } = {};

  selectedOneForEdit: Partial<Aregister> = {};
  openedAllAppllicationsForEdit: {
    [aregister_id: string]: Partial<Aregister>;
  } = {};

  rootStore: RootStore;

  setSelectedApplication = (id: string) => {
    this.isLoading = true;
    this.error = false;

    this.selectedOneForEdit = {};

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow(
        "/aregister",
        "Реестр заявок строительных лесов"
      );
    }
    if (!this.rootStore.menuStore.allWindows[`/aregister/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/aregister/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllAppllicationsForEdit[id];
    }

    if (
      Object.keys(this.openedAllAppllicationsForEdit).length &&
      this.openedAllAppllicationsForEdit[id] &&
      this.editableFields[id]
    ) {
      if (Object.values(this.openedAllAppllicationsForEdit[id]).length) {
        this.selectedOneForEdit = this.openedAllAppllicationsForEdit[id];
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else {
      this.getData(id);
    }
  };

  getData = (id: string) => {
    this.isLoading = true;
    Promise.all([this.getDataInfo(id)]).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };

  getDataInfo = async (id: string) => {
    try {
      const data: ApiResponse<Aregister | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getOne",
          params: {
            module: "aregister",
            id: id
          }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.selectedOneForEdit = data["result"];

          Promise.all([this.getEditableFields(id)]).then(() => {
            Promise.all([
              this.rootStore.aregisterAppcreateStore.getInitialValuesForEdit(
                data["result"] as Aregister
              )
            ]).then(() => {
              this.rootStore.menuStore.updateTabWindow({
                mainPath: `/aregister/id=${id}`,
                title: `Заявка ${this.selectedOneForEdit["internal_num"]}`
              });
            });
          });
        } else {
          this.selectedOneForEdit = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/aregister/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        }

        this.openedAllAppllicationsForEdit[id] = this.selectedOneForEdit;
      });
    } catch (error) {
      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/aregister/id=${id}`,
        title: "Ничего не найдено"
      });
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getEditableFields = async (id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getEditableFields",
          params: {
            module: "aregister",
            id: id
          }
        });

      runInAction(() => {
        if (data["result"]) {
          this.editableFields[id] = Object.values(data.result);
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
