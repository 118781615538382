import styles from "./aregisterTableMassEditRow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef } from "react";

import { useFormikContext } from "formik";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";
import { Select } from "stores/utils/types/Select";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

type AregisterTableMassEditRowProps = {
  openedListName: string;
  changeOpenedWindows: (title: string) => void;
  getStatus: (title: string) => Select<{
    done?: string;
    cancelled?: string;
  }>;
};

const AregisterTableMassEditRow = ({
  openedListName,
  changeOpenedWindows,
  getStatus
}: AregisterTableMassEditRowProps) => {
  const { aregisterListStore } = useStores();

  const { values, dirty, setFieldValue, isValid, setFieldTouched } =
    useFormikContext<AregisterListFormik>();

  const refChange = useRef<HTMLTextAreaElement>();

  const handleSendBtnClick = (title: string) => () => {
    if (isValid && values?.[title]) {
      values.info.forEach((item, ind) => {
        const status = getStatus(item.status);
        !status?.custom?.done &&
          !status?.custom?.cancelled &&
          setFieldValue(`info.${ind}.${title}`, values?.[title]);
        setFieldTouched(`info.${ind}.${title}`, true, false);
      });

      setFieldValue(title, "");

      setTimeout(() => {
        refChange.current.click();
      });

      aregisterListStore.setIsMassEditDisabled(false);
    }
  };

  return aregisterListStore.openedEditType === "mass_edit" ? (
    <tr className={styles.blueRow}>
      {aregisterListStore.currentTitles.map((title) => {
        switch (title) {
          case "internal_num":
            return <td key={title}></td>;
          case "date_start":
          case "date_end":
            return (
              <td key={title}>
                <div
                  className={styles.datePickerForAllEdit}
                  id={`AregisterTableMassEditRow_${title}`}
                >
                  <DatePickerField
                    name={title}
                    title=""
                    placeholderVisible
                    isCalendarOpened={openedListName === title}
                    setIsCalendarOpened={() => {
                      changeOpenedWindows(title);
                    }}
                    placement="right"
                    className={styles.datePickerCustom}
                    onChange={() => {
                      aregisterListStore.setIsMassEditDisabled(true);
                    }}
                  />
                  <StatusIcon
                    icon="iconsend"
                    color="bw-gray5"
                    onClick={{
                      id: `AregisterTableMassEditRow_send_${title}`,
                      handleClick: handleSendBtnClick(title)
                    }}
                    className={classNames(styles.iconMassEdit, {
                      [styles.hasValue]: values?.[title] && dirty && isValid
                    })}
                  />
                </div>
              </td>
            );

          default:
            return <td key={title}></td>;
        }
      })}
    </tr>
  ) : null;
};

export default observer(AregisterTableMassEditRow);
