import Error from "./Error";
import ErrorWindow from "./Error/ErrorWindow";
import Loading from "./Loading/index";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";

type LoadedComponentProps = {
  isLoading?: boolean;
  error?: boolean;
  errorMessage?: string;
  closeWindow?: () => void;
  actionButton?: {
    [key: string]: { id: number; title: string; action?: () => void };
  };
  children?: JSX.Element;
  withoutText?: boolean;
};

const LoadedComponent = ({
  isLoading,
  error,
  errorMessage,
  closeWindow,
  actionButton,
  children,
  withoutText
}: LoadedComponentProps) => {
  const { modalWindowsStore, menuStore, apiStore } = useStores();

  // проверка - есть ли для открытой страницы ошибка кода в сторе api
  const errorPage = apiStore.errorPage.includes(
    `${menuStore.tabId || "main"}_${menuStore.openedModule || "main"}_${
      menuStore.openedSubmodule || null
    }`
  );

  if (isLoading && !errorPage) {
    return <Loading withoutText={withoutText} />;
  }
  if (modalWindowsStore.isErrorWindow && errorMessage) {
    return (
      <ErrorWindow
        errorMessage={errorMessage}
        setIsOpenWindow={() => {
          closeWindow && closeWindow();
          modalWindowsStore.setIsErrorWindow(false);
        }}
        actionButton={actionButton}
      />
    );
  }
  if (error || errorPage) {
    return <Error error={error} errorText={errorMessage} />;
  }
  return children;
};

export default observer(LoadedComponent);
