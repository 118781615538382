import styles from "./datePickerField.module.scss";
import "./index.scss";
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { useFormikContext, useField } from "formik";
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale
} from "react-datepicker";

import NumberFormat from "react-number-format";
import StatusIcon from "shared/ui/StatusIcon";

import { format, isValid, parse } from "date-fns";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import { dateFormats } from "stores/utils/consts";
import getDate from "date-fns/fp/getDate";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";
import { classNames } from "shared/utils/helpers/classNames";
import CustomCalendarContainer from "./CustomCalendarContainer";
import CustomHeader from "./CustomHeader";
import { Button, ButtonTheme } from "shared/ui/Button";
import { Placement } from "@floating-ui/react-dom";

export type DateFormatType = "yyyy" | "MM.yyyy" | "MMMM yyyy";

type DatePickerFieldProps = {
  /**
   * Имя поля для связывания с Formik
   */
  name: string;
  /**
   * Состояние открытия/закрытия календаря
   */
  isCalendarOpened: boolean;
  /**
   * Функция открытия/закрытия календаря
   */
  setIsCalendarOpened: (isCalendarOpened: boolean) => void;
  /**
   * Функция при изменении даты
   */
  onChange?: (value: string) => void;
  /**
   * Функция при блюре
   */
  onBlur?: (value: string) => void;
  /**
   * Заблокирован ли календарь
   */
  disabled?: boolean;
  /**
   * Нужно ли отображать плэйсхолдер
   */
  placeholderVisible?: boolean;
  /**
   * Обязательность поля
   */
  required?: boolean;
  /**
   * Название поля, которое выводится в интерфейсе
   */
  title: string;
  /**
   * Варианты отображения календаря. Сейчас доступны right | left | bottom-start | bottom-end
   */
  placement?: Placement;
  /**
   * Вид отображения дата (полная дата, только код, месяц и год)
   */
  dateFormat?: DateFormatType;
  /**
   * Нужна ли кнопка очистки значения
   */
  withClearBtn?: boolean;
  /**
   * Если передан dateFormat MM.yyyy, то нужно ли выводить полное название месяца
   */
  withMonthLongName?: boolean;
  /**
   * Минимальная дата для выбора (даты меньше блокируются)
   */
  minDate?: Date;
  /**
   * Максимальная дата для выбора (даты больше блокируются)
   */
  maxDate?: Date;
  /**
   * Дэйтпикер в виде кнопки
   */
  isButton?: boolean;
  /**
   * Стили от родительских компонентов
   */
  className?: { container?: string; popper?: string; input?: string };
  /**
   * Не отображать ошибку валидации
   */
  withoutError?: boolean;
};

const DatePickerField = ({
  name,
  isCalendarOpened,
  setIsCalendarOpened,
  onChange,
  onBlur,
  disabled,
  placeholderVisible,
  required,
  title,
  placement,
  dateFormat,
  withClearBtn,
  withMonthLongName,
  minDate,
  maxDate,
  isButton,
  className,
  withoutError
}: DatePickerFieldProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({
    name,
    disabled,
    required,
    title
  });
  const [initialValue, setInitialValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<Date>(null);
  const [hoveredDate, setHoveredDate] = useState<Date>(null);

  const renderDayContents = (_day: number, date: Date) => {
    return (
      <span
        className={styles.day}
        onMouseMove={() => {
          if (
            (hoveredDate &&
              format(hoveredDate, "yyyy-MM-dd") !==
                format(date, "yyyy-MM-dd")) ||
            !hoveredDate
          ) {
            setHoveredDate(date);
          }
        }}
      >
        {getDate(date)}
      </span>
    );
  };

  const handleChange = (value: Date) => {
    inputRef.current?.focus();
    const newDate = value && format(value, "yyyy-MM-dd");
    setFieldValue(field.name, newDate);
    setFieldTouched(field.name);
    onChange && onChange(newDate);
    onBlur && onBlur(field.value);
    setIsCalendarOpened(false);
    setTimeout(() => {
      inputRef.current?.blur();
      setFieldTouched(field.name);
    }, 200);
  };

  useEffect(() => {
    setInitialValue(field.value);
    setDate(field.value !== null ? getParsedDate(String(field.value)) : null);
    setHoveredDate(getParsedDate(String(field.value)));
  }, [field.value, isCalendarOpened]);

  useEffect(() => {
    setDate(hoveredDate);
  }, [hoveredDate]);

  const handleChangeCalendarState = () => {
    if (!disabled) {
      setFieldTouched(field.name);
      setIsCalendarOpened(!isCalendarOpened);
    }
  };

  const handleClearValue = () => {
    if (field.value) {
      setFieldTouched(field.name);
      setFieldValue(field.name, "");
    }
  };

  const isTitleWithValue = Boolean(
    placeholderVisible || (!placeholderVisible && initialValue) || hoveredDate
  );

  const isError = meta.error && meta.touched && !withoutError;

  const placeholderForDateFormat = useRef({
    yyyy: "гггг",
    ["MM.yyyy"]: "мм.гггг",
    [dateFormats.date.placeholder]: dateFormats.date.placeholder
  });

  const formatForInputLisit = useRef({
    yyyy: "####",
    ["MM.yyyy"]: "##.####",
    [dateFormats.date.format]: dateFormats.date.format.replace(/[^.-/-]/g, "#")
  });

  const placeholder = placeholderVisible
    ? placeholderForDateFormat.current[
        dateFormat || dateFormats.date.placeholder
      ]
    : title;

  const handleClickOutSide = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target["dataset"].icon === "calendar") return;
    setIsCalendarOpened(false);
  };

  const handleClickOnInput = () => inputRef.current?.focus();
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    inputRef.current?.blur();
    onBlur && onBlur(event.target.value);
    setFieldTouched(field.name);
  };

  const handleOnFocus = () => setFieldTouched(field.name);

  const handleOnChangeRow = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const { value } = event.target;

    setFieldTouched(field.name);

    if (value) {
      const replaceValue = value.replace(/\W|_/g, "");
      const getIsValidDate = (format: string) =>
        isValid(parse(value, format, new Date()));

      const newDate = getIsValidDate(dateFormats.date.format)
        ? format(
            parse(value, dateFormats.date.format, new Date()),
            "yyyy-MM-dd"
          )
        : value;

      if (dateFormat === "yyyy") {
        setFieldValue(field.name, value);
      } else if (dateFormat === "MM.yyyy") {
        getIsValidDate(dateFormat)
          ? setFieldValue(field.name, `01.${value}`)
          : setFieldValue(field.name, value);
      } else {
        setFieldValue(field.name, newDate);
      }

      setInitialValue(newDate);

      if (
        replaceValue?.length === 8 ||
        (dateFormat === "yyyy" && replaceValue?.length === 4) ||
        (dateFormat === "MM.yyyy" && replaceValue?.length === 6)
      ) {
        if (getIsValidDate(dateFormat || dateFormats.date.format)) {
          inputRef.current?.blur();
          inputRef.current?.focus();
        }
      }
    } else {
      setFieldValue(field.name, null);
      setInitialValue(null);
    }
  };

  const getCustomHeader = (props: ReactDatePickerCustomHeaderProps) => (
    <CustomHeader dateFormat={dateFormat} {...props} />
  );

  return (
    <div
      className={classNames(
        styles.inputContainer,
        {
          [styles.requiredBlock]: required,
          [styles.invalid]: isError,
          [styles.disabled]: disabled,
          [styles.active]: isCalendarOpened,
          [styles.buttonMode]: isButton
        },
        [className?.container]
      )}
      id="DatePickerField_openCalendar"
      onClick={handleChangeCalendarState}
      data-icon="calendar"
    >
      <div
        className={classNames(styles.valueContainer, {
          [styles.requiredWithoutValue]: required && !isTitleWithValue
        })}
      >
        {isButton ? (
          ""
        ) : (
          <p
            className={classNames(styles.title, {
              [styles.titleWithValue]: isTitleWithValue,
              [styles.required]: required
            })}
          >
            {title}
          </p>
        )}
        <DatePicker
          onClickOutside={handleClickOutSide}
          renderDayContents={renderDayContents}
          onInputClick={handleClickOnInput}
          onBlur={handleOnBlur}
          onChangeRaw={handleOnChangeRow}
          onFocus={handleOnFocus}
          onSelect={handleChange}
          onChange={handleChange}
          popperClassName={classNames(
            "",
            {
              [`react-datepicker-popper_${placement}`]: placement
            },
            [className?.popper]
          )}
          open={isCalendarOpened}
          selected={date}
          dateFormat={
            dateFormat === "yyyy"
              ? "yyyy"
              : dateFormat === "MM.yyyy"
              ? withMonthLongName
                ? "MMM yyyy"
                : "MM.yyyy"
              : dateFormats.date.format
          }
          placeholderText={placeholder}
          showYearPicker={dateFormat === "yyyy"}
          showMonthYearPicker={dateFormat === "MM.yyyy"}
          calendarContainer={CustomCalendarContainer}
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : null}
          name={name}
          customInput={
            isButton ? (
              <InputButton value={date} onClick={handleChangeCalendarState} />
            ) : withMonthLongName ? (
              <input
                name={name}
                ref={inputRef}
                autoComplete="off"
                className={classNames("", {}, [className?.input])}
              />
            ) : (
              <NumberFormat
                name={name}
                mask="_"
                format={
                  formatForInputLisit.current[
                    dateFormat || dateFormats.date.format
                  ]
                }
                getInputRef={inputRef}
                autoComplete="off"
              />
            )
          }
          readOnly={disabled || withMonthLongName}
          shouldCloseOnSelect={false}
          locale={ru}
          renderCustomHeader={getCustomHeader}
          autoComplete="off"
        />
      </div>
      {!isButton && (
        <div className={styles.iconBtns}>
          <div
            className={classNames("", {
              [styles.iconCalendar_active]: isCalendarOpened
            })}
          >
            <StatusIcon icon="iconcalendar" color="bw-gray5" />
          </div>
          {withClearBtn && !disabled ? (
            <Button
              theme={ButtonTheme.ROUND}
              disabled={!field.value}
              onClick={handleClearValue}
            >
              <IconMinus
                id={`DatePicker_clearBtn_${field.name}`}
                className={classNames(styles.clearBtn, {
                  [styles.clearBtnDisabled]: !field.value
                })}
              />
            </Button>
          ) : null}
        </div>
      )}
      {isError ? <div className={styles.error}>{meta.error}</div> : null}
    </div>
  );
};

export default DatePickerField;

type InputButtonProps = {
  value: Date;
  ref: React.RefObject<HTMLButtonElement>;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const InputButton = forwardRef<HTMLButtonElement, InputButtonProps>(
  (props, ref) => (
    <button className={styles.inputButton} onClick={props.onClick} ref={ref}>
      {props.value ? `${props.value}` : ""}
    </button>
  )
);
InputButton.displayName = "ButtonDatePikerInput";

export type DatePickerAttrs = JSX.LibraryManagedAttributes<
  typeof DatePickerField,
  DatePickerFieldProps
>;
