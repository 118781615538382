import styles from "./buildingOneStaffTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";

const BuildingOneStaffTabs = () => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  const handleClick = (tabName: string) => () =>
    buildingOneStaffStore.groupCount[id]?.[tabName] &&
    buildingOneStaffStore.activeTab[id] !== tabName &&
    buildingOneStaffStore.setActiveTab(tabName, id);

  return Object.entries(buildingOneStaffStore.tabs).length ? (
    <>
      <div className={styles.tabsContainer}>
        {Object.entries(buildingOneStaffStore.tabs).map(([tabName, tab]) =>
          tab?.color ? (
            <div
              key={tabName}
              className={classNames(
                styles[
                  `color_${tab.color_level ? `${tab.color}_${1}` : tab.color}`
                ],
                {
                  [styles.tabBlock]: Boolean(
                    buildingOneStaffStore.groupCount[id]?.[tabName]
                  ),
                  [styles.tabBlock_empty]:
                    !buildingOneStaffStore.groupCount[id]?.[tabName],
                  [styles.tabBlock_selected]:
                    tabName === buildingOneStaffStore.activeTab[id]
                }
              )}
              onClick={handleClick(tabName)}
              id={`BuildingOneStaffTabs_changeTabs_${tabName}`}
            >
              <p>{tab.title?.toUpperCase()}</p>
              <div className={styles.tabBlock__count}>
                <p>{buildingOneStaffStore.groupCount[id]?.[tabName] || 0}</p>
              </div>
            </div>
          ) : null
        )}
      </div>
    </>
  ) : null;
};

export default observer(BuildingOneStaffTabs);
