/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "./buildingOneMap.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from "stores/index";
import iconMap from "shared/assets/images/mainIcons/iconMap.svg";

const BuildingOneMap = () => {
  const { buildingOneStore } = useStores();
  const [localMap, setLocalMap] = useState<any>();

  useEffect(() => {
    if (
      Object.values(buildingOneStore.selectedOne).length &&
      buildingOneStore.selectedOne.lantitude &&
      buildingOneStore.selectedOne.longitude
    ) {
      window.ymaps.ready(() => {
        const buildingMark = new window.ymaps.Placemark(
          [
            buildingOneStore.selectedOne.lantitude,
            buildingOneStore.selectedOne.longitude
          ],
          {},
          {
            iconLayout: "default#imageWithContent",
            iconImageHref: iconMap,
            iconImageSize: [50, 50]
          }
        );

        if (localMap) {
          localMap.geoObjects.removeAll();
          localMap.setCenter(
            [
              buildingOneStore.selectedOne.lantitude,
              buildingOneStore.selectedOne.longitude
            ],
            10
          );
          localMap.geoObjects.add(buildingMark);
        } else {
          // нужно исправить ошибку в консоли в этом месте
          const map = new window.ymaps.Map("map", {
            center: [
              buildingOneStore.selectedOne.lantitude,
              buildingOneStore.selectedOne.longitude
            ],
            zoom: 10
          });

          map.geoObjects.add(buildingMark);
          setLocalMap(map);
        }
      });
    }
  }, [buildingOneStore.selectedOne]);

  return <div className={styles.map} id="map" />;
};

export default observer(BuildingOneMap);
