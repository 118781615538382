import styles from "./staffOneWorkshiftTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";

import { Table } from "react-bootstrap";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpandStroke.svg";
import { without } from "lodash";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import Alert from "shared/ui/Alert";

type StaffOneWorkshiftProps = {
  activeTab: string;
};

const workshift_cols = {
  position: {
    title: "Должность"
  },
  start_date: {
    title: "Дата начала"
  },
  subdivision: {
    title: "Подразделение"
  },
  company: {
    title: "Компания"
  },
  staff_code: {
    title: "Стафф-код"
  },
  workshifts: {
    title: "Смены"
  }
};

const StaffOneWorkshift = ({ activeTab }: StaffOneWorkshiftProps) => {
  const { staffOneAllSectionsStore } = useStores();

  const [openedRow, setOpenedRow] = useState<string[]>([]);
  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      staffOneAllSectionsStore.workshift?.[activeTab] &&
        setOpenedRow(
          Object.keys(staffOneAllSectionsStore.workshift[activeTab])
        );
    }, 50);
  }, [activeTab, id]);

  return staffOneAllSectionsStore.workshift?.[activeTab] ? (
    <Table>
      <thead className={styles.thead}>
        <tr>
          {Object.entries(workshift_cols).map(([name, col]) => {
            return (
              <th key={name}>
                <div>
                  {name === "position" ? (
                    <IconExpand
                      className={`${styles.iconExpand} ${
                        openedRow.length === 0 ? styles.iconExpand_closed : ""
                      }`}
                      id={`StaffOneWorkshiftTable_openedAllRowsBtn_${name}`}
                      onClick={() => {
                        setOpenedRow(
                          openedRow.length
                            ? []
                            : Object.keys(
                                staffOneAllSectionsStore.workshift[activeTab]
                              )
                        );
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {col.title}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {(() => {
          return Object.entries(
            staffOneAllSectionsStore.workshift[activeTab]
          ).map(([workshiftGroup_id, workshiftsList]) => {
            return Object.entries(workshiftsList).map(
              ([workshift_id, workshift], i) => {
                if (i !== 0 && !openedRow.includes(workshiftGroup_id)) {
                  return;
                }

                return (
                  <tr key={workshift_id}>
                    {Object.keys(workshift_cols).map((col) => {
                      let workshiftsSum = 0;

                      if (col === "workshifts") {
                        !openedRow.includes(workshiftGroup_id) &&
                          Object.values(
                            staffOneAllSectionsStore.workshift[activeTab][
                              workshiftGroup_id
                            ]
                          ).forEach(({ workshifts }) => {
                            workshiftsSum += workshifts;
                          });
                      }

                      return (
                        <td
                          key={`${workshift.id}_${col}`}
                          className={
                            col === "position" && i !== 0 ? styles.position : ""
                          }
                        >
                          {col === "position" && i === 0 ? (
                            <div>
                              {i === 0 &&
                              Object.entries(workshiftsList).length > 1 ? (
                                <IconExpand
                                  className={`${styles.position__icon} ${
                                    !openedRow.includes(workshiftGroup_id)
                                      ? styles.position__icon_closed
                                      : ""
                                  }`}
                                  id={`StaffOneWorkshiftTable_openedRowBtn_${workshift.id}`}
                                  onClick={() => {
                                    setTimeout(() => {
                                      setOpenedRow(
                                        openedRow.includes(workshiftGroup_id)
                                          ? without(openedRow, workshift.id)
                                          : [workshift.id, ...openedRow]
                                      );
                                    }, 1000);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {workshift[
                                col === "position" ? "position_title" : col
                              ] || "Должность не указана"}
                            </div>
                          ) : col !== "position" ? (
                            <div>
                              {col === "start_date"
                                ? getFormattedDate(workshift[col])
                                : col === "workshifts"
                                ? openedRow.includes(workshiftGroup_id)
                                  ? workshift[col]
                                  : workshiftsSum
                                : col === "company"
                                ? openedRow.includes(workshiftGroup_id)
                                  ? staffOneAllSectionsStore.companiesData[
                                      workshift[col]
                                    ]?.title
                                  : ""
                                : openedRow.includes(workshiftGroup_id)
                                ? workshift[col]
                                : ""}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            );
          });
        })()}
      </tbody>
    </Table>
  ) : (
    <Alert
      errors={{
        head: `Не найдена история должностей в компании 
      ${staffOneAllSectionsStore.companiesData[activeTab]?.title || ""}`,
        color: "empty"
      }}
    />
  );
};

export default observer(StaffOneWorkshift);
