import styles from "./aregisterOneMasscopyFormSendBtn.module.scss";

import { ReactComponent as IconSend } from "shared/assets/images/mainIcons/iconsSend/iconSend.svg";

type AregisterOneMasscopyFormSendBtn = {
  name: string;
  onClick: () => void;
  tableType: string;
};

const AregisterOneMasscopyFormSendBtn = ({
  name,
  onClick,
  tableType
}: AregisterOneMasscopyFormSendBtn) => {
  return (
    <div
      className={`${styles.iconSend} ${
        tableType === "column" ? styles.iconSend_column : ""
      }`}
      data-type="iconSend"
    >
      <IconSend id={name} onClick={onClick} />
    </div>
  );
};

export default AregisterOneMasscopyFormSendBtn;
