import { BuildingSelects } from "stores/BuildingModule/types/Selects";
import { numberSchema, stringSchema } from "shared/utils/validation/validation";
import { object, array, number } from "yup";

export const getValidationSchema = (
  objectstatus: BuildingSelects["objectstatus"]
) => {
  const validationSchema = object({
    customer: stringSchema,
    group: stringSchema,
    comment: stringSchema,
    payment_type: stringSchema.test(
      "payment_type",
      "Поле обязательно",
      function (value) {
        if (!this.parent.isMassEdit) {
          if (!value) {
            return false;
          }
        }
        return true;
      }
    ),
    objectstatus: stringSchema,
    work_hours: number()
      .typeError("Только число")
      .max(24, `Не более ${24} часов`)
      .nullable()
      .test("work_hours", "Часы обязательны", function (value) {
        if (
          (!value || value <= 0) && this.parent.objectstatus
            ? objectstatus?.[`${this.parent.objectstatus}`?.toUpperCase()]
                ?.custom?.check_hours === "on"
            : null
        ) {
          return false;
        } else return true;
      }),
    night: numberSchema,
    study: numberSchema
  });

  return array().of(validationSchema);
};
