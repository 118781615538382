import styles from "./loaderSpinner.module.scss";
import StatusIcon from "../StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";

type LoaderSpinner = {
  color: string;
  size?: "small" | "large";
};

const LoaderSpinner = ({ color, size = "large" }: LoaderSpinner) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames("", {
          [styles.small]: size === "small",
          [styles.large]: size === "large"
        })}
      >
        <StatusIcon icon="iconloaderspinner" color={color} />
      </div>
    </div>
  );
};
export default LoaderSpinner;
