type StatusDict = {
  [key: string]: string;
};

export const settings: StatusDict = {
  active: "Сотрудник активный",
  ending:
    "Отсутствует\nСотрудник покинул объект. Выставляется автоматически после трансфера.",
  price: "Ставка",
  choice: "Можно выбрать",
  date_end: "Есть дата окончания",
  date_end_required: "Дата окончания обязательна",
  sub_date: "Дополнительная дата",
  nsalary:
    "Финансовый отчет\nПри выборе будет показываться финансовый отчет с возможностью добавления финансовых операций.",
  close_withholding:
    "Закрывать удержания\nПри выборе удержания будет проводится финансовые операции удержаний",
  open_withholding:
    "Восстанавливать удержания\nПри выборе удержания будет проводится обратные финансовые операции удержаний",
  showtabel:
    "Отображать в табеле\nКогда сотрудника нет ни в одной смене месяца, отображать если у него установлен статус"
};

export const additional: StatusDict = {
  position: "Должность",
  over_price: "Надбавка по Ч-Ч",
  over_price_aregister: "Надбавка по заявке"
};

export const category: StatusDict = {
  none: "Не указано",
  vacation: "Отпуск"
};

export const staffFields: StatusDict = {
  transferbuilding: "Трансфер на другой объект",
  returndate: "Есть дата возврата"
};

export const type: StatusDict = {
  set_default: "Тип по умолчанию",
  mark_delete: "Пометить тип на удаление"
};
