import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Notice from "shared/assets/images/iconStatus/Notice.svg";
import styles from "./conditionalCubes.module.scss";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";
import { TextFieldGroup } from "shared/ui/TextFieldGroup";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";

const ConditionalCubes = () => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();

  const cols = aregisterOneStore.cubes[id]?.translate;

  return !isEmpty(aregisterOneStore.cubes[id]) ? (
    <div className={styles.main}>
      <p className={styles.mainTitle}>
        {aregisterOneStore.cols.conditional_cubes.title}
      </p>
      {getEntries(aregisterOneStore.cubes[id]).map(([field, value]) => {
        if (field !== "portions" && field !== "translate")
          return (
            <TextFieldGroup
              key={field}
              name={field}
              fields={[field]}
              titles={getKeys(cols)}
              cols={cols}
              getValue={() =>
                typeof value === "number" ? numberWithSpaces(value) : value
              }
              className={{ field: styles.mainField }}
            />
          );
      })}
      {"portions" in aregisterOneStore.cubes[id] ? (
        <>
          <p className={styles.rangesTitle}>Диапазоны</p>
          {getValues(aregisterOneStore.cubes[id].portions).map((portion, i) => (
            <div
              key={portion.range_conditional_cubes}
              className={styles.ranges}
            >
              <p className={styles.rangesCount}>{i + 1}</p>
              <div className={styles.rangesFields}>
                {getEntries(portion).map(([field, value]) => (
                  <TextFieldGroup
                    key={`${portion.range_conditional_cubes}_${field}`}
                    name={field}
                    fields={[field]}
                    titles={getKeys(cols)}
                    cols={cols}
                    getValue={() => (value ? numberWithSpaces(value) : null)}
                    className={{ field: styles.field }}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  ) : (
    <div>
      <div className={styles.main}>
        <p className={styles.mainTitle}>Приведенный объем</p>
      </div>
      <p className={styles.noCubes}>
        <img src={Notice} />
        Приведенный объем не вычислен
      </p>
    </div>
  );
};

export default observer(ConditionalCubes);
