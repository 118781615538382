import styles from "./buildingOneTimesheetInfoHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useNavigate, useParams } from "react-router-dom";

import iconSum from "shared/assets/images/mainIcons/iconSum.svg";

const BuildingOneTimesheetInfoHeader = () => {
  const {
    buildingOneTimesheetStore,
    buildingOneAddWorkshiftFormStore,
    menuStore
  } = useStores();
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToOneWorkshift = (id: string) => {
    navigate(`../../workshift/id=${id}/staff`);
  };

  return (
    <thead className={menuStore.isScroll ? styles.shadow : ""}>
      <tr>
        <th className={styles.tn}>ТН</th>
        <th className={styles.fio}>ФИО</th>
        <>
          {buildingOneTimesheetStore.daysInMonth[
            buildingOneTimesheetStore.year[id]
          ]?.[buildingOneTimesheetStore.month[id]]?.length
            ? buildingOneTimesheetStore.daysInMonth[
                buildingOneTimesheetStore.year[id]
              ][buildingOneTimesheetStore.month[id]].map((day) => {
                const workshiftDate = `${buildingOneTimesheetStore.year[id]}-${
                  buildingOneTimesheetStore.month[id] < 10 ? 0 : ""
                }${buildingOneTimesheetStore.month[id]}-${
                  day < 10 ? 0 : ""
                }${day}`;
                const workshift =
                  buildingOneTimesheetStore.allOpenedTabels[id]?.ws_list?.[
                    workshiftDate
                  ];

                return (
                  <th
                    key={day}
                    id={`BuildingOneTimesheetInfoHeader_selectDay_${day}`}
                    className={`${styles.day} ${
                      buildingOneTimesheetStore.openedWindow === workshiftDate
                        ? styles.day_selected
                        : ""
                    }`}
                    onClick={() => {
                      workshift
                        ? navigateToOneWorkshift(workshift.id)
                        : buildingOneTimesheetStore.setOpenedWindow(
                            workshiftDate
                          );
                    }}
                    onBlur={() => {
                      buildingOneTimesheetStore.setOpenedWindow("");
                    }}
                    tabIndex={0}
                  >
                    <div
                      className={`${
                        styles[
                          `day__value_${
                            buildingOneTimesheetStore.selects?.workshift_status[
                              workshift?.status
                            ]?.custom.color || "default"
                          }`
                        ]
                      } ${
                        styles[
                          `day__value_text_${
                            !workshift?.["status"] ? "bw-gray7" : "blue-lazure"
                          }`
                        ]
                      }`}
                    >
                      <p>{day}</p>
                    </div>
                    {buildingOneTimesheetStore.openedWindow ===
                    workshiftDate ? (
                      <div className={`${styles.list} ${styles.list_small}`}>
                        <p
                          onClick={() => {
                            buildingOneTimesheetStore.setSelectedDate(
                              workshiftDate
                            );
                            setTimeout(() => {
                              buildingOneAddWorkshiftFormStore.setOpenedWindow(
                                "timesheet"
                              );
                            }, 200);
                          }}
                          id="BuildingOneTimesheetInfoHeader_addWorkshift"
                        >
                          Добавить смену
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </th>
                );
              })
            : null}
        </>
        <th className={styles.hours}>
          <img src={iconSum} />
          Часы
        </th>
      </tr>
    </thead>
  );
};

export default observer(BuildingOneTimesheetInfoHeader);
