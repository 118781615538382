import styles from "./staffOneSalaryLastOperations.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";
import { fileUrl } from "stores/utils/consts";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import LoadedComponent from "widgets/LoadedComponent";
import { useEffect, useState } from "react";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import StaffOneSalaryFieldsRecordsInfo from "../RecordsInfo";
import Alert from "shared/ui/Alert";

const StaffOneSalaryLastOperations = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();
  const [tooltipHideTrigger, setTooltipHideTrigger] = useState(false);

  useEffect(() => {
    !staffOneSalaryStore.lastOperationList[id]?.length &&
      staffOneSalaryStore.getLastOperations(id);
  }, [id]);

  useEffect(() => {
    tooltipHideTrigger && setTooltipHideTrigger(false);
  }, [tooltipHideTrigger]);

  // массив типов операций, которые присутствуют в таблице "Последние операции"
  const availableTypeForUid =
    staffOneSalaryStore.lastOperationList[id]?.length &&
    staffOneSalaryStore.lastOperationList[id].map(
      (operation) => operation["type"]
    );

  const handleSetActiveOperationType = (value: string) =>
    staffOneSalaryStore.setSelectedTypeFromOperations(id, value);

  const lastOperationsList = Object.values(
    staffOneSalaryStore.lastOperationList[id] || {}
  ).filter((operation) =>
    staffOneSalaryStore.selectedTypeFromOperations[id] !== "all"
      ? staffOneSalaryStore.selectedTypeFromOperations[id] === operation.type
      : operation
  );

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingForOperations[id]}
      withoutText
    >
      <>
        {staffOneSalaryStore.lastOperationList[id]?.length &&
        Object.values(staffOneSalaryStore.lastOperationCols)?.length &&
        staffOneSalaryStore.currentTitlesForOperations.length ? (
          <>
            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                <ButtonsTabs
                  selectedTab={
                    staffOneSalaryStore.selectedTypeFromOperations[id]
                  }
                  tabs={[
                    {
                      title: "Все операции",
                      id: "all"
                    },
                    ...Object.values(
                      staffOneSalaryStore.operationTypesSelects
                    ).filter((type) => availableTypeForUid.includes(type["id"]))
                  ]}
                  changeActiveTab={handleSetActiveOperationType}
                  title="Тип операции"
                />
              </div>
              <StaffOneSalaryFieldsRecordsInfo
                name="lastOperationList"
                lastOperationsList={lastOperationsList}
              />
            </div>
            <Table>
              <thead className={styles.thead}>
                <tr>
                  {staffOneSalaryStore.currentTitlesForOperations.map(
                    (title) => {
                      return (
                        <th key={title} className={styles.title}>
                          <div className="d-flex align-items-center">
                            <p className="m-0">
                              {
                                staffOneSalaryStore.lastOperationCols[title]?.[
                                  "title"
                                ]
                              }
                            </p>
                          </div>
                        </th>
                      );
                    }
                  )}
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {lastOperationsList.map((operation, ind) => {
                  return (
                    <tr
                      key={operation.id as string}
                      className={classNames("", {
                        [styles.greyRow]: !(ind % 2)
                      })}
                    >
                      {staffOneSalaryStore.currentTitlesForOperations.map(
                        (title) => {
                          switch (title) {
                            case "author":
                              return (
                                <td key={title}>
                                  <p>{operation[title]["title"]}</p>
                                </td>
                              );
                            case "type":
                              return (
                                <td key={title}>
                                  <p
                                    className={
                                      styles[
                                        `type_${
                                          staffOneSalaryStore
                                            .operationTypesSelects[
                                            operation[title]
                                          ]["custom"]?.["color"]
                                        }`
                                      ]
                                    }
                                  >
                                    {
                                      staffOneSalaryStore.operationTypesSelects[
                                        operation[title]
                                      ]["title"]
                                    }
                                  </p>
                                </td>
                              );
                            case "time_spending":
                              return (
                                <td key={title}>
                                  <p>{getFormattedDate(operation[title])}</p>
                                </td>
                              );
                            case "money":
                              return (
                                <td key={title}>
                                  <p>{numberWithSpaces(operation[title])}</p>
                                </td>
                              );
                            case "object":
                              return (
                                <td key={title}>
                                  <div className={styles.tdBuilding}>
                                    <div className={styles.objLink}>
                                      <a
                                        className={styles.link}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`${fileUrl}/crm/building/?id=${operation[title]["id"]}`}
                                      >
                                        <p>{operation[title]["title"]}</p>
                                      </a>
                                      {operation["paymentlist"] ? (
                                        <a
                                          rel="noreferrer"
                                          target="_blank"
                                          href={`${fileUrl}/crm/salary/?ps_id=${operation["paymentlist"]}`}
                                          className={styles.tooltip}
                                          data-tooltip="Перейти к Ведомости"
                                        >
                                          <div className={styles.connect}>
                                            <IconFinance />
                                          </div>
                                        </a>
                                      ) : null}
                                    </div>
                                    <div className={styles.btnLink}>
                                      <a
                                        className={styles.link}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`${fileUrl}/crm/salary/?sd=${operation.id}`}
                                      >
                                        Перейти
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              );
                            default:
                              return (
                                <td key={title}>
                                  <p>{operation[title]}</p>
                                </td>
                              );
                          }
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <Alert
            errors={{
              head: "Нет информации по последним операциям",
              color: "empty"
            }}
          />
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryLastOperations);
