import styles from "./premiumListTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { getValues } from "shared/utils/helpers/getValues";
import StaffOneSalaryFieldsRecordsInfo from "../../RecordsInfo";
import { PremiumType } from "stores/StaffModule/staffOne/staffOneSalary";

type PremiumListTabsProps = {
  premiumList: PremiumType[];
};

const PremiumListTabs = ({ premiumList }: PremiumListTabsProps) => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  //Массив компаний, которые присутствуют в таблице "Премии"
  const availableCompanyForUid = Object.values(
    staffOneSalaryStore.premium[id] || {}
  ).map((premium) => premium["object"]["company_id"]);
  const handleSetActiveStatus = (value: string) =>
    staffOneSalaryStore.setSelectedStatusForPremium(id, value);
  const handleSetActiveCompany = (value: string) =>
    staffOneSalaryStore.setSelectedCompanyForPremium(id, value);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        <ButtonsTabs
          selectedTab={staffOneSalaryStore.selectedStatusForPremium[id]}
          tabs={[
            {
              title: "Все статусы",
              id: "all"
            },
            ...getValues(staffOneSalaryStore.premiumStatusSelects)
          ]}
          changeActiveTab={handleSetActiveStatus}
          title="Статус"
        />
      </div>
      <div className={styles.tabs}>
        <ButtonsTabs
          selectedTab={staffOneSalaryStore.selectedCompanyForPremium[id]}
          tabs={[
            {
              title: "Все компании",
              id: "all"
            },
            ...getValues(staffOneSalaryStore.companiesDict).filter((company) =>
              availableCompanyForUid.includes(company["id"])
            )
          ]}
          changeActiveTab={handleSetActiveCompany}
          title="Компании"
        />
      </div>
      <StaffOneSalaryFieldsRecordsInfo
        name="premium"
        premiumList={premiumList}
      />
    </div>
  );
};

export default observer(PremiumListTabs);
