import styles from "./staffOneStatusesTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonTheme } from "shared/ui/Button";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";

type StaffOneStatusesTabsProps = {
  changeSelectedStatus: (arg: string) => void;
};

const StaffOneStatusesTabs = ({
  changeSelectedStatus
}: StaffOneStatusesTabsProps) => {
  const { staffOneStatusesStore } = useStores();

  return (
    <div className={styles.tabs}>
      {!isEmpty(staffOneStatusesStore.statusesParams)
        ? getEntries(staffOneStatusesStore.statusesParams).map(
            ([key, status]) => {
              const handleSetSelectedStatus = () => changeSelectedStatus(key);

              if (
                !staffOneStatusesStore.statusesParams[
                  staffOneStatusesStore.lastStatus["type"]
                ]?.custom?.ignore_transit_cols_bool
                  ? status.custom.choice &&
                    staffOneStatusesStore.showStatuses.includes(key)
                  : true
              ) {
                return (
                  <Button
                    key={key}
                    theme={ButtonTheme.TAB}
                    selected={staffOneStatusesStore.selectedStatus === key}
                    onClick={handleSetSelectedStatus}
                  >
                    <div className={styles.button}>
                      <StatusIcon
                        icon={status.custom?.icon}
                        color={
                          staffOneStatusesStore.selectedStatus !== status.id
                            ? status.custom.color_api
                            : "default"
                        }
                      />
                      {status.title}
                    </div>
                  </Button>
                );
              }
            }
          )
        : ""}
    </div>
  );
};
export default observer(StaffOneStatusesTabs);
