import styles from "./dragAndDropSelect.module.scss";
import { observer } from "mobx-react-lite";
import { useField, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";

import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

type DragAndDropSelectProps = {
  name: string;
  options: { [key: string]: OptionWithTitle };
  isMenuOpened: boolean;
  setIsMenuOpened: (isMenuOpened: boolean) => void;
  subOptions?: {
    id: string;
    title: string;
  }[];
  setSubOptions?: (
    subOptions: {
      id: string;
      title: string;
    }[]
  ) => void;
  notSearchable?: boolean;
  required?: boolean;
  valueName?: string;
  onClickSelectedOption?: (arg: string) => void;
  onClickSelectedSubOption?: (arg: string) => void;
  onClick?: (option: OptionWithTitle) => void;
};

const DragAndDropSelect = ({
  name,
  options,
  isMenuOpened,
  setIsMenuOpened,
  subOptions,
  setSubOptions,

  notSearchable,
  required,
  valueName,
  onClickSelectedOption,
  onClickSelectedSubOption,
  onClick
}: DragAndDropSelectProps) => {
  const { aregisterReportStore } = useStores();
  const [listOptions, setListOptions] = useState<OptionWithTitle[]>([]);
  const { setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField({
    name,
    required
  });

  const buttonRef = useRef<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>();
  const valueField = valueName || "newname";
  const values: string[] = field.value;

  useEffect(() => {
    updateListOptions();
    buttonRef.current.focus();
  }, [meta.value]);

  useOnClickOutside({
    ref,
    handler: () => {
      setTimeout(() => {
        isMenuOpened && setIsMenuOpened(false);
      }, 200);
    }
  });

  const MainListsElement = ({ item }: { item: string }) => {
    return (
      <div className={styles.itemBorder}>
        <div
          key={item}
          className={classNames(styles.item, {
            [styles.item_selected]:
              item === aregisterReportStore.selectedOptions.masterId
          })}
          onClick={() => {
            onClickSelectedOption(item);
            aregisterReportStore.setIsShowSubValues(true);
            isMenuOpened && setIsMenuOpened(false);
          }}
          id={`DragAndDropSelect_chooseOption_${item}`}
        >
          <div className={styles.item_title}>{getTitleFromOption(item)}</div>
        </div>
      </div>
    );
  };

  const SubListsElement = ({
    subItem
  }: {
    subItem: { id: string; title: string };
  }) => {
    return (
      <div
        key={subItem.id}
        className={classNames(styles.subItem, {
          [styles.subItem__selected]:
            subItem.id === aregisterReportStore.selectedOptions.brigadierId
        })}
        onClick={() => onClickSelectedSubOption(subItem.id)}
        id={`DragAndDropSelect_chooseOption_${subItem.id}`}
      >
        <p>{subItem.title}</p>
      </div>
    );
  };

  const handleDragEndMain = (sourceIndex: number, destinationIndex: number) => {
    const items = [...values];
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    setValue(items);
  };

  const handleDragEndSub = (sourceIndex: number, destinationIndex: number) => {
    const items = [...subOptions];
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    setSubOptions(items);
  };

  const getTitleFromOption = (value: string) => {
    let title = "";
    if (options[value]) {
      title = options[value]["title"];
    } else {
      Object.values(options).forEach((option) => {
        if (option[valueField] === value) {
          title = option["title"];
        }
      });
    }
    return title;
  };

  // обновляем выпадающий список при клике на опцию она удаляется из списка
  const updateListOptions = () => {
    if (listOptions.length) {
      const idx = listOptions.findIndex((option) =>
        values.includes(option[valueField] as string)
      );
      if (idx !== -1) {
        const array = [...listOptions];
        array.splice(idx, 1);
        setListOptions(array);
      }
    } else {
      const modifiedOptions: OptionWithTitle[] = [];
      Object.entries(options).forEach(([key, option]) => {
        if (!values.includes(key)) {
          modifiedOptions.push(option);
        }
      });
      setListOptions(modifiedOptions);
    }
  };
  return (
    <>
      <div className={styles.field}>
        <div className={styles.field__items}>
          <div className={styles.iconBorder}>
            <IconAdd
              className={classNames("", {
                [styles.iconClose]: isMenuOpened,
                [styles.iconAdd]: !isMenuOpened,
                [styles.iconDisabled]: !listOptions.length
              })}
              onClick={() => {
                setIsMenuOpened(!isMenuOpened);
                if (required) {
                  setFieldTouched(field.name);
                }
              }}
              id={`DragAndDropSelect_showMenu_${name}`}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === "Escape" && isMenuOpened) {
                  setIsMenuOpened(false);
                }
              }}
              tabIndex={-1}
              ref={buttonRef}
            />
          </div>
          <ListManager
            items={values}
            direction="horizontal"
            maxItems={6}
            render={(item: string) => <MainListsElement item={item} />}
            onDragEnd={handleDragEndMain}
          />
        </div>
        {isMenuOpened ? (
          <div className={styles.scrollboard} ref={ref}>
            <ItemsScrollBoard
              options={options}
              addItem={(item: OptionWithTitle) => {
                if (values.length) {
                  setValue([...values, item[valueField]]);
                } else {
                  setValue([item[valueField]]);
                }
                onClick && onClick(item);
              }}
              values={values}
              searchPlaceholder="Поиск по мастерам"
              notSearchable={notSearchable}
              valueName={valueField}
            />
          </div>
        ) : null}
      </div>
      {aregisterReportStore.isShowSubValues &&
      Object.values(subOptions).length ? (
        <div className={styles.field}>
          <p className={styles.title}>Бригадиры</p>
          <div className={styles.field__subItems}>
            <button
              type="button"
              className={classNames(styles.subItem, {
                [styles.subItem__selected]:
                  aregisterReportStore.selectedOptions.brigadierId === ""
              })}
              onClick={() => {
                if (aregisterReportStore.selectedOptions.brigadierId !== "") {
                  onClickSelectedSubOption("");
                  aregisterReportStore.setSelectedOptions({ brigadierId: "" });
                }
              }}
              id={`DragAndDropSelect_chooseAll_${name}`}
            >
              <p>Все</p>
            </button>
            <ListManager
              items={subOptions}
              direction="horizontal"
              maxItems={5}
              render={(item: { id: string; title: string }) => (
                <SubListsElement subItem={item} />
              )}
              onDragEnd={handleDragEndSub}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default observer(DragAndDropSelect);
