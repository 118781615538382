import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { without } from "lodash";
import { baseUrl, access_key } from "stores/utils/consts";
import { ApiResponse } from "./utils/types/ApiResponse";

type ApiProps = {
  baseClass: string;
  currentClass?: string;
  method?: string;
  requestMethod?: "GET" | "POST";

  where?: {
    [key: string]: {
      value: string | number | string[] | number[] | { [key: string]: number };
      comparison?: string;
      logic?: string;
    };
  };
  order?: { order: string; ordered: string };
  select?: string[];

  on_page?: number;
  page?: number;

  params?: { [key: string]: string | number | string[] | number[] };

  body?: { [key: string]: string | number | File | string[] };
};

export default class ApiStore {
  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  // код и текст ошибки
  errorCodeMessage: Partial<{ code: number; message: string }> = {};
  // массив страниц с ошибкой
  errorPage: string[] = [];
  // справочник с url для каждого типа запроса
  readWriteUrl: Record<"GET" | "POST", string> = { GET: "", POST: "" };

  // функция проверки ответа
  getResponse = (res: Response) => {
    // Если ok === true, возвращаем  Promise
    if (res.ok) {
      return res.json();
    }
    // Если false, нужно отобразить ошибку с кодом и дефолтным текстом
    // Таким образом обрабатываются 500-е ошибки методов
    else {
      this.errorCodeMessage = {
        code: res.status,
        message: "Внутренняя ошибка сервера"
      };
      // адрес страницы в формате "id страницы_модуль_подмодуль" для которой нужно сохранить ошибку
      const page = `${this.rootStore.menuStore.tabId || "main"}_${
        this.rootStore.menuStore.openedModule || "main"
      }_${this.rootStore.menuStore.openedSubmodule || null}`;

      if (!this.errorPage.includes(page)) {
        this.errorPage.push(page);
      }
      throw new Error("Что-то пошло не так...");
    }
  };

  // функция получения текста ошибки
  getErrorMessage = (
    // ответ с сервера
    response: { message: string | { head: string } },
    // текст ошибки, который нужно отобразить, если в response нет текста
    defaultErrorMessage: string
  ) => {
    // проверяем является ли message строкой
    return typeof response.message === "string"
      ? // если да, то возвращаем message
        response.message
      : // если нет -- проверяем наличие head в message
      "head" in response.message
      ? // если есть -- отображаем head
        response.message.head
      : // если нет - дефолтный текст
        defaultErrorMessage;
  };

  getReadWriteUrl = async () => {
    try {
      const data: ApiResponse<Record<"read" | "write", string>> =
        await this.getData({
          requestMethod: "GET",
          baseClass: "core",
          method: "getReadWriteUrl"
        });

      runInAction(() => {
        if (data["code"] == 200 && Object.values(data["result"]).length) {
          this.readWriteUrl["GET"] = data["result"]["read"];
          this.readWriteUrl["POST"] = data["result"]["write"];
        }
      });
    } catch (error) {
      return error;
    }
  };

  getData = async ({
    baseClass,
    currentClass,
    method,
    requestMethod,

    where,
    order,
    select,

    on_page,
    page,

    params,

    body
  }: ApiProps) => {
    const url = this.readWriteUrl[requestMethod]
      ? `https://${this.readWriteUrl[requestMethod]}/api/`
      : baseUrl;
    let requestUrl = `${url}${baseClass}${
      currentClass ? "/" + currentClass : ""
    }/${method}`;
    let isParamed = false;

    const checkIsParamed = (string: string) => {
      if (isParamed) {
        requestUrl += `&${string}`;
      } else {
        isParamed = true;
        requestUrl += `?${string}`;
      }
    };

    for (const param in where) {
      const obj = where[param];

      const isInRangeValues: boolean =
        obj["value"]["start"] && obj["value"]["end"];
      const isArrayValues: boolean = Array.isArray(obj["value"]);

      if (isInRangeValues) {
        checkIsParamed(
          `filter[where][${param}][value][start]=${obj["value"]["start"]}`
        );

        requestUrl += `&filter[where][${param}][value][end]=${obj["value"]["end"]}`;
      }

      if (Array.isArray(obj["value"])) {
        const values = obj["value"];

        for (const val of values) {
          checkIsParamed(`filter[where][${param}][value][]=${val}`);
        }
      }

      if (!isArrayValues && !isInRangeValues) {
        checkIsParamed(`filter[where][${param}][value]=${obj["value"]}`);
      }

      if (obj["comparison"]) {
        checkIsParamed(
          `filter[where][${param}][comparison]=${obj["comparison"]}`
        );
      }

      if (obj["logic"]) {
        checkIsParamed(`filter[where][${param}][logic]=${obj["logic"]}`);
      }
    }

    if (order) {
      if (order.ordered) {
        checkIsParamed(`filter[order][]=${`${order.order}`} ${order.ordered}`);
      } else checkIsParamed(`filter[order][]=${order.order}`);
    }

    if (select) {
      checkIsParamed(`filter[select][]=${select}`);
    }

    if (on_page) {
      checkIsParamed(`on_page=${on_page}`);
    }

    if (page) {
      checkIsParamed(`page=${page}`);
    }

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length) {
            value.forEach((item: string | number) => {
              checkIsParamed(`${key}[]=${item}`);
            });
          }
        } else checkIsParamed(`${key}=${value}`);
      });
    }

    const bodyCredentials = new FormData();
    if (body) {
      const data = Object.entries(body);

      for (const item of data) {
        bodyCredentials.append(item[0], item[1] as string);
      }
    }

    try {
      const headers: {
        method: string;
        headers: { "Access-Key": string };
        body?: FormData;
      } = {
        method: requestMethod,
        headers: {
          "Access-Key": access_key
        }
      };

      if (body) headers.body = bodyCredentials;

      const request = await fetch(requestUrl, headers);
      const response = await this.getResponse(request);

      if (response.code === 423) {
        // удаляем сохраненный ключ
        this.rootStore.userStore.cleanAccessKey();
        // и перенаправляем пользователя на страницу входа с переадресацией обратно на текущую страницу
        window.location.href = `${process.env.REACT_APP_API_URL}/?referrer=${window.location.href}`;
      }
      // Если вернулась ошибка доступа 403 или метод на бэке не найден 404 в коде
      // Нужно обработать ошибку
      else if (response.code === 403 || response.code === 404) {
        // адрес страницы в формате "id страницы_модуль_подмодуль" для которой нужно сохранить ошибку
        const page = `${this.rootStore.menuStore.tabId || "main"}_${
          this.rootStore.menuStore.openedModule || "main"
        }_${this.rootStore.menuStore.openedSubmodule || null}`;

        // Записываем ошибку и страницу с ошибкой
        runInAction(() => {
          this.errorCodeMessage = {
            code: response.code,
            message: this.getErrorMessage(
              response,
              response.code === 403 ? "Ошибка доступа" : "Страница не найдена"
            )
          };
          if (!this.errorPage.includes(page)) {
            this.errorPage.push(page);
          }
        });
        throw new Error("Что-то пошло не так...");
      } else {
        // Если код не 403, 404 и не любая 500-я ошибка
        // Возвращаем ответ
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  // метод удаления ошибки вкладки
  deleteErrorPage = (page: string) => {
    // если передано page: all - удаляем все ошибки
    if (page === "all") {
      this.errorPage = [];
    }
    // если нет -- проверяем есть ли ошибка на данной странице
    if (this.errorPage.includes(page)) {
      // и удаляем ее из массива
      this.errorPage = without(this.errorPage, page);
    }
  };
}
