import styles from "./aregisterMasscopyFormFormula.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import { Input } from "shared/ui/Inputs/Input";
import Switch from "shared/ui/Inputs/Switch";

import { ReactComponent as IconDash } from "shared/assets/images/iconStatus/Dash.svg";

type AregisterMasscopyFormFormulaProps = {
  values: { [key: string]: string | number };
  type: string;
  numeric?: number;
  onClick?: (name: string) => void;
  onKeyDown?: (name: string, key: string) => void;
};

const AregisterMasscopyFormFormula = ({
  values,
  type,
  numeric,
  onClick,
  onKeyDown
}: AregisterMasscopyFormFormulaProps) => {
  const { id } = useParams();
  const { aregisterAppcreateStore, aregisterOneMasscopyStore } = useStores();
  const [formulaText, setFormulaText] = useState("");
  const [formula, setFormula] = useState(0);
  const { handleChange, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    values.project &&
      setFormulaText(
        aregisterAppcreateStore.getSizeFormulaForMasscopy(
          String(values.species)
        )
      );
  }, [values.project]);

  useEffect(() => {
    values.species
      ? setFormula(
          aregisterAppcreateStore.params.species.variable[values.species]
            ?.custom?.["formula"]
            ? Number(
                aregisterAppcreateStore.params.species.variable[values.species]
                  .custom["formula"]
              )
            : 0
        )
      : setFormula(0);
  }, [values.species, values.project]);

  const getSize = (value: string, field: string) => {
    let numbers: number[] = [];
    switch (field) {
      case "height":
        numbers = [Number(values.length), Number(values.width), Number(value)];
        break;
      case "width":
        numbers = [Number(values.length), Number(value), Number(values.height)];
        break;
      case "length":
        numbers = [Number(value), Number(values.width), Number(values.height)];
        break;
    }

    let result: number | string;
    switch (formula) {
      case 0:
      case undefined:
        result = numbers.reduce((acc, rec) => acc * rec);
        break;
      case 1:
        result = numbers[0] * numbers[1];
        break;
      case 2:
        if (numbers[0] > numbers[1]) {
          result = numbers[0] * numbers[2];
        } else {
          result = numbers[1] * numbers[2];
        }
        break;
    }

    setFieldValue(
      `copies[${numeric}][size]`,
      isNaN(+result) || result === 0 ? null : `${Number(result).toFixed(2)}`
    );
    setFieldTouched(
      `copies[${numeric}][size]`,
      isNaN(+result) || result === 0 ? true : false
    );
    return result;
  };

  return type !== "edit" && type !== "mass_edit" ? (
    <div
      className={`${styles.size} ${
        aregisterOneMasscopyStore.tableType[id] === "column"
          ? styles.size_column
          : styles.size_row
      } ${
        aregisterOneMasscopyStore.tableType[id] === "row" &&
        values?.project &&
        aregisterAppcreateStore.projects[values.project]?.allow_manual_size ===
          1
          ? styles.size_row_big
          : aregisterOneMasscopyStore.tableType[id] === "column" &&
            values?.project &&
            aregisterAppcreateStore.projects[values.project]
              ?.allow_manual_size === 1
          ? styles.size_column_big
          : ""
      }`}
    >
      {values?.project &&
      aregisterAppcreateStore.projects[values.project]?.allow_manual_size ===
        1 ? (
        <div
          className={
            aregisterOneMasscopyStore.tableType[id] === "column"
              ? styles.size_column__prop
              : styles.size_row__prop
          }
        >
          <div>{aregisterAppcreateStore.cols.manual_size.title}</div>
          <div>
            {values.manual_size ? (
              values.manual_size
            ) : (
              <IconDash className={styles.iconDash} />
            )}
          </div>
        </div>
      ) : null}
      {["length", "width", "height", "size"].map((sizeKey) => {
        const sizeField = aregisterAppcreateStore.cols[sizeKey];
        return (
          <div
            key={sizeKey}
            className={
              aregisterOneMasscopyStore.tableType[id] === "column"
                ? styles.size_column__prop
                : styles.size_row__prop
            }
          >
            <div>{sizeField.title}</div>

            <div>
              {values.allow_manual_size ? (
                <IconDash className={styles.iconDash} />
              ) : (
                values[sizeKey]
              )}
            </div>
          </div>
        );
      })}
      <div
        className={
          aregisterOneMasscopyStore.tableType[id] === "column"
            ? styles.size_column__prop
            : styles.size_row__prop
        }
      >
        <div>Формула</div>
        <div>
          {values.allow_manual_size ? (
            <IconDash className={styles.iconDash} />
          ) : (
            formulaText || "Д*Ш*В"
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${
        values.project &&
        aregisterAppcreateStore.projects[values.project]?.allow_manual_size ===
          1 &&
        aregisterOneMasscopyStore.tableType[id] === "column"
          ? styles.size_column_big
          : values.project &&
            aregisterAppcreateStore.projects[values.project]
              ?.allow_manual_size === 1 &&
            aregisterOneMasscopyStore.tableType[id] === "row"
          ? styles.size_row_big
          : ""
      } ${
        aregisterOneMasscopyStore.tableType[id] === "column"
          ? styles.size_column
          : styles.size_row
      } ${styles.size_input}`}
    >
      {values.project &&
      aregisterAppcreateStore.projects[values.project]?.allow_manual_size ===
        1 ? (
        <div
          className={`${
            aregisterOneMasscopyStore.tableType[id] === "column"
              ? styles.size_column__prop
              : styles.size_row__prop
          } ${
            aregisterOneMasscopyStore.tableType[id] === "column"
              ? styles.size_column__prop_input
              : styles.size_row__prop_input
          }`}
        >
          <div
            className={styles.switch}
            id={`AregisterMasscopyFormFormula_copies[${numeric}][allow_manual_size]`}
            onClick={() => {
              setFieldValue(
                `copies[${numeric}][allow_manual_size]`,
                values.allow_manual_size ? 0 : 1
              );
            }}
          >
            <div>{aregisterAppcreateStore.cols.manual_size.title}</div>
            <Switch name={`copies[${numeric}][allow_manual_size]`} />
          </div>
          <div>
            <Input
              name={`copies[${numeric}][manual_size]`}
              disabled={!values.allow_manual_size}
              onClick={() =>
                onClick && onClick(`copies[${numeric}][manual_size]`)
              }
              onKeyDown={(e: { keyCode: number }) => {
                if (e["keyCode"] === 27) {
                  onKeyDown &&
                    onKeyDown(`copies[${numeric}][manual_size]`, "manual_size");
                }
              }}
              createRef
              autocomplete="off"
            />
          </div>
        </div>
      ) : null}
      {["length", "width", "height", "size"].map((sizeKey) => {
        const sizeField = aregisterAppcreateStore.cols[sizeKey];
        return (
          <div
            key={sizeKey}
            className={`${
              aregisterOneMasscopyStore.tableType[id] === "column"
                ? styles.size_column__prop
                : styles.size_row__prop
            } ${
              aregisterOneMasscopyStore.tableType[id] === "column"
                ? styles.size_column__prop_input
                : styles.size_row__prop_input
            }`}
          >
            <div>{sizeField.title}</div>
            <div>
              <Input
                name={`copies[${numeric}][${sizeKey}]`}
                disabled={
                  sizeKey === "size" || Boolean(values.allow_manual_size)
                }
                onChange={(e) => {
                  if (sizeKey === "height" && formula === 1) return;

                  handleChange(e);
                  if (sizeKey !== "size") {
                    getSize(e.target.value, sizeKey);

                    setTimeout(() => {
                      setFieldTouched("size");
                    }, 200);
                  }
                }}
                onKeyDown={(e: { keyCode: number }) => {
                  if (e["keyCode"] === 27) {
                    onKeyDown &&
                      onKeyDown(`copies[${numeric}][${sizeKey}]`, sizeKey);
                  }
                }}
                onClick={() => {
                  setFieldTouched("size");
                  onClick && onClick(`copies[${numeric}][${sizeKey}]`);
                }}
                createRef
                autocomplete="off"
              />
            </div>
          </div>
        );
      })}
      <div
        className={`${
          aregisterOneMasscopyStore.tableType[id] === "column"
            ? styles.size_column__prop
            : styles.size_row__prop
        } ${styles.size__formula}`}
      >
        <div>Формула</div>
        <div> {!values.allow_manual_size ? formulaText || "Д*Ш*В" : ""}</div>
      </div>
    </div>
  );
};

export default observer(AregisterMasscopyFormFormula);
