import styles from "./pageTitle.module.scss";

type PageTitleProps = {
  /**
   * Заголовок
   */
  title: string;
  /**
   * Расположение заголовка справа
   */
  rightCol?: boolean;
  /**
   * Расположение заголовка слева
   */
  leftCol?: boolean;
  /**
   * Должен ли заголовок перемещаться при вертикальном скролле
   */
  isStickyTitle?: boolean;
};

const PageTitle = ({
  rightCol,
  leftCol,
  title,
  isStickyTitle
}: PageTitleProps) => {
  return (
    <div
      className={`${styles.pageTitle} ${
        isStickyTitle ? styles.stickyTitle : ""
      }`}
    >
      {leftCol && (
        <div className={styles.leftCol}>
          <p className={styles.title}>{title}</p>
        </div>
      )}
      {rightCol && (
        <div className={styles.rightCol}>
          <p className={styles.title}>{title}</p>
        </div>
      )}
    </div>
  );
};

export default PageTitle;
