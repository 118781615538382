import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import styles from "./staffVacTableCurrentStatus.module.scss";
import StatusIcon from "shared/ui/StatusIcon";

type StaffVacTableCurrentStatusProps = {
  data: {
    author: string;
    color: string;
    icon: string;
    status: string;
    OM: { date_start: string; date_end: string; id: string };
    OE?: { date_start: string; date_end: string; id: string };
  };
  cols: {
    [key: string]: { newname: string; title: string; cols?: string[] } | string;
  };
};

const StaffVacTableCurrentStatus = ({
  data,
  cols
}: StaffVacTableCurrentStatusProps) => {
  return data["status"] ? (
    <div className={styles.container}>
      <div className={styles.statusTitle}>
        {data["icon"] ? (
          <StatusIcon icon={data["icon"]} color={data["color"]} />
        ) : (
          ""
        )}
        <p>{cols[data["status"]]["title"]}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.row__gray}>{data["OM"]["id"]}</p>
        <p>
          {`${getFormattedDate(data["OM"]["date_start"])} - 
          ${getFormattedDate(data["OM"]["date_end"])}`}
        </p>
      </div>
      <p>{data["author"]}</p>

      {data["OE"] ? (
        <div className={`${styles.row} ${styles.row__color}`}>
          <p>{data["OE"]["id"]}</p>
          <p>
            {`${getFormattedDate(data["OE"]["date_start"])} - 
            ${getFormattedDate(data["OE"]["date_end"])}`}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <></>
  );
};

export default StaffVacTableCurrentStatus;
