import iconExpand from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { useEffect, useState } from "react";
import styles from "./polatiAppVacantRequestSelect.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Records } from "stores/PolatiAppModule/types/Records";
import { Button } from "shared/ui/Button";

type PolatiAppVacantRequestSelectProps = {
  selectedLabel: string;
  options: { [key: string]: string }[];
  id: string;
  setStatus: (
    id: string,
    status: string
  ) => {
    [key: string]: Records;
  };
  value: string;
  top: boolean;
  onClick: () => void;
  onFocus: () => void;
  title: string;
};

const PolatiAppVacantRequestSelect = ({
  selectedLabel,
  options,
  id,
  setStatus,
  value,
  top,
  onClick,
  onFocus,
  title
}: PolatiAppVacantRequestSelectProps) => {
  const [preSelectedOption, setPreSelectedOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleSubmit = () => {
    setSelectedOption(preSelectedOption);
    setStatus(id, preSelectedOption);
    setIsMenuOpened(false);
  };

  useEffect(() => {
    if (selectedLabel) {
      setSelectedOption(selectedLabel);
    } else
      options.find((option) => {
        if (option.value === value) {
          setSelectedOption(option.label);
        }
      });
  }, [selectedLabel, value]);

  return (
    <div
      id={`PolatiAppVacantRequestSelect_${id}_wrapper`}
      className="position-relative"
      onClick={onClick}
      tabIndex={0}
      onFocus={onFocus}
      onBlur={() => {
        setTimeout(() => {
          setIsMenuOpened(false);
        }, 200);
      }}
    >
      <div
        id={`PolatiAppVacantRequestSelect_${id}_container`}
        className={styles.valueContainer}
        onClick={() => setIsMenuOpened(!isMenuOpened)}
        data-select
      >
        <p className={styles.label}>{selectedOption || title}</p>
        <img
          src={iconExpand}
          className={isMenuOpened ? styles.transformed : ""}
          onClick={() => setIsMenuOpened(!isMenuOpened)}
          id={`PolatiAppVacantRequestSelect_${id}_btn`}
        />
      </div>
      {isMenuOpened ? (
        <ul className={`${styles.list} ${top ? styles.list_top : ""}`}>
          <Scrollbars
            style={{ width: "100%" }}
            autoHeight
            autoHeightMax="250px"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            {options && options.length
              ? options
                  .filter((option) => option.label !== selectedOption)
                  .map((option, i) => {
                    return (
                      <li
                        id={`option_${i}_${id}`}
                        key={option["value"]}
                        value={option["value"]}
                        onClick={() => {
                          setPreSelectedOption(option["label"]);
                        }}
                      >
                        <p
                          className={`m-0 ${
                            option["label"] === preSelectedOption
                              ? styles.selectedOption
                              : ""
                          }`}
                        >
                          {option["label"]}
                        </p>
                      </li>
                    );
                  })
              : null}
            <div className={styles.saveBtn}>
              <Button onClick={handleSubmit}>Сохранить</Button>
            </div>
          </Scrollbars>
        </ul>
      ) : null}
    </div>
  );
};

export default PolatiAppVacantRequestSelect;
