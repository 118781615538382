import styles from "./salaryPaymentlistAll.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import LoadedComponent from "widgets/LoadedComponent";
import SalaryPaymentlistAllTable from "./SalaryPaymentlistAllTable";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import TypeAndStatusFilterBtns from "./TypeAndStatusFilterBtns";

import { browserName } from "react-device-detect";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";

const SalaryPaymentlistAll = () => {
  const { menuStore, filterStore, salaryPaymentlistAllStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | string[] } = {};

  // в данном useEffect заполняем словарь для расширенного поиска, если в url присутствуют параметры фильтрации, переходя по ссылке или обновляя страницу
  // применяем для полей, пагинация которых происходит с бэка (объект)
  useEffect(() => {
    if (
      Array.isArray(
        salaryPaymentlistAllStore.salaryPaymentTableCols["object"]
      ) &&
      salaryPaymentlistAllStore.salaryPaymentTableCols["object"].length
    ) {
      // согласно значению поля Объект записываем в словарь эти объекты
      salaryPaymentlistAllStore.getBuildingDictForSavedFilter(
        salaryPaymentlistAllStore.salaryPaymentTableCols["object"]
      );
      salaryPaymentlistAllStore.setDictForArray(
        salaryPaymentlistAllStore.dictForFilter
      );
    }
  }, [salaryPaymentlistAllStore.salaryPaymentTableCols["object"]]);

  // в данном useEffect заполняем словарь для сохраненного фильтра для тех полей, пагинация которых происходит с бэка (объект)
  useEffect(() => {
    if (
      Array.isArray(salaryPaymentlistAllStore.filterParams["filter[object]"]) &&
      salaryPaymentlistAllStore.filterParams["filter[object]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Объект в сохраненном фильтре записываем в словарь эти объекты
      salaryPaymentlistAllStore.getBuildingDictForSavedFilter(
        salaryPaymentlistAllStore.filterParams["filter[object]"]
      );
      salaryPaymentlistAllStore.setDictForArray(
        salaryPaymentlistAllStore.dictForFilter
      );
    }
  }, [
    filterStore.isOpenedSavedFilterOptions,
    salaryPaymentlistAllStore.downloadedFilter
  ]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      salaryPaymentlistAllStore.pageBuilding !== 1 &&
      salaryPaymentlistAllStore.pageBuilding <=
        salaryPaymentlistAllStore.maxPageBuilding &&
      salaryPaymentlistAllStore.pageBuilding !==
        salaryPaymentlistAllStore.prevPageBuilding
    ) {
      salaryPaymentlistAllStore.getMoreBuildingList();
    }
  }, [
    salaryPaymentlistAllStore.pageBuilding,
    salaryPaymentlistAllStore.maxPageBuilding
  ]);

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      salaryPaymentlistAllStore.setIsSettingParamsFromURL(true);
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      salaryPaymentlistAllStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            salaryPaymentlistAllStore.setSearchValue(value);
            break;

          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      salaryPaymentlistAllStore.setFilterParamsFromQuery(
        queryParams as { [key: string]: string }
      );
    }

    !salaryPaymentlistAllStore.paymentList.length &&
      salaryPaymentlistAllStore.getPaymentListData();
    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("paymentlist");
    menuStore.updateWindow({
      mainPath: "/salary",
      path: "/salary/paymentlist",
      title: "Финансы"
    });
  }, []);

  // обновляет параметры query запроса в поисковой строке при изменении переменных из массива зависимостей
  useEffect(() => {
    if (!salaryPaymentlistAllStore.isSettingParamsFromURL) {
      const filterParams: {
        [key: string]: string;
      } = {};

      if (salaryPaymentlistAllStore.searchValue) {
        filterParams["fast_search"] = salaryPaymentlistAllStore.searchValue;
      }

      Object.entries(salaryPaymentlistAllStore.filterParams)?.forEach(
        ([key, value]: [string, string | number | string[]]) => {
          switch (typeof value) {
            case "string":
            case "number":
              filterParams[key] = String(value);
              break;
            case "object":
              value?.forEach((objectValue, index) => {
                filterParams[`${key}[${index}]`] = objectValue;
              });
              break;
          }
        }
      );

      salaryPaymentlistAllStore.setQueryParams(filterParams);
      setSearchParams(filterParams);
    }
  }, [
    salaryPaymentlistAllStore.filterParams,
    salaryPaymentlistAllStore.searchValue
  ]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (Object.keys(salaryPaymentlistAllStore.queryParams).length) {
      setSearchParams(salaryPaymentlistAllStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      salaryPaymentlistAllStore.page !== 1 &&
      salaryPaymentlistAllStore.page <= salaryPaymentlistAllStore.maxPage &&
      salaryPaymentlistAllStore.page !== salaryPaymentlistAllStore.prevPage
    ) {
      salaryPaymentlistAllStore.getMorePremiumList();
    }
  }, [salaryPaymentlistAllStore.page, salaryPaymentlistAllStore.maxPage]);

  return (
    Object.values(salaryPaymentlistAllStore.selects.type || {})?.length && (
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <TableWithStickyFilter
            isLoading={salaryPaymentlistAllStore.isLoading}
            isLoadingForFilters={salaryPaymentlistAllStore.isLoadingForFilters}
            searchValue={salaryPaymentlistAllStore.searchValue}
            setSearchValue={salaryPaymentlistAllStore.setSearchValue}
            getData={salaryPaymentlistAllStore.getPaymentList}
            selectedFilter={salaryPaymentlistAllStore.selectedFilter}
            setSelectedFilter={salaryPaymentlistAllStore.setSelectedFilter}
            cols={salaryPaymentlistAllStore.salaryPaymentTableCols}
            params={salaryPaymentlistAllStore.salaryPaymentParam}
            allCols={salaryPaymentlistAllStore.salaryPaymentTableCols}
            currentCols={salaryPaymentlistAllStore.currentTitles}
            getDataWithFilter={
              salaryPaymentlistAllStore.getPaymentListWithFilter
            }
            filterParams={
              Object.keys(salaryPaymentlistAllStore.filterParams).length > 1
                ? salaryPaymentlistAllStore.filterParams
                : undefined
            }
            setQueryParams={salaryPaymentlistAllStore.setQueryParams}
            queryParams={salaryPaymentlistAllStore.queryParams}
            isSearchable
            downloadedFilter={salaryPaymentlistAllStore.downloadedFilter}
            setDownloadedFilter={salaryPaymentlistAllStore.setDownloadedFilter}
            filters={salaryPaymentlistAllStore.filters}
            foundCounter={salaryPaymentlistAllStore.foundCounter}
            itemName="ведомостей"
            theadTop={getTHeadTop(
              filterStore.savedFiltersHeight,
              Boolean(salaryPaymentlistAllStore.searchValue),
              filterStore.isOpenedSavedFilters && browserName === "Firefox"
            )}
            withoutColsSetting
            selectMulti={["object", "staff", "author", "type", "status"]}
            defaultFields={{
              type: [
                Object.values(
                  salaryPaymentlistAllStore.selects.type || {}
                ).find((type) => type.custom?.pl_type === "object").id
              ],
              status: Object.keys(
                salaryPaymentlistAllStore.selects.status || {}
              )
            }}
            selectsWithLoading={["object", "staff", "author"]}
            dateRangeFields={salaryPaymentlistAllStore.dateRangeFields}
            setFiltersChanged={salaryPaymentlistAllStore.setFiltersChanged}
            pageSelectsInfo={{
              staff: {
                page: salaryPaymentlistAllStore.pageStaff,
                prevPage: salaryPaymentlistAllStore.prevPageStaff,
                maxPage: salaryPaymentlistAllStore.maxPageStaff,
                searchValue: salaryPaymentlistAllStore.searchValueStaff,
                isLoading: salaryPaymentlistAllStore.isLoadingForStaffList,
                setSearchValue: salaryPaymentlistAllStore.setSearchValueStaff,
                setPage: salaryPaymentlistAllStore.setPageStaff,
                getList: salaryPaymentlistAllStore.getStaffList
              },
              object: {
                page: salaryPaymentlistAllStore.pageBuilding,
                prevPage: salaryPaymentlistAllStore.prevPageBuilding,
                maxPage: salaryPaymentlistAllStore.maxPageBuilding,
                searchValue: salaryPaymentlistAllStore.searchValueBuilding,
                isLoading: salaryPaymentlistAllStore.isLoadingForBuildingList,
                setSearchValue:
                  salaryPaymentlistAllStore.setSearchValueBuilding,
                setPage: salaryPaymentlistAllStore.setPageBuilding,
                getList: salaryPaymentlistAllStore.getBuildingList
              },
              author: {
                page: salaryPaymentlistAllStore.pageAuthor,
                prevPage: salaryPaymentlistAllStore.prevPageAuthor,
                maxPage: salaryPaymentlistAllStore.maxPageAuthor,
                searchValue: salaryPaymentlistAllStore.searchValueAuthor,
                isLoading: salaryPaymentlistAllStore.isLoadingForAuthorList,
                setSearchValue: salaryPaymentlistAllStore.setSearchValueAuthor,
                setPage: salaryPaymentlistAllStore.setPageAuthor,
                getList: salaryPaymentlistAllStore.getAuthorList
              }
            }}
            withoutSavedFilter
            moreButton={<TypeAndStatusFilterBtns />}
          >
            <SalaryPaymentlistAllTable />
          </TableWithStickyFilter>
        </ErrorBoundary>
        {salaryPaymentlistAllStore.page !== 1 ? (
          <div className={styles.firstCol}>
            {salaryPaymentlistAllStore.isLoading ? (
              <p className={styles.loading}>Пожалуйста, подождите</p>
            ) : (
              ""
            )}
          </div>
        ) : (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LoadedComponent isLoading={salaryPaymentlistAllStore.isLoading} />
          </ErrorBoundary>
        )}
      </>
    )
  );
};

export default observer(SalaryPaymentlistAll);
