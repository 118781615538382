import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import StaffOneInfoFinanceMain from "./StaffOneInfoFinanceMain";
import StaffOneInfoFinanceTableDetailed from "./StaffOneInfoFinanceTableDetailed";

const StaffOneInfoFinance = () => {
  const { staffOneStore } = useStores();

  const [toggleTable, setToggleTable] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.salary) && staffOneStore.getSalaryWidget(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_salary[id]}
      withoutText
    >
      <>
        <StaffOneInfoFinanceMain setToggleTable={setToggleTable} />

        {toggleTable ? (
          <div className="position-relative">
            <StaffOneInfoFinanceTableDetailed setToggleTable={setToggleTable} />
          </div>
        ) : (
          ""
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoFinance);
