import styles from "./buildingOneWorkshiftStafftableBool.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useFormikContext } from "formik";
import StatusIcon from "shared/ui/StatusIcon";

import { classNames } from "shared/utils/helpers/classNames";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";

const BuildingOneWorkshiftStafftableBool = ({
  name,
  staffIndex
}: WorkshiftStaffFieldProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { values, setFieldValue } = useFormikContext<StaffType[]>();

  // функция отправки данных при клике на иконку
  const handleClick = (value: number) => () => {
    if (value !== values[staffIndex][name]) {
      setFieldValue(`${staffIndex}.${name}`, value);
      buildingOneWorkshiftStore.sendData(
        value,
        name,
        values[staffIndex].ws_staff_id,
        values[staffIndex].uid,
        values[staffIndex].isMassEdit
      );
    }
  };

  // Состояние в смене - Работает
  const isWorkObjectstatus =
    values[staffIndex].objectstatus &&
    buildingOneWorkshiftStore.selects.objectstatus[
      values[staffIndex].objectstatus
    ]?.id === "192afc381518f3ad2dacced85301dd865144450e"; // id состояния Работает
  // Состояние в смене - Дни в пути
  const isDayOnWayObjectstatus =
    values[staffIndex].objectstatus &&
    buildingOneWorkshiftStore.selects.objectstatus[
      values[staffIndex].objectstatus
    ]?.id === "540501e61061c3e29fcfecaf4b80a137670a2ed9"; //id состояния Дни в пути
  // Наличие часов работы
  const withoutWorkHours = !values[staffIndex].work_hours;

  // Отображение полей при массовом редактировании
  const isMassEditable = values[staffIndex].isMassEdit && name !== "study";
  // Открытый режим отображения поля bool
  const mode = buildingOneWorkshiftStore.getOpenedModeForField(
    name,
    isMassEditable
  );

  /*
    отображение столбца "Ночь" согласно документации:
    если статус в смене Работает (Р)  и не указаны часы
    либо же если статус отличный от Работает (Р)
    то мы ничего не отображаем
  */
  if (
    !values[staffIndex].isMassEdit &&
    name === "night" &&
    ((isWorkObjectstatus && withoutWorkHours) || !isWorkObjectstatus)
  ) {
    return;
  }

  /*
    отображение столбца "study" согласно документации:
    если статус в смене "Дни в пути"
    то мы ничего не отображаем
  */
  if (name === "study" && isDayOnWayObjectstatus) {
    return;
  }

  switch (mode) {
    case "show":
      return (
        <StatusIcon
          icon={values[staffIndex][name] ? "bigcheck" : "dash"}
          color={values[staffIndex][name] ? "accent-green" : "accent-coral"}
        />
      );
    case "edit":
      return (
        <div className="d-flex gap-1">
          {[1, 0].map((item) => (
            <button
              key={`${values[staffIndex].uid}_${name}_${item}`}
              type="button"
              className={classNames(styles.checkBlock, {
                [styles.checkBlock_active]: item
                  ? !!values[staffIndex][name]
                  : !values[staffIndex][name]
              })}
              id={`${staffIndex}.${name}_${item ? "active" : "disabled"}`}
              onClick={handleClick(item)}
            >
              <StatusIcon
                icon={item ? "bigcheck" : "dash"}
                color={
                  item && values[staffIndex][name]
                    ? "accent-green"
                    : !item && !values[staffIndex][name]
                    ? "accent-coral"
                    : "bw-gray3"
                }
              />
            </button>
          ))}
        </div>
      );
  }
};

export default observer(BuildingOneWorkshiftStafftableBool);
