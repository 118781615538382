import styles from "./aregisterOneInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import LoadedComponent from "widgets/LoadedComponent";
import AregisterOneInfoMain from "./AregisterCard";
import ConditionalCubes from "./ConditionalCubes";
import AregisterHeader from "./AregisterHeader";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import { isEmpty } from "lodash";

const AregisterOneInfo = () => {
  const { id } = useParams();
  const { menuStore, aregisterOneStore } = useStores();

  useEffect(() => {
    aregisterOneStore.setSelectedAregister(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("aregisterOne");
    menuStore.setOpenedSubmodule("aregisterOne");
    menuStore.updateWindow({
      mainPath: `/aregister/id=${id}`,
      path: `/aregister/id=${id}`
    });
  }, [id]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent
        isLoading={aregisterOneStore.isLoading[id]}
        error={aregisterOneStore.error[id]}
      >
        {!isEmpty(aregisterOneStore.selectedOne) &&
        aregisterOneStore.aregisterTitles.length ? (
          <div className={styles.aregister}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AregisterHeader />
            </ErrorBoundary>
            <div className={styles.main}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AregisterOneInfoMain />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ConditionalCubes />
              </ErrorBoundary>
            </div>
          </div>
        ) : null}
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(AregisterOneInfo);
