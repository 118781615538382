import { observer } from "mobx-react-lite";
import StaffOneSalaryRetentionPPE from "./StaffOneSalaryRetentionPPE";
import StaffOneSalaryLastOperations from "./StaffOneSalaryLastOperations";
import StaffOneSalaryRetentionUC from "./StaffOneSalaryRetentionUC";
import StaffOneSalaryPaymentList from "./StaffOneSalaryPaymentList";
import StaffOneSalaryPremium from "./StaffOneSalaryPremium";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { MutableRefObject } from "react";
import Collapsible from "shared/ui/Collapsible";

const fieldsTitlе = {
  retentionPPE: "Удержания СИЗ",
  withholding_edu: "Вычеты УЦ",
  paymentlist: "Ведомости сотрудника",
  premium: "Премии",
  operations: "Последние операции"
};

const fields = {
  retentionPPE: <StaffOneSalaryRetentionPPE />,
  withholding_edu: <StaffOneSalaryRetentionUC />,
  paymentlist: <StaffOneSalaryPaymentList />,
  premium: <StaffOneSalaryPremium />,
  operations: <StaffOneSalaryLastOperations />
};

type StaffOneSalaryFieldsProps = {
  field: string;
  refsForBlock: MutableRefObject<HTMLDivElement[]>;
};

const StaffOneSalaryFields = ({
  field,
  refsForBlock
}: StaffOneSalaryFieldsProps) => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  const setConditionBlockList = () => {
    staffOneSalaryStore.setConditionBlockList(
      id,
      field,
      !staffOneSalaryStore.conditionBlockList[id]?.[field]
    );
  };

  return (
    <div
      // Динамически задаем ref для каждого блока
      ref={(el) => (refsForBlock.current[field] = el)}
    >
      <Collapsible
        onClick={setConditionBlockList}
        title={fieldsTitlе[field]}
        isOpenedField={staffOneSalaryStore.conditionBlockList[id]?.[field]}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {fields[field]}
        </ErrorBoundary>
      </Collapsible>
    </div>
  );
};

export default observer(StaffOneSalaryFields);
