import { string, object } from "yup";

import { dateSchema } from "shared/utils/validation/validation";

const validationOperationForm = {
  time_create: dateSchema,
  time_spending: dateSchema
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    // hardcode
    if (["uid", "author"].includes(col)) return;
    validationSchema[col] = validationOperationForm[col]
      ? validationOperationForm[col]
      : string().nullable();

    if (requiredCols.includes(col)) {
      validationSchema[col] = validationSchema[col].required(
        "Поле обязательно для заполнения"
      );
    }
  });

  return object(validationSchema);
};
