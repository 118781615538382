import * as yup from "yup";
import { object } from "yup";
import { getYear } from "date-fns";
import { stringSchema } from "shared/utils/validation/validation";

export const validationStaffOneConvitionForm = object({
  article: stringSchema.required("Поле обязательно для заполнения"),
  date_start: yup
    .date()
    .typeError("Некорректный формат даты")
    .required("Поле обязательно для заполнения")
    .max(new Date(), "Дата получения не может быть больше текущего года")
    .min(
      getYear(new Date()) - 70 + 14,
      `Дата получения не может быть раньше ${
        getYear(new Date()) - 70 + 14
      } года`
    )
    .nullable()
});
