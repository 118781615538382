import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import BuildingsSelected from "shared/assets/images/menuIcons/iconSelected/Buildings.svg";

import EmployeeStatus from "shared/assets/images/menuIcons/iconDefault/EmployeeStatus.svg";
import EmployeeStatusSelected from "shared/assets/images/menuIcons/iconSelected/EmployeeStatus.svg";

import WaitingWorker from "shared/assets/images/menuIcons/iconDefault/WaitingWorker.svg";
import WaitingWorkerSelected from "shared/assets/images/menuIcons/iconSelected/WaitingWorker.svg";

import PlannedBuildings from "shared/assets/images/menuIcons/iconDefault/PlannedBuildings.svg";
import PlannedBuildingsSelected from "shared/assets/images/menuIcons/iconSelected/PlannedBuildings.svg";

import { fileUrl } from "stores/utils/consts";

const BuildingSection = () => {
  const overview = [
    {
      text: "Объекты",
      to: "/building/",
      moduleName: "building",
      submoduleName: "main",
      defaultIcon: Buildings,
      selectedIcon: BuildingsSelected
    },
    {
      text: "История статусов",
      to: "/building/staffEvents",
      fullstack: `${fileUrl}/crm/building/?action=staffEvents`,
      submoduleName: "staffEvents",
      defaultIcon: EmployeeStatus,
      selectedIcon: EmployeeStatusSelected
    },
    {
      text: "Межвахта",
      to: "/building/watch",
      fullstack: `${fileUrl}/crm/building/?action=staffVacation`,
      submoduleName: "watch",
      defaultIcon: WaitingWorker,
      selectedIcon: WaitingWorkerSelected
    },
    {
      text: "Планируемые объекты",
      to: "/building/buildingReport",
      fullstack: `${fileUrl}/crm/building/?action=plannedObjectList`,
      submoduleName: "buildingReport",
      defaultIcon: PlannedBuildings,
      selectedIcon: PlannedBuildingsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
            fullstack={item["fullstack"]}
          />
        ))}
      </MenuSection>
    </>
  );
};
export default BuildingSection;
