import styles from "./aregisterAllReportNewReportTableCell.module.scss";

export type AregisterAllReportNewReportTableCellProps = {
  column?: number;
  rowSpan?: number;
  highlightedColumn?: number;
  setHighlightedColumn?: (arg: number) => void;
  children?: JSX.Element | JSX.Element[] | number | string;
  className?: string;
  id?: string;
  onClick?: () => void;
};

const AregisterAllReportNewReportTableCell = ({
  column,
  rowSpan,
  highlightedColumn,
  setHighlightedColumn,
  className,
  children,
  id,
  onClick
}: AregisterAllReportNewReportTableCellProps) => {
  return (
    <td
      className={`${className} ${
        highlightedColumn !== undefined && highlightedColumn === column
          ? styles.highlighted
          : ""
      } 
      `}
      onMouseEnter={() => {
        setHighlightedColumn && setHighlightedColumn(column);
      }}
      onMouseLeave={() => {
        setHighlightedColumn && setHighlightedColumn(-1);
      }}
      rowSpan={rowSpan}
      onClick={onClick}
      id={id}
    >
      {children}
    </td>
  );
};

export default AregisterAllReportNewReportTableCell;
