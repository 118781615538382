import { format, parse, isValid } from "date-fns";
import { dateFormats } from "stores/utils/consts";
import { standardDatePattern } from "shared/utils/validation/validation";

// Преобразование даты в формат объекта даты в соответствии с паттерном констант -- для дата пикера
export const getParsedDate = (str: string | Date) => {
  if (str) {
    if (typeof str === "string" && standardDatePattern.test(str)) {
      return new Date(str);
    }
    if (typeof str === "string" && isValid(parse(str, "yyyy", new Date()))) {
      return parse(str, "yyyy", new Date());
    }
    if (typeof str === "string" && !isNaN(Number(str))) {
      return parse(str, "yyyy", new Date());
    }
    if (
      typeof str === "string" &&
      isValid(parse(str, "yyyy-MM-dd HH:mm:ss", new Date()))
    ) {
      return parse(str, "yyyy-MM-dd HH:mm:ss", new Date());
    }

    const fullFormatArray = dateFormats.date.format.split(/\W/g);
    const fullDateArray =
      typeof str !== "object"
        ? str.split(/\W/g)
        : format(str, "yyyy-MM-dd").split(/\W/g);

    const datePartsIndexes = {
      year: fullFormatArray.findIndex((val) => val.match(/y/gi)),
      month: fullFormatArray.findIndex((val) => val.match(/m/gi)),
      day: fullFormatArray.findIndex((val) => val.match(/d/gi))
    };

    const dateObj = new Date(
      +fullDateArray[datePartsIndexes.year],
      +fullDateArray[datePartsIndexes.month] - 1,
      +fullDateArray[datePartsIndexes.day]
    );

    return isValid(dateObj) ? dateObj : null;
  } else return null;
};
