import styles from "./aregisterOneEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import LoadedComponent from "widgets/LoadedComponent";
import AregisterForm from "features/AregisterForm";

import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";
import iconCancel from "shared/assets/images/mainIcons/iconCancel.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const AregisterOneEdit = () => {
  const { id } = useParams();
  const { menuStore, aregisterOneEditStore, aregisterAppcreateStore } =
    useStores();

  useEffect(() => {
    aregisterOneEditStore.setSelectedApplication(id);

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("aregisterOne");
    menuStore.setOpenedSubmodule("aregisterOneEdit");

    menuStore.updateWindow({
      mainPath: `/aregister/id=${id}`,
      path: `/aregister/id=${id}/edit`
    });
  }, [id]);

  return (
    <LoadedComponent
      error={aregisterOneEditStore.error}
      isLoading={
        aregisterOneEditStore.isLoading || aregisterAppcreateStore.isLoading
      }
    >
      <>
        {Object.values(aregisterOneEditStore.selectedOneForEdit).length &&
        Object.values(aregisterAppcreateStore.projects).length &&
        aregisterAppcreateStore.openedAllInitialValuesForEdit[id] &&
        aregisterOneEditStore.editableFields[id]?.length ? (
          <>
            <div
              className={styles.title}
              style={{
                backgroundColor: aregisterOneEditStore.selectedOneForEdit.status
                  .done
                  ? "#F3F3F3"
                  : aregisterOneEditStore.selectedOneForEdit.status.cancelled
                  ? "#FFEEED"
                  : "#EFF9E9"
              }}
            >
              <p>
                Заявка{" "}
                <>{aregisterOneEditStore.selectedOneForEdit["internal_num"]}</>
              </p>
              <div className={styles.title__state}>
                <img
                  src={
                    aregisterOneEditStore.selectedOneForEdit.status.done
                      ? iconLock
                      : aregisterOneEditStore.selectedOneForEdit.status
                          .cancelled
                      ? iconCancel
                      : iconLockOpen
                  }
                />
                <p
                  style={{
                    color: aregisterOneEditStore.selectedOneForEdit.status.done
                      ? "#9C9C9D"
                      : aregisterOneEditStore.selectedOneForEdit.status
                          .cancelled
                      ? "#FF584E"
                      : "#17A854"
                  }}
                >
                  {aregisterOneEditStore.selectedOneForEdit.status.title}
                </p>
              </div>
            </div>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AregisterForm
                type="edit"
                initialValues={
                  aregisterAppcreateStore.openedAllInitialValuesForEdit[id]
                }
              />
            </ErrorBoundary>
          </>
        ) : null}
      </>
    </LoadedComponent>
  );
};

export default observer(AregisterOneEdit);
