import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import styles from "./buttonsGroupForFormEdit.module.scss";

type ButtonsGroupForFormEditProps = {
  blueBtnTitle?: string;
  blueBtnDisabled?: boolean;
  blueBtnOnClick?: () => void;
  grayBtnTitle?: string;
  grayBtnDisabled?: boolean;
  grayBtnOnClick?: () => void;
};

const ButtonsGroupForFormEdit = ({
  blueBtnTitle,
  blueBtnDisabled,
  blueBtnOnClick,
  grayBtnTitle,
  grayBtnOnClick,
  grayBtnDisabled
}: ButtonsGroupForFormEditProps) => {
  return (
    <div className={styles.btnGroup}>
      {blueBtnTitle && (
        <Button
          id="buttonsGroupForFormEdit_createBtn"
          type="button"
          size={ButtonSize.L}
          disabled={blueBtnDisabled}
          onClick={blueBtnOnClick}
        >
          {blueBtnTitle}
        </Button>
      )}
      {grayBtnTitle && (
        <Button
          id="buttonsGroupForFormEdit_cancelBtn"
          type="button"
          theme={ButtonTheme.SECONDARY}
          size={ButtonSize.L}
          disabled={grayBtnDisabled}
          onClick={grayBtnOnClick}
        >
          {grayBtnTitle}
        </Button>
      )}
    </div>
  );
};

export default ButtonsGroupForFormEdit;
