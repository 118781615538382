import { observer } from "mobx-react-lite";
import styles from "./reportsWindow.module.scss";
import { useState } from "react";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import { Formik } from "formik";
import { Table } from "react-bootstrap";
import Checkbox from "shared/ui/Inputs/Checkbox";
import Modal from "shared/ui/Modal";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";

import iconCloseWhite from "shared/assets/images/mainIcons/iconsClose/iconCloseWhite.svg";
import { diff } from "deep-object-diff";
import { PresaveForAregister } from "stores/AregisterModule/types/PresaveForAregister";
import { isEmpty } from "lodash";
import { Button } from "shared/ui/Button";
import { getValues } from "shared/utils/helpers/getValues";
import Alert from "shared/ui/Alert";

const TITLES = ["Временный номер", "Дата", "Включены в заявку"];

type ReportsWindowProps = {
  onClose: () => void;
};

const ReportsWindow = ({ onClose }: ReportsWindowProps) => {
  const { aregisterOneStore } = useStores();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { id } = useParams();

  const handleSubmit = (values: Record<string, PresaveForAregister>) => {
    aregisterOneStore.changePresavesConditions(
      diff(aregisterOneStore.allAllowedPresaves[id], values) as Record<
        string,
        { is_pinned: boolean }
      >,
      id
    );
    return onClose();
  };

  const handleChangeShow = (val: boolean, isClose?: boolean) => () => {
    setShowDeleteModal(val);
    isClose && onClose();
  };

  return (
    <div className={styles.window}>
      <Formik
        initialValues={aregisterOneStore.allAllowedPresaves[id]}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty, handleSubmit, handleReset }) => (
          <div className={styles.wrapper}>
            <div className={styles.title}>
              Доступные отчеты мастера
              <Button className={styles.button}>
                <img
                  src={iconCloseWhite}
                  onClick={dirty ? handleChangeShow(true) : onClose}
                  id="ReportsWindow_closeWindow"
                />
              </Button>
            </div>
            <div>
              {!isEmpty(aregisterOneStore.allAllowedPresaves[id]) ? (
                <>
                  <Table className={styles.table}>
                    <thead>
                      <tr>
                        {TITLES.map((title) => (
                          <th key={title}>{title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getValues(aregisterOneStore.allAllowedPresaves[id]).map(
                        (presave) => (
                          <tr key={presave.id}>
                            <td>{presave.title}</td>
                            <td>{presave.date_active}</td>
                            <td>
                              <Checkbox name={`${presave.id}[is_pinned]`} />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <div className={styles.buttonsGroup}>
                    <ButtonsGroupForFormEdit
                      blueBtnTitle="Сохранить"
                      blueBtnDisabled={!isValid || !dirty}
                      blueBtnOnClick={handleSubmit}
                      grayBtnTitle="Отмена"
                      grayBtnDisabled={!isValid || !dirty}
                      grayBtnOnClick={
                        dirty ? handleChangeShow(true) : handleReset
                      }
                    />
                  </div>
                </>
              ) : (
                <Alert
                  errors={{ head: "Отчётов не найдено", color: "empty" }}
                />
              )}
            </div>
          </div>
        )}
      </Formik>
      <Modal
        // возможно понадобятся правки когда откроется раздел
        type="clarification"
        show={showDeleteModal}
        onHide={handleChangeShow(false)}
        title="Закрыть окно без сохранения данных?"
        btnWithCrossTitle="Закрыть"
        btnWithCrossOnClick={handleChangeShow(false, true)}
        blueBtnOnClick={handleChangeShow(false)}
        blueBtnTitle="Отмена"
        btnWithCrossImg
      />
    </div>
  );
};

export default observer(ReportsWindow);
