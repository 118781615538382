import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Input } from "shared/ui/Inputs/Input";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Textarea from "shared/ui/Inputs/Textarea";
import FormWindow from "shared/ui/FormWindow";
import StatusIcon from "shared/ui/StatusIcon";
import { ViewField } from "shared/ui/ViewField";
import { Button, ButtonTheme } from "shared/ui/Button";

import StaffOneSafetyWorkTableWindowHistory from "./StaffOneSafetyWorkTableWindowHistory";
import validationDate from "./validation";

import { ReactComponent as Attachment } from "shared/assets/images/mainIcons/iconClip/iconClipWithoutStroke.svg";
import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";

import { addDays, format, sub } from "date-fns";
import { fileUrl } from "stores/utils/consts";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

import { Certificate } from "stores/StaffModule/types/SafetyWork";
import { Company } from "stores/StaffModule/types/Company";
import styles from "./staffOneSafetyWorkTableWindow.module.scss";

type StaffOneSafetyWorkTableWindowProps = {
  certificate: { certificate: Certificate; company: Company };
  setOpenWindow: (openWindow: boolean) => void;
};

const StaffOneSafetyWorkTableWindow = ({
  certificate,
  setOpenWindow
}: StaffOneSafetyWorkTableWindowProps) => {
  const { staffOneSafetyWorkStore } = useStores();
  const { id } = useParams<{ id: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openForm, setOpenForm] = useState("detail");
  const [openedListName, setOpenedListName] = useState("");
  const [initialValues, setInitialValues] = useState<Partial<Certificate>>({});
  const [showFields, setShowFields] = useState(
    getValues(staffOneSafetyWorkStore.certsShowFields["detail"])
  );

  useEffect(() => {
    if (openForm === "add") {
      setInitialValues(getInitialValuesForAdd());
    } else {
      setInitialValues(getInitialValues());
    }
  }, [openForm]);

  useEffect(() => {
    switch (openForm) {
      case "detail":
        setShowFields(
          getValues(staffOneSafetyWorkStore.certsShowFields["detail"])
        );
        break;
      case "add":
        setShowFields(
          getValues(staffOneSafetyWorkStore.certsShowFields["add"])
        );
        break;
      case "edit":
        setShowFields(
          getValues(staffOneSafetyWorkStore.certsShowFields["edit"])
        );
        break;
    }
  }, [openForm, staffOneSafetyWorkStore.certsShowFields]);

  const getInitialValues = () => {
    const initialFormValues: Partial<Certificate> = {};

    showFields.forEach((field: string) => {
      switch (field) {
        case "time_create":
        case "kind":
        case "type":
        case "date_start":
        case "date_end":
        case "comment":
        case "company":
        case "user":
          initialFormValues[field] = certificate.certificate[field];
          break;
        default:
          initialFormValues[field] = certificate.certificate[field] || null;
          break;
      }
    });

    initialFormValues["file"] =
      certificate.certificate["file"] !== -1
        ? certificate.certificate["file"]
        : null;

    return initialFormValues;
  };

  const getInitialValuesForAdd = () => {
    const initialFormValues: Partial<Certificate> = {};

    showFields.forEach((field: string) => {
      switch (field) {
        case "time_create":
          initialFormValues[field] = format(new Date(), "yyyy-MM-dd");
          break;
        case "kind":
        case "type":
        case "company":
          initialFormValues[field] = certificate.certificate[field];
          break;
        case "date_start":
        case "date_end":
          initialFormValues[field] = null;
          break;
        case "comment":
          initialFormValues[field] = "";
          break;
        default:
          initialFormValues[field] = null;
          break;
      }
    });

    initialFormValues["file"] = null;

    return initialFormValues;
  };

  const changeOpenedWindows = (name: string) => {
    setOpenedListName(openedListName === name ? "" : name);
  };

  const history = certificate.certificate.history;

  return (
    <div className={styles.background}>
      <div className={styles.modalWindow}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationDate}
          onSubmit={(values) => {
            if (openForm === "add") {
              staffOneSafetyWorkStore.addCertificate(
                id,
                values.kind,
                values.type,
                values.date_start,
                values.date_end,
                values.comment,
                certificate.company.id,
                values.file as File
              );
            }
            if (openForm === "edit") {
              const wasFileInitiallyPresent = initialValues.file !== null;
              const isFileNowNull = values.file === null;

              if (wasFileInitiallyPresent && isFileNowNull) {
                staffOneSafetyWorkStore.deleteFile(
                  certificate.certificate.id,
                  certificate.certificate.uid
                );
              }

              staffOneSafetyWorkStore.updateCertificate(
                certificate.certificate.id,
                values.uid,
                values.kind,
                values.type,
                values.date_start,
                values.date_end,
                values.comment,
                values.company,
                values.file as File
              );
            }
            setOpenForm("detail");
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            isValid,
            dirty
          }) => {
            // Функция для очистки файла
            const handleClearFile = () => {
              setFieldTouched("file");
              setFieldValue("file", null);
            };

            // Функция для обработки изменения файла
            const handleFileChange = (
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldTouched("file");
              const file = event.currentTarget.files?.[0] || null;
              setFieldValue("file", file);

              // Сбрасываем значение поля для возможности выбора того же файла
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            };

            return (
              <div
                className={classNames("", {
                  [styles.btnForDetail]: openForm === "detail"
                })}
              >
                <FormWindow
                  title={
                    openForm === "edit"
                      ? "Редактирование записи"
                      : openForm === "add"
                      ? "Добавление записи"
                      : "Просмотр записи"
                  }
                  setOpenWindow={setOpenWindow}
                  additionalChildren={
                    getValues(history).length > 0 && (
                      <StaffOneSafetyWorkTableWindowHistory
                        history={history}
                        cols={staffOneSafetyWorkStore.certsColsTable}
                      />
                    )
                  }
                  saveBtnTitle={
                    openForm === "add" || openForm === "edit"
                      ? "Сохранить"
                      : values.date_start && values.date_end
                      ? "Редактировать"
                      : ""
                  }
                  saveBtnOnClickFunc={() => {
                    if (openForm === "detail") {
                      setOpenForm("edit");
                    } else {
                      handleSubmit();
                    }
                  }}
                  saveBtnDisabledValue={
                    openForm === "add"
                      ? !isValid ||
                        !dirty ||
                        !values.date_start ||
                        !values.date_end
                      : openForm === "edit"
                      ? !isValid || !dirty
                      : false
                  }
                  addBtnTitle={
                    openForm === "add" || openForm === "edit"
                      ? "Назад"
                      : "Добавить запись"
                  }
                  addBtnOnClickFunc={() => {
                    if (openForm === "detail") {
                      setOpenForm("add");
                    } else {
                      setOpenForm("detail");
                    }
                  }}
                  addBtnImg={
                    openForm !== "detail" ? (
                      <IconLeft className={styles.backIcon} />
                    ) : null
                  }
                  cancelBtnTitle={openForm === "edit" ? "Удалить запись" : ""}
                  cancelBtnImg={<IconClose />}
                  hasBackBtn
                  cancelBtnOnClick={() => {
                    staffOneSafetyWorkStore.deleteСertificate(
                      certificate.certificate.id,
                      certificate.certificate.uid
                    );
                  }}
                  isScroll
                >
                  <Form>
                    <div className={styles.inputs}>
                      {getKeys(values).map((key) => {
                        switch (key) {
                          case "time_create":
                            return (
                              <div
                                className={classNames("", {
                                  [styles.viewForm]:
                                    openForm !== "edit" && openForm !== "add"
                                })}
                                key={key}
                              >
                                <ViewField
                                  title={
                                    staffOneSafetyWorkStore.certsColsTable[key][
                                      "title"
                                    ]
                                  }
                                  value={getFormattedDate(values[key])}
                                  disabled={openForm === "edit"}
                                />
                              </div>
                            );
                          case "kind":
                          case "type":
                            return (
                              <div
                                className={classNames("", {
                                  [styles.viewForm]:
                                    openForm !== "edit" && openForm !== "add"
                                })}
                                key={key}
                              >
                                <ViewField
                                  title={
                                    staffOneSafetyWorkStore.certsColsTable[key][
                                      "title"
                                    ]
                                  }
                                  value={
                                    certificate.certificate[`${key}_title`]
                                  }
                                  disabled={openForm === "edit"}
                                />
                              </div>
                            );
                          case "date_start":
                            return (
                              <React.Fragment key={key}>
                                {openForm === "edit" || openForm === "add" ? (
                                  <div
                                    className={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        "date_end"
                                      ]
                                        ? styles.dateContainer
                                        : ""
                                    }
                                  >
                                    <DatePickerField
                                      name={key}
                                      title={
                                        staffOneSafetyWorkStore.certsColsTable[
                                          key
                                        ]["title"]
                                      }
                                      isCalendarOpened={openedListName === key}
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows(key);
                                      }}
                                      onChange={(startDate) => {
                                        const duration =
                                          staffOneSafetyWorkStore.certsTypeList[
                                            values.type
                                          ].custom.duration;
                                        if (duration < 10000) {
                                          const autoEndDate = addDays(
                                            new Date(startDate),
                                            duration
                                          );
                                          setFieldValue(
                                            "date_end",
                                            format(autoEndDate, "yyyy-MM-dd")
                                          );
                                        }
                                      }}
                                      placement="right"
                                      withClearBtn
                                    />
                                    {staffOneSafetyWorkStore.certsColsTable[
                                      "date_end"
                                    ] && (
                                      <DatePickerField
                                        name="date_end"
                                        title={
                                          staffOneSafetyWorkStore.certsColsTable
                                            .date_end["title"]
                                        }
                                        isCalendarOpened={
                                          openedListName === "date_end"
                                        }
                                        setIsCalendarOpened={() => {
                                          changeOpenedWindows("date_end");
                                        }}
                                        onChange={(endDate) => {
                                          const duration =
                                            staffOneSafetyWorkStore
                                              .certsTypeList[values.type].custom
                                              .duration;
                                          if (duration < 10000) {
                                            const autoStartDate = sub(
                                              new Date(endDate),
                                              {
                                                days: duration
                                              }
                                            );
                                            setFieldValue(
                                              "date_start",
                                              format(
                                                autoStartDate,
                                                "yyyy-MM-dd"
                                              )
                                            );
                                          }
                                        }}
                                        withClearBtn
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        "date_end"
                                      ]
                                        ? `${styles.dateContainer} ${styles.viewForm}`
                                        : styles.viewForm
                                    }
                                  >
                                    <ViewField
                                      title={
                                        staffOneSafetyWorkStore.certsColsTable[
                                          key
                                        ]["title"]
                                      }
                                      value={getFormattedDate(values[key])}
                                    />
                                    {staffOneSafetyWorkStore.certsColsTable[
                                      "date_end"
                                    ] && (
                                      <ViewField
                                        title={
                                          staffOneSafetyWorkStore.certsColsTable
                                            .date_end?.title
                                        }
                                        value={getFormattedDate(
                                          values.date_end
                                        )}
                                      />
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          case "date_end":
                            if (
                              !staffOneSafetyWorkStore.certsColsTable[
                                "date_start"
                              ]
                            ) {
                              return (
                                <React.Fragment key={key}>
                                  {openForm === "edit" || openForm === "add" ? (
                                    <DatePickerField
                                      name={key}
                                      title={
                                        staffOneSafetyWorkStore.certsColsTable[
                                          key
                                        ]["title"]
                                      }
                                      isCalendarOpened={openedListName === key}
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows(key);
                                      }}
                                      placement="left"
                                      withClearBtn
                                    />
                                  ) : (
                                    <div className={styles.viewForm}>
                                      <ViewField
                                        title={
                                          staffOneSafetyWorkStore
                                            .certsColsTable[key]["title"]
                                        }
                                        value={getFormattedDate(values[key])}
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            } else return null;
                          case "comment":
                            return (
                              <div
                                className={classNames("", {
                                  [styles.viewForm]:
                                    openForm !== "edit" && openForm !== "add"
                                })}
                                key={key}
                              >
                                {openForm !== "edit" && openForm !== "add" ? (
                                  <ViewField
                                    value={values[key]}
                                    title={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        key
                                      ]["title"]
                                    }
                                  />
                                ) : (
                                  <Textarea
                                    name={key}
                                    value={values[key] || ""}
                                    label={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        key
                                      ]["title"]
                                    }
                                    onChange={handleChange}
                                    placeholder={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        key
                                      ]["title"]
                                    }
                                    commentCol={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        key
                                      ]
                                    }
                                  />
                                )}
                              </div>
                            );
                          case "company":
                            return (
                              <div
                                className={classNames("", {
                                  [styles.viewForm]:
                                    openForm !== "edit" && openForm !== "add"
                                })}
                                key={key}
                              >
                                <ViewField
                                  value={certificate.company.title}
                                  title={
                                    staffOneSafetyWorkStore.certsColsTable[key][
                                      "title"
                                    ]
                                  }
                                  disabled={openForm === "edit"}
                                />
                              </div>
                            );
                          case "file":
                            return (
                              <React.Fragment key={key}>
                                {openForm !== "edit" &&
                                  openForm !== "add" &&
                                  values.file && (
                                    <div className={styles.fileContainer}>
                                      <div className={styles.displayFlex}>
                                        <Attachment
                                          className={styles.attachment}
                                        />
                                        <div className={styles.displayFileName}>
                                          <div
                                            className={styles.tooltip}
                                            data-tooltip="Нажмите для просмотра файла"
                                          >
                                            <a
                                              href={
                                                typeof values.file === "string"
                                                  ? fileUrl + values.file
                                                  : "#"
                                              }
                                              rel="noreferrer"
                                              target="_blank"
                                            >
                                              {typeof values.file === "string"
                                                ? values.file.slice(16)
                                                : ""}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={classNames(
                                          styles.icons,
                                          {},
                                          [styles.tooltipLeft]
                                        )}
                                        data-tooltip="Скачать изображение"
                                      >
                                        <a
                                          href={
                                            typeof values.file === "string"
                                              ? fileUrl + values.file
                                              : "#"
                                          }
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <StatusIcon
                                            icon="icondownloadwb"
                                            color="bw-gray5"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                {(openForm === "edit" ||
                                  openForm === "add") && (
                                  <div className={styles.editFileInput}>
                                    <div className={styles.displayFlex}>
                                      <input
                                        type="file"
                                        id="file"
                                        accept=".jpg, .jpeg, .png, .gif, .ico, .csv, .xls, .xlsx, .txt, .doc, .docx, .pdf, .cdr, .zip"
                                        name={key}
                                        ref={fileInputRef}
                                        multiple={false}
                                        onChange={handleFileChange}
                                        onBlur={handleBlur}
                                        hidden
                                      />
                                      <label htmlFor="file">
                                        <Attachment
                                          className={styles.attachmentEdit}
                                        />
                                      </label>
                                      <div className={styles.displayFileName}>
                                        {values.file ? (
                                          typeof values.file === "object" ? (
                                            values.file.name.replace(
                                              /^.*\\/,
                                              ""
                                            )
                                          ) : typeof values.file ===
                                            "string" ? (
                                            values.file.slice(16)
                                          ) : (
                                            <div>Прикрепить файл</div>
                                          )
                                        ) : (
                                          <div>Прикрепить файл</div>
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.buttonsContainer}>
                                      {values.file && (
                                        <Button
                                          theme={ButtonTheme.ROUND}
                                          onClick={handleClearFile}
                                          data-tooltip="Очистить поле"
                                          className={styles.tooltipRed}
                                        >
                                          <IconMinus
                                            className={styles.clearBtn}
                                          />
                                        </Button>
                                      )}
                                      {typeof values.file === "string" &&
                                      initialValues.file !== null ? (
                                        <div
                                          className={classNames(
                                            styles.icons,
                                            {},
                                            [styles.tooltipLeft]
                                          )}
                                          data-tooltip="Скачать изображение"
                                        >
                                          <a
                                            href={
                                              typeof values.file === "string"
                                                ? fileUrl + values.file
                                                : "#"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <StatusIcon
                                              icon="icondownloadwb"
                                              color="bw-gray5"
                                            />
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          case "user":
                            return (
                              <div
                                className={classNames("", {
                                  [styles.viewForm]:
                                    openForm !== "edit" && openForm !== "add"
                                })}
                                key={key}
                              >
                                <ViewField
                                  title={
                                    staffOneSafetyWorkStore.certsColsTable[key][
                                      "title"
                                    ]
                                  }
                                  value={values[key]}
                                  disabled={openForm === "edit"}
                                />
                              </div>
                            );
                          case "id":
                          case "uid":
                            return;
                          default:
                            if (staffOneSafetyWorkStore.certsColsTable[key]) {
                              return (
                                <div
                                  className={classNames("", {
                                    [styles.viewForm]:
                                      openForm !== "edit" && openForm !== "add"
                                  })}
                                  key={key}
                                >
                                  <Input
                                    disabled={
                                      openForm !== "edit" && openForm !== "add"
                                    }
                                    name={key}
                                    label={
                                      staffOneSafetyWorkStore.certsColsTable[
                                        key
                                      ]["title"]
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              );
                            } else return;
                        }
                      })}
                    </div>
                  </Form>
                </FormWindow>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default observer(StaffOneSafetyWorkTableWindow);
