import styles from "./measurmentsField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { TextFieldGroup } from "shared/ui/TextFieldGroup";
import { groups } from "stores/AregisterModule/aregisterOne/aregisterOne";

const FIELD_SIZE_ARRAY = ["size"];

type MeasurmentsFieldProps = {
  title: string;
  getValue: (name: string) => string;
};

const MeasurmentsField = ({ title, getValue }: MeasurmentsFieldProps) => {
  const { aregisterOneStore } = useStores();

  if (["length", "width", "height", "size"].includes(title)) return;

  return (
    <div className={styles.block}>
      <p>Размеры</p>
      {groups.sizes.map((field_name) => (
        <TextFieldGroup
          key={field_name}
          name={field_name}
          fields={groups.sizes}
          titles={aregisterOneStore.aregisterTitles}
          cols={aregisterOneStore.cols}
          getValue={getValue}
          className={{ row: styles.rowYellow }}
        />
      ))}

      {!aregisterOneStore.cols.size.hidden ? (
        <TextFieldGroup
          key="size"
          name="size"
          fields={FIELD_SIZE_ARRAY}
          titles={aregisterOneStore.aregisterTitles}
          cols={aregisterOneStore.cols}
          getValue={(name: string) => aregisterOneStore.selectedOne[name]}
        />
      ) : null}
    </div>
  );
};

export default observer(MeasurmentsField);
