import styles from "./staffOnePremiumDetailWorkshift.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import { BarChart, Bar, LabelList, XAxis, Cell, YAxis } from "recharts";
import Scrollbars from "react-custom-scrollbars-2";
import { Props } from "recharts/types/component/Label";
import { useEffect, useRef } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import Legend from "./Legend";
import { IScrollBars } from "stores/utils/types/ScrollBars";
import { months } from "shared/utils/dictionaries/months";

type ElementOfBar = {
  month: number;
  year: number;
  ws_in_month: number;
  ws_in_month_old: number;
  ws_open_month: number;
  old_month: boolean;
  id: string;
};

type PropsForTicks = {
  x: number;
  y: number;
  index: number;
  payload: { value: string | number; coordinate: number };
};

const StaffOnePremiumDetailWorkshift = () => {
  const { staffOnePremiumLevelStore } = useStores();
  const { id } = useParams();

  const scrollbar = useRef<IScrollBars>();

  useEffect(() => {
    !staffOnePremiumLevelStore.isLoadingForDetailWorkshift[id] &&
      scrollbar.current.scrollToRight();
  }, [staffOnePremiumLevelStore.isLoadingForDetailWorkshift[id]]);

  // список координат для отображения годов
  const coordinateForYear: Record<string, number> = {};

  // приводим данные с бэка к необходимому виду для отображения на диаграмме
  const getDataForBar = () => {
    if (
      staffOnePremiumLevelStore.premium_level_detail_workshift[id] &&
      Object.values(
        staffOnePremiumLevelStore.premium_level_detail_workshift[id]
      ).length
    ) {
      const dataForBar: ElementOfBar[] = [];
      let elementOfBar = {} as ElementOfBar;
      Object.values(
        staffOnePremiumLevelStore.premium_level_detail_workshift[id]
      ).forEach((value) => {
        if (value["month"] && Object.values(value["month"]).length) {
          Object.values(value["month"])
            .sort((a, b) => a["month"] - b["month"])
            .forEach((month) => {
              elementOfBar = {} as ElementOfBar;

              // если в массиве dataForBar есть элемент с индификатором "год_месяц", то эту запись не пушим в массив dataForBar
              if (
                !dataForBar
                  .map((element) => element["id"])
                  .includes(`${value["year"]}_${month["month"]}`)
              ) {
                elementOfBar["id"] = `${value["year"]}_${month["month"]}`;
                elementOfBar["year"] = value["year"];
                elementOfBar["month"] = month["month"];
                elementOfBar["old_month"] = month["old_month"];

                if (month["ws_open_month"]) {
                  elementOfBar["ws_open_month"] = month["ws_open_month"];
                }

                if (month["old_month"]) {
                  elementOfBar["ws_in_month_old"] = month["ws_in_month"];
                } else {
                  elementOfBar["ws_in_month"] = month["ws_in_month"];
                }

                dataForBar.push(elementOfBar);
              } else {
                // если в массиве dataForBar есть элемент с индификатором "год_месяц", то дополняем элемент из dataForBar с таким же индификатором "год_месяц"
                // полем ws_in_month_old или ws_in_month в зависимости от поля old_month данной записи
                dataForBar.map((element) => {
                  if (element["id"] === `${value["year"]}_${month["month"]}`) {
                    if (month["old_month"]) {
                      return (element["ws_in_month_old"] =
                        month["ws_in_month"]);
                    } else {
                      return (element["ws_in_month"] = month["ws_in_month"]);
                    }
                  }
                });
              }
            });
        }
      });
      return dataForBar.filter((element) => Object.values(element).length);
    }
  };

  // функция для кастомизации значений месяцев на оси Х
  const CustomizedXaxiStickMonth = (props: PropsForTicks) => {
    const { x, y, payload, index } = props;

    // получаем координаты для каждого года
    getDataForBar().forEach((element, ind) => {
      if (index === ind && !(element["year"] in coordinateForYear)) {
        coordinateForYear[element["year"]] = payload["coordinate"];
      }
    });

    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x="-37"
          y="-7.5"
          width="73.2px"
          height="32px"
          stroke="#e7e7e7"
          fill={!("ws_in_month" in getDataForBar()[index]) ? "#FAFAFA" : "none"}
        />
        <text
          y={12}
          textAnchor="middle"
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fill: !("ws_in_month" in getDataForBar()[index])
              ? "#6E6E6E"
              : "#000000"
          }}
        >
          {months[payload.value]}
        </text>
      </g>
    );
  };

  // функция для кастомизации значений годов на оси Х
  const CustomizedXaxiStickYear = (props: PropsForTicks) => {
    const { y, payload } = props;

    return (
      <g transform={`translate(${coordinateForYear[payload.value] - 37},${y})`}>
        <rect
          y="-7"
          width={`${
            getDataForBar().filter(
              (element) => element["year"] === payload.value
            ).length * 73.1
          }px`}
          height="35px"
          stroke="#e7e7e7"
          fill="none"
        />
        <text
          x={6}
          y={15}
          style={{ fontSize: "14px", fontWeight: "400", fill: "#000000" }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // функция отображения иконок уровней на каждой диаграмме
  const CustomizedLabel = (props: Props & { index?: number }) => {
    const { value, x, y, index } = props;

    return (
      <g
        transform={
          +value > 0
            ? `translate(${+x + 30},${+y + 13})`
            : `translate(${+x + 30},${+y - 5})`
        }
      >
        <text
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fill: !("ws_in_month" in getDataForBar()[index])
              ? "#6E6E6E"
              : "#000000"
          }}
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.loaderContainer}>
      {staffOnePremiumLevelStore.premium_level_detail_workshift[id] &&
      Object.values(
        staffOnePremiumLevelStore.premium_level_detail_workshift[id]
      ).length ? (
        <div className={styles.container}>
          <Legend />
          <LoadedComponent
            isLoading={
              staffOnePremiumLevelStore.isLoadingForDetailWorkshift[id]
            }
            withoutText
          >
            <Scrollbars
              ref={scrollbar}
              style={{ width: "100%" }}
              autoHeight
              autoHeightMax="620px"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className={styles.barchart}>
                <BarChart
                  width={getDataForBar().length * 73 + 72}
                  height={600}
                  data={getDataForBar()}
                  barCategoryGap={1}
                  barSize={72}
                  maxBarSize={72}
                >
                  <Bar
                    dataKey="ws_open_month"
                    stackId="a"
                    barSize={72}
                    maxBarSize={72}
                  >
                    {getDataForBar().map((_entry, index) => (
                      <Cell key={`cell-${index}`} fill="#FFEEED" />
                    ))}
                    <LabelList
                      dataKey="ws_open_month"
                      content={(props) => <CustomizedLabel {...props} />}
                      style={{ fill: "#D41D3C" }}
                    />
                  </Bar>
                  <Bar
                    dataKey="ws_in_month"
                    stackId="a"
                    barSize={72}
                    maxBarSize={72}
                  >
                    {getDataForBar().map((_entry, index) => (
                      <Cell key={`cell-${index}`} fill="#e5f3ff" />
                    ))}
                    <LabelList
                      dataKey="ws_in_month"
                      content={(props) => <CustomizedLabel {...props} />}
                    />
                  </Bar>

                  <Bar
                    dataKey="ws_in_month_old"
                    stackId="a"
                    barSize={72}
                    maxBarSize={72}
                  >
                    {getDataForBar().map((_entry, index) => (
                      <Cell key={`cell-${index}`} fill="#F3F3F3" />
                    ))}
                    <LabelList
                      dataKey="ws_in_month_old"
                      content={(props) => <CustomizedLabel {...props} />}
                    />
                  </Bar>
                  <XAxis
                    xAxisId="0"
                    dataKey="month"
                    tickLine={false}
                    axisLine={{ stroke: "#6E6E6E" }}
                    tick={(props: PropsForTicks) => (
                      <CustomizedXaxiStickMonth {...props} />
                    )}
                    height={32}
                  />
                  <XAxis
                    xAxisId="1"
                    dataKey="year"
                    allowDuplicatedCategory={false}
                    tickLine={false}
                    axisLine={false}
                    tick={(props: PropsForTicks) => (
                      <CustomizedXaxiStickYear {...props} />
                    )}
                    height={40}
                  />
                  <YAxis type="number" tickLine={false} axisLine={false} />
                </BarChart>
              </div>
            </Scrollbars>
          </LoadedComponent>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOnePremiumDetailWorkshift);
