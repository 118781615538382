import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { DropResult } from "react-beautiful-dnd";
import { Company } from "stores/StaffModule/types/Company";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { PresaveStaff } from "./types/PresaveStaff";
import { cloneDeep } from "lodash";
import { parse } from "date-fns";
import { AregisterSelects } from "./types/AregisterSelects";

type SelectedOptions = {
  date_start: string;
  date_end: string;
  project_id: string;
  masterId?: string;
  brigadierId?: string;
};

type Master = {
  id: string;
  title: string;
  fio: string;
  tn: number;
  has_presave: boolean;
};

type Project = {
  id: string;
  title: string;
  company_list: {
    [key: string]: string;
  };
};

type Aregister = {
  [id: string]: {
    id: string;
    date_start: string;
    date_end: string;
    title: string;
    status: string;
    master: string;
    brigadier: string;
    master_fio: string;
    brigadier_fio: string;
  };
};

type Presave = {
  time_create: string;
  id: string;
  date_active: string;
  aregister: string;
  title: string | number;
  presave_ws?: number;
};
export default class AregisterReportStore {
  error = false;
  errorMessage = "";
  errorMessageForMasters: Errors["message"] | { [key: string]: string } = {};
  isLoading = false;
  isLoadingForTable = false;

  isShowMasters = false;
  isShowTable = false;
  isShowSubValues = false;

  projectsList: { [key: string]: Partial<Project> } = {};
  aregisterList: Aregister = {};
  presaveList: Presave[] | -1 = [];
  presaveStaffHours: { [key: string]: number } = {};
  presaveStaffList: PresaveStaff[] = [];
  companiesList: { [id: string]: Omit<Company, "employments"> } = {};
  filteredAregisterList: Aregister = {};
  filteredPresaveList: Presave[] = [];
  presaveTempNums: { [key: string]: string | number } = {};
  closedAregister: boolean;
  canceledAregister: boolean;

  aregisterTitlesList: {
    [id: string]: {
      id: string;
      title: string;
    };
  } = {};

  brigadiersList: {
    [id: string]: {
      id: string;
      title: string;
    };
  } = {};

  mastersList: {
    [key: string]: Master;
  } = {};

  brigadiersOfMaster: {
    id: string;
    title: string;
  }[];

  selectedOptions: SelectedOptions = {
    date_start: null,
    date_end: null,
    project_id: "",
    masterId: "",
    brigadierId: ""
  };

  aregisterStatus: AregisterSelects["status"] = {};

  staffForAdding: { [key: string]: PresaveStaff } = {};
  totalSum = 0;
  temporary_num: { [key: string]: string } = {};
  companiesQuantity = 0; //количество компаний (строк) для массового заполнения часов

  searchValue = "";

  handleDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (type === "group") {
      const reorderedStores = [...this.presaveStaffList];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const [removedStore] = reorderedStores.splice(sourceIndex, 1);
      reorderedStores.splice(destinationIndex, 0, removedStore);
      this.updateListItems(reorderedStores);
    }
  };

  getAregisterStatus = async () => {
    try {
      const data: ApiResponse<AregisterSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getSelects",
          params: { module: "aregister" }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.aregisterStatus = data.result.status;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  //  получаем список проектов
  getProjects = async () => {
    try {
      const data: ApiResponse<{
        [key: string]: Project;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getProjects",
        params: {
          module: "aregister"
        }
      });

      runInAction(() => {
        if (!data["message"]) {
          this.projectsList = data["result"];
        } else this.errorMessageForMasters = data["message"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получаем список мастеров (id проекта, даты)
  getPresaveMasters = async (
    project: string,
    date_start: string,
    date_end: string
  ) => {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const data: ApiResponse<{
        [key: string]: Master;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPresaveMasters",
        params: {
          project,
          date_start,
          date_end
        }
      });

      runInAction(() => {
        if (!data["message"]) {
          const oneMasterObj: { [key: string]: Master } = {};
          Object.values(data["result"]).forEach((master) => {
            oneMasterObj[master.id] = {
              title: master.fio,
              ...master
            };
            this.mastersList = oneMasterObj;
          });
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  // Получить список заявок на даты, по мастеру и проекту.
  // Создаем список бригадиров по мастеру
  getPresaveAvailableAregisters = async (
    project: string,
    date_start: string,
    date_end: string,
    master: string
  ) => {
    this.isLoadingForTable = true;
    try {
      const data: ApiResponse<Aregister> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getPresaveAvailableAregisters",
          params: {
            project,
            date_start,
            date_end,
            master
          }
        });

      runInAction(() => {
        if (!data["message"]) {
          this.aregisterList = data["result"];
          Object.values(data["result"]).forEach((aregister) => {
            this.aregisterTitlesList[aregister.id] = {
              id: aregister.id,
              title: aregister.title
            };
            if (aregister.brigadier !== null) {
              this.brigadiersList[aregister.brigadier] = {
                id: aregister.brigadier,
                title: aregister.brigadier_fio
              };
            }
          });
          this.filteredAregisterList = cloneDeep(this.aregisterList);
        } else {
          this.errorMessageForMasters = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForTable = false));
    }
  };

  // функция отдает список бригадиров по конкретному мастеру
  getBrigadiersFromMaster = (master: string) => {
    const brigadiersOfMaster: { id: string; title: string }[] = [];
    Object.values(this.aregisterList).forEach((aregister) => {
      if (
        aregister.brigadier_fio !== null &&
        master === aregister.master &&
        !brigadiersOfMaster.some((elem) => elem.id === aregister.brigadier)
      ) {
        brigadiersOfMaster.push({
          id: aregister.brigadier,
          title: aregister.brigadier_fio ? aregister.brigadier_fio : "Пустые"
        });
      }
    });
    this.brigadiersOfMaster = brigadiersOfMaster;
  };

  // Получить почасовки, и сотрудников из них(основная таблица)
  // + сумма часов из смен по сотруднику на диапазон дат
  getPresaveTable = async (
    project: string,
    date_start: string,
    date_end: string,
    master: string
  ) => {
    try {
      const data: ApiResponse<{
        presave_list: { [key: string]: Presave } | -1;
        presave_staff_list: {
          [key: string]: PresaveStaff;
        };
        presave_staff_hours: { [key: string]: number };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPresaveTable",
        params: {
          project,
          date_start,
          date_end,
          master
        }
      });

      runInAction(() => {
        if (data["result"]["presave_list"] !== -1) {
          this.presaveStaffHours = data["result"]["presave_staff_hours"];
          this.presaveStaffList = Object.values(
            data["result"]["presave_staff_list"]
          );
          this.presaveList = [
            ...Object.values(data["result"]["presave_list"])
          ].sort(
            (a, b) =>
              parse(
                b["time_create"],
                "dd.MM.yyyy HH:mm:ss",
                new Date()
              ).valueOf() -
              parse(
                a["time_create"],
                "dd.MM.yyyy HH:mm:ss",
                new Date()
              ).valueOf()
          );

          const presaveTempNums: { [key: string]: string | number } = {};
          this.presaveList.forEach((presave) => {
            presaveTempNums[`temporary_num_${presave.id}`] = presave.title;
          });
          this.presaveTempNums = presaveTempNums;

          this.filteredPresaveList = cloneDeep(this.presaveList);
          let quantity = 0;
          this.presaveStaffList.forEach((staff) => {
            quantity += Object.values(staff.company_ws).length;
          });
          this.companiesQuantity = quantity;
        } else {
          this.createPresave(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
          this.presaveStaffHours = {};
          this.presaveStaffList = [];
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
        this.getWorkHoursSumChanged();
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // Получить доступных сотрудников для почасовок
  getStaffForPresave = async (
    project: string,
    date_start: string,
    date_end: string
  ) => {
    try {
      const data: ApiResponse<{
        [key: string]: {
          uid: string;
          fio: string;
          tn: number;
          company_ws: { [key: string]: number };
        };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getStaffForPresave",
        params: {
          project,
          date_start,
          date_end
        }
      });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          const staffForAdding: { [key: string]: PresaveStaff } = {};
          Object.values(data["result"]).forEach((staff) => {
            {
              staffForAdding[staff.uid] = {
                id: staff.uid,
                title: `ТН${staff.tn} ${staff.fio}`,
                ...staff
              };
            }
            this.staffForAdding = staffForAdding;
          });
        } else this.errorMessageForMasters = data["message"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // функция фильтрует почасовки по конкретному бригадиру
  getBrigadierPresaveList = (id: string) => {
    this.filteredAregisterList = Object.fromEntries(
      Object.entries(this.aregisterList).filter(
        ([, aregister]) => aregister.brigadier === id || !id
      )
    );
    this.filteredPresaveList =
      this.presaveList !== -1 &&
      this.presaveList.filter(
        (presave) =>
          this.filteredAregisterList[presave.aregister] !== undefined ||
          presave.aregister === null
      );
    this.getPresaveWorkHoursSum();
  };

  getPresaveSelects = async () => {
    try {
      const data: ApiResponse<{
        company_list: { [id: string]: Omit<Company, "employments"> };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPresaveSelects",
        params: {
          module: "aregister"
        }
      });

      runInAction(() => {
        if (!data["message"]) {
          this.companiesList = data["result"]["company_list"];
        } else this.errorMessageForMasters = data["message"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // Получить сумму часов из почасовки
  getPresaveWorkHoursSum = () => {
    this.filteredPresaveList.forEach((presave) => {
      let presave_ws = 0;
      Object.entries(this.presaveStaffHours).forEach(([id, hours]) => {
        if (id.startsWith(presave.id)) {
          presave_ws += hours;
        }
      });
      presave.presave_ws = presave_ws;
    });
  };

  // Получить сумму часов сотрудника
  getStaffWorkHoursSum = () => {
    Object.values(this.presaveStaffList).forEach((staff) => {
      let work_hours_sum = 0;
      Object.entries(this.presaveStaffHours).forEach(([key, hours]) => {
        const ids = key.split("_");
        if (ids[1] === staff.uid) {
          work_hours_sum += hours;
        }
      });
      staff.work_hours_sum = work_hours_sum;
    });
  };

  // Получить общую сумму часов
  getTotalWorkHoursSum = () => {
    let total_sum = 0;
    this.presaveStaffList.forEach((staff) => {
      total_sum += staff.work_hours_sum;
    });
    this.totalSum = total_sum;
  };

  // Получить изменения сумм часов на фронте при добавлении или изменении часов сотрудника
  getWorkHoursSumChanged = (hourId?: string, num?: number) => {
    hourId && num !== undefined && (this.presaveStaffHours[hourId] = num);
    this.getPresaveWorkHoursSum();
    this.getStaffWorkHoursSum();
    this.getTotalWorkHoursSum();
  };

  // Добавление сотрудников в таблицу
  addStaffRow = (staffIds: string[]) => {
    const row: PresaveStaff[] = [];
    staffIds.forEach((id) => {
      row.push(this.staffForAdding[id]);
    });
    this.presaveStaffList = [...this.presaveStaffList, ...row];
  };

  getData = () => {
    this.isLoading = true;
    Promise.all([this.getProjects(), this.getPresaveSelects()]).then(() => {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    });
  };

  getMastersData = async () => {
    this.brigadiersOfMaster = [];
    this.isLoading = true;
    Promise.all([
      this.getBrigadierPresaveList(""),
      this.getAregisterStatus(),
      this.getPresaveAvailableAregisters(
        this.selectedOptions.project_id,
        this.selectedOptions.date_start,
        this.selectedOptions.date_end,
        this.selectedOptions.masterId
      ),
      // сначала проверяем есть ли presaveStaffList для таблицы
      this.getPresaveTable(
        this.selectedOptions.project_id,
        this.selectedOptions.date_start,
        this.selectedOptions.date_end,
        this.selectedOptions.masterId
      ),
      this.getStaffForPresave(
        this.selectedOptions.project_id,
        this.selectedOptions.date_start,
        this.selectedOptions.date_end
      )
    ]).then(() => {
      // потом собираем бригадиров по мастеру только если presaveStaffList не пустой
      runInAction(() => {
        if (
          this.presaveStaffList.length &&
          Object.keys(this.presaveStaffHours).length
        ) {
          this.getBrigadiersFromMaster(this.selectedOptions.masterId);
        }
      });
    });
    this.isLoading = false;
  };

  resetAll = () => {
    this.error = false;
    this.errorMessage = "";
    this.errorMessageForMasters = {};
    this.isLoading = false;
    this.isLoadingForTable = false;
    this.searchValue = "";
    this.aregisterList = {};
    this.presaveList = [];
    this.presaveStaffHours = {};
    this.presaveStaffList = [];
    this.filteredAregisterList = {};
    this.filteredPresaveList = [];
    this.brigadiersOfMaster = [];
  };

  deletePresave = async (presave: string) => {
    const formData = {
      module: "aregister",
      presave: presave
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "deletePresave",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  createPresave = async (
    project: string,
    date_start: string,
    date_end: string,
    master: string,
    num?: number
  ) => {
    const formData = {
      project,
      date_start,
      date_end,
      master,
      num
    };
    try {
      const data: ApiResponse<{ [key: string]: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "aregister",
          method: "createPresave",
          body: formData
        });

      runInAction(() => {
        if (data["result"] && !data["message"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  duplicatePresave = async (presave: string) => {
    const formData = {
      module: "aregister",
      presave
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "duplicatePresave",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  deletePresaveStaff = async (
    project: string,
    date_start: string,
    date_end: string,
    master: string,
    uid: string
  ) => {
    const formData = {
      project,
      date_start,
      date_end,
      master,
      uid
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "deletePresaveStaff",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  changePresaveHours = async (
    presave: string,
    uid: string,
    company: string,
    hours: number
  ) => {
    const formData = {
      module: "aregister",
      presave,
      uid,
      company,
      hours
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "changePresaveHours",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  changePresaveHoursMass = async (
    presave: string,
    hours: number,
    staffIds: string[]
  ) => {
    const formData = {
      module: "aregister",
      presave,
      hours
    };
    staffIds.forEach((item) => {
      const uid = item.split("_")[0];
      const company = item.split("_")[1];
      formData[`staff[${uid}][]`] = company;
    });

    try {
      const data: ApiResponse<{
        [key: string]: {
          [key: string]: {
            result: boolean;
            presave_staff_id?: string;
            message?: Omit<Errors["message"], "body">;
          };
        };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "changePresaveHoursMass",
        body: formData
      });

      runInAction(() => {
        if (!data["message"]) {
          this.getMastersData();
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  unpinPresave = async (presave: string) => {
    const formData = {
      module: "aregister",
      presave
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "unpinPresave",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  pinPresave = async (presave: string, aregister: string) => {
    const formData = {
      module: "aregister",
      presave,
      aregister
    };
    try {
      const data: ApiResponse<boolean> & { master_id: string } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "aregister",
          method: "pinPresave",
          body: formData
        });

      runInAction(() => {
        if (!data["message"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  updatePresave = async (
    presave: string,
    date_active?: string,
    title?: string
  ) => {
    const formData = {
      module: "aregister",
      presave,
      date_active,
      title
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "aregister",
        method: "updatePresave",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPresaveTable(
            this.selectedOptions.project_id,
            this.selectedOptions.date_start,
            this.selectedOptions.date_end,
            this.selectedOptions.masterId
          );
        } else {
          this.errorMessageForMasters = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setSelectedOptions = (options: Partial<SelectedOptions>) => {
    for (const key in options) {
      this.selectedOptions[key] = options[key];
    }
  };

  setIsShowMasters = (value: boolean) => {
    this.isShowMasters = value;
  };

  setBrigadiers = (
    brigadiersOfMaster: {
      id: string;
      title: string;
    }[]
  ) => {
    runInAction(() => {
      this.brigadiersOfMaster = brigadiersOfMaster;
    });
  };

  setIsShowTable = (value: boolean) => {
    this.isShowTable = value;
  };

  setIsShowSubValues = (value: boolean) => {
    this.isShowSubValues = value;
  };

  updateListItems = (value: PresaveStaff[]) => {
    this.presaveStaffList = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
