import styles from "./aregisterOneMasscopyFormTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import { Table } from "react-bootstrap";
import Select from "shared/ui/Inputs/Select";
import { Input } from "shared/ui/Inputs/Input";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Switch from "shared/ui/Inputs/Switch";
import Tooltip from "shared/ui/Tooltip";

import AregisterOneMasscopyFormAddBlock from "../AregisterOneMasscopyFormAddBlock";
import AregisterOneMasscopyFormFormula from "../AregisterOneMasscopyFormFormula";
import AregisterOneMasscopyFormSendBtn from "../AregisterOneMasscopyFormSendBtn";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconDash } from "shared/assets/images/iconStatus/Dash.svg";

import { parse } from "date-fns";

import { Application } from "stores/AregisterModule/types/Application";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type AregisterOneMasscopyFormTableProps = {
  values: {
    reload: number;
    original: Partial<Application>;
    copies: Partial<Application>[];
    massfill: Partial<Application>;
  };
  isOpenedMassFill: boolean;
  setIsOpenedMassFill: (arg: boolean) => void;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
  highlightIndex: number;
  setHighlightIndex: (index: number) => void;
  focusIndex: number;
  setFocusIndex: (index: number) => void;
  focusRowIndex: number;
  setFocusRowIndex: (index: number) => void;
  changeFocusIndex: () => void;
  focusedRef: React.MutableRefObject<HTMLDivElement>;
  checkCustomFields: () => boolean;
  editedCell: string;
  setEditedCell: (name: string) => void;
  handleEscPress: (name: string, secondName?: string) => void;
};

const AregisterOneMasscopyFormTable = ({
  values,
  isOpenedMassFill,
  setIsOpenedMassFill,
  openedListName,
  changeOpenedListName,
  highlightIndex,
  setHighlightIndex,
  focusIndex,
  setFocusIndex,
  focusRowIndex,
  setFocusRowIndex,
  changeFocusIndex,
  checkCustomFields,
  focusedRef,
  editedCell,
  setEditedCell,
  handleEscPress
}: AregisterOneMasscopyFormTableProps) => {
  const { menuStore, aregisterAppcreateStore } = useStores();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { id } = useParams();

  return (
    <div>
      <Table className={styles.table}>
        <thead
          className={`${styles.titles} ${
            menuStore.isScroll ? styles.shadow : ""
          }`}
        >
          <tr>
            {Object.keys(values.original).map((title, i) => {
              return (
                <Fragment key={title}>
                  {i === 0 ? (
                    <th
                      className={`${styles.firstCol} ${
                        styles.firstCol__firstCell
                      } ${
                        menuStore.scroll["scrollLeft"]
                          ? styles.firstCol_shadow
                          : ""
                      } ${highlightIndex === -1 ? styles.highlighted : ""}`}
                      onMouseEnter={() => {
                        setHighlightIndex(-1);
                      }}
                      onMouseLeave={() => {
                        setHighlightIndex(null);
                      }}
                    >
                      <div> </div>
                    </th>
                  ) : null}
                  {(() => {
                    const field = aregisterAppcreateStore.cols[title];
                    const param = aregisterAppcreateStore.params[title];

                    if (
                      [
                        "length",
                        "width",
                        "height",
                        "size",
                        "manual_size",
                        "conditional_cubes",
                        "no_kc",
                        "allow_manual_hours",
                        "allow_manual_size"
                      ].includes(title)
                    )
                      return;

                    if (title === "manual_hours") {
                      return values.original?.project &&
                        aregisterAppcreateStore.projects[
                          values.original.project
                        ]?.allow_manual_hours === 1 ? (
                        <th
                          key={`${title}_title`}
                          onMouseEnter={() => {
                            setHighlightIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightIndex(null);
                          }}
                          className={
                            highlightIndex === i || focusIndex === i
                              ? styles.highlighted
                              : ""
                          }
                        >
                          <div className={styles.titles__oneTitle}>
                            {field?.title}
                          </div>
                        </th>
                      ) : null;
                    }

                    return field?.title && title !== "custom_fields" ? (
                      <th
                        key={`${title}_title`}
                        onMouseEnter={() => {
                          setHighlightIndex(i);
                        }}
                        onMouseLeave={() => {
                          setHighlightIndex(null);
                        }}
                        className={
                          highlightIndex === i || focusIndex === i
                            ? styles.highlighted
                            : ""
                        }
                      >
                        <div
                          className={`${styles.titles__oneTitle} ${
                            param?.required
                              ? styles.titles__oneTitle_required
                              : ""
                          } ${title === "kc" ? styles.title__oneTitle_kc : ""}`}
                        >
                          {field?.title}
                        </div>
                      </th>
                    ) : null;
                  })()}
                </Fragment>
              );
            })}
            {checkCustomFields()
              ? Object.keys(
                  aregisterAppcreateStore.projects[values.original.project]
                    .custom.custom_fields
                ).map((customTitle, i) => {
                  return (
                    <th
                      key={`${customTitle}_title`}
                      onMouseEnter={() => {
                        setHighlightIndex(i + 100);
                      }}
                      onMouseLeave={() => {
                        setHighlightIndex(null);
                      }}
                      className={
                        highlightIndex === i + 100 || focusIndex === i + 100
                          ? styles.highlighted
                          : ""
                      }
                    >
                      <Tooltip
                        key={`${customTitle}_title`}
                        text="Дополнительное поле"
                        placement="bottom-end"
                        withoutFlip
                      >
                        <div
                          className={`${styles.titles__oneTitle} ${styles.titles__oneTitle_required} ${styles.titles__oneTitle_custom}`}
                        >
                          {customTitle}
                        </div>
                      </Tooltip>
                    </th>
                  );
                })
              : null}
            <th className={styles.empty}></th>
          </tr>
        </thead>
        <tbody>
          {/* Оригинальная заявка */}
          <tr className={styles.original}>
            {Object.entries(values.original).map(([key, value], i) => {
              const field = aregisterAppcreateStore.cols[key];
              const param = aregisterAppcreateStore.params[key];
              return field?.title ? (
                <Fragment key={key}>
                  {i === 0 ? (
                    <td
                      className={`${styles.firstCol} ${
                        menuStore.scroll["scrollLeft"]
                          ? styles.firstCol_shadow
                          : ""
                      } ${
                        highlightIndex === -1 || focusIndex === i
                          ? styles.highlighted
                          : ""
                      }`}
                      onMouseEnter={() => {
                        setHighlightIndex(-1);
                      }}
                      onMouseLeave={() => {
                        setHighlightIndex(null);
                      }}
                    >
                      <AregisterOneMasscopyFormAddBlock
                        copies={values.copies}
                        isOpenedMassFill={isOpenedMassFill}
                        setIsOpenedMassFill={setIsOpenedMassFill}
                      />
                    </td>
                  ) : null}

                  {(() => {
                    switch (key) {
                      case "project":
                        return (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell} ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div className={styles.original__cell__value}>
                              {aregisterAppcreateStore.projects[value as string]
                                ?.title || ""}
                            </div>
                          </td>
                        );
                      case "master":
                      case "brigadier":
                        return value && Object.values(value).length ? (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell} ${
                              styles.link
                            } ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div className={styles.original__cell__value}>
                              {value === "masterless" ? (
                                "Без мастера"
                              ) : value["fio"] ? (
                                <>
                                  <div>{`TH${value["tn"]}`}</div>
                                  <Link
                                    to={`/staff/id=${value["id"]}`}
                                    id={`AregisterOneMasscopyStaff_${value["id"]}`}
                                    target="_blank"
                                  >
                                    {value["fio"]}
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        ) : (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell} ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div className={styles.original__cell__value}>
                              {value as string}
                            </div>
                          </td>
                        );

                      case "measurements":
                        return (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell} ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div className={styles.original__cell__value}>
                              <AregisterOneMasscopyFormFormula
                                values={values.original}
                                type="original"
                              />
                            </div>
                          </td>
                        );

                      case "manual_hours":
                        return values.original.project &&
                          aregisterAppcreateStore.projects[
                            values.original.project
                          ]?.allow_manual_hours === 1 ? (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell_manualHours} ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div
                              className={
                                styles.original__cell_manualHours__value
                              }
                            >
                              <div
                                className={
                                  styles.original__cell_manualHours__value__title
                                }
                              >
                                {field.title}
                              </div>
                              <div
                                className={
                                  styles.original__cell_manualHours__value__text
                                }
                              >
                                {values.original.manual_hours ? (
                                  values.original.manual_hours
                                ) : (
                                  <IconDash className={styles.iconDash} />
                                )}
                              </div>
                            </div>
                          </td>
                        ) : null;

                      case "manual_size":
                      case "allow_manual_size":
                      case "allow_manual_hours":
                      case "length":
                      case "width":
                      case "height":
                      case "size":
                      case "conditional_cubes":
                      case "custom_fields":
                      case "no_kc":
                        return;

                      default:
                        if (
                          param?.isvariable ||
                          (param?.variable &&
                            Object.values(param.variable).length)
                        ) {
                          return (
                            <td
                              key={`${key}_field`}
                              className={`${styles.original__cell} ${
                                highlightIndex === i || focusIndex === i
                                  ? styles.highlighted
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightIndex(null);
                              }}
                            >
                              <div className={styles.original__cell__value}>
                                {param.variable[value as string]?.title || ""}
                              </div>
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`${key}_field`}
                            className={`${styles.original__cell} ${
                              highlightIndex === i || focusIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightIndex(null);
                            }}
                          >
                            <div className={styles.original__cell__value}>
                              {field.type === "date"
                                ? getFormattedDate(value as string)
                                : (value as string)}
                            </div>
                          </td>
                        );
                    }
                  })()}
                </Fragment>
              ) : null;
            })}
            {checkCustomFields()
              ? Object.entries(
                  aregisterAppcreateStore.projects[values.original.project]
                    .custom.custom_fields
                ).map(([customTitle, customField], i) => {
                  return (
                    <td
                      key={`${customTitle}_field`}
                      className={`${styles.original__cell} ${
                        highlightIndex === i || focusIndex === i + 100
                          ? styles.highlighted
                          : ""
                      }`}
                      onMouseEnter={() => {
                        setHighlightIndex(i);
                      }}
                      onMouseLeave={() => {
                        setHighlightIndex(null);
                      }}
                    >
                      <div className={styles.original__cell__value}>
                        {values.original?.custom_fields?.[customTitle] &&
                        customField.type === "date"
                          ? getFormattedDate(
                              values.original.custom_fields[
                                customTitle
                              ] as string
                            )
                          : values.original?.custom_fields?.[customTitle] || ""}
                      </div>
                    </td>
                  );
                })
              : null}
            <td className={styles.empty}></td>
          </tr>

          {/* Массовое заполнения */}
          {isOpenedMassFill ? (
            <tr>
              {Object.entries(values.massfill).map(([key, value], i) => {
                const field = aregisterAppcreateStore.cols[key];
                const param = aregisterAppcreateStore.params[key];
                return field?.title ? (
                  <Fragment key={key}>
                    {i === 0 ? (
                      <td
                        className={`${styles.massfill} ${styles.firstCol} ${
                          menuStore.scroll["scrollLeft"]
                            ? styles.firstCol_shadow
                            : ""
                        } ${
                          highlightIndex === -1 || focusRowIndex === -1
                            ? styles.highlighted
                            : ""
                        }`}
                        onMouseEnter={() => {
                          setHighlightIndex(-1);
                        }}
                        onMouseLeave={() => {
                          setHighlightIndex(null);
                        }}
                      >
                        <div className={styles.massfill__title}>
                          Массовое заполнение
                        </div>
                      </td>
                    ) : null}
                    {(() => {
                      const addMassfill = (key: string) => {
                        values.copies.forEach((_copy, numeric) => {
                          if (key === "date_start") {
                            (values.copies[numeric]["date_end"] &&
                              parse(
                                values.massfill[key] as string,
                                "yyyy-MM-dd",
                                new Date()
                              ) >
                                parse(
                                  values.copies[numeric]["date_end"] as string,
                                  "yyyy-MM-dd",
                                  new Date()
                                )) ||
                            !values.copies[numeric]["date_end"]
                              ? setFieldValue(
                                  `copies[${numeric}]["date_end"]`,
                                  values.massfill[key]
                                )
                              : "";
                          }

                          if (key === "kc") {
                            setFieldValue(
                              `copies[${numeric}][no_kc]`,
                              values.massfill.no_kc
                            );
                          }

                          if (key === "manual_hours") {
                            setFieldValue(
                              `copies[${numeric}][allow_manual_hours]`,
                              values.massfill.allow_manual_hours
                            );
                          }

                          setFieldValue(
                            `copies[${numeric}][${key}]`,
                            values.massfill[key]
                          );
                        });

                        changeFocusIndex();
                        setFieldTouched(`massfill[${key}]`);
                      };

                      switch (key) {
                        case "project":
                        case "measurements":
                        case "internal_num":
                        case "master":
                        case "author":
                        case "brigadier":
                          return (
                            <td
                              key={`${key}_field`}
                              className={`${styles.field} ${
                                highlightIndex === i ||
                                focusIndex === i ||
                                focusRowIndex === -1
                                  ? styles.highlighted
                                  : ""
                              }`}
                            ></td>
                          );
                        case "manual_hours":
                          return values.massfill.project &&
                            aregisterAppcreateStore.projects[
                              values.massfill.project
                            ]?.allow_manual_hours === 1 ? (
                            <td
                              key={`${key}_field`}
                              className={`${styles.field_manualHours} ${
                                highlightIndex === i ||
                                focusIndex === i ||
                                focusRowIndex === -1
                                  ? styles.highlighted
                                  : ""
                              } ${
                                focusIndex === i && focusRowIndex === -1
                                  ? styles.focused
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightIndex(null);
                              }}
                            >
                              <div
                                className={
                                  styles.field_manualHours__fieldContainer
                                }
                              >
                                <div
                                  id="AregisterOneMasscopyFormTable_massfill[allow_manual_hours]"
                                  onClick={() => {
                                    setFieldValue(
                                      "massfill[allow_manual_hours]",
                                      values.massfill.allow_manual_hours ? 0 : 1
                                    );
                                    setFieldValue(`massfill[${key}]`, null);
                                  }}
                                  className={
                                    styles.field_manualHours__fieldContainer__title
                                  }
                                >
                                  <div>
                                    {
                                      aregisterAppcreateStore.cols.manual_hours
                                        .title
                                    }
                                  </div>
                                  <Switch name="massfill[allow_manual_hours]" />
                                </div>
                                <div
                                  className={styles.field_manualHours_massfill}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(i);
                                    setFocusRowIndex(-1);
                                  }}
                                  id={`AregisterOneMasscopyFormTable__massfill[${key}]`}
                                >
                                  <div
                                    className={
                                      styles.field_manualHours_massfill__fieldContainer
                                    }
                                  >
                                    <Input
                                      name={`massfill[${key}]`}
                                      disabled={
                                        !values["massfill"][
                                          "allow_manual_hours"
                                        ]
                                      }
                                      autocomplete="off"
                                      createRef
                                      onClick={() => {
                                        setEditedCell(`massfill[${key}]`);
                                      }}
                                      onKeyDown={(e: { keyCode: number }) => {
                                        if (
                                          editedCell === `massfill[${key}]` &&
                                          e.keyCode === 27
                                        ) {
                                          handleEscPress(key);
                                        }
                                      }}
                                    />
                                    <AregisterOneMasscopyFormSendBtn
                                      name={`massfill[${key}]_sendBtnRow`}
                                      onClick={() => {
                                        addMassfill(key);
                                      }}
                                      tableType="row"
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          ) : null;

                        case "kc":
                          return (
                            <td
                              key={`${key}_field`}
                              className={`${styles.field_kc_massfill} ${
                                highlightIndex === i ||
                                focusIndex === i ||
                                focusRowIndex === -1
                                  ? styles.highlighted
                                  : ""
                              } ${
                                focusIndex === i && focusRowIndex === -1
                                  ? styles.focused
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightIndex(null);
                              }}
                            >
                              <div
                                className={
                                  styles.field_kc_massfill__fieldContainer
                                }
                              >
                                <div
                                  className={
                                    styles.field_kc_massfill__fieldContainer__title
                                  }
                                >
                                  <div
                                    id={`AregisterOneMasscopyFormTable_massfill[${key}]`}
                                    ref={focusedRef}
                                    onClick={() => {
                                      setFocusIndex(i);
                                      setFocusRowIndex(-1);
                                    }}
                                  >
                                    <Input
                                      name={`massfill[${key}]`}
                                      disabled={Boolean(values.massfill.no_kc)}
                                      autocomplete="off"
                                      createRef
                                      onClick={() => {
                                        setEditedCell(`massfill[${key}]`);
                                      }}
                                      onKeyDown={(e: { keyCode: number }) => {
                                        if (
                                          editedCell === `massfill[${key}]` &&
                                          e.keyCode === 27
                                        ) {
                                          handleEscPress(key);
                                        }
                                      }}
                                    />
                                  </div>
                                  <label>
                                    <Switch
                                      name={`massfill[no_kc]`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `massfill[${key}]`,
                                          e.target.checked
                                            ? null
                                            : values.original[key]
                                        );
                                      }}
                                    />
                                    Не в КС
                                  </label>
                                </div>
                                <AregisterOneMasscopyFormSendBtn
                                  name={`massfill[${key}]_sendBtnRow`}
                                  onClick={() => {
                                    addMassfill(key);
                                  }}
                                  tableType="row"
                                />
                              </div>
                            </td>
                          );
                        case "length":
                        case "width":
                        case "height":
                        case "size":
                        case "conditional_cubes":
                        case "custom_fields":
                        case "manual_size":
                        case "allow_manual_size":
                        case "allow_manual_hours":
                        case "no_kc":
                          return;
                        default:
                          if (
                            param?.isvariable ||
                            (param?.variable &&
                              Object.values(param.variable).length)
                          ) {
                            return (
                              <td
                                key={`${key}_field`}
                                className={`${styles.field_select_massfill} ${
                                  highlightIndex === i ||
                                  focusIndex === i ||
                                  focusRowIndex === -1
                                    ? styles.highlighted
                                    : ""
                                } ${
                                  focusIndex === i && focusRowIndex === -1
                                    ? styles.focused
                                    : ""
                                }`}
                                onMouseEnter={() => {
                                  setHighlightIndex(i);
                                }}
                                onMouseLeave={() => {
                                  setHighlightIndex(null);
                                }}
                                onClick={() => {
                                  setEditedCell(`massfill[${key}]`);
                                }}
                                id={`AregisterOneMasscopyFormTable__massfill[${key}]_cell`}
                                onKeyDown={(e: { keyCode: number }) => {
                                  if (
                                    editedCell === `massfill[${key}]` &&
                                    e.keyCode === 27
                                  ) {
                                    handleEscPress(key);
                                  }
                                }}
                              >
                                <div
                                  id={`AregisterOneMasscopyFormTable_massfill[${key}]`}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(i);
                                    setFocusRowIndex(-1);
                                  }}
                                  className={
                                    styles.field_select_massfill__fieldContainer
                                  }
                                >
                                  <Select
                                    options={aregisterAppcreateStore.setDirectory(
                                      values.massfill.project as string,
                                      key
                                    )}
                                    name={`massfill[${key}]`}
                                    label={
                                      param.variable[value as string]?.title ||
                                      ""
                                    }
                                    valueName="id"
                                    required={[
                                      "type",
                                      "type_work",
                                      "species",
                                      "sub_work"
                                    ].includes(key)}
                                  />
                                  <AregisterOneMasscopyFormSendBtn
                                    name={`massfill[${key}]_sendBtnRow`}
                                    onClick={() => {
                                      addMassfill(key);
                                    }}
                                    tableType="row"
                                  />
                                </div>
                              </td>
                            );
                          } else if (
                            field.type === "date" ||
                            field.type === "timestamp"
                          ) {
                            return (
                              <td
                                key={`${key}_field`}
                                className={`${styles.field} ${
                                  highlightIndex === i ||
                                  focusIndex === i ||
                                  focusRowIndex === -1
                                    ? styles.highlighted
                                    : ""
                                } ${
                                  focusIndex === i && focusRowIndex === -1
                                    ? styles.focused
                                    : ""
                                }`}
                                onMouseEnter={() => {
                                  setHighlightIndex(i);
                                }}
                                onMouseLeave={() => {
                                  setHighlightIndex(null);
                                }}
                                onClick={() => {
                                  setEditedCell(`massfill[${key}]`);
                                }}
                                id={`AregisterOneMasscopyFormTable__massfill[${key}]_cell`}
                                onKeyDown={(e: { keyCode: number }) => {
                                  if (
                                    editedCell === `massfill[${key}]` &&
                                    e.keyCode === 27
                                  ) {
                                    handleEscPress(key);
                                  }
                                }}
                              >
                                <div
                                  id={`AregisterOneMasscopyFormTable_massfill[${key}]`}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(i);
                                    setFocusRowIndex(-1);
                                  }}
                                  className={styles.massDateCell}
                                >
                                  <DatePickerField
                                    name={`massfill[${key}]`}
                                    title={""}
                                    isCalendarOpened={
                                      openedListName === `massfill[${key}]`
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedListName(`massfill[${key}]`);
                                    }}
                                    onChange={(value) => {
                                      if (key === "date_start") {
                                        (values.massfill.date_end &&
                                          parse(
                                            value,
                                            "yyyy-MM-dd",
                                            new Date()
                                          ) >
                                            parse(
                                              values.massfill
                                                .date_end as string,
                                              "yyyy-MM-dd",
                                              new Date()
                                            )) ||
                                        !values.massfill.date_end
                                          ? setFieldValue(
                                              `massfill[date_end]`,
                                              value
                                            )
                                          : "";
                                      }
                                    }}
                                    minDate={
                                      key === "date_end" &&
                                      values.massfill.date_start
                                        ? parse(
                                            values.massfill
                                              .date_start as string,
                                            "yyyy-MM-dd",
                                            new Date()
                                          )
                                        : null
                                    }
                                    className={{
                                      container: styles.datePickerCustomMass,
                                      input: styles.datePickerCustomInput
                                    }}
                                  />

                                  <AregisterOneMasscopyFormSendBtn
                                    name={`massfill[${key}]_sendBtnRow`}
                                    onClick={() => {
                                      addMassfill(key);
                                    }}
                                    tableType="row"
                                  />
                                </div>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={`${key}_field`}
                              className={`${styles.field_massfill} ${
                                highlightIndex === i ||
                                focusIndex === i ||
                                focusRowIndex === -1
                                  ? styles.highlighted
                                  : ""
                              } ${
                                focusIndex === i && focusRowIndex === -1
                                  ? styles.focused
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightIndex(null);
                              }}
                            >
                              <div
                                id={`AregisterOneMasscopyFormTable_massfill[${key}]`}
                                ref={focusedRef}
                                onClick={() => {
                                  setFocusIndex(i);
                                  setFocusRowIndex(-1);
                                }}
                                className={
                                  styles.field_massfill__fieldContainer
                                }
                              >
                                <Input
                                  name={`massfill[${key}]`}
                                  autocomplete="off"
                                  createRef
                                  onClick={() => {
                                    setEditedCell(`massfill[${key}]`);
                                  }}
                                  onKeyDown={(e: { keyCode: number }) => {
                                    if (
                                      editedCell === `massfill[${key}]` &&
                                      e.keyCode === 27
                                    ) {
                                      handleEscPress(key);
                                    }
                                  }}
                                />
                                <AregisterOneMasscopyFormSendBtn
                                  name={`massfill[${key}]_sendBtnRow`}
                                  onClick={() => {
                                    addMassfill(key);
                                  }}
                                  tableType="row"
                                />
                              </div>
                            </td>
                          );
                      }
                    })()}
                  </Fragment>
                ) : null;
              })}
              {checkCustomFields()
                ? Object.entries(
                    aregisterAppcreateStore.projects[values.original.project]
                      .custom.custom_fields
                  ).map(([customTitle, customField], i) => {
                    const addMassfillCustomFields = (key: string) => {
                      values.copies.forEach((_copy, numeric) => {
                        setFieldValue(
                          `copies[${numeric}][custom_fields][${key}]`,
                          values.massfill.custom_fields[key]
                        );
                      });
                      setFocusIndex(null);
                      setFocusRowIndex(null);
                    };
                    return (
                      <td
                        key={`massfill[custom_fields][${customTitle}]`}
                        className={`${(() => {
                          switch (customField.type) {
                            case "select":
                              return styles.field_select_massfill;
                            case "text":
                            default:
                              return styles.field_massfill;
                          }
                        })()} ${
                          highlightIndex === i + 100 ||
                          focusIndex === i + 100 ||
                          focusRowIndex === -1
                            ? styles.highlighted
                            : ""
                        }`}
                        onMouseEnter={() => {
                          setHighlightIndex(i + 100);
                        }}
                        onMouseLeave={() => {
                          setHighlightIndex(null);
                        }}
                        onClick={() => {
                          ["date", "select"].includes(customField.type) &&
                            setEditedCell(
                              `massfill[custom_fields][${customTitle}]`
                            );
                        }}
                        id={`AregisterOneMasscopyFormTable_massfill[custom_fields][${customTitle}]_cell`}
                        onKeyDown={(e: { keyCode: number }) => {
                          if (
                            editedCell ===
                              `massfill[custom_fields][${customTitle}]` &&
                            e.keyCode === 27 &&
                            ["date", "select"].includes(customField.type)
                          ) {
                            handleEscPress(customTitle, "custom_fields");
                          }
                        }}
                      >
                        <div
                          id={`AregisterOneMasscopyFormTable_massfill[custom_fields][${customTitle}]`}
                          ref={focusedRef}
                          onClick={() => {
                            setFocusIndex(i + 100);
                            setFocusRowIndex(-1);
                          }}
                          className={(() => {
                            switch (customField.type) {
                              case "select":
                                return styles.field_select_massfill__fieldContainer;
                              case "text":
                              default:
                                return styles.field_massfill__fieldContainer;
                            }
                          })()}
                        >
                          {(() => {
                            const name = `massfill[custom_fields][${customTitle}]`;
                            switch (customField.type) {
                              case "select":
                                return (
                                  <Select
                                    name={name}
                                    label={values[name] as string}
                                    title={" "}
                                    options={customField.data}
                                    valueName="id"
                                  />
                                );

                              case "date":
                                return (
                                  <DatePickerField
                                    name={name}
                                    isCalendarOpened={openedListName === name}
                                    setIsCalendarOpened={() => {
                                      changeOpenedListName(name);
                                    }}
                                    title={" "}
                                    className={{
                                      container: styles.datePickerCustom,
                                      input: styles.datePickerCustomInput
                                    }}
                                  />
                                );

                              case "text":
                              default:
                                {
                                  /* TODO: возможно нужно будет править, т.к. сейчас интерфейс недоступен */
                                }
                                return (
                                  <Input
                                    name={name}
                                    autocomplete="off"
                                    createRef
                                    onClick={() => {
                                      setEditedCell(
                                        `massfill[custom_fields][${customTitle}]`
                                      );
                                    }}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `massfill[custom_fields][${customTitle}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(
                                          customTitle,
                                          "custom_fields"
                                        );
                                      }
                                    }}
                                  />
                                );
                            }
                          })()}
                          {customField.type === "date" ? (
                            <div
                              className={
                                styles.field_date_massfill__fieldContainer__iconSend
                              }
                            >
                              <AregisterOneMasscopyFormSendBtn
                                name={`massfill[${customTitle}]_sendBtnRow`}
                                onClick={() => {
                                  addMassfillCustomFields(customTitle);
                                }}
                                tableType="row"
                              />
                            </div>
                          ) : (
                            <AregisterOneMasscopyFormSendBtn
                              name={`massfill[${customTitle}]_sendBtnRow`}
                              onClick={() => {
                                addMassfillCustomFields(customTitle);
                              }}
                              tableType="row"
                            />
                          )}
                        </div>
                      </td>
                    );
                  })
                : null}
              <td className={styles.empty}></td>
            </tr>
          ) : null}

          {/* Формы копий заявки */}
          {values.copies.length
            ? values.copies.map((copy, numeric) => {
                return (
                  <tr
                    className={numeric % 2 ? styles.copy : styles.copy_gray}
                    key={`${numeric + 1}/${values.copies.length}`}
                  >
                    {Object.entries(copy).map(([key, value], i) => {
                      const field = aregisterAppcreateStore.cols[key];
                      const param = aregisterAppcreateStore.params[key];
                      return field?.title ? (
                        <Fragment key={key}>
                          <>
                            {i === 0 ? (
                              <td
                                className={`${styles.copy__title} ${
                                  styles.firstCol
                                } ${
                                  numeric % 2 ? styles.copy__title_yellow : ""
                                } ${
                                  menuStore.scroll["scrollLeft"]
                                    ? styles.firstCol_shadow
                                    : ""
                                } ${
                                  highlightIndex === -1 ||
                                  focusRowIndex === numeric
                                    ? styles.highlighted
                                    : ""
                                }`}
                                onMouseEnter={() => {
                                  setHighlightIndex(-1);
                                }}
                                onMouseLeave={() => {
                                  setHighlightIndex(null);
                                }}
                              >
                                <div className={styles.copy__title__text}>
                                  <div>Копия</div>
                                  <div>{`${numeric + 1}/${
                                    values.copies.length
                                  }`}</div>
                                  <div>
                                    <IconClose
                                      id={`AregisterOneMasscopyFormTable_deleteCopyBtn_${numeric}`}
                                      onClick={() => {
                                        const oldCopies = values.copies;

                                        oldCopies.splice(numeric, 1);

                                        setFieldValue(
                                          "copies",
                                          oldCopies.length ? oldCopies : []
                                        );

                                        if (
                                          !oldCopies.length &&
                                          isOpenedMassFill
                                        ) {
                                          setFieldValue("massfill", {});
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            ) : null}
                            {(() => {
                              switch (key) {
                                case "project":
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        className={`${styles.field__fieldContainer} ${styles.field__fieldContainer_project}`}
                                      >
                                        {
                                          aregisterAppcreateStore.projects[
                                            value as string
                                          ]?.title
                                        }
                                      </div>
                                    </td>
                                  );

                                case "internal_num":
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                        ref={focusedRef}
                                        onClick={() => {
                                          setFocusIndex(i);
                                          setFocusRowIndex(numeric);
                                        }}
                                        className={styles.field__fieldContainer}
                                      >
                                        <Input
                                          name={`copies[${numeric}][${key}]`}
                                          onChange={(e) => {
                                            e.target.value?.length ||
                                            e.target.value?.trim()?.length !== 0
                                              ? aregisterAppcreateStore.checkInternalNum(
                                                  `${id}_masscopy`,
                                                  values.original
                                                    .project as string,
                                                  e.target.value,
                                                  numeric
                                                )
                                              : aregisterAppcreateStore.deleteErrors(
                                                  `${id}_masscopy`,
                                                  key
                                                );
                                          }}
                                          autocomplete="off"
                                          createRef
                                          onClick={() => {
                                            setEditedCell(
                                              `copies[${numeric}][${key}]`
                                            );
                                          }}
                                          onKeyDown={(e: {
                                            keyCode: number;
                                          }) => {
                                            if (
                                              editedCell ===
                                                `copies[${numeric}][${key}]` &&
                                              e.keyCode === 27
                                            ) {
                                              handleEscPress(key);
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  );

                                case "master":
                                case "brigadier":
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field_select} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                      onClick={() => {
                                        setEditedCell(
                                          `copies[${numeric}][${key}]`
                                        );
                                      }}
                                      id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]_cell`}
                                      onKeyDown={(e: { keyCode: number }) => {
                                        if (
                                          editedCell ===
                                            `copies[${numeric}][${key}]` &&
                                          e.keyCode === 27
                                        ) {
                                          handleEscPress(key);
                                        }
                                      }}
                                    >
                                      <div
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                        ref={focusedRef}
                                        onClick={() => {
                                          setFocusIndex(i);
                                          setFocusRowIndex(numeric);
                                        }}
                                        className={
                                          styles.field_select__fieldContainer
                                        }
                                      >
                                        <Select
                                          options={aregisterAppcreateStore.setDirectory(
                                            copy.project as string,
                                            key,
                                            id,
                                            numeric
                                          )}
                                          name={`copies[${numeric}][${key}]`}
                                          label={
                                            param.variable[value as string]
                                              ?.title || ""
                                          }
                                          valueName="id"
                                          required
                                        />
                                      </div>
                                    </td>
                                  );

                                case "measurements":
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${
                                        copy.project &&
                                        aregisterAppcreateStore.projects[
                                          copy.project
                                        ]?.allow_manual_size
                                          ? styles.field_size
                                          : styles.field
                                      } ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                        ref={focusedRef}
                                        onClick={() => {
                                          setFocusIndex(i);
                                          setFocusRowIndex(numeric);
                                        }}
                                        className={
                                          copy.project &&
                                          aregisterAppcreateStore.projects[
                                            copy.project
                                          ]?.allow_manual_size
                                            ? styles.field_size__fieldContainer
                                            : styles.field__fieldContainer
                                        }
                                      >
                                        <AregisterOneMasscopyFormFormula
                                          values={copy}
                                          type="edit"
                                          numeric={numeric}
                                          onClick={(name: string) => {
                                            setEditedCell(name);
                                          }}
                                          onKeyDown={(
                                            name: string,
                                            key: string
                                          ) => {
                                            if (editedCell === name) {
                                              handleEscPress(name, key);
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  );

                                case "manual_hours":
                                  return copy.project &&
                                    aregisterAppcreateStore.projects[
                                      copy.project
                                    ]?.allow_manual_hours === 1 ? (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field_manualHours} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                        ref={focusedRef}
                                        onClick={() => {
                                          setFocusIndex(i);
                                          setFocusRowIndex(numeric);
                                        }}
                                        className={
                                          styles.field_manualHours__fieldContainer
                                        }
                                      >
                                        <div
                                          id={`AregisterOneMasscopyFormTable_copies[${numeric}][allow_manual_hours]`}
                                          onClick={() => {
                                            setFieldValue(
                                              `copies[${numeric}][allow_manual_hours]`,
                                              copy.allow_manual_hours ? 0 : 1
                                            );
                                          }}
                                          className={
                                            styles.field_manualHours__fieldContainer__title
                                          }
                                        >
                                          <div>
                                            {
                                              aregisterAppcreateStore.cols
                                                .manual_hours.title
                                            }
                                          </div>
                                          <Switch
                                            name={`copies[${numeric}][allow_manual_hours]`}
                                          />
                                        </div>
                                        <div
                                          className={
                                            styles.field_manualHours__fieldContainer__input
                                          }
                                        >
                                          <Input
                                            name={`copies[${numeric}][manual_hours]`}
                                            disabled={
                                              !copy["allow_manual_hours"]
                                            }
                                            autocomplete="off"
                                            createRef
                                            onClick={() => {
                                              setEditedCell(
                                                `copies[${numeric}][${key}]`
                                              );
                                            }}
                                            onKeyDown={(e: {
                                              keyCode: number;
                                            }) => {
                                              if (
                                                editedCell ===
                                                  `copies[${numeric}][${key}]` &&
                                                e.keyCode === 27
                                              ) {
                                                handleEscPress(key);
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  ) : null;

                                case "kc":
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field_kc} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        className={
                                          styles.field_kc__fieldContainer
                                        }
                                      >
                                        <div
                                          id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                          ref={focusedRef}
                                          onClick={() => {
                                            setFocusIndex(i);
                                            setFocusRowIndex(numeric);
                                          }}
                                        >
                                          <Input
                                            name={`copies[${numeric}][${key}]`}
                                            disabled={Boolean(copy.no_kc)}
                                            autocomplete="off"
                                            createRef
                                            onClick={() => {
                                              setEditedCell(
                                                `copies[${numeric}][${key}]`
                                              );
                                            }}
                                            onKeyDown={(e: {
                                              keyCode: number;
                                            }) => {
                                              if (
                                                editedCell ===
                                                  `copies[${numeric}][${key}]` &&
                                                e.keyCode === 27
                                              ) {
                                                handleEscPress(key);
                                              }
                                            }}
                                          />
                                        </div>
                                        <label>
                                          <Switch
                                            name={`copies[${numeric}][no_kc]`}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `copies[${numeric}][${key}]`,
                                                e.target.checked
                                                  ? null
                                                  : values.original[key]
                                              );
                                            }}
                                          />
                                          {
                                            aregisterAppcreateStore.cols[
                                              "no_kc"
                                            ]?.title
                                          }
                                        </label>
                                      </div>
                                    </td>
                                  );

                                case "allow_manual_size":
                                case "allow_manual_hours":
                                case "manual_size":
                                case "length":
                                case "width":
                                case "height":
                                case "size":
                                case "conditional_cubes":
                                case "custom_fields":
                                case "no_kc":
                                  return;
                                default:
                                  if (
                                    param?.isvariable ||
                                    (param?.variable &&
                                      Object.values(param.variable).length)
                                  ) {
                                    return (
                                      <td
                                        key={`copies[${numeric}][${key}]`}
                                        className={`${styles.field_select} ${
                                          highlightIndex === i ||
                                          focusIndex === i ||
                                          focusRowIndex === numeric
                                            ? styles.highlighted
                                            : ""
                                        }`}
                                        onMouseEnter={() => {
                                          setHighlightIndex(i);
                                        }}
                                        onMouseLeave={() => {
                                          setHighlightIndex(null);
                                        }}
                                        onClick={() => {
                                          setEditedCell(
                                            `copies[${numeric}][${key}]`
                                          );
                                        }}
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]_cell`}
                                        onKeyDown={(e: { keyCode: number }) => {
                                          if (
                                            editedCell ===
                                              `copies[${numeric}][${key}]` &&
                                            e.keyCode === 27
                                          ) {
                                            handleEscPress(key);
                                          }
                                        }}
                                      >
                                        <div
                                          id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                          ref={focusedRef}
                                          onClick={() => {
                                            setFocusIndex(i);
                                            setFocusRowIndex(numeric);
                                          }}
                                          className={
                                            styles.field_select__fieldContainer
                                          }
                                        >
                                          <Select
                                            options={aregisterAppcreateStore.setDirectory(
                                              copy.project as string,
                                              key
                                            )}
                                            name={`copies[${numeric}][${key}]`}
                                            label={
                                              param.variable[value as string]
                                                ?.title || ""
                                            }
                                            valueName="id"
                                            required={[
                                              "type",
                                              "type_work",
                                              "species",
                                              "sub_work"
                                            ].includes(key)}
                                          />
                                        </div>
                                      </td>
                                    );
                                  } else if (
                                    field.type === "date" ||
                                    field.type === "timestamp"
                                  ) {
                                    return (
                                      <td
                                        key={`copies[${numeric}][${key}]`}
                                        className={`${styles.field} ${
                                          highlightIndex === i ||
                                          focusIndex === i ||
                                          focusRowIndex === numeric
                                            ? styles.highlighted
                                            : ""
                                        }`}
                                        onMouseEnter={() => {
                                          setHighlightIndex(i);
                                        }}
                                        onMouseLeave={() => {
                                          setHighlightIndex(null);
                                        }}
                                        onClick={() => {
                                          setEditedCell(
                                            `copies[${numeric}][${key}]`
                                          );
                                        }}
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]_cell`}
                                        onKeyDown={(e: { keyCode: number }) => {
                                          if (
                                            editedCell ===
                                              `copies[${numeric}][${key}]` &&
                                            e.keyCode === 27
                                          ) {
                                            handleEscPress(key);
                                          }
                                        }}
                                      >
                                        <div
                                          id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                          ref={focusedRef}
                                          onClick={() => {
                                            setFocusIndex(i);
                                            setFocusRowIndex(numeric);
                                          }}
                                        >
                                          <DatePickerField
                                            name={`copies[${numeric}][${key}]`}
                                            title={""}
                                            className={{
                                              container:
                                                styles.datePickerCustom,
                                              input:
                                                styles.datePickerCustomInput
                                            }}
                                            isCalendarOpened={
                                              openedListName ===
                                              `${key}_${numeric}`
                                            }
                                            setIsCalendarOpened={() => {
                                              changeOpenedListName(
                                                `${key}_${numeric}`
                                              );
                                            }}
                                            onChange={(value) => {
                                              if (key === "date_start") {
                                                (values.copies[numeric][
                                                  "date_end"
                                                ] &&
                                                  parse(
                                                    value,
                                                    "yyyy-MM-dd",
                                                    new Date()
                                                  ) >
                                                    parse(
                                                      values.copies[numeric][
                                                        "date_end"
                                                      ] as string,
                                                      "yyyy-MM-dd",
                                                      new Date()
                                                    )) ||
                                                !values.copies[numeric][
                                                  "date_end"
                                                ]
                                                  ? setFieldValue(
                                                      `copies[${numeric}]["date_end"]`,
                                                      value
                                                    )
                                                  : "";
                                              }

                                              if (
                                                [
                                                  "date_start",
                                                  "date_end"
                                                ].includes(key)
                                              ) {
                                                setFieldValue(
                                                  `copies[${numeric}][master]]`,
                                                  "masterless"
                                                );
                                                setFieldValue(
                                                  `copies[${numeric}][brigadier]]`,
                                                  null
                                                );
                                                new Promise(() =>
                                                  aregisterAppcreateStore.getMastersAndGangers(
                                                    values.original
                                                      .project as string,
                                                    values.copies[numeric][
                                                      "date_start"
                                                    ] as string,
                                                    values.copies[numeric][
                                                      "date_end"
                                                    ] as string,
                                                    `copies_${id}_${numeric}`
                                                  )
                                                ).then(() => {
                                                  setTimeout(() => {
                                                    setFieldTouched(
                                                      `copies[${numeric}][master]]`
                                                    );
                                                    setFieldTouched(
                                                      `copies[${numeric}][brigadier]]`
                                                    );
                                                  }, 500);
                                                });
                                              }
                                            }}
                                            minDate={
                                              key === "date_end" &&
                                              copy.date_start
                                                ? parse(
                                                    copy.date_start as string,
                                                    "yyyy-MM-dd",
                                                    new Date()
                                                  )
                                                : null
                                            }
                                          />
                                        </div>
                                      </td>
                                    );
                                  }
                                  return (
                                    <td
                                      key={`copies[${numeric}][${key}]`}
                                      className={`${styles.field} ${
                                        highlightIndex === i ||
                                        focusIndex === i ||
                                        focusRowIndex === numeric
                                          ? styles.highlighted
                                          : ""
                                      }`}
                                      onMouseEnter={() => {
                                        setHighlightIndex(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHighlightIndex(null);
                                      }}
                                    >
                                      <div
                                        id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]`}
                                        ref={focusedRef}
                                        onClick={() => {
                                          setFocusIndex(i);
                                          setFocusRowIndex(numeric);
                                        }}
                                        className={styles.field__fieldContainer}
                                      >
                                        <Input
                                          name={`copies[${numeric}][${key}]`}
                                          autocomplete="off"
                                          createRef
                                          onClick={() => {
                                            setEditedCell(
                                              `copies[${numeric}][${key}]`
                                            );
                                          }}
                                          onKeyDown={(e: {
                                            keyCode: number;
                                          }) => {
                                            if (
                                              editedCell ===
                                                `copies[${numeric}][${key}]` &&
                                              e.keyCode === 27
                                            ) {
                                              handleEscPress(key);
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  );
                              }
                            })()}
                          </>
                        </Fragment>
                      ) : null;
                    })}
                    {checkCustomFields()
                      ? Object.entries(
                          aregisterAppcreateStore.projects[
                            values.original.project
                          ].custom.custom_fields
                        ).map(([customTitle, customField], i) => {
                          return (
                            <td
                              key={`copies[${numeric}][custom_fields][${customTitle}]`}
                              className={`${styles.field} ${
                                highlightIndex === i + 100 ||
                                focusIndex === i + 100 ||
                                focusRowIndex === numeric
                                  ? styles.highlighted
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightIndex(i + 100);
                              }}
                              onMouseLeave={() => {
                                setHighlightIndex(null);
                              }}
                              onClick={() => {
                                ["date", "select"].includes(customField.type) &&
                                  setEditedCell(
                                    `copies[${numeric}][custom_fields][${customTitle}]`
                                  );
                              }}
                              id={`AregisterOneMasscopyFormTable_copies[${numeric}][custom_fields][${customTitle}]_cell`}
                              onKeyDown={(e: { keyCode: number }) => {
                                if (
                                  editedCell ===
                                    `copies[${numeric}][custom_fields][${customTitle}]` &&
                                  e.keyCode === 27 &&
                                  ["date", "select"].includes(customField.type)
                                ) {
                                  handleEscPress(customTitle, "custom_fields");
                                }
                              }}
                            >
                              <div
                                id={`AregisterOneMasscopyFormTable_copies[${numeric}][custom_fields][${customTitle}]`}
                                ref={focusedRef}
                                onClick={() => {
                                  setFocusIndex(i + 100);
                                  setFocusRowIndex(numeric);
                                }}
                                className={(() => {
                                  switch (customField.type) {
                                    case "select":
                                      return styles.field_select__fieldContainer;
                                    case "text":
                                    default:
                                      return styles.field__fieldContainer;
                                  }
                                })()}
                              >
                                {(() => {
                                  const name = `copies[${numeric}][custom_fields][${customTitle}]`;
                                  switch (customField.type) {
                                    case "select":
                                      return (
                                        <Select
                                          name={name}
                                          label={values[name] as string}
                                          title={" "}
                                          options={customField.data}
                                          valueName="id"
                                        />
                                      );

                                    case "date":
                                      return (
                                        <DatePickerField
                                          name={name}
                                          isCalendarOpened={
                                            openedListName === name
                                          }
                                          setIsCalendarOpened={() => {
                                            changeOpenedListName(name);
                                          }}
                                          title={" "}
                                          className={{
                                            container: styles.datePickerCustom,
                                            input: styles.datePickerCustomInput
                                          }}
                                        />
                                      );

                                    case "text":
                                    default:
                                      {
                                        /* TODO: возможно нужно будет править, т.к. сейчас интерфейс недоступен */
                                      }
                                      return (
                                        <Input
                                          name={name}
                                          autocomplete="off"
                                          createRef
                                          onClick={() => {
                                            setEditedCell(
                                              `copies[${numeric}][custom_fields][${customTitle}]`
                                            );
                                          }}
                                          onKeyDown={(e: {
                                            keyCode: number;
                                          }) => {
                                            if (
                                              editedCell ===
                                                `copies[${numeric}][custom_fields][${customTitle}]` &&
                                              e.keyCode === 27
                                            ) {
                                              handleEscPress(
                                                customTitle,
                                                "custom_fields"
                                              );
                                            }
                                          }}
                                        />
                                      );
                                  }
                                })()}
                              </div>
                            </td>
                          );
                        })
                      : null}
                    <td className={styles.empty}></td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </div>
  );
};

export default observer(AregisterOneMasscopyFormTable);
