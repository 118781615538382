import StatusIcon from "shared/ui/StatusIcon";
import styles from "./staffImportTableLoader.module.scss";

type StaffImportTableLoaderProps = {
  text: string;
};

const StaffImportTableLoader = ({ text }: StaffImportTableLoaderProps) => {
  return (
    <div className={styles.container}>
      <StatusIcon icon="icondoublecheck" />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default StaffImportTableLoader;
