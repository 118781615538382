import styles from "./staffOneSafetyWorkCommentsInput.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Formik } from "formik";
import commentValidation from "./validation";
import { Form } from "react-bootstrap";
import Textarea from "shared/ui/Inputs/Textarea";
import { ReactComponent as SendButton } from "shared/assets/images/mainIcons/iconsSend/iconSendWithoutStroke.svg";
import DatePickerField from "shared/ui/Inputs/DatePickerField";

const StaffOneSafetyWorkCommentsInput = () => {
  const { staffOneSafetyWorkStore } = useStores();
  const { id } = useParams();

  const [isCalendar, setIsCalendar] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: "",
        uid: id,
        event_date: null
      }}
      validationSchema={commentValidation}
      onSubmit={(values) => {
        staffOneSafetyWorkStore.addHistoryComment(
          values.uid,
          values.comment,
          values.event_date
        );
      }}
    >
      {({ errors, isValid, dirty, handleSubmit }) => {
        return (
          <Form>
            <div className={styles.commentInputField}>
              <div className={styles.buttonsWrapper}>
                <DatePickerField
                  name="event_date"
                  title={
                    staffOneSafetyWorkStore.certsColsEvents.event_date?.title
                  }
                  isCalendarOpened={isCalendar}
                  setIsCalendarOpened={setIsCalendar}
                  className={{
                    container: styles.datePickerCustom,
                    input: styles.datePickerInput
                  }}
                  withoutError
                />
                <button
                  type="button"
                  className={styles.sendButton}
                  disabled={!isValid || !dirty}
                  id="StaffOneSafetyWorkCommentsInput_submitButton"
                  onClick={() => handleSubmit()}
                >
                  <SendButton />
                </button>
              </div>
              <Textarea
                placeholder="Ваш комментарий..."
                name="comment"
                forCommentInputField
              />
            </div>
            {errors.event_date ? (
              <div className={styles.errors}>
                <div>{errors.event_date as string}</div>
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(StaffOneSafetyWorkCommentsInput);
