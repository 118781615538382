import styles from "./buildingOneWorkshiftStafftableHours.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import NumberFormat from "react-number-format";
import iconSend from "shared/assets/images/mainIcons/iconsSend/iconSend.svg";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { ChangeEvent } from "react";

import { classNames } from "shared/utils/helpers/classNames";

const BuildingOneWorkshiftStafftableHours = ({
  name,
  staffIndex
}: WorkshiftStaffFieldProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { values, setFieldValue, errors } = useFormikContext<StaffType[]>();

  // явно указываем имя поля для корректной типизации
  if (name === "work_hours") {
    const value = values[staffIndex][name];

    const mode = buildingOneWorkshiftStore.getOpenedModeForField(name);
    // Заблокирован ли ввод часов, согласно настройкам состояний в смене
    const isBlockedHours =
      mode === "edit" &&
      buildingOneWorkshiftStore.selects.objectstatus[
        values[staffIndex].objectstatus
      ]?.custom?.block_hours === "on";

    const handleChange = (e: ChangeEvent) => {
      setFieldValue(`${staffIndex}.${name}`, +e["target"]["value"] || null);
    };

    const handleClickOrBlur = (type: "single" | "mass") => () => {
      if (type === "single" && errors[staffIndex]?.[name]) return;
      buildingOneWorkshiftStore.sendData(
        values[staffIndex][name],
        name,
        values[staffIndex].ws_staff_id,
        values[staffIndex].uid,
        values[staffIndex].isMassEdit
      );
    };

    // Если ввод часов заблокирован - ничего не отображаем
    if (!isBlockedHours) {
      switch (mode) {
        case "show":
          return (
            <p className={classNames("", { [styles.redHours]: value <= 4 })}>
              {values[staffIndex][name] || 0}
            </p>
          );
        case "edit":
          return (
            <div className={styles.hoursContainer}>
              <NumberFormat
                name={`${staffIndex}.${name}`}
                value={values[staffIndex][name]}
                onChange={handleChange}
                onBlur={handleClickOrBlur("single")}
                allowNegative={false}
                isAllowed={({ formattedValue, floatValue }) =>
                  formattedValue === "" || floatValue <= 24
                }
                allowedDecimalSeparators={[",", "."]}
                decimalScale={2}
                className={classNames(styles.numberInput, {
                  [styles.numberInput_error]: !!errors[staffIndex]?.[name]
                })}
              />
              {/* Иконка отправки данных при массовом редактировании */}
              {values[staffIndex].isMassEdit && (
                <img
                  src={iconSend}
                  className={styles.hoursComplete}
                  id="BuildingOneWorkshiftStafftableHours_complete_hours"
                  alt="complete_hours"
                  onClick={handleClickOrBlur("mass")}
                />
              )}
              {/* Отображение ошибок */}
              {errors[staffIndex]?.[name] && (
                <div className={styles.errorText}>
                  {errors[staffIndex][name]}
                </div>
              )}
            </div>
          );
      }
    }
  }
};

export default observer(BuildingOneWorkshiftStafftableHours);
