import styles from "./staffRatingTableCoefficient.module.scss";
import { Table } from "react-bootstrap";
import { RatingStaff } from "stores/StaffModule/types/Rating";

type StaffRatingTableCoefficientProps = {
  selectedStaff: RatingStaff;
};

const StaffRatingTableCoefficient = ({
  selectedStaff
}: StaffRatingTableCoefficientProps) => {
  //hardcode
  const rank_d = [4.0, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5.0];
  const rank_coefficient = [
    0.0, 0.2, 0.4, 0.6, 0.8, 1.0, 1.05, 1.1, 1.15, 1.2, 1.25
  ];

  return (
    <Table>
      <tbody className={styles.tableBody}>
        <tr>
          <td className={styles.firstCol}>Средняя оценка</td>
          {rank_d.map((rank) => {
            return (
              <td
                key={rank}
                className={
                  selectedStaff && selectedStaff.rank_d === rank
                    ? styles.selected
                    : ""
                }
              >
                {rank.toFixed(2)}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className={styles.firstCol}>Коэффициент</td>
          {rank_coefficient.map((coefficient) => {
            return (
              <td
                key={coefficient}
                className={
                  selectedStaff &&
                  selectedStaff.rank_coefficient === coefficient
                    ? styles.selected
                    : ""
                }
              >
                {coefficient.toFixed(2)}
              </td>
            );
          })}
        </tr>
      </tbody>
    </Table>
  );
};

export default StaffRatingTableCoefficient;
