import Switch from "shared/ui/Inputs/Switch";
import styles from "./photoAltGalleryManageBox.module.scss";
import StatusIcon from "shared/ui/StatusIcon";

type ManageBoxProps = {
  imageName: string;
  makeMainPhoto: () => void;
  handleDeleteClick: () => void;
  imageIndex: number
};

const PhotoAltGalleryManageBox = ({
  imageName,
  makeMainPhoto,
  handleDeleteClick,
  imageIndex
}: ManageBoxProps) => {
  return (
    <div className={styles.manageBox}>
      <div className={styles.leftCol}>
        <label className={styles.switch}>
          <Switch name={`[${imageIndex}][isMain]`} onChange={makeMainPhoto} />
          Фото профиля
        </label>
        <div className={styles.photoInfo}>
          <span className={styles.photoName}>Название изображения:</span>{" "}
          {imageName}
        </div>
      </div>
      <div className={styles.rightCol}>
        <button
          type="button"
          className={styles.manageBoxBtns}
          id="PhotoAltGalleryManageBox_deletePhoto"
          onClick={handleDeleteClick}
        >
          <StatusIcon icon="iconclose" color="bwGray5" /> Удалить
        </button>
      </div>
    </div>
  );
};

export default PhotoAltGalleryManageBox;
