import styles from "./staffOneSalaryRetentionUCTable.module.scss";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useStores } from "stores";
import { WithholdingEdu } from "stores/StaffModule/types/WithholdingEdu";

import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import Alert from "shared/ui/Alert";

//хардкод
const currentTitles = [
  "index",
  "date_start",
  "closed_ws_num",
  "remainder_operationless",
  "all_operation_sum",
  "status"
];

const cols = {
  index: { title: "Номер вычета" },
  date_start: { title: "Дата начала" },
  closed_ws_num: { title: "Рабочих дней всего" },
  remainder_operationless: {
    title: "Размер вычета в зависимости от кол-ва дней"
  },
  all_operation_sum: { title: "Удержания" },
  status: { title: "Статус вычета" }
};

const StaffOneSalaryRetentionUCTable = () => {
  const { staffOneSalaryStore, staffRetentionListStore, staffOneStore } =
    useStores();
  const { id } = useParams();
  const navigate = useNavigate();

  // функция для перессылки в таблицы всех вычетов уц с открытым окном вычета текущего сотрудника
  const handleClick = () => {
    staffRetentionListStore.setSearchValue(`тн${staffOneStore.info["uid"]}`);
    staffRetentionListStore.getWithHoldingEduList();
    staffRetentionListStore.setSelectedStaffName(`${staffOneStore.info["surname"]} ${staffOneStore.info["name"]}
    ${staffOneStore.info["patronymic"]}`);
    staffRetentionListStore.getHoldingEduItemHistory(id);
    staffRetentionListStore.getHoldingEduItemOperations(id);
    navigate(`../staff/staffRetention`);
  };

  const staffRetention: Partial<WithholdingEdu> =
    staffOneSalaryStore.staffRetention[id];
  return staffRetention?.widget && Object.keys(staffRetention.widget).length ? (
    <>
      <div className={styles.tableBlock}>
        <Table>
          <thead>
            <tr>
              {currentTitles.map((title) => {
                return (
                  <th key={title} className={styles.title}>
                    {cols[title]["title"]}
                  </th>
                );
              })}
              <th className={styles.title}></th>
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {Object.values(staffRetention.widget).map((retention) => {
              return (
                <tr key={retention.id}>
                  {currentTitles.map((title) => {
                    switch (title) {
                      case "date_start":
                        return (
                          <td key={title}>
                            <p>{getFormattedDate(retention[title])}</p>
                          </td>
                        );
                      case "remainder_operationless":
                        return (
                          <td key={title}>
                            <div className={styles.cellWithFormula}>
                              <p>
                                {title in retention &&
                                typeof retention[title] === "number"
                                  ? numberWithSpaces(retention[title])
                                  : ""}
                              </p>
                              {retention["remainder_formula"] ? (
                                <Tooltip
                                  text={
                                    <div className={styles.formulaContent}>
                                      <p>{retention["remainder_formula"]}</p>
                                      <p>{`${retention["money"]} - стоимость обучения`}</p>
                                      <p>{`${
                                        retention["money"] / retention["ws_num"]
                                      } - размер вычета за одну смену (${
                                        retention["money"]
                                      }/${retention["ws_num"]})`}</p>
                                      <p>{`${retention["closed_ws_num"]} - количество отработанных смен`}</p>
                                    </div>
                                  }
                                  borderColor="blue-lazure"
                                >
                                  <StatusIcon icon="iconquestionsmall" />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        );
                      case "all_operation_sum":
                        return (
                          <td key={title}>
                            <p>
                              {retention[title]
                                ? numberWithSpaces(Math.abs(retention[title]))
                                : ""}
                            </p>
                          </td>
                        );
                      case "status":
                        return (
                          <td key={title}>
                            <div className={styles.status}>
                              <StatusIcon
                                icon={
                                  staffRetention.statusList[retention.status]?.[
                                    "icon"
                                  ]
                                }
                                color={
                                  staffRetention.statusList[retention.status]?.[
                                    "color_api"
                                  ]
                                }
                              />
                              <p>
                                {staffRetention.statusList[retention.status]?.[
                                  "show_title_api"
                                ]
                                  ? staffRetention.statusList[retention.status]
                                      ?.title
                                  : ""}
                              </p>
                            </div>
                          </td>
                        );
                      default:
                        return (
                          <td key={title}>
                            <p>{retention[title]}</p>
                          </td>
                        );
                    }
                  })}
                  <td
                    className={styles.link}
                    onClick={handleClick}
                    id={`StaffOneSalaryRetentionUCTable_to_allHoldingEduTable_${retention.id}`}
                  >
                    Перейти
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  ) : (
    <Alert errors={{ head: "Нет информации", color: "empty" }} />
  );
};

export default observer(StaffOneSalaryRetentionUCTable);
