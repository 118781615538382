import styles from "./dateRangePickerInput.module.scss";
import { ChangeEvent, useRef } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import StatusIcon from "shared/ui/StatusIcon";
import NumberFormat from "react-number-format";
import { Input } from "shared/ui/Inputs/Input";
import { Formik } from "formik";

import { isValid, parse } from "date-fns";
import { dateFormats } from "stores/utils/consts";
import { validationDateRangePickerInput } from "./validation";
import { classNames } from "shared/utils/helpers/classNames";

type DateRangePickerInputProps = {
  startDate: Date;
  setStartDate: (value: Date) => void;
  endDate: Date;
  setEndDate: (value: Date) => void;
  inputStartDateValue: string;
  inputEndDateValue: string;
  labelForMainField: string;
  setIsCalendarOpened: (isCalendarOpened: boolean) => void;
  labelForSiblingField: string;
  setInputError: (arg: boolean) => void;
};

// проверка даты вынесена вне компонента, чтобы isValid от date-fns не конфликтовал с isValid от Formik'a
const checkValue = (value: string) => {
  return value && isValid(parse(value, dateFormats.date.format, new Date()));
};

const DateRangePickerInput = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  labelForMainField,
  inputStartDateValue,
  inputEndDateValue,
  setIsCalendarOpened,
  labelForSiblingField,
  setInputError
}: DateRangePickerInputProps) => {
  const ref = useRef();

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        event["srcElement"]["className"].includes("react-datepicker") ||
        event["srcElement"]["className"].includes("dateRangePicker_children") ||
        event["srcElement"]["className"].includes(
          "dateRangePicker_btnSaveDiv"
        ) ||
        event["srcElement"]["alt"] === "prevMonth" ||
        event["srcElement"]["alt"] === "nextMonth"
      )
        return;
      setTimeout(() => {
        setIsCalendarOpened(false);
      }, 200);
    }
  });

  const handleSetDate =
    (type: "start" | "end") => (event: ChangeEvent<HTMLInputElement>) => {
      if (checkValue(event.target.value)) {
        const date = parse(
          event.target.value,
          dateFormats.date.format,
          new Date()
        );
        type === "start" && setStartDate(date);
        type === "end" && setEndDate(date);
      }
    };

  return (
    <Formik
      initialValues={{
        startDate: inputStartDateValue,
        endDate: inputEndDateValue
      }}
      onSubmit={() => null}
      validationSchema={validationDateRangePickerInput}
      enableReinitialize
    >
      {({ isValid }) => {
        setTimeout(() => {
          setInputError(!isValid);
        }, 200);
        return (
          <div className={styles.inputsRow} ref={ref}>
            <div
              className={classNames(styles.input, {
                [styles.input__active]: startDate && !endDate
              })}
            >
              <NumberFormat
                name="startDate"
                mask="_"
                label={labelForMainField || "Дата, от"}
                format={dateFormats.date.format.replace(/[^.-/-]/g, "#")}
                onChange={handleSetDate("start")}
                customInput={Input}
              />
              <div className={styles.inputImg}>
                <StatusIcon icon="iconcalendar" color="bw-gray5" />
              </div>
            </div>
            <div
              className={classNames(styles.input, {
                [styles.input__active]: Boolean(endDate)
              })}
            >
              <NumberFormat
                name="endDate"
                mask="_"
                label={labelForSiblingField ? labelForSiblingField : "до"}
                format={dateFormats.date.format.replace(/[^.-/-]/g, "#")}
                onChange={handleSetDate("end")}
                customInput={Input}
              />
              <div className={styles.inputImg}>
                <StatusIcon icon="iconcalendar" color="bw-gray5" />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default DateRangePickerInput;
