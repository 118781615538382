import styles from "./buildingOneWorkshiftStafftableInputShow.module.scss";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { useFormikContext } from "formik";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { classNames } from "shared/utils/helpers/classNames";

interface IBuildingOneWorkshiftStafftableInputShowProps
  extends WorkshiftStaffFieldProps {
  setIsMenuOpened: (value: boolean) => void;
  setIsTouched: (value: string) => void;
}

const BuildingOneWorkshiftStafftableInputShow = ({
  name,
  staffIndex,
  setIsMenuOpened,
  setIsTouched
}: IBuildingOneWorkshiftStafftableInputShowProps) => {
  const { errors, values } = useFormikContext<StaffType[]>();

  if (name === "group")
    return (
      <div
        className={classNames(styles.showBlock, {
          [styles.showBlock_error]: errors[staffIndex]?.[name]
        })}
        onClick={() => setIsTouched(values[staffIndex].uid)}
      >
        <div>{values[staffIndex][name]}</div>
        <IconExpand
          id="BuildingOneWorkshiftStafftableInputShow_menuOpen"
          onClick={() => setIsMenuOpened(true)}
        />
      </div>
    );
};

export default BuildingOneWorkshiftStafftableInputShow;
