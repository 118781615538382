import { useEffect, useState } from "react";
import { Filter } from "stores/PolatiAppModule/types/Filter";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { getValues } from "shared/utils/helpers/getValues";

type PolatiAppVacantRequestFilterProps = {
  filters: { [key: string]: { id: string; title: string } };
  getDataWithFilter: (filter: Filter) => void;
};

const PolatiAppVacantRequestFilter = ({
  filters,
  getDataWithFilter
}: PolatiAppVacantRequestFilterProps) => {
  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    !isEmpty(filters) ? setSelectedFilter(getEntries(filters)[0][0]) : null;
  }, []);

  const handleSelectedFilter = (id: string) => {
    selectedFilter !== id && setSelectedFilter(id);
    getDataWithFilter(filters[id] as Filter);
  };

  return (
    <ButtonsTabs
      tabs={
        getValues(filters) as {
          title: string;
          id: string;
        }[]
      }
      selectedTab={selectedFilter}
      changeActiveTab={handleSelectedFilter}
    />
  );
};

export default PolatiAppVacantRequestFilter;
