import { makeAutoObservable } from "mobx";
import RootStore from "stores";

import {
  mainMenuDict,
  mainInformationDict,
  widgetsDict,
  referenceInfoDict,
  Dict,
  DictionaryElement,
  MainMenuDict
} from "shared/utils/dictionaries/cardSettingsDict";

type FilterDataType = {
  check_col: string;
  cols: Array<string>;
  custom: { access_show: string; dismissal: string };
  ordered: string;
};

export type MenuDict = {
  id: string | number;
  title: string;
  ordered: string | number;
};

export type MainInformationDict = {
  title: string;
  filterdata: FilterDataType;
};

export default class StaffCardSettingsStore {
  error = false;
  errorMessage = "";
  isLoading = false;

  mainMenu: MainMenuDict = mainMenuDict;
  submenu: Dict = mainInformationDict;
  widgets: Dict = widgetsDict;
  referenceInfo: Dict = referenceInfoDict;

  selectedItemOfMainMenu: Partial<MenuDict> = {
    id: "1",
    title: "Основная информация",
    ordered: 1
  };

  selectedItemOfSubmenu: DictionaryElement = {
    title: "Фотография и ФИО",
    filterdata: {
      check_col: "",
      cols: [
        "dismiss_date",
        "dismiss_reason",
        "dismiss_comment",
        "dismiss_position",
        "dismiss_object",
        "black_list",
        "grey_list",
        "black_reason",
        "grey_reason",
        "labor_book_number",
        "labor_book_track_number"
      ],
      custom: {
        access_show: "1",
        dismissal: "dismissal"
      },
      ordered: "1"
    }
  };

  setSelectedItemOfMainMenu = (id: string) => {
    Object.values(this.mainMenu).map((item) => {
      if (item["id"] === id) {
        this.selectedItemOfMainMenu = item;
        switch (this.selectedItemOfMainMenu["title"]) {
          case "Основная информация":
            this.submenu = mainInformationDict;
            break;
          case "Виджеты":
            this.submenu = this.widgets;
            break;
          case "Справочная информация":
            this.submenu = this.referenceInfo;
            break;
        }
        this.selectedItemOfSubmenu = this.submenu[0];
      }
    });
  };

  setSelectedItemOfSubmenu = (title: string) => {
    this.submenu.map((item) => {
      if (item["title"] === title) {
        this.selectedItemOfSubmenu = item;
      }
    });
  };

  updateSubmenu = (array: DictionaryElement[]) => {
    const newMenu: DictionaryElement[] = [];
    array.forEach((item: DictionaryElement, index: number) => {
      this.submenu.forEach((el) => {
        if (item["title"] === el["title"]) {
          el["filterdata"]["ordered"] = index + 1;
          newMenu.push(el);
        }
      });
    });
    this.submenu = newMenu;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
