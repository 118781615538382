import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import styles from "./cardOne.module.scss";
import { Link } from "react-router-dom";
import { classNames } from "shared/utils/helpers/classNames";

type CardOneProps = {
  height?: "small" | "big";
  title: string;
  subtitle?: string;
  number?: number;
  link_id?: string;
};

const CardOne = ({
  height = "small",
  title,
  subtitle,
  number,
  link_id
}: CardOneProps) => {
  return (
    <div
      className={classNames(styles.card, {
        [styles.smallCard]: height === "small",
        [styles.bigCard]: height !== "small"
      })}
    >
      <div>
        <div className={styles.cardTitle}>{title}</div>
        {subtitle && link_id ? (
          <Link
            to={`/staff/id=${link_id}`}
            id={`CardOne_${link_id}`}
            target="_blank"
            className={styles.link}
          >
            <div className={styles.cardSubtitle}>{subtitle}</div>
          </Link>
        ) : subtitle ? (
          <div className={styles.cardSubtitle}>{subtitle}</div>
        ) : null}
      </div>
      {number !== undefined && (
        <div className={styles.cardNumber}>
          {number === null ? "-" : numberWithSpaces(number)}
        </div>
      )}
    </div>
  );
};

export default CardOne;
