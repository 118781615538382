import { Field } from "formik";
import styles from "./radio.module.scss";

type RadioButtonProps = {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent) => void;
  onFocus?: (event: React.MouseEvent) => void;
  onBlur?: (event: React.MouseEvent) => void;
};

const RadioButton = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur
}: RadioButtonProps) => {
  return (
    <Field
      type="radio"
      className={styles.radio}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default RadioButton;
