import { makeAutoObservable } from "mobx";
import RootStore from "stores";

export default class ModalWindowsStore {
  isErrorWindow = false;

  setIsErrorWindow = (value: boolean) => {
    this.isErrorWindow = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
