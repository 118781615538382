import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { QualityType } from "./types/QualityType";
import { RatingStaff } from "./types/Rating";

type Rating = {
  title: string;
  active: number;
  date_start: string;
  date_end: string;
  involved_bage: { [key: string]: string };
  time_create: string;
  id: string;
  finance_block: number;
  staffs_to_show: number;
  find_staff_interval: number;
};

type RatingList = {
  title: string;
  active: number;
  id: string;
};
export default class StaffRatingStore {
  error = false;
  isLoading = false;
  isLoadingForTable = false;
  isLoadingForDetails = false;
  searchValue = "";
  isOpenCoefficientTableWindow = false;
  selectedStaff: RatingStaff | null = null;
  staffDetails: { [key: string]: QualityType[] } = {};

  ratingStaffList: {
    [key: string]: { [key: string]: { [key: string]: RatingStaff } };
  } = {};

  // объект в котором ключ это оценка (от 1 до 5), а значение - количество этих оценок у сотрудника
  staffRatingRanks: {
    [key: string]: number;
  } = {};

  // объект в котором ключ это id качества (например "Всегда придет на помощь"),
  // а значение - количество этого отмеченного качества у сотрудника
  staffRatingQualities: {
    [key: string]: number;
  } = {};

  // активная кнопка рейтинга по годам
  activeRatingYearTab = "";
  // массив кнопок по рейтингам по годам
  ratingYearsTabs: RatingList[] = [];

  // активная вкладка SwitchedTabs по ТОПам
  activeSwitchedTab = "";

  itrSwitchedTabs = [
    { id: "inTop", title: "Топ-100" },
    { id: "notInTop", title: "Не топы" },
    { id: "outOfRating", title: "Не в рейтинге" }
  ];

  // для номеров страниц в соответствии с вкладками по ТОПам при вызове getRatingStaff
  itrSwitchedTabsId = {
    inTop: 1,
    notInTop: 2,
    outOfRating: 3
  };

  setIsOpenCoefficientTableWindow = (value: boolean) => {
    this.isOpenCoefficientTableWindow = value;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setActiveRatingYearTab = (activeRatingYearTab: string) => {
    this.activeRatingYearTab = activeRatingYearTab;
    this.setActiveSwitchedTab("inTop");
    this.getRatingStaff(activeRatingYearTab, this.activeSwitchedTab);
  };

  setActiveSwitchedTab = (activeSwitchedTab: string) => {
    this.activeSwitchedTab = activeSwitchedTab;
    this.getRatingStaff(this.activeRatingYearTab, this.activeSwitchedTab);
  };

  setSelectedStaff = (selectedStaff: RatingStaff) => {
    this.selectedStaff = selectedStaff;
  };

  // Получаем справочник рейтингов ИТР по годам
  getRatingList = async () => {
    this.isLoading = true;
    try {
      const data: ApiResponse<{ [key: string]: Rating }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getRatingList"
        });

      runInAction(() => {
        if (data["code"] === 200 && Object.values(data.result).length) {
          this.ratingYearsTabs = Object.values(data["result"]);
          this.ratingYearsTabs.forEach((tab) => {
            tab.active && (this.activeRatingYearTab = tab.id);
          });
          this.getRatingStaff(this.activeRatingYearTab, this.activeSwitchedTab);
        } else {
          this.ratingYearsTabs = [];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  // Получаем список сотрудников по id ИТР на определенный год
  getRatingStaff = async (rating_id: string, activeSwitchedTab: string) => {
    this.isLoadingForTable = true;

    const params: { rating_id: string; search: string; page?: number } = {
      rating_id,
      search: this.searchValue
    };
    if (activeSwitchedTab) {
      params.page = this.itrSwitchedTabsId[this.activeSwitchedTab];
    }

    try {
      const data: ApiResponse<{ [key: string]: RatingStaff }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getRatingStaff",
          params: params
        });

      runInAction(() => {
        if (data["code"] === 200) {
          if (this.ratingStaffList[rating_id] === undefined) {
            this.ratingStaffList[rating_id] = {};
          }
          this.ratingStaffList[rating_id][this.activeSwitchedTab] =
            data["result"];
          // hardcode убрать когда починят фотографии на бэке
          Object.values(
            this.ratingStaffList[rating_id][this.activeSwitchedTab]
          ).map((staff) => {
            if (
              staff.photo.endsWith(".jpg") &&
              !staff.photo.includes("static")
            ) {
              staff.photo = staff.photo.replace(".jpg", ".jpeg");
            }
          });
          this.getRatingSelects();
        } else {
          this.ratingStaffList[rating_id][this.activeSwitchedTab] = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForTable = false;
      });
    }
  };

  // Получаем детали - оценки, человеческие качества и их количество
  getRatingStaffDetail = async (rating_id: string, staff_id: string) => {
    this.isLoadingForDetails = true;
    try {
      const data: {
        code: number;
        hash: string;
        ranks?: { [key: string]: { rank: number; count: number } };
        qualities?: { [key: string]: { quality: string; count: number } };
        message?: Omit<Errors["message"], "body">;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getRatingStaffDetail",
        params: {
          rating_id,
          staff_id
        }
      });

      runInAction(() => {
        if (data["code"] === 200) {
          Object.values(data["ranks"]).forEach((rank) => [
            (this.staffRatingRanks[rank.rank] = rank.count)
          ]);

          Object.values(data["qualities"]).forEach((quality) => [
            (this.staffRatingQualities[quality.quality] = quality.count)
          ]);
        } else {
          this.staffRatingRanks = {};
          this.staffRatingQualities = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForDetails = false;
      });
    }
  };

  // Получаем справочник по качествам (qualities)
  getRatingSelects = async () => {
    try {
      const data: ApiResponse<{
        quality_type: { [key: string]: QualityType };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getRatingSelects"
      });

      runInAction(() => {
        if (data["code"] === 200 && Object.values(data.result).length) {
          this.staffDetails = {};
          Object.values(data["result"]["quality_type"]).forEach((quality) => {
            if (!this.staffDetails[quality.custom["rating"]]) {
              this.staffDetails[quality.custom["rating"]] = [];
            }
            this.staffDetails[quality.custom["rating"]].push(quality);
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
