import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import AwardsHeader from "./AwardsHeader";
import AwardsSearch from "./AwardsSearch";
import AwardsFields from "./AwardsFields";
import { useEffect } from "react";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

const StaffOneAwards = () => {
  const { menuStore, staffOneAwardsStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneAwardsStore.setSelectedOneForAllAwards(id);
    staffOneAwardsStore.setIsFocusSearch(false);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffAwards");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/awards`
    });
  }, [id]);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsHeader />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsSearch id={id} />
      </ErrorBoundary>
      {getValues(staffOneAwardsStore.awardGroups).map((field) => {
        const filteredAwards = Object.fromEntries(
          getEntries(staffOneAwardsStore.awards).filter(([, value]) =>
            staffOneAwardsStore.searchValue
              ? String(value.type_title.toLowerCase()).includes(
                  staffOneAwardsStore.searchValue.toLowerCase()
                ) &&
                staffOneAwardsStore.awardGroups[value.custom.group].title ===
                  field.title
              : staffOneAwardsStore.awardGroups[value.custom.group].title ===
                field.title
          )
        );
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback} key={field.id}>
            <AwardsFields awards={filteredAwards} field={field.title} />
          </ErrorBoundary>
        );
      })}
    </>
  );
};

export default observer(StaffOneAwards);
