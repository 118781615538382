import { classNames } from "shared/utils/helpers/classNames";
import styles from "./textField.module.scss";

export enum TextFieldTheme {
  LINK = "link"
}

type TextFieldProps = {
  /**
   * Заголовок
   */
  title: string;
  /**
   * Значение - текст/число или JSX.Element
   */
  value: React.ReactNode;
  /**
   * Вид значения, передаем из enum TextFieldTheme
   */
  theme?: string;
  /**
   * Стили, заданные в родителе для поля, заголовка и значения отдельно
   */
  className?: { field?: string; title?: string; value?: string };
};

export const TextField = ({
  title,
  className,
  value,
  theme
}: TextFieldProps) => {
  return (
    <div className={classNames(styles.field, {}, [className?.field])}>
      <p className={classNames(styles.title, {}, [className?.title])}>
        {title}
      </p>
      <div
        className={classNames(
          styles.value,
          {
            [styles[theme]]: theme
          },
          [className?.value]
        )}
      >
        {value || <br />}
      </div>
    </div>
  );
};

export type TextFieldAttrs = JSX.LibraryManagedAttributes<
  typeof TextField,
  TextFieldProps
>;
