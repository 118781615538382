import styles from "./staffOneSafetyWork.module.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";

import StaffOneSafetyWorkContent from "./StaffOneSafetyWorkContent";
import StaffOneSafetyWorkComments from "./StaffOneSafetyWorkComments";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffOneSafetyWork = () => {
  const { menuStore, staffOneSafetyWorkStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneSafetyWorkStore.setSelectedOneForCertificates(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffSafetyWork");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/safety_work`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneSafetyWorkStore.isLoading}
      error={staffOneSafetyWorkStore.error}
    >
      <>
        <PageTitle title="Охрана труда" leftCol />
        <div className={styles.container}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOneSafetyWorkContent />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOneSafetyWorkComments />
          </ErrorBoundary>
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSafetyWork);
