import styles from "./staffOneWorkshiftHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Card from "shared/ui/Cards/Card";
import { useParams } from "react-router-dom";

// import { Link } from "react-router-dom";
import { fileUrl } from "stores/utils/consts";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";

const worshift_cards: {
  [key: string]: { title: string; tooltip_text: string; color?: string };
} = {
  current_tour: {
    title: "Смен в вахте",
    tooltip_text:
      "Количество непрерывно отработанных (без отпуска) и закрытых смен сотрудника на текущем объекте.",
    color: "blue-blue"
  },
  count_work_all: {
    title: "Смен всего",
    tooltip_text:
      "Общее количество закрытых отработанных смен с учётом смен до августа 2021 года.",
    color: "amber"
  },
  count_work_all_edu: {
    title: "Смен для расчёта вычета УЦ",
    tooltip_text:
      "Количество закрытых смен с августа 2021 года + количество смен для подарков до августа 2021 года (данные из Google Sheets). Полученное число смен учитывается в расчёте вычета за УЦ."
  },
  count_work_from_august: {
    title: "Смен с августа 2021",
    tooltip_text: "Количество закрытых отработанных смен с августа 2021 года."
  },
  count_work_before_august: {
    title: "Смен до августа 2021",
    tooltip_text:
      "Количество закрытых отработанных смен до августа 2021 года. Тогда учёт смен не вёлся в ERP, поэтому эти данные приходят из Google Sheets."
  }
};

const StaffOneWorkshiftHeader = () => {
  const { staffOneAllSectionsStore } = useStores();
  const { id } = useParams();

  return staffOneAllSectionsStore.workshiftTotal ? (
    <div className={styles.cards}>
      {Object.entries(worshift_cards).map(([key, col]) => {
        return (
          <Card
            key={key}
            data={[
              {
                title: col.title,
                tooltip_text: col.tooltip_text,
                value:
                  key === "current_tour"
                    ? staffOneAllSectionsStore.workshiftTotal.current_tour
                        ?.count
                    : staffOneAllSectionsStore.workshiftTotal[key] || ""
              }
            ]}
            color={
              key === "current_tour" &&
              staffOneAllSectionsStore.allWidgetMetrics[id]?.color
                ? staffOneAllSectionsStore.allWidgetMetrics[id].color
                : col.color || "gray1"
            }
            custom_data={
              key === "current_tour" &&
              staffOneAllSectionsStore.allWidgetMetrics[id] ? (
                <div className={styles.status}>
                  <div className={styles.status__header}>
                    <Badge
                      text={
                        staffOneAllSectionsStore.allWidgetMetrics[id]
                          .company_title
                      }
                      colorName={
                        staffOneAllSectionsStore.companiesData
                          ? getValues(
                              staffOneAllSectionsStore.companiesData
                            ).find(
                              (company) =>
                                company.title ===
                                staffOneAllSectionsStore.allWidgetMetrics[id]
                                  .company_title
                            )?.["color"]
                          : "primary"
                      }
                    />
                    <Badge
                      text={
                        staffOneAllSectionsStore.allWidgetMetrics[id].status
                      }
                      textColorName="bw-gray7"
                      icon={staffOneAllSectionsStore.allWidgetMetrics[id].icon}
                      iconColor={
                        staffOneAllSectionsStore.allWidgetMetrics[id].color
                      }
                      colorName="none"
                    />
                  </div>
                  <a
                    className={styles.status__building}
                    href={`${fileUrl}/crm/building/?id=${staffOneAllSectionsStore.allWidgetMetrics[id].building_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      staffOneAllSectionsStore.allWidgetMetrics[id]
                        .building_title
                    }
                  </a>
                  {/* <Link
                    className={styles.status__building}
                    to={`../../building/id=${staffOneAllSectionsStore.allWidgetMetrics[id].building_id}/timesheet`}
                    target="_blank"
                  >
                    {
                      staffOneAllSectionsStore.allWidgetMetrics[id]
                        .building_title
                    }
                  </Link> */}
                </div>
              ) : undefined
            }
          />
        );
      })}
    </div>
  ) : null;
};

export default observer(StaffOneWorkshiftHeader);
