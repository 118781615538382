import styles from "./buildingOneStaffTableSafetyworkTable.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { Table } from "react-bootstrap";
import { useStores } from "stores";
import StatusIcon from "shared/ui/StatusIcon";

type BuildingOneStaffTableSafetyworkTableProps = {
  staff_id: string;
};

const currentTitles = ["kind", "type", "status"];

const statusMessage = {
  valid: { message: "Ok" },
  warning: {
    message: "Скоро истечет",
    icon: "hourglass",
    color: "accent-orange"
  },
  danger: {
    message: "Не действителен",
    icon: "attention",
    color: "accent-coral"
  },
  no_data: { message: "Нет данных", color: "bw-gray4" }
};

const BuildingOneStaffTableSafetyworkTable = ({
  staff_id
}: BuildingOneStaffTableSafetyworkTableProps) => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  return (
    <Table className={styles.table}>
      <tbody>
        {buildingOneStaffStore.safetyworkWidgets[id]?.[staff_id] &&
        !buildingOneStaffStore.safetyworkWidgets[id][staff_id].error ? (
          Object.values(
            buildingOneStaffStore.safetyworkWidgets[id][staff_id]
          ).map((document) => {
            const status = !Object.values(document.document).length
              ? "no_data"
              : document.document.fey
              ? "warning"
              : !document.document.valid
              ? "danger"
              : "valid";
            return (
              <tr
                key={`${staff_id}_${document.kind}_${document.type}`}
                className={styles[`document_${statusMessage[status]["color"]}`]}
              >
                {currentTitles.map((title) => {
                  switch (title) {
                    case "status":
                      return (
                        <td key={title}>
                          <div className={styles.status}>
                            <p>{statusMessage[status]?.message}</p>
                            {Object.values(document.document).length &&
                            (document.document.fey ||
                              !document.document.valid) ? (
                              <StatusIcon
                                icon={statusMessage[status]?.["icon"]}
                                color={statusMessage[status]?.["color"]}
                              />
                            ) : null}
                          </div>
                        </td>
                      );
                    default:
                      return <td key={title}>{document[title]}</td>;
                  }
                })}
              </tr>
            );
          })
        ) : buildingOneStaffStore.safetyworkWidgets[id]?.[staff_id]?.error ? (
          <tr>
            <td>
              {
                buildingOneStaffStore.safetyworkWidgets[id][staff_id]
                  .error as string
              }
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
};

export default observer(BuildingOneStaffTableSafetyworkTable);
