import styles from "./salaryPaymentlistOneInfoHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStores } from "stores";

import Tooltip from "shared/ui/Tooltip";

type SalaryPaymentlistOneInfoHeaderProps = {
  isSelectedPayment: boolean;
  getStatusIcon: (status_id: string) => JSX.Element;
};

const SalaryPaymentlistOneInfoHeader = ({
  isSelectedPayment,
  getStatusIcon
}: SalaryPaymentlistOneInfoHeaderProps) => {
  const { menuStore, salaryPaymentlistOneStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (!isSelectedPayment) {
      if (menuStore.scroll.scrollTop >= 30 && !isScroll) {
        setTimeout(() => {
          setIsScroll(true);
        }, 100);
      } else if (menuStore.scroll.scrollTop < 28 && isScroll) {
        setTimeout(() => {
          setIsScroll(false);
        }, 100);
      }
    }
  }, [menuStore.scroll.scrollTop]);

  return (
    <div
      className={`${styles.header} ${
        isScroll ? styles.header_sticky : styles.header_normal
      }`}
    >
      <div
        className={
          styles[
            `header_${
              salaryPaymentlistOneStore.paymentStatuses[
                salaryPaymentlistOneStore.selectedOnePayment.status
              ]?.custom.color
            }`
          ]
        }
      >
        <div className={styles.header__title}>{`Ведомость на ${
          salaryPaymentlistOneStore.selectedOnePayment.staff?.id
            ? "сотрудника"
            : "объект"
        }:`}</div>

        <Tooltip
          text={`Перейти в карточку ${
            salaryPaymentlistOneStore.selectedOnePayment.staff?.id
              ? "сотрудника"
              : "объекта"
          }`}
        >
          <Link
            to={`/${
              salaryPaymentlistOneStore.selectedOnePayment.staff?.id
                ? "staff"
                : "building"
            }/id=${
              salaryPaymentlistOneStore.selectedOnePayment.staff?.id
                ? salaryPaymentlistOneStore.selectedOnePayment.staff.id
                : salaryPaymentlistOneStore.selectedOnePayment.object.id
            }`}
            id={`SalaryPaymentlistOneInfoHeader_Link_${
              salaryPaymentlistOneStore.selectedOnePayment.staff?.id
                ? salaryPaymentlistOneStore.selectedOnePayment.staff.id
                : salaryPaymentlistOneStore.selectedOnePayment.object.id
            }`}
            className={styles.header__link}
            target="_blank"
          >
            {salaryPaymentlistOneStore.selectedOnePayment.staff?.id
              ? `TH${salaryPaymentlistOneStore.selectedOnePayment.staff.uid} ${
                  salaryPaymentlistOneStore.selectedOnePayment.staff.surname
                } ${salaryPaymentlistOneStore.selectedOnePayment.staff.name}${
                  salaryPaymentlistOneStore.selectedOnePayment.staff.patronymic
                    ? " " +
                      salaryPaymentlistOneStore.selectedOnePayment.staff
                        .patronymic
                    : ""
                }`
              : salaryPaymentlistOneStore.selectedOnePayment.object.title}
          </Link>
        </Tooltip>

        <div
          className={`${styles.header__status} ${
            styles[
              `header__status_${
                salaryPaymentlistOneStore.paymentStatuses[
                  salaryPaymentlistOneStore.selectedOnePayment.status
                ]?.custom.color
              }`
            ]
          }`}
        >
          {getStatusIcon(salaryPaymentlistOneStore.selectedOnePayment.status)}
          {
            salaryPaymentlistOneStore.paymentStatuses[
              salaryPaymentlistOneStore.selectedOnePayment.status
            ]?.title
          }
        </div>

        {["uid", "time_create", "date_start"].map((field) => {
          if (salaryPaymentlistOneStore.paymentParams[field]?.access_show) {
            switch (field) {
              case "uid":
                return (
                  <div
                    className={`${styles.header__info} ${
                      isScroll
                        ? styles.header__info_block
                        : styles.header__info_none
                    }`}
                    key={`header_${field}`}
                  >
                    <div className={styles.header__info__title}>
                      {salaryPaymentlistOneStore.paymentCols[field].title}
                    </div>
                    <div className={styles.header__info__value}>
                      {salaryPaymentlistOneStore.selectedOnePayment[field]}
                    </div>
                  </div>
                );
              case "time_create":
                return !salaryPaymentlistOneStore.selectedOnePayment.staff ? (
                  <div
                    className={`${styles.header__info} ${
                      isScroll
                        ? styles.header__info_block
                        : styles.header__info_none
                    }`}
                    key={`header_${field}`}
                  >
                    <div className={styles.header__info__title}>
                      {salaryPaymentlistOneStore.paymentCols[field].title}
                    </div>
                    <div className={styles.header__info__value}>
                      {salaryPaymentlistOneStore.selectedOnePayment.time_create_formatted?.slice(
                        0,
                        10
                      )}
                    </div>
                  </div>
                ) : null;
              case "date_start":
                return (
                  <div
                    className={`${styles.header__info} ${
                      isScroll
                        ? styles.header__info_block
                        : styles.header__info_none
                    }`}
                    key={`header_${field}`}
                  >
                    <div className={styles.header__info__title}>
                      {!salaryPaymentlistOneStore.selectedOnePayment.staff
                        ? "Период"
                        : "Дата ведомости"}
                    </div>
                    <div className={styles.header__info__value}>
                      {!salaryPaymentlistOneStore.selectedOnePayment.staff
                        ? `${salaryPaymentlistOneStore.selectedOnePayment.date_start_formatted} - ${salaryPaymentlistOneStore.selectedOnePayment.date_end_formatted}`
                        : salaryPaymentlistOneStore.selectedOnePayment
                            .date_start_formatted}
                    </div>
                  </div>
                );
            }
          }
        })}
      </div>
    </div>
  );
};

export default observer(SalaryPaymentlistOneInfoHeader);
