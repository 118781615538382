export let access_key = "";
export let user_id = "";
export const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;
export const fileUrl = process.env.REACT_APP_API_URL;

export const changeAccessKey = (value: string) => {
  access_key = value;
};

export const changeUserId = (value: string) => {
  user_id = value;
};

export const dateFormats = {
  timestamp: {
    format: "",
    placeholder: ""
  },
  date: {
    format: "",
    placeholder: ""
  },
  time: {
    format: "",
    placeholder: ""
  }
};

export const changeDateFormats = (
  key: string,
  format: string,
  placeholder: string
) => {
  dateFormats[key] = {
    format: format,
    placeholder: placeholder
  };
};
