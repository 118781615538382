import styles from "./routerComponentButton.module.scss";
import { useStores } from "stores/index";

import iconArrowTop from "shared/assets/images/mainIcons/iconArrowTop.svg";
import { observer } from "mobx-react-lite";

type RouterComponentButtonProps = {
  pathname: string;
};

const RouterComponentButton = ({ pathname }: RouterComponentButtonProps) => {
  const { menuStore } = useStores();

  // Исключение, при которых не нужно отображать кнопку ScrollToTop
  if (pathname === "/aregister/report") return;

  return (
    <button
      id="RouterComponentButton_scrollToTop"
      onClick={() => {
        menuStore.scrollbarRef.current.scrollToTop();
      }}
      className={styles.scrollToTop}
      style={{ opacity: menuStore.isScroll ? 1 : 0 }}
    >
      <img src={iconArrowTop} alt="Scroll to top" />
    </button>
  );
};

export default observer(RouterComponentButton);
