import styles from "./staffOneStatusesTableOne.module.scss";
import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import StaffOneStatusesWindow from "../../StaffOneStatusesWindow";
import StatusIcon from "shared/ui/StatusIcon";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpandBackground.svg";

// import { Link } from "react-router-dom";
import { classNames } from "shared/utils/helpers/classNames";

import { Status } from "stores/StaffModule/types/Status";
import { fileUrl } from "stores/utils/consts";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneStatusesTableOneProps = {
  objectItem: {
    [key: string]: Status;
  };

  buildingIndex: number;
  isFirstSubVacation: boolean;
  isLastSubVaсation: boolean;
  isActiveStatus: boolean;
  allObject: {
    [key: string]: {
      [key: string]: Status;
    };
  };
  isMoreInfoOpen: boolean;
  setIsMoreInfoOpen: () => void;
};

// hardcode
const statusTitles = ["time_create", "author"];

// hardcode
const transferStatus = "a0f6708450dcb7ff0895a240457396a705a28811";

const StaffOneStatusesTableOne = ({
  objectItem,
  buildingIndex,
  isFirstSubVacation,
  isLastSubVaсation,
  isActiveStatus,
  allObject,
  isMoreInfoOpen,
  setIsMoreInfoOpen
}: StaffOneStatusesTableOneProps) => {
  const { staffOneStatusesStore } = useStores();

  const lastStatusInObject = Object.values(objectItem)[0];

  return (
    <>
      <div key={lastStatusInObject["id"]} className="d-flex">
        <div className={styles.buildingNumber}>
          {Object.values(allObject).length - buildingIndex}
        </div>

        <div className="w-100">
          <div className={styles.buildingInfo}>
            <IconExpand
              className={classNames(styles.iconExpand, {
                [styles.iconExpand_opened]: isMoreInfoOpen
              })}
              onClick={() => setIsMoreInfoOpen()}
              id={`StaffOneStatusesTableOne_openInfoBtn_${lastStatusInObject?.["id"]}`}
            />
            <a
              className={styles.buildingTitle}
              href={`${fileUrl}/crm/building/?id=${lastStatusInObject?.["building"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {lastStatusInObject?.["building_title"]}{" "}
            </a>
            {/* <Link
              className={styles.buildingTitle}
              to={`../../building/id=${lastStatusInObject?.["building"]}/timesheet`}
              target="_blank"
            >
              {lastStatusInObject?.["building_title"]}{" "}
            </Link> */}
            <img src={Buildings} className={styles.citiesImg} />
            <div className={styles.buildingStatusDate}>
              {getFormattedDate(
                Object.values(objectItem)[
                  Object.values(objectItem).length - 1
                ]?.[isFirstSubVacation ? "sub_start_date" : "event_start"]
              )}{" "}
              -{" "}
              {isActiveStatus
                ? "по настоящее время"
                : getFormattedDate(
                    lastStatusInObject?.[
                      isLastSubVaсation
                        ? "sub_end_date"
                        : lastStatusInObject["event_end"]
                        ? "event_end"
                        : "event_start"
                    ]
                  )}
            </div>
          </div>
          {isMoreInfoOpen ? (
            <>
              {Object.values(objectItem).map((statusItem, ind) => {
                const isSubDate = Boolean(
                  staffOneStatusesStore.statusesParams[statusItem.type].custom
                    .sub_date
                );
                const isSubVacation =
                  Boolean(
                    statusItem["sub_start_date"] && statusItem["sub_end_date"]
                  ) && isSubDate;
                const isLastActiveStatus =
                  ind === 0 &&
                  staffOneStatusesStore.statusesParams[statusItem.type].custom
                    .active &&
                  buildingIndex === 0 &&
                  calculateDays(statusItem["event_start"], new Date()) > 0;

                // Проверяем совпадают ли полностью даты отпусков Ое и ОМ
                const isSameDates =
                  isSubDate &&
                  Boolean(
                    statusItem["event_start"] ===
                      statusItem["sub_start_date"] &&
                      statusItem["event_end"] === statusItem["sub_end_date"]
                  );

                const getSecondaryInfo = (col: string) => {
                  return (
                    <div key={col} className={styles.titleAndValue}>
                      {`${staffOneStatusesStore.statusesCols[col].title}: ${
                        col === "time_create"
                          ? getFormattedDate(statusItem[col].slice(0, 10))
                          : statusItem[col]
                      }`}
                    </div>
                  );
                };
                if (!isSubVacation) {
                  return (
                    <Fragment key={statusItem["id"]}>
                      <div
                        className={classNames(
                          styles.statusOneBlock,
                          {
                            [styles.statusOneBlock_selected]:
                              staffOneStatusesStore.selectedRow ===
                              statusItem["id"]
                          },
                          [
                            styles[
                              `statusOneBlock_${
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.color
                              }`
                            ]
                          ]
                        )}
                        id={`StaffOneStatusesTableOne_${statusItem["id"]}`}
                        onClick={staffOneStatusesStore.handleSetSelectedStatus(
                          statusItem
                        )}
                      >
                        <div className={styles.mainInfo}>
                          <div className={styles.statusTitleAndDate}>
                            <StatusIcon
                              icon={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.icon
                              }
                              color={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.color_api
                              }
                            />
                            <p>
                              {statusItem["type_title"]}{" "}
                              {statusItem["bage"] ? (
                                statusItem["bage"]
                              ) : isSubDate ? (
                                <span
                                  className={classNames(
                                    styles.vacationMark,
                                    {},
                                    [
                                      styles[
                                        `vacationMark_${
                                          staffOneStatusesStore.statusesParams[
                                            statusItem.type
                                          ].custom.color_api
                                        }`
                                      ]
                                    ]
                                  )}
                                >
                                  Ом
                                </span>
                              ) : transferStatus === statusItem["type"] ? (
                                statusItem["parent"] ? (
                                  "(входящий)"
                                ) : (
                                  "(исходящий)"
                                )
                              ) : (
                                ""
                              )}
                            </p>
                            <p className={styles.dateValue}>
                              {getFormattedDate(statusItem["event_start"])}{" "}
                              {!statusItem["event_end"]
                                ? ""
                                : isLastActiveStatus
                                ? "- по настоящее время"
                                : `- ${getFormattedDate(
                                    statusItem["event_end"]
                                  )}`}
                            </p>
                          </div>
                          {calculateDays(
                            statusItem["event_start"],
                            new Date()
                          ) > 0 ? (
                            <p className={styles.inStatus}>
                              Дней в статусе:{" "}
                              <span>{statusItem.days_in_status}</span>
                            </p>
                          ) : (
                            <p className={styles.inStatus}>
                              Статус начнется через{" "}
                              <span>{statusItem.days_in_status}</span>{" "}
                              {getUnits(
                                statusItem.days_in_status,
                                "день",
                                "дня",
                                "дней"
                              )}
                            </p>
                          )}
                        </div>
                        <div className="d-flex">
                          {statusTitles.map((title) => getSecondaryInfo(title))}
                        </div>
                      </div>
                      {staffOneStatusesStore.selectedRow ===
                      statusItem["id"] ? (
                        <div className={styles.windowContainer}>
                          <div className={styles.window}>
                            <StaffOneStatusesWindow
                              selectedStatus={statusItem}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  );
                } else {
                  return (
                    <div
                      key={statusItem["id"]}
                      className={classNames("", {
                        [styles.statusOneBlock_selected]:
                          staffOneStatusesStore.selectedRow === statusItem["id"]
                      })}
                    >
                      <div
                        className={classNames(styles.statusOneBlock, {}, [
                          styles[
                            `statusOneBlock_${
                              staffOneStatusesStore.statusesParams[
                                statusItem.type
                              ].custom.color
                            }`
                          ]
                        ])}
                        id={`StaffOneStatusesTableOne_${statusItem["id"]}`}
                        onClick={staffOneStatusesStore.handleSetSelectedStatus(
                          statusItem
                        )}
                      >
                        <div className={styles.mainInfo}>
                          <div className={styles.statusTitleAndDate}>
                            <StatusIcon
                              icon={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.icon
                              }
                              color={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.color_api
                              }
                            />
                            <p>
                              {statusItem["type_title"]}{" "}
                              <span
                                className={classNames(styles.vacationMark, {}, [
                                  styles[
                                    `vacationMark_${
                                      staffOneStatusesStore.statusesParams[
                                        statusItem.type
                                      ].custom.color_api
                                    }`
                                  ]
                                ])}
                              >
                                Ое
                              </span>
                            </p>
                            <p className={styles.dateValue}>
                              {getFormattedDate(statusItem["sub_start_date"])}{" "}
                              {`- ${getFormattedDate(
                                statusItem["sub_end_date"]
                              )}`}
                            </p>
                          </div>
                          {calculateDays(
                            statusItem["sub_start_date"],
                            new Date()
                          ) > 0 ? (
                            <p className={styles.inStatus}>
                              Дней в статусе:{" "}
                              <span>{statusItem.days_in_status}</span>
                            </p>
                          ) : (
                            <p className={styles.inStatus}>
                              Статус начнется через{" "}
                              <span>{statusItem.days_in_status}</span>{" "}
                              {getUnits(
                                statusItem.days_in_status,
                                "день",
                                "дня",
                                "дней"
                              )}
                            </p>
                          )}
                        </div>
                        <div className="d-flex">
                          {statusTitles.map((title) => getSecondaryInfo(title))}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          styles.statusOneBlock,
                          {
                            [styles.statusOneBlock_invisible]: isSameDates
                          },
                          [
                            styles[
                              `statusOneBlock_${
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.color
                              }`
                            ]
                          ]
                        )}
                        id={`StaffOneStatusesTableOne_${statusItem["id"]}`}
                        onClick={staffOneStatusesStore.handleSetSelectedStatus(
                          statusItem
                        )}
                      >
                        <div className={styles.mainInfo}>
                          <div className={styles.statusTitleAndDate}>
                            <StatusIcon
                              icon={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.icon
                              }
                              color={
                                staffOneStatusesStore.statusesParams[
                                  statusItem.type
                                ].custom.color_api
                              }
                            />
                            <p>
                              {statusItem["type_title"]}{" "}
                              <span
                                className={classNames(styles.vacationMark, {}, [
                                  styles[
                                    `vacationMark_${
                                      staffOneStatusesStore.statusesParams[
                                        statusItem.type
                                      ].custom.color_api
                                    }`
                                  ]
                                ])}
                              >
                                Ом
                              </span>
                            </p>
                            <p className={styles.dateValue}>
                              {getFormattedDate(statusItem["event_start"])}{" "}
                              {`- ${getFormattedDate(statusItem["event_end"])}`}
                            </p>
                          </div>
                          {calculateDays(
                            statusItem["event_start"],
                            new Date()
                          ) > 0 ? (
                            <p className={styles.inStatus}>
                              Дней в статусе:{" "}
                              <span>{statusItem.days_in_status}</span>
                            </p>
                          ) : (
                            <p className={styles.inStatus}>
                              Статус начнется через{" "}
                              <span>{statusItem.days_in_status}</span>{" "}
                              {getUnits(
                                statusItem.days_in_status,
                                "день",
                                "дня",
                                "дней"
                              )}
                            </p>
                          )}
                        </div>
                        <div className="d-flex">
                          {statusTitles.map((title) => getSecondaryInfo(title))}
                        </div>
                      </div>
                      {staffOneStatusesStore.selectedRow ===
                      statusItem["id"] ? (
                        <div className={styles.windowContainer}>
                          <div className={styles.window}>
                            <StaffOneStatusesWindow
                              selectedStatus={statusItem}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              })}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default observer(StaffOneStatusesTableOne);
