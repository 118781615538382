import styles from "./staffOneInfoAwardOne.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { fileUrl } from "stores/utils/consts";
import { useParams } from "react-router-dom";

const StaffOneInfoAwardOne = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  return (
    <>
      {Object.values(staffOneStore.awards[id]).map((award) => {
        const { id, img_src } = award;
        return (
          <img
            src={`${fileUrl + img_src}?${Math.floor(Math.random() * 100000)}`}
            className={styles.iconAward}
            alt="award"
            key={id}
          />
        );
      })}
    </>
  );
};

export default observer(StaffOneInfoAwardOne);
