import styles from "./buildingOneWorkshiftStafftable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { Formik } from "formik";

import BuildingOneWorkshiftStafftableInput from "./BuildingOneWorkshiftStafftableInput";
import BuildingOneWorkshiftStafftableHours from "./BuildingOneWorkshiftStafftableHours";
import BuildingOneWorkshiftStafftableSelect from "./BuildingOneWorkshiftStafftableSelect";
import BuildingOneWorkshiftStafftableBool from "./BuildingOneWorkshiftStafftableBool";
import BuildingOneWorkshiftStafftableThead from "./BuildingOneWorkshiftStafftableThead";
import BuildingOneWorkshiftStafftableTextarea from "./BuildingOneWorkshiftStafftableTextarea";
import BuildingOneWorkshiftStafftableWarning from "./BuildingOneWorkshiftStafftableWarning";

import { getValidationSchema } from "./validation";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";

const BuildingOneWorkshiftStaffTable = () => {
  const { buildingOneWorkshiftStore, menuStore } = useStores();
  const [tableSort, setTableSort] = useState<StaffType[]>();
  const [isMassEdit, setIsMassEdit] = useState(false);
  const { id } = useParams();

  const handleSetEditingWarning =
    (name: keyof StaffType, ws_staff_id: string) => () => {
      if (
        buildingOneWorkshiftStore.editingWarnings[name]?.includes(ws_staff_id)
      ) {
        buildingOneWorkshiftStore.deleteEditingWarning(name, ws_staff_id);
      }
    };

  useEffect(() => {
    if (buildingOneWorkshiftStore.edited[id] !== "group") {
      setTableSort(buildingOneWorkshiftStore.getTableSort());
    } else {
      setTableSort(buildingOneWorkshiftStore.getTableSort());
      buildingOneWorkshiftStore.setEdited("");
    }
  }, [
    id,
    buildingOneWorkshiftStore.searchValue[id],
    buildingOneWorkshiftStore.selectedFilters[id],
    buildingOneWorkshiftStore.staffListOrder[id],
    buildingOneWorkshiftStore.staffList[id],
    buildingOneWorkshiftStore.edited[id]
  ]);

  useEffect(() => {
    if (
      buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom?.done === "on"
    ) {
      setIsMassEdit(false);
    } else {
      switch (buildingOneWorkshiftStore.openedMode[id]) {
        case "show":
          isMassEdit && setIsMassEdit(false);
          break;
        case "edit":
          !isMassEdit && setIsMassEdit(true);
          break;
      }
    }
  }, [buildingOneWorkshiftStore.openedMode[id], id]);

  return tableSort?.length ? (
    <Formik
      initialValues={tableSort}
      onSubmit={() => {
        return;
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
      validationSchema={getValidationSchema(
        buildingOneWorkshiftStore.selects.objectstatus
      )}
    >
      {({ values }) => (
        <table
          className={classNames(styles.table, {
            [styles.table_minWidth]: menuStore.isMenuShown,
            [styles.table_maxWidth]: !menuStore.isMenuShown
          })}
        >
          <BuildingOneWorkshiftStafftableThead />
          <tbody>
            {values.map((staff, index) => {
              return (
                <tr
                  key={staff.uid}
                  className={classNames("", {
                    [styles.greyRow]: !(index % 2),
                    [styles.noDisplay]: staff.isMassEdit && !isMassEdit,
                    [styles.blueRow]: staff.isMassEdit
                  })}
                >
                  {getEntries(buildingOneWorkshiftStore.workshiftTableCols).map(
                    ([name, col]) => {
                      if (buildingOneWorkshiftStore.isShowCol(name)) {
                        if (name === "uid") {
                          return (
                            <Fragment key={`${staff.uid}_${name}`}>
                              <td>{staff.staff_info?.tn}</td>
                              <td>
                                <Link
                                  className={styles.link}
                                  id={`BuildingOneWorkshiftStaffTable_showOneStaff_${staff.uid}`}
                                  to={`/staff/id=${staff.uid}`}
                                  target="_blank"
                                >
                                  <p>{staff.staff_info?.fio}</p>
                                </Link>
                              </td>
                            </Fragment>
                          );
                        }

                        return (
                          <td
                            key={`${staff.uid}_${name}`}
                            className={classNames("", {
                              [styles.textarea]: name === "comment",
                              "w-auto": Boolean(
                                name === "comment" &&
                                  buildingOneWorkshiftStore.openedMode[id] ===
                                    "edit" &&
                                  (buildingOneWorkshiftStore
                                    .workshiftTableParams[name].access_edit ||
                                    buildingOneWorkshiftStore
                                      .workshiftTableParams[name])
                              )
                            })}
                            onClick={handleSetEditingWarning(
                              name,
                              staff.ws_staff_id
                            )}
                          >
                            <div
                              className={classNames("", {
                                "d-flex gap-2":
                                  buildingOneWorkshiftStore.editingWarnings[
                                    name
                                  ]?.includes(staff.ws_staff_id)
                              })}
                            >
                              {(() => {
                                if (col.type === "bool") {
                                  return (
                                    <BuildingOneWorkshiftStafftableBool
                                      name={name}
                                      staffIndex={index}
                                    />
                                  );
                                }
                                switch (name) {
                                  case "group":
                                    return (
                                      <BuildingOneWorkshiftStafftableInput
                                        name={name}
                                        staffIndex={index}
                                      />
                                    );
                                  case "customer":
                                  case "objectstatus":
                                  case "payment_type":
                                    return (
                                      <BuildingOneWorkshiftStafftableSelect
                                        name={name}
                                        staffIndex={index}
                                      />
                                    );
                                  case "work_hours":
                                    return (
                                      <BuildingOneWorkshiftStafftableHours
                                        name={name}
                                        staffIndex={index}
                                      />
                                    );
                                  case "comment":
                                    return (
                                      <BuildingOneWorkshiftStafftableTextarea
                                        name={name}
                                        staffIndex={index}
                                      />
                                    );
                                  default:
                                    if (
                                      buildingOneWorkshiftStore.selects[name]?.[
                                        staff[name] || ""
                                      ]
                                    ) {
                                      return buildingOneWorkshiftStore.selects[
                                        name
                                      ][staff[name]].title;
                                    } else return staff[name];
                                }
                              })()}
                              <BuildingOneWorkshiftStafftableWarning
                                name={name}
                                ws_staff_id={staff.ws_staff_id}
                              />
                            </div>
                          </td>
                        );
                      }
                    }
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Formik>
  ) : null;
};

export default observer(BuildingOneWorkshiftStaffTable);
