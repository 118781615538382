import styles from "./savedButtonsPanel.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { useFormikContext } from "formik";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import { statusParams } from "stores/AregisterModule/directories/statusesParams";
import { getAregisterStatus } from "shared/utils/helpers/getAregisterStatus";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

const SavedButtonsPanel = () => {
  const { aregisterListStore } = useStores();

  const { values, dirty, handleReset, isValid } =
    useFormikContext<AregisterListFormik>();

  const isMassEdit = aregisterListStore.openedEditType === "mass_edit";
  const isMassStatus = aregisterListStore.openedEditType === "mass_status";

  const getPanelTitle = () => {
    if (isMassEdit) return "Массовое редактирование";
    else if (isMassStatus) {
      const status = getAregisterStatus(
        aregisterListStore.selects.status?.[
          aregisterListStore.openedMassStatusEdit
        ]?.custom
      );

      return statusParams[status]?.savedPanelTitle;
    } else return "Редактирование";
  };

  const getChanges = () => {
    if (isMassEdit && dirty && !aregisterListStore.isMassEditDisabled)
      return "Внесены изменения";
    else if (isMassStatus)
      if (dirty && values.aregister?.length)
        return (
          <>
            Отмечено заявок: <b>{values.aregister.length}</b>
          </>
        );
      else return "";
    else return "Внесены изменения";
  };

  const getBtnTitle = () => {
    const status = getAregisterStatus(
      aregisterListStore.selects.status?.[
        aregisterListStore.openedMassStatusEdit
      ]?.custom
    );
    if (isMassStatus) return statusParams[status]?.savedPanelBtnTitle;
    else return "Сохранить";
  };

  const handleSavedBtnClick = () => {
    if (aregisterListStore.openedEditType === "mass_status") {
      aregisterListStore.changeStatus(values.aregister);
      handleReset();
    } else {
      // Здесь нужно сохранить изменения в заявках
    }
  };

  return aregisterListStore.openedPanel ? (
    <div className={styles.rightSide}>
      <p className={styles.title}>{getPanelTitle()}</p>
      <p className={styles.text}>{getChanges()}</p>
      <ButtonsGroupForFormEdit
        blueBtnTitle={getBtnTitle()}
        blueBtnDisabled={
          !dirty ||
          !isValid ||
          (aregisterListStore.openedEditType === "mass_edit" &&
            !aregisterListStore.isMassEditDisabled)
        }
        blueBtnOnClick={handleSavedBtnClick}
        grayBtnTitle={isMassStatus ? "Отменить выбор" : "Отменить"}
        grayBtnDisabled={!dirty}
        grayBtnOnClick={handleReset}
      />
    </div>
  ) : null;
};

export default observer(SavedButtonsPanel);
