import styles from "./staffOneInfoOneCompanies.module.scss";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

const StaffOneInfoOneCompanies = () => {
  const { staffOneStore } = useStores();

  useEffect(() => {
    // если стафкода в выбранной компании нет, то обновляем данные по компании на пустые значения
    if (!staffOneStore.companiesPlacesTabs.length) {
      staffOneStore.setSelectedCompanyPlace("");
    }
  }, [staffOneStore.companiesPlacesTabs]);

  return (
    <div className={styles.wrapper}>
      <SwitchedTabs
        tabs={staffOneStore.companiesTabs}
        changeActiveTab={staffOneStore.setSelectedCompany}
        selectedTab={staffOneStore.selectedCompany}
      />

      {staffOneStore.companiesPlacesTabs.length ? (
        <div className={styles.tabs}>
          {staffOneStore.companiesPlacesTabs.map((tab) => {
            return (
              <div
                className={
                  staffOneStore.selectedCompanyPlace === tab.id
                    ? styles.oneTabActive
                    : styles.oneTab
                }
                key={`tab-company-place-${tab.id}`}
                id={`tab-company-place-${tab.id}`}
                onClick={() => {
                  staffOneStore.setSelectedCompanyPlace(tab.id);
                }}
              >
                <p>{tab.title}</p>
                <p>{`${tab.id} ${tab.status || ""}`}</p>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOneInfoOneCompanies);
