import styles from "./inputNumber.module.scss";
import { useField, useFormikContext } from "formik";
import { RefObject } from "react";

import { Input } from "shared/ui/Inputs/Input";

import { ReactComponent as ButtonUp } from "shared/assets/images/mainIcons/iconUp.svg";
import { ReactComponent as ButtonDown } from "shared/assets/images/mainIcons/iconDown.svg";
import { classNames } from "shared/utils/helpers/classNames";

type InputNumberProps = {
  name: string;
  label?: string;
  value: string | number;
  withoutBtns?: boolean;
  minValue?: number;
  maxValue?: number;
  smallInputWithoutBorder?: boolean;
  inputRef?: RefObject<HTMLElement>;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent) => void;
};

export const InputNumber = ({
  name,
  label,
  withoutBtns,
  minValue,
  maxValue,
  smallInputWithoutBorder,
  inputRef,
  disabled,
  onBlur
}: InputNumberProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleOnBlur = (e: React.FocusEvent) => onBlur && onBlur(e);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(field.name);
    setFieldValue(name, +e.target.value.replace(/^0+/, ""));
  };
  const handleClickOnUpButton = (value: "up" | "down") => () => {
    if (
      (value === "up" ? maxValue : minValue) === undefined || value === "up"
        ? +field.value < maxValue
        : +field.value > minValue
    ) {
      setFieldTouched(field.name);
      setFieldValue(
        field.name,
        value === "up" ? +field.value + 1 : +field.value - 1
      );
    }
  };
  const handleValidate = (value: string) => {
    if (value !== null && isNaN(+value)) return "Только число";
    if (minValue !== undefined && +value < minValue)
      return `Не менее ${minValue}`;
    if (maxValue !== undefined && +value > maxValue)
      return `Не более ${maxValue}`;
  };

  return (
    <div
      className={classNames(styles.sectionUpDown, {
        [styles.sectionUpDown_withBtn]: !withoutBtns,
        [styles.sectionUpDown_smallInputWithoutBorder]: smallInputWithoutBorder,
        [styles.sectionUpDown_smallInputWithoutBorder_invalid]:
          smallInputWithoutBorder && meta.error,
        [styles.sectionUpDown_disabled]: disabled
      })}
    >
      <Input
        name={name}
        label={label}
        onChange={handleOnChange}
        inputRef={inputRef}
        validate={handleValidate}
        disabled={disabled}
        onBlur={handleOnBlur}
      />
      {!withoutBtns ? (
        <div className={styles.buttonsGroup}>
          <div
            className={classNames(styles.buttonsGroup__updown, {
              [styles.buttonsGroup__updown_disabled]:
                disabled || field.value === maxValue
            })}
          >
            <ButtonUp
              id={`InputNumber-btnIncrease_${field.name}`}
              onClick={handleClickOnUpButton("up")}
            />
          </div>
          <div
            className={classNames(styles.buttonsGroup__updown, {
              [styles.buttonsGroup__updown_disabled]:
                disabled || +field.value === minValue
            })}
          >
            <ButtonDown
              id={`InputNumber-btnDecrease_${field.name}`}
              onClick={handleClickOnUpButton("down")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
