import { object, string } from "yup";
import { dateSchema } from "shared/utils/validation/validation";

export const validationPlannedObject = object({
  planned_object: string()
    .nullable()
    .required("Поле обязательно для заполнения"),
  date_start: dateSchema
    .required("Поле обязательно для заполнения")
    .min(new Date(), "Дата должна быть больше текущей")
});
