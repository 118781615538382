import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

const SalaryPaymentlistOneHistory = () => {
  const { id } = useParams();
  const { menuStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (
        value["parent_path"] &&
        value["parent_path"] !== `/salary/paymentlist`
      ) {
        menuStore.deleteWindow(key);
      }
    });

    menuStore.setOpenedModule("paymentlistOne");
    menuStore.setOpenedSubmodule("paymentlistOneHistory");
    menuStore.sendTabId(id);

    menuStore.updateWindow({
      mainPath: `/salary/paymentlist/id=${id}`,
      path: `/salary/paymentlist/id=${id}/history`
    });
  }, [id]);

  return <>История ведомости {id}</>;
};

export default observer(SalaryPaymentlistOneHistory);
