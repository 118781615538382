import styles from "./buildingOneTimesheetInfoLegend.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef } from "react";

import { Scrollbars } from "react-custom-scrollbars-2";
import StatusIcon from "shared/ui/StatusIcon";

import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { maxHeight } from "shared/utils/constants/maxHeight";
import { Button, ButtonSize } from "shared/ui/Button";

type BuildingOneTimesheetInfoLegendProps = {
  setIsOpenedLegend: (el: boolean) => void;
};

const BuildingOneTimesheetInfoLegend = ({
  setIsOpenedLegend
}: BuildingOneTimesheetInfoLegendProps) => {
  const { buildingOneTimesheetStore } = useStores();
  const ref = useRef();
  useOnClickOutside({ ref, handler: () => setIsOpenedLegend(false) });

  return (
    <div className={styles.boardWrapper} ref={ref}>
      <Scrollbars
        width="max-content"
        autoHeight
        autoHeightMax={`calc(${maxHeight} - 110px)`}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <div className={styles.board}>
          <div className={styles.header}>
            <p>Легенда</p>

            <Button
              size={ButtonSize.S}
              id="BuildingOneTimesheetInfoLegend_closeBtn"
              onClick={() => setIsOpenedLegend(false)}
            >
              <StatusIcon icon="iconclose" color="bw-white" />
            </Button>
          </div>

          <div className={styles.items}>
            {buildingOneTimesheetStore.legend.map((item) => (
              <p
                key={`${item.short_name}_${item.description}`}
                className={styles[`item_${item.color}`]}
              >
                {item.short_name} - {item.description}
              </p>
            ))}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default observer(BuildingOneTimesheetInfoLegend);
