import { useStores } from "stores/index";

import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Statement from "shared/assets/images/menuIcons/iconDefault/Statement.svg";
import StatementSelected from "shared/assets/images/menuIcons/iconSelected/Statement.svg";

import History from "shared/assets/images/menuIcons/iconDefault/History.svg";
import HistorySelected from "shared/assets/images/menuIcons/iconSelected/History.svg";

const SalarySectionOnePaymentlist = () => {
  const { menuStore } = useStores();

  const tabId = menuStore.tabId;

  const overview = [
    {
      text: "Описание",
      to: `/salary/paymentlist/id=${tabId}`,
      moduleName: "paymentlistOne",
      submoduleName: "paymentlistOne",
      defaultIcon: Statement,
      selectedIcon: StatementSelected
    },
    {
      text: "История",
      to: `/salary/paymentlist/id=${tabId}/history`,
      moduleName: "paymentlistOne",
      submoduleName: "paymentlistOneHistory",
      defaultIcon: History,
      selectedIcon: HistorySelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default SalarySectionOnePaymentlist;
