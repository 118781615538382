import Checkbox from "shared/ui/Inputs/Checkbox";
import { settings } from "shared/utils/dictionaries/statusDict";
import Tooltip from "shared/ui/Tooltip";
import { StatusType } from "stores/StaffModule/staffStatus";

type SettingsProps = {
  values: StatusType;
  handleChange: (event: React.ChangeEvent<Element>) => void;
};

const Settings = ({ values, handleChange }: SettingsProps) => {
  return (
    <section>
      <p>Настройки</p>

      <ul>
        {Object.keys(settings).map((key) => {
          const title = settings[key];
          const hasTooltip = title.includes("\n");

          return (
            <li key={key}>
              <label className="d-flex align-items-center gap-2">
                <Checkbox
                  name={`custom.${key}`}
                  value={values[key]}
                  onChange={handleChange}
                />
                {hasTooltip ? (
                  <>
                    <div>{title.split("\n")[0]}</div>
                    <Tooltip text={title.split("\n")[1]} />
                  </>
                ) : (
                  <div>{title}</div>
                )}
              </label>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Settings;
