import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import BuildingOneWorkshiftStaffHeader from "./BuildingOneWorkshiftStaffHeader";
import BuildingOneWorkshiftStaffTable from "./BuildingOneWorkshiftStafftable";
import BuildingOneWorkshiftFilter from "./BuildingOneWorkshiftFilter";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { StaffType } from "stores/BuildingModule/types/StaffType";
import Alert from "shared/ui/Alert";

export type WorkshiftStaffValuesType = {
  groups: { [group_name: string]: number };
  staffList: { [group_name: string]: { [staff_id: string]: StaffType } };
};

const BuildingOneWorkshiftStaff = () => {
  const { id } = useParams();
  const { menuStore, buildingOneWorkshiftStore } = useStores();

  useEffect(() => {
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("workshiftOne");
    menuStore.setOpenedSubmodule("workshiftOneStaff");
    buildingOneWorkshiftStore.setSelectedWorkshiftForInfo(id, "/staff");

    buildingOneWorkshiftStore.selectedOne?.comment &&
      buildingOneWorkshiftStore.editStatus(
        id,
        Object.values(buildingOneWorkshiftStore.selects.status).find(
          (type) => type.custom.done === "on"
        ).id,
        buildingOneWorkshiftStore.selectedOne.comment,
        true
      );
  }, [id]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent
        isLoading={buildingOneWorkshiftStore.isLoading}
        error={buildingOneWorkshiftStore.error}
      >
        {buildingOneWorkshiftStore.selects.status &&
        Object.values(buildingOneWorkshiftStore.selectedOne || {}).length &&
        buildingOneWorkshiftStore.staffList?.[id]?.[
          buildingOneWorkshiftStore.selectedFilters[id]
        ] ? (
          <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BuildingOneWorkshiftStaffHeader />
            </ErrorBoundary>
            {Object.values(buildingOneWorkshiftStore.staffList[id] || {})
              .length ? (
              <>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BuildingOneWorkshiftFilter />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BuildingOneWorkshiftStaffTable />
                </ErrorBoundary>
              </>
            ) : (
              <Alert
                errors={{
                  head: "Сотрудники в смене не найдены",
                  color: "empty"
                }}
              />
            )}
          </>
        ) : null}
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(BuildingOneWorkshiftStaff);
