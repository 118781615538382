import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

const SalaryAddPayment = () => {
  const { menuStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("add_payment");
    menuStore.updateWindow({
      mainPath: "/salary",
      path: "/salary/add_payment",
      title: "Финансы"
    });
  }, []);

  return <div>Создание ведомости</div>;
};

export default observer(SalaryAddPayment);
