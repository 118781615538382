import { makeAutoObservable, runInAction } from "mobx";
import { staffFields } from "shared/utils/dictionaries/statusDict";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import RootStore from "stores";

type Condition = {
  short: string;
  long: string;
  checked: boolean;
};

type Custom = {
  short_name: string;
  icon?: string;
  color?: string;
  staff_cols: { [key: string]: string };
  transit_cols: string[];
  notice_change: string[];
  category: string;
  buidling_condition?: {
    active: number | boolean;
    list: { [key: string]: string };
    fullList: Condition[];
  };
};

export type StatusType = {
  id: string;
  custom: Custom;
  index?: number;
  statesList?: Condition[];
  title: string;
};

export default class StaffStatusStore {
  statusTypes: { [key: string]: StatusType } = {};
  selectedStatusId = "";
  statusFields: { [key: string]: { newname: string; title: string } } = {};
  newStatus: StatusType;
  statusTitles: string[] = [];

  // hardcode
  statesList: Condition[] = [];

  isLoading = false;
  error = false;

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  getStatusTypes = async () => {
    runInAction(() => (this.isLoading = true));
    try {
      this.statusTypes = {};

      const data: ApiResponse<{ [key: string]: StatusType }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });

      if (data["code"] === 200) {
        runInAction(() => {
          this.statusTypes = Object.assign({}, data["result"]);
          this.statusTypes[""];
          this.setStatesList();
          this.setSelectedStatusId(Object.values(this.statusTypes)[0]["id"]);
          Object.values(this.statusTypes).forEach((status, i) => {
            this.statusTitles.push(status["title"]);
            this.statusTypes[status["id"]].index = i + 1;
            this.statusTypes[status["id"]]["custom"]["transit_cols"] =
              this.statusTypes[status["id"]]["custom"]["transit_cols"] &&
              Object.values(
                this.statusTypes[status["id"]]["custom"]["transit_cols"]
              );
            this.statusTypes[status["id"]]["custom"]["notice_change"] =
              this.statusTypes[status["id"]]["custom"]["notice_change"] &&
              Object.values(
                this.statusTypes[status["id"]]["custom"]["notice_change"]
              );
          });
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  //Поменять, когда с бэка начнут приходить длинные названия состояний
  setStatesList = () => {
    const result: Condition[] = [];

    for (const status of Object.values(this.statusTypes)) {
      if (status["custom"]?.["buidling_condition"]) {
        const buidling_condition = status["custom"]?.["buidling_condition"];

        buidling_condition["active"] = buidling_condition["active"] === 1;
        const values = Object.values(buidling_condition["list"]).map(
          (value: string) => {
            return {
              short: value,
              long: "Длинное название состояния",
              checked: true
            };
          }
        );

        buidling_condition["fullList"] = values;

        if (values.length) {
          for (let i = 0; i < values.length; i++) {
            result.push(values[i]);
          }
        }
      } else {
        status["custom"]["buidling_condition"] = {
          active: false,
          list: {},
          fullList: []
        };
      }
    }

    const unique: Condition[] = [
      ...new Set(result.map(({ short }) => short))
    ].map((item) => {
      return {
        short: item,
        long: result.find(({ short }) => short === item)["long"],
        checked: false
      };
    });

    runInAction(() => {
      this.statesList = unique;
      for (const status of Object.values(this.statusTypes)) {
        const newUnique = [...unique];
        status["statesList"] = newUnique.map((item) => {
          item.checked = status["custom"]["buidling_condition"][
            "fullList"
          ].find(({ short }) => short === item["short"])?.["checked"];
          return item;
        });
      }
    });
  };

  setStatusFields = async () => {
    const staffTableCols = this.rootStore.staffListStore.staffTableCols;
    const fields: { [key: string]: string } = {};

    for (const prop in staffTableCols) {
      if (prop !== "id") {
        fields[prop] = staffTableCols[prop]["title"];
      }
    }

    for (const prop in staffFields) {
      fields[prop] = staffFields[prop];
    }

    runInAction(() => {
      Object.entries(fields)
        .map(([key, value]) => {
          return { newname: key, title: value };
        })
        .forEach((status, i) => {
          return (this.statusFields[i] = status);
        });
    });
  };

  setSelectedStatusId = (id: string) => {
    this.selectedStatusId = id;
    if (id !== "new") {
      this.newStatus = null;
    }
  };

  //Изменить, когда будет проведен рефакторинг настроек статусов
  addNewStatus = (newStatus: StatusType) => {
    newStatus["id"] = "";
    newStatus["title"] = "";
    newStatus["index"] = Object.values(this.statusTypes).length + 1;
    const customKeys = Object.keys(newStatus["custom"]);
    for (const key of customKeys) {
      if (Number.isInteger(newStatus["custom"][key])) {
        newStatus["custom"][key] = 0;
      }
    }
    newStatus["custom"]["bool_cols"] = {};
    newStatus["custom"]["category"] = "none";
    newStatus["custom"]["notice_change"] = [];
    newStatus["custom"]["transit_cols"] = [];
    newStatus["custom"]["color"] = "";
    newStatus["custom"]["icon"] = "";
    newStatus["custom"]["short_name"] = "";
    newStatus["custom"]["staff_cols"] = {};
    newStatus["custom"]["buidling_condition"]["active"] = false;
    newStatus["custom"]["buidling_condition"]["list"] = {};
    newStatus["custom"]["buidling_condition"]["fullList"] = [];
    runInAction(() => {
      newStatus["statesList"] = this.statesList;
      this.newStatus = newStatus;
    });
  };

  addNewState = (value: Condition) => {
    runInAction(() =>
      this.statusTypes[this.selectedStatusId].statesList.unshift(value)
    );
  };

  toggleExistingState = (action: string, value: Condition) => {
    if (action === "add") {
      runInAction(() => {
        this.statusTypes[
          this.selectedStatusId
        ].custom.buidling_condition.fullList.unshift(value);
      });
    } else {
      runInAction(() => {
        const idx = this.statusTypes[
          this.selectedStatusId
        ].custom.buidling_condition.fullList.findIndex(
          ({ short }) => short === value["short"]
        );
        this.statusTypes[
          this.selectedStatusId
        ].custom.buidling_condition.fullList.splice(idx, 1);
      });
    }
  };
}
