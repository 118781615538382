import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { MutableRefObject, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "shared/ui/Cards/Card";
import { useStores } from "stores";
import styles from "./staffOneSalaryMetrics.module.scss";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";

type StaffOneSalaryMetricsProps = {
  refsForBlock: MutableRefObject<HTMLDivElement[]>;
};

const StaffOneSalaryMetrics = ({
  refsForBlock
}: StaffOneSalaryMetricsProps) => {
  const { staffOneSalaryStore, menuStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneSalaryStore.metricsList) &&
      staffOneSalaryStore.getFinanceMetrics(id);
  }, [id]);

  return (
    <div className={styles.loaderContainer}>
      <LoadedComponent
        isLoading={staffOneSalaryStore.isLoadingMetrics[id]}
        withoutText
      >
        <>
          {!isEmpty(staffOneSalaryStore.metricsList[id]) ? (
            <div className={styles.cards}>
              {getEntries(staffOneSalaryStore.metricsList[id]).map(
                ([key, metric]) => (
                  <Card
                    key={key}
                    data={getValues(metric.metrics)}
                    color={metric.color}
                    center
                    onClick={() => {
                      // раскрываем выбранный блок
                      staffOneSalaryStore.setConditionBlockList(
                        id,
                        getValues(metric.metrics)?.[0]?.table_title,
                        true
                      );

                      // скроллимся к выбранному блоку
                      menuStore.scrollbarRef.current.view.scroll({
                        top:
                          refsForBlock.current?.[
                            getValues(metric.metrics)[0]?.table_title
                          ]?.offsetTop - 100,
                        left: 0,
                        behavior: "smooth"
                      });
                    }}
                  />
                )
              )}
            </div>
          ) : null}
        </>
      </LoadedComponent>
    </div>
  );
};

export default observer(StaffOneSalaryMetrics);
