import { Button, ButtonTheme } from "shared/ui/Button";
import styles from "./errorWindow.module.scss";

import StatusIcon from "shared/ui/StatusIcon";

type ErrorWindowProps = {
  errorMessage: string;
  setIsOpenWindow: () => void;
  actionButton: {
    [key: string]: { id: number; title: string; action?: () => void };
  };
};

const ErrorWindow = ({
  errorMessage,
  setIsOpenWindow,
  actionButton
}: ErrorWindowProps) => {
  return (
    <div className={styles.background}>
      <div className={styles.errorWindow}>
        <div className={styles.errorHeader}>Ошибка</div>
        <div
          id="closeWindowBtn"
          className={styles.closeIcon}
          onClick={setIsOpenWindow}
        >
          <StatusIcon icon="iconclose" color="bw-gray5" />
        </div>
        <div className={styles.errorContainer}>
          <StatusIcon icon="iconalert" color="accent-red" />
          {errorMessage?.length ? (
            <div>{errorMessage}</div>
          ) : (
            <div>Что-то пошло не так...</div>
          )}
        </div>
        <div className={styles.btnGroup}>
          {Object.values(actionButton).map((item, i) => {
            return (
              <Button
                key={item.id}
                id={`buttonAction_${item.id}`}
                theme={Object.keys(actionButton).length === 1
                  ? ButtonTheme.SECONDARY
                  : i % 2 === 0
                  ? ButtonTheme.SECONDARY
                  : ButtonTheme.PRIMARY}
                className={
                  Object.keys(actionButton).length === 1
                    ? styles.btnAccept
                    : styles.btnToBack
                }
                onClick={setIsOpenWindow}
              >
                {item.title}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ErrorWindow;
