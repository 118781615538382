import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Building } from "stores/StaffModule/types/Building";
import { StaffList } from "stores/StaffModule/types/StaffList";
import { StaffListItem } from "stores/StaffModule/types/StaffListItem";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
interface IBuildingOneModify extends Omit<Building, "users"> {
  users: { [key: string]: string | number };
  work_direction: string;
}
export default class BuildingOneStaffMovementsStore {
  error = false;
  errorMessage = "";
  errorMessageForMovement: Partial<Errors["message"]> = {};
  isLoading = false;

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  staffList: { [key: string]: StaffListItem[] } = {};
  statusList: { [key: string]: BuildingEventType } = {};
  buildings: { [key: string]: Partial<IBuildingOneModify> } = {};

  selectedBuilding: Partial<IBuildingOneModify> = {};
  selectedBuildingId = "";
  selectedBuildingCompany = "";
  setInEmployeeTransfersStatusId = "";

  rootStore: RootStore;

  staffListOrder = "time_create";
  staffListOrdered = "DESC";
  searchValue = "";

  actionButton = {
    0: {
      id: 1,
      title: "OK"
    }
  };

  // hardcode
  dismissStatusId = "e329ed9b615763361b86d0d31cc3aac6b620c262";
  activeStatusId = "58f3be3209ee7f6c586d7a7e583b5e9eab8caac2";
  transferStatusId = "a0f6708450dcb7ff0895a240457396a705a28811";
  vacationStatusId = "5b700e8e92e40355f0f772c3ab9de6615d585cf2";
  roadStatusId = "5a2b968e53fd429b7e889634831c389896e1b44";

  // hardcode
  statusesCurrentObject = {
    "58f3be3209ee7f6c586d7a7e583b5e9eab8caac2":
      "Сотрудник активен на текущем объекте",
    a0f6708450dcb7ff0895a240457396a705a28811:
      "Сотрудник в трансфере на текущий объект",
    "5a2b968e53fd429b7e889634831c389896e1b44":
      "Сотрудник уехал на другой объект"
  };

  // hardcode
  currentTitles: string[] = [
    "uid",
    "fio",
    "position",
    "building",
    "event_type",
    "date_transfer"
  ];

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setStaffListOrder = (value: string) => {
    this.staffListOrder = value;
  };

  setStaffListOrdered = (value: string) => {
    this.staffListOrdered = value;
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setSelectedBuilding = async (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/building", "Объекты");
    }
    if (!this.rootStore.menuStore.allWindows[`/building/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/building/id=${id}`,
        "Загрузка..."
      );
      delete this.buildings[id];
    }

    if (!this.buildings[id]) {
      await this.rootStore.buildingOneStore.getBuildingOne(id);
      this.buildings[id] =
        this.rootStore.buildingOneStore.openedAllBuildings[id];
    }

    if (Object.values(this.buildings[id]).length) {
      runInAction(() => {
        this.selectedBuilding = this.buildings[id];
        this.selectedBuildingId = this.selectedBuilding.id;
        this.selectedBuildingCompany = this.selectedBuilding.company;

        if (!this.staffList[this.selectedBuildingId]) {
          Promise.all([this.getEventTypeList()]).then(() => {
            this.getStaffMovementsList();
          });
        }
      });
    } else {
      runInAction(() => {
        this.error = true;
      });
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempStaffObject: StaffListItem = { ...oneOfStaff };

      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (value) {
          case null:
            tempStaffObject[key] = "";
            break;
          case -1:
            tempStaffObject[key] = 0;
            break;
          default:
            if (typeof value === "string")
              tempStaffObject[key] = getFormattedDate(value);
            else tempStaffObject[key] = value;
            break;
        }
        switch (key) {
          case "surname":
            tempStaffObject[key] = `${value} ${oneOfStaff["name"]} ${
              oneOfStaff["patronymic"] ? oneOfStaff["patronymic"] : ""
            }`;
            break;
        }
      });
      return tempStaffObject;
    });
  };

  newParams = (): { [key: string]: string | string[] } => {
    const params: { [key: string]: string | string[] } = {};
    if (this.staffListOrder) {
      params["order"] = this.staffListOrder;
    }
    if (this.staffListOrdered) {
      params["ordered"] = this.staffListOrdered;
    }
    if (this.searchValue) {
      params["fast_search"] = this.searchValue;
    }
    if (this.selectedBuildingId && !this.searchValue) {
      params["filter[building][]"] = this.selectedBuildingId;
    }
    if (this.selectedBuildingCompany && !this.searchValue) {
      params["filter[company][]"] = this.selectedBuildingCompany;
    }
    if (this.dismissStatusId && this.vacationStatusId && !this.searchValue) {
      params["filter[event_type]"] = [
        this.dismissStatusId,
        this.vacationStatusId
      ];
    }
    return params;
  };

  getStaffMovementsList = async () => {
    this.isLoading = true;
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams()
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.staffList[this.selectedBuildingId] = this.dataPreparation(
            Object.values(data["records"])
          );
        } else {
          this.staffList[this.selectedBuildingId] = [];
        }
        this.maxPage = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    }
  };

  getMoreStaffMovements = async () => {
    this.isLoading = true;
    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          only_records: 1
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.staffList[this.selectedBuildingId].push(
            ...this.dataPreparation(Object.values(data["records"]))
          );
        } else {
          this.staffList[this.selectedBuildingId] = [];
          this.errorMessage = "Ничего не найдено";
          this.rootStore.modalWindowsStore.setIsErrorWindow(true);
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  addStaffEvent = async (values: {
    uid: string;
    building: string;
    building_parent: string;
    type: string;
    event_start: string;
    event_end: string;
  }) => {
    this.isLoading = true;
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "addStaffEvent",
        body: { ...values }
      });
      runInAction(() => {
        if (data["result"]) {
          this.getStaffMovementsList();
        } else {
          this.errorMessageForMovement = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getEventTypeList = async () => {
    this.isLoading = true;
    try {
      const data: ApiResponse<{ [key: string]: BuildingEventType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).map((status) => {
            status?.custom?.set_in_employee_transfers
              ? (this.setInEmployeeTransfersStatusId = status.id)
              : "";
          });
          this.statusList = data["result"];
        } else {
          this.error = true;
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      this.isLoading = false;
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
