import { date, string } from "yup";
import { isValid, parse } from "date-fns";
const text = /^[а-яё -/]+$/gi;
const textWithPunc = /^[а-яё -.,_!?:;]+$/gi;
export const numberRegEx = /^[0-9]+$/gi;
export const textAndNumWithPunc = /^[0-9а-яё -.,_!?:;]+$/gi;

export const datePattern =
  /(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}/; //паттерн проверки даты ru формата
export const standardDatePattern =
  /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/; //паттерн проверки даты standard формата

export const stringSchema = string().trim().lowercase().nullable();

export const stringNoPunc = stringSchema.matches(text, "Только кириллица");

export const stringNoPuncButLimit = stringSchema
  .matches(text, "Только кириллица")
  .min(2, "Минимум 2 знака")
  .max(100, "Максимум 100 знаков");

export const stringWithPuncAndLimit = stringSchema
  .matches(textWithPunc, "Только кириллица")
  .min(2, "Минимум 2 знака")
  .max(100, "Максимум 100 знаков");

export const stringWithNumAndPuncAndLimit = stringSchema
  .matches(textAndNumWithPunc, "Только кириллица и цифры")
  .min(2, "Минимум 2 знака")
  .max(100, "Максимум 100 знаков");

export const commentSchema = stringSchema
  .matches(textAndNumWithPunc, "Только кириллица и цифры")
  .min(2, "Минимум 2 знака");

export const numberSchema = string()
  .matches(numberRegEx, "Только число")
  .nullable();

export const dateSchema = date()
  .nullable()
  .transform((originalValue: string) => {
    const parsed = parse(originalValue, "yyyy-MM-dd", new Date());
    return isValid(parsed)
      ? parsed
      : originalValue === null
      ? null
      : originalValue;
  })
  .typeError("Некорректный формат даты");

export const maxDateToday = dateSchema.max(
  new Date(),
  "Дата выдачи не может быть больше текущей даты"
);
