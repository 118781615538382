import styles from "./staffVacTableComment.module.scss";
import { Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import { useState, useRef } from "react";
import { validationComment } from "./validation";
import { ReactComponent as IconSend } from "shared/assets/images/mainIcons/iconsSend/iconSendWithoutStroke.svg";
import { ReactComponent as IconChat } from "shared/assets/images/mainIcons/iconsChat/iconChatWithoutFill.svg";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import TextareaAutosize from "react-textarea-autosize";
import Tooltip from "shared/ui/Tooltip";

type StaffVacTableCommentProps = {
  value: string;
};

const StaffVacTableComment = ({ value }: StaffVacTableCommentProps) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const ref = useRef();
  useOnClickOutside({ ref, handler: () => setIsOpenForm(false) });

  return (
    <>
      {value ? (
        <div className={styles.value}>
          <IconChat />
          {value}
        </div>
      ) : isOpenForm ? (
        <Formik
          enableReinitialize
          initialValues={{
            comment: value
          }}
          validateOnBlur
          onSubmit={(values) => {
            // eslint-disable-next-line no-console
            console.log("id", values);

            setIsOpenForm(false);
          }}
          validationSchema={validationComment}
        >
          {({ values, handleChange, isValid, handleSubmit, dirty, errors }) => {
            return (
              <Form onSubmit={handleSubmit} ref={ref}>
                <div className={styles.form}>
                  <Field
                    as={TextareaAutosize}
                    maxLength={251}
                    name="comment"
                    value={values["comment"]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                  />
                  <Tooltip
                    color="blue-lazure"
                    text="Отправка комментария в разработке"
                  >
                    <button
                      type="submit"
                      className={styles.sendFormBtn}
                      disabled={!isValid || !dirty}
                      id="staffVacTableComment_sendForm"
                    >
                      <IconSend />
                    </button>
                  </Tooltip>
                </div>
                {errors["comment"] ? (
                  <div className={styles.error}>
                    <div>{errors["comment"]}</div>
                  </div>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div
          className={styles.button}
          onClick={() => setIsOpenForm(true)}
          id="staffVacTableComment_openForm"
        >
          <IconChat />
          Добавить
        </div>
      )}
    </>
  );
};

export default StaffVacTableComment;
