import styles from "./salaryPremiumsOneInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores";
import { Link, useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import { Formik } from "formik";
import SalaryPremiumsOneStaffTable from "./SalaryPremiumsOneStaffTable";
import StatusIcon from "shared/ui/StatusIcon";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import { Button, ButtonTheme } from "shared/ui/Button";

const SalaryPremiumsOneInfo = () => {
  const { id } = useParams();
  const { menuStore, salaryPremiumOneStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary/premium`) {
        menuStore.deleteWindow(key);
      }
    });

    salaryPremiumOneStore.setSelectedOneOfSalaryPremium(id);

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("premiumOne");
    menuStore.setOpenedSubmodule("premiumOne");
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryPremiumOneStore.isLoading}>
      <>
        {Object.keys(salaryPremiumOneStore.selectedOneSalaryPremium).length ? (
          <Formik
            initialValues={
              Object.values(salaryPremiumOneStore.premiumInitialValues).length
                ? {
                    record: salaryPremiumOneStore.premiumInitialValues,
                    table:
                      salaryPremiumOneStore.selectedOneSalaryPremium["table"]
                  }
                : salaryPremiumOneStore.selectedOneSalaryPremium
            }
            enableReinitialize
            onSubmit={() => {
              return;
            }}
          >
            {({ values }) => {
              return (
                <>
                  <div
                    className={styles.header}
                    style={{
                      background:
                        salaryPremiumOneStore.salaryPremiumTypes["status"][
                          values["record"]["status"]
                        ]?.["custom"]["done"] === "on"
                          ? "#EFF9E9"
                          : "#E0F7FA"
                    }}
                  >
                    <div>Описание премии</div>
                    <div
                      style={{
                        color:
                          salaryPremiumOneStore.salaryPremiumTypes["status"][
                            values["record"]["status"]
                          ]?.["custom"]["color"]
                      }}
                      className={styles.statusTitle}
                    >
                      {salaryPremiumOneStore.salaryPremiumTypes["status"][
                        values["record"]["status"]
                      ]?.["custom"]["done"] === "on" ? (
                        <StatusIcon icon="coins" color="accent-green" />
                      ) : (
                        <StatusIcon icon="circlecheck" color="cyan" />
                      )}
                      {
                        salaryPremiumOneStore.salaryPremiumTypes["status"][
                          values["record"]["status"]
                        ]?.["title"]
                      }
                    </div>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.infoBlock}>
                      <>
                        {Object.keys(salaryPremiumOneStore.cols).map(
                          (title) => {
                            switch (title) {
                              case "time_create":
                                return (
                                  <div
                                    key={title}
                                    className={
                                      "calculation_period" in
                                        salaryPremiumOneStore.cols ||
                                      "last_update" in
                                        salaryPremiumOneStore.cols
                                        ? styles.multipleRow
                                        : ""
                                    }
                                  >
                                    <div className={styles.field}>
                                      <p className={styles.fieldTitle}>
                                        {
                                          salaryPremiumOneStore.cols[title][
                                            "title"
                                          ]
                                        }
                                      </p>
                                      <p className={styles.fieldValue}>
                                        {values["record"][title]}
                                      </p>
                                    </div>
                                    {"calculation_period" in
                                    salaryPremiumOneStore.cols ? (
                                      <div className={styles.field}>
                                        <p className={styles.fieldTitle}>
                                          {
                                            salaryPremiumOneStore.cols[
                                              "calculation_period"
                                            ]["title"]
                                          }
                                        </p>
                                        <p className={styles.fieldValue}>
                                          {getParsedDate(
                                            String(
                                              values["record"][
                                                "calculation_period"
                                              ]
                                            )
                                          )
                                            .toLocaleDateString("ru-RU", {
                                              month: "short",
                                              year: "numeric"
                                            })
                                            .slice(0, -2)}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {"last_update" in
                                    salaryPremiumOneStore.cols ? (
                                      <div className={styles.field}>
                                        <p className={styles.fieldTitle}>
                                          {
                                            salaryPremiumOneStore.cols[
                                              "last_update"
                                            ]["title"]
                                          }
                                        </p>
                                        <p className={styles.fieldValue}>
                                          {values["record"]["last_update"]}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              case "calculation_period":
                                if (
                                  !("time_create" in salaryPremiumOneStore.cols)
                                ) {
                                  return (
                                    <div
                                      key={title}
                                      className={
                                        "last_update" in
                                        salaryPremiumOneStore.cols
                                          ? styles.multipleRow
                                          : ""
                                      }
                                    >
                                      <div className={styles.field}>
                                        <p className={styles.fieldTitle}>
                                          {
                                            salaryPremiumOneStore.cols[title][
                                              "title"
                                            ]
                                          }
                                        </p>
                                        <p className={styles.fieldValue}>
                                          {getParsedDate(
                                            String(
                                              values["record"][
                                                "calculation_period"
                                              ]
                                            )
                                          )
                                            .toLocaleDateString("ru-RU", {
                                              month: "short",
                                              year: "numeric"
                                            })
                                            .slice(0, -2)}
                                        </p>
                                      </div>
                                      {"last_update" in
                                      salaryPremiumOneStore.cols ? (
                                        <div className={styles.field}>
                                          <p className={styles.fieldTitle}>
                                            {
                                              salaryPremiumOneStore.cols[
                                                "last_update"
                                              ]["title"]
                                            }
                                          </p>
                                          <p className={styles.fieldValue}>
                                            {values["record"]["last_update"]}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                } else return;
                              case "last_update":
                                if (
                                  !(
                                    "time_create" in salaryPremiumOneStore.cols
                                  ) &&
                                  !(
                                    "calculation_period" in
                                    salaryPremiumOneStore.cols
                                  )
                                ) {
                                  return (
                                    <div className={styles.field} key={title}>
                                      <p className={styles.fieldTitle}>
                                        {
                                          salaryPremiumOneStore.cols[title][
                                            "title"
                                          ]
                                        }
                                      </p>
                                      <div className={styles.fieldValue}>
                                        {values["record"][title]}
                                      </div>
                                    </div>
                                  );
                                } else return;
                              case "object":
                                return (
                                  <div className={styles.field} key={title}>
                                    <p className={styles.fieldTitle}>
                                      {
                                        salaryPremiumOneStore.cols[title][
                                          "title"
                                        ]
                                      }
                                    </p>
                                    <Link
                                      to={`/building/id=${values["record"][title]}`}
                                      id="SalaryPremiumsOneInfo_goToBuilding"
                                      target="_blank"
                                      className={styles.buidlingLink}
                                    >
                                      <p className={styles.fieldValue}>
                                        {
                                          salaryPremiumOneStore.params[title][
                                            "variable"
                                          ][values["record"][title]]?.["title"]
                                        }
                                      </p>
                                    </Link>
                                  </div>
                                );
                              case "status":
                                return (
                                  <div
                                    key={title}
                                    className={styles.statusField}
                                  >
                                    <div className={styles.field}>
                                      <p className={styles.fieldTitle}>
                                        {
                                          salaryPremiumOneStore.cols[title][
                                            "title"
                                          ]
                                        }
                                      </p>
                                      <div
                                        className={`${styles.fieldValue} ${styles.statusTitle}`}
                                      >
                                        {salaryPremiumOneStore
                                          .salaryPremiumTypes["status"][
                                          values["record"]["status"]
                                        ]?.["custom"]["done"] === "on" ? (
                                          <StatusIcon
                                            icon="coins"
                                            color="accent-green"
                                          />
                                        ) : (
                                          <StatusIcon
                                            icon="circlecheck"
                                            color="cyan"
                                          />
                                        )}
                                        {
                                          salaryPremiumOneStore
                                            .salaryPremiumTypes["status"][
                                            values["record"]["status"]
                                          ]?.["title"]
                                        }
                                      </div>
                                    </div>
                                    <Button
                                      id="SalaryPremiumsOneInfo_action"
                                      theme={ButtonTheme.SECONDARY}
                                      style={{
                                        color:
                                          salaryPremiumOneStore
                                            .salaryPremiumTypes["status"][
                                            values["record"]["status"]
                                          ]?.["custom"]["done"] === "on"
                                            ? salaryPremiumOneStore
                                                .salaryPremiumTypes["status"][
                                                "257ddd5543bbcef9030101132345973b93005c0b"
                                              ]?.["custom"]["color"]
                                            : salaryPremiumOneStore
                                                .salaryPremiumTypes["status"][
                                                "ewqedqerwr4q324rerr"
                                              ]?.["custom"]["color"]
                                      }}
                                    >
                                      {salaryPremiumOneStore.salaryPremiumTypes[
                                        "status"
                                      ][values["record"]["status"]]?.["custom"][
                                        "done"
                                      ] !== "on" ? (
                                        <StatusIcon
                                          icon="coins"
                                          color="accent-green"
                                        />
                                      ) : (
                                        <StatusIcon
                                          icon="circlecheck"
                                          color="cyan"
                                        />
                                      )}
                                      {salaryPremiumOneStore.salaryPremiumTypes[
                                        "status"
                                      ][values["record"]["status"]]?.["custom"][
                                        "done"
                                      ] === "on"
                                        ? "Вернуть в Рассчитано"
                                        : "Начислить (Подтвердить)"}
                                    </Button>
                                  </div>
                                );
                              case "id":
                                return;
                              default:
                                return (
                                  <div className={styles.field} key={title}>
                                    <p className={styles.fieldTitle}>
                                      {
                                        salaryPremiumOneStore.cols[title][
                                          "title"
                                        ]
                                      }
                                    </p>
                                    <p className={styles.fieldValue}>
                                      {values["record"][title]}
                                    </p>
                                  </div>
                                );
                            }
                          }
                        )}
                      </>
                    </div>
                    {salaryPremiumOneStore.salaryPremiumTypes["status"][
                      values["record"]["status"]
                    ]?.["custom"]["done"] !== "on" ? (
                      <div>
                        <Button
                          theme={ButtonTheme.SECONDARY}
                          id="SalaryPremiumsOneInfo_count"
                        >
                          <StatusIcon icon="iconturn" color="blue-lazure" />
                          Пересчитать
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {Object.values(values["table"]).length ? (
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <SalaryPremiumsOneStaffTable values={values["table"]} />
                    </ErrorBoundary>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          </Formik>
        ) : (
          ""
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(SalaryPremiumsOneInfo);
