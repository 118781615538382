import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import Textarea from "shared/ui/Inputs/Textarea";

import { StaffType } from "stores/BuildingModule/types/StaffType";
import { useFormikContext } from "formik";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
const BuildingOneWorkshiftStafftableTextarea = ({
  name,
  staffIndex
}: WorkshiftStaffFieldProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { values } = useFormikContext<StaffType[]>();

  const notEditable =
    !values[staffIndex].isMassEdit ||
    (name === "comment" && !values[staffIndex].isMassEdit);
  const mode = buildingOneWorkshiftStore.getOpenedModeForField(
    name,
    notEditable
  );
  const handleBlur = () => {
    buildingOneWorkshiftStore.sendData(
      values[name],
      name,
      values[staffIndex].ws_staff_id,
      values[staffIndex].uid,
      values[staffIndex].isMassEdit
    );
  };

  if (name === "comment") {
    switch (mode) {
      case "show":
        return <>{values[staffIndex][name]}</>;
      case "edit":
        return (
          <Textarea
            placeholder="Комментарий"
            name={`${staffIndex}.${name}`}
            value={values[staffIndex][name] || ""}
            onBlur={handleBlur}
            maxRows={1}
          />
        );
    }
  }
};

export default observer(BuildingOneWorkshiftStafftableTextarea);
