import styles from "./projectsOneInfoPhoto.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores/index";

import FormWindow from "shared/ui/FormWindow";
import StatusIcon from "shared/ui/StatusIcon";

import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";

const ProjectsOneInfoPhoto = () => {
  const { projectsOneStore } = useStores();
  const [morePhotos, setMorePhotos] = useState(false);
  const [isOpenedPhoto, setIsOpenedPhoto] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Фото проекта</div>
      <div className={styles.columnContainer}>
        {projectsOneStore.selectedOneProject.images &&
        Object.values(projectsOneStore.selectedOneProject.images).length ? (
          Object.entries(projectsOneStore.selectedOneProject.images).map(
            ([id, image], index) => {
              return index <= 3 || (index > 3 && morePhotos) ? (
                <img
                  key={id}
                  className={`${styles.photo} ${styles.photo_withHover}`}
                  src={image.small}
                  id={`ProjectsOneInfoPhoto_small_${image.small}`}
                  onClick={() => {
                    setIsOpenedPhoto(id);
                  }}
                />
              ) : (
                ""
              );
            }
          )
        ) : (
          <div className={styles.photo}>
            <IconImage className={styles.iconPhotoDefault} />
          </div>
        )}
      </div>
      {projectsOneStore.selectedOneProject.images &&
      Object.values(projectsOneStore.selectedOneProject.images).length > 4 &&
      !morePhotos ? (
        <div
          className={styles.moreBtn}
          id="ProjectsOneInfoPhoto_moreBtn"
          onClick={() => setMorePhotos(true)}
        >
          <p>Ещё фото</p>
          <IconExpand />
        </div>
      ) : null}
      {isOpenedPhoto?.length ? (
        <div className={styles.windowContainer}>
          <div
            className={`${styles.window} ${
              Object.entries(projectsOneStore.selectedOneProject.images).filter(
                ([id]) => id === isOpenedPhoto
              )?.[0]?.[1]?.title?.length >= 75
                ? styles.window_withText
                : ""
            }`}
          >
            <FormWindow
              title="Фото проекта"
              setOpenWindow={() => setIsOpenedPhoto("")}
              withoutSendData
              isNotHaveButtons
              isScroll
            >
              <>
                <button
                  className={styles.window__prevBtn}
                  id="ProjectsOneInfoPhoto_prevBtn"
                  onClick={() => {
                    Object.keys(
                      projectsOneStore.selectedOneProject.images
                    ).forEach((id, index) => {
                      if (id === isOpenedPhoto && index !== 0) {
                        setIsOpenedPhoto(
                          Object.keys(
                            projectsOneStore.selectedOneProject.images
                          )[index - 1]
                        );
                      }
                    });
                  }}
                  disabled={
                    Object.keys(
                      projectsOneStore.selectedOneProject.images
                    ).filter((id, index) => {
                      return id === isOpenedPhoto && index !== 0;
                    })?.length === 0
                  }
                >
                  <div>
                    <StatusIcon icon="iconback" color="blue-lazure" />
                  </div>
                </button>
                {Object.values(
                  projectsOneStore.selectedOneProject.images
                ).filter((image) => image.normal === isOpenedPhoto)?.[0]
                  ?.title ? (
                  <div className={styles.window__title}>
                    {
                      Object.entries(
                        projectsOneStore.selectedOneProject.images
                      ).filter(([id]) => id === isOpenedPhoto)?.[0]?.[1]?.title
                    }
                  </div>
                ) : (
                  ""
                )}
                <div className={styles.window__photo}>
                  <img
                    className={styles.photo}
                    src={
                      projectsOneStore.selectedOneProject.images[isOpenedPhoto]
                        .normal
                    }
                  />
                </div>
                <button
                  className={styles.window__nextBtn}
                  id="ProjectsOneInfoPhoto_nextBtn"
                  onClick={() => {
                    Object.keys(
                      projectsOneStore.selectedOneProject.images
                    ).forEach((id, index) => {
                      if (
                        id === isOpenedPhoto &&
                        index !==
                          Object.entries(
                            projectsOneStore.selectedOneProject.images
                          ).length -
                            1
                      ) {
                        setIsOpenedPhoto(
                          Object.keys(
                            projectsOneStore.selectedOneProject.images
                          )[index + 1]
                        );
                      }
                    });
                  }}
                  disabled={
                    Object.keys(
                      projectsOneStore.selectedOneProject.images
                    ).filter((id, index) => {
                      return (
                        id === isOpenedPhoto &&
                        index ===
                          Object.keys(
                            projectsOneStore.selectedOneProject.images
                          ).length -
                            1
                      );
                    })?.length > 0
                  }
                >
                  <div>
                    <StatusIcon icon="iconnext" color="blue-lazure" />
                  </div>
                </button>
              </>
            </FormWindow>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(ProjectsOneInfoPhoto);
