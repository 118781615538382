import { useStores } from "stores";
import styles from "./aregisterAllReportNewReportTableHeaderCell.module.scss";
import { observer } from "mobx-react-lite";
import { classNames } from "shared/utils/helpers/classNames";

export type AregisterAllReportNewReportTableHeaderCellProps = {
  tableCols: { [key: string]: { [key: string]: string } };
  title: string;
};

const AregisterAllReportNewReportTableHeaderCell = ({
  tableCols,
  title
}: AregisterAllReportNewReportTableHeaderCellProps) => {
  const { menuStore } = useStores();

  return (
    <>
      <th className={classNames(styles.number, {}, [styles.number__empty])}>
        {title === "temporary_num" ? "№" : ""}
      </th>
      <th
        className={classNames(styles.cell, {
          [styles.cell__shadow]: menuStore.scroll["scrollLeft"]
        })}
      >
        {tableCols[title].title}
      </th>
    </>
  );
};

export default observer(AregisterAllReportNewReportTableHeaderCell);
