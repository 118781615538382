import { object } from "yup";
import { dateSchema } from "shared/utils/validation/validation";

const validation = object().shape({
  event_start: dateSchema
    .required("Поле обязательно для заполнения")
    .test(
      "event_start",
      "Не может быть позже даты приезда",
      function (val) {
        if (this.parent.event_end && val) {
          return Boolean(this.parent.event_end >= val);
        } else return true;
      }
    ),
    event_end: dateSchema
    .required("Поле обязательно для заполнения")
    .test(
      "event_end",
      "Не может быть ранее даты начала",
      function (val) {
        if (this.parent.event_start && val) {
          return Boolean(this.parent.event_start <= val);
        } else return true;
      }
    )
});

export default validation;
