import styles from "./buildingOnePhoto.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores/index";

import BuildingOnePhotoWindow from "./BuildingOnePhotoWindow";

import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";

const BuildingOnePhoto = () => {
  const { buildingOneStore } = useStores();
  const [morePhotos, setMorePhotos] = useState(false);
  const [isOpenedPhoto, setIsOpenedPhoto] = useState("");

  return buildingOneStore.selectedOne ? (
    <div className={styles.container}>
      <div className={styles.heading}>Фото объекта</div>
      <div className={styles.columnContainer}>
        {buildingOneStore.selectedOne.pictures &&
        Object.values(buildingOneStore.selectedOne.pictures).length ? (
          Object.entries(buildingOneStore.selectedOne.pictures).map(
            ([id, image], index) => {
              return index <= 3 || (index > 3 && morePhotos) ? (
                <img
                  key={id}
                  className={`${styles.photo} ${styles.photo_withHover}`}
                  src={image.medium}
                  id={`BuildingOnePhoto_medium_${image.medium}`}
                  onClick={() => {
                    setIsOpenedPhoto(id);
                  }}
                />
              ) : (
                ""
              );
            }
          )
        ) : (
          <div className={styles.photo}>
            <IconImage className={styles.iconAddFotoDefault} />
          </div>
        )}
      </div>
      {buildingOneStore.selectedOne.pictures &&
      Object.values(buildingOneStore.selectedOne.pictures).length > 4 &&
      !morePhotos ? (
        <div
          className={styles.moreBtn}
          id="BuildingOnePhoto_moreBtn"
          onClick={() => setMorePhotos(true)}
        >
          <p>Ещё фото</p>
          <IconExpand />
        </div>
      ) : null}
      {isOpenedPhoto?.length ? (
        <BuildingOnePhotoWindow
          isOpenedPhoto={isOpenedPhoto}
          setIsOpenedPhoto={setIsOpenedPhoto}
        />
      ) : (
        ""
      )}
    </div>
  ) : null;
};

export default observer(BuildingOnePhoto);
