import { useState } from "react";
import StaffOnePremiumLevelReward from "./StaffOnePremiumLevelReward";
import StaffOnePremiumLevelHistory from "./StaffOnePremiumLevelHistory";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import Collapsible from "shared/ui/Collapsible";

const fieldsTitle = {
  history_level: "История уровней",
  reward: "Вознаграждения"
};

type StaffOnePremiumLevelBlocksProps = {
  field: string;
};

const StaffOnePremiumLevelBlocks = ({
  field
}: StaffOnePremiumLevelBlocksProps) => {
  const [isShow, setIsShow] = useState(true);

  return (
    <Collapsible
      onClick={() => {
        setIsShow(!isShow);
      }}
      title={fieldsTitle[field]}
      isOpenedField={isShow}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {(() => {
          switch (field) {
            case "history_level":
              return <StaffOnePremiumLevelHistory />;
            case "reward":
              return <StaffOnePremiumLevelReward />;
          }
        })()}
      </ErrorBoundary>
    </Collapsible>
  );
};

export default StaffOnePremiumLevelBlocks;
