import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { PremiumLevel } from "../types/PremiumLevel";
import { PremiumLevelSelect } from "../types/Selects";

type DetailWorkshift = {
  [key: string]: {
    year: number;
    month: {
      [key: string]: {
        month: number;
        ws_in_month: number;
        ws_open_month: number;
        days_in_month: number;
        old_month: boolean;
      };
    };
  };
};

export default class StaffOnePremiumLevelStore {
  error: Record<string, boolean> = {};
  isLoading: Record<string, boolean> = {};
  isLoadingForDetailWorkshift: Record<string, boolean> = {};

  // текущие значение сотрудника в программе ПОЛАТИ бонус
  premium_level_current: Record<string, PremiumLevel> = {};
  //справочник уровней ПОЛАТИ бонус
  premium_level_selects: Record<string, PremiumLevelSelect> = {};
  // вывод сообщения в разделе, если сотрудник ну участвует в программе
  premium_level_error_text: Record<string, string> = {};
  // детальноый список месяцев по отработанным сменам в каждом из них
  premium_level_detail_workshift: Record<string, DetailWorkshift> = {};

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  getData = (id: string) => {
    this.isLoading[id] = true;
    Promise.all([
      !Object.keys(this.premium_level_selects).length &&
        this.getPremiumLevelSelects(),
      !(id in this.premium_level_current) && this.getPremiumLevelInfo(id),
      !(id in this.premium_level_detail_workshift) &&
        this.getDetailWorkshift(
          id,
          new Date().getMonth() + 1,
          new Date().getFullYear()
        ),
      this.rootStore.staffOneStore.setSelectedOneForInfo(id)
    ]).then(() => {
      runInAction(() => {
        this.isLoading[id] = false;
      });
    });
  };

  getPremiumLevelInfo = async (id: string) => {
    try {
      const data: ApiResponse<PremiumLevel> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "level",
          method: "getOne",
          params: {
            id
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          if (Object.values(data["result"]).length && !("message" in data)) {
            this.premium_level_current[id] = data["result"];
          } else {
            this.premium_level_current[id] = null;
            this.premium_level_error_text[id] = data["message"]?.["head"];
          }
        } else {
          this.premium_level_current[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium_level_current[id] = null;
      });
    }
  };

  getDetailWorkshift = async (id: string, month: number, year: number) => {
    this.isLoadingForDetailWorkshift[id] = true;
    try {
      const data: ApiResponse<DetailWorkshift> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "level",
          method: "getDetailWorkshift",
          params: {
            id,
            month,
            year
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          if (Object.values(data["result"]).length && !("message" in data)) {
            this.premium_level_detail_workshift[id] = data["result"];
          } else {
            this.premium_level_detail_workshift[id] = null;
            this.premium_level_error_text[id] = data["message"]?.["head"];
          }
        } else {
          this.premium_level_detail_workshift[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium_level_detail_workshift[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForDetailWorkshift[id] = false;
      });
    }
  };

  getPremiumLevelSelects = async () => {
    try {
      const data: { selects: { type: Record<string, PremiumLevelSelect> } } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "level",
          method: "getTypes"
        });

      runInAction(() => {
        if (!data["errors"]) {
          this.premium_level_selects = data["selects"]["type"];
        } else {
          this.premium_level_selects = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium_level_selects = {};
      });
    }
  };
}
