import styles from "./masterAndBrigadierField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import { Formik } from "formik";
import { Link } from "react-router-dom";
import Tooltip from "shared/ui/Tooltip";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";

type MasterAndBrigadierFieldProps = {
  name: string;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
};

const MasterAndBrigadierField = ({
  name,
  openedListName,
  changeOpenedListName
}: MasterAndBrigadierFieldProps) => {
  const { aregisterOneStore } = useStores();

  const { id } = useParams();

  const ref = useRef<HTMLDivElement>();
  useOnClickOutside({
    ref,
    handler: () => {
      setTimeout(() => {
        changeOpenedListName(name);
      }, 200);
    }
  });

  return (
    <Formik
      initialValues={{
        [name]:
          aregisterOneStore.selectedOne[name] &&
          aregisterOneStore.selectedOne[name].id
            ? aregisterOneStore.selectedOne[name].id
            : name === "master"
            ? "masterless"
            : null
      }}
      onSubmit={() => null}
    >
      {({ values, setFieldValue }) => {
        const dict =
          name === "master"
            ? aregisterOneStore.masters[id]
            : aregisterOneStore.brigadiers[id];
        const isMasterless = values[name] === "masterless";

        const setSelectedItem = (option: { id?: string; title: string }) => {
          setFieldValue(name, option.id);
          changeOpenedListName(name);
        };

        return (
          <div className={styles.select}>
            {values[name] && !isMasterless && !isEmpty(dict) ? (
              <Tooltip text="Перейти в карточку сотрудника">
                <div>
                  <Link
                    to={`/staff/id=${values[name]}`}
                    id={`MasterAndBrigadierField_${values[name]}`}
                    target="_blank"
                  >
                    {name === "master"
                      ? aregisterOneStore.masters[id][values[name]]?.title
                      : aregisterOneStore.brigadiers[id][values[name]]?.title}
                  </Link>
                </div>
              </Tooltip>
            ) : isMasterless ? (
              <p className={styles.noMaster}>Без мастера</p>
            ) : (
              <p className={styles.noBrigadier}>Бригадир</p>
            )}
            <Tooltip
              text={`${
                openedListName === name ? "Закрыть" : "Открыть"
              } список ${name === "master" ? "мастеров" : "бригадиров"}`}
            >
              <IconExpand
                className={classNames("", {
                  [styles.openListBtn]: openedListName !== name,
                  [styles.openListBtnTransformed]: openedListName === name
                })}
                onClick={() => {
                  name !== openedListName && changeOpenedListName(name);
                }}
                id={`{MasterAndBrigadierField_openList_${name}}`}
              />
            </Tooltip>

            {openedListName === name ? (
              <div className={styles.scrollboard} ref={ref}>
                <ItemsScrollBoard
                  options={dict as Record<string, { title: string }>}
                  addItem={setSelectedItem}
                  valueName="id"
                />
              </div>
            ) : null}
          </div>
        );
      }}
    </Formik>
  );
};

export default observer(MasterAndBrigadierField);
