import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import styles from "./staffOneInfoComments.module.scss";

import StaffOneInfoCommentOne from "./StaffOneInfoCommentOne";
import StaffOneInfoCommentsNo from "./StaffOneInfoCommentsNo/index";
import { getValues } from "shared/utils/helpers/getValues";

const StaffOneInfoComments = () => {
  const { commentsStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !commentsStore.comments[id] && commentsStore.getComments(id);
  }, [id]);

  const commentsGroup = getValues(commentsStore.comments[id]).slice(0, 2);

  return (
    <LoadedComponent isLoading={commentsStore.isLoading[id]} withoutText>
      <>
        {commentsGroup?.length ? (
          <>
            <div className={styles.height} />
            {commentsGroup.map((commentItem) => (
              <StaffOneInfoCommentOne
                key={commentItem.id}
                commentItem={commentItem}
              />
            ))}
          </>
        ) : (
          <StaffOneInfoCommentsNo />
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoComments);
