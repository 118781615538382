import { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Form } from "react-bootstrap";
import { Formik } from "formik";

import tableStyle from "shared/assets/styles/tableStyles/bigTableStyle.module.scss";
import styles from "./staffCitiesTable.module.scss";

import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import { Input } from "shared/ui/Inputs/Input";
import { validationCitiesFields } from "./validation";
import LoadedComponent from "widgets/LoadedComponent";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type StaffCitiesTableProps = {
  isAddingNew: boolean;
  setIsAddingNew: (isAddingNew: boolean) => void;
};

const StaffCitiesTable = ({
  isAddingNew,
  setIsAddingNew
}: StaffCitiesTableProps) => {
  const { staffCitiesStore, menuStore } = useStores();

  const [editCity, setEditCity] = useState("");
  const prevEditCity = useRef("");

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      staffCitiesStore.max_page >= staffCitiesStore.page &&
      staffCitiesStore.page === staffCitiesStore.prev_page
    ) {
      staffCitiesStore.setPage(staffCitiesStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  useEffect(() => {
    prevEditCity.current = "";
  }, []);

  useEffect(() => {
    prevEditCity.current = editCity;
  }, [editCity]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cities: staffCitiesStore.staffCities
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => {
        // console.log();
      }}
      validationSchema={validationCitiesFields}
    >
      {({
        values,
        initialValues,
        isValid,
        dirty,

        setFieldValue,
        handleChange,
        handleSubmit,
        resetForm
      }) => {
        const btns: {
          action: string;
          type: "submit" | "button" | "reset";
          icon: string;
          color: string;
          disabled: boolean;
        }[] = [
          {
            action: "submit",
            type: "button",
            icon: "bigcheck",
            color: !isValid || !dirty ? "bw-gray3" : "blue-lazure",
            disabled: !isValid || !dirty
          },
          {
            action: "cancel",
            type: "button",
            icon: "iconclose",
            color: "bw-gray5",
            disabled: false
          }
        ];

        const editRecord = (action: string, idx: number) => {
          switch (action) {
            case "submit":
              handleSubmit();
              setEditCity("");
              break;
            case "cancel":
              if (idx !== -1) {
                for (const key of staffCitiesStore.staffCitiesTableKeys) {
                  setFieldValue(
                    `cities.${idx}.${key}`,
                    initialValues.cities[idx][key]
                  );
                }
                setEditCity("");
              }
              break;
            case "delete":
              break;
            default:
              "";
          }
        };

        const createNewCity = () => {
          const newCityObj: { [key: string]: string } = {};
          if (staffCitiesStore.error) {
            values.cities = [];
            staffCitiesStore.setError(null);
          }
          for (const field of staffCitiesStore.staffCitiesTableKeys) {
            newCityObj[field] = "";
          }
          newCityObj.id = null;
          staffCitiesStore.addNewCity(newCityObj);
          setEditCity(null);
          resetForm();
        };

        return (
          <>
            <Button
              theme={ButtonTheme.CLEAR}
              onClick={() => {
                setIsAddingNew(true);
                createNewCity();
              }}
              id="addNewCityBtn_addCity"
              disabled={isAddingNew}
            >
              Добавить
            </Button>

            <Form>
              <div className={styles.tableContainer}>
                <table className={styles.typesTable}>
                  <thead>
                    <tr>
                      <th className={tableStyle.title}>Тип города</th>
                      <th className={tableStyle.title}>Город</th>
                      <th className={tableStyle.title}>Тип региона</th>
                      <th className={tableStyle.title}>Регион</th>
                      <th className={tableStyle.title}>Тип района</th>
                      <th className={tableStyle.title}>Район</th>
                      <th className={tableStyle.title}>Федеральный округ</th>
                      <th className={tableStyle.title}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.cities && values.cities.length
                      ? values.cities.map((city, idx, array) => (
                          <tr
                            key={city["id"]}
                            className={
                              city["id"] === editCity ? styles.newRow : ""
                            }
                          >
                            {Object.keys(city).map((key) =>
                              key !== "id" ? (
                                <td key={`${key}_${city["id"]}`}>
                                  <div className={styles.input}>
                                    <Input
                                      name={`cities.${idx}.${key}`}
                                      onChange={handleChange}
                                      onFocus={() => {
                                        const prevEditIdx = array.findIndex(
                                          ({ id }) =>
                                            id === prevEditCity.current
                                        );
                                        if (city["id"] !== editCity) {
                                          editRecord("cancel", prevEditIdx);
                                          setEditCity(city["id"]);
                                        }
                                      }}
                                      useFastField
                                    />
                                  </div>
                                </td>
                              ) : null
                            )}
                            <td className={styles.btnGroup}>
                              {editCity === city["id"] ? (
                                <>
                                  <ButtonsGroupForEdit
                                    btns={btns}
                                    id={city["id"]}
                                    idx={idx}
                                    onClick={editRecord}
                                  />
                                  {editCity && (
                                    <Button
                                      type="button"
                                      id={`deleteCityBtn_${city["id"]}`}
                                      onClick={() => {
                                        staffCitiesStore.deleteCity(idx);
                                        setEditCity("");
                                        setIsAddingNew(false);
                                      }}
                                      theme={ButtonTheme.SECONDARY}
                                      size={ButtonSize.S}
                                      disabled
                                    >
                                      <StatusIcon
                                        icon="iconbasket"
                                        color="bw-gray3"
                                      />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <Button
                                  type="button"
                                  id={`deleteCityBtn_${city["id"]}`}
                                  onClick={() => {
                                    setEditCity(city["id"]);
                                    staffCitiesStore.deleteCity(idx);
                                  }}
                                  theme={ButtonTheme.SECONDARY}
                                  size={ButtonSize.S}
                                  disabled
                                >
                                  <StatusIcon
                                    icon="iconbasket"
                                    color="bw-gray5"
                                  />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
                {staffCitiesStore.page !== 1 ? (
                  <p>
                    {staffCitiesStore.isLoading ? (
                      <p className={styles.loading}>Пожалуйста, подождите</p>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <LoadedComponent
                    isLoading={staffCitiesStore.isLoading}
                    errorMessage={staffCitiesStore.errorMessage}
                    actionButton={staffCitiesStore.actionButton}
                  />
                )}
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default observer(StaffCitiesTable);
