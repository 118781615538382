import styles from "./buildingOneWorkshiftHeaderWorkshiftMessageWindow.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import Alert from "shared/ui/Alert";
import { Button, ButtonTheme } from "shared/ui/Button";

type BuildingOneWorkshiftHeaderWorkshiftMessageWindowProps = {
  setIsOpenedPanel: (value: boolean) => void;
};

const BuildingOneWorkshiftHeaderWorkshiftMessageWindow = ({
  setIsOpenedPanel
}: BuildingOneWorkshiftHeaderWorkshiftMessageWindowProps) => {
  const { buildingOneWorkshiftStore } = useStores();
  const { id } = useParams();

  const ref = useRef<HTMLDivElement>();

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        (typeof event["target"]["className"] === "string" &&
          !event["target"]["className"].includes(
            "closeWorkshiftButtons_errorsBlock"
          ) &&
          !event["target"]["className"].includes(
            "closeWorkshiftButtons_errorText"
          )) ||
        (typeof event["target"]["className"] === "object" &&
          !event["target"]["className"]["baseVal"]?.includes(
            "closeWorkshiftButtons_icon__expand"
          ))
      )
        setIsOpenedPanel(false);
    }
  });

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      setIsOpenedPanel(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  const checkErrors = () => {
    // если апи разрешает закрыть смену
    if (buildingOneWorkshiftStore.closeWorkshiftMsg[id]?.result) {
      // но у кого-то из смены есть статус, не позволяющий закрыть смену
      if (
        buildingOneWorkshiftStore.closeWorkshiftStatusMsg[id]?.result === false
      )
        // сообщение о возможности закрыть смену не отрисовываем
        return false;
      // если на фронте проверки пройдены - показываем
      else return true;
    }
    // и если апи не разрешает закрыть смену и есть сообщение для отображения - тоже
    else if (buildingOneWorkshiftStore.closeWorkshiftMsg[id]?.message)
      return true;
  };

  return (
    <div className={styles.container} ref={ref}>
      {checkErrors()
        ? [buildingOneWorkshiftStore.closeWorkshiftMsg[id].message].map(
            (error) => {
              return (
                <Alert
                  errors={error}
                  key={error["head"]}
                  splitItemLi="Количество"
                />
              );
            }
          )
        : null}
      {buildingOneWorkshiftStore.closeWorkshiftStatusMsg[id]?.message
        ? [buildingOneWorkshiftStore.closeWorkshiftStatusMsg[id].message].map(
            (error) => {
              return (
                <Alert
                  errors={error}
                  key={error["head"]}
                  splitItemLi="Количество"
                />
              );
            }
          )
        : null}
      <div className={styles.btnBlock}>
        <Button
          id="BuildingOneWorkshiftHeaderWorkshiftMessageWindow_closePanelBtn"
          type="button"
          onClick={() => setIsOpenedPanel(false)}
          theme={ButtonTheme.SECONDARY}
        >
          Скрыть сообщение
        </Button>
      </div>
    </div>
  );
};

export default observer(BuildingOneWorkshiftHeaderWorkshiftMessageWindow);
