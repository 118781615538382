import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { baseUrl, changeAccessKey, changeUserId } from "stores/utils/consts";
import { ApiResponse } from "stores/utils/types/ApiResponse";

// объявляю метод ym (яндекс метрика) для всего объекта window
// ym имеет тип any, так как методы яндекса пока не имеют типизации
declare global {
  interface Window {
    ym;
  }
}

export default class UserStore {
  // индикатор загрузки токена, данных юзера, формата даты, топ-меню и списка виджетов в модуле сотрудника
  // нужен, чтобы отрендерить всё приложение только после загрузки данных, описанных выше
  isLoadingAccessKey = false;
  // индикатор наличия данных юзера в ЯМ, чтобы избежать повторных запросов
  hasUserData = false;

  accessKey = "";
  user_id = "";

  getLocalStorageOrDefault = (key: string, defaultValue: string) => {
    const stored = localStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return stored;
  };

  componentDidMount() {
    this.accessKey = this.getLocalStorageOrDefault("key", "");
    this.user_id = this.getLocalStorageOrDefault("id", "");
    changeUserId(this.user_id);
    changeAccessKey(this.accessKey);
  }

  setLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this, {});
  }

  getNewAccessKey = () => {
    this.isLoadingAccessKey = true;
    this.accessKey = this.getLocalStorageOrDefault("key", "");
    this.user_id = this.getLocalStorageOrDefault("id", "");
    changeAccessKey(this.accessKey);
    changeUserId(this.user_id);

    if (!this.accessKey) {
      if (location.hash) {
        this.getAccessKey(location.hash.substring(6));
      } else
        window.location.href = `${process.env.REACT_APP_API_URL}/?referrer=${window.location.href}`;
    } else {
      if (!this.hasUserData) {
        this.rootStore.apiStore.getReadWriteUrl();
        setTimeout(() => {
          this.getUser(this.user_id);
        }, 50);
      } else {
        this.isLoadingAccessKey = false;
      }
    }
  };

  getUser = async (userID: string) => {
    try {
      const data: ApiResponse<{ id: number; groupid: number } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          currentClass: "user",
          method: "getUser",
          params: { id: userID }
        });

      runInAction(() => {
        if (data?.code == 200 && data?.result !== -1) {
          Promise.all([
            this.rootStore.dateFormatsStore.getDateFormatsAll(),
            this.rootStore.menuStore.getTopMenu(),
            this.rootStore.menuStore.getStaffWidgetsList()
          ]).then(() => {
            data["result"]?.["groupid"] ? (this.hasUserData = true) : "";
            // функция для отправки данных пользователя в яндес метрику
            window.ym(93098246, "userParams", {
              UserID: data["result"]?.["id"],
              UserGroup: data["result"]?.["groupid"]
            });
          });
        }
      });
    } finally {
      runInAction(() => (this.isLoadingAccessKey = false));
    }
  };

  getAccessKey = async (token: string) => {
    return fetch(`${baseUrl}auth/?action=access_key&token=${token}`, {
      method: "POST"
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          window.location.href = `${process.env.REACT_APP_API_URL}/?referrer=${window.location.href}`;
        }
      })
      .then((response) => {
        runInAction(() => {
          if (response.value) {
            this.setLocalStorage("key", response.value);
            this.setLocalStorage("id", response.user_id);
            this.accessKey = response.value;
            this.user_id = response.user_id;
            changeUserId(this.user_id);
            changeAccessKey(this.accessKey);
            if (!this.hasUserData) {
              this.rootStore.apiStore.getReadWriteUrl();
              setTimeout(() => {
                this.getUser(this.user_id);
              }, 50);
            } else {
              this.isLoadingAccessKey = false;
            }
          } else {
            this.deleteAccessKey();
          }
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (error)
            window.location.href = `${process.env.REACT_APP_API_URL}/?referrer=${window.location.href}`;
        });
      });
  };

  deleteAccessKey = () => {
    this.cleanAccessKey();
    window.location.href = `${process.env.REACT_APP_API_URL}/crm/login?exit`;
  };

  cleanAccessKey = () => {
    this.accessKey = "";
    this.setLocalStorage("key", "");
    changeAccessKey("");
  };

  handleInvalidToken = (e: { [key: string]: string }) => {
    if (e.key === "key" && e.oldValue && !e.newValue) {
      window.location.reload();
    }
  };
}
