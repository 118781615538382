import styles from "./staffImportTableBody.module.scss";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { useEffect, useState } from "react";
import { append, identity, times, without } from "ramda";
import { observer } from "mobx-react-lite";
import { StaffImportTableForFormik } from "stores/StaffModule/types/StaffImportTable";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { Input } from "shared/ui/Inputs/Input";
import Textarea from "shared/ui/Inputs/Textarea";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import SelectMultiTable from "shared/ui/TableInputs/SelectMultiTable";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import StaffImportTableError from "../StaffImportTableError";
import NumberFormat from "react-number-format";
import StaffImportTableBodyStatus from "./StaffImportTableBodyStatus";
import StaffImportTableBodyDuplicateError from "./StaffImportTableBodyDuplicateError";
import { classNames } from "shared/utils/helpers/classNames";

const formatDict = {
  snils: "###-###-### ##",
  inn: "############",
  pasp_code: "###-###"
};

const StaffImportTableBody = () => {
  const { staffImportStore, menuStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isDateHidden, setIsDateHidden] = useState([]);

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    initialValues,
    errors
  } = useFormikContext<StaffImportTableForFormik>();

  const changeOpenedWindows = (name: string) => {
    setOpenedListName(openedListName === name ? "" : name);
  };

  useEffect(() => {
    if (values.edu_date_start_all_visible) {
      setIsDateHidden([]);
    } else {
      setIsDateHidden(times(identity, values.staff.length));
    }
  }, [values.edu_date_start_all_visible]);

  const handleEduChange = (ind) => (e) => {
    if (!e.target.checked) {
      setFieldValue(`staff.${ind}.edu_date_start`, "");
      setIsDateHidden(append(ind));
    } else {
      setIsDateHidden(without([ind]));
    }
  };

  return (
    <>
      {values["staff"].map((row, ind) => (
        <tr
          key={ind}
          className={classNames(styles.tableInfo, {
            [styles.greyRow]: ind % 2
          })}
        >
          <td data-no-hover>
            <p>{ind + 1}</p>
          </td>

          {staffImportStore.currentTitles.map((title) => {
            // если у колонки есть ошибка дублирующихся данных в файле
            const isInnerDuplicateError = Boolean(
              staffImportStore.verificationResponse[ind]?.["inner_doubles"]?.[
                title
              ]
            );

            // если у колонки есть ошибка дублирующихся данных с бэка
            const isDuplicateError = Boolean(
              staffImportStore.verificationResponse[ind]?.["doubles"]?.[title]
            );

            // содержит ли колонка ошибку после исправления данных в ней
            const isIncludeCurrentStateError =
              staffImportStore.duplicateColsResponse[ind]?.includes(title);

            // получаем красную ячейку после ошибки валидации или ошибки дубля
            const getErrorCell = () => {
              const hasError =
                errors["staff"]?.[ind]?.[title] ||
                isIncludeCurrentStateError ||
                isInnerDuplicateError;
              return hasError ? styles.errorCell : "";
            };

            // получаем желтую ячейку после изменений начальных значений
            const getModifiedCell = () => {
              const isModified =
                initialValues["staff"][ind][title]?.toString() !==
                  row[title]?.toString() && !getErrorCell();
              return isModified ? styles.modifiedСell : "";
            };

            // функция, которая выводит или, наоборот, убирает ошибку дубля у ячейки
            const setDuplicateError = (title: string, value: string) => {
              // если с бэка пришел approve: false на строку с сотрудником
              if (
                staffImportStore.verificationResponse[ind] &&
                !staffImportStore.verificationResponse[ind]["approve"]
              ) {
                // если пользователь отредактировал ячейку и она уже не равна начальному значению после проверки, но колонка ячейки еще находится в массиве колонок с ошибками этой строки
                if (
                  initialValues["staff"][ind][title] !== value &&
                  staffImportStore.duplicateColsResponse[ind]?.includes(title)
                ) {
                  // то убираем эту колонку из массива колонок с ошибками этой строки
                  staffImportStore.setDuplicateColsResponse(
                    ind,
                    staffImportStore.duplicateColsResponse[ind]?.filter(
                      (key) => key !== title
                    )
                  );
                }
                // если пользователь вернул значения к начальному значению после проверки, а колонка ячейки уже удалена из массива колонок с ошибками этой строки
                if (
                  initialValues["staff"][ind][title] === value &&
                  !staffImportStore.duplicateColsResponse[ind]?.includes(title)
                ) {
                  // то обратно добавляем эту колонку в массив колонок с ошибками этой строки
                  staffImportStore.setDuplicateColsResponse(ind, [
                    ...staffImportStore.duplicateColsResponse[ind],
                    title
                  ]);
                }

                // если массив колонок с ошибками этой строки пустой, то для всей строки задаем статус "Требуется подтверждение", если не пустой, то возвращаем статус ошибки дубля
                if (!staffImportStore.duplicateColsResponse[ind].length) {
                  staffImportStore.setStaffStatus("verificationRequired", ind);
                } else {
                  staffImportStore.setStaffStatus("incorrectDouble", ind);
                }
              }

              // если изначально строка с сотрудником пришла с меткой approve:true от бэка
              if (staffImportStore.verificationResponse[ind]?.["approve"]) {
                // строку не меняли или же вернули к начальному значению после проверки, то оставляем статус "Подтверждено"
                if (initialValues["staff"][ind][title] === value) {
                  staffImportStore.setStaffStatus("correct", ind);
                  setFieldValue(`staff.${ind}.select`, 1);
                } else {
                  // если вносились какие-то изменения, то говорим, что снова статус "Требуется подтверждение"
                  staffImportStore.setStaffStatus("verificationRequired", ind);
                  setFieldValue(`staff.${ind}.select`, 0);
                }
              }
            };

            switch (title) {
              case "select":
                return (
                  <td key={title}>
                    <div className={styles.selectCell}>
                      <Checkbox
                        name={`staff.${ind}.${title}`}
                        id={`staff.${ind}.${title}`}
                        disabled={
                          staffImportStore.staffStatus[ind] !== "correct"
                        }
                      />
                    </div>
                  </td>
                );
              case "status":
                return (
                  <StaffImportTableBodyStatus
                    index={ind}
                    key={title}
                    className={classNames(styles.whiteCell, {
                      [styles.greyCell]: ind % 2
                    })}
                  />
                );
              case "company":
                return (
                  <SelectMultiTable
                    key={title}
                    name={`staff.${ind}.${title}`}
                    options={staffImportStore.companiesDict}
                    valueName="id"
                    className={{ scrollboard: styles.selectMultiPopper }}
                  />
                );
              case "holding_edu":
                return (
                  <td key={title} className={getModifiedCell()}>
                    <div className={styles.selectCell}>
                      <Checkbox
                        name={`staff.${ind}.${title}`}
                        id={`staff.${ind}.${title}`}
                        onChange={handleEduChange(ind)}
                      />
                    </div>
                  </td>
                );
              case "edu_date_start":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                    {...renderHover(isDateHidden, ind)}
                  >
                    {isDateHidden.includes(ind) ? null : (
                      <DatePickerField
                        name={`staff.${ind}.${title}`}
                        title={""}
                        placeholderVisible
                        isCalendarOpened={
                          openedListName === `staff.${ind}.${title}`
                        }
                        setIsCalendarOpened={() => {
                          changeOpenedWindows(`staff.${ind}.${title}`);
                        }}
                        onChange={(value) => {
                          setDuplicateError(title, value);
                        }}
                        disabled={
                          title === "edu_date_start" && !row["holding_edu"]
                        }
                        className={{
                          container: styles.datePickerCustom,
                          popper: styles.datePickerPopper,
                          input: styles.datePickerInput
                        }}
                        withoutError
                      />
                    )}
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDuplicateError ? (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                );
              case "training_date_start":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <DatePickerField
                      name={`staff.${ind}.${title}`}
                      title={""}
                      placeholderVisible
                      isCalendarOpened={
                        openedListName === `staff.${ind}.${title}`
                      }
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(`staff.${ind}.${title}`);
                      }}
                      onChange={(value) => {
                        setDuplicateError(title, value);
                      }}
                      maxDate={
                        new Date(
                          Date.parse(
                            values.staff[ind]?.training_date_finish as string
                          )
                        )
                      }
                      className={{
                        container: styles.datePickerCustom,
                        popper: styles.datePickerPopper,
                        input: styles.datePickerInput
                      }}
                      withoutError
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDuplicateError ? (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                );
              case "birthday":
              case "pasp_date":
              case "training_date_finish":
                return (
                  <td
                    key={title}
                    className={`${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <DatePickerField
                      name={`staff.${ind}.${title}`}
                      title={""}
                      placeholderVisible
                      isCalendarOpened={
                        openedListName === `staff.${ind}.${title}`
                      }
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(`staff.${ind}.${title}`);
                      }}
                      onChange={(value) => {
                        setDuplicateError(title, value);
                      }}
                      minDate={
                        title === "training_date_finish"
                          ? new Date(
                              Date.parse(
                                values.staff[ind]?.training_date_start as string
                              )
                            )
                          : undefined
                      }
                      className={{
                        container: styles.datePickerCustom,
                        popper: styles.datePickerPopper,
                        input: styles.datePickerInput
                      }}
                      withoutError
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDuplicateError ? (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                );
              case "pasp_place":
              case "propisk":
              case "living_adress":
              case "birth_place":
                return (
                  <td
                    key={title}
                    className={`${
                      styles.textareaBlock
                    } ${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <Textarea
                      name={`staff.${ind}.${title}`}
                      value={row[title] as string}
                      placeholder={staffImportStore.cols[title]}
                      onChange={(e) => {
                        handleChange(e);
                        setDuplicateError(title, e.target.value);
                      }}
                      onBlur={handleBlur}
                      withoutErrorText
                      withoutCounter
                    />
                    <StaffImportTableError
                      error={errors.staff?.[ind]?.[title]}
                    />
                    {isDuplicateError ? (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                );
              case "snils":
              case "inn":
              case "pasp_code":
                return (
                  <td
                    key={title}
                    className={`${
                      styles.inputBlock
                    } ${getModifiedCell()} ${getErrorCell()}`}
                  >
                    <NumberFormat
                      format={formatDict[title]}
                      mask={formatDict[title].includes(" ") ? "_" : ""}
                      name={`staff.${ind}.${title}`}
                      value={row[title] as string}
                      placeholder={staffImportStore.cols[title]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setDuplicateError(title, e.target.value);
                      }}
                      onBlur={handleBlur}
                      customInput={Input}
                    />
                    {isInnerDuplicateError ? (
                      <StaffImportTableError
                        error={errors.staff?.[ind]?.[title]}
                      />
                    ) : (
                      ""
                    )}
                    {isDuplicateError || isInnerDuplicateError ? (
                      <StaffImportTableBodyDuplicateError
                        index={ind}
                        title={title}
                        innerDoubles={isInnerDuplicateError}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                );

              default:
                if (!title.includes("phone")) {
                  return (
                    <td
                      key={title}
                      className={classNames(
                        styles.inputBlock,
                        {
                          [styles.patronymic]: title === "patronymic",
                          [styles.patronymic__shadow]:
                            title === "patronymic" &&
                            menuStore.scroll["scrollLeft"]
                        },
                        [getModifiedCell(), getErrorCell()]
                      )}
                    >
                      <Input
                        name={`staff.${ind}.${title}`}
                        onChange={(e) => {
                          handleChange(e);
                          setDuplicateError(title, e.target.value);
                        }}
                      />
                      <StaffImportTableError
                        error={errors.staff?.[ind]?.[title]}
                      />
                      {isDuplicateError ? (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={title}
                      className={`${
                        styles.phoneBlock
                      } ${getModifiedCell()} ${getErrorCell()}`}
                    >
                      {row[title] ? (
                        <PhoneInput
                          name={`staff.${ind}.${title}`}
                          onChange={(e, countryCallingCode) => {
                            const validValue = `${countryCallingCode}${e.target.value
                              .replaceAll("-", "")
                              .replaceAll(" ", "")
                              .replaceAll("_", "")
                              .replaceAll("(", "")
                              .replaceAll(")", "")}`;

                            setDuplicateError(title, validValue);
                          }}
                          className={{
                            flag: styles.phoneBlockTop,
                            callingCode: styles.phoneBlockCode,
                            clearBtn: styles.phoneBlockTop,
                            error: styles.phoneBlockError,
                            input: styles.phoneBlockInput,
                            select: styles.phoneBlockSelect
                          }}
                        />
                      ) : (
                        <PhoneInput
                          name={`staff.${ind}.${title}`}
                          className={{
                            flag: styles.phoneBlockTop,
                            callingCode: styles.phoneBlockCode,
                            clearBtn: styles.phoneBlockTop,
                            error: styles.phoneBlockError,
                            input: styles.phoneBlockInput,
                            select: styles.phoneBlockSelect
                          }}
                        />
                      )}
                      <StaffImportTableError
                        error={errors.staff?.[ind]?.[title]}
                      />
                      {isDuplicateError || isInnerDuplicateError ? (
                        <StaffImportTableBodyDuplicateError
                          index={ind}
                          title={title}
                          innerDoubles={isInnerDuplicateError}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }
            }
          })}
        </tr>
      ))}
    </>
  );
};

export default observer(StaffImportTableBody);

function renderHover(isDateHidden, ind) {
  if (isDateHidden.includes(ind)) {
    return {
      "data-no-hover": true
    };
  }
  return {};
}
