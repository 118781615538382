import styles from "./staffOneStatusesTable.module.scss";

import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores/index";

import StaffOneStatusesTableOne from "./StaffOneStatusesTableOne";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpandBackground.svg";
import { without } from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { classNames } from "shared/utils/helpers/classNames";

const StaffOneStatusesTable = () => {
  const { staffOneStatusesStore, menuStore } = useStores();

  const [openedBuildings, setOpenedBuildings] = useState<{
    [company_id: string]: string[];
  }>({});

  const filteredCompanies = Object.fromEntries(
    Object.entries(staffOneStatusesStore.statusesList).filter(
      ([key, value]) => {
        if (Object.values(value).length) {
          return staffOneStatusesStore.filteredCompany !== "all"
            ? staffOneStatusesStore.filteredCompany === key
            : key !== "all";
        }
      }
    )
  );

  return (
    <div className={styles.allCompanyTable}>
      {Object.values(staffOneStatusesStore.statusesCols).length
        ? staffOneStatusesStore.companiesTabs.map((company) => {
            const value = filteredCompanies[company.id];
            const buildings: string[] = [];

            if (value) {
              Object.values(value).forEach((building, i) => {
                const status = Object.values(building)[0];
                if (!buildings.includes(status?.building)) {
                  buildings.push(`${status.building}_${i}`);
                }
              });

              if (!openedBuildings[company.id]) {
                setOpenedBuildings({
                  ...openedBuildings,
                  [company.id]: buildings
                });
              }
            }

            const openInfoButton = () => {
              setOpenedBuildings({
                ...openedBuildings,
                [company.id]: openedBuildings[company.id]?.length
                  ? []
                  : buildings
              });
            };

            return (
              value && (
                <div key={company.id} className={styles.oneCompanyBlock}>
                  <div className={styles.indexBorder}></div>
                  <div
                    className={classNames(styles.oneCompanyBlock_title, {
                      [styles.shadow]: menuStore.isScroll
                    })}
                  >
                    <IconExpand
                      className={classNames(styles.iconExpand, {
                        [styles.iconExpand_closed]:
                          !openedBuildings[company.id]?.length
                      })}
                      onClick={openInfoButton}
                      id={`StaffOneStatusesTable_openInfoBtn_${company.id}`}
                    />
                    {
                      staffOneStatusesStore.staffInfo["company"][company.id][
                        "title"
                      ]
                    }
                  </div>
                  {Object.values(value).map((objectItem, buildingIndex) => {
                    //переменная, которая проверяет является ли первая дата на объекте датой отпуска
                    const isFirstSubVacation = Boolean(
                      Object.values(objectItem)[
                        Object.values(objectItem).length - 1
                      ]?.sub_start_date &&
                        Object.values(objectItem)[
                          Object.values(objectItem).length - 1
                        ]?.sub_end_date
                    );

                    //переменная, которая проверяет является ли последняя дата на объекте датой отпуска
                    const isLastSubVaсation = Boolean(
                      Object.values(objectItem)[0]?.sub_start_date &&
                        Object.values(objectItem)[0]?.sub_end_date
                    );

                    //переменная, которая проверяет является ли последний статус у текущего объекта "Активен"
                    const isActiveStatus =
                      staffOneStatusesStore.statusesParams[
                        Object.values(objectItem)[0]?.type
                      ].custom.active && buildingIndex === 0;

                    return (
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        key={Object.values(objectItem)[0]["id"]}
                      >
                        <StaffOneStatusesTableOne
                          objectItem={objectItem}
                          buildingIndex={buildingIndex}
                          isFirstSubVacation={isFirstSubVacation}
                          isLastSubVaсation={isLastSubVaсation}
                          isActiveStatus={isActiveStatus}
                          allObject={value}
                          isMoreInfoOpen={
                            openedBuildings[company.id]?.length
                              ? openedBuildings[company.id].includes(
                                  `${
                                    Object.values(objectItem)[0].building
                                  }_${buildingIndex}`
                                )
                              : false
                          }
                          setIsMoreInfoOpen={() => {
                            if (
                              openedBuildings[company.id].includes(
                                `${
                                  Object.values(objectItem)[0].building
                                }_${buildingIndex}`
                              )
                            ) {
                              setOpenedBuildings({
                                ...openedBuildings,
                                [company.id]: without(
                                  openedBuildings[company.id],
                                  `${
                                    Object.values(objectItem)[0].building
                                  }_${buildingIndex}`
                                )
                              });
                            } else {
                              setOpenedBuildings({
                                ...openedBuildings,
                                [company.id]: [
                                  `${
                                    Object.values(objectItem)[0].building
                                  }_${buildingIndex}`,
                                  ...openedBuildings[company.id]
                                ]
                              });
                            }
                          }}
                        />
                      </ErrorBoundary>
                    );
                  })}
                </div>
              )
            );
          })
        : ""}
    </div>
  );
};

export default observer(StaffOneStatusesTable);
