import styles from "./staffRatingTable.module.scss";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useStores } from "stores/index";
import { fileUrl } from "stores/utils/consts";
import StaffRatingTableDetailsWindow from "./StaffRatingTableDetailsWindow";
import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";
import Avatar from "shared/assets/images/mainIcons/iconPolatiAvatar.svg";
import iconStarSmall from "shared/assets/images/mainIcons/iconStar/iconStarSmall.svg";
import LoadedComponent from "widgets/LoadedComponent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Link } from "react-router-dom";
import { useState } from "react";

type StaffRatingTableProps = {
  selectedTab: string;
  switchedTab: string;
};

//hardcode
const titles = [
  "№ в рейтинге",
  "ТН, ФИО",
  "Должность",
  "Средняя оценка",
  "Засчитано оценок",
  "Коэффициент",
  "Отработано смен",
  "Баллы"
];

const StaffRatingTable = ({
  selectedTab,
  switchedTab
}: StaffRatingTableProps) => {
  const { staffRatingStore, menuStore } = useStores();
  const [selectedRow, setSelectedRow] = useState<number>(null);

  const tooltips = {
    "Засчитано оценок":
      "Рейтинг ИТР учитывает оценки работников, которые пересекались на площадке с ИТР персоналом хотя бы в одной смене в течение года.",
    Баллы: "Произведение количества смен и коэффициента."
  };

  return (
    <div className={styles.isLoadingForTable}>
      {staffRatingStore.ratingStaffList[selectedTab]?.[switchedTab] ? (
        <Table className={styles.table}>
          <thead>
            <tr
              className={`${styles.stickyHeader} ${
                menuStore.isScroll ? styles.stickyHeader__shadow : ""
              }`}
            >
              {titles.map((title) => {
                switch (title) {
                  case "Засчитано оценок":
                  case "Баллы":
                    return (
                      <th key={title} className={styles.title}>
                        <div className="d-flex align-items-center">
                          {title}{" "}
                          <div
                            className={styles.tooltip}
                            data-tooltip={tooltips[title]}
                          >
                            <IconQuestionSmall
                              className={styles.iconQuestion}
                              alt="tooltip"
                            />
                          </div>
                        </div>
                      </th>
                    );
                  case "Коэффициент":
                    return (
                      <th
                        key={title}
                        className={`${styles.title} ${styles.title__link}`}
                        onClick={() => {
                          staffRatingStore.setIsOpenCoefficientTableWindow(
                            true
                          );
                        }}
                        id={`StaffRatingTable_openCoefficientWindow_${title}`}
                      >
                        {title}
                      </th>
                    );
                  default:
                    return (
                      <th key={title} className={styles.title}>
                        {title}
                      </th>
                    );
                }
              })}
              <th className={styles.emptyTH} />
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {Object.values(
              staffRatingStore.ratingStaffList[selectedTab][switchedTab]
            ).map((staff, index) => {
              return (
                <tr
                  key={staff.id}
                  className={`${index % 2 ? styles.greyRow : ""} ${
                    selectedRow === index ? styles.selectedRow : ""
                  }`}
                  onClick={() => {
                    setSelectedRow(index);
                    staffRatingStore.setSelectedStaff(staff);
                    staffRatingStore.getRatingStaffDetail(
                      staffRatingStore.activeRatingYearTab,
                      staff.id
                    );
                  }}
                  id={`StaffRatingTable_showStaffDetails_${staff.id}`}
                >
                  <td className={styles.rankNum}>
                    <div>
                      {staff.position_in_rating
                        ? staff.position_in_rating
                        : index + 1}
                    </div>
                  </td>
                  <td>
                    <div className={styles.starRank}>
                      <img
                        src={
                          staff.photo
                            ? `${fileUrl}${staff.photo}?${Math.floor(
                                Math.random() * 100000
                              )}`
                            : Avatar
                        }
                        className={styles.avatar}
                      />
                      <Link
                        className={styles.link}
                        id={`StaffRatingTable_staffLink_${staff.id}`}
                        to={`/staff/id=${staff.id}`}
                      >
                        {`${staff.uid} ${staff.title}`}
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div>{staff.position}</div>
                  </td>
                  <td>
                    <div className={styles.starRank}>
                      <img src={iconStarSmall} />
                      {staff.rank_d}
                    </div>
                  </td>
                  <td>{staff.marks_count}</td>
                  <td>{staff.rank_coefficient}</td>
                  <td>{staff.count_ws}</td>
                  <td>{staff.rank_itr}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        ""
      )}
      <LoadedComponent isLoading={staffRatingStore.isLoadingForTable} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {staffRatingStore.selectedStaff ||
        staffRatingStore.isOpenCoefficientTableWindow ? (
          <StaffRatingTableDetailsWindow />
        ) : (
          ""
        )}
      </ErrorBoundary>
    </div>
  );
};
export default observer(StaffRatingTable);
