import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import styles from "./staffStatusSetting.module.scss";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import StaffStatusSettingsMenu from "./StaffStatusSettingsMenu";
import StaffStatusSettingsForm from "./StaffStatusSettingsForm";

const StaffStatusSettings = () => {
  const { staffStatusStore, menuStore } = useStores();

  useEffect(() => {
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("statusSettings");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/statusSettings",
      title: "Сотрудники"
    });

    staffStatusStore.getStatusTypes();
    staffStatusStore.setStatusFields();
  }, []);

  return (
    <div>
      <PageTitle title="Настройка статусов работников" leftCol />

      <LoadedComponent
        isLoading={staffStatusStore.isLoading}
        error={staffStatusStore.error}
      >
        <div className={styles.main}>
          <StaffStatusSettingsMenu />
          <StaffStatusSettingsForm />
        </div>
      </LoadedComponent>
    </div>
  );
};

export default observer(StaffStatusSettings);
