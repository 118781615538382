import { classNames } from "shared/utils/helpers/classNames";
import styles from "./buttonNext.module.scss";
import { ReactComponent as IconNext } from "shared/assets/images/mainIcons/iconNext.svg";

type ButtonNextProps = {
  disabled: boolean;
  /**
   * Для кнопки переключения назад (previous)
   */
  leftDirection?: boolean;
  className?: string;
  onClick: () => void;
};

export const ButtonNext = ({
  disabled,
  leftDirection,
  className,
  onClick
}: ButtonNextProps) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.buttonDisabled]: disabled,
          [styles.buttonPrev]: leftDirection
        },
        [className]
      )}
      onClick={onClick}
    >
      <IconNext
        className={classNames(styles.icon, {
          [styles.iconDisabled]: disabled
        })}
      />
    </button>
  );
};
