import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { Col } from "stores/utils/types/Col";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { AregisterType } from "./types/aregisterType";
import { MastersGangers } from "./types/MastersGangers";
import { MastersAndGangers } from "./aregisterOne/aregisterOne";
import { AregisterSelects } from "./types/AregisterSelects";

import { isEmpty } from "lodash";

export type AregisterEditTypes = [
  "",
  "exit",
  "edit",
  "mass_edit",
  "mass_status"
];

export default class AregisterListStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;
  isMassEditDisabled = false;

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  cols: Record<string, Col> = {};
  currentTitles: string[] = [];

  aregisterList: AregisterType[] = [];
  aregisterCount = 0;

  selects: Partial<AregisterSelects> = {};

  openedFilter = false;
  openedPanel = false;
  openedEditType: AregisterEditTypes[number] = "";
  openedMassStatusEdit = "";
  unableCloseApp: string[] = [];

  searchValue = "";

  isScaleChanged = false;

  openedModal: string;
  preOpenEditType: { type: AregisterEditTypes[number]; sub_type: string } = {
    type: "",
    sub_type: ""
  };

  // списки мастеров и бригадиров для карточки заявки
  masters: Record<string, MastersAndGangers> = {};
  brigadiers: Record<string, MastersAndGangers> = {};

  resetAll = () => {
    this.error = false;
    this.errorMessage = "";
    this.isLoading = false;
    this.isLoadingForFilters = false;
    this.isMassEditDisabled = false;

    this.onPage = 100;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;

    this.cols = {};

    this.aregisterList = [];

    this.selects = {};

    this.openedFilter = false;
    this.openedPanel = false;
    this.openedEditType = "";
    this.openedMassStatusEdit = "";

    this.unableCloseApp = [];

    this.searchValue = "";
  };

  getData = () => {
    this.isLoading = true;

    Promise.all([isEmpty(this.selects) && this.getSelects()]).then(() =>
      this.getPage()
    );
  };

  getPage = async () => {
    this.isLoading = true;
    this.errorMessage = "";
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.aregisterList = [];
    this.aregisterCount = 0;

    const params: Record<string, string> = {
      module: "aregister"
    };

    if (this.searchValue) {
      params.fast_search = this.searchValue;
    }

    try {
      const data: ApiResponse<{
        [key: string]: AregisterType;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPage",
        on_page: this.onPage,
        params: params
      });
      runInAction(() => {
        if (data.code === 200) {
          if (isEmpty(data.result)) {
            this.errorMessage = "Ничего не найдено";
          } else {
            this.aregisterList.push(...Object.values(data.result || {}));
            this.cols = data.cols;
            this.currentTitles = Object.values(data.show_fields || {});
            this.aregisterCount = data.nav.count;
          }
        }
        this.maxPage = data.nav.max_page;
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getMorePage = async () => {
    this.errorMessage = "";
    this.isLoading = true;

    const params: Record<string, string> = {
      module: "aregister"
    };

    if (this.searchValue) {
      params.fast_search = this.searchValue;
    }

    try {
      const data: ApiResponse<{
        [key: string]: AregisterType;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "aregister",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: params
      });
      runInAction(() => {
        if (data.code === 200) {
          this.aregisterList.push(...Object.values(data.result || {}));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.aregisterList = [];
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => (this.error = false));
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<AregisterSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "aregister",
          method: "getSelects",
          params: { module: "aregister" }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    }
  };

  getMastersAndGangers = async (aregister: AregisterType) => {
    if (!(aregister.id in this.masters) || !(aregister.id in this.brigadiers)) {
      this.masters[aregister.id] = {};
      this.brigadiers[aregister.id] = {};
      try {
        const data: ApiResponse<MastersGangers> =
          await this.rootStore.apiStore.getData({
            requestMethod: "GET",
            baseClass: "aregister",
            method: "getMastersAndGangers",
            params: {
              project_id: aregister.project.id,
              date_start: aregister.date_start,
              date_end: aregister.date_end
            }
          });

        runInAction(() => {
          const master = aregister.master
            ? {
                id: aregister.master.id,
                title: aregister.master.fio
              }
            : null;

          const brigadier = aregister.brigadier
            ? {
                id: aregister.brigadier.id,
                title: aregister.brigadier.fio
              }
            : null;

          this.masters[aregister.id] = master
            ? {
                masterless: {
                  id: "masterless",
                  title: "Без мастера"
                },
                [master.id]: master
              }
            : {
                masterless: {
                  id: "masterless",
                  title: "Без мастера"
                }
              };
          this.brigadiers[aregister.id] = brigadier
            ? { [brigadier.id]: brigadier }
            : {};

          if (data.result) {
            const getList = (
              options: {
                [key: string]: {
                  fio: string;
                  tn: number;
                };
              },
              aregisterValue: MastersAndGangers,
              isMaster?: string
            ) => {
              const list: MastersAndGangers = aregisterValue;

              Object.entries(options).length &&
                Object.entries(options).forEach(([id, staffOne]) => {
                  if (!list[id]) {
                    list[id] = {
                      title: `ТН${staffOne.tn} ${staffOne.fio}`,
                      id: id
                    };
                  }
                });

              // В некоторых заявках есть такие ошибки (будут приходить нам чуть позже):
              // "Мастер заявки не числится в закрытых сменах либо не имеет часов"
              // Данные такого мастера и бригадира (если он выбран) не приходят в списке на выбранное число заявки
              // И их нужно добавить в список хардкодом для отображения в поле Select
              if (
                isMaster
                  ? aregister.master?.id
                  : aregister.brigadier?.id &&
                    !list[
                      isMaster ? aregister.master.id : aregister.brigadier.id
                    ]
              ) {
                const staff = isMaster ? aregister.master : aregister.brigadier;

                list[staff.id] = {
                  title: `ТН${staff.tn} ${staff.fio}}`,
                  id: staff.id
                };
              }

              return list;
            };

            this.masters[aregister.id] = getList(
              data.result.masters,
              this.masters[aregister.id],
              "isMaster"
            );
            this.brigadiers[aregister.id] = getList(
              data.result.gangers,
              this.brigadiers[aregister.id]
            );
          }
        });
      } catch (error) {
        runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
      }
    }
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setIsMassEditDisabled = (value: boolean) => {
    this.isMassEditDisabled = value;
  };

  setOpenedPanel = (value: boolean) => {
    this.openedPanel = value;
  };

  setOpenedEditType = (
    value: AregisterEditTypes[number],
    sub_value?: string
  ) => {
    if (value === "mass_status") {
      if (
        (sub_value && this.openedMassStatusEdit === sub_value) ||
        !sub_value
      ) {
        this.openedPanel = true;
        this.openedEditType = "edit";
        this.openedMassStatusEdit = "";
        this.unableCloseApp = [];
      } else {
        this.openedEditType = "mass_status";
        this.openedMassStatusEdit = sub_value;
        this.unableCloseApp = [];
      }
    } else {
      this.openedEditType = this.openedEditType === value ? "" : value;
      this.openedMassStatusEdit = "";
      this.unableCloseApp = [];
    }
  };

  changeStatus = (values: string[]) => {
    this.isLoading = true;

    setTimeout(() => {
      runInAction(() => {
        this.selects.status[this.openedMassStatusEdit]["custom"]["done"]
          ? (this.unableCloseApp = values.slice(
              0,
              values.length === 1 || values.length === 2
                ? values.length
                : Math.trunc(values.length / 3)
            ))
          : "";
        this.isLoading = false;
      });
    }, 2000);
  };

  getSum = (array: string[], name: string) => {
    let sum = 0;

    Object.values(this.aregisterList).forEach((application) => {
      if (array.includes(application.id)) {
        sum += application[name];
      }
    });

    return sum.toFixed(2);
  };

  changeOpenedModal = (name?: string) => {
    this.openedModal =
      this.openedModal !== name
        ? name
        : ["mass_status", "mass_edit"].includes(name)
        ? "edit"
        : "";
  };

  setPreOpenEditType = (value: {
    type: AregisterEditTypes[number];
    sub_type: string;
  }) => {
    this.preOpenEditType = value;
  };

  setIsScaleChanged = (value: boolean) => {
    this.isScaleChanged = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
